// import React, { useState, useEffect, useRef } from "react";

// const Gallery = () => {
//   const items = [
//     // { type: "image", src: "s1.png" },
//     // { type: "image", src: "p2.jpg" },
//     { type: "image", src: "s3.jpg" },
//     // { type: "image", src: "t1.jpg" },
//     // { type: "image", src: "s5.png" },
//     { type: "image", src: "g1.jpeg" },
//     // { type: "image", src: " s7.jpeg" },
//     // { type: "image", src: "p3.jpg" },
//     { type: "image", src: "s9.jpg" },
//     { type: "image", src: "g4.jpeg" },
//     { type: "image", src: "g5.jpeg" },
//     // { type: "image", src: " s11.jpg" },
//     // { type: "image", src: "s12.jpg" },
//     // { type: "image", src: "s1.png" },
//     { type: "image", src: "g3.jpeg" },
//     // { type: "image", src: "s3.jpg" },
//     // { type: "image", src: "g1.jpeg" },
//     // // { type: "image", src: "s5.png" },
//     // { type: "image", src: "g2.jpeg" },
//     // // { type: "image", src: " s7.jpeg" },
//     // { type: "image", src: "p3.jpg" },
//     // { type: "image", src: "s9.jpg" },
//     { type: "image", src: "g6.jpeg" },
//     // { type: "image", src: " s11.jpg" },
//     // { type: "image", src: "s12.jpg" },
//   ];

//   const carouselRef = useRef(null);
//   const [isPaused, setIsPaused] = useState(false);
//   const [popupContent, setPopupContent] = useState(null);

//   useEffect(() => {
//     const carousel = carouselRef.current;

//     if (!carousel || isPaused) return;

//     // Duplicated scroll content
//     const scrollWidth = carousel.scrollWidth / 2; // Since we duplicate content

//     const interval = setInterval(() => {
//       if (carousel) {
//         // Scroll the carousel forward
//         carousel.scrollBy({ left: 4, behavior: "smooth" });

//         // If we've scrolled past the first set of items, reset to the start of duplicate
//         if (carousel.scrollLeft >= scrollWidth) {
//           carousel.scrollLeft = 0; // Jump to the start of duplicate items
//         }
//       }
//     }, 20);

//     return () => clearInterval(interval);
//   }, [isPaused]);

//   const openPopup = (item) => {
//     setPopupContent(item);
//   };

//   const closePopup = () => {
//     setPopupContent(null);
//   };

//   return (
//     <div style={{ width: "100%", padding: "20px", margin: "50px 0px" }}>
//       {/* Popup for displaying the full image/video */}
//       {popupContent && (
//         <div
//           style={{
//             position: "fixed",
//             top: "50%",
//             left: "50%",
//             transform: "translate(-50%, -50%)",
//             zIndex: 1000,
//             backgroundColor: "#fff",
//             padding: "20px",
//             borderRadius: "10px",
//             boxShadow: "0 8px 16px rgba(0, 0, 0, 0.3)",
//           }}
//         >
//           <button
//             onClick={closePopup}
//             style={{
//               position: "absolute",
//               top: "10px",
//               right: "10px",
//               background: "none",
//               border: "none",
//               fontSize: "16px",
//               cursor: "pointer",
//             }}
//           >
//             ✖
//           </button>
//           {popupContent.type === "image" ? (
//             <img
//               src={popupContent.src}
//               alt="Popup"
//               style={{ width: "100%", height: "auto", borderRadius: "8px" }}
//             />
//           ) : (
//             <iframe
//               title="YouTube Video Popup"
//               src={popupContent.src}
//               style={{
//                 width: "100%",
//                 height: "300px",
//                 border: "none",
//               }}
//               allow="autoplay; encrypted-media"
//             />
//           )}
//         </div>
//       )}

//       {/* Overlay background when popup is open */}
//       {popupContent && (
//         <div
//           onClick={closePopup}
//           style={{
//             position: "fixed",
//             top: 0,
//             left: 0,
//             width: "100vw",
//             height: "100vh",
//             backgroundColor: "rgba(0, 0, 0, 0.5)",
//             zIndex: 999,
//           }}
//         />
//       )}

//       <div
//         id="carousel"
//         ref={carouselRef}
//         style={{
//           display: "flex",
//           gap: "10px",
//           overflowX: "scroll",
//           whiteSpace: "nowrap",
//           padding: "30px",
//           border: "1px solid #ddd",
//           borderRadius: "8px",
//           scrollSnapType: "x mandatory",
//         }}
//         onMouseEnter={() => setIsPaused(true)} // Pause scrolling on hover
//         onMouseLeave={() => setIsPaused(false)} // Resume scrolling on mouse leave
//       >
//         {/* Render items twice for infinite loop effect */}
//         {[...items, ...items].map((item, index) => (
//           <div
//             key={index}
//             style={{
//               minWidth: "400px", // Ensure enough width for each item
//               height: "300px",
//               cursor: "pointer",
//               borderRadius: "8px",
//               overflow: "hidden",
//               display: "flex",
//               alignItems: "center",
//               justifyContent: "center",
//             }}
//             onClick={() => openPopup(item)} // Open popup on item click
//           >
//             {item.type === "image" ? (
//               <img
//                 src={item.src}
//                 alt="Gallery Item"
//                 style={{ width: "100%", height: "100%" }}
//               />
//             ) : (
//               <iframe
//                 title="YouTube Video"
//                 src={item.src}
//                 style={{ width: "100%", height: "100%", border: "none" }}
//               />
//             )}
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default Gallery;
import React, { useState, useEffect, useRef } from "react";
import axios from "axios";

const Gallery = () => {
  const [items, setItems] = useState([]); // State to hold gallery items
  const [isPaused, setIsPaused] = useState(false); // Carousel pause state
  const [popupContent, setPopupContent] = useState(null); // Content for popup display
  const [error, setError] = useState(""); // Error state
  const carouselRef = useRef(null); // Ref for the carousel container

  const modifyImageUrl = (image) => {
    if (!image) return ""; // If image is null/undefined, return an empty string

    // If image is a string (direct URL), return it as is
    if (typeof image === "string") {
      // Handle Google Drive URLs
      if (image.includes("drive.google.com")) {
        console.log("inside google drive");
        // const match = image.match(/\/d\/(.+?)\//);
        // if (match) {
        //   console.log(`https://lh3.googleusercontent.com/d/${match[1]}=s1900?authuser=0`);
        //   return `https://lh3.googleusercontent.com/d/${match[1]}=s1900?authuser=0`;
        // }
        const fileId = image.match(/\/d\/(.+?)\//)[1]; // Extract file ID from URL if it exists
        return fileId
          ? `https://lh3.googleusercontent.com/d/${fileId}=s1900?authuser=0`
          : image;
      }
      return image; // Return direct AWS S3 links without modification
    }

    // If image is an object (future-proofing), handle it correctly
    if (typeof image === "object" && image.url) {
      if (image.storageType === "google_drive") {
        const match = image.url.match(/\/d\/(.+?)\//);
        if (match) {
          return `https://lh3.googleusercontent.com/d/${match[1]}=s1900?authuser=0`;
        }
      }
      return image.url;
    }

    return ""; // Fallback for unexpected cases
  };

  // Fetch all gallery items from the backend (no filtering by division)
  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await axios.post("/api/gallery/fetchimages", {
          division: "Gallery", // Include division
        });

        if (response.data && Array.isArray(response.data.images)) {
          if (response.data.images.length === 0) {
            setError("No gallery items found for this division.");
          } else {
            setItems(response.data.images);
            setError("");
          }
        } else {
          throw new Error("Invalid response structure or no images found.");
        }
      } catch (err) {
        console.error("Error fetching gallery items:", err.message);
        setError("Failed to load gallery items. Please try again later.");
      }
    };

    fetchImages();
  }, []);

  // Automatic carousel scrolling
  useEffect(() => {
    const carousel = carouselRef.current;

    if (!carousel || isPaused) return;

    const scrollWidth = carousel.scrollWidth / 2; // Scroll limit

    const interval = setInterval(() => {
      carousel.scrollBy({ left: 4, behavior: "smooth" });

      if (carousel.scrollLeft >= scrollWidth) {
        carousel.scrollLeft = 0; // Reset scroll position
      }
    }, 20);

    return () => clearInterval(interval);
  }, [isPaused]);

  // Open popup with item details
  const openPopup = (item) => {
    setPopupContent(item);
  };

  // Close the popup
  const closePopup = () => {
    setPopupContent(null);
  };

  return (
    <div style={{ width: "100%", padding: "20px", margin: "50px 0px" }}>
      {/* Error message */}
      {error && (
        <div
          style={{ color: "red", textAlign: "center", marginBottom: "20px" }}
        >
          {error}
        </div>
      )}

      {/* Popup for displaying item details */}
      {popupContent && (
        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 1000,
            backgroundColor: "#fff",
            padding: "20px",
            borderRadius: "10px",
            boxShadow: "0 8px 16px rgba(0, 0, 0, 0.3)",
          }}
        >
          <button
            onClick={closePopup}
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
              background: "none",
              border: "none",
              fontSize: "16px",
              cursor: "pointer",
            }}
          >
            ✖
          </button>
          {popupContent.mediaType === "image" ? (
            <img
              src={modifyImageUrl(popupContent.mediaUrl)}
              alt={popupContent.title}
              style={{ width: "100%", height: "auto", borderRadius: "8px" }}
            />
          ) : (
            <iframe
              title={popupContent.title}
              src={popupContent.mediaUrl}
              style={{
                width: "100%",
                height: "300px",
                border: "none",
              }}
              allow="autoplay; encrypted-media"
            />
          )}
        </div>
      )}

      {/* Overlay background when popup is open */}
      {popupContent && (
        <div
          onClick={closePopup}
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 999,
          }}
        />
      )}

      {/* Carousel for gallery items */}
      {items.length > 0 && (
        <div
          id="carousel"
          ref={carouselRef}
          style={{
            display: "flex",
            gap: "10px",
            overflowX: "scroll",
            whiteSpace: "nowrap",
            padding: "30px",
            border: "1px solid #ddd",
            borderRadius: "8px",
            scrollSnapType: "x mandatory",
          }}
          onMouseEnter={() => setIsPaused(true)} // Pause scrolling on hover
          onMouseLeave={() => setIsPaused(false)} // Resume scrolling on mouse leave
        >
          {[...items, ...items].map((item, index) => (
            <div
              key={index}
              style={{
                minWidth: "400px",
                height: "300px",
                cursor: "pointer",
                borderRadius: "8px",
                overflow: "hidden",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              onClick={() => openPopup(item)} // Open popup on click
            >
              {item.mediaType === "image" ? (
                <img
                  src={modifyImageUrl(item.mediaUrl)}
                  alt={item.title}
                  style={{ width: "100%", height: "100%" }}
                />
              ) : (
                <iframe
                  title={item.title}
                  src={item.mediaUrl}
                  style={{ width: "100%", height: "100%", border: "none" }}
                  allow="autoplay; encrypted-media"
                />
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Gallery;
