import React from "react";
import "./FooterInfo.css";

function FooterInfo() {
  return (
    <footer className="footer-info" style={{
      fontFamily: '"Libre Baskerville", serif'
    }}>
      <div className="footer-container">
        <div className="about-us">
          <h2>Contact Us</h2>
          <p>
            Email:{" "}
            <a href="mailto:ashutosh@gyankosha.com">ashutosh@gyankosha.com</a>
          </p>
        </div>
        <div className="social-media">
          <h2>Follow Us</h2>
          <div className="icons">
            <a
              href="https://www.youtube.com/@gyankosha"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Visit our YouTube channel"
            >
              <i className="fab fa-youtube"></i>
            </a>
            <a
              href="https://www.linkedin.com/company/gyankosha/"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Visit our LinkedIn page"
            >
              <i className="fab fa-linkedin-in"></i>
            </a>
            <a
              href="https://www.instagram.com/_gyankosha/"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Visit our Instagram profile"
            >
              <i className="fab fa-instagram"></i>
            </a>
          </div>
        </div>
        <div className="faq-section">
          <h2>FAQs</h2>
          <ul>
            <li>
              <a
                href="https://www.youtube.com/watch?v=example1"
                target="_blank"
                rel="noopener noreferrer"
              >
                How to register for a course?
              </a>
            </li>
            <li>
              <a
                href="https://www.youtube.com/watch?v=example2"
                target="_blank"
                rel="noopener noreferrer"
              >
                How to reset your password?
              </a>
            </li>
            <li>
              <a
                href="https://www.youtube.com/watch?v=example3"
                target="_blank"
                rel="noopener noreferrer"
              >
                How to access study material?
              </a>
            </li>
          </ul>
          <p>
            <a href="/faq" target="_blank" rel="noopener noreferrer">
              More Questions?
            </a>
          </p>
        </div>
      </div>
    </footer>
  );
}

export default FooterInfo;
