// // // import React, { useEffect, useState } from "react";
// // // import { useLocation } from "react-router-dom";
// // // import ArticleCard from "../components/ArticleCard"; // Ensure this is the correct path
// // // import Pagination from "../components/Pagination"; // Import Pagination component

// // // function ArticleCardPage() {
// // //   const [cards, setCards] = useState([]);
// // //   const [currentPage, setCurrentPage] = useState(1);
// // //   const [totalArticles, setTotalArticles] = useState(0);
// // //   const cardsPerPage = 5; // Adjust this to set the number of cards per page
// // //   const location = useLocation(); // Get the current location

// // //   // Extract the division from the query string (e.g., ?division=student)
// // //   const queryParams = new URLSearchParams(location.search);
// // //   const division = queryParams.get("division");

// // //   useEffect(() => {
// // //     const fetchCards = async () => {
// // //       try {
// // //         const response = await fetch(
// // //           `/api/articles/fetcharticle?division=${division}`
// // //         );
// // //         const data = await response.json();
// // //         console.log("Fetched articles:", data); // Log the data for debugging

// // //         // Set total articles count for pagination
// // //         setTotalArticles(data.length);

// // //         // Pagination logic: Slice the fetched data based on the current page
// // //         const startIndex = (currentPage - 1) * cardsPerPage;
// // //         const paginatedData = data.slice(startIndex, startIndex + cardsPerPage);

// // //         const formattedCards = paginatedData.map((article) => ({
// // //           title: article.title,
// // //           titleImage: article.titleImage, // Get the first image URL
// // //           _id: article._id, // Add a link to the article
// // //           //   tag: article.tags.length > 0 ? article.tags[0] : "General", // Display the first tag or "General"
// // //         }));

// // //         setCards(formattedCards);
// // //       } catch (error) {
// // //         console.error("Error fetching articles:", error);
// // //       }
// // //     };

// // //     if (division) {
// // //       fetchCards(); // Fetch articles only if division is present
// // //     }
// // //   }, [division, currentPage]); // Re-fetch articles when division or currentPage changes

// // //   // Pagination handler function
// // //   const paginate = (pageNumber) => setCurrentPage(pageNumber);

// // //   return (
// // //     <div style={{ padding: "20px" }}>
// // //       <h2>Articles for {division} Division</h2>
// // //       {/* Pass the cards data to the ArticleCard component */}
// // //       <ArticleCard cards={cards} />

// // //       {/* Pagination component */}
// // //       <Pagination
// // //         quizzesPerPage={cardsPerPage}
// // //         totalQuizzes={totalArticles}
// // //         currentPage={currentPage}
// // //         paginate={paginate}
// // //       />
// // //     </div>
// // //   );
// // // }

// // // export default ArticleCardPage;
// // import React, { useEffect, useState } from "react";
// // import { useLocation } from "react-router-dom";
// // import ArticleCard from "../components/ArticleCard"; // Ensure this is the correct path
// // import Pagination from "../components/Pagination"; // Import Pagination component

// // function ArticleCardPage() {
// //   const [cards, setCards] = useState([]);
// //   const [currentPage, setCurrentPage] = useState(1);
// //   const [totalArticles, setTotalArticles] = useState(0);
// //   const cardsPerPage = 5; // Adjust this to set the number of cards per page
// //   const location = useLocation(); // Get the current location

// //   // Extract the division from the query string (e.g., ?division=student)
// //   const queryParams = new URLSearchParams(location.search);
// //   const division = queryParams.get("division");

// //   useEffect(() => {
// //     const fetchCards = async () => {
// //       try {
// //         let response;
// //         if (division === "All") {
// //           // Use the fetchallarticles API for "All"
// //           response = await fetch("/api/articles/fetchallarticles");
// //         } else {
// //           // Use fetcharticle API for specific divisions
// //           response = await fetch(
// //             `/api/articles/fetcharticle?division=${division}`
// //           );
// //         }
// //         const data = await response.json();
// //         console.log("Fetched articles:", data); // Log the data for debugging

// //         // Set total articles count for pagination
// //         setTotalArticles(data.length);

// //         // Pagination logic: Slice the fetched data based on the current page
// //         const startIndex = (currentPage - 1) * cardsPerPage;
// //         const paginatedData = data.slice(startIndex, startIndex + cardsPerPage);

// //         const formattedCards = paginatedData.map((article) => ({
// //           title: article.title,
// //           titleImage: article.titleImage, // Get the first image URL
// //           _id: article._id, // Add a link to the article
// //         }));

// //         setCards(formattedCards);
// //       } catch (error) {
// //         console.error("Error fetching articles:", error);
// //       }
// //     };

// //     fetchCards(); // Fetch articles based on the current division and page
// //   }, [division, currentPage]); // Re-fetch articles when division or currentPage changes

// //   // Pagination handler function
// //   const paginate = (pageNumber) => setCurrentPage(pageNumber);

// //   return (
// //     <div style={{ padding: "20px" }}>
// //       <h2>Articles for {division === "All" ? "everyone" : `${division}`}</h2>
// //       {/* Pass the cards data to the ArticleCard component */}
// //       <ArticleCard cards={cards} />

// //       {/* Pagination component */}
// //       <Pagination
// //         quizzesPerPage={cardsPerPage}
// //         totalQuizzes={totalArticles}
// //         currentPage={currentPage}
// //         paginate={paginate}
// //       />
// //     </div>
// //   );
// // }

// // export default ArticleCardPage;
// import React, { useEffect, useState } from "react";
// import { useLocation } from "react-router-dom";
// import ArticleCard from "../components/ArticleCard"; // Ensure this is the correct path
// import Pagination from "../components/Pagination"; // Import Pagination component

// function ArticleCardPage() {
//   const [cards, setCards] = useState([]);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [totalArticles, setTotalArticles] = useState(0);
//   const cardsPerPage = 8; // Display 8 articles per page
//   const location = useLocation();

//   // Extract the division from the query string (e.g., ?division=student)
//   const queryParams = new URLSearchParams(location.search);
//   const division = queryParams.get("division");

//   useEffect(() => {
//     const fetchCards = async () => {
//       try {
//         let response;
//         if (division === "All") {
//           response = await fetch("/api/articles/fetchallarticles");
//         } else {
//           response = await fetch(
//             `/api/articles/fetcharticle?division=${division}`
//           );
//         }
//         const data = await response.json();

//         // Set total articles count for pagination
//         setTotalArticles(data.length);

//         // Pagination logic: Slice the fetched data based on the current page
//         const startIndex = (currentPage - 1) * cardsPerPage;
//         const paginatedData = data.slice(startIndex, startIndex + cardsPerPage);

//         const formattedCards = paginatedData.map((article) => ({
//           title: article.title,
//           titleImage: article.titleImage,
//           _id: article._id,
//         }));

//         setCards(formattedCards);
//       } catch (error) {
//         console.error("Error fetching articles:", error);
//       }
//     };

//     fetchCards();
//   }, [division, currentPage]);

//   // Pagination handler function
//   const paginate = (pageNumber) => setCurrentPage(pageNumber);

//   return (
//     <div style={{ padding: "20px" }}>
//       <h2
//         style={{ textAlign: "center", fontSize: "24px", marginBottom: "20px" }}
//       >
//         Articles for {division === "All" ? "Everyone" : `${division}`}
//       </h2>

//       {/* Grid Container for Articles */}
//       <div
//         style={{
//           display: "grid",
//           gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))",
//           gap: "20px",
//         }}
//       >
//         {cards.map((card, index) => (
//           <div
//             key={index}
//             style={{
//               width: "400px",
//               height: "600px",
//               backgroundColor: "#fff",
//               boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
//               borderRadius: "8px",
//               overflow: "hidden",
//               display: "flex",
//               flexDirection: "column",
//             }}
//           >
//             <ArticleCard cards={[card]} />
//           </div>
//         ))}
//       </div>

//       {/* Pagination Component */}
//       <div
//         style={{ marginTop: "20px", display: "flex", justifyContent: "center" }}
//       >
//         <Pagination
//           quizzesPerPage={cardsPerPage}
//           totalQuizzes={totalArticles}
//           currentPage={currentPage}
//           paginate={paginate}
//         />
//       </div>
//     </div>
//   );
// }

// export default ArticleCardPage;
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ArticleCard from "../components/ArticleCard"; // Ensure this is the correct path
import Pagination from "../components/Pagination"; // Import Pagination component

function ArticleCardPage() {
  const [cards, setCards] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalArticles, setTotalArticles] = useState(0);
  const cardsPerPage = 10; // Display 8 articles per page
  const location = useLocation();

  // Extract the division from the query string (e.g., ?division=student)
  const queryParams = new URLSearchParams(location.search);
  const division = queryParams.get("division");

  useEffect(() => {
    const fetchCards = async () => {
      try {
        let response;
        if (division === "All") {
          response = await fetch("/api/articles/fetchallarticles");
        } else {
          response = await fetch(
            `/api/articles/fetcharticle?division=${division}`
          );
        }
        const data = await response.json();

        // Set total articles count for pagination
        setTotalArticles(data.length);

        // Pagination logic: Slice the fetched data based on the current page
        const startIndex = (currentPage - 1) * cardsPerPage;
        const paginatedData = data.slice(startIndex, startIndex + cardsPerPage);

        const formattedCards = paginatedData.map((article) => ({
          title: article.title,
          titleImage: article.titleImage,
          _id: article._id,
        }));

        setCards(formattedCards);
      } catch (error) {
        console.error("Error fetching articles:", error);
        setCards([]); // Ensure empty array on error
      }
    };

    fetchCards();
  }, [division, currentPage]);

  // Pagination handler function
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div style={{ padding: "10px" }}>
      <h2
        style={{ textAlign: "center", fontSize: "24px", marginBottom: "20px" }}
      >
        Articles for {division === "All" ? "Everyone" : `${division}`}
      </h2>

      {/* Grid Container for Articles */}
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fill, minmax(250px, 1fr))", // Ensure no horizontal scroll
          gap: "20px",
        }}
      >
        {cards.length === 0 ? (
          <div>No articles found.</div> // Fallback message if no articles are available
        ) : (
          cards.map((card, index) => (
            <div
              key={index}
              style={{
                backgroundColor: "#fff",
                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                borderRadius: "8px",
                overflow: "hidden",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <ArticleCard cards={[card]} />
            </div>
          ))
        )}
      </div>

      {/* Pagination Component */}
      <div
        style={{ marginTop: "20px", display: "flex", justifyContent: "center" }}
      >
        <Pagination
          quizzesPerPage={cardsPerPage}
          totalQuizzes={totalArticles}
          currentPage={currentPage}
          paginate={paginate}
        />
      </div>
    </div>
  );
}

export default ArticleCardPage;
