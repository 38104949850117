import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { getQuizById } from "../actions/quizActions";
import { useParams } from "react-router-dom";
import { getAllSubjects } from "../actions/subjectActions";
import { getAllDifficultys } from "../actions/difficultyActions";
import { getAllClassss } from "../actions/classsActions";

const Editquiz = () => {
  const { quizid } = useParams();
  const dispatch = useDispatch();

  const [selectedQuestionIndex, setSelectedQuestionIndex] = useState(0);
  const [editedQuiz, setEditedQuiz] = useState({
    name: "",
    difficulty: "",
    classs: "",
    subject: "",
    division: "",
    chapter: "",
    quiz_image: null,
    questions: [],
  });
  const [file, setFile] = useState(null);
  const [questionFile, setQuestionFile] = useState(null);
  const [answerFile, setAnswerFile] = useState(null);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [chapters, setChapters] = useState([]);
  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState({}); // Track upload status for each field

  const quizDetails = useSelector((state) => state.getQuizByIdReducer);
  const { quiz } = quizDetails;

  const subjectsState = useSelector((state) => state.getAllSubjectsReducer);
  const { subjects } = subjectsState;

  const difficultysState = useSelector(
    (state) => state.getAllDifficultysReducer
  );
  const { difficultys } = difficultysState;

  const classssState = useSelector((state) => state.getAllClassssReducer);
  const { classss } = classssState;

  const userstate = useSelector((state) => state.loginUserReducer);
  const { currentUser1 } = userstate;
  useEffect(() => {
    const fetchChapters = async () => {
      const { division, subject, classs } = editedQuiz;
      if (division && subject && classs) {
        try {
          const response = await axios.get(
            `/api/chapters/fetchchapters?division=${division}&subject=${subject}&classs=${classs}`
          );
          setChapters(Array.isArray(response.data) ? response.data : []);
        } catch (error) {
          console.error("Error fetching chapters:", error);
        }
      } else {
        setChapters([]);
      }
    };

    fetchChapters();
  }, [editedQuiz.division, editedQuiz.subject, editedQuiz.classs]); // Ensure dependencies match

  useEffect(() => {
    dispatch(getQuizById(quizid));
    dispatch(getAllSubjects());
    dispatch(getAllDifficultys());
    dispatch(getAllClassss());
  }, [dispatch, quizid]);

  useEffect(() => {
    if (quiz) {
      setEditedQuiz({
        name: quiz.name,
        difficulty: quiz.difficulty,
        classs: quiz.classs,
        subject: quiz.subject,
        chapter: quiz.chapter || "",
        division: quiz.division,
        quiz_image: quiz.quiz_image,
        questions: quiz.questions,
      });
    }
  }, [quiz]);

  const handleQuizChange = (field, value) => {
    setEditedQuiz((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };
  const handleFileChange = (e, field, questionIndex = null) => {
    const file = e.target.files[0];
    if (file) {
      handleImageUpload(file, field, questionIndex);
    }
  };

  const handleQuestionChange = (index, field, value) => {
    const updatedQuestions = [...editedQuiz.questions];
    updatedQuestions[index][field] = value;
    setEditedQuiz((prev) => ({ ...prev, questions: updatedQuestions }));
  };
  const handleImageUpload = async (file, field, questionIndex = null) => {
    setUploading((prev) => ({ ...prev, [field]: true })); // Start loader for the field
    const formData = new FormData();
    formData.append("image", file);

    try {
      const response = await axios.post("/api/quizs/upload", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      const { imageUrl } = response.data;
      if (questionIndex !== null) {
        handleQuestionChange(questionIndex, field, imageUrl);
      } else {
        setEditedQuiz((prev) => ({ ...prev, [field]: imageUrl }));
      }

      alert("Image uploaded successfully!");
    } catch (error) {
      console.error("Error uploading image:", error);
      alert("Failed to upload image. Please try again.");
    } finally {
      setUploading((prev) => ({ ...prev, [field]: false })); // Stop loader
    }
  };

  const handleOptionChangeHindi = (questionIndex, optionIndex, value) => {
    const updatedQuestions = [...editedQuiz.questions];

    // Ensure options_hindi is initialized
    if (!Array.isArray(updatedQuestions[questionIndex].options_hindi)) {
      updatedQuestions[questionIndex].options_hindi = ["", "", "", ""];
    }

    // Update the specific option
    updatedQuestions[questionIndex].options_hindi[optionIndex] = value;

    // Update state
    setEditedQuiz((prevState) => ({
      ...prevState,
      questions: updatedQuestions,
    }));
  };

  const handleOptionChange = (questionIndex, optionIndex, value) => {
    const updatedQuestions = [...editedQuiz.questions];
    updatedQuestions[questionIndex].options[optionIndex] = value;
    setEditedQuiz((prevState) => ({
      ...prevState,
      questions: updatedQuestions,
    }));
  };

  const handleQuestionFileChange = (e, field) => {
    if (field === "question_image") {
      setQuestionFile(e.target.files[0]);
    } else if (field === "answer_image") {
      setAnswerFile(e.target.files[0]);
    }
  };
  const submitHandler = async (e) => {
    e.preventDefault();

    try {
      const quizData = {
        _id: quizid,
        name: editedQuiz.name,
        difficulty: editedQuiz.difficulty,
        classs: editedQuiz.classs,
        division: editedQuiz.division,
        subject: editedQuiz.subject,
        chapter: editedQuiz.chapter,
        questions: JSON.stringify(editedQuiz.questions), // Serialize to JSON
      };

      const response = await axios.post("/api/quizs/editquiz", quizData);
      alert("Quiz updated successfully!");
      console.log("Response:", response.data);
    } catch (error) {
      console.error("Error submitting quiz:", error);
      alert("Failed to update the quiz. Please try again.");
    }
  };

  // Function to add a new question
  const addNewQuestion = async () => {
    const newQuestion = {
      question: "",
      question_in_hindi: "",
      question_image: null,
      options: ["", "", "", ""],
      options_hindi: ["", "", "", ""],
      correct_answer: "",
      correct_answer_hindi: "",
      answer_explanation_in_english: "",
      answer_explanation_in_hindi: "",
      answer_image: null,
    };
    try {
      await axios.post("/api/quizs/addquestionedit", {
        quizId: quizid,
        newQuestion,
      });
      setEditedQuiz((prevState) => ({
        ...prevState,
        questions: [...prevState.questions, newQuestion],
      }));
      alert("New Question Added Successfully");
    } catch (error) {
      console.error(error);
    }
  };

  // Function to delete a question
  const deleteQuestion = async (index) => {
    try {
      await axios.post("/api/quizs/deletequestion", {
        quizId: quizid,
        questionIndex: index,
      });
      const updatedQuestions = editedQuiz.questions.filter(
        (q, i) => i !== index
      );
      setEditedQuiz((prevState) => ({
        ...prevState,
        questions: updatedQuestions,
      }));
      alert("Selected Question Deleted Successfully");
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="container">
      <hr />
      <h1>Edit Quiz</h1>
      <hr />
      <form onSubmit={submitHandler} encType="multipart/form-data">
        <div className="form-group">
          <label>Name</label>
          <input
            type="text"
            className="form-control"
            placeholder="Enter quiz name"
            value={editedQuiz.name}
            onChange={(e) => handleQuizChange("name", e.target.value)}
          />
        </div>

        <div className="form-group">
          <label>Category</label>
          <select
            className="form-control"
            value={editedQuiz.difficulty}
            onChange={(e) => handleQuizChange("difficulty", e.target.value)}
          >
            <option value="">Select Category</option>
            {difficultys &&
              difficultys.map((diff) => (
                <option key={diff._id} value={diff.diff}>
                  {diff.diff}
                </option>
              ))}
          </select>
        </div>

        <div className="form-group">
          <label>Class</label>
          <select
            className="form-control"
            value={editedQuiz.classs}
            onChange={(e) => handleQuizChange("classs", e.target.value)}
          >
            <option value="">Select Class</option>
            {classss &&
              classss.map((cls) => (
                <option key={cls._id} value={cls.cls}>
                  {cls.cls}
                </option>
              ))}
          </select>
        </div>

        <div className="form-group">
          <label>Division</label>
          <select
            className="form-control"
            value={editedQuiz.division}
            onChange={(e) => handleQuizChange("division", e.target.value)}
          >
            <option value="Udbodh">Udbodh</option>
            <option value="Bauddha Dakshata">Bauddha Dakshata</option>
            <option value="Abhyudaya">Abhyudaya</option>
          </select>
        </div>

        <div className="form-group">
          <label>Subject</label>
          <select
            className="form-control"
            value={editedQuiz.subject}
            onChange={(e) => handleQuizChange("subject", e.target.value)}
          >
            <option value="">Select Subject</option>
            {subjects &&
              subjects.map((sub) => (
                <option key={sub._id} value={sub.sub}>
                  {sub.sub}
                </option>
              ))}
          </select>
        </div>

        <div className="form-group">
          <label>Chapter</label>
          <select
            className="form-control"
            value={editedQuiz.chapter}
            onChange={(e) => handleQuizChange("chapter", e.target.value)}
          >
            <option value="">Select Chapter</option>
            {chapters &&
              chapters.map((chap) => (
                <option key={chap._id} value={chap.chapter}>
                  {chap.chapter}
                </option>
              ))}
          </select>
        </div>

        <div className="form-group">
          <label>Upload Quiz Image</label>
          <input
            type="file"
            className="form-control-file"
            onChange={(e) => handleFileChange(e, "quiz_image")}
          />
          {uploading.quiz_image && <p>Uploading quiz image...</p>}
        </div>

        <div className="form-group">
          <label>Select Question to Edit</label>
          <select
            className="form-control"
            value={selectedQuestionIndex}
            onChange={(e) => setSelectedQuestionIndex(Number(e.target.value))}
          >
            {editedQuiz.questions.map((question, index) => (
              <option key={index} value={index}>
                Question {index + 1}
              </option>
            ))}
          </select>
        </div>

        {editedQuiz.questions[selectedQuestionIndex] && (
          <div className="mb-3">
            <label>Question {selectedQuestionIndex + 1}</label>
            <input
              type="text"
              className="form-control mb-2"
              placeholder={`Enter question ${selectedQuestionIndex + 1}`}
              value={editedQuiz.questions[selectedQuestionIndex].question || ""}
              onChange={(e) =>
                handleQuestionChange(
                  selectedQuestionIndex,
                  "question",
                  e.target.value
                )
              }
            />

            <input
              type="text"
              className="form-control mb-2"
              placeholder={`Enter question ${
                selectedQuestionIndex + 1
              } in Hindi`}
              value={
                editedQuiz.questions[selectedQuestionIndex].question_in_hindi ||
                ""
              }
              onChange={(e) =>
                handleQuestionChange(
                  selectedQuestionIndex,
                  "question_in_hindi",
                  e.target.value
                )
              }
            />

            <label>Question Passage {selectedQuestionIndex + 1}</label>
            <input
              type="text"
              className="form-control mb-2"
              placeholder={`Enter question passage ${
                selectedQuestionIndex + 1
              }`}
              value={
                editedQuiz.questions[selectedQuestionIndex]?.question_passage ||
                ""
              }
              onChange={(e) =>
                handleQuestionChange(
                  selectedQuestionIndex,
                  "question_passage",
                  e.target.value
                )
              }
            />

            <label>Question Passage in Hindi {selectedQuestionIndex + 1}</label>
            <input
              type="text"
              className="form-control mb-2"
              placeholder={`Enter question passage in hindi ${
                selectedQuestionIndex + 1
              }`}
              value={
                editedQuiz.questions[selectedQuestionIndex]
                  ?.question_passage_hindi || ""
              }
              onChange={(e) =>
                handleQuestionChange(
                  selectedQuestionIndex,
                  "question_passage_hindi",
                  e.target.value
                )
              }
            />

            <label>Passage Enable</label>
            <input
              type="checkbox"
              checked={
                editedQuiz.questions[selectedQuestionIndex].passage_Enable
              }
              onChange={(e) =>
                handleQuestionChange(
                  selectedQuestionIndex,
                  "passage_Enable",
                  e.target.checked
                )
              }
            />

            <label>Options</label>
            {(
              editedQuiz.questions[selectedQuestionIndex]?.options || [
                "",
                "",
                "",
                "",
              ]
            ).map((option, optionIndex) => (
              <input
                key={optionIndex}
                type="text"
                className="form-control mb-2"
                placeholder={`Enter option ${optionIndex + 1}`}
                value={option || ""}
                onChange={(e) =>
                  handleOptionChange(
                    selectedQuestionIndex,
                    optionIndex,
                    e.target.value
                  )
                }
              />
            ))}

            <div className="form-group">
              <label>Correct Answer</label>
              <select
                className="form-control"
                value={
                  editedQuiz.questions[selectedQuestionIndex]?.correct_answer ||
                  ""
                }
                onChange={(e) =>
                  handleQuestionChange(
                    selectedQuestionIndex,
                    "correct_answer",
                    e.target.value
                  )
                }
              >
                {(
                  editedQuiz.questions[selectedQuestionIndex]?.options || []
                ).map((option, optionIndex) => (
                  <option key={optionIndex} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>

            <label>Options in Hindi</label>
            {(
              editedQuiz.questions[selectedQuestionIndex]?.options_hindi || [
                "",
                "",
                "",
                "",
              ]
            ).map((option, optionIndex) => (
              <input
                key={optionIndex}
                type="text"
                className="form-control mb-2"
                placeholder={`Enter option ${optionIndex + 1} in Hindi`}
                value={option || ""}
                onChange={(e) =>
                  handleOptionChangeHindi(
                    selectedQuestionIndex,
                    optionIndex,
                    e.target.value
                  )
                }
              />
            ))}

            <div className="form-group">
              <label>Correct Answer in Hindi</label>
              <select
                className="form-control"
                value={
                  editedQuiz.questions[selectedQuestionIndex]
                    ?.correct_answer_hindi || ""
                }
                onChange={(e) =>
                  handleQuestionChange(
                    selectedQuestionIndex,
                    "correct_answer_hindi",
                    e.target.value
                  )
                }
              >
                {(
                  editedQuiz.questions[selectedQuestionIndex]
                    ?.options_hindi || ["", "", "", ""]
                ).map((option, optionIndex) => (
                  <option key={optionIndex} value={option || ""}>
                    {option || `Option ${optionIndex + 1}`}
                  </option>
                ))}
              </select>
            </div>

            <div className="form-group">
              <label>Question Image</label>
              <input
                type="file"
                className="form-control-file"
                onChange={(e) =>
                  handleFileChange(e, "question_image", selectedQuestionIndex)
                }
              />
              {uploading[`question_image_${selectedQuestionIndex}`] && (
                <p>Uploading question image...</p>
              )}
            </div>

            <input
              type="text"
              className="form-control mb-2"
              placeholder={`Enter answer explanation in English`}
              value={
                editedQuiz.questions[selectedQuestionIndex]
                  .answer_explanation_in_english || ""
              }
              onChange={(e) =>
                handleQuestionChange(
                  selectedQuestionIndex,
                  "answer_explanation_in_english",
                  e.target.value
                )
              }
            />

            <input
              type="text"
              className="form-control mb-2"
              placeholder={`Enter answer explanation in Hindi`}
              value={
                editedQuiz.questions[selectedQuestionIndex]
                  .answer_explanation_in_hindi || ""
              }
              onChange={(e) =>
                handleQuestionChange(
                  selectedQuestionIndex,
                  "answer_explanation_in_hindi",
                  e.target.value
                )
              }
            />

            <div className="form-group">
              <label>Answer Image</label>
              <input
                type="file"
                className="form-control-file"
                onChange={(e) =>
                  handleFileChange(e, "answer_image", selectedQuestionIndex)
                }
              />
              {uploading[`answer_image_${selectedQuestionIndex}`] && (
                <p>Uploading answer image...</p>
              )}
            </div>
          </div>
        )}

        <div className="form-group">
          <label>
            YouTube Video Link for Question {selectedQuestionIndex + 1}
          </label>
          <input
            type="text"
            className="form-control mb-2"
            placeholder={`Enter YouTube video link or ID for question ${
              selectedQuestionIndex + 1
            }`}
            value={
              editedQuiz.questions[selectedQuestionIndex]?.youtube_video || ""
            }
            onChange={(e) =>
              handleQuestionChange(
                selectedQuestionIndex,
                "youtube_video",
                e.target.value
              )
            }
          />
        </div>

        <button type="submit" className="btn btn-primary" disabled={loading}>
          {loading ? "Submitting..." : "Submit"}
        </button>

        {loading && (
          <p className="text-info mt-2">
            Submitting your changes, please wait...
          </p>
        )}

        <button onClick={addNewQuestion} className="btn btn-secondary ml-2">
          Add New Question
        </button>

        {currentUser1.isAdmin ? (
          <button
            onClick={() => deleteQuestion(selectedQuestionIndex)}
            className="btn btn-danger ml-2"
          >
            Delete Question
          </button>
        ) : null}
      </form>
    </div>
  );
};

export default Editquiz;
