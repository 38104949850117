// // // import React, { useRef, useEffect } from "react";
// // // import "./LearningOutcomes.css";

// // // const data = [
// // //   { title: "Career Progressions", count: 20, color: "gray" },
// // //   { title: "Problem Solving Skills", count: 30, color: "red" },
// // //   { title: "Critical thinking abilities", count: 25, color: "orange" },
// // //   { title: "Connecting the dots", count: 22, color: "blue" },
// // //   { title: "Mental Agility and Creativity", count: 28, color: "lightblue" },
// // //   { title: "Informed Decision Making", count: 26, color: "green" },
// // // ];

// // // function LearningOutcomes() {
// // //   const timelineRef = useRef([]);

// // //   useEffect(() => {
// // //     const observer = new IntersectionObserver(
// // //       (entries) => {
// // //         entries.forEach((entry) => {
// // //           if (entry.isIntersecting) {
// // //             entry.target.classList.add("animate");
// // //             observer.unobserve(entry.target);
// // //           }
// // //         });
// // //       },
// // //       { threshold: 0.5 } // Trigger when 50% visible
// // //     );

// // //     // Observe each timeline item
// // //     timelineRef.current.forEach((item) => observer.observe(item));

// // //     return () => {
// // //       observer.disconnect();
// // //     };
// // //   }, []);

// // //   return (
// // <div className="learning-outcomes">
// //   <h2
// //     style={{
// //       textAlign: "center",
// //       fontFamily: '"Libre Baskerville", serif',
// //       fontSize: "2em",
// //       fontWeight: "bold",
// //       marginTop: "5vh",
// //       marginBottom: "2vh",
// //     }}
// //   >
// //     Learning Outcomes
// //   </h2>
// //   <p
// //     style={{
// //       fontFamily: '"Libre Baskerville", serif',
// //       fontWeight: "bold",
// //       textAlign: "center",
// //       marginBottom: "20px",
// //     }}
// //   >
// //     Well-rounded individual with a broad understanding of the world & the
// //     ability to think critically & solve problems effectively.
// //   </p>

// //   {/* Timeline Container */}
// //   <div className="timeline">
// //     {data.map((item, index) => (
// //       <div
// //         key={index}
// //         ref={(el) => (timelineRef.current[index] = el)}
// //         className="timeline-item"
// //         style={{
// //           borderLeft: `4px solid ${item.color}`,
// //           paddingLeft: "20px",
// //           marginBottom: "30px",
// //           opacity: 0, // Initially hidden, will animate in
// //         }}
// //       >
// //         <h3
// //           style={{
// //             fontSize: "1.5em",
// //             color: item.color,
// //           }}
// //         >
// //           {item.title}
// //         </h3>
// //         <p>{`Count: ${item.count}`}</p>
// //       </div>
// //     ))}
// //   </div>
// // </div>
// // //   );
// // // }

// // // export default LearningOutcomes;
// // import React, { useRef, useEffect, useState } from "react";
// // import "./LearningOutcomes.css";

// // function LearningOutcomes() {
// //   const headingRef = useRef(null);
// //   const bubblesRef = useRef([]);
// //   const [isVisible, setIsVisible] = useState(false);

// //   const data = [
// //     {
// //       title: "Career Progressions",
// //       color: "#389e2d",
// //       image: "student.gif",
// //     },
// //     {
// //       title: "Problem Solving Skills",
// //       color: "#1E90FF",
// //       image: "Puzzle.gif",
// //     },
// //     {
// //       title: "Critical thinking abilities",
// //       color: "#FF8C00",
// //       image: "Imagination.gif",
// //     },
// //     {
// //       title: "Connecting the dots",
// //       color: "#FF6347",
// //       image: "Imagination.gif",
// //     },
// //     {
// //       title: "Mental Agility and Creativity",
// //       color: "#98C7FF",
// //       image: "Imagination.gif",
// //     },
// //     {
// //       title: "Informed Decision Making",
// //       color: "#32CD32",
// //       image: "Imagination.gif",
// //     },
// //   ];

// //   useEffect(() => {
// //     const observer = new IntersectionObserver(
// //       (entries) => {
// //         entries.forEach((entry) => {
// //           if (entry.isIntersecting) {
// //             setIsVisible(true);
// //             entry.target.classList.add("animate");
// //             observer.unobserve(entry.target);
// //           }
// //         });
// //       },
// //       { threshold: 0.5 }
// //     );

// //     if (headingRef.current) {
// //       observer.observe(headingRef.current);
// //     }
// //     bubblesRef.current.forEach((bubble) => bubble && observer.observe(bubble));

// //     return () => observer.disconnect();
// //   }, []);

// //   return (
// // <div>
// //   <h2
// //     ref={headingRef}
// //     style={{
// //       textAlign: "center",
// //       fontFamily: '"Libre Baskerville", serif',
// //       fontSize: "2em",
// //       fontWeight: "bold",
// //       marginTop: "5vh",
// //       marginBottom: "2vh",
// //       width: "100%",
// //     }}
// //   >
// //     Program Design
// //   </h2>
// //   <div className="bubbles-container">
// //     {data.map((item, index) => (
// //       <div
// //         key={item.title}
// //         ref={(el) => (bubblesRef.current[index] = el)}
// //         className={`bubble bubble-${index + 1}`}
// //         style={{
// //           backgroundColor: item.color,
// //           opacity: isVisible ? 0.75 : 0,
// //           visibility: isVisible ? "visible" : "hidden",
// //         }}
// //       >
// //         <div className="image-wrapper">
// //           <img src={item.image} alt={item.title} />
// //         </div>
// //         <div className="bubble-title">
// //           <h3>{item.title}</h3>
// //         </div>
// //       </div>
// //     ))}
// //   </div>
// //   <br />
// // </div>
// //   );
// // }

// // export default LearningOutcomes;
// import React, { useRef, useEffect, useState } from "react";
// import "./LearningOutcomes.css";

// function LearningOutcomes() {
//   const headingRef = useRef(null);
//   const bubblesRef = useRef([]);
//   const [isVisible, setIsVisible] = useState(false);
//   const [isMobile, setIsMobile] = useState(false);

//   const data = [
//     {
//       title: " Academic Excellence-Deeper and Broadened Subject Knowledge",
//       image: "student.gif",
//     },
//     { title: "Problem Solving Skills", image: "Puzzle.gif" },
//     { title: "Critical Thinking Abilities", image: "Imagination.gif" },
//     {
//       title: "Competitive Readiness: Get set for any exam",
//       image: "Imagination.gif",
//     },
//     { title: "Mental Agility and Creativity", image: "Imagination.gif" },
//     {
//       title:
//         "Academic rigour Ensuring dedicated studies in a time bound manner",
//       image: "Imagination.gif",
//     },
//   ];

//   useEffect(() => {
//     // Detect if the device is mobile
//     const handleResize = () => {
//       setIsMobile(window.innerWidth <= 768); // Adjust breakpoint as needed
//     };

//     handleResize(); // Initial check
//     window.addEventListener("resize", handleResize);

//     const observer = new IntersectionObserver(
//       (entries) => {
//         entries.forEach((entry) => {
//           if (entry.isIntersecting) {
//             setTimeout(() => setIsVisible(true), 1500); // Delay for visibility
//             entry.target.classList.add("animate");
//             observer.unobserve(entry.target);
//           }
//         });
//       },
//       { threshold: 0.5 }
//     );

//     if (headingRef.current) {
//       observer.observe(headingRef.current);
//     }
//     bubblesRef.current.forEach((bubble) => bubble && observer.observe(bubble));

//     return () => {
//       observer.disconnect();
//       window.removeEventListener("resize", handleResize);
//     };
//   }, []);

//   return isMobile ? (
//     // Mobile View
//     <div>
//       <h2
//         ref={headingRef}
//         style={{
//           textAlign: "center",
//           fontFamily: '"Libre Baskerville", serif',
//           fontSize: "1.5em",
//           fontWeight: "bold",
//           marginTop: "3vh",
//           marginBottom: "2vh",
//         }}
//       >
//         Learning Outcomes
//       </h2>
//       <div className="bubbles-container-mobile">
//         {data.map((item, index) => (
//           <div
//             key={item.title}
//             ref={(el) => (bubblesRef.current[index] = el)}
//             className={`bubble-mobile bubble-${index + 1}`}
//             style={{
//               opacity: isVisible ? 1 : 0,
//               visibility: isVisible ? "visible" : "hidden",
//             }}
//           >
//             <div className="image-wrapper-mobile">
//               <img
//                 src={item.image}
//                 alt={item.title}
//                 style={{
//                   width: "80%", // Adjust image size for mobile
//                   height: "auto",
//                   objectFit: "contain",
//                 }}
//               />
//             </div>
//             <div className="bubble-title">
//               <h4 style={{ fontSize: "1rem", textAlign: "center" }}>
//                 {item.title}
//               </h4>
//             </div>
//           </div>
//         ))}
//       </div>
//     </div>
//   ) : (
//     // Desktop View
//     <div>
//       <h2
//         ref={headingRef}
//         style={{
//           textAlign: "center",
//           fontFamily: '"Libre Baskerville", serif',
//           fontSize: "2em",
//           fontWeight: "bold",
//           marginTop: "5vh",
//           marginBottom: "2vh",
//           width: "100%",
//         }}
//       >
//         Learning Outcomes
//       </h2>
//       <div className="bubbles-container">
//         {data.map((item, index) => (
//           <div
//             key={item.title}
//             ref={(el) => (bubblesRef.current[index] = el)}
//             className={`bubble bubble-${index + 1}`}
//             style={{
//               backgroundColor: item.color,
//               opacity: isVisible ? 0.75 : 0,
//               visibility: isVisible ? "visible" : "hidden",
//             }}
//           >
//             <div className="image-wrapper">
//               <img src={item.image} alt={item.title} />
//             </div>
//             <div className="bubble-title">
//               <h3>{item.title}</h3>
//             </div>
//           </div>
//         ))}
//       </div>
//       <br />
//     </div>
//   );
// }

// export default LearningOutcomes;
import React, { useRef, useEffect, useState } from "react";
import "./LearningOutcomes.css";

function LearningOutcomes() {
  const headingRef = useRef(null);
  const bubblesRef = useRef([]);
  const [isVisible, setIsVisible] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const data = [
    {
      title: "Academic Excellence - Deeper and Broadened Subject Knowledge",
      image: "gif3.gif",
    },
    { title: "Problem Solving Skills", image: "gif6.gif" },
    { title: "Critical Thinking Abilities", image: "gif2.gif" },
    {
      title: "Competitive Readiness: Get set for any exam",
      image: "gif5.gif",
    },
    { title: "Mental Agility and Creativity", image: "gif1.gif" },
    {
      title:
        "Academic Rigour Ensuring Dedicated Studies in a Time-Bound Manner",
      image: "gif4.gif",
    },
  ];

  useEffect(() => {
    // Detect if the device is mobile
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Adjust breakpoint as needed
    };

    handleResize(); // Initial check
    window.addEventListener("resize", handleResize);

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setTimeout(() => setIsVisible(true), 500); // Delay for visibility
            entry.target.classList.add("animate");
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.5 }
    );

    if (headingRef.current) {
      observer.observe(headingRef.current);
    }
    bubblesRef.current.forEach((bubble) => bubble && observer.observe(bubble));

    return () => {
      observer.disconnect();
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div>
      <h2
        ref={headingRef}
        style={{
          textAlign: "center",
          fontFamily: '"Libre Baskerville", serif',
          fontSize: isMobile ? "2em" : "3rem",
          fontWeight: "bold",
          marginTop: "5vh",
          marginBottom: "2vh",
        }}
      >
        LEARNING OUTCOMES
      </h2>
      <div
        className={isMobile ? "bubbles-container-mobile" : "bubbles-container"}
      >
        {data.map((item, index) => (
          <div
            key={item.title}
            ref={(el) => (bubblesRef.current[index] = el)}
            className={`bubble ${
              isMobile ? "bubble-mobile" : `bubble-${index + 1}`
            }`}
            style={{
              opacity: isVisible ? 1 : 0,
              visibility: isVisible ? "visible" : "hidden",
            }}
          >
            <div
              className="image-wrapper"
              style={{
                boxShadow: "0 8px 15px rgba(0, 0, 0, 0.3)", // Shadow behind the image
                borderRadius: "10px", // Optional for rounded corners
              }}
            >
              <img
                src={item.image}
                alt={item.title}
                style={{
                  width: isMobile ? "80%" : "100%",
                  height: "auto",
                  objectFit: "contain",
                  transition: "transform 0.3s ease",
                }}
              />
            </div>
            <div className="bubble-title">
              <h4 style={{ textAlign: "center" }}>{item.title}</h4>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default LearningOutcomes;
