// // import React, { useState } from "react";

// // function StudentLearning() {
// //   const [isVideoOpen, setIsVideoOpen] = useState(false);

// //   const handleButtonClick = () => {
// //     alert("Button clicked!");
// //   };

// //   return (
// // <div style={{ position: "relative", height: "80vh", overflow: "hidden" }}>
// //   {/* Navbar */}
// //   <nav
// //     style={{
// //       backgroundColor: "#0e003d", // Blue background
// //       color: "white", // White text
// //       padding: "15px 20px",
// //       position: "sticky",
// //       top: 0,
// //       zIndex: 1, // Reduced z-index
// //       textAlign: "center",
// //       boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)", // Add shadow for depth
// //     }}
// //   >
// //     <h2
// //       style={{
// //         fontSize: "1.8rem",
// //         fontWeight: "bold",
// //         letterSpacing: "1px",
// //         margin: 0,
// //         textShadow: "2px 2px 4px rgba(0, 0, 0, 0.3)",
// //         // Add subtle text shadow
// //         fontFamily: '"Libre Baskerville", serif',
// //       }}
// //     >
// //       Student Centric Classroom Program: In Partnership with Schools
// //     </h2>
// //   </nav>

// //   {/* Main Content */}
// //   <div
// //     className="student-learning-container"
// //     style={{
// //       display: "flex",
// //       flexDirection: "row",
// //       overflowY: "auto",
// //       height: "calc(100vh - 60px)", // Adjust height to fit below the navbar
// //       backgroundColor: "white",
// //       padding: "20px",
// //       gap: "20px", // Space between left and right sections
// //     }}
// //   >
// //     {/* Left Side: Image */}
// //     <div
// //       className="left-side"
// //       style={{
// //         flex: 1, // Equal size for left and right sections
// //         display: "flex",
// //         justifyContent: "center",
// //         alignItems: "center",
// //       }}
// //     >
// //       <img
// //         src="/StudentLearning.png" // Replace with the relevant image for the student-centric program
// //         alt="Student Centric Classroom"
// //         style={{
// //           width: "100%",
// //           height: "auto",
// //           maxHeight: "500px",
// //           borderRadius: "10px", // Add rounded corners
// //         }}
// //       />
// //     </div>

// //     {/* Right Side: Text and Link */}
// //     <div
// //       className="right-side"
// //       style={{
// //         flex: 1, // Equal size for left and right sections
// //         padding: "20px",
// //         textAlign: "left", // Align text to the left
// //         fontFamily: '"Libre Baskerville", serif',
// //       }}
// //     >
// //       <strong
// //         style={{
// //           fontSize: "1.2rem",
// //           marginBottom: "15px",
// //           display: "block",
// //           color: "#0e003d",
// //         }}
// //       >
// //         Gyankosha’s Student Centric Classroom Program:
// //       </strong>
// //       <h2
// //         style={{
// //           fontSize: "1.5rem",
// //           fontWeight: "bold",
// //           marginBottom: "20px",
// //           color: "#333",
// //         }}
// //       >
// //         Designed to place students at the heart of the learning experience,
// //         fostering engagement, curiosity, and critical thinking.{" "}
// //       </h2>
// //       <p
// //         style={{
// //           fontSize: "1rem",
// //           marginBottom: "20px",
// //           lineHeight: "1.6",
// //         }}
// //       >
// //         <ul style={{ paddingLeft: "20px" }}>
// //           <li>
// //             Delivered through interactive sessions in partnership with
// //             schools, this program focuses on skill-building beyond
// //             textbooks, covering areas such as Mental Abilities, blended
// //             school subject and GK( Hybrid Engagement), financial literacy,
// //             social skills etc.
// //           </li>
// //           <li>
// //             By encouraging active participation, collaboration, and
// //             personalized learning, Gyankosha helps students develop
// //             confidence, creativity, and a growth mindset.{" "}
// //           </li>
// //           <li>
// //             This approach not only enhances academic performance but also
// //             prepares students to excel in competitive exams and face
// //             real-world challenges with resilience and adaptability
// //           </li>
// //         </ul>
// //       </p>
// //       <a
// //         href="#"
// //         className="video-link"
// //         style={{
// //           color: "#0e003d",
// //           textDecoration: "underline",
// //           fontSize: "1rem",
// //           display: "block",
// //           marginBottom: "20px",
// //         }}
// //         onClick={(e) => {
// //           e.preventDefault();
// //           setIsVideoOpen(true);
// //         }}
// //       >
// //         Watch Video
// //       </a>
// //       <button
// //         className="action-button"
// //         onClick={handleButtonClick}
// //         style={{
// //           backgroundColor: "#0e003d",
// //           color: "white",
// //           padding: "10px 20px",
// //           border: "none",
// //           borderRadius: "5px",
// //           cursor: "pointer",
// //           fontSize: "1rem",
// //         }}
// //       >
// //         Question? Contact Us
// //       </button>
// //     </div>
// //   </div>

// //   {/* Video Popup */}
// //   {isVideoOpen && (
// //     <div
// //       className="video-modal"
// //       style={{
// //         position: "fixed",
// //         top: 0,
// //         left: 0,
// //         right: 0,
// //         bottom: 0,
// //         backgroundColor: "rgba(0, 0, 0, 0.7)",
// //         zIndex: 10,
// //         display: "flex",
// //         justifyContent: "center",
// //         alignItems: "center",
// //       }}
// //     >
// //       <div
// //         className="video-content"
// //         style={{
// //           position: "relative",
// //           backgroundColor: "white",
// //           padding: "20px",
// //           borderRadius: "10px",
// //           boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)",
// //         }}
// //       >
// //         <button
// //           className="close-button"
// //           onClick={() => setIsVideoOpen(false)}
// //           style={{
// //             position: "absolute",
// //             top: "10px",
// //             right: "10px",
// //             fontSize: "30px",
// //             color: "#333",
// //             background: "none",
// //             border: "none",
// //             cursor: "pointer",
// //           }}
// //         >
// //           &times;
// //         </button>
// //         <iframe
// //           width="560"
// //           height="315"
// //           src="https://www.youtube.com/embed/dQw4w9WgXcQ" // Replace with the actual video URL
// //           title="YouTube video player"
// //           frameBorder="0"
// //           allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
// //           allowFullScreen
// //           style={{
// //             maxWidth: "100%",
// //             maxHeight: "100%",
// //             borderRadius: "10px",
// //           }}
// //         ></iframe>
// //       </div>
// //     </div>
// //   )}
// // </div>
// //   );
// // }

// // export default StudentLearning;
// import React, { useEffect, useState } from "react";

// function StudentLearning() {
//   const [isVideoOpen, setIsVideoOpen] = useState(false);
//   const [screenSize, setScreenSize] = useState("desktop"); // Default to desktop

//   useEffect(() => {
//     const handleResize = () => {
//       const width = window.innerWidth;
//       if (width <= 600) {
//         setScreenSize("mobile");
//       } else if (width <= 1000) {
//         setScreenSize("tablet");
//       } else {
//         setScreenSize("desktop");
//       }
//     };

//     window.addEventListener("resize", handleResize);
//     handleResize(); // Initialize state on load

//     return () => {
//       window.removeEventListener("resize", handleResize);
//     };
//   }, []);

//   const handleButtonClick = () => {
//     const footer = document.getElementById("FooterInfo"); // Select the footer section
//     if (footer) {
//       footer.scrollIntoView({ behavior: "smooth" }); // Smoothly scroll to the footer
//     }
//   };

//   return screenSize === "tablet" || screenSize === "mobile" ? (
//     <div
//       style={{
//         // padding: "10px",
//         backgroundColor: "#f9f9f9",
//         fontFamily: '"Libre Baskerville", serif',
//         textAlign: "justify",
//         height: "auto",
//         maxHeight:
//           screenSize === "mobile"
//             ? "1100px"
//             : screenSize === "tablet"
//             ? "1600px"
//             : "1100px", // Adjust line height based on screen size"1200px",
//         lineHeight:
//           screenSize === "mobile"
//             ? "1.2"
//             : screenSize === "tablet"
//             ? "1.1"
//             : "1.5",
//       }}
//     >
//       {/* Navbar */}
//       <nav
//         style={{
//           backgroundColor: "#0e003d",
//           color: "white",
//           padding: "15px 20px",
//           position: "sticky",
//           zIndex: 1,
//           textAlign: "center",

//           boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
//           width: "100vw",
//         }}
//       >
//         <h2
//           style={{
//             fontSize: "2.0rem",
//             fontWeight: "bold",
//             letterSpacing: "1px",
//             //  margin: 0,
//             textShadow: "2px 2px 4px rgba(0, 0, 0, 0.3)",
//             fontFamily: '"Libre Baskerville", serif',
//           }}
//         >
//           Student Centric Classroom Program
//         </h2>
//       </nav>

//       {/* Main Content */}
//       <div
//         className="student-container"
//         style={{
//           display: "block", // Mobile or desktop layout
//           flexDirection: "column",
//           // overflowY: "auto",
//           height: screenSize === "mobile" ? "150vh" : "calc(auto - 50px)",
//           backgroundColor: "white",
//           padding: "2vh",
//           gap: "20px",
//           height:
//             screenSize === "mobile"
//               ? "160vh"
//               : screenSize === "tablet"
//               ? "auto"
//               : "1100px",
//           maxHeight:
//             screenSize === "mobile"
//               ? "1100px"
//               : screenSize === "tablet"
//               ? "1500px"
//               : "1100px", // Adjust line height based on screen size"1200px",
//           lineHeight:
//             screenSize === "mobile"
//               ? "1"
//               : screenSize === "tablet"
//               ? "1.1"
//               : "1.5",
//           justifyContent: screenSize === "mobile" ? "center" : " ",
//           //  alignItems:  screenSize === "mobile" ? "center" : " ",
//         }}
//       >
//         {/* Left Side: Image */}
//         {/* Image displayed first */}
//         <div style={{ marginTop: "20px", textAlign: "center" }}>
//           <img
//             src="/studentlearningg.png"
//             alt="Student Centric Classroom"
//             style={{
//               width:
//                 screenSize === "mobile"
//                   ? "90%"
//                   : screenSize === "tablet"
//                   ? "70%"
//                   : "80%",
//               height: "auto",
//               maxHeight: "600px",
//               marginBottom: "20px",
//             }}
//           />
//         </div>
//         {/* Right Side: Text and Content */}
//         {screenSize === "Tablet" ? (
//           <div
//             className="right-side"
//             style={{
//               flex: screenSize === "mobile" ? "1" : "1 1 15%",
//               padding: screenSize === "mobile" ? "5vh" : "20px",
//               textAlign: "left",
//               fontFamily: '"Libre Baskerville", serif',
//               fontSize: screenSize === "mobile" ? "1rem" : "1.2rem",
//               height: "auto",
//               maxHeight: screenSize === "mobile" ? "900px" : "1200px",
//               marginBottom: "2vh",
//               // marginInline: "-5px",
//               textAlign: "justify",
//             }}
//           >
//             <strong
//               style={{
//                 fontSize: "1.5rem",
//                 marginBottom: "5px",
//                 display: "block",
//                 color: "#0e003d",
//                 textAlign: "Left",
//               }}
//             >
//               Gyankosha’s Student Centric Classroom Program:
//             </strong>
//             <h2
//               style={{
//                 fontSize: "1.3rem",
//                 fontWeight: "bold",
//                 marginBottom: "10px",
//                 color: "#333",
//               }}
//             >
//               Designed to place students at the heart of the learning
//               experience.
//             </h2>
//             <ul
//               style={{
//                 fontSize: "1.2rem",
//                 paddingLeft: "20px",
//                 marginBottom: "10px",
//               }}
//             >
//               {" "}
//               <li>
//                 Delivered through interactive sessions in partnership with
//                 schools, this program focuses on skill-building beyond
//                 textbooks, covering areas such as Mental Abilities, blended
//                 school subjec( Hybrid Engagement), financial literacy, social
//                 skills etc.
//               </li>
//               <li>
//                 By encouraging active participation, collaboration, and
//                 personalized learning, Gyankosha helps students develop
//                 confidence, creativity, and a growth mindset.{" "}
//               </li>
//               <li>
//                 This approach not only enhances academic performance but also
//                 prepares students to excel in competitive exams and face
//                 real-world challenges with resilience and adaptability
//               </li>
//             </ul>
//             <a
//               href="#"
//               style={{
//                 color: "#0e003d",
//                 textDecoration: "underline",
//                 fontSize: "1rem",
//                 display: "block",
//                 marginBottom: "10px",
//                 marginTop: "5px",
//               }}
//               onClick={(e) => {
//                 e.preventDefault();
//                 setIsVideoOpen(true);
//               }}
//             >
//               Watch Video
//             </a>
//             <button
//               style={{
//                 backgroundColor: "#0e003d",
//                 color: "white",
//                 padding: "10px 20px",
//                 border: "none",
//                 borderRadius: "5px",
//                 cursor: "pointer",
//                 fontSize: "1rem",
//                 marginTop: "10px", // Ensure some spacing between the video link and button
//                 display: "inline-block",
//               }}
//               onClick={handleButtonClick}
//             >
//               Question? Contact Us
//             </button>
//           </div>
//         ) : (
//           <div
//             className="right-side"
//             style={{
//               flex: screenSize === "mobile" ? "1" : "1 1 15%",
//               padding: screenSize === "mobile" ? "5vh" : "20px",
//               textAlign: "left",
//               fontFamily: '"Libre Baskerville", serif',
//               fontSize: screenSize === "mobile" ? "1rem" : "1.2rem",
//               height: "auto",
//               marginBottom: "2vh",
//               // marginInline: "-5px",
//               textAlign: "justify",
//             }}
//           >
//             <strong
//               style={{
//                 fontSize: "1.3rem",
//                 marginBottom: "5px",
//                 display: "block",
//                 color: "#0e003d",
//                 textAlign: "Left",
//               }}
//             >
//               Gyankosha’s Student Centric Classroom Program:
//             </strong>
//             <h2
//               style={{
//                 fontSize: "1.2rem",
//                 fontWeight: "bold",
//                 marginBottom: "10px",
//                 color: "#333",
//               }}
//             >
//               Designed to place students at the heart of the learning
//               experience.
//             </h2>
//             <ul
//               style={{
//                 fontSize: "1.1rem",
//                 paddingLeft: "20px",
//                 marginBottom: "10px",
//               }}
//             >
//               {" "}
//               <li>
//                 Delivered through interactive sessions in partnership with
//                 schools, this program focuses on skill-building beyond
//                 textbooks, covering areas such as Mental Abilities, blended
//                 school subjec( Hybrid Engagement), financial literacy, social
//                 skills etc.
//               </li>
//               <li>
//                 By encouraging active participation, collaboration, and
//                 personalized learning, Gyankosha helps students develop
//                 confidence, creativity, and a growth mindset.{" "}
//               </li>
//               <li>
//                 This approach not only enhances academic performance but also
//                 prepares students to excel in competitive exams and face
//                 real-world challenges with resilience and adaptability
//               </li>
//             </ul>
//             <a
//               href="#"
//               style={{
//                 color: "#0e003d",
//                 textDecoration: "underline",
//                 fontSize: "1rem",
//                 display: "block",
//                 marginBottom: "10px",
//                 marginTop: "5px",
//               }}
//               onClick={(e) => {
//                 e.preventDefault();
//                 setIsVideoOpen(true);
//               }}
//             >
//               Watch Video
//             </a>
//             <button
//               style={{
//                 backgroundColor: "#0e003d",
//                 color: "white",
//                 padding: "10px 20px",
//                 border: "none",
//                 borderRadius: "5px",
//                 cursor: "pointer",
//                 fontSize: "1rem",
//                 marginTop: "10px", // Ensure some spacing between the video link and button
//                 display: "inline-block",
//               }}
//               onClick={handleButtonClick}
//             >
//               Question? Contact Us
//             </button>
//           </div>
//         )}
//       </div>

//       {/* Video Popup */}
//       {isVideoOpen && (
//         <div
//           style={{
//             position: "fixed",
//             top: 0,
//             left: 0,
//             right: 0,
//             bottom: 0,
//             backgroundColor: "rgba(0, 0, 0, 0.7)",
//             zIndex: 10,
//             display: "flex",
//             justifyContent: "center",
//             alignItems: "center",
//           }}
//         >
//           <div
//             style={{
//               position: "relative",
//               backgroundColor: "white",
//               padding: "20px",
//               borderRadius: "10px",
//               boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)",
//             }}
//           >
//             <button
//               style={{
//                 position: "absolute",
//                 top: "10px",
//                 right: "10px",
//                 fontSize: "30px",
//                 color: "#333",
//                 background: "transparent",
//                 border: "none",
//                 cursor: "pointer",
//               }}
//               onClick={() => setIsVideoOpen(false)}
//             >
//               &times;
//             </button>
//             <iframe
//               width="400"
//               height="315"
//               src="https://www.youtube-nocookie.com/embed/5KbUbAPeh1k?si=wCz3rAwFYlDyc9tS?rel=0"
//               title="YouTube video player"
//               style={{
//                 border: "none",
//                 borderRadius: "10px",
//               }}
//               frameborder="0"
//               allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
//               referrerpolicy="strict-origin-when-cross-origin"
//               allowfullscreen
//             ></iframe>
//           </div>
//         </div>
//       )}
//     </div>
//   ) : (
//     // Desktop View
//     <div
//       style={{
//         position: "relative",
//         height: "auto",
//         maxHeight: "1000px",
//         overflow: "hidden",
//         margin: "50px 0px",
//       }}
//     >
//       {/* Navbar */}
//       <nav
//         style={{
//           backgroundColor: "#0e003d",
//           color: "white",
//           padding: "15px 20px",
//           position: "sticky",
//           top: 0,
//           left: 0,
//           right: 0,
//           zIndex: 1,
//           textAlign: "center",
//           boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
//           width: "100vw",
//           // marginInline: "-5px",
//           marginBottom: "60px",
//         }}
//       >
//         <h2
//           style={{
//             fontSize: "2.5rem",
//             fontWeight: "bold",
//             letterSpacing: "1px",
//             margin: 0,
//             textShadow: "2px 2px 4px rgba(0, 0, 0, 0.3)",
//             fontFamily: '"Libre Baskerville", serif',
//           }}
//         >
//           Student Centric Classroom Program: In Partnership with Schools
//         </h2>
//       </nav>
//       {/* Main Content */}
//       <div
//         className="student-learning-container"
//         style={{
//           display: "flex",
//           flexDirection: "row",
//           //  overflowY: "auto",
//           // height: "calc(auto - 60px)",
//           height: "calc(110vh-5px)",
//           backgroundColor: "white",
//           padding: "5px",
//           gap: "20px",
//         }}
//       >
//         {/* Left Side: Image */}
//         <div
//           className="left-side"
//           style={{
//             flex: 1,
//             display: "flex",
//             justifyContent: "center",
//             //alignItems: "center",
//             borderRadius: "10px",
//             height: "calc(5px-110vh)",
//             width: "50vw",
//           }}
//         >
//           <img
//             src="/studentlearningg.png" // Replace with the relevant image for the student-centric program
//             alt="Student Centric Classroom"
//             style={{
//               width: "50vw",
//               height: "auto",
//               maxHeight: "800px",
//               alignContent: "center",
//               borderRadius: "10px", // Add rounded corners
//               boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)", // Add shadow for depth
//             }}
//           />
//         </div>

//         {/* Right Side: Text and Link */}
//         <div
//           className="right-side"
//           style={{
//             flex: 1,
//             padding: "30px",
//             textAlign: "left",
//             fontFamily: '"Libre Baskerville", serif',
//             fontSize: "1.2rem",
//           }}
//         >
//           <strong
//             style={{
//               fontSize: "2rem", // Reduced font size
//               marginBottom: "5px", // Reduced margin
//               display: "block",
//               color: "#0e003d",
//               textAlign: "Left",
//               fontFamily: '"Libre Baskerville", serif',
//             }}
//           >
//             Gyankosha’s Student Centric Classroom Program:
//           </strong>
//           <h2
//             style={{
//               fontSize: "1.5rem",
//               fontWeight: "bold",
//               marginBottom: "20px",
//               color: "#333",
//             }}
//           >
//             Designed to place students at the heart of the learning experience,
//             fostering engagement, curiosity, and critical thinking.{" "}
//           </h2>

//           <ul
//             style={{
//               fontSize: "1.2rem",
//               marginBottom: "20px",
//               marginBottom: "10px",
//             }}
//           >
//             <li>
//               Delivered through interactive sessions in partnership with
//               schools, this program focuses on skill-building beyond textbooks,
//               covering areas such as Mental Abilities, blended school subjec(
//               Hybrid Engagement), financial literacy, social skills etc.
//             </li>
//             <li>
//               By encouraging active participation, collaboration, and
//               personalized learning, Gyankosha helps students develop
//               confidence, creativity, and a growth mindset.{" "}
//             </li>
//             <li>
//               This approach not only enhances academic performance but also
//               prepares students to excel in competitive exams and face
//               real-world challenges with resilience and adaptability
//             </li>
//           </ul>
//           <div
//             style={{
//               display: "grid",
//               gridTemplateColumns: "1fr 1fr",
//               gap: "10px",
//               marginTop: "20px",
//               textAlign: "center",
//             }}
//           >
//             <div
//               style={{
//                 background: "#f0f8ff",
//                 padding: "10px",
//                 //  borderRadius: "8px",
//                 boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
//               }}
//             >
//               <h3 style={{ fontSize: "1.2rem", margin: 0, color: "#0e003d" }}>
//                 Academic Excellence and Rigour
//               </h3>
//             </div>

//             <div
//               style={{
//                 background: "#f0f8ff",
//                 padding: "10px",
//                 // borderRadius: "8px",
//                 boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
//               }}
//             >
//               <h3 style={{ fontSize: "1.2rem", margin: 0, color: "#0e003d" }}>
//                 Interactive Learning{" "}
//               </h3>
//             </div>

//             <div
//               style={{
//                 background: "#f0f8ff",
//                 padding: "10px",
//                 //  borderRadius: "8px",
//                 boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
//               }}
//             >
//               <h3 style={{ fontSize: "1.2rem", margin: 0, color: "#0e003d" }}>
//                 Curriculum Integration: Complements school education{" "}
//               </h3>
//             </div>

//             <div
//               style={{
//                 background: "#f0f8ff",
//                 padding: "10px",
//                 //  borderRadius: "8px",
//                 boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
//               }}
//             >
//               <h3 style={{ fontSize: "1.2rem", margin: 0, color: "#0e003d" }}>
//                 Enhanced Subject Interest & Curiosity: Unique of it's kind
//               </h3>
//             </div>
//           </div>
//           <a
//             href="#"
//             className="video-link"
//             style={{
//               color: "#0e003d",
//               textDecoration: "underline",
//               fontSize: "1.5rem",
//               display: "block",
//               marginBottom: "20px",
//             }}
//             onClick={(e) => {
//               e.preventDefault();
//               setIsVideoOpen(true);
//             }}
//           >
//             Watch Video
//           </a>
//           <button
//             className="action-button"
//             onClick={handleButtonClick}
//             style={{
//               backgroundColor: "#0e003d",
//               color: "white",
//               padding: "10px 20px",
//               border: "none",
//               borderRadius: "5px",
//               cursor: "pointer",
//               fontSize: "1.5rem",
//             }}
//           >
//             Question? Contact Us
//           </button>
//         </div>
//       </div>
//       {/* Video Popup */}
//       {isVideoOpen && (
//         <div
//           className="video-modal"
//           style={{
//             position: "fixed",
//             top: 0,
//             left: 0,
//             right: 0,
//             bottom: 0,
//             backgroundColor: "rgba(0, 0, 0, 0.7)",
//             zIndex: 10,
//             display: "flex",
//             justifyContent: "center",
//             alignItems: "center",
//           }}
//         >
//           <div
//             className="video-content"
//             style={{
//               position: "relative",
//               backgroundColor: "white",
//               padding: "20px",
//               borderRadius: "10px",
//               boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)",
//             }}
//           >
//             <button
//               className="close-button"
//               onClick={() => setIsVideoOpen(false)}
//               style={{
//                 position: "absolute",
//                 top: "10px",
//                 right: "10px",
//                 fontSize: "30px",
//                 color: "#333",
//                 background: "none",
//                 border: "none",
//                 cursor: "pointer",
//               }}
//             >
//               &times;
//             </button>
//             <iframe
//               width="560"
//               height="315"
//               src="https://www.youtube.com/embed/dQw4w9WgXcQ" // Replace with the actual video URL
//               title="YouTube video player"
//               frameBorder="0"
//               allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
//               allowFullScreen
//               style={{
//                 maxWidth: "100%",
//                 maxHeight: "100%",
//                 borderRadius: "10px",
//               }}
//             ></iframe>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// }

// export default StudentLearning;

import React, { useState, useRef, useEffect } from "react";
import LearningAsStudent from "./LearnAsStudent";
import StudentGallery from "./StudentGallery";
import FooterInfo from "./FooterInfo";
import { Link } from "react-router-dom";

const styles = {
  navbar: {
    backgroundColor: "#0e003d",
    color: "white",
    textAlign: "center",
    padding: "30px",
    position: "sticky",
    top: 0,
    zIndex: 1,
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
  },
  navbarTitle: {
    fontSize: "1.8rem",
    fontWeight: "bold",
    margin: 0,
    textShadow: "2px 2px 4px rgba(0, 0, 0, 0.3)",
    fontFamily: '"Libre Baskerville", serif',
  },
  container: {
    display: "flex",
    flexDirection: "column",
    fontFamily: '"Libre Baskerville", serif',
    fontWeight: "bolder",
    alignItems: "center",
    overflowX: "hidden",
    backgroundColor: "#ffffff",
    padding: "20px",
    marginBottom: "5vh",
  },
  mainContent: {
    display: "flex",
    flexDirection: "column",

    padding: "20px",
  },
  imageSection: {
    display: "flex",
    width: "60vw",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "right",
    gap: "20px",
    paddingTop: "5vh",
  },

  imageContainer: {
    display: "flex",
    flexDirection: "column",
    width: "20vw",
    height: "auto",
    //   gap: "10px",
    alignItems: "center",
    width: "15vw",
    height: "20vw",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
  },
  image: {
    width: "100%",
    height: "35vw",
    objectFit: "cover",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
  },
  textSection: {
    flex: 1,
    textAlign: "justify",
    height: "auto",
    padding: "5vw",
    width: "100%",
    fontSize: "1.5rem",
    color: "black",
  },
  programTitle: {
    fontSize: "1.5rem",
    color: "#0e003d",
    display: "block",
    textAlign: "left",

    marginBottom: "10px",
  },
  header: {
    paddingTop: "5vh",
    paddingLeft: "10px",
    textAlign: "left",
    marginBottom: "20px",
    width: "50vw",
  },
  title: {
    fontSize: "24px",
    fontWeight: "bold",
    color: "black",
  },
  subtitle: {
    fontSize: "1.8rem",
    fontWeight: "bolder",
    color: "#0e003d",
  },
  contentBoxes: {
    padding: "5vh",
    height: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "20px",
    marginTop: "30px",
    overflow: "hidden",
    background: "white",
    width: "100vw",
  },
  contentBox: {
    width: "60%",
    height: "50%",
    background:
      "linear-gradient(to left, rgb(251, 252, 252), #e0f7fa, rgb(128, 206, 234))",
    color: "#0e003d",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    //padding: "20px",
    borderRadius: "10px",
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
    opacity: 0,
    transform: "translateY(50px)",
    transition: "all 0.8s ease-in-out",
  },
  contentBoxVisible: {
    opacity: 2,
    transform: "translateY(0)",
  },
  contentBoxLeft: {
    alignSelf: "flex-start",
    transform: "translateX(-100px)",
  },
  contentBoxRight: {
    alignSelf: "flex-end",
    transform: "translateX(100px)",
  },
  contentBoxText: {
    fontWeight: "600",
    fontSize: window.innerWidth <= 1000 ? "1.8rem" : "2.3rem",
    lineHeight: "1.5",
    justifyContent: "center",
    textAlign: "center",
    zIndex: 2,
    lineHeight: 1.2,
    padding: "1.5rem",
    position: "fit",
  },
  overlay: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "0%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.4)",
    borderRadius: "10px",
    zIndex: 1,
  },
  videoModal: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.7)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 10,
  },
  videoContent: {
    backgroundColor: "white",
    padding: "20px",
    borderRadius: "10px",
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)",
    position: "relative",
  },
  closeButton: {
    position: "absolute",
    top: "10px",
    right: "10px",
    fontSize: "30px",
    background: "none",
    border: "none",
    cursor: "pointer",
  },
  actionButton: {
    backgroundColor: "#0e003d",
    color: "white",
    padding: "10px 20px",
    border: "none",
    marginTop: "5vh",
    fontSize: "1.5rem",
    borderRadius: "5px",
    cursor: "pointer",
  },
  galleryContainer: {
    display: "flex",
    overflowX: "hidden",
    // gap: "20px",
    // padding: "20px",
    width: "100%",
  },
  galleryItem: {
    minWidth: "300px",
    height: "200px",
    borderRadius: "8px",
    overflow: "hidden",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  "@media (max-width: 1200px)": {
    container: {
      padding: "20px",
    },
    imageSection: {
      width: "70vw",
    },
    imageContainer: {
      width: "40vw",
    },
    textSection: {
      fontSize: "1.2rem",
      padding: "4vw",
    },
    contentBoxes: {
      padding: "4vh",
    },
    contentBox: {
      width: "60%",
    },
  },
  "@media (max-width: 900px)": {
    container: {
      padding: "10px",
    },
    imageSection: {
      width: "80vw",
    },
    imageContainer: {
      width: "45vw",
    },
    textSection: {
      fontSize: "1rem",
      padding: "3vw",
    },

    contentBoxes: {
      padding: "3vh",
    },
    contentBox: {
      width: "80%",
    },
  },
  "@media (max-width: 600px)": {
    container: {
      padding: "10px",
    },

    imageSection: {
      width: "100vw",
    },
    imageContainer: {
      width: "70vw",
    },
    textSection: {
      fontSize: "1rem",
      padding: "2vw",
    },
    contentBoxes: {
      padding: "2vh",
    },
    contentBox: {
      width: "100%",
    },
  },
};

function VideoModal({ isOpen, onClose }) {
  if (!isOpen) return null;

  return (
    <div style={styles.videoModal}>
      <div style={styles.videoContent}>
        <button style={styles.closeButton} onClick={onClose}>
          &times;
        </button>
        <iframe
          width="560"
          height="315"
          src="https://www.youtube-nocookie.com/embed/5KbUbAPeh1k?rel=0"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  );
}

function ContentBoxes() {
  const boxes = [
    "                Academic Excellence and Rigour",
    "Interactive Learning",
    "Curriculum Integration: Complements school education",
    // "Engaging and Interactive Approach: Real Life Scenarios and Actionable Takeaways",
    "Enhanced Subject Interest & Curiosity: Unique of it's kind",
  ];

  const [visibleBoxes, setVisibleBoxes] = useState([]);
  const boxRefs = useRef([]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setVisibleBoxes((prev) => {
              const index = Number(entry.target.dataset.index);
              return prev.includes(index) ? prev : [...prev, index];
            });
          }
        });
      },
      { threshold: 0.2 }
    );

    boxRefs.current.forEach((box) => observer.observe(box));

    return () => observer.disconnect();
  }, []);

  return (
    <div style={styles.contentBoxes}>
      {boxes.map((text, index) => (
        <div
          key={index}
          data-index={index}
          ref={(el) => (boxRefs.current[index] = el)}
          style={{
            ...styles.contentBox,
            ...(visibleBoxes.includes(index) && styles.contentBoxVisible),
            ...(index % 2 === 0
              ? {
                  ...styles.contentBoxLeft,
                  ...(visibleBoxes.includes(index) && {
                    transform: "translateX(0)",
                  }),
                }
              : {
                  ...styles.contentBoxRight,
                  ...(visibleBoxes.includes(index) && {
                    transform: "translateX(0)",
                  }),
                }),
          }}
        >
          <div style={styles.overlay}></div>
          <h3 style={styles.contentBoxText}>{text}</h3>
        </div>
      ))}
    </div>
  );
}

function StudentLearning() {
  const [isVideoOpen, setIsVideoOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const updateIsMobile = () => {
    setIsMobile(window.innerWidth <= 1000);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Set up event listener to handle window resize
  useEffect(() => {
    updateIsMobile(); // Check on initial render
    window.addEventListener("resize", updateIsMobile); // Add event listener for resize
    return () => {
      window.removeEventListener("resize", updateIsMobile); // Cleanup listener on unmount
    };
  }, []);
  const handleButtonClick = () => {
    document
      .getElementById("FooterInfo")
      ?.scrollIntoView({ behavior: "smooth" });
  };
  const subtitleFontSize =
    window.innerWidth < 600
      ? "0.9rem"
      : window.innerWidth < 900
      ? "1.5rem"
      : "1.7rem";

  const responsiveStyles = {
    // image: {
    //   objectFit: "cover",
    //   width: "100%",
    //   height: "auto",
    // },
    imageSection: {
      flexDirection: isMobile ? "column" : "row",
      width: isMobile ? "0vw" : "50vw",
    },
    container: {
      padding: isMobile ? "0px" : "20px",
      marginBottom: isMobile ? "0vh" : "5vh",
    },
    imageContainer: {
      width: isMobile ? "100%" : "15vw",
      justifyContent: "center",
    },
    title: {
      fontSize: "24px",
      fontWeight: "bold",
      color: isMobile ? "grey" : "black",
    },
    subtitle: {
      // fontSize: isMobile ? "1.5rem" : "32px",
      fontWeight: "bolder",
      textOverflow: "ellipsis",
      textAlign: "center",
      wordWrap: "break-word",
      color: isMobile ? "white" : "#0e003d",
    },
    textSection: {
      fontSize: isMobile ? "1rem" : "1.5rem",
      padding: isMobile ? "2vw" : "5vw",
    },
    // moreArticlesButtonStyle: {
    //   fontSize: isMobile ? "14px" : "16px",
    //   padding: isMobile ? "8px 12px" : "10px 20px",
    //   backgroundColor: "rgb(40, 9, 92)",
    //   color: "#fff",
    //   border: "none",
    //   right: "10vw",
    //   borderRadius: "4px",
    //   cursor: "pointer",
    //   textDecoration: "none",
    //   transition: "background-color 0.3s ease",
    // },
    moreArticlesButtonStyle: {
      fontSize: isMobile ? "14px" : "16px",
      fontWeight: "1000",
      padding: isMobile ? "10px 12px" : "10px 20px",
      backgroundColor: "rgb(40, 9, 92)",
      color: "#fff",
      border: "none",
      position: "absolute", // Position relative to the containing div
      top: "50%", // Center vertically within the navbar
      right: "20px", // Adjust for the right corner
      transform: "translateY(-50%)", // Vertical alignment
      borderRadius: "4px",
      cursor: "pointer",
      textDecoration: "none",
      zIndex: 0, // Keeps it above other elements
      transition: "background-color 0.3s ease",
    },

    navbarStyle: {
      position: "relative",
      display: "flex", // Use flexbox for layout
      justifyContent: "space-between", // Space out heading and button
      alignItems: "center", // Align items vertically
      backgroundColor: "#0e003d",
      color: "white",
      width: "100%",
      padding: "15px 20px",
      fontFamily: '"Libre Baskerville", serif',
      fontWeight: "bolder",
      boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
    },

    header: {
      paddingLeft: isMobile ? "" : "10px",
      width: isMobile ? "auto" : "50vw",
      alignItems: isMobile ? "center" : "",
      textAlign: isMobile ? "center" : "left",
    },
    subtitle: {
      fontSize: subtitleFontSize,
    },
    actionButton: {
      fontSize: isMobile ? "1rem" : "1.5rem",
      padding: isMobile ? "8px 16px" : "10px 20px",
    },
  };
  const mobileStyles = {
    navbarStyle: {
      flexDirection: "column", // Stack items vertically
      alignItems: "flex-start", // Align items to the left
      padding: "10px 15px",
    },
    moreArticlesButtonStyle: {
      fontSize: "14px",
      padding: "8px 12px",
      position: "relative", // Move to relative for better stacking
      top: "auto", // Reset top position
      right: "auto", // Reset right position
      transform: "none", // Remove transformation
      marginTop: "10px", // Add margin for spacing
    },
  };

  return (
    <div
      style={{
        background: `
linear-gradient(to left, rgb(75, 47, 197),rgb(106, 164, 251), rgb(7, 129, 190)),
    linear-gradient(to top, #ffffff,#ffffff,rgb(251, 252, 252),rgba(251, 252, 252, 0.61), rgba(255, 255, 255, 0.21), rgba(255, 255, 255, 0))
  `,
        backgroundBlendMode: "overlay",
        padding: "5vw",
        paddingBottom: "0vw",

        width: "100vw",
      }}
    >
      <div style={styles.container}>
        <div style={{ ...styles.mainContent, overflowX: "hidden" }}>
          <div
            style={{
              ...styles.container,
              flexDirection: isMobile ? "column" : "row",
              marginBottom: isMobile ? "0vh" : "5vh",
              height: "auto",
            }}
          >
            {window.innerWidth <= 1000 ? (
              <div
                style={{
                  ...responsiveStyles.imageContainer,
                }}
              >
                <img
                  src="./studentlearningg.png"
                  alt="Student Group Activity"
                  style={{
                    ...responsiveStyles.image,
                    height: "40vh",
                  }}
                />
                <div
                  style={{
                    ...styles.header,
                    ...responsiveStyles.header,
                  }}
                >
                  <h1 style={styles.title}>GYANKOSHA presents</h1>
                  <h2
                    style={{
                      ...styles.subtitle,
                      ...responsiveStyles.subtitle,
                    }}
                  >
                    STUDENT CENTRIC <br></br>CLASSROOM PROGRAM
                  </h2>
                </div>
              </div>
            ) : (
              <>
                <div
                  style={{
                    ...styles.imageSection,
                    ...responsiveStyles.imageSection,
                  }}
                >
                  <div
                    style={{
                      ...styles.imageContainer,
                      ...responsiveStyles.imageContainer,
                      padding: "0px",
                    }}
                  >
                    <img
                      src="./studentlearningg.png" //abcd
                      alt="Classroom 1"
                      style={{ ...styles.image, marginBottom: "10px" }}
                    />
                    <img
                      src="./studentlearningg2.jpeg" //abcd
                      alt="Classroom 2"
                      style={styles.image}
                    />
                  </div>
                  <div
                    style={{
                      ...styles.imageContainer,
                      ...responsiveStyles.imageContainer,
                    }}
                  >
                    <img
                      src="/studentlearningg3.png" //abcd
                      alt="Student Group Activity"
                      style={{
                        ...styles.image,

                        height: isMobile ? "40vh" : "90vh",
                      }}
                    />
                  </div>
                </div>
                <div style={{ ...styles.header }}>
                  <h1 style={styles.title}>GYANKOSHA presents</h1>

                  <h2
                    style={{ ...styles.subtitle, ...responsiveStyles.subtitle }}
                  >
                    STUDENT CENTRIC <br></br>CLASSROOM PROGRAM{" "}
                  </h2>
                </div>
              </>
            )}
          </div>
        </div>
        <div
          style={{
            ...styles.textSection,
            ...responsiveStyles.textSection,
            overflowX: "hidden",
          }}
        >
          <strong
            style={{
              ...styles.programTitle,
              fontSize: isMobile ? "1.3rem" : "1.5rem",
            }}
          >
            Gyankosha’s Student Centric Classroom Program:
          </strong>
          <h3
            style={{
              fontWeight: "bold",
              marginBottom: "0px",
              fontSize: isMobile ? "1.3rem" : "2.0rem",
              textAlign: "left",
            }}
          >
            Designed to place students at the heart of the learning experience.
          </h3>
          <ul
            style={{
              fontSize: isMobile ? "1rem" : "1.5rem",
              padding: isMobile ? "2vw" : "5vw",
              textAlign: "justify",
            }}
          >
            <li>
              Delivered through interactive sessions in partnership with
              schools, this program focuses on skill-building beyond textbooks,
              covering areas such as Mental Abilities, blended school subjec(
              Hybrid Engagement), financial literacy, social skills etc.
            </li>
            <li>
              By encouraging active participation, collaboration, and
              personalized learning, Gyankosha helps students develop
              confidence, creativity, and a growth mindset.{" "}
            </li>
            <li>
              This approach not only enhances academic performance but also
              prepares students to excel in competitive exams and face
              real-world challenges with resilience and adaptability
            </li>{" "}
          </ul>
          {/* Buttons */}

          {/* <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              setIsVideoOpen(true);
            }}
            style={{
              color: "#0e003d",
              textDecoration: "underline",
              padding: "5vh",
              fontSize: isMobile ? "1rem" : "1.8rem",
            }}
          >
            Watch Video
          </a> */}
          <br />
          <button
            style={{ ...styles.actionButton, ...responsiveStyles.actionButton }}
            onClick={handleButtonClick}
          >
            Question? Contact Us
          </button>
          <div
            style={{
              ...styles.container,
              ...responsiveStyles.textSection,

              fontSizeAdjust: ".250",
            }}
          >
            <ContentBoxes />
          </div>
        </div>
        <div
          style={{
            ...(window.innerWidth <= 600
              ? { ...responsiveStyles.navbarStyle, ...mobileStyles.navbarStyle }
              : responsiveStyles.navbarStyle),
            background: "rgba(3, 2, 51, 0.95)",
          }}
        >
          <strong style={{ fontSize: "1.8rem", padding: "0px", flex: "1" }}>
            LEARNING AS A STUDENT
          </strong>
          <Link
            to="/articlecardpage?division=Teacher"
            style={{
              ...responsiveStyles.moreArticlesButtonStyle,
              ...mobileStyles.moreArticlesButtonStyle,
            }}
            onMouseEnter={(e) => {
              e.currentTarget.style.backgroundColor = "rgba(3, 2, 51, 0.95)";
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.backgroundColor = "rgb(2, 205, 251)";
            }}
            onClick={(e) => {
              e.currentTarget.style.backgroundColor = "rgba(5, 4, 70, 1)";
            }}
          >
            →
          </Link>
        </div>
        <div
          style={{
            ...styles.galleryContainer,
            overflowX: "scroll",
            width: "100%",
          }}
        >
          <LearningAsStudent />
        </div>
        <div style={{ ...styles.galleryContainer }}>
          <StudentGallery />
        </div>
        <div style={{ width: "100vw" }}>
          {" "}
          <FooterInfo id="FooterInfo" />
        </div>
      </div>

      <VideoModal isOpen={isVideoOpen} onClose={() => setIsVideoOpen(false)} />
    </div>
  );
}

export default StudentLearning;
