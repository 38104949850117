import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";

const QuestionPassageToggle = () => {
  const { quizId } = useParams();
  const [editedQuiz, setEditedQuiz] = useState({ questions: [] });
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [message, setMessage] = useState({ type: "", content: "" });

  useEffect(() => {
    fetchQuestions();
  }, [quizId]);

  // Fetch questions for the quiz
  const fetchQuestions = async () => {
    setLoading(true);
    try {
      const response = await axios.post("/api/KyebyeQuiz/getKyebyeQuizById", {
        quizid: quizId,
      });
      if (response.data.questions) {
        setEditedQuiz((prevState) => ({
          ...prevState,
          questions: response.data.questions,
        }));
      } else {
        setEditedQuiz((prevState) => ({ ...prevState, questions: [] }));
      }
      setMessage({ type: "", content: "" });
    } catch (error) {
      console.error("Error fetching quiz questions:", error);
      setMessage({ type: "error", content: "Failed to fetch quiz questions." });
    } finally {
      setLoading(false);
    }
  };

  // Handle changes to any question's passage_enable field
  const handlePassageEnableChange = (index, value) => {
    const updatedQuestions = [...editedQuiz.questions];
    updatedQuestions[index].passage_enable = value; // Update the passage_enable field
    setEditedQuiz((prevState) => ({
      ...prevState,
      questions: updatedQuestions,
    }));
  };

  // Submit all changes to the backend
  const submitAllChanges = async () => {
    setSubmitting(true);
    try {
      // Collect updated questions data
      const updatedQuestions = editedQuiz.questions.map((question) => ({
        id: question.id,
        passage_enable: question.passage_enable,
      }));

      // Send data to backend to update the quiz
      const response = await axios.post(
        "/api/KyebyeQuiz/updateAllQuestionPassages",
        { quizId, questions: updatedQuestions }
      );

      setMessage({ type: "success", content: response.data.message });
    } catch (error) {
      console.log("Error submitting changes:", error);
      setMessage({
        type: "error",
        content: "Failed to submit changes. Please try again.",
      });
    } finally {
      setSubmitting(false);
    }
  };

  if (loading) {
    return (
      <div className="spinner-border" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    );
  }

  return (
    <div className="container">
      <h2>Manage Kyebye Question Passages</h2>

      {message.content && (
        <div
          className={`alert alert-${
            message.type === "error" ? "danger" : "success"
          }`}
        >
          {message.content}
        </div>
      )}

      <ul className="list-group">
        {editedQuiz.questions.map((question, index) => (
          <li key={question.id || index} className="list-group-item">
            <h5>Question {index + 1}</h5>

            <p>
              <strong>Question Passage:</strong>{" "}
              {question.question_passage || "No passage provided"}
            </p>

            <label>Passage Enable</label>
            <input
              type="checkbox"
              checked={question.passage_enable}
              onChange={(e) =>
                handlePassageEnableChange(index, e.target.checked)
              }
            />
          </li>
        ))}
      </ul>

      <button
        className="btn btn-primary mt-3"
        onClick={submitAllChanges}
        disabled={submitting}
      >
        {submitting ? "Submitting..." : "Submit All Changes"}
      </button>
    </div>
  );
};

export default QuestionPassageToggle;
