// // // // // import React from "react";
// // // // // import { useNavigate } from "react-router-dom";

// // // // // function MediaContainer2() {
// // // // //   const navigate = useNavigate();

// // // // //   const containerStyle = {
// // // // //     display: "flex",
// // // // //     justifyContent: "space-between",
// // // // //     alignItems: "center",
// // // // //     backgroundColor: "hsl(0, 0%, 100%)",
// // // // //     borderRadius: "10px",
// // // // //     width: "100%",
// // // // //     maxWidth: "1200px",
// // // // //     marginLeft: "auto",
// // // // //     marginRight: "auto",
// // // // //     marginTop: "620px",
// // // // //     height: "90vh",
// // // // //     padding: "20px",
// // // // //   };

// // // // //   const leftStyle = {
// // // // //     flex: 1,
// // // // //     display: "flex",
// // // // //     justifyContent: "center",
// // // // //     alignItems: "center",
// // // // //     padding: "10px",
// // // // //   };

// // // // //   const rightStyle = {
// // // // //     flex: 1,
// // // // //     padding: "10px",
// // // // //     display: "flex",
// // // // //     flexDirection: "column",
// // // // //     justifyContent: "start",
// // // // //   };

// // // // //   const imgStyle = {
// // // // //     width: "100%",
// // // // //     maxWidth: "500px",
// // // // //     height: "auto",
// // // // //     borderRadius: "5px",
// // // // //     objectFit: "cover",
// // // // //   };

// // // // //   return (
// // // // //     <div style={containerStyle}>
// // // // //       {/* Left Section */}
// // // // //       <div style={leftStyle}>
// // // // //         <img src="/education.gif" alt="Education" style={imgStyle} />
// // // // //       </div>

// // // // //       {/* Right Section */}
// // // // //       <div style={rightStyle}>
// // // // //         <p
// // // // //           style={{
// // // // //             fontFamily: '"Libre Baskerville", serif',
// // // // //             fontSize: "26px", // Increased font size
// // // // //             lineHeight: "1.8", // Adjusted for better readability
// // // // //             textAlign: "justify",
// // // // //             color: "#0e003d", // Updated color
// // // // //           }}
// // // // //         >
// // // // //           Gyankosha provides comprehensive learning that focuses on developing
// // // // //           mental agility, diversified perspectives, and 21st-century skills
// // // // //           critical for students.<br></br>Partnering with schools and students to
// // // // //           deliver achedmic excellence,skill devlopment, and holistic learning
// // // // //           solutions.
// // // // //         </p>
// // // // //         <div>
// // // // //           <button
// // // // //             style={{
// // // // //               padding: "12px 25px",
// // // // //               backgroundColor: "#0e003d",
// // // // //               color: "white",
// // // // //               border: "none",
// // // // //               //  borderRadius: "5px",
// // // // //               fontSize: "16px",
// // // // //               cursor: "pointer",
// // // // //               margin: "10px",
// // // // //             }}
// // // // //             onClick={() => navigate("/middlescreen")}
// // // // //           >
// // // // //             Explore Solutions for Schools
// // // // //           </button>
// // // // //           <button
// // // // //             style={{
// // // // //               padding: "12px 25px",
// // // // //               backgroundColor: "#0e003d",
// // // // //               color: "white",
// // // // //               border: "none",
// // // // //               // borderRadius: "5px",
// // // // //               fontSize: "16px",
// // // // //               cursor: "pointer",
// // // // //               margin: "10px",
// // // // //             }}
// // // // //             onClick={() => navigate("/middlescreen")}
// // // // //           >
// // // // //             Start Learning as a Student
// // // // //           </button>
// // // // //         </div>
// // // // //       </div>
// // // // //     </div>
// // // // //   );
// // // // // }

// // // // // export default MediaContainer2;
// // // // import React from "react";
// // // // import { useNavigate } from "react-router-dom";

// // // // function MediaContainer2() {
// // // //   const navigate = useNavigate();
// // // //   const handleScrollToSection = (sectionId) => {
// // // //     const section = document.getElementById(sectionId);
// // // //     if (section) {
// // // //       section.scrollIntoView({ behavior: "smooth" });
// // // //     }
// // // //   };
// // // //   const containerStyle = {
// // // //     display: "flex",
// // // //     //  boxsizing: "border-box",
// // // //     alignItems: "center",
// // // //     backgroundColor: "#e0f7fa", // Light blue background
// // // //     // borderRadius: "15px",
// // // //     width: "100%", // Full width
// // // //     maxWidth: "100vw",
// // // //     margin: "40px auto",
// // // //     padding: "0px 10px",
// // // //     boxShadow: "0 8px 20px rgba(0, 0, 0, 0.1)",
// // // //     overflow: "hidden",
// // // //     position: "relative",
// // // //     gap: "20px",
// // // //     marginTop: "730px",
// // // //     transition: "transform 0.3s ease-in-out", // Smooth scaling effect
// // // //   };

// // // //   const containerHoverStyle = {
// // // //     transform: "scale(1.02)", // Slight zoom-in effect for the whole container
// // // //   };

// // // //   const imgStyle = {
// // // //     flex: "0 0 40vw",
// // // //     height: "auto",
// // // //     borderRadius: "10px",
// // // //     objectFit: "cover",
// // // //     padding: "10px",
// // // //     transition: "transform 0.5s ease",
// // // //     boxShadow: "0 5px 15px rgba(0, 0, 0, 0.2)",
// // // //   };

// // // //   const imgHoverStyle = {
// // // //     transform: "scale(1.1)", // Slight zoom-in effect for the image
// // // //   };

// // // //   const rightSectionStyle = {
// // // //     flex: 1,
// // // //     display: "flex",
// // // //     flexDirection: "column",
// // // //     justifyContent: "center",
// // // //     gap: "20px",
// // // //     animation: "fadeIn 1.5s ease-in-out",
// // // //   };

// // // //   const textStyle = {
// // // //     fontFamily: '"Libre Baskerville", serif',
// // // //     fontSize: "20px",
// // // //     lineHeight: "1.8",
// // // //     color: "#004d99",
// // // //     margin: 0,
// // // //     padding: "30px",
// // // //     textAlign: "justifyr",
// // // //   };

// // // //   const headingStyle = {
// // // //     fontSize: "40px", // Adjust to your preferred size
// // // //     fontWeight: "bold",
// // // //     color: "#0e003d", // Corrected to a valid hex color for dark text
// // // //     marginBottom: "0px",
// // // //     textAlign: "center", // Optional: centers the heading text
// // // //     fontFamily: '"Libre Baskerville", serif',
// // // //   };
// // // //   const buttonGroupStyle = {
// // // //     display: "flex",
// // // //     gap: "15px",
// // // //   };

// // // //   const buttonStyle = {
// // // //     padding: "15px 25px",
// // // //     backgroundColor: "#0e003d", // Blue button
// // // //     color: "white",
// // // //     border: "none",
// // // //     //borderRadius: "8px",
// // // //     marginRight: "20px",
// // // //     fontSize: "16px",
// // // //     fontWeight: "bold",
// // // //     cursor: "pointer",
// // // //     textAlign: "center",
// // // //     boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
// // // //     transition:
// // // //       "transform 0.3s ease, background-color 0.3s ease, box-shadow 0.3s ease",
// // // //   };

// // // //   const buttonHoverStyle = {
// // // //     //   transform: "scale(1.1)", // Zoom effect for buttons on hover
// // // //     backgroundColor: "#004080", // Darker blue on hover
// // // //     boxShadow: "0 6px 12px rgba(0, 0, 0, 0.3)",
// // // //   };

// // // //   return (
// // // //     <div
// // // //       style={containerStyle}
// // // //       onMouseEnter={(e) =>
// // // //         Object.assign(e.currentTarget.style, containerHoverStyle)
// // // //       }
// // // //       onMouseLeave={(e) =>
// // // //         Object.assign(e.currentTarget.style, { transform: "scale(1)" })
// // // //       }
// // // //     >
// // // //       {/* Left Section: Image */}
// // // //       <img
// // // //         src="/Download_Study_Literature_concept_for_free-removebg-preview.png"
// // // //         alt="Education"
// // // //         style={imgStyle}
// // // //         onMouseEnter={(e) =>
// // // //           Object.assign(e.currentTarget.style, imgHoverStyle)
// // // //         }
// // // //         onMouseLeave={(e) =>
// // // //           Object.assign(e.currentTarget.style, { transform: "scale(1)" })
// // // //         }
// // // //       />

// // // //       {/* Right Section: Text and Buttons */}
// // // //       <div style={{ rightSectionStyle, paddingLeft: "20px" }}>
// // // //         <p style={headingStyle}>GYANKOSHA</p>

// // // //         <p style={textStyle}>
// // // //           Gyankosha provides comprehensive learning that focuses on developing
// // // //           mental agility, diversified perspectives, and 21st-century skills
// // // //           critical for students.<br></br>Partnering with schools and students to
// // // //           deliver academic excellence, skill development, and holistic learning
// // // //           solutions.
// // // //         </p>

// // // //         <div
// // // //           style={{ buttonGroupStyle, alignContent: "center", padding: "20px" }}
// // // //         >
// // // //           <button
// // // //             style={buttonStyle}
// // // //             onMouseEnter={(e) =>
// // // //               Object.assign(e.currentTarget.style, buttonHoverStyle)
// // // //             }
// // // //             onMouseLeave={(e) =>
// // // //               Object.assign(e.currentTarget.style, buttonStyle)
// // // //             }
// // // //             onClick={() => handleScrollToSection("MediaContainer")}
// // // //           >
// // // //             Explore Teaching Solutions
// // // //           </button>
// // // //           <button
// // // //             style={buttonStyle}
// // // //             onMouseEnter={(e) =>
// // // //               Object.assign(e.currentTarget.style, buttonHoverStyle)
// // // //             }
// // // //             onMouseLeave={(e) =>
// // // //               Object.assign(e.currentTarget.style, buttonStyle)
// // // //             }
// // // //             onClick={() => handleScrollToSection("productofferings")}
// // // //           >
// // // //             Start Learning Now
// // // //           </button>
// // // //         </div>
// // // //       </div>
// // // //     </div>
// // // //   );
// // // // }

// // // // export default MediaContainer2;
// // // // import React, { useState, useEffect } from "react";
// // // // import { useNavigate } from "react-router-dom";

// // // // function MediaContainer2() {
// // // //   const [isMobile, setIsMobile] = useState(false);
// // // //   const [marginTop, setMarginTop] = useState("730px"); // Default margin-top for desktop
// // // //   const navigate = useNavigate();

// // // //   useEffect(() => {
// // // //     const checkScreenWidth = () => {
// // // //       const isMobileView = window.innerWidth <= 768;
// // // //       setIsMobile(isMobileView);

// // // //       // Dynamically adjust the marginTop based on the screen width or height
// // // //       setMarginTop(isMobileView ? `${window.innerHeight * 1.05}px` : "730px");
// // // //     };

// // // //     checkScreenWidth(); // Check on initial load
// // // //     window.addEventListener("resize", checkScreenWidth); // Check on window resize

// // // //     return () => {
// // // //       window.removeEventListener("resize", checkScreenWidth);
// // // //     };
// // // //   }, []);

// // // //   const handleScrollToSection = (sectionId) => {
// // // //     const section = document.getElementById(sectionId);
// // // //     if (section) {
// // // //       section.scrollIntoView({ behavior: "smooth" });
// // // //     }
// // // //   };

// // // //   const containerStyle = {
// // // //     display: "flex",
// // // //     alignItems: "center",
// // // //     backgroundColor: "#e0f7fa",
// // // //     width: "100%",
// // // //     maxWidth: "100vw",
// // // //     margin: "40px auto",
// // // //     padding: "0px 10px",
// // // //     boxShadow: "0 8px 20px rgba(0, 0, 0, 0.1)",
// // // //     overflow: "hidden",
// // // //     position: "relative",
// // // //     gap: "20px",
// // // //     marginTop: marginTop,
// // // //     transition: "transform 0.3s ease-in-out",
// // // //     flexDirection: isMobile ? "column" : "row", // Change flex direction based on screen size
// // // //   };

// // // //   const containerHoverStyle = {
// // // //     transform: "scale(1.02)",
// // // //   };

// // // //   const imgStyle = {
// // // //     flex: "0 0 40vw",
// // // //     height: "auto",
// // // //     borderRadius: "10px",
// // // //     objectFit: "cover",
// // // //     padding: "10px",
// // // //     transition: "transform 0.5s ease",
// // // //     boxShadow: "0 5px 15px rgba(0, 0, 0, 0.2)",
// // // //     width: isMobile ? "100%" : "auto", // Full width on mobile
// // // //   };

// // // //   const imgHoverStyle = {
// // // //     transform: "scale(1.1)",
// // // //   };

// // // //   const rightSectionStyle = {
// // // //     flex: 1,
// // // //     display: "flex",
// // // //     flexDirection: "column",
// // // //     justifyContent: "center",
// // // //     gap: "20px",
// // // //     animation: "fadeIn 1.5s ease-in-out",
// // // //     paddingLeft: isMobile ? "0px" : "20px", // Adjust padding on mobile
// // // //     textAlign: isMobile ? "center" : "left", // Center text on mobile
// // // //   };

// // // //   const textStyle = {
// // // //     fontFamily: '"Libre Baskerville", serif',
// // // //     fontSize: "20px",
// // // //     lineHeight: "1.8",
// // // //     color: "#004d99",
// // // //     margin: 0,
// // // //     padding: "30px",
// // // //     textAlign: isMobile ? "center" : "justify", // Center text on mobile
// // // //   };

// // // //   const headingStyle = {
// // // //     fontSize: "40px",
// // // //     fontWeight: "bold",
// // // //     color: "#0e003d",
// // // //     marginBottom: "0px",
// // // //     textAlign: "center",
// // // //     fontFamily: '"Libre Baskerville", serif',
// // // //   };

// // // //   const buttonGroupStyle = {
// // // //     display: "flex",
// // // //     gap: "15px",
// // // //     flexDirection: isMobile ? "column" : "row", // Stack buttons vertically on mobile
// // // //   };

// // // //   const buttonStyle = {
// // // //     padding: "15px 25px",
// // // //     backgroundColor: "#0e003d",
// // // //     color: "white",
// // // //     border: "none",
// // // //     fontSize: "16px",
// // // //     fontWeight: "bold",
// // // //     cursor: "pointer",
// // // //     textAlign: "center",
// // // //     boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
// // // //     transition:
// // // //       "transform 0.3s ease, background-color 0.3s ease, box-shadow 0.3s ease",
// // // //   };

// // // //   const buttonHoverStyle = {
// // // //     backgroundColor: "#004080",
// // // //     boxShadow: "0 6px 12px rgba(0, 0, 0, 0.3)",
// // // //   };

// // // //   return isMobile ? (
// // // //     <div
// // // //       style={containerStyle}
// // // //       onMouseEnter={(e) =>
// // // //         Object.assign(e.currentTarget.style, { transform: "scale(1.02)" })
// // // //       }
// // // //       onMouseLeave={(e) =>
// // // //         Object.assign(e.currentTarget.style, { transform: "scale(1)" })
// // // //       }
// // // //     >
// // // //       {/* Left Section: Image */}
// // // //       <img
// // // //         src="/Download_Study_Literature_concept_for_free-removebg-preview.png"
// // // //         alt="Education"
// // // //         style={imgStyle}
// // // //         onMouseEnter={(e) =>
// // // //           Object.assign(e.currentTarget.style, { transform: "scale(1.1)" })
// // // //         }
// // // //         onMouseLeave={(e) =>
// // // //           Object.assign(e.currentTarget.style, { transform: "scale(1)" })
// // // //         }
// // // //       />

// // // //       {/* Right Section: Text and Buttons */}
// // // //       <div style={rightSectionStyle}>
// // // //         <p
// // // //           style={{ fontSize: "40px", fontWeight: "bold", textAlign: "center" }}
// // // //         >
// // // //           GYANKOSHA
// // // //         </p>
// // // //         <p
// // // //           style={{ fontSize: "20px", lineHeight: "1.8", textAlign: "justify" }}
// // // //         >
// // // //           Gyankosha provides comprehensive learning that focuses on developing
// // // //           mental agility, diversified perspectives, and 21st-century skills
// // // //           critical for students. Partnering with schools and students to deliver
// // // //           academic excellence, skill development, and holistic learning
// // // //           solutions.
// // // //         </p>

// // // //         <div style={buttonGroupStyle}>
// // // //           <button
// // // //             style={buttonStyle}
// // // //             onMouseEnter={(e) =>
// // // //               Object.assign(e.currentTarget.style, buttonHoverStyle)
// // // //             }
// // // //             onMouseLeave={(e) =>
// // // //               Object.assign(e.currentTarget.style, buttonStyle)
// // // //             }
// // // //             onClick={() => handleScrollToSection("MediaContainer")}
// // // //           >
// // // //             Explore Solutions for Schools
// // // //           </button>
// // // //           <button
// // // //             style={buttonStyle}
// // // //             onMouseEnter={(e) =>
// // // //               Object.assign(e.currentTarget.style, buttonHoverStyle)
// // // //             }
// // // //             onMouseLeave={(e) =>
// // // //               Object.assign(e.currentTarget.style, buttonStyle)
// // // //             }
// // // //             onClick={() => handleScrollToSection("productofferings")}
// // // //           >
// // // //             Start Learning as a Student
// // // //           </button>
// // // //         </div>
// // // //       </div>
// // // //     </div>
// // // //   ) : (
// // // //     <div
// // // //       style={containerStyle}
// // // //       onMouseEnter={(e) =>
// // // //         Object.assign(e.currentTarget.style, containerHoverStyle)
// // // //       }
// // // //       onMouseLeave={(e) =>
// // // //         Object.assign(e.currentTarget.style, { transform: "scale(1)" })
// // // //       }
// // // //     >
// // // //       {/* Left Section: Image */}
// // // //       <img
// // // //         src="/Download_Study_Literature_concept_for_free-removebg-preview.png"
// // // //         alt="Education"
// // // //         style={imgStyle}
// // // //         onMouseEnter={(e) =>
// // // //           Object.assign(e.currentTarget.style, imgHoverStyle)
// // // //         }
// // // //         onMouseLeave={(e) =>
// // // //           Object.assign(e.currentTarget.style, { transform: "scale(1)" })
// // // //         }
// // // //       />

// // // //       {/* Right Section: Text and Buttons */}
// // // //       <div style={rightSectionStyle}>
// // // //         <p style={headingStyle}>GYANKOSHA</p>

// // // //         <p style={textStyle}>
// // // //           Gyankosha provides comprehensive learning that focuses on developing
// // // //           mental agility, diversified perspectives, and 21st-century skills
// // // //           critical for students.<br></br>Partnering with schools and students to
// // // //           deliver academic excellence, skill development, and holistic learning
// // // //           solutions.
// // // //         </p>

// // // //         <div style={buttonGroupStyle}>
// // // //           <button
// // // //             style={buttonStyle}
// // // //             onMouseEnter={(e) =>
// // // //               Object.assign(e.currentTarget.style, buttonHoverStyle)
// // // //             }
// // // //             onMouseLeave={(e) =>
// // // //               Object.assign(e.currentTarget.style, buttonStyle)
// // // //             }
// // // //             onClick={() => handleScrollToSection("MediaContainer")}
// // // //           >
// // // //             Explore Solutions for Schools
// // // //           </button>
// // // //           <button
// // // //             style={buttonStyle}
// // // //             onMouseEnter={(e) =>
// // // //               Object.assign(e.currentTarget.style, buttonHoverStyle)
// // // //             }
// // // //             onMouseLeave={(e) =>
// // // //               Object.assign(e.currentTarget.style, buttonStyle)
// // // //             }
// // // //             onClick={() => handleScrollToSection("productofferings")}
// // // //           >
// // // //             Start Learning as a Student
// // // //           </button>
// // // //         </div>
// // // //       </div>
// // // //     </div>
// // // //   );
// // // // }

// // // // export default MediaContainer2;
// // // // import React, { useState, useEffect } from "react";
// // // // import { useNavigate } from "react-router-dom";

// // // // function MediaContainer2() {
// // // //   const [marginTop, setMarginTop] = useState("730px"); // Default margin-top for desktop
// // // //   const navigate = useNavigate();

// // // //   useEffect(() => {
// // // //     const checkScreenWidth = () => {
// // // //       const width = window.innerWidth;

// // // //       if (width <= 900) {
// // // //         // Smartphone
// // // //         setMarginTop(`${window.innerHeight * 1.05}px`);
// // // //       } else if (width <= 1200 && width > 900) {
// // // //         // Tablet
// // // //         setMarginTop("100vh");
// // // //       } else {
// // // //         // Desktop
// // // //         setMarginTop("100vh");
// // // //       }
// // // //     };

// // // //     checkScreenWidth(); // Check on initial load
// // // //     window.addEventListener("resize", checkScreenWidth); // Check on window resize

// // // //     return () => {
// // // //       window.removeEventListener("resize", checkScreenWidth);
// // // //     };
// // // //   }, []);

// // // //   const handleScrollToSection = (sectionId) => {
// // // //     const section = document.getElementById(sectionId);
// // // //     if (section) {
// // // //       section.scrollIntoView({ behavior: "smooth" });
// // // //     }
// // // //   };

// // // //   return (
// // // //     <>
// // // //       {/* CSS Styles for Responsiveness */}
// // // //       <style>
// // // //         {`
// // // //           .container {
// // // //             display: flex;
// // // //             align-items: center;
// // // //             background-color: #e0f7fa;
// // // //             width: 100%;
// // // //             max-width: 100vw;
// // // //             margin: 40px auto;
// // // //             margin-top: auto;
// // // //             padding: 0 10px;
// // // //             box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
// // // //             overflow: hidden;
// // // //             position: relative;
// // // //             gap: 20px;
// // // //             margin-top: ${marginTop};
// // // //             transition: transform 0.3s ease-in-out;
// // // //             flex-direction: row; /* Default to row */
// // // //           }

// // // //           .container:hover {
// // // //             transform: scale(1.02);
// // // //           }

// // // //           .image {
// // // //             flex: 0 0 40vw;
// // // //             height: auto;
// // // //             border-radius: 10px;
// // // //             object-fit: cover;
// // // //             padding: 10px;
// // // //             transition: transform 0.5s ease;
// // // //             box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
// // // //           }

// // // //           .image:hover {
// // // //             transform: scale(1.1);
// // // //           }

// // // //           .right-section {
// // // //             flex: 1;
// // // //             display: flex;
// // // //             flex-direction: column;
// // // //             justify-content: center;
// // // //             gap: 20px;
// // // //             animation: fadeIn 1.5s ease-in-out;
// // // //             padding-left: 20px;
// // // //             text-align: left;
// // // //           }

// // // //           .heading {
// // // //             font-size: 40px;
// // // //             font-weight: bold;
// // // //             color: #0e003d;
// // // //             margin-bottom: 0px;
// // // //             text-align: center;
// // // //             font-family: "Libre Baskerville", serif;
// // // //           }

// // // //           .text {
// // // //             font-family: "Libre Baskerville", serif;
// // // //             font-size: 20px;
// // // //             line-height: 1.8;
// // // //             color: #004d99;
// // // //             margin: 0;
// // // //             padding: 30px;
// // // //             text-align: justify;
// // // //           }

// // // //           .button-group {
// // // //             display: flex;
// // // //             gap: 15px;
// // // //             flex-direction: row; /* Default to row */
// // // //           }

// // // //           .button {
// // // //             padding: 15px 25px;
// // // //             background-color: #0e003d;
// // // //             color: white;
// // // //             border: none;
// // // //             font-size: 16px;
// // // //             font-weight: bold;
// // // //             cursor: pointer;
// // // //             text-align: center;
// // // //             box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
// // // //             transition: transform 0.3s ease, background-color 0.3s ease,
// // // //               box-shadow 0.3s ease;
// // // //           }

// // // //           .button:hover {
// // // //             background-color: #004080;
// // // //             box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
// // // //           }

// // // //           /* Media Queries for Mobile */
// // // //           @media (max-width: 900px) {
// // // //             .container {
// // // //               flex-direction: column; /* Stack vertically */
// // // //               margin-top: ${marginTop};
// // // //             }

// // // //             .image {
// // // //               flex: 0 0 auto;
// // // //               width: 100%; /* Full width */
// // // //               padding: 0;
// // // //             }

// // // //             .right-section {
// // // //               padding-left: 0;
// // // //               text-align: center; /* Center content */
// // // //             }

// // // //             .button-group {
// // // //               flex-direction: column; /* Stack buttons vertically */
// // // //             }

// // // //             .text {
// // // //               text-align: center; /* Center text */
// // // //             }
// // // //           }
// // // //         `}
// // // //       </style>

// // // //       {/* Main Container */}
// // // //       <div className="container">
// // // //         {/* Left Section: Image */}
// // // //         <img
// // // //           src="/Download_Study_Literature_concept_for_free-removebg-preview.png"
// // // //           alt="Education"
// // // //           className="image"
// // // //         />

// // // //         {/* Right Section: Text and Buttons */}
// // // //         <div className="right-section">
// // // //           <p className="heading">GYANKOSHA</p>
// // // //           <p className="text">
// // // //             Gyankosha provides comprehensive learning that focuses on developing
// // // //             mental agility, diversified perspectives, and 21st-century skills
// // // //             critical for students. Partnering with schools and students to
// // // //             deliver academic excellence, skill development, and holistic
// // // //             learning solutions.
// // // //           </p>

// // // //           <div className="button-group">
// // // //             <button
// // // //               className="button"
// // // //               onClick={() => handleScrollToSection("MediaContainer")}
// // // //             >
// // // //               Explore Solutions for Schools
// // // //             </button>
// // // //             <button
// // // //               className="button"
// // // //               onClick={() => handleScrollToSection("productofferings")}
// // // //             >
// // // //               Start Learning as a Student
// // // //             </button>
// // // //           </div>
// // // //         </div>
// // // //       </div>
// // // //     </>
// // // //   );
// // // // }

// // // // export default MediaContainer2;
// // // import React, { useState, useEffect } from "react";
// // // import { useNavigate } from "react-router-dom";

// // // function MediaContainer2() {
// // //   const [marginTop, setMarginTop] = useState("730px"); // Default margin-top for desktop
// // //   const navigate = useNavigate();

// // //   useEffect(() => {
// // //     const checkScreenWidth = () => {
// // //       const width = window.innerWidth;

// // //       if (width <= 900) {
// // //         // Smartphone
// // //         setMarginTop(`${window.innerHeight * 1.05}px`);
// // //       } else if (width <= 1200 && width > 900) {
// // //         // Tablet
// // //         setMarginTop("120vh");
// // //       } else {
// // //         // Desktop
// // //         setMarginTop("120vh");
// // //       }
// // //     };

// // //     checkScreenWidth(); // Check on initial load
// // //     window.addEventListener("resize", checkScreenWidth); // Check on window resize

// // //     return () => {
// // //       window.removeEventListener("resize", checkScreenWidth);
// // //     };
// // //   }, []);

// // //   const handleScrollToSection = (sectionId) => {
// // //     const section = document.getElementById(sectionId);
// // //     if (section) {
// // //       section.scrollIntoView({ behavior: "smooth" });
// // //     }
// // //   };

// // //   return (
// // //     <>
// // //       {/* CSS Styles for Responsiveness */}
// // //       <style>
// // //         {`
// // //           .container {
// // //             display: flex;
// // //             align-items: center;
// // //             background-color: #e0f7fa;
// // //             width: 100%;
// // //             max-width: 100vw;
// // //             margin: 40px auto;
// // //             margin-top: auto;
// // //             padding: 0 10px;
// // //             box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
// // //             overflow: hidden;
// // //             position: relative;
// // //             gap: 20px;
// // //             margin-top: ${marginTop};
// // //             transition: transform 0.3s ease-in-out;
// // //             flex-direction: row; /* Default to row */
// // //           }

// // //           .container:hover {
// // //             transform: scale(1.02);
// // //           }

// // //           .image {
// // //             flex: 0 0 40vw;
// // //             height: auto;
// // //             width:50vw;
// // //             border-radius: 10px;
// // //             object-fit: cover;
// // //             padding: 10px;
// // //             transition: transform 0.5s ease;
// // //             box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
// // //           }

// // //           .image:hover {
// // //             transform: scale(1.1);
// // //           }

// // //           .right-section {
// // //             flex: 1;
// // //             display: flex;
// // //             flex-direction: column;
// // //             justify-content: center;
// // //             gap: 20px;
// // //             animation: fadeIn 1.5s ease-in-out;
// // //             padding-left: 20px;
// // //             text-align: left;
// // //           }

// // //           .heading {
// // //             font-size: 4rem;
// // //             font-weight: bold;
// // //             color: #0e003d;
// // //             margin-bottom: 0px;
// // //             text-align: center;
// // //             font-family: "Libre Baskerville", serif;
// // //           }

// // //           .text {
// // //             font-family: "Libre Baskerville", serif;
// // //             font-size: 2rem;
// // //             line-height: 1.8;
// // //             color: #004d99;
// // //             margin: 0;
// // //             padding: 30px;
// // //             text-align: justify;
// // //           }

// // //           .button-group {
// // //           font-family: "Libre Baskerville", serif;
// // // display: flex;
// // // gap: 15px;
// // // justify-content: center;
// // // flex-direction: row; /* Default to row */
// // //           }

// // //           .button {
// // //   padding: 15px 25px;
// // //   background-color: #0e003d;
// // //   color: white;
// // //   border: none;
// // //   font-size: 1.8rem;
// // //  font-weight: bold;
// // //   cursor: pointer;
// // //   text-align: center;
// // //   box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
// // //   transition: transform 0.3s ease, background-color 0.3s ease,
// // //     box-shadow 0.3s ease;
// // //           }

// // //           .button:hover {
// // //             background-color: #004080;
// // //             box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
// // //           }

// // //           /* Media Queries for Mobile */
// // //           @media (max-width: 1100px) {
// // //             .container {
// // //               flex-direction: column; /* Stack vertically */
// // //               margin-top: ${marginTop};
// // //             }

// // //             .image {
// // //               flex: 0 0 auto;
// // //               width: 100%; /* Full width */
// // //               padding: 0;
// // //             }

// // //             .right-section {
// // //               padding-left: 0;
// // //               text-align: center; /* Center content */
// // //             }

// // //             .button-group {
// // //               flex-direction: column; /* Stack buttons vertically */
// // //             }

// // //             .text {
// // //               text-align: center; /* Center text */
// // //             }
// // //           }
// // //         `}
// // //       </style>

// // //       {/* Main Container */}
// // //       <div className="container">
// // //         {/* Left Section: Image */}
// // //         <img
// // //           src="/Download_Study_Literature_concept_for_free-removebg-preview.png"
// // //           alt="Education"
// // //           className="image"
// // //         />

// // //         {/* Right Section: Text and Buttons */}
// // //         <div className="right-section">
// // //           <p className="heading">GYANKOSHA</p>
// // //           <p className="text">
// // //             Gyankosha in alignment with NEP guidelines is providing
// // //             comprehensive learning that focuses on developing mental agility,
// // //             diversified perspectives, and 21st-century skills critical for
// // //             students.<br></br> Partnering with schools , teachers , parents and
// // //             students to deliver academic excellence, skill development, and
// // //             holistic learning solutions.
// // //           </p>

// // //           <div className="button-group">
// // //             <button
// // //               className="button"
// // //               onClick={() => handleScrollToSection("MediaContainer")}
// // //             >
// // //               Explore Solutions for Schools
// // //             </button>
// // //             <button
// // //               className="button"
// // //               onClick={() => handleScrollToSection("productofferings")}
// // //             >
// // //               Start Learning as a Student
// // //             </button>
// // //           </div>
// // //         </div>
// // //       </div>
// // //     </>
// // //   );
// // // }

// // // export default MediaContainer2;

// // import React from "react";

// // function MediaContainer2() {
// //   const containerStyle = {
// //     display: "flex",
// //     justifyContent: "space-between",
// //     background: "#e0f7fa",
// //     alignItems: "center",
// //     padding: "20px",
// //     gap: "20px",
// //     marginTop: "110vh",
// //     background:
// //       "linear-gradient(to left,rgb(251, 252, 252), #e0f7fa,rgb(128, 206, 234))",

// //     flexDirection: window.innerWidth <= 767 ? "column" : "row",
// //     textAlign: window.innerWidth <= 767 ? "center" : "left",
// //     fontFamily: '"Libre Baskerville", serif',
// //   };

// //   const contentStyle = {
// //     flex: 1,
// //     padding: window.innerWidth <= 767 ? "10px" : "20px",
// //   };

// //   const headingStyle = {
// //     fontSize: window.innerWidth <= 767 ? "2rem" : "3rem",
// //     fontWeight: "bold",
// //     color: " #0e003d",
// //     marginBottom: "0px",
// //     textAlign: "center",
// //     fontFamily: `"Libre Baskerville", serif`,
// //     padding: "1vh",
// //     lineHeight: "1.8",
// //   };

// //   const paragraphStyle = {
// //     padding: window.innerWidth <= 767 ? "10px" : "20px",
// //     fontSize: window.innerWidth <= 767 ? "1rem" : "1.5rem",
// //     lineHeight: "1.6",
// //     color: "#004d99",
// //     textAlign: "justify",
// //   };

// //   const buttonContainerStyle = {
// //     fontFamily: `"Libre Baskerville", serif`,
// //     display: "flex",
// //     justifyContent: "center",
// //     flexDirection: "row" /* Default to row */,
// //     marginTop: "20px",
// //     flexDirection: window.innerWidth <= 767 ? "column" : "row",
// //   };

// //   const buttonStyle = {
// //     backgroundColor: "#0e003d",
// //     border: "none",
// //     fontSize: "1.8rem",
// //     fontWeight: "bold",
// //     color: "white",
// //     textAlign: "center",
// //     transition:
// //       "transform 0.3s ease, background-color 0.3s ease, box-shadow 0.3s ease",
// //     padding: " 20px",
// //     fontSize: window.innerWidth <= 767 ? "0.9rem" : "1rem",
// //     marginRight: window.innerWidth <= 767 ? "0" : "10px",
// //     marginBottom: window.innerWidth <= 767 ? "10px" : "0",
// //     // border: "none",
// //     // borderRadius: "5px",

// //     cursor: "pointer",
// //   };

// //   const imageContainerStyle = {
// //     flex: 1,
// //     textAlign: "center",
// //   };

// //   const imageStyle = {
// //     flex: 1,
// //     maxWidth: "100%",
// //     height: "auto",
// //     borderRadius: "10px",
// //     boxShadow: "0px 4px 6px rgba(12, 9, 91, 0.1)",
// //     transition: "background-color 0.3s ease",
// //   };

// //   return (
// //     <div style={containerStyle}>
// //       {/* <div style={imageContainerStyle}> */}
// //       <img
// //         src="./Download_Study_Literature_concept_for_free-removebg-preview.png" /* Replace with the correct image URL */
// //         alt="Learning Illustration"
// //         style={imageStyle}
// //       />
// //       {/* // </div> */}
// //       <div style={contentStyle}>
// //         <p style={headingStyle}>GYANKOSHA</p>
// //         <p style={paragraphStyle}>
// //           Gyankosha, in alignment with NEP guidelines, is providing
// //           comprehensive learning that focuses on developing mental agility,
// //           diversified perspectives, and 21st-century skills critical for
// //           students. Partnering with schools, teachers, parents, and students to
// //           deliver academic excellence, skill development, and holistic learning
// //           solutions.
// //         </p>
// //         <div style={buttonContainerStyle}>
// //           <button style={buttonStyle}>EXPLORE SOLUTIONS FOR SCHOOLS</button>
// //           <button style={buttonStyle}>START LEARNING AS STUDENT</button>
// //         </div>
// //       </div>
// //     </div>
// //   );
// // }

// // export default MediaContainer2;
// import React, { useEffect, useState } from "react";

// function MediaContainer2() {
//   const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);

//   useEffect(() => {
//     const handleResize = () => setIsMobile(window.innerWidth <= 767);
//     window.addEventListener("resize", handleResize);
//     return () => window.removeEventListener("resize", handleResize);
//   }, []);

//   const containerStyle = {
//     display: "flex",
//     justifyContent: "space-between",
//     background:
//       "linear-gradient(to left, rgb(251, 252, 252), #e0f7fa, rgb(128, 206, 234))",
//     alignItems: "center",
//     padding: "20px",
//     gap: "20px",
//     marginTop: "110vh",
//     flexDirection: isMobile ? "column" : "row",
//     textAlign: isMobile ? "center" : "left",
//     fontFamily: '"Libre Baskerville", serif',
//     animation: "fadeIn 1.5s ease-in-out",
//   };

//   const contentStyle = {
//     flex: 1,
//     padding: isMobile ? "10px" : "20px",
//   };

//   const headingStyle = {
//     fontSize: isMobile ? "2rem" : "3rem",
//     fontWeight: "bold",
//     color: "#0e003d",
//     marginBottom: "0px",
//     textAlign: "center",
//     padding: "1vh",
//     lineHeight: "1.8",
//     animation: "slideInFromLeft 1.2s ease-out",
//   };

//   const paragraphStyle = {
//     padding: isMobile ? "10px" : "20px",
//     fontSize: isMobile ? "1rem" : "1.5rem",
//     lineHeight: "1.6",
//     color: "#004d99",
//     textAlign: "justify",
//     animation: "slideInFromRight 1.2s ease-out",
//   };

//   const buttonContainerStyle = {
//     display: "flex",
//     justifyContent: "center",
//     flexDirection: isMobile ? "column" : "row",
//     marginTop: "20px",
//     animation: "fadeInUp 1.5s ease-in-out",
//   };

//   const buttonStyle = {
//     backgroundColor: "#0e003d",
//     border: "none",
//     fontSize: isMobile ? "0.9rem" : "1rem",
//     fontWeight: "bold",
//     color: "white",
//     textAlign: "center",
//     padding: "15px 25px",
//     margin: isMobile ? "10px 0" : "0 10px",
//     cursor: "pointer",
//     transition:
//       "transform 0.3s ease, background-color 0.3s ease, box-shadow 0.3s ease",
//     borderRadius: "5px",
//   };

//   const imageStyle = {
//     flex: 1,
//     maxWidth: "100%",
//     height: "auto",
//     borderRadius: "10px",
//     boxShadow: "0px 4px 6px rgba(12, 9, 91, 0.1)",
//     animation: "zoomIn 1.2s ease-in-out",
//   };

//   return (
//     <div style={containerStyle}>
//       <img
//         src="./Download_Study_Literature_concept_for_free-removebg-preview.png" /* Replace with the correct image URL */
//         alt="Learning Illustration"
//         style={imageStyle}
//       />
//       <div style={contentStyle}>
//         <p style={headingStyle}>GYANKOSHA</p>
//         <p style={paragraphStyle}>
//           Gyankosha, in alignment with NEP guidelines, is providing
//           comprehensive learning that focuses on developing mental agility,
//           diversified perspectives, and 21st-century skills critical for
//           students. Partnering with schools, teachers, parents, and students to
//           deliver academic excellence, skill development, and holistic learning
//           solutions.
//         </p>
//         <div style={buttonContainerStyle}>
//           <button
//             style={buttonStyle}
//             onMouseEnter={(e) => (e.target.style.transform = "scale(1.05)")}
//             onMouseLeave={(e) => (e.target.style.transform = "scale(1)")}
//           >
//             EXPLORE SOLUTIONS FOR SCHOOLS
//           </button>
//           <button
//             style={buttonStyle}
//             onMouseEnter={(e) => (e.target.style.transform = "scale(1.05)")}
//             onMouseLeave={(e) => (e.target.style.transform = "scale(1)")}
//           >
//             START LEARNING AS STUDENT
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default MediaContainer2;
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

function MediaContainer2() {
  const [isImageHovered, setImageHovered] = useState(false);
  const [isButtonHovered, setButtonHovered] = useState({});
  const navigate = useNavigate();

  const containerStyle = {
    display: "flex",
    justifyContent: "space-between",
    background: "#e0f7fa",
    alignItems: "center",
    padding: "20px",
    gap: "20px",
    marginTop: "110vh",
    background:
      "linear-gradient(to left,rgb(251, 252, 252), #e0f7fa,rgb(128, 206, 234))",
    flexDirection: window.innerWidth <= 1000 ? "column" : "row",
    textAlign: window.innerWidth <= 767 ? "center" : "left",
    fontFamily: '"Libre Baskerville", serif',
  };

  const contentStyle = {
    flex: 1,
    padding: window.innerWidth <= 767 ? "10px" : "20px",
  };

  const headingStyle = {
    fontSize: window.innerWidth <= 767 ? "2rem" : "3rem",
    fontWeight: "bold",
    color: " #0e003d",
    marginBottom: "0px",
    textAlign: "center",
    fontFamily: `"Libre Baskerville", serif`,
    padding: "1vh",
    lineHeight: "1.8",
  };

  const paragraphStyle = {
    padding: window.innerWidth <= 767 ? "10px" : "20px",
    fontSize: window.innerWidth <= 767 ? "1rem" : "1.5rem",
    lineHeight: "1.6",
    color: "#004d99",
    textAlign: "justify",
  };

  const buttonContainerStyle = {
    fontFamily: `"Libre Baskerville", serif`,
    display: "flex",
    justifyContent: "center",
    flexDirection: window.innerWidth <= 767 ? "column" : "row",
    marginTop: "20px",
  };

  const buttonStyle = {
    backgroundColor: "#0e003d",
    border: "none",
    fontSize: window.innerWidth <= 767 ? "0.9rem" : "1rem",
    fontWeight: "bold",
    color: "white",
    textAlign: "center",
    transition:
      "transform 0.3s ease, background-color 0.3s ease, box-shadow 0.3s ease",
    padding: "20px",
    marginRight: window.innerWidth <= 767 ? "0" : "10px",
    marginBottom: window.innerWidth <= 767 ? "10px" : "0",
    cursor: "pointer",
  };

  const imageStyle = {
    flex: 1,
    maxWidth: "100%",
    height: "auto",
    borderRadius: "10px",
    boxShadow: "0px 4px 6px rgba(12, 9, 91, 0.1)",
    transition: "transform 0.3s ease, box-shadow 0.3s ease",
    transform: isImageHovered ? "scale(1.1)" : "scale(1)",
  };

  const buttonHoverStyle = (hovered) => ({
    ...buttonStyle,
    backgroundColor: hovered ? "#004d99" : "#0e003d",
    color: hovered ? "#e0f7fa" : "white",
    transform: hovered ? "scale(1.05)" : "scale(1)",
  });
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  return (
    <div style={containerStyle}>
      <img
        src="./Download_Study_Literature_concept_for_free-removebg-preview.png"
        alt="Learning Illustration"
        style={imageStyle}
        onMouseEnter={() => setImageHovered(true)}
        onMouseLeave={() => setImageHovered(false)}
      />
      <div style={contentStyle}>
        <p style={headingStyle}>GYANKOSHA</p>
        <p style={paragraphStyle}>
          Gyankosha, in alignment with NEP guidelines, is providing
          comprehensive learning that focuses on developing mental agility,
          diversified perspectives, and 21st-century skills critical for
          students. Partnering with schools, teachers, parents, and students to
          deliver academic excellence, skill development, and holistic learning
          solutions.
        </p>
        <div style={buttonContainerStyle}>
          <button
            style={buttonHoverStyle(isButtonHovered[0])}
            onMouseEnter={() =>
              setButtonHovered((prev) => ({ ...prev, 0: true }))
            }
            onMouseLeave={() =>
              setButtonHovered((prev) => ({ ...prev, 0: false }))
            }
            onClick={() => scrollToSection("MediaContainer")}
          >
            EXPLORE SOLUTIONS FOR SCHOOLS
          </button>
          <button
            style={buttonHoverStyle(isButtonHovered[1])}
            onMouseEnter={() =>
              setButtonHovered((prev) => ({ ...prev, 1: true }))
            }
            onMouseLeave={() =>
              setButtonHovered((prev) => ({ ...prev, 1: false }))
            }
            onClick={() => scrollToSection("productofferings")}
          >
            START LEARNING AS STUDENT
          </button>
        </div>
      </div>
    </div>
  );
}

export default MediaContainer2;
