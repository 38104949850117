// import React, { useState, useEffect, useRef } from "react";
// import axios from "axios";
// import "bootstrap/dist/css/bootstrap.min.css";

// const AddArticle = () => {
//   const [title, setTitle] = useState("");
//   const [content, setContent] = useState([]);
//   const [author, setAuthor] = useState("");
//   const [division, setDivision] = useState("Student");
//   const [currentContent, setCurrentContent] = useState({
//     type: "text",
//     value: "",
//   });
//   const [titleImage, setTitleImage] = useState(""); // New state for title image
//   const [isPublished, setIsPublished] = useState(false);
//   const contentIdRef = useRef(0); // To keep track of unique IDs for content

//   // Load content from localStorage on component mount
//   useEffect(() => {
//     const storedContent = JSON.parse(localStorage.getItem("articleContent"));
//     if (storedContent) {
//       setContent(storedContent);
//       contentIdRef.current = storedContent.length; // Resume IDs from the stored content
//     }
//   }, []);

//   // Save content to localStorage whenever it changes
//   useEffect(() => {
//     localStorage.setItem("articleContent", JSON.stringify(content));
//   }, [content]);

//   const handleImageUpload = async (e, setImageCallback) => {
//     const file = e.target.files[0];
//     if (!file) {
//       alert("No file selected.");
//       return;
//     }

//     const formData = new FormData();
//     formData.append("image", file);

//     try {
//       const response = await axios.post(`/api/articles/upload`, formData, {
//         headers: { "Content-Type": "multipart/form-data" },
//       });

//       const imageUrl = response.data.imageUrl;
//       setImageCallback(imageUrl); // Set the uploaded image URL
//       alert("Image uploaded successfully!");
//     } catch (error) {
//       console.error("Image upload failed:", error);
//       alert("Failed to upload the image. Please try again.");
//     }
//   };

//   const handleAddContent = () => {
//     if (
//       (currentContent.type === "text" && !currentContent.value.trim()) ||
//       (currentContent.type === "image" && !currentContent.value)
//     ) {
//       alert("Content cannot be empty.");
//       return;
//     }

//     const contentWithId = {
//       ...currentContent,
//       id: contentIdRef.current++, // Increment the unique ID for each new content
//     };

//     setContent([...content, contentWithId]);
//     setCurrentContent({ type: "text", value: "" }); // Reset content input
//     alert("Content added successfully!");
//   };

//   const handleRemoveContent = (id) => {
//     setContent(content.filter((item) => item.id !== id));
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     const newArticle = {
//       title,
//       content,
//       author,
//       division,
//       titleImage, // Include the title image in the article data
//       isPublished,
//     };

//     try {
//       const response = await axios.post(
//         "/api/articles/addarticles",
//         newArticle
//       );

//       alert("Article and content saved successfully!");
//       setTitle("");
//       setAuthor("");
//       setContent([]);
//       setTitleImage(""); // Reset the title image
//       setIsPublished(false);
//       localStorage.removeItem("articleContent");
//     } catch (error) {
//       console.error("Failed to submit the article:", error);
//       alert("Error submitting the article. Please try again.");
//     }
//   };

//   return (
//     <div className="container mt-4">
//       <h1>Add Article</h1>
//       <form onSubmit={handleSubmit}>
//         <div className="mb-3">
//           <label className="form-label">Title</label>
//           <input
//             type="text"
//             className="form-control"
//             value={title}
//             onChange={(e) => setTitle(e.target.value)}
//             required
//           />
//         </div>
//         <div className="mb-3">
//           <label className="form-label">Author</label>
//           <input
//             type="text"
//             className="form-control"
//             value={author}
//             onChange={(e) => setAuthor(e.target.value)}
//             required
//           />
//         </div>
//         <div className="mb-3">
//           <label className="form-label">Division</label>
//           <select
//             className="form-select"
//             value={division}
//             onChange={(e) => setDivision(e.target.value)}
//           >
//             <option value="">Select Category</option>
//             <option value="Student">Student</option>
//             <option value="Teacher">Teacher</option>
//             <option value="Parent">Parent</option>
//           </select>
//         </div>
//         <div className="mb-3">
//           <label className="form-label">Title Image</label>
//           <input
//             type="file"
//             className="form-control"
//             accept="image/*"
//             onChange={(e) => handleImageUpload(e, setTitleImage)}
//           />
//           {titleImage && (
//             <div className="mt-2">
//               <img
//                 src={titleImage}
//                 alt="Title Preview"
//                 style={{ maxWidth: "100%", height: "auto" }}
//               />
//             </div>
//           )}
//         </div>
//         <div className="mb-3">
//           <label className="form-label">Add Content</label>
//           <div className="d-flex">
//             <select
//               className="form-select me-2"
//               value={currentContent.type}
//               onChange={(e) =>
//                 setCurrentContent({ ...currentContent, type: e.target.value })
//               }
//             >
//               <option value="text">Text</option>
//               <option value="image">Image</option>
//             </select>
//             {currentContent.type === "text" ? (
//               <input
//                 type="text"
//                 className="form-control"
//                 value={currentContent.value}
//                 onChange={(e) =>
//                   setCurrentContent({
//                     ...currentContent,
//                     value: e.target.value,
//                   })
//                 }
//               />
//             ) : (
//               <input
//                 type="file"
//                 className="form-control"
//                 accept="image/*"
//                 onChange={(e) =>
//                   handleImageUpload(e, (url) =>
//                     setCurrentContent({ type: "image", value: url })
//                   )
//                 }
//               />
//             )}
//           </div>
//           <button
//             type="button"
//             className="btn btn-primary mt-2"
//             onClick={handleAddContent}
//           >
//             Add Content
//           </button>
//         </div>
//         <ul className="list-group mb-3">
//           {content.map((item) => (
//             <li key={item.id} className="list-group-item">
//               {item.type === "text" ? (
//                 <span>{item.value}</span>
//               ) : (
//                 <img
//                   src={item.value}
//                   alt="Uploaded"
//                   style={{ maxWidth: "100%" }}
//                 />
//               )}
//               <button
//                 type="button"
//                 className="btn btn-danger btn-sm float-end"
//                 onClick={() => handleRemoveContent(item.id)}
//               >
//                 Remove
//               </button>
//             </li>
//           ))}
//         </ul>
//         <div className="form-check mb-3">
//           <input
//             className="form-check-input"
//             type="checkbox"
//             checked={isPublished}
//             onChange={(e) => setIsPublished(e.target.checked)}
//           />
//           <label className="form-check-label">Publish Now</label>
//         </div>
//         <button type="submit" className="btn btn-success">
//           Submit Article
//         </button>
//       </form>
//     </div>
//   );
// };

// export default AddArticle;
import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";

const AddArticle = () => {
  const [title, setTitle] = useState("");
  const [content, setContent] = useState([]);
  const [author, setAuthor] = useState("");
  const [division, setDivision] = useState("Student");
  const [currentContent, setCurrentContent] = useState({
    type: "text",
    value: "",
  });
  const [titleImage, setTitleImage] = useState("");
  const [isPublished, setIsPublished] = useState(false);
  const contentIdRef = useRef(0);

  useEffect(() => {
    const storedContent = JSON.parse(localStorage.getItem("articleContent"));
    if (storedContent) {
      setContent(storedContent);
      contentIdRef.current = storedContent.length;
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("articleContent", JSON.stringify(content));
  }, [content]);

  const handleImageUpload = async (e, setImageCallback) => {
    const file = e.target.files[0];
    if (!file) {
      alert("No file selected.");
      return;
    }

    const formData = new FormData();
    formData.append("image", file);

    try {
      const response = await axios.post(`/api/articles/upload`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      const imageUrl = response.data.imageUrl;
      setImageCallback(imageUrl);
      alert("Image uploaded successfully!");
    } catch (error) {
      console.error("Image upload failed:", error);
      alert("Failed to upload the image. Please try again.");
    }
  };

  const handleAddContent = () => {
    if (!currentContent.value.trim()) {
      alert("Content cannot be empty.");
      return;
    }

    const contentWithId = {
      ...currentContent,
      id: contentIdRef.current++,
    };

    setContent([...content, contentWithId]);
    setCurrentContent({ type: "text", value: "" });
    alert("Content added successfully!");
  };

  const handleRemoveContent = (id) => {
    setContent(content.filter((item) => item.id !== id));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newArticle = {
      title,
      content,
      author,
      division,
      titleImage,
      isPublished,
    };

    try {
      const response = await axios.post(
        "/api/articles/addarticles",
        newArticle
      );

      alert("Article and content saved successfully!");
      setTitle("");
      setAuthor("");
      setContent([]);
      setTitleImage("");
      setIsPublished(false);
      localStorage.removeItem("articleContent");
    } catch (error) {
      console.error("Failed to submit the article:", error);
      alert("Error submitting the article. Please try again.");
    }
  };

  return (
    <div className="container mt-4">
      <h1>Add Article</h1>
      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label className="form-label">Title</label>
          <input
            type="text"
            className="form-control"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />
        </div>
        <div className="mb-3">
          <label className="form-label">Author</label>
          <input
            type="text"
            className="form-control"
            value={author}
            onChange={(e) => setAuthor(e.target.value)}
            required
          />
        </div>
        <div className="mb-3">
          <label className="form-label">Division</label>
          <select
            className="form-select"
            value={division}
            onChange={(e) => setDivision(e.target.value)}
          >
            <option value="">Select Category</option>
            <option value="Student">Student</option>
            <option value="Teacher">Teacher</option>
            <option value="Parent">Parent</option>
            <option value="Stories">Stories</option>
          </select>
        </div>
        <div className="mb-3">
          <label className="form-label">Title Image</label>
          <input
            type="file"
            className="form-control"
            accept="image/*"
            onChange={(e) => handleImageUpload(e, setTitleImage)}
          />
          {titleImage && (
            <div className="mt-2">
              <img
                src={titleImage}
                alt="Title Preview"
                style={{ maxWidth: "100%", height: "auto" }}
              />
            </div>
          )}
        </div>
        <div className="mb-3">
          <label className="form-label">Add Content</label>
          <div className="d-flex">
            <select
              className="form-select me-2"
              value={currentContent.type}
              onChange={(e) =>
                setCurrentContent({ ...currentContent, type: e.target.value })
              }
            >
              <option value="text">Text</option>
              <option value="image">Image</option>
              <option value="youtube">YouTube</option>
              <option value="heading">Heading</option>
            </select>
            {currentContent.type === "image" ? (
              <input
                type="file"
                className="form-control"
                accept="image/*"
                onChange={(e) =>
                  handleImageUpload(e, (url) =>
                    setCurrentContent({ type: "image", value: url })
                  )
                }
              />
            ) : (
              <input
                type="text"
                className="form-control"
                value={currentContent.value}
                onChange={(e) =>
                  setCurrentContent({
                    ...currentContent,
                    value: e.target.value,
                  })
                }
              />
            )}
          </div>
          <button
            type="button"
            className="btn btn-primary mt-2"
            onClick={handleAddContent}
          >
            Add Content
          </button>
        </div>
        <ul className="list-group mb-3">
          {content.map((item) => (
            <li key={item.id} className="list-group-item">
              {item.type === "text" && <span>{item.value}</span>}
              {item.type === "heading" && (
                <h5 className="mb-0">{item.value}</h5>
              )}
              {item.type === "image" && (
                <img
                  src={item.value}
                  alt="Uploaded"
                  style={{ maxWidth: "100%" }}
                />
              )}
              {item.type === "youtube" && (
                <iframe
                  width="560"
                  height="315"
                  src={item.value}
                  title="YouTube video"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              )}
              <button
                type="button"
                className="btn btn-danger btn-sm float-end"
                onClick={() => handleRemoveContent(item.id)}
              >
                Remove
              </button>
            </li>
          ))}
        </ul>
        <div className="form-check mb-3">
          <input
            className="form-check-input"
            type="checkbox"
            checked={isPublished}
            onChange={(e) => setIsPublished(e.target.checked)}
          />
          <label className="form-check-label">Publish Now</label>
        </div>
        <button type="submit" className="btn btn-success">
          Submit Article
        </button>
      </form>
    </div>
  );
};

export default AddArticle;
