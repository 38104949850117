// import React, { useState, useEffect } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import axios from "axios";
// import { getAllSubjects } from "../actions/subjectActions";
// import { getAllDifficultys } from "../actions/difficultyActions";
// import { getAllClassss } from "../actions/classsActions";

// const QuizUploadCSV = () => {
//   const [name, setName] = useState("");
//   const [difficulty, setDifficulty] = useState("");
//   const [classs, setClasss] = useState("");
//   const [division, setDivision] = useState("");
//   const [subject, setSubject] = useState("");
//   const [file, setFile] = useState(null);
//   const [loading, setLoading] = useState(false);

//   const subjectsState = useSelector((state) => state.getAllSubjectsReducer);
//   const {
//     subjects,
//     loading: subjectsLoading,
//     error: subjectsError,
//   } = subjectsState;

//   const difficultysState = useSelector(
//     (state) => state.getAllDifficultysReducer
//   );
//   const {
//     difficultys,
//     loading: difficultysLoading,
//     error: difficultysError,
//   } = difficultysState;

//   const classssState = useSelector((state) => state.getAllClassssReducer);
//   const {
//     classss,
//     loading: classssLoading,
//     error: classssError,
//   } = classssState;

//   useEffect(() => {
//     dispatch(getAllSubjects());
//     dispatch(getAllDifficultys());
//     dispatch(getAllClassss());
//   }, [dispatch]);

//   useEffect(() => {
//     const request = indexedDB.open("quizImagesDB", 1);

//     request.onupgradeneeded = (event) => {
//       const db = event.target.result;
//       db.createObjectStore("quizImages", { keyPath: "id" });
//     };

//     request.onsuccess = (event) => {
//       setDb(event.target.result);
//     };

//     request.onerror = (event) => {
//       console.error("Error opening IndexedDB:", event.target.error);
//     };
//   }, []);

//   // Helper function to add days to a date
//   const addDays = (date, days) => {
//     const result = new Date(date);
//     result.setDate(result.getDate() + days);
//     return result;
//   };

//   // Function to generate the week list starting from 1st April
//   const generateWeeks = () => {
//     const weeks = [];
//     let startDate = new Date("2024-04-01"); // Starting from 1st April 2024

//     for (let i = 1; i <= 52; i++) {
//       const startOfWeek = new Date(startDate); // Clone the start date
//       const endOfWeek = addDays(startOfWeek, 6); // End of the week (6 days after start)

//       const formattedStart = startOfWeek.toLocaleDateString("en-GB", {
//         day: "2-digit",
//         month: "short",
//       });
//       const formattedEnd = endOfWeek.toLocaleDateString("en-GB", {
//         day: "2-digit",
//         month: "short",
//       });

//       weeks.push({
//         weekNum: i,
//         start: formattedStart,
//         end: formattedEnd,
//       });

//       // Move the startDate to the next week
//       startDate = addDays(startDate, 7);
//     }
//     return weeks;
//   };

//   const weeks = generateWeeks();

//   const handleFileChange = (e) => {
//     setFile(e.target.files[0]);
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     setLoading(true);

//     try {
//       // Step 1: Create the empty quiz
//       const quizData = {
//         name,
//         difficulty,
//         classs,
//         division,
//         subject,
//       };

//       const quizResponse = await axios.post("/api/quizs/addquiz", quizData);
//       const quizId = quizResponse.data.quizId;

//       alert("Empty quiz created successfully!");

//       // Step 2: Upload the questions via CSV
//       if (!file) {
//         alert("Please select a CSV file to upload questions.");
//         setLoading(false);
//         return;
//       }

//       const formData = new FormData();
//       formData.append("file", file);
//       formData.append("quizId", quizId);

//       await axios.post("/api/quizs/uploadquestionscsv", formData, {
//         headers: { "Content-Type": "multipart/form-data" },
//       });

//       alert("Questions uploaded successfully!");
//     } catch (error) {
//       console.error("Error during submission:", error);
//       alert("An error occurred while submitting the quiz or questions.");
//     } finally {
//       setLoading(false);
//     }
//   };

//   return (
//     <div>
//       <h1>Create Quiz and Upload Questions</h1>
//       <form onSubmit={handleSubmit}>
//         {/* Quiz Details */}
//         <div>
//           <label>Quiz Name:</label>
//           <input
//             type="text"
//             value={name}
//             onChange={(e) => setName(e.target.value)}
//             required
//           />
//         </div>
//         <div>
//           <label>Difficulty:</label>
//           <input
//             type="text"
//             value={difficulty}
//             onChange={(e) => setDifficulty(e.target.value)}
//             required
//           />
//         </div>
//         <div>
//           <label>Class:</label>
//           <input
//             type="text"
//             value={classs}
//             onChange={(e) => setClasss(e.target.value)}
//             required
//           />
//         </div>
//         <div>
//           <label>Division:</label>
//           <input
//             type="text"
//             value={division}
//             onChange={(e) => setDivision(e.target.value)}
//             required
//           />
//         </div>
//         <div>
//           <label>Subject:</label>
//           <input
//             type="text"
//             value={subject}
//             onChange={(e) => setSubject(e.target.value)}
//             required
//           />
//         </div>

//         {/* File Upload */}
//         <div>
//           <label>Upload CSV:</label>
//           <input type="file" onChange={handleFileChange} accept=".csv" />
//         </div>

//         {/* Submit Button */}
//         <button type="submit" disabled={loading}>
//           {loading ? "Submitting..." : "Submit"}
//         </button>
//       </form>
//     </div>
//   );
// };

// export default QuizUploadCSV;
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { getAllSubjects } from "../actions/subjectActions";
import { getAllDifficultys } from "../actions/difficultyActions";
import { getAllClassss } from "../actions/classsActions";

const KyebyeQuizUploadCSV = () => {
  const [name, setName] = useState("");
  const [difficulty, setDifficulty] = useState("");
  const [classs, setClasss] = useState("");
  const [division, setDivision] = useState("");
  const [subject, setSubject] = useState("");
  const [week, setWeek] = useState(0); // Track selected week
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [image, setImage] = useState(null);
  const [chapter, setchapter] = useState("");
  const [chapters, setchapters] = useState([]);
  const subjectsState = useSelector((state) => state.getAllSubjectsReducer);
  const { subjects } = subjectsState;

  const difficultysState = useSelector(
    (state) => state.getAllDifficultysReducer
  );
  const { difficultys } = difficultysState;

  const classssState = useSelector((state) => state.getAllClassssReducer);
  const { classss } = classssState;
  useEffect(() => {
    const fetchChapters = async () => {
      if (division && subject && classs) {
        try {
          const response = await axios.get(
            `/api/chapters/fetchchapters?division=${division}&subject=${subject}&classs=${classs}`
          );

          console.log("Fetched chapters:", response.data);
          setchapters(Array.isArray(response.data) ? response.data : []);
        } catch (error) {
          console.error("Error fetching chapters:", error);
        }
      } else {
        setchapters([]); // Reset chapters if any dependency is missing
      }
    };

    fetchChapters();
  }, [division, subject, classs]); // Ensure dependencies match

  useEffect(() => {
    dispatch(getAllSubjects());
    dispatch(getAllDifficultys());
    dispatch(getAllClassss());
  }, [dispatch]);

  // Helper function to add days to a date
  const addDays = (date, days) => {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  };

  // Generate the week list starting from 1st April
  const generateWeeks = () => {
    const weeks = [];
    let startDate = new Date("2024-04-01");

    for (let i = 1; i <= 52; i++) {
      const startOfWeek = new Date(startDate);
      const endOfWeek = addDays(startOfWeek, 6);

      const formattedStart = startOfWeek.toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "short",
      });
      const formattedEnd = endOfWeek.toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "short",
      });

      weeks.push({
        weekNum: i,
        start: formattedStart,
        end: formattedEnd,
      });

      startDate = addDays(startDate, 7);
    }
    return weeks;
  };

  const weeks = generateWeeks();

  const handleImageChange = (e) => {
    setImage(e.target.files[0]); // Set image file state
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      // Step 1: Create the empty quiz
      const quizData = {
        name,
        difficulty,
        classs,
        division,
        subject,
      };

      const formData = new FormData();
      formData.append("name", name);
      formData.append("difficulty", difficulty);
      formData.append("classs", classs);
      formData.append("division", division);
      formData.append("subject", subject);
      formData.append("chapter", chapter);
      // Step 2: Include the quiz image if it exists
      if (image) {
        formData.append("quiz_image", image); // Adding the quiz image field
      }

      const response = await axios.post(
        "/api/KyebyeQuiz/addKyebyeQuiz",
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
          timeout: 600000, // Set timeout to 10 minutes
        }
      );

      const quizId = response.data.quizId;
      console.log("quiz id", quizId);
      alert("Quiz with empty questions submitted successfully");

      // Step 3: Upload the questions via CSV
      if (!file) {
        alert("Please select a CSV file to upload questions.");
        setLoading(false);
        return;
      }
      const csvFormData = new FormData();
      csvFormData.append("file", file);

      await axios.post(
        `/api/KyebyeQuiz/uploadkyebyequestionscsv/${quizId}`,
        csvFormData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      alert("Questions uploaded successfully!");
    } catch (error) {
      console.error("Error during submission:", error);
      alert("An error occurred while submitting the quiz or questions.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <hr />
      <h1>Add New KYEBYE Quiz</h1>
      <hr />
      <form onSubmit={handleSubmit} encType="multipart/form-data">
        <div className="form-group">
          <label>Name</label>
          <input
            type="text"
            className="form-control"
            placeholder="Enter quiz name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            style={{ border: "1px solid black", marginBottom: "10px" }}
          />
        </div>

        <div className="form-group">
          <label>Category</label>
          <select
            className="form-control"
            value={difficulty}
            onChange={(e) => setDifficulty(e.target.value)}
            style={{ marginBottom: "10px" }}
          >
            <option value="">Select Category</option>
            {difficultys &&
              difficultys.map((diff) => (
                <option key={diff._id} value={diff.diff}>
                  {diff.diff}
                </option>
              ))}
          </select>
        </div>

        <div className="form-group">
          <label>Class</label>
          <select
            className="form-control"
            value={classs}
            onChange={(e) => setClasss(e.target.value)}
            style={{ marginBottom: "10px" }}
          >
            <option value="">Select Class</option>
            {classss &&
              classss.map((cls) => (
                <option key={cls._id} value={cls.cls}>
                  {cls.cls}
                </option>
              ))}
          </select>
        </div>

        <div className="form-group">
          <label>Division</label>
          <select
            className="form-control"
            value={division}
            onChange={(e) => setDivision(e.target.value)}
            style={{ marginBottom: "10px" }}
          >
            <option value="">Select Division</option>
            <option value="Kye Bye">Kye Bye</option>
            <option value="Business Wisdom">Business Wisdom</option>
          </select>
        </div>

        <div className="form-group">
          <label>Subject</label>
          <select
            className="form-control"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            style={{ marginBottom: "10px" }}
          >
            <option value="">Select Subject</option>
            {subjects &&
              subjects.map((sub) => (
                <option key={sub._id} value={sub.sub}>
                  {sub.sub}
                </option>
              ))}
          </select>
        </div>

        {/* Image Upload Section */}
        <div className="form-group">
          <label>Upload Image</label>
          <input
            type="file"
            className="form-control"
            onChange={handleImageChange}
            accept="image/*"
            style={{ marginBottom: "10px" }}
          />
        </div>
        <div className="form-group">
          <label>Chapter</label>
          <select
            className="form-control"
            value={chapter}
            onChange={(e) => setchapter(e.target.value)}
          >
            <option value="">Select Chapter</option>
            {Array.isArray(chapters) &&
              chapters.map((chap) => (
                <option key={chap._id} value={chap.chapter}>
                  {chap.chapter}
                </option>
              ))}
          </select>
        </div>

        <div className="form-group">
          <label>Week</label>
          <select
            className="form-control"
            value={week}
            onChange={(e) => setWeek(e.target.value)}
            style={{ marginBottom: "10px" }}
          >
            <option value={0}>Select Week</option>
            {weeks.map(({ weekNum, start, end }) => (
              <option key={weekNum} value={weekNum}>
                Week {weekNum}: {start} - {end}
              </option>
            ))}
          </select>
        </div>

        {/* File Upload Section */}
        <div className="form-group">
          <label>Upload CSV File</label>
          <input
            type="file"
            className="form-control"
            onChange={handleFileChange}
            accept=".csv"
            style={{ marginBottom: "10px" }}
          />
        </div>

        <button type="submit" className="btn btn-primary" disabled={loading}>
          {loading ? "Submitting..." : "Submit"}
        </button>
      </form>
    </div>
  );
};

export default KyebyeQuizUploadCSV;
