// import React, { useState, useEffect } from "react";
// import toast from "react-hot-toast";
// import { Button } from "react-bootstrap";
// import Modal from "react-bootstrap/Modal";
// import "./pay.css";
// import { useDispatch, useSelector } from "react-redux";
// import {
//   deleteUser,
//   getAllUsers,
//   updatePaidStatus,
// } from "../actions/userActions";

// const Paymentplansscreen = () => {
//   const [showReferralModal, setShowReferralModal] = useState(false);
//   const [hasReferralCode, setHasReferralCode] = useState(null); // Track Yes/No selection
//   const [referralCodeVerified, setReferralCodeVerified] = useState(false);
//   const [verifyingReferral, setVerifyingReferral] = useState(false);
//   const [selectedPlanId, setSelectedPlanId] = useState(null);
//   const [selectedTotalCount, setSelectedTotalCount] = useState(null);

//   const [showModal, setShowModal] = useState(false);
//   const [referralCode, setReferralCode] = useState(null);
//   const [referralInput, setReferralInput] = useState(""); // State to hold the referral code input
//   const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
//   const [subscriptionPlan, setSubscriptionPlan] = useState(null); // To store the user's subscription plan from MongoDB
//   const [subs, setSubs] = useState(null);
//   const [loadingPlan, setLoadingPlan] = useState(null); // Add a state to track the loading button
//   const usersstate = useSelector((state) => state.getAllUsersReducer);
//   const userstate = useSelector((state) => state.loginUserReducer);
//   const { currentUser1 } = userstate;
//   const { error, loading, users } = usersstate;

//   useEffect(() => {
//     const handleResize = () => {
//       setIsMobile(window.innerWidth <= 767);
//     };

//     window.addEventListener("resize", handleResize);
//     return () => {
//       window.removeEventListener("resize", handleResize);
//     };
//   }, []);

//   // Fetch user's subscription plan from MongoDB
//   useEffect(() => {
//     const fetchUserSubscription = async () => {
//       try {
//         const res = await fetch(`/api/users/${currentUser1._id}`);
//         const data = await res.json();
//         setSubscriptionPlan(data.subscription?.plan || null); // Set the subscription plan
//         setSubs(data.subscription);
//       } catch (error) {
//         console.error("Error fetching user subscription:", error);
//       }
//     };

//     if (currentUser1) {
//       if (currentUser1?._id) {
//         fetchUserSubscription();
//       }
//     } else {
//       alert(
//         "You are not Logged in. Login first to subscribe by clicking on the Login link on the top-right corner."
//       );
//     }
//   }, [currentUser1]);

//   const buttonShadowStyle = {
//     boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.5)",
//     transition: "background-color 0.3s",
//   };

//   const scrollToSection = (id, offset = 0) => {
//     const section = document.getElementById(id);
//     if (section) {
//       const elementPosition =
//         section.getBoundingClientRect().top + window.scrollY;
//       const offsetPosition = elementPosition - offset;

//       window.scrollTo({
//         top: offsetPosition,
//         behavior: "smooth",
//       });
//     }
//   };

//   // Handle modal close event
//   const handleCloseModal = () => {
//     setShowModal(false);

//     // Trigger page reload only when modal is closed
//     window.location.reload();
//   };

//   // handleSubscription Function
//   const handleSubscription = async (plan_id, total_count) => {
//     setLoadingPlan(plan_id); // Set loading for the clicked plan
//     try {
//       const res = await fetch(`/api/payment/subscription`, {
//         method: "POST",
//         headers: {
//           "content-type": "application/json",
//         },
//         body: JSON.stringify({
//           plan_id,
//           userId: currentUser1._id, // Pass user ID to backend
//           total_count,
//           referralCode: referralInput.trim(), // Include referral code in request
//         }),
//       });

//       const data = await res.json();
//       console.log(data);
//       handlePaymentVerify(data.data);
//       // setReferralCode(data.referralCode); // Set referral code if returned
//       // setShowModal(true);
//       setLoadingPlan(null); // Reset loading state in case of error
//     } catch (error) {
//       console.log(error);
//       setLoadingPlan(null); // Reset loading state in case of error
//     }
//   };

//   const verifyReferralCode = async () => {
//     setVerifyingReferral(true);
//     try {
//       console.log("referralInput : ", referralInput);
//       const res = await fetch(`/api/users/verifyReferral`, {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify({ referralCode: referralInput.trim() }),
//       });
//       const data = await res.json();

//       console.log("data : ", data);
//       if (data.success) {
//         setReferralCodeVerified(true);
//         toast.success("Referral code verified successfully!");
//       } else {
//         toast.error("Invalid referral code.");
//       }
//     } catch (error) {
//       console.error("Error verifying referral code:", error);
//       toast.error("Failed to verify referral code.");
//     } finally {
//       setVerifyingReferral(false);
//     }
//   };

//   // handlePaymentVerify Function
//   const handlePaymentVerify = async (data) => {
//     const options = {
//       key: process.env.REACT_APP_RAZORPAY_KEY_ID,
//       subscription_id: data.id,
//       name: "Gyankosha",
//       description: "Payment for Quiz Subscription",
//       handler: async (response) => {
//         console.log("response", response);
//         try {
//           const res = await fetch(`/api/payment/verify`, {
//             method: "POST",
//             headers: {
//               "content-type": "application/json",
//             },
//             body: JSON.stringify({
//               razorpay_subscription_id: response.razorpay_subscription_id,
//               razorpay_payment_id: response.razorpay_payment_id,
//               razorpay_signature: response.razorpay_signature,
//               userId: currentUser1._id, // Pass user ID for verification
//             }),
//           });

//           const verifyData = await res.json();

//           if (verifyData.message) {
//             toast.success(verifyData.message);
//             console.log("verifyData.referralCode : ", verifyData.referralCode);
//             if (verifyData.referralCode) {
//               setReferralCode(verifyData.referralCode);
//               setShowModal(true);
//             }
//             // Reload the page after 2 seconds
//             // setTimeout(() => {
//             //   window.location.reload();
//             // }, 2000);
//           }
//         } catch (error) {
//           console.log(error);
//         } finally {
//           setLoadingPlan(null); // Reset loading state in case of error
//         }
//       },
//       theme: {
//         color: "#5f63b8",
//       },
//     };
//     const rzp1 = new window.Razorpay(options);
//     rzp1.open();
//   };

//   // Inside Paymentplansscreen.js

//   const handleUnsubscribe = async () => {
//     try {
//       console.log("subs : ", subs);
//       console.log("subs id : ", subs.id);
//       const res = await fetch(`/api/payment/unsubscribe`, {
//         method: "POST",
//         headers: {
//           "content-type": "application/json",
//         },
//         body: JSON.stringify({
//           // subscriptionId: currentUser1.subscription?.id,
//           subscriptionId: subs.id,
//           userId: currentUser1._id,
//         }),
//       });

//       const data = await res.json();

//       if (data.message) {
//         toast.success(data.message);

//         // Reload the page after 2 seconds
//         setTimeout(() => {
//           window.location.reload();
//         }, 2000);
//       }
//     } catch (error) {
//       console.log(error);
//       toast.error("Error unsubscribing from the plan.");
//     }
//   };

//   const renderPlanButton = (planId, total_count) => {
//     const isSubscribed = subscriptionPlan === planId;

//     return isSubscribed && subs.status == "active" ? (
//       <button
//         className="plan-button"
//         style={{ ...buttonShadowStyle }}
//         onClick={handleUnsubscribe}
//       >
//         Unsubscribe
//       </button>
//     ) : (
//       !(subscriptionPlan && subs.status == "active") && (
//         <button
//           className="plan-button"
//           style={{ ...buttonShadowStyle }}
//           onClick={() => {
//             setShowReferralModal(true); // Open the modal
//             setReferralCodeVerified(false); // Reset verification
//             setHasReferralCode(null); // Reset Yes/No selection
//             setSelectedPlanId(planId); // Store the selected planId
//             setSelectedTotalCount(total_count); // Store the selected total_count
//           }}
//           disabled={!!(subscriptionPlan && subs.status == "active")} // Disable if any subscription exists
//         >
//           {loadingPlan === planId ? "Please Wait..." : "Get Now"}
//         </button>
//       )
//     );
//   };

//   return (
//     <div
//       className="pricing-table"
//       style={{
//         marginTop: isMobile ? "150px" : "70px",
//         backgroundColor: "#f0f0f0",
//         padding: "20px",
//         fontFamily: '"Libre Baskerville", serif',
//       }}
//     >
//       <h3 className="mt-5 mb-5">
//         In order to access more quizzes, please purchase one of the below
//         mentioned plans:
//       </h3>

//       {/* Link Bar */}
//       <div
//         className="link-bar"
//         style={{
//           // backgroundColor: "#5f63b8",
//           display: "flex",
//           flexWrap: "wrap",
//           justifyContent: "center",
//           alignItems: "center", // Centers items vertically
//           backgroundColor: "#000080",
//           padding: "10px",
//           position: "fixed",
//           width: "100%",
//           top: 140,
//           zIndex: 100,
//         }}
//       >
//         {[
//           { id: "udbodh", label: "Udbodh" },
//           { id: "bauddha-dakshata", label: "Bauddha Dakshata" },
//           { id: "kyebye", label: "KyeBye" },
//           { id: "abhyudaya", label: "Abhyudaya" },
//           { id: "combo-1", label: "Combo 1" },
//           { id: "combo-2", label: "Combo 2" },
//           { id: "combo-3", label: "Combo 3" },
//           { id: "combo-4", label: "Combo 4" },
//           { id: "combo-5", label: "Combo 5" },
//           { id: "combo-6", label: "Combo 6" },
//           { id: "combo-7", label: "Combo 7" },
//           { id: "combo-8", label: "Combo 8" },
//         ].map((section) => (
//           <button
//             key={section.id}
//             style={{
//               color: "white",
//               background: "none",
//               border: "none",
//               margin: "0 0px",
//               cursor: "pointer",
//               fontSize: "15px",
//               fontWeight: "bold",
//               transition: "background-color 0.3s, color 0.3s", // Smooth transition effect
//               borderRadius: "50px", // Rounded pill shape
//               padding: "5px 15px", // Add padding for a pill-like appearance
//             }}
//             onMouseEnter={(e) => {
//               e.target.style.color = "#5f63b8"; // Hover text color
//               e.target.style.background = "white"; // Hover background color
//             }}
//             onMouseLeave={(e) => {
//               e.target.style.color = "white"; // Original text color
//               e.target.style.background = "none"; // Original background color
//             }}
//             onClick={() => scrollToSection(section.id, isMobile ? 350 : 195)}
//           >
//             {section.label}
//           </button>
//         ))}
//       </div>

//       {/* <div className="mb-3">
//         <label htmlFor="referralInput" className="form-label">
//           Have a referral code? Enter here:
//         </label>
//         <input
//           type="text"
//           className="form-control"
//           id="referralInput"
//           value={referralInput}
//           onChange={(e) => setReferralInput(e.target.value)}
//           placeholder="Enter referral code"
//         />
//       </div> */}

//       <h2
//         id="udbodh"
//         style={{
//           fontFamily: '"Libre Baskerville", serif',
//           fontWeight: "bold",
//         }}
//       >
//         Udbodh
//       </h2>
//       <div
//         className="plans d-flex flex-wrap shadow-lg rounded-5"
//         style={{ padding: "20px", marginRight: "30px", marginLeft: "30px" }}
//       >
//         <div className="plan shadow">
//           <h3 className="plan-title yearly">Yearly Plan</h3>
//           <p className="plan-price">
//             <small>
//               <small>
//                 <small>
//                   <small>
//                     <small>
//                       <s className="text-muted">₹ 3600</s>
//                     </small>
//                   </small>
//                 </small>
//               </small>
//             </small>
//             {"\n "}₹3240
//           </p>
//           <ul className="plan-details">
//             <li>Validity: 365 days from the date of subscription.</li>
//             <li>Access to Udbodh.</li>
//           </ul>
//           {/* <button
//             className="plan-button"
//             style={{ ...buttonShadowStyle }}
//             onClick={() =>
//               handleSubscription(
//                 process.env.REACT_APP_PLAN_ID_UDBODH_ANNUALLY,
//                 8
//               )
//             }
//           >
//             Get Now
//           </button> */}
//           {renderPlanButton(process.env.REACT_APP_PLAN_ID_UDBODH_ANNUALLY, 8)}
//         </div>

//         <div className="plan shadow">
//           <h3 className="plan-title half-yearly">Half Yearly Plan</h3>
//           <p className="plan-price">
//             <small>
//               <small>
//                 <small>
//                   <small>
//                     <small>
//                       <s className="text-muted">₹ 1800</s>
//                     </small>
//                   </small>
//                 </small>
//               </small>
//             </small>
//             {"\n "}₹1710
//           </p>

//           <ul className="plan-details">
//             <li>Validity: 180 days from the date of subscription.</li>
//             <li>Access to Udbodh.</li>
//           </ul>
//           {/* <button
//             className="plan-button"
//             style={{ ...buttonShadowStyle }}
//             onClick={() =>
//               handleSubscription(
//                 process.env.REACT_APP_PLAN_ID_UDBODH_HALFYEARLY,
//                 16
//               )
//             }
//           >
//             Get Now
//           </button> */}
//           {renderPlanButton(
//             process.env.REACT_APP_PLAN_ID_UDBODH_HALFYEARLY,
//             16
//           )}
//         </div>

//         <div className="plan shadow">
//           <h3 className="plan-title quarterly">Quarterly Plan</h3>
//           <p className="plan-price">
//             <small>
//               <small>
//                 <small>
//                   <small>
//                     <small>
//                       <s className="text-muted">₹ 900</s>
//                     </small>
//                   </small>
//                 </small>
//               </small>
//             </small>
//             {"\n "}₹878
//           </p>

//           <ul className="plan-details">
//             <li>Validity: 90 days from the date of subscription.</li>
//             <li>Access to Udbodh.</li>
//           </ul>
//           {/* <button
//             className="plan-button"
//             style={{ ...buttonShadowStyle }}
//             onClick={() =>
//               handleSubscription(
//                 process.env.REACT_APP_PLAN_ID_UDBODH_QUARTERLY,
//                 32
//               )
//             }
//           >
//             Get Now
//           </button> */}
//           {renderPlanButton(process.env.REACT_APP_PLAN_ID_UDBODH_QUARTERLY, 32)}
//         </div>

//         <div className="plan shadow">
//           <h3 className="plan-title monthly">Monthly Plan</h3>
//           <p className="plan-price">{"\n "}₹300</p>

//           <ul className="plan-details">
//             <li>Validity: 30 days from the date of subscription.</li>
//             <li>Access to Udbodh.</li>
//           </ul>
//           {/* <button
//             className="plan-button"
//             style={{ ...buttonShadowStyle }}
//             onClick={() =>
//               handleSubscription(
//                 process.env.REACT_APP_PLAN_ID_UDBODH_MONTHLY,
//                 96
//               )
//             }
//           >
//             Get Now
//           </button> */}
//           {renderPlanButton(process.env.REACT_APP_PLAN_ID_UDBODH_MONTHLY, 96)}
//         </div>
//       </div>

//       {/*-------------------------------------------------------------------------------------------------------------------*/}

//       <h2
//         id="bauddha-dakshata"
//         className="mt-5"
//         style={{
//           fontFamily: '"Libre Baskerville", serif',
//           fontWeight: "bold",
//         }}
//       >
//         Bauddha Dakshata
//       </h2>
//       <div
//         className="plans d-flex flex-wrap shadow-lg rounded-5"
//         style={{ padding: "20px", marginRight: "30px", marginLeft: "30px" }}
//       >
//         <div className="plan shadow">
//           <h3 className="plan-title yearly">Yearly Plan</h3>
//           <p className="plan-price">
//             <small>
//               <small>
//                 <small>
//                   <small>
//                     <small>
//                       <s className="text-muted">₹ 6000</s>
//                     </small>
//                   </small>
//                 </small>
//               </small>
//             </small>
//             {"\n "}₹5400
//           </p>

//           <ul className="plan-details">
//             <li>Validity: 365 days from the date of subscription.</li>
//             <li>Access to Bauddha Dakshata.</li>
//           </ul>
//           {/* <button
//             className="plan-button"
//             style={{ ...buttonShadowStyle }}
//             onClick={() =>
//               handleSubscription(process.env.REACT_APP_PLAN_ID_BD_ANNUALLY, 8)
//             }
//           >
//             Get Now
//           </button> */}
//           {renderPlanButton(process.env.REACT_APP_PLAN_ID_BD_ANNUALLY, 8)}
//         </div>

//         <div className="plan shadow">
//           <h3 className="plan-title half-yearly">Half Yearly Plan</h3>
//           <p className="plan-price">
//             <small>
//               <small>
//                 <small>
//                   <small>
//                     <small>
//                       <s className="text-muted">₹ 3000</s>
//                     </small>
//                   </small>
//                 </small>
//               </small>
//             </small>
//             {"\n "}₹2850
//           </p>

//           <ul className="plan-details">
//             <li>Validity: 180 days from the date of subscription.</li>
//             <li>Access to Bauddha Dakshata.</li>
//           </ul>
//           {/* <button
//             className="plan-button"
//             style={{ ...buttonShadowStyle }}
//             onClick={() =>
//               handleSubscription(
//                 process.env.REACT_APP_PLAN_ID_BD_HALFYEARLY,
//                 16
//               )
//             }
//           >
//             Get Now
//           </button> */}
//           {renderPlanButton(process.env.REACT_APP_PLAN_ID_BD_HALFYEARLY, 16)}
//         </div>

//         <div className="plan shadow">
//           <h3 className="plan-title quarterly">Quarterly Plan</h3>
//           <p className="plan-price">
//             <small>
//               <small>
//                 <small>
//                   <small>
//                     <small>
//                       <s className="text-muted">₹ 1500</s>
//                     </small>
//                   </small>
//                 </small>
//               </small>
//             </small>
//             {"\n "}₹1463
//           </p>

//           <ul className="plan-details">
//             <li>Validity: 90 days from the date of subscription.</li>
//             <li>Access to Bauddha Dakshata.</li>
//           </ul>
//           {/* <button
//             className="plan-button"
//             style={{ ...buttonShadowStyle }}
//             onClick={() =>
//               handleSubscription(process.env.REACT_APP_PLAN_ID_BD_QUARTERLY, 32)
//             }
//           >
//             Get Now
//           </button> */}
//           {renderPlanButton(process.env.REACT_APP_PLAN_ID_BD_QUARTERLY, 32)}
//         </div>

//         <div className="plan shadow">
//           <h3 className="plan-title monthly">Monthly Plan</h3>
//           <p className="plan-price">{"\n "}₹500</p>

//           <ul className="plan-details">
//             <li>Validity: 30 days from the date of subscription.</li>
//             <li>Access to Bauddha Dakshata.</li>
//           </ul>
//           {/* <button
//             className="plan-button"
//             style={{ ...buttonShadowStyle }}
//             onClick={() =>
//               handleSubscription(process.env.REACT_APP_PLAN_ID_BD_MONTHLY, 96)
//             }
//           >
//             Get Now
//           </button> */}
//           {renderPlanButton(process.env.REACT_APP_PLAN_ID_BD_MONTHLY, 96)}
//         </div>
//       </div>

//       {/*-------------------------------------------------------------------------------------------------------------------*/}

//       <h2
//         id="kyebye"
//         className="mt-5"
//         style={{
//           fontFamily: '"Libre Baskerville", serif',
//           fontWeight: "bold",
//         }}
//       >
//         KyeBye
//       </h2>
//       <div
//         className="plans d-flex flex-wrap shadow-lg rounded-5"
//         style={{ padding: "20px", marginRight: "30px", marginLeft: "30px" }}
//       >
//         <div className="plan shadow">
//           <h3 className="plan-title yearly">Yearly Plan</h3>
//           <p className="plan-price">
//             <small>
//               <small>
//                 <small>
//                   <small>
//                     <small>
//                       <s className="text-muted">₹ 6000</s>
//                     </small>
//                   </small>
//                 </small>
//               </small>
//             </small>
//             {"\n "}₹5400
//           </p>

//           <ul className="plan-details">
//             <li>Validity: 365 days from the date of subscription.</li>
//             <li>Access to KyeBye.</li>
//           </ul>
//           {/* <button
//             className="plan-button"
//             style={{ ...buttonShadowStyle }}
//             onClick={() =>
//               handleSubscription(
//                 process.env.REACT_APP_PLAN_ID_KYEBYE_ANNUALLY,
//                 8
//               )
//             }
//           >
//             Get Now
//           </button> */}
//           {renderPlanButton(process.env.REACT_APP_PLAN_ID_KYEBYE_ANNUALLY, 8)}
//         </div>

//         <div className="plan shadow">
//           <h3 className="plan-title half-yearly">Half Yearly Plan</h3>
//           <p className="plan-price">
//             <small>
//               <small>
//                 <small>
//                   <small>
//                     <small>
//                       <s className="text-muted">₹ 3000</s>
//                     </small>
//                   </small>
//                 </small>
//               </small>
//             </small>
//             {"\n "}₹2850
//           </p>

//           <ul className="plan-details">
//             <li>Validity: 180 days from the date of subscription.</li>
//             <li>Access to KyeBye.</li>
//           </ul>
//           {/* <button
//             className="plan-button"
//             style={{ ...buttonShadowStyle }}
//             onClick={() =>
//               handleSubscription(
//                 process.env.REACT_APP_PLAN_ID_KYEBYE_HALFYEARLY,
//                 16
//               )
//             }
//           >
//             Get Now
//           </button> */}
//           {renderPlanButton(
//             process.env.REACT_APP_PLAN_ID_KYEBYE_HALFYEARLY,
//             16
//           )}
//         </div>

//         <div className="plan shadow">
//           <h3 className="plan-title quarterly">Quarterly Plan</h3>
//           <p className="plan-price">
//             <small>
//               <small>
//                 <small>
//                   <small>
//                     <small>
//                       <s className="text-muted">₹ 1500</s>
//                     </small>
//                   </small>
//                 </small>
//               </small>
//             </small>
//             {"\n "}₹1463
//           </p>

//           <ul className="plan-details">
//             <li>Validity: 90 days from the date of subscription.</li>
//             <li>Access to KyeBye.</li>
//           </ul>
//           {/* <button
//             className="plan-button"
//             style={{ ...buttonShadowStyle }}
//             onClick={() =>
//               handleSubscription(
//                 process.env.REACT_APP_PLAN_ID_KYEBYE_QUARTERLY,
//                 32
//               )
//             }
//           >
//             Get Now
//           </button> */}
//           {renderPlanButton(process.env.REACT_APP_PLAN_ID_KYEBYE_QUARTERLY, 32)}
//         </div>

//         <div className="plan shadow">
//           <h3 className="plan-title monthly">Monthly Plan</h3>
//           <p className="plan-price">{"\n "}₹500</p>

//           <ul className="plan-details">
//             <li>Validity: 30 days from the date of subscription.</li>
//             <li>Access to KyeBye.</li>
//           </ul>
//           {/* <button
//             className="plan-button"
//             style={{ ...buttonShadowStyle }}
//             onClick={() =>
//               handleSubscription(
//                 process.env.REACT_APP_PLAN_ID_KYEBYE_MONTHLY,
//                 96
//               )
//             }
//           >
//             Get Now
//           </button> */}
//           {renderPlanButton(process.env.REACT_APP_PLAN_ID_KYEBYE_MONTHLY, 96)}
//         </div>
//       </div>
//       {/*-------------------------------------------------------------------------------------------------------------------*/}

//       <h2
//         id="abhyudaya"
//         className="mt-5"
//         style={{
//           fontFamily: '"Libre Baskerville", serif',
//           fontWeight: "bold",
//         }}
//       >
//         Abhyudaya
//       </h2>
//       <div
//         className="plans d-flex flex-wrap shadow-lg rounded-5"
//         style={{ padding: "20px", marginRight: "30px", marginLeft: "30px" }}
//       >
//         <div className="plan shadow">
//           <h3 className="plan-title yearly">Yearly Plan</h3>
//           <p className="plan-price">
//             <small>
//               <small>
//                 <small>
//                   <small>
//                     <small>
//                       <s className="text-muted">₹ 12000</s>
//                     </small>
//                   </small>
//                 </small>
//               </small>
//             </small>
//             {"\n "}₹10800
//           </p>

//           <ul className="plan-details">
//             <li>Validity: 365 days from the date of subscription.</li>
//             <li>Access to Abhyudaya.</li>
//           </ul>
//           {/* <button
//             className="plan-button"
//             style={{ ...buttonShadowStyle }}
//             onClick={() =>
//               handleSubscription(
//                 process.env.REACT_APP_PLAN_ID_ABHYUDAYA_ANNUALLY,
//                 8
//               )
//             }
//           >
//             Get Now
//           </button> */}
//           {renderPlanButton(
//             process.env.REACT_APP_PLAN_ID_ABHYUDAYA_ANNUALLY,
//             8
//           )}
//         </div>

//         <div className="plan shadow">
//           <h3 className="plan-title half-yearly">Half Yearly Plan</h3>
//           <p className="plan-price">
//             <small>
//               <small>
//                 <small>
//                   <small>
//                     <small>
//                       <s className="text-muted">₹ 6000</s>
//                     </small>
//                   </small>
//                 </small>
//               </small>
//             </small>
//             {"\n "}₹5700
//           </p>

//           <ul className="plan-details">
//             <li>Validity: 180 days from the date of subscription.</li>
//             <li>Access to Abhyudaya.</li>
//           </ul>
//           {/* <button
//             className="plan-button"
//             style={{ ...buttonShadowStyle }}
//             onClick={() =>
//               handleSubscription(
//                 process.env.REACT_APP_PLAN_ID_ABHYUDAYA_HALFYEARLY,
//                 16
//               )
//             }
//           >
//             Get Now
//           </button> */}
//           {renderPlanButton(
//             process.env.REACT_APP_PLAN_ID_ABHYUDAYA_HALFYEARLY,
//             16
//           )}
//         </div>

//         <div className="plan shadow">
//           <h3 className="plan-title quarterly">Quarterly Plan</h3>
//           <p className="plan-price">
//             <small>
//               <small>
//                 <small>
//                   <small>
//                     <small>
//                       <s className="text-muted">₹ 3000</s>
//                     </small>
//                   </small>
//                 </small>
//               </small>
//             </small>
//             {"\n "}₹2925
//           </p>

//           <ul className="plan-details">
//             <li>Validity: 90 days from the date of subscription.</li>
//             <li>Access to Abhyudaya.</li>
//           </ul>
//           {/* <button
//             className="plan-button"
//             style={{ ...buttonShadowStyle }}
//             onClick={() =>
//               handleSubscription(
//                 process.env.REACT_APP_PLAN_ID_ABHYUDAYA_QUARTERLY,
//                 32
//               )
//             }
//           >
//             Get Now
//           </button> */}
//           {renderPlanButton(
//             process.env.REACT_APP_PLAN_ID_ABHYUDAYA_QUARTERLY,
//             32
//           )}
//         </div>

//         <div className="plan shadow">
//           <h3 className="plan-title monthly">Monthly Plan</h3>
//           <p className="plan-price">{"\n "}₹1000</p>

//           <ul className="plan-details">
//             <li>Validity: 30 days from the date of subscription.</li>
//             <li>Access to Abhyudaya.</li>
//           </ul>
//           {/* <button
//             className="plan-button"
//             style={{ ...buttonShadowStyle }}
//             onClick={() =>
//               handleSubscription(
//                 process.env.REACT_APP_PLAN_ID_ABHYUDAYA_MONTHLY,
//                 96
//               )
//             }
//           >
//             Get Now
//           </button> */}
//           {renderPlanButton(
//             process.env.REACT_APP_PLAN_ID_ABHYUDAYA_MONTHLY,
//             96
//           )}
//         </div>
//       </div>

//       {/*-------------------------------------------------------------------------------------------------------------------*/}

//       <h2
//         id="combo-1"
//         className="mt-5"
//         style={{
//           fontFamily: '"Libre Baskerville", serif',
//           fontWeight: "bold",
//         }}
//       >
//         Combo 1 ( Udbodh + Bauddha Dakshata )
//       </h2>
//       <div
//         className="plans d-flex flex-wrap shadow-lg rounded-5"
//         style={{ padding: "20px", marginRight: "30px", marginLeft: "30px" }}
//       >
//         <div className="plan shadow">
//           <h3 className="plan-title yearly">Yearly Plan</h3>
//           <p className="plan-price">
//             <small>
//               <small>
//                 <small>
//                   <small>
//                     <small>
//                       <s className="text-muted">₹ 8400</s>
//                     </small>
//                   </small>
//                 </small>
//               </small>
//             </small>
//             {"\n "}₹7560
//           </p>

//           <ul className="plan-details">
//             <li>Validity: 365 days from the date of subscription.</li>
//             <li>Access to Udbodh and Bauddha Dakshata.</li>
//           </ul>
//           {/* <button
//             className="plan-button"
//             style={{ ...buttonShadowStyle }}
//             onClick={() =>
//               handleSubscription(
//                 process.env.REACT_APP_PLAN_ID_COMBO_ONE_ANNUALLY,
//                 8
//               )
//             }
//           >
//             Get Now
//           </button> */}
//           {renderPlanButton(
//             process.env.REACT_APP_PLAN_ID_COMBO_ONE_ANNUALLY,
//             8
//           )}
//         </div>

//         <div className="plan shadow">
//           <h3 className="plan-title half-yearly">Half Yearly Plan</h3>
//           <p className="plan-price">
//             <small>
//               <small>
//                 <small>
//                   <small>
//                     <small>
//                       <s className="text-muted">₹ 4200</s>
//                     </small>
//                   </small>
//                 </small>
//               </small>
//             </small>
//             {"\n "}₹3990
//           </p>

//           <ul className="plan-details">
//             <li>Validity: 180 days from the date of subscription.</li>
//             <li>Access to Udbodh and Bauddha Dakshata.</li>
//           </ul>
//           {/* <button
//             className="plan-button"
//             style={{ ...buttonShadowStyle }}
//             onClick={() =>
//               handleSubscription(
//                 process.env.REACT_APP_PLAN_ID_COMBO_ONE_HALFYEARLY,
//                 16
//               )
//             }
//           >
//             Get Now
//           </button> */}
//           {renderPlanButton(
//             process.env.REACT_APP_PLAN_ID_COMBO_ONE_HALFYEARLY,
//             16
//           )}
//         </div>

//         <div className="plan shadow">
//           <h3 className="plan-title quarterly">Quarterly Plan</h3>
//           <p className="plan-price">
//             <small>
//               <small>
//                 <small>
//                   <small>
//                     <small>
//                       <s className="text-muted">₹ 2100</s>
//                     </small>
//                   </small>
//                 </small>
//               </small>
//             </small>
//             {"\n "}₹2048
//           </p>

//           <ul className="plan-details">
//             <li>Validity: 90 days from the date of subscription.</li>
//             <li>Access to Udbodh and Bauddha Dakshata.</li>
//           </ul>
//           {/* <button
//             className="plan-button"
//             style={{ ...buttonShadowStyle }}
//             onClick={() =>
//               handleSubscription(
//                 process.env.REACT_APP_PLAN_ID_COMBO_ONE_QUARTERLY,
//                 32
//               )
//             }
//           >
//             Get Now
//           </button> */}
//           {renderPlanButton(
//             process.env.REACT_APP_PLAN_ID_COMBO_ONE_QUARTERLY,
//             32
//           )}
//         </div>

//         <div className="plan shadow">
//           <h3 className="plan-title monthly">Monthly Plan</h3>
//           <p className="plan-price">{"\n "}₹700</p>

//           <ul className="plan-details">
//             <li>Validity: 30 days from the date of subscription.</li>
//             <li>Access to Udbodh and Bauddha Dakshata.</li>
//           </ul>
//           {/* <button
//             className="plan-button"
//             style={{ ...buttonShadowStyle }}
//             onClick={() =>
//               handleSubscription(
//                 process.env.REACT_APP_PLAN_ID_COMBO_ONE_MONTHLY,
//                 96
//               )
//             }
//           >
//             Get Now
//           </button> */}
//           {renderPlanButton(
//             process.env.REACT_APP_PLAN_ID_COMBO_ONE_MONTHLY,
//             96
//           )}
//         </div>
//       </div>

//       {/*-------------------------------------------------------------------------------------------------------------------*/}

//       <h2
//         id="combo-2"
//         className="mt-5"
//         style={{
//           fontFamily: '"Libre Baskerville", serif',
//           fontWeight: "bold",
//         }}
//       >
//         Combo 2 ( Udbodh + KyeBye )
//       </h2>
//       <div
//         className="plans d-flex flex-wrap shadow-lg rounded-5"
//         style={{ padding: "20px", marginRight: "30px", marginLeft: "30px" }}
//       >
//         <div className="plan shadow">
//           <h3 className="plan-title yearly">Yearly Plan</h3>
//           <p className="plan-price">
//             <small>
//               <small>
//                 <small>
//                   <small>
//                     <small>
//                       <s className="text-muted">₹ 8400</s>
//                     </small>
//                   </small>
//                 </small>
//               </small>
//             </small>
//             {"\n "}₹7560
//           </p>

//           <ul className="plan-details">
//             <li>Validity: 365 days from the date of subscription.</li>
//             <li>Access to Udbodh and KyeBye.</li>
//           </ul>
//           {/* <button
//             className="plan-button"
//             style={{ ...buttonShadowStyle }}
//             onClick={() =>
//               handleSubscription(
//                 process.env.REACT_APP_PLAN_ID_COMBO_TWO_ANNUALLY,
//                 8
//               )
//             }
//           >
//             Get Now
//           </button> */}
//           {renderPlanButton(
//             process.env.REACT_APP_PLAN_ID_COMBO_TWO_ANNUALLY,
//             8
//           )}
//         </div>

//         <div className="plan shadow">
//           <h3 className="plan-title half-yearly">Half Yearly Plan</h3>
//           <p className="plan-price">
//             <small>
//               <small>
//                 <small>
//                   <small>
//                     <small>
//                       <s className="text-muted">₹ 4200</s>
//                     </small>
//                   </small>
//                 </small>
//               </small>
//             </small>
//             {"\n "}₹3990
//           </p>

//           <ul className="plan-details">
//             <li>Validity: 180 days from the date of subscription.</li>
//             <li>Access to Udbodh and KyeBye.</li>
//           </ul>
//           {/* <button
//             className="plan-button"
//             style={{ ...buttonShadowStyle }}
//             onClick={() =>
//               handleSubscription(
//                 process.env.REACT_APP_PLAN_ID_COMBO_TWO_HALFYEARLY,
//                 16
//               )
//             }
//           >
//             Get Now
//           </button> */}
//           {renderPlanButton(
//             process.env.REACT_APP_PLAN_ID_COMBO_TWO_HALFYEARLY,
//             16
//           )}
//         </div>

//         <div className="plan shadow">
//           <h3 className="plan-title quarterly">Quarterly Plan</h3>
//           <p className="plan-price">
//             <small>
//               <small>
//                 <small>
//                   <small>
//                     <small>
//                       <s className="text-muted">₹ 2100</s>
//                     </small>
//                   </small>
//                 </small>
//               </small>
//             </small>
//             {"\n "}₹2048
//           </p>

//           <ul className="plan-details">
//             <li>Validity: 90 days from the date of subscription.</li>
//             <li>Access to Udbodh and KyeBye.</li>
//           </ul>
//           {/* <button
//             className="plan-button"
//             style={{ ...buttonShadowStyle }}
//             onClick={() =>
//               handleSubscription(
//                 process.env.REACT_APP_PLAN_ID_COMBO_TWO_QUARTERLY,
//                 32
//               )
//             }
//           >
//             Get Now
//           </button> */}
//           {renderPlanButton(
//             process.env.REACT_APP_PLAN_ID_COMBO_TWO_QUARTERLY,
//             32
//           )}
//         </div>

//         <div className="plan shadow">
//           <h3 className="plan-title monthly">Monthly Plan</h3>
//           <p className="plan-price">{"\n "}₹700</p>

//           <ul className="plan-details">
//             <li>Validity: 30 days from the date of subscription.</li>
//             <li>Access to Udbodh and KyeBye.</li>
//           </ul>
//           {/* <button
//             className="plan-button"
//             style={{ ...buttonShadowStyle }}
//             onClick={() =>
//               handleSubscription(
//                 process.env.REACT_APP_PLAN_ID_COMBO_TWO_MONTHLY,
//                 96
//               )
//             }
//           >
//             Get Now
//           </button> */}
//           {renderPlanButton(
//             process.env.REACT_APP_PLAN_ID_COMBO_TWO_MONTHLY,
//             96
//           )}
//         </div>
//       </div>

//       {/*-------------------------------------------------------------------------------------------------------------------*/}

//       <h2
//         id="combo-3"
//         className="mt-5"
//         style={{
//           fontFamily: '"Libre Baskerville", serif',
//           fontWeight: "bold",
//         }}
//       >
//         Combo 3 ( Udbodh + Abhyudaya )
//       </h2>
//       <div
//         className="plans d-flex flex-wrap shadow-lg rounded-5"
//         style={{ padding: "20px", marginRight: "30px", marginLeft: "30px" }}
//       >
//         <div className="plan shadow">
//           <h3 className="plan-title yearly">Yearly Plan</h3>
//           <p className="plan-price">
//             <small>
//               <small>
//                 <small>
//                   <small>
//                     <small>
//                       <s className="text-muted">₹ 14400</s>
//                     </small>
//                   </small>
//                 </small>
//               </small>
//             </small>
//             {"\n "}₹12960
//           </p>

//           <ul className="plan-details">
//             <li>Validity: 365 days from the date of subscription.</li>
//             <li>Access to Udbodh and Abhyudaya.</li>
//           </ul>
//           {/* <button
//             className="plan-button"
//             style={{ ...buttonShadowStyle }}
//             onClick={() =>
//               handleSubscription(
//                 process.env.REACT_APP_PLAN_ID_COMBO_THREE_ANNUALLY,
//                 8
//               )
//             }
//           >
//             Get Now
//           </button> */}
//           {renderPlanButton(
//             process.env.REACT_APP_PLAN_ID_COMBO_THREE_ANNUALLY,
//             8
//           )}
//         </div>

//         <div className="plan shadow">
//           <h3 className="plan-title half-yearly">Half Yearly Plan</h3>
//           <p className="plan-price">
//             <small>
//               <small>
//                 <small>
//                   <small>
//                     <small>
//                       <s className="text-muted">₹ 7200</s>
//                     </small>
//                   </small>
//                 </small>
//               </small>
//             </small>
//             {"\n "}₹6840
//           </p>

//           <ul className="plan-details">
//             <li>Validity: 180 days from the date of subscription.</li>
//             <li>Access to Udbodh and Abhyudaya.</li>
//           </ul>
//           {/* <button
//             className="plan-button"
//             style={{ ...buttonShadowStyle }}
//             onClick={() =>
//               handleSubscription(
//                 process.env.REACT_APP_PLAN_ID_COMBO_THREE_HALFYEARLY,
//                 16
//               )
//             }
//           >
//             Get Now
//           </button> */}
//           {renderPlanButton(
//             process.env.REACT_APP_PLAN_ID_COMBO_THREE_HALFYEARLY,
//             16
//           )}
//         </div>

//         <div className="plan shadow">
//           <h3 className="plan-title quarterly">Quarterly Plan</h3>
//           <p className="plan-price">
//             <small>
//               <small>
//                 <small>
//                   <small>
//                     <small>
//                       <s className="text-muted">₹ 3600</s>
//                     </small>
//                   </small>
//                 </small>
//               </small>
//             </small>
//             {"\n "}₹3510
//           </p>

//           <ul className="plan-details">
//             <li>Validity: 90 days from the date of subscription.</li>
//             <li>Access to Udbodh and Abhyudaya.</li>
//           </ul>
//           {/* <button
//             className="plan-button"
//             style={{ ...buttonShadowStyle }}
//             onClick={() =>
//               handleSubscription(
//                 process.env.REACT_APP_PLAN_ID_COMBO_THREE_QUARTERLY,
//                 32
//               )
//             }
//           >
//             Get Now
//           </button> */}
//           {renderPlanButton(
//             process.env.REACT_APP_PLAN_ID_COMBO_THREE_QUARTERLY,
//             32
//           )}
//         </div>

//         <div className="plan shadow">
//           <h3 className="plan-title monthly">Monthly Plan</h3>
//           <p className="plan-price">{"\n "}₹1200</p>

//           <ul className="plan-details">
//             <li>Validity: 30 days from the date of subscription.</li>
//             <li>Access to Udbodh and Abhyudaya.</li>
//           </ul>
//           {/* <button
//             className="plan-button"
//             style={{ ...buttonShadowStyle }}
//             onClick={() =>
//               handleSubscription(
//                 process.env.REACT_APP_PLAN_ID_COMBO_THREE_MONTHLY,
//                 96
//               )
//             }
//           >
//             Get Now
//           </button> */}
//           {renderPlanButton(
//             process.env.REACT_APP_PLAN_ID_COMBO_THREE_MONTHLY,
//             96
//           )}
//         </div>
//       </div>

//       {/*-------------------------------------------------------------------------------------------------------------------*/}

//       <h2
//         id="combo-4"
//         className="mt-5"
//         style={{
//           fontFamily: '"Libre Baskerville", serif',
//           fontWeight: "bold",
//         }}
//       >
//         Combo 4 ( Bauddha Dakshata + KyeBye )
//       </h2>
//       <div
//         className="plans d-flex flex-wrap shadow-lg rounded-5"
//         style={{ padding: "20px", marginRight: "30px", marginLeft: "30px" }}
//       >
//         <div className="plan shadow">
//           <h3 className="plan-title yearly">Yearly Plan</h3>
//           <p className="plan-price">
//             <small>
//               <small>
//                 <small>
//                   <small>
//                     <small>
//                       <s className="text-muted">₹ 10800</s>
//                     </small>
//                   </small>
//                 </small>
//               </small>
//             </small>
//             {"\n "}₹9720
//           </p>

//           <ul className="plan-details">
//             <li>Validity: 365 days from the date of subscription.</li>
//             <li>Access to Bauddha Dakshata and KyeBye.</li>
//           </ul>
//           {/* <button
//             className="plan-button"
//             style={{ ...buttonShadowStyle }}
//             onClick={() =>
//               handleSubscription(
//                 process.env.REACT_APP_PLAN_ID_COMBO_FOUR_ANNUALLY,
//                 8
//               )
//             }
//           >
//             Get Now
//           </button> */}
//           {renderPlanButton(
//             process.env.REACT_APP_PLAN_ID_COMBO_FOUR_ANNUALLY,
//             8
//           )}
//         </div>

//         <div className="plan shadow">
//           <h3 className="plan-title half-yearly">Half Yearly Plan</h3>
//           <p className="plan-price">
//             <small>
//               <small>
//                 <small>
//                   <small>
//                     <small>
//                       <s className="text-muted">₹ 5400</s>
//                     </small>
//                   </small>
//                 </small>
//               </small>
//             </small>
//             {"\n "}₹5130
//           </p>

//           <ul className="plan-details">
//             <li>Validity: 180 days from the date of subscription.</li>
//             <li>Access to Bauddha Dakshata and KyeBye.</li>
//           </ul>
//           {/* <button
//             className="plan-button"
//             style={{ ...buttonShadowStyle }}
//             onClick={() =>
//               handleSubscription(
//                 process.env.REACT_APP_PLAN_ID_COMBO_FOUR_HALFYEARLY,
//                 16
//               )
//             }
//           >
//             Get Now
//           </button> */}
//           {renderPlanButton(
//             process.env.REACT_APP_PLAN_ID_COMBO_FOUR_HALFYEARLY,
//             16
//           )}
//         </div>

//         <div className="plan shadow">
//           <h3 className="plan-title quarterly">Quarterly Plan</h3>
//           <p className="plan-price">
//             <small>
//               <small>
//                 <small>
//                   <small>
//                     <small>
//                       <s className="text-muted">₹ 2700</s>
//                     </small>
//                   </small>
//                 </small>
//               </small>
//             </small>
//             {"\n "}₹2633
//           </p>

//           <ul className="plan-details">
//             <li>Validity: 90 days from the date of subscription.</li>
//             <li>Access to Bauddha Dakshata and KyeBye.</li>
//           </ul>
//           {/* <button
//             className="plan-button"
//             style={{ ...buttonShadowStyle }}
//             onClick={() =>
//               handleSubscription(
//                 process.env.REACT_APP_PLAN_ID_COMBO_FOUR_QUARTERLY,
//                 32
//               )
//             }
//           >
//             Get Now
//           </button> */}
//           {renderPlanButton(
//             process.env.REACT_APP_PLAN_ID_COMBO_FOUR_QUARTERLY,
//             32
//           )}
//         </div>

//         <div className="plan shadow">
//           <h3 className="plan-title monthly">Monthly Plan</h3>
//           <p className="plan-price">{"\n "}₹900</p>

//           <ul className="plan-details">
//             <li>Validity: 30 days from the date of subscription.</li>
//             <li>Access to Bauddha Dakshata and KyeBye.</li>
//           </ul>
//           {/* <button
//             className="plan-button"
//             style={{ ...buttonShadowStyle }}
//             onClick={() =>
//               handleSubscription(
//                 process.env.REACT_APP_PLAN_ID_COMBO_FOUR_MONTHLY,
//                 96
//               )
//             }
//           >
//             Get Now
//           </button> */}
//           {renderPlanButton(
//             process.env.REACT_APP_PLAN_ID_COMBO_FOUR_MONTHLY,
//             96
//           )}
//         </div>
//       </div>

//       {/*-------------------------------------------------------------------------------------------------------------------*/}

//       <h2
//         id="combo-5"
//         className="mt-5"
//         style={{
//           fontFamily: '"Libre Baskerville", serif',
//           fontWeight: "bold",
//         }}
//       >
//         Combo 5 ( Bauddha Dakshata + Abhyudaya )
//       </h2>
//       <div
//         className="plans d-flex flex-wrap shadow-lg rounded-5"
//         style={{ padding: "20px", marginRight: "30px", marginLeft: "30px" }}
//       >
//         <div className="plan shadow">
//           <h3 className="plan-title yearly">Yearly Plan</h3>
//           <p className="plan-price">
//             <small>
//               <small>
//                 <small>
//                   <small>
//                     <small>
//                       <s className="text-muted">₹ 16800</s>
//                     </small>
//                   </small>
//                 </small>
//               </small>
//             </small>
//             {"\n "}₹15120
//           </p>

//           <ul className="plan-details">
//             <li>Validity: 365 days from the date of subscription.</li>
//             <li>Access to Bauddha Dakshata and Abhyudaya.</li>
//           </ul>
//           {/* <button
//             className="plan-button"
//             style={{ ...buttonShadowStyle }}
//             onClick={() =>
//               handleSubscription(
//                 process.env.REACT_APP_PLAN_ID_COMBO_FIVE_ANNUALLY,
//                 8
//               )
//             }
//           >
//             Get Now
//           </button> */}
//           {renderPlanButton(
//             process.env.REACT_APP_PLAN_ID_COMBO_FIVE_ANNUALLY,
//             8
//           )}
//         </div>

//         <div className="plan shadow">
//           <h3 className="plan-title half-yearly">Half Yearly Plan</h3>
//           <p className="plan-price">
//             <small>
//               <small>
//                 <small>
//                   <small>
//                     <small>
//                       <s className="text-muted">₹ 8400</s>
//                     </small>
//                   </small>
//                 </small>
//               </small>
//             </small>
//             {"\n "}₹7980
//           </p>

//           <ul className="plan-details">
//             <li>Validity: 180 days from the date of subscription.</li>
//             <li>Access to Bauddha Dakshata and Abhyudaya.</li>
//           </ul>
//           {/* <button
//             className="plan-button"
//             style={{ ...buttonShadowStyle }}
//             onClick={() =>
//               handleSubscription(
//                 process.env.REACT_APP_PLAN_ID_COMBO_FIVE_HALFYEARLY,
//                 16
//               )
//             }
//           >
//             Get Now
//           </button> */}
//           {renderPlanButton(
//             process.env.REACT_APP_PLAN_ID_COMBO_FIVE_HALFYEARLY,
//             16
//           )}
//         </div>

//         <div className="plan shadow">
//           <h3 className="plan-title quarterly">Quarterly Plan</h3>
//           <p className="plan-price">
//             <small>
//               <small>
//                 <small>
//                   <small>
//                     <small>
//                       <s className="text-muted">₹ 4200</s>
//                     </small>
//                   </small>
//                 </small>
//               </small>
//             </small>
//             {"\n "}₹4095
//           </p>

//           <ul className="plan-details">
//             <li>Validity: 90 days from the date of subscription.</li>
//             <li>Access to Bauddha Dakshata and Abhyudaya.</li>
//           </ul>
//           {/* <button
//             className="plan-button"
//             style={{ ...buttonShadowStyle }}
//             onClick={() =>
//               handleSubscription(
//                 process.env.REACT_APP_PLAN_ID_COMBO_FIVE_QUARTERLY,
//                 32
//               )
//             }
//           >
//             Get Now
//           </button> */}
//           {renderPlanButton(
//             process.env.REACT_APP_PLAN_ID_COMBO_FIVE_QUARTERLY,
//             32
//           )}
//         </div>

//         <div className="plan shadow">
//           <h3 className="plan-title monthly">Monthly Plan</h3>
//           <p className="plan-price">{"\n "}₹1400</p>

//           <ul className="plan-details">
//             <li>Validity: 30 days from the date of subscription.</li>
//             <li>Access to Bauddha Dakshata and Abhyudaya.</li>
//           </ul>
//           {/* <button
//             className="plan-button"
//             style={{ ...buttonShadowStyle }}
//             onClick={() =>
//               handleSubscription(
//                 process.env.REACT_APP_PLAN_ID_COMBO_FIVE_MONTHLY,
//                 96
//               )
//             }
//           >
//             Get Now
//           </button> */}
//           {renderPlanButton(
//             process.env.REACT_APP_PLAN_ID_COMBO_FIVE_MONTHLY,
//             96
//           )}
//         </div>
//       </div>

//       {/*-------------------------------------------------------------------------------------------------------------------*/}

//       <h2
//         id="combo-6"
//         className="mt-5"
//         style={{
//           fontFamily: '"Libre Baskerville", serif',
//           fontWeight: "bold",
//         }}
//       >
//         Combo 6 ( KyeBye + Abhyudaya )
//       </h2>
//       <div
//         className="plans d-flex flex-wrap shadow-lg rounded-5"
//         style={{ padding: "20px", marginRight: "30px", marginLeft: "30px" }}
//       >
//         <div className="plan shadow">
//           <h3 className="plan-title yearly">Yearly Plan</h3>
//           <p className="plan-price">
//             <small>
//               <small>
//                 <small>
//                   <small>
//                     <small>
//                       <s className="text-muted">₹ 16800</s>
//                     </small>
//                   </small>
//                 </small>
//               </small>
//             </small>
//             {"\n "}₹15120
//           </p>

//           <ul className="plan-details">
//             <li>Validity: 365 days from the date of subscription.</li>
//             <li>Access to KyeBye and Abhyudaya.</li>
//           </ul>
//           {/* <button
//             className="plan-button"
//             style={{ ...buttonShadowStyle }}
//             onClick={() =>
//               handleSubscription(
//                 process.env.REACT_APP_PLAN_ID_COMBO_SIX_ANNUALLY,
//                 8
//               )
//             }
//           >
//             Get Now
//           </button> */}
//           {renderPlanButton(
//             process.env.REACT_APP_PLAN_ID_COMBO_SIX_ANNUALLY,
//             8
//           )}
//         </div>

//         <div className="plan shadow">
//           <h3 className="plan-title half-yearly">Half Yearly Plan</h3>
//           <p className="plan-price">
//             <small>
//               <small>
//                 <small>
//                   <small>
//                     <small>
//                       <s className="text-muted">₹ 8400</s>
//                     </small>
//                   </small>
//                 </small>
//               </small>
//             </small>
//             {"\n "}₹7980
//           </p>

//           <ul className="plan-details">
//             <li>Validity: 180 days from the date of subscription.</li>
//             <li>Access to KyeBye and Abhyudaya.</li>
//           </ul>
//           {/* <button
//             className="plan-button"
//             style={{ ...buttonShadowStyle }}
//             onClick={() =>
//               handleSubscription(
//                 process.env.REACT_APP_PLAN_ID_COMBO_SIX_HALFYEARLY,
//                 16
//               )
//             }
//           >
//             Get Now
//           </button> */}
//           {renderPlanButton(
//             process.env.REACT_APP_PLAN_ID_COMBO_SIX_HALFYEARLY,
//             16
//           )}
//         </div>

//         <div className="plan shadow">
//           <h3 className="plan-title quarterly">Quarterly Plan</h3>
//           <p className="plan-price">
//             <small>
//               <small>
//                 <small>
//                   <small>
//                     <small>
//                       <s className="text-muted">₹ 4200</s>
//                     </small>
//                   </small>
//                 </small>
//               </small>
//             </small>
//             {"\n "}₹4095
//           </p>

//           <ul className="plan-details">
//             <li>Validity: 90 days from the date of subscription.</li>
//             <li>Access to KyeBye and Abhyudaya.</li>
//           </ul>
//           {/* <button
//             className="plan-button"
//             style={{ ...buttonShadowStyle }}
//             onClick={() =>
//               handleSubscription(
//                 process.env.REACT_APP_PLAN_ID_COMBO_SIX_QUARTERLY,
//                 32
//               )
//             }
//           >
//             Get Now
//           </button> */}
//           {renderPlanButton(
//             process.env.REACT_APP_PLAN_ID_COMBO_SIX_QUARTERLY,
//             32
//           )}
//         </div>

//         <div className="plan shadow">
//           <h3 className="plan-title monthly">Monthly Plan</h3>
//           <p className="plan-price">{"\n "}₹1400</p>

//           <ul className="plan-details">
//             <li>Validity: 30 days from the date of subscription.</li>
//             <li>Access to KyeBye and Abhyudaya.</li>
//           </ul>
//           {/* <button
//             className="plan-button"
//             style={{ ...buttonShadowStyle }}
//             onClick={() =>
//               handleSubscription(
//                 process.env.REACT_APP_PLAN_ID_COMBO_SIX_MONTHLY,
//                 96
//               )
//             }
//           >
//             Get Now
//           </button> */}
//           {renderPlanButton(
//             process.env.REACT_APP_PLAN_ID_COMBO_SIX_MONTHLY,
//             96
//           )}
//         </div>
//       </div>

//       {/*-------------------------------------------------------------------------------------------------------------------*/}

//       <h2
//         id="combo-7"
//         className="mt-5"
//         style={{
//           fontFamily: '"Libre Baskerville", serif',
//           fontWeight: "bold",
//         }}
//       >
//         Combo 7 ( Udbodh + Bauddha Dakshata + KyeBye )
//       </h2>
//       <div
//         className="plans d-flex flex-wrap shadow-lg rounded-5"
//         style={{ padding: "20px", marginRight: "30px", marginLeft: "30px" }}
//       >
//         <div className="plan shadow">
//           <h3 className="plan-title yearly">Yearly Plan</h3>
//           <p className="plan-price">
//             <small>
//               <small>
//                 <small>
//                   <small>
//                     <small>
//                       <s className="text-muted">₹ 13800</s>
//                     </small>
//                   </small>
//                 </small>
//               </small>
//             </small>
//             {"\n "}₹12420
//           </p>

//           <ul className="plan-details">
//             <li>Validity: 365 days from the date of subscription.</li>
//             <li>Access to Udbodh, Bauddha Dakshata and KyeBye.</li>
//           </ul>
//           {/* <button
//             className="plan-button"
//             style={{ ...buttonShadowStyle }}
//             onClick={() =>
//               handleSubscription(
//                 process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_ANNUALLY,
//                 8
//               )
//             }
//           >
//             Get Now
//           </button> */}
//           {renderPlanButton(
//             process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_ANNUALLY,
//             8
//           )}
//         </div>

//         <div className="plan shadow">
//           <h3 className="plan-title half-yearly">Half Yearly Plan</h3>
//           <p className="plan-price">
//             <small>
//               <small>
//                 <small>
//                   <small>
//                     <small>
//                       <s className="text-muted">₹ 6900</s>
//                     </small>
//                   </small>
//                 </small>
//               </small>
//             </small>
//             {"\n "}₹6555
//           </p>

//           <ul className="plan-details">
//             <li>Validity: 180 days from the date of subscription.</li>
//             <li>Access to Udbodh, Bauddha Dakshata and KyeBye.</li>
//           </ul>
//           {/* <button
//             className="plan-button"
//             style={{ ...buttonShadowStyle }}
//             onClick={() =>
//               handleSubscription(
//                 process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_HALFYEARLY,
//                 16
//               )
//             }
//           >
//             Get Now
//           </button> */}
//           {renderPlanButton(
//             process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_HALFYEARLY,
//             16
//           )}
//         </div>

//         <div className="plan shadow">
//           <h3 className="plan-title quarterly">Quarterly Plan</h3>
//           <p className="plan-price">
//             <small>
//               <small>
//                 <small>
//                   <small>
//                     <small>
//                       <s className="text-muted">₹ 3450</s>
//                     </small>
//                   </small>
//                 </small>
//               </small>
//             </small>
//             {"\n "}₹3364
//           </p>

//           <ul className="plan-details">
//             <li>Validity: 90 days from the date of subscription.</li>
//             <li>Access to Udbodh, Bauddha Dakshata and KyeBye.</li>
//           </ul>
//           {/* <button
//             className="plan-button"
//             style={{ ...buttonShadowStyle }}
//             onClick={() =>
//               handleSubscription(
//                 process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_QUARTERLY,
//                 32
//               )
//             }
//           >
//             Get Now
//           </button> */}
//           {renderPlanButton(
//             process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_QUARTERLY,
//             32
//           )}
//         </div>

//         <div className="plan shadow">
//           <h3 className="plan-title monthly">Monthly Plan</h3>
//           <p className="plan-price">{"\n "}₹1150</p>

//           <ul className="plan-details">
//             <li>Validity: 30 days from the date of subscription.</li>
//             <li>Access to Udbodh, Bauddha Dakshata and KyeBye.</li>
//           </ul>
//           {/* <button
//             className="plan-button"
//             style={{ ...buttonShadowStyle }}
//             onClick={() =>
//               handleSubscription(
//                 process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_MONTHLY,
//                 96
//               )
//             }
//           >
//             Get Now
//           </button> */}
//           {renderPlanButton(
//             process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_MONTHLY,
//             96
//           )}
//         </div>
//       </div>

//       {/*-------------------------------------------------------------------------------------------------------------------*/}

//       <h2
//         id="combo-8"
//         className="mt-5"
//         style={{
//           fontFamily: '"Libre Baskerville", serif',
//           fontWeight: "bold",
//         }}
//       >
//         Combo 8 ( Udbodh + Bauddha Dakshata + KyeBye + Abhyudaya )
//       </h2>
//       <div
//         className="plans d-flex flex-wrap shadow-lg rounded-5"
//         style={{ padding: "20px", marginRight: "30px", marginLeft: "30px" }}
//       >
//         <div className="plan shadow">
//           <h3 className="plan-title yearly">Yearly Plan</h3>
//           <p className="plan-price">
//             <small>
//               <small>
//                 <small>
//                   <small>
//                     <small>
//                       <s className="text-muted">₹ 25200</s>
//                     </small>
//                   </small>
//                 </small>
//               </small>
//             </small>
//             {"\n "}₹22680
//           </p>

//           <ul className="plan-details">
//             <li>Validity: 365 days from the date of subscription.</li>
//             <li>Access to Udbodh, Bauddha Dakshata, KyeBye and Abhyudaya.</li>
//           </ul>
//           {/* <button
//             className="plan-button"
//             style={{ ...buttonShadowStyle }}
//             onClick={() =>
//               handleSubscription(
//                 process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//                 8
//               )
//             }
//           >
//             Get Now
//           </button> */}
//           {renderPlanButton(process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY, 8)}
//         </div>

//         <div className="plan shadow">
//           <h3 className="plan-title half-yearly">Half Yearly Plan</h3>
//           <p className="plan-price">
//             <small>
//               <small>
//                 <small>
//                   <small>
//                     <small>
//                       <s className="text-muted">₹ 12600</s>
//                     </small>
//                   </small>
//                 </small>
//               </small>
//             </small>
//             {"\n "}₹11970
//           </p>

//           <ul className="plan-details">
//             <li>Validity: 180 days from the date of subscription.</li>
//             <li>Access to Udbodh, Bauddha Dakshata, KyeBye and Abhyudaya.</li>
//           </ul>
//           {/* <button
//             className="plan-button"
//             style={{ ...buttonShadowStyle }}
//             onClick={() =>
//               handleSubscription(
//                 process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//                 16
//               )
//             }
//           >
//             Get Now
//           </button> */}
//           {renderPlanButton(process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY, 16)}
//         </div>

//         <div className="plan shadow">
//           <h3 className="plan-title quarterly">Quarterly Plan</h3>
//           <p className="plan-price">
//             <small>
//               <small>
//                 <small>
//                   <small>
//                     <small>
//                       <s className="text-muted">₹ 6300</s>
//                     </small>
//                   </small>
//                 </small>
//               </small>
//             </small>
//             {"\n "}₹6143
//           </p>

//           <ul className="plan-details">
//             <li>Validity: 90 days from the date of subscription.</li>
//             <li>Access to Udbodh, Bauddha Dakshata, KyeBye and Abhyudaya.</li>
//           </ul>
//           {/* <button
//             className="plan-button"
//             style={{ ...buttonShadowStyle }}
//             onClick={() =>
//               handleSubscription(
//                 process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//                 32
//               )
//             }
//           >
//             Get Now
//           </button> */}
//           {renderPlanButton(process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY, 32)}
//         </div>

//         <div className="plan shadow">
//           <h3 className="plan-title monthly">Monthly Plan</h3>
//           <p className="plan-price">{"\n "}₹2100</p>

//           <ul className="plan-details">
//             <li>Validity: 30 days from the date of subscription.</li>
//             <li>Access to Udbodh, Bauddha Dakshata, KyeBye and Abhyudaya.</li>
//           </ul>
//           {/* <button
//             className="plan-button"
//             style={{ ...buttonShadowStyle }}
//             onClick={() =>
//               handleSubscription(
//                 process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//                 96
//               )
//             }
//           >
//             Get Now
//           </button> */}
//           {renderPlanButton(process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY, 96)}
//         </div>
//       </div>
//       <div className="contact-info">
//         <p>FOR ANY QUERY PLEASE CONTACT:</p>
//         <p>ashutosh@gyankosha.com</p>
//       </div>

//       {/* Modal to Display Referral Code */}
//       {/* <Modal show={showModal} onHide={handleCloseModal} centered>
//         <Modal.Header closeButton>
//           <Modal.Title>Your Referral Code</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           <p>Share this code with your friends to earn rewards:</p>
//           <h3 className="text-primary">{referralCode}</h3>
//         </Modal.Body>
//         <Modal.Footer>
//           <Button variant="secondary" onClick={handleCloseModal}>
//             Close
//           </Button>
//         </Modal.Footer>
//       </Modal> */}

//       <Modal show={showModal} onHide={handleCloseModal} centered>
//         <Modal.Header closeButton>
//           <Modal.Title>
//             Gyankosha's Subscription Active now and your Referral Code :{" "}
//             <span style={{ color: "red" }}>{referralCode}</span>
//           </Modal.Title>
//         </Modal.Header>
//         {/* <Modal.Body>
//     <div style={{ fontFamily: "Arial, sans-serif", lineHeight: "1.6" }}>
//       <p>
//         Dear <strong>{currentUser1.name}</strong>,
//       </p>
//       <p>
//         Thank you for choosing <strong>Gyankosha</strong> as your trusted
//         education partner. We are thrilled to have you on board! Your
//         subscription plan is now active, giving you access to our premium
//         content and resources.
//       </p>
//       <p>
//         Attached, you will find your unique Referral Code:{" "}
//         <strong className="text-primary">{referralCode}</strong>. When a new
//         user registers using your referral code, you become eligible for a{" "}
//         <strong>20% cashback</strong> to your account and the referrer become eligible for a{" "}
//         <strong>10% cashback</strong> to his account as a token of
//         appreciation.
//       </p>
//       <p>
//         Let’s keep learning, growing, and making education exciting together!
//       </p>
//       <p>Warm regards,</p>
//       <p>
//         <strong>The Gyankosha Team</strong>
//       </p>
//     </div>
//   </Modal.Body> */}

//         <Modal.Body>
//           <div style={{ fontFamily: "Arial, sans-serif", lineHeight: "1.6" }}>
//             <p>
//               Dear <strong>{currentUser1 ? currentUser1.name : ""}</strong>,
//             </p>
//             <p>
//               Thank you for choosing <strong>Gyankosha</strong> as your trusted
//               education partner. We are thrilled to have you on board! Your
//               subscription plan is now active, giving you access to the premium
//               content and resources.
//             </p>
//             <p>
//               Attached, you will find your unique Referral Code:{" "}
//               <strong style={{ color: "red" }}>{referralCode}</strong>.
//             </p>
//             <p>
//               As a token of appreciation for your support, when a new user
//               subscribes and makes their first payment using your referral code,
//               you become eligible for a <strong>20% cashback</strong> to your
//               account, and the one referred by you becomes eligible for a{" "}
//               <strong>10% cashback</strong> to their account. Please note that
//               the cashback is applicable only against the first payment made by
//               the referred user and is valid for one cycle only.
//             </p>
//             <p>
//               Let’s keep learning, growing, and making education exciting
//               together!
//             </p>
//             <p>Warm regards,</p>
//             <p>
//               <strong>The Gyankosha Team</strong>
//             </p>
//           </div>
//         </Modal.Body>

//         <Modal.Footer>
//           <Button variant="secondary" onClick={handleCloseModal}>
//             Close
//           </Button>
//         </Modal.Footer>
//       </Modal>

//       <Modal
//         show={showReferralModal}
//         onHide={() => setShowReferralModal(false)}
//         centered
//       >
//         <Modal.Header closeButton>
//           {hasReferralCode === null && (
//             <Modal.Title>Do you have a referral code?</Modal.Title>
//           )}
//         </Modal.Header>
//         <Modal.Body>
//           {hasReferralCode === null && (
//             <div>
//               <div className="form-check">
//                 <input
//                   className="form-check-input"
//                   type="radio"
//                   name="referralCodeOption"
//                   id="referralYes"
//                   onChange={() => setHasReferralCode(true)}
//                 />
//                 <label className="form-check-label" htmlFor="referralYes">
//                   Yes
//                 </label>
//               </div>
//               <div className="form-check">
//                 <input
//                   className="form-check-input"
//                   type="radio"
//                   name="referralCodeOption"
//                   id="referralNo"
//                   onChange={() => setHasReferralCode(false)}
//                 />
//                 <label className="form-check-label" htmlFor="referralNo">
//                   No
//                 </label>
//               </div>
//             </div>
//           )}
//           {hasReferralCode === true && !referralCodeVerified && (
//             <div>
//               <input
//                 type="text"
//                 className="form-control mt-3"
//                 placeholder="Enter referral code"
//                 value={referralInput}
//                 onChange={(e) => setReferralInput(e.target.value)}
//               />
//               <button
//                 className="btn btn-primary mt-2"
//                 onClick={verifyReferralCode}
//                 disabled={verifyingReferral}
//               >
//                 {verifyingReferral ? "Verifying..." : "Verify Referral Code"}
//               </button>
//             </div>
//           )}
//           {referralCodeVerified && (
//             <div>
//               <button
//                 className="btn btn-success mt-3"
//                 onClick={() => {
//                   setShowReferralModal(false);
//                   handleSubscription(selectedPlanId, selectedTotalCount);
//                 }}
//               >
//                 Proceed to payment with referral code
//               </button>
//             </div>
//           )}
//           {hasReferralCode === false && (
//             <div>
//               <button
//                 className="btn btn-success mt-3"
//                 onClick={() => {
//                   setShowReferralModal(false);
//                   handleSubscription(selectedPlanId, selectedTotalCount);
//                 }}
//               >
//                 Proceed to payment
//               </button>
//             </div>
//           )}
//         </Modal.Body>
//       </Modal>
//     </div>
//   );
// };

// export default Paymentplansscreen;
// ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import "./pay.css";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteUser,
  getAllUsers,
  updatePaidStatus,
} from "../actions/userActions";

const Paymentplansscreen = () => {
  const [showReferralModal, setShowReferralModal] = useState(false);
  const [hasReferralCode, setHasReferralCode] = useState(null); // Track Yes/No selection
  const [referralCodeVerified, setReferralCodeVerified] = useState(false);
  const [verifyingReferral, setVerifyingReferral] = useState(false);
  const [selectedPlanId, setSelectedPlanId] = useState(null);
  const [selectedTotalCount, setSelectedTotalCount] = useState(null);

  const [showModal, setShowModal] = useState(false);
  const [referralCode, setReferralCode] = useState(null);
  const [referralInput, setReferralInput] = useState(""); // State to hold the referral code input
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
  const [subscriptionPlan, setSubscriptionPlan] = useState(null); // To store the user's subscription plan from MongoDB
  const [subs, setSubs] = useState(null);
  const [loadingPlan, setLoadingPlan] = useState(null); // Add a state to track the loading button

  const [showClassSelection, setShowClassSelection] = useState(true); // Manage modal stage
  const [selectedClass, setSelectedClass] = useState(""); // Store selected class
  const [classes, setClasses] = useState([]); // Store classes for the drop-down

  const usersstate = useSelector((state) => state.getAllUsersReducer);
  const userstate = useSelector((state) => state.loginUserReducer);
  const { currentUser1 } = userstate;
  const { error, loading, users } = usersstate;

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 767);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const fetchClasses = async () => {
      try {
        const response = await fetch("/api/classss/getallclassss");
        const data = await response.json();
        setClasses(data);
      } catch (error) {
        console.error("Error fetching classes:", error);
      }
    };
    fetchClasses();
  }, []);

  // Fetch user's subscription plan from MongoDB
  useEffect(() => {
    const fetchUserSubscription = async () => {
      try {
        const res = await fetch(`/api/users/${currentUser1._id}`);
        const data = await res.json();
        setSubscriptionPlan(data.subscription?.plan || null); // Set the subscription plan
        setSubs(data.subscription);
      } catch (error) {
        console.error("Error fetching user subscription:", error);
      }
    };

    if (currentUser1) {
      if (currentUser1?._id) {
        fetchUserSubscription();
      }
    } else {
      alert(
        "You are not Logged in. Login first to subscribe by clicking on the Login link on the top-right corner."
      );
    }
  }, [currentUser1]);

  const buttonShadowStyle = {
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.5)",
    transition: "background-color 0.3s",
  };

  const scrollToSection = (id, offset = 0) => {
    const section = document.getElementById(id);
    if (section) {
      const elementPosition =
        section.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementPosition - offset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  };

  // Handle modal close event
  const handleCloseModal = () => {
    setShowModal(false);

    // Trigger page reload only when modal is closed
    window.location.reload();
  };

  // handleSubscription Function
  const handleSubscription = async (plan_id, total_count) => {
    setLoadingPlan(plan_id); // Set loading for the clicked plan
    try {
      const res = await fetch(`/api/payment/subscription`, {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({
          plan_id,
          userId: currentUser1._id, // Pass user ID to backend
          total_count,
          referralCode: referralInput.trim(), // Include referral code in request
        }),
      });

      const data = await res.json();
      console.log(data);
      handlePaymentVerify(data.data);
      // setReferralCode(data.referralCode); // Set referral code if returned
      // setShowModal(true);
      setLoadingPlan(null); // Reset loading state in case of error
    } catch (error) {
      console.log(error);
      setLoadingPlan(null); // Reset loading state in case of error
    }
  };

  const verifyReferralCode = async () => {
    setVerifyingReferral(true);
    try {
      console.log("referralInput : ", referralInput);
      const res = await fetch(`/api/users/verifyReferral`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ referralCode: referralInput.trim() }),
      });
      const data = await res.json();

      console.log("data : ", data);
      if (data.success) {
        setReferralCodeVerified(true);
        toast.success("Referral code verified successfully!");
      } else {
        toast.error("Invalid referral code.");
      }
    } catch (error) {
      console.error("Error verifying referral code:", error);
      toast.error("Failed to verify referral code.");
    } finally {
      setVerifyingReferral(false);
    }
  };

  // handlePaymentVerify Function
  const handlePaymentVerify = async (data) => {
    const options = {
      key: process.env.REACT_APP_RAZORPAY_KEY_ID,
      subscription_id: data.id,
      name: "Gyankosha",
      description: "Payment for Quiz Subscription",
      handler: async (response) => {
        console.log("response", response);
        try {
          const res = await fetch(`/api/payment/verify`, {
            method: "POST",
            headers: {
              "content-type": "application/json",
            },
            body: JSON.stringify({
              razorpay_subscription_id: response.razorpay_subscription_id,
              razorpay_payment_id: response.razorpay_payment_id,
              razorpay_signature: response.razorpay_signature,
              userId: currentUser1._id, // Pass user ID for verification
            }),
          });

          const verifyData = await res.json();

          if (verifyData.message) {
            toast.success(verifyData.message);
            console.log("verifyData.referralCode : ", verifyData.referralCode);
            if (verifyData.referralCode) {
              setReferralCode(verifyData.referralCode);
              setShowModal(true);
            }
            // Reload the page after 2 seconds
            // setTimeout(() => {
            //   window.location.reload();
            // }, 2000);
          }
        } catch (error) {
          console.log(error);
        } finally {
          setLoadingPlan(null); // Reset loading state in case of error
        }
      },
      theme: {
        color: "#5f63b8",
      },
    };
    const rzp1 = new window.Razorpay(options);
    rzp1.open();
  };

  // Inside Paymentplansscreen.js

  const handleUnsubscribe = async () => {
    try {
      console.log("subs : ", subs);
      console.log("subs id : ", subs.id);
      const res = await fetch(`/api/payment/unsubscribe`, {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({
          // subscriptionId: currentUser1.subscription?.id,
          subscriptionId: subs.id,
          userId: currentUser1._id,
        }),
      });

      const data = await res.json();

      if (data.message) {
        toast.success(data.message);

        // Reload the page after 2 seconds
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    } catch (error) {
      console.log(error);
      toast.error("Error unsubscribing from the plan.");
    }
  };

  const renderPlanButton = (planId, total_count) => {
    const isSubscribed = subscriptionPlan === planId;

    return isSubscribed && subs.status == "active" ? (
      <button
        className="plan-button"
        style={{ ...buttonShadowStyle }}
        onClick={handleUnsubscribe}
      >
        Unsubscribe
      </button>
    ) : (
      !(subscriptionPlan && subs.status == "active") && (
        <button
          className="plan-button"
          style={{ ...buttonShadowStyle }}
          onClick={() => {
            setShowReferralModal(true); // Open the modal
            setReferralCodeVerified(false); // Reset verification
            setHasReferralCode(null); // Reset Yes/No selection
            setSelectedPlanId(planId); // Store the selected planId
            setSelectedTotalCount(total_count); // Store the selected total_count
          }}
          disabled={!!(subscriptionPlan && subs.status == "active")} // Disable if any subscription exists
        >
          {loadingPlan === planId ? "Please Wait..." : "Get Now"}
        </button>
      )
    );
  };

  const handleClassChange = (e) => {
    setSelectedClass(e.target.value);
  };

  const handleClassConfirm = () => {
    if (selectedClass) {
      setShowClassSelection(false); // Proceed to the next part of the modal
    } else {
      alert("Please select a class.");
    }
  };

  return (
    <div
      className="pricing-table"
      style={{
        marginTop: isMobile ? "150px" : "70px",
        backgroundColor: "#f0f0f0",
        padding: "20px",
        fontFamily: '"Libre Baskerville", serif',
      }}
    >
      <h3 className="mt-5 mb-5">
        In order to access more quizzes, please purchase one of the below
        mentioned plans:
      </h3>

      {/* Link Bar */}
      <div
        className="link-bar"
        style={{
          // backgroundColor: "#5f63b8",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          alignItems: "center", // Centers items vertically
          backgroundColor: "#000080",
          padding: "10px",
          position: "fixed",
          width: "100%",
          top: 140,
          zIndex: 100,
        }}
      >
        {[
          { id: "udbodh", label: "Udbodh" },
          { id: "bauddha-dakshata", label: "Bauddha Dakshata" },
          { id: "kyebye", label: "KyeBye" },
          { id: "abhyudaya", label: "Abhyudaya" },
          { id: "combo-1", label: "Combo 1" },
          { id: "combo-2", label: "Combo 2" },
          { id: "combo-3", label: "Combo 3" },
          { id: "combo-4", label: "Combo 4" },
          { id: "combo-5", label: "Combo 5" },
          { id: "combo-6", label: "Combo 6" },
          { id: "combo-7", label: "Combo 7" },
          { id: "combo-8", label: "Combo 8" },
        ].map((section) => (
          <button
            key={section.id}
            style={{
              color: "white",
              background: "none",
              border: "none",
              margin: "0 0px",
              cursor: "pointer",
              fontSize: "15px",
              fontWeight: "bold",
              transition: "background-color 0.3s, color 0.3s", // Smooth transition effect
              borderRadius: "50px", // Rounded pill shape
              padding: "5px 15px", // Add padding for a pill-like appearance
            }}
            onMouseEnter={(e) => {
              e.target.style.color = "#5f63b8"; // Hover text color
              e.target.style.background = "white"; // Hover background color
            }}
            onMouseLeave={(e) => {
              e.target.style.color = "white"; // Original text color
              e.target.style.background = "none"; // Original background color
            }}
            onClick={() => scrollToSection(section.id, isMobile ? 350 : 195)}
          >
            {section.label}
          </button>
        ))}
      </div>

      <h2
        id="udbodh"
        style={{
          fontFamily: '"Libre Baskerville", serif',
          fontWeight: "bold",
        }}
      >
        Udbodh
      </h2>
      <div
        className="plans d-flex flex-wrap shadow-lg rounded-5"
        style={{ padding: "20px", marginRight: "30px", marginLeft: "30px" }}
      >
        <div className="plan shadow">
          <h3 className="plan-title yearly">Yearly Plan</h3>
          <p className="plan-price">
            <small>
              <small>
                <small>
                  <small>
                    <small>
                      <s className="text-muted">₹ 3600</s>
                    </small>
                  </small>
                </small>
              </small>
            </small>
            {"\n "}₹3240
          </p>
          <ul className="plan-details">
            <li>Validity: 365 days from the date of subscription.</li>
            <li>Access to Udbodh.</li>
          </ul>

          {renderPlanButton(process.env.REACT_APP_PLAN_ID_UDBODH_ANNUALLY, 8)}
        </div>

        <div className="plan shadow">
          <h3 className="plan-title half-yearly">Half Yearly Plan</h3>
          <p className="plan-price">
            <small>
              <small>
                <small>
                  <small>
                    <small>
                      <s className="text-muted">₹ 1800</s>
                    </small>
                  </small>
                </small>
              </small>
            </small>
            {"\n "}₹1710
          </p>

          <ul className="plan-details">
            <li>Validity: 180 days from the date of subscription.</li>
            <li>Access to Udbodh.</li>
          </ul>

          {renderPlanButton(
            process.env.REACT_APP_PLAN_ID_UDBODH_HALFYEARLY,
            16
          )}
        </div>

        <div className="plan shadow">
          <h3 className="plan-title quarterly">Quarterly Plan</h3>
          <p className="plan-price">
            <small>
              <small>
                <small>
                  <small>
                    <small>
                      <s className="text-muted">₹ 900</s>
                    </small>
                  </small>
                </small>
              </small>
            </small>
            {"\n "}₹878
          </p>

          <ul className="plan-details">
            <li>Validity: 90 days from the date of subscription.</li>
            <li>Access to Udbodh.</li>
          </ul>

          {renderPlanButton(process.env.REACT_APP_PLAN_ID_UDBODH_QUARTERLY, 32)}
        </div>

        <div className="plan shadow">
          <h3 className="plan-title monthly">Monthly Plan</h3>
          <p className="plan-price">{"\n "}₹300</p>

          <ul className="plan-details">
            <li>Validity: 30 days from the date of subscription.</li>
            <li>Access to Udbodh.</li>
          </ul>

          {renderPlanButton(process.env.REACT_APP_PLAN_ID_UDBODH_MONTHLY, 96)}
        </div>
      </div>

      {/*-------------------------------------------------------------------------------------------------------------------*/}

      <h2
        id="bauddha-dakshata"
        className="mt-5"
        style={{
          fontFamily: '"Libre Baskerville", serif',
          fontWeight: "bold",
        }}
      >
        Bauddha Dakshata
      </h2>
      <div
        className="plans d-flex flex-wrap shadow-lg rounded-5"
        style={{ padding: "20px", marginRight: "30px", marginLeft: "30px" }}
      >
        <div className="plan shadow">
          <h3 className="plan-title yearly">Yearly Plan</h3>
          <p className="plan-price">
            <small>
              <small>
                <small>
                  <small>
                    <small>
                      <s className="text-muted">₹ 6000</s>
                    </small>
                  </small>
                </small>
              </small>
            </small>
            {"\n "}₹5400
          </p>

          <ul className="plan-details">
            <li>Validity: 365 days from the date of subscription.</li>
            <li>Access to Bauddha Dakshata.</li>
          </ul>

          {renderPlanButton(process.env.REACT_APP_PLAN_ID_BD_ANNUALLY, 8)}
        </div>

        <div className="plan shadow">
          <h3 className="plan-title half-yearly">Half Yearly Plan</h3>
          <p className="plan-price">
            <small>
              <small>
                <small>
                  <small>
                    <small>
                      <s className="text-muted">₹ 3000</s>
                    </small>
                  </small>
                </small>
              </small>
            </small>
            {"\n "}₹2850
          </p>

          <ul className="plan-details">
            <li>Validity: 180 days from the date of subscription.</li>
            <li>Access to Bauddha Dakshata.</li>
          </ul>

          {renderPlanButton(process.env.REACT_APP_PLAN_ID_BD_HALFYEARLY, 16)}
        </div>

        <div className="plan shadow">
          <h3 className="plan-title quarterly">Quarterly Plan</h3>
          <p className="plan-price">
            <small>
              <small>
                <small>
                  <small>
                    <small>
                      <s className="text-muted">₹ 1500</s>
                    </small>
                  </small>
                </small>
              </small>
            </small>
            {"\n "}₹1463
          </p>

          <ul className="plan-details">
            <li>Validity: 90 days from the date of subscription.</li>
            <li>Access to Bauddha Dakshata.</li>
          </ul>

          {renderPlanButton(process.env.REACT_APP_PLAN_ID_BD_QUARTERLY, 32)}
        </div>

        <div className="plan shadow">
          <h3 className="plan-title monthly">Monthly Plan</h3>
          <p className="plan-price">{"\n "}₹500</p>

          <ul className="plan-details">
            <li>Validity: 30 days from the date of subscription.</li>
            <li>Access to Bauddha Dakshata.</li>
          </ul>

          {renderPlanButton(process.env.REACT_APP_PLAN_ID_BD_MONTHLY, 96)}
        </div>
      </div>

      {/*-------------------------------------------------------------------------------------------------------------------*/}

      <h2
        id="kyebye"
        className="mt-5"
        style={{
          fontFamily: '"Libre Baskerville", serif',
          fontWeight: "bold",
        }}
      >
        KyeBye
      </h2>
      <div
        className="plans d-flex flex-wrap shadow-lg rounded-5"
        style={{ padding: "20px", marginRight: "30px", marginLeft: "30px" }}
      >
        <div className="plan shadow">
          <h3 className="plan-title yearly">Yearly Plan</h3>
          <p className="plan-price">
            <small>
              <small>
                <small>
                  <small>
                    <small>
                      <s className="text-muted">₹ 6000</s>
                    </small>
                  </small>
                </small>
              </small>
            </small>
            {"\n "}₹5400
          </p>

          <ul className="plan-details">
            <li>Validity: 365 days from the date of subscription.</li>
            <li>Access to KyeBye.</li>
          </ul>

          {renderPlanButton(process.env.REACT_APP_PLAN_ID_KYEBYE_ANNUALLY, 8)}
        </div>

        <div className="plan shadow">
          <h3 className="plan-title half-yearly">Half Yearly Plan</h3>
          <p className="plan-price">
            <small>
              <small>
                <small>
                  <small>
                    <small>
                      <s className="text-muted">₹ 3000</s>
                    </small>
                  </small>
                </small>
              </small>
            </small>
            {"\n "}₹2850
          </p>

          <ul className="plan-details">
            <li>Validity: 180 days from the date of subscription.</li>
            <li>Access to KyeBye.</li>
          </ul>

          {renderPlanButton(
            process.env.REACT_APP_PLAN_ID_KYEBYE_HALFYEARLY,
            16
          )}
        </div>

        <div className="plan shadow">
          <h3 className="plan-title quarterly">Quarterly Plan</h3>
          <p className="plan-price">
            <small>
              <small>
                <small>
                  <small>
                    <small>
                      <s className="text-muted">₹ 1500</s>
                    </small>
                  </small>
                </small>
              </small>
            </small>
            {"\n "}₹1463
          </p>

          <ul className="plan-details">
            <li>Validity: 90 days from the date of subscription.</li>
            <li>Access to KyeBye.</li>
          </ul>

          {renderPlanButton(process.env.REACT_APP_PLAN_ID_KYEBYE_QUARTERLY, 32)}
        </div>

        <div className="plan shadow">
          <h3 className="plan-title monthly">Monthly Plan</h3>
          <p className="plan-price">{"\n "}₹500</p>

          <ul className="plan-details">
            <li>Validity: 30 days from the date of subscription.</li>
            <li>Access to KyeBye.</li>
          </ul>

          {renderPlanButton(process.env.REACT_APP_PLAN_ID_KYEBYE_MONTHLY, 96)}
        </div>
      </div>
      {/*-------------------------------------------------------------------------------------------------------------------*/}

      <h2
        id="abhyudaya"
        className="mt-5"
        style={{
          fontFamily: '"Libre Baskerville", serif',
          fontWeight: "bold",
        }}
      >
        Abhyudaya
      </h2>
      <div
        className="plans d-flex flex-wrap shadow-lg rounded-5"
        style={{ padding: "20px", marginRight: "30px", marginLeft: "30px" }}
      >
        <div className="plan shadow">
          <h3 className="plan-title yearly">Yearly Plan</h3>
          <p className="plan-price">
            <small>
              <small>
                <small>
                  <small>
                    <small>
                      <s className="text-muted">₹ 12000</s>
                    </small>
                  </small>
                </small>
              </small>
            </small>
            {"\n "}₹10800
          </p>

          <ul className="plan-details">
            <li>Validity: 365 days from the date of subscription.</li>
            <li>Access to Abhyudaya.</li>
          </ul>

          {renderPlanButton(
            process.env.REACT_APP_PLAN_ID_ABHYUDAYA_ANNUALLY,
            8
          )}
        </div>

        <div className="plan shadow">
          <h3 className="plan-title half-yearly">Half Yearly Plan</h3>
          <p className="plan-price">
            <small>
              <small>
                <small>
                  <small>
                    <small>
                      <s className="text-muted">₹ 6000</s>
                    </small>
                  </small>
                </small>
              </small>
            </small>
            {"\n "}₹5700
          </p>

          <ul className="plan-details">
            <li>Validity: 180 days from the date of subscription.</li>
            <li>Access to Abhyudaya.</li>
          </ul>

          {renderPlanButton(
            process.env.REACT_APP_PLAN_ID_ABHYUDAYA_HALFYEARLY,
            16
          )}
        </div>

        <div className="plan shadow">
          <h3 className="plan-title quarterly">Quarterly Plan</h3>
          <p className="plan-price">
            <small>
              <small>
                <small>
                  <small>
                    <small>
                      <s className="text-muted">₹ 3000</s>
                    </small>
                  </small>
                </small>
              </small>
            </small>
            {"\n "}₹2925
          </p>

          <ul className="plan-details">
            <li>Validity: 90 days from the date of subscription.</li>
            <li>Access to Abhyudaya.</li>
          </ul>

          {renderPlanButton(
            process.env.REACT_APP_PLAN_ID_ABHYUDAYA_QUARTERLY,
            32
          )}
        </div>

        <div className="plan shadow">
          <h3 className="plan-title monthly">Monthly Plan</h3>
          <p className="plan-price">{"\n "}₹1000</p>

          <ul className="plan-details">
            <li>Validity: 30 days from the date of subscription.</li>
            <li>Access to Abhyudaya.</li>
          </ul>

          {renderPlanButton(
            process.env.REACT_APP_PLAN_ID_ABHYUDAYA_MONTHLY,
            96
          )}
        </div>
      </div>

      {/*-------------------------------------------------------------------------------------------------------------------*/}

      <h2
        id="combo-1"
        className="mt-5"
        style={{
          fontFamily: '"Libre Baskerville", serif',
          fontWeight: "bold",
        }}
      >
        Combo 1 ( Udbodh + Bauddha Dakshata )
      </h2>
      <div
        className="plans d-flex flex-wrap shadow-lg rounded-5"
        style={{ padding: "20px", marginRight: "30px", marginLeft: "30px" }}
      >
        <div className="plan shadow">
          <h3 className="plan-title yearly">Yearly Plan</h3>
          <p className="plan-price">
            <small>
              <small>
                <small>
                  <small>
                    <small>
                      <s className="text-muted">₹ 8400</s>
                    </small>
                  </small>
                </small>
              </small>
            </small>
            {"\n "}₹7560
          </p>

          <ul className="plan-details">
            <li>Validity: 365 days from the date of subscription.</li>
            <li>Access to Udbodh and Bauddha Dakshata.</li>
          </ul>

          {renderPlanButton(
            process.env.REACT_APP_PLAN_ID_COMBO_ONE_ANNUALLY,
            8
          )}
        </div>

        <div className="plan shadow">
          <h3 className="plan-title half-yearly">Half Yearly Plan</h3>
          <p className="plan-price">
            <small>
              <small>
                <small>
                  <small>
                    <small>
                      <s className="text-muted">₹ 4200</s>
                    </small>
                  </small>
                </small>
              </small>
            </small>
            {"\n "}₹3990
          </p>

          <ul className="plan-details">
            <li>Validity: 180 days from the date of subscription.</li>
            <li>Access to Udbodh and Bauddha Dakshata.</li>
          </ul>

          {renderPlanButton(
            process.env.REACT_APP_PLAN_ID_COMBO_ONE_HALFYEARLY,
            16
          )}
        </div>

        <div className="plan shadow">
          <h3 className="plan-title quarterly">Quarterly Plan</h3>
          <p className="plan-price">
            <small>
              <small>
                <small>
                  <small>
                    <small>
                      <s className="text-muted">₹ 2100</s>
                    </small>
                  </small>
                </small>
              </small>
            </small>
            {"\n "}₹2048
          </p>

          <ul className="plan-details">
            <li>Validity: 90 days from the date of subscription.</li>
            <li>Access to Udbodh and Bauddha Dakshata.</li>
          </ul>

          {renderPlanButton(
            process.env.REACT_APP_PLAN_ID_COMBO_ONE_QUARTERLY,
            32
          )}
        </div>

        <div className="plan shadow">
          <h3 className="plan-title monthly">Monthly Plan</h3>
          <p className="plan-price">{"\n "}₹700</p>

          <ul className="plan-details">
            <li>Validity: 30 days from the date of subscription.</li>
            <li>Access to Udbodh and Bauddha Dakshata.</li>
          </ul>

          {renderPlanButton(
            process.env.REACT_APP_PLAN_ID_COMBO_ONE_MONTHLY,
            96
          )}
        </div>
      </div>

      {/*-------------------------------------------------------------------------------------------------------------------*/}

      <h2
        id="combo-2"
        className="mt-5"
        style={{
          fontFamily: '"Libre Baskerville", serif',
          fontWeight: "bold",
        }}
      >
        Combo 2 ( Udbodh + KyeBye )
      </h2>
      <div
        className="plans d-flex flex-wrap shadow-lg rounded-5"
        style={{ padding: "20px", marginRight: "30px", marginLeft: "30px" }}
      >
        <div className="plan shadow">
          <h3 className="plan-title yearly">Yearly Plan</h3>
          <p className="plan-price">
            <small>
              <small>
                <small>
                  <small>
                    <small>
                      <s className="text-muted">₹ 8400</s>
                    </small>
                  </small>
                </small>
              </small>
            </small>
            {"\n "}₹7560
          </p>

          <ul className="plan-details">
            <li>Validity: 365 days from the date of subscription.</li>
            <li>Access to Udbodh and KyeBye.</li>
          </ul>

          {renderPlanButton(
            process.env.REACT_APP_PLAN_ID_COMBO_TWO_ANNUALLY,
            8
          )}
        </div>

        <div className="plan shadow">
          <h3 className="plan-title half-yearly">Half Yearly Plan</h3>
          <p className="plan-price">
            <small>
              <small>
                <small>
                  <small>
                    <small>
                      <s className="text-muted">₹ 4200</s>
                    </small>
                  </small>
                </small>
              </small>
            </small>
            {"\n "}₹3990
          </p>

          <ul className="plan-details">
            <li>Validity: 180 days from the date of subscription.</li>
            <li>Access to Udbodh and KyeBye.</li>
          </ul>

          {renderPlanButton(
            process.env.REACT_APP_PLAN_ID_COMBO_TWO_HALFYEARLY,
            16
          )}
        </div>

        <div className="plan shadow">
          <h3 className="plan-title quarterly">Quarterly Plan</h3>
          <p className="plan-price">
            <small>
              <small>
                <small>
                  <small>
                    <small>
                      <s className="text-muted">₹ 2100</s>
                    </small>
                  </small>
                </small>
              </small>
            </small>
            {"\n "}₹2048
          </p>

          <ul className="plan-details">
            <li>Validity: 90 days from the date of subscription.</li>
            <li>Access to Udbodh and KyeBye.</li>
          </ul>

          {renderPlanButton(
            process.env.REACT_APP_PLAN_ID_COMBO_TWO_QUARTERLY,
            32
          )}
        </div>

        <div className="plan shadow">
          <h3 className="plan-title monthly">Monthly Plan</h3>
          <p className="plan-price">{"\n "}₹700</p>

          <ul className="plan-details">
            <li>Validity: 30 days from the date of subscription.</li>
            <li>Access to Udbodh and KyeBye.</li>
          </ul>

          {renderPlanButton(
            process.env.REACT_APP_PLAN_ID_COMBO_TWO_MONTHLY,
            96
          )}
        </div>
      </div>

      {/*-------------------------------------------------------------------------------------------------------------------*/}

      <h2
        id="combo-3"
        className="mt-5"
        style={{
          fontFamily: '"Libre Baskerville", serif',
          fontWeight: "bold",
        }}
      >
        Combo 3 ( Udbodh + Abhyudaya )
      </h2>
      <div
        className="plans d-flex flex-wrap shadow-lg rounded-5"
        style={{ padding: "20px", marginRight: "30px", marginLeft: "30px" }}
      >
        <div className="plan shadow">
          <h3 className="plan-title yearly">Yearly Plan</h3>
          <p className="plan-price">
            <small>
              <small>
                <small>
                  <small>
                    <small>
                      <s className="text-muted">₹ 14400</s>
                    </small>
                  </small>
                </small>
              </small>
            </small>
            {"\n "}₹12960
          </p>

          <ul className="plan-details">
            <li>Validity: 365 days from the date of subscription.</li>
            <li>Access to Udbodh and Abhyudaya.</li>
          </ul>

          {renderPlanButton(
            process.env.REACT_APP_PLAN_ID_COMBO_THREE_ANNUALLY,
            8
          )}
        </div>

        <div className="plan shadow">
          <h3 className="plan-title half-yearly">Half Yearly Plan</h3>
          <p className="plan-price">
            <small>
              <small>
                <small>
                  <small>
                    <small>
                      <s className="text-muted">₹ 7200</s>
                    </small>
                  </small>
                </small>
              </small>
            </small>
            {"\n "}₹6840
          </p>

          <ul className="plan-details">
            <li>Validity: 180 days from the date of subscription.</li>
            <li>Access to Udbodh and Abhyudaya.</li>
          </ul>

          {renderPlanButton(
            process.env.REACT_APP_PLAN_ID_COMBO_THREE_HALFYEARLY,
            16
          )}
        </div>

        <div className="plan shadow">
          <h3 className="plan-title quarterly">Quarterly Plan</h3>
          <p className="plan-price">
            <small>
              <small>
                <small>
                  <small>
                    <small>
                      <s className="text-muted">₹ 3600</s>
                    </small>
                  </small>
                </small>
              </small>
            </small>
            {"\n "}₹3510
          </p>

          <ul className="plan-details">
            <li>Validity: 90 days from the date of subscription.</li>
            <li>Access to Udbodh and Abhyudaya.</li>
          </ul>

          {renderPlanButton(
            process.env.REACT_APP_PLAN_ID_COMBO_THREE_QUARTERLY,
            32
          )}
        </div>

        <div className="plan shadow">
          <h3 className="plan-title monthly">Monthly Plan</h3>
          <p className="plan-price">{"\n "}₹1200</p>

          <ul className="plan-details">
            <li>Validity: 30 days from the date of subscription.</li>
            <li>Access to Udbodh and Abhyudaya.</li>
          </ul>

          {renderPlanButton(
            process.env.REACT_APP_PLAN_ID_COMBO_THREE_MONTHLY,
            96
          )}
        </div>
      </div>

      {/*-------------------------------------------------------------------------------------------------------------------*/}

      <h2
        id="combo-4"
        className="mt-5"
        style={{
          fontFamily: '"Libre Baskerville", serif',
          fontWeight: "bold",
        }}
      >
        Combo 4 ( Bauddha Dakshata + KyeBye )
      </h2>
      <div
        className="plans d-flex flex-wrap shadow-lg rounded-5"
        style={{ padding: "20px", marginRight: "30px", marginLeft: "30px" }}
      >
        <div className="plan shadow">
          <h3 className="plan-title yearly">Yearly Plan</h3>
          <p className="plan-price">
            <small>
              <small>
                <small>
                  <small>
                    <small>
                      <s className="text-muted">₹ 10800</s>
                    </small>
                  </small>
                </small>
              </small>
            </small>
            {"\n "}₹9720
          </p>

          <ul className="plan-details">
            <li>Validity: 365 days from the date of subscription.</li>
            <li>Access to Bauddha Dakshata and KyeBye.</li>
          </ul>

          {renderPlanButton(
            process.env.REACT_APP_PLAN_ID_COMBO_FOUR_ANNUALLY,
            8
          )}
        </div>

        <div className="plan shadow">
          <h3 className="plan-title half-yearly">Half Yearly Plan</h3>
          <p className="plan-price">
            <small>
              <small>
                <small>
                  <small>
                    <small>
                      <s className="text-muted">₹ 5400</s>
                    </small>
                  </small>
                </small>
              </small>
            </small>
            {"\n "}₹5130
          </p>

          <ul className="plan-details">
            <li>Validity: 180 days from the date of subscription.</li>
            <li>Access to Bauddha Dakshata and KyeBye.</li>
          </ul>

          {renderPlanButton(
            process.env.REACT_APP_PLAN_ID_COMBO_FOUR_HALFYEARLY,
            16
          )}
        </div>

        <div className="plan shadow">
          <h3 className="plan-title quarterly">Quarterly Plan</h3>
          <p className="plan-price">
            <small>
              <small>
                <small>
                  <small>
                    <small>
                      <s className="text-muted">₹ 2700</s>
                    </small>
                  </small>
                </small>
              </small>
            </small>
            {"\n "}₹2633
          </p>

          <ul className="plan-details">
            <li>Validity: 90 days from the date of subscription.</li>
            <li>Access to Bauddha Dakshata and KyeBye.</li>
          </ul>

          {renderPlanButton(
            process.env.REACT_APP_PLAN_ID_COMBO_FOUR_QUARTERLY,
            32
          )}
        </div>

        <div className="plan shadow">
          <h3 className="plan-title monthly">Monthly Plan</h3>
          <p className="plan-price">{"\n "}₹900</p>

          <ul className="plan-details">
            <li>Validity: 30 days from the date of subscription.</li>
            <li>Access to Bauddha Dakshata and KyeBye.</li>
          </ul>

          {renderPlanButton(
            process.env.REACT_APP_PLAN_ID_COMBO_FOUR_MONTHLY,
            96
          )}
        </div>
      </div>

      {/*-------------------------------------------------------------------------------------------------------------------*/}

      <h2
        id="combo-5"
        className="mt-5"
        style={{
          fontFamily: '"Libre Baskerville", serif',
          fontWeight: "bold",
        }}
      >
        Combo 5 ( Bauddha Dakshata + Abhyudaya )
      </h2>
      <div
        className="plans d-flex flex-wrap shadow-lg rounded-5"
        style={{ padding: "20px", marginRight: "30px", marginLeft: "30px" }}
      >
        <div className="plan shadow">
          <h3 className="plan-title yearly">Yearly Plan</h3>
          <p className="plan-price">
            <small>
              <small>
                <small>
                  <small>
                    <small>
                      <s className="text-muted">₹ 16800</s>
                    </small>
                  </small>
                </small>
              </small>
            </small>
            {"\n "}₹15120
          </p>

          <ul className="plan-details">
            <li>Validity: 365 days from the date of subscription.</li>
            <li>Access to Bauddha Dakshata and Abhyudaya.</li>
          </ul>

          {renderPlanButton(
            process.env.REACT_APP_PLAN_ID_COMBO_FIVE_ANNUALLY,
            8
          )}
        </div>

        <div className="plan shadow">
          <h3 className="plan-title half-yearly">Half Yearly Plan</h3>
          <p className="plan-price">
            <small>
              <small>
                <small>
                  <small>
                    <small>
                      <s className="text-muted">₹ 8400</s>
                    </small>
                  </small>
                </small>
              </small>
            </small>
            {"\n "}₹7980
          </p>

          <ul className="plan-details">
            <li>Validity: 180 days from the date of subscription.</li>
            <li>Access to Bauddha Dakshata and Abhyudaya.</li>
          </ul>

          {renderPlanButton(
            process.env.REACT_APP_PLAN_ID_COMBO_FIVE_HALFYEARLY,
            16
          )}
        </div>

        <div className="plan shadow">
          <h3 className="plan-title quarterly">Quarterly Plan</h3>
          <p className="plan-price">
            <small>
              <small>
                <small>
                  <small>
                    <small>
                      <s className="text-muted">₹ 4200</s>
                    </small>
                  </small>
                </small>
              </small>
            </small>
            {"\n "}₹4095
          </p>

          <ul className="plan-details">
            <li>Validity: 90 days from the date of subscription.</li>
            <li>Access to Bauddha Dakshata and Abhyudaya.</li>
          </ul>

          {renderPlanButton(
            process.env.REACT_APP_PLAN_ID_COMBO_FIVE_QUARTERLY,
            32
          )}
        </div>

        <div className="plan shadow">
          <h3 className="plan-title monthly">Monthly Plan</h3>
          <p className="plan-price">{"\n "}₹1400</p>

          <ul className="plan-details">
            <li>Validity: 30 days from the date of subscription.</li>
            <li>Access to Bauddha Dakshata and Abhyudaya.</li>
          </ul>

          {renderPlanButton(
            process.env.REACT_APP_PLAN_ID_COMBO_FIVE_MONTHLY,
            96
          )}
        </div>
      </div>

      {/*-------------------------------------------------------------------------------------------------------------------*/}

      <h2
        id="combo-6"
        className="mt-5"
        style={{
          fontFamily: '"Libre Baskerville", serif',
          fontWeight: "bold",
        }}
      >
        Combo 6 ( KyeBye + Abhyudaya )
      </h2>
      <div
        className="plans d-flex flex-wrap shadow-lg rounded-5"
        style={{ padding: "20px", marginRight: "30px", marginLeft: "30px" }}
      >
        <div className="plan shadow">
          <h3 className="plan-title yearly">Yearly Plan</h3>
          <p className="plan-price">
            <small>
              <small>
                <small>
                  <small>
                    <small>
                      <s className="text-muted">₹ 16800</s>
                    </small>
                  </small>
                </small>
              </small>
            </small>
            {"\n "}₹15120
          </p>

          <ul className="plan-details">
            <li>Validity: 365 days from the date of subscription.</li>
            <li>Access to KyeBye and Abhyudaya.</li>
          </ul>

          {renderPlanButton(
            process.env.REACT_APP_PLAN_ID_COMBO_SIX_ANNUALLY,
            8
          )}
        </div>

        <div className="plan shadow">
          <h3 className="plan-title half-yearly">Half Yearly Plan</h3>
          <p className="plan-price">
            <small>
              <small>
                <small>
                  <small>
                    <small>
                      <s className="text-muted">₹ 8400</s>
                    </small>
                  </small>
                </small>
              </small>
            </small>
            {"\n "}₹7980
          </p>

          <ul className="plan-details">
            <li>Validity: 180 days from the date of subscription.</li>
            <li>Access to KyeBye and Abhyudaya.</li>
          </ul>

          {renderPlanButton(
            process.env.REACT_APP_PLAN_ID_COMBO_SIX_HALFYEARLY,
            16
          )}
        </div>

        <div className="plan shadow">
          <h3 className="plan-title quarterly">Quarterly Plan</h3>
          <p className="plan-price">
            <small>
              <small>
                <small>
                  <small>
                    <small>
                      <s className="text-muted">₹ 4200</s>
                    </small>
                  </small>
                </small>
              </small>
            </small>
            {"\n "}₹4095
          </p>

          <ul className="plan-details">
            <li>Validity: 90 days from the date of subscription.</li>
            <li>Access to KyeBye and Abhyudaya.</li>
          </ul>

          {renderPlanButton(
            process.env.REACT_APP_PLAN_ID_COMBO_SIX_QUARTERLY,
            32
          )}
        </div>

        <div className="plan shadow">
          <h3 className="plan-title monthly">Monthly Plan</h3>
          <p className="plan-price">{"\n "}₹1400</p>

          <ul className="plan-details">
            <li>Validity: 30 days from the date of subscription.</li>
            <li>Access to KyeBye and Abhyudaya.</li>
          </ul>

          {renderPlanButton(
            process.env.REACT_APP_PLAN_ID_COMBO_SIX_MONTHLY,
            96
          )}
        </div>
      </div>

      {/*-------------------------------------------------------------------------------------------------------------------*/}

      <h2
        id="combo-7"
        className="mt-5"
        style={{
          fontFamily: '"Libre Baskerville", serif',
          fontWeight: "bold",
        }}
      >
        Combo 7 ( Udbodh + Bauddha Dakshata + KyeBye )
      </h2>
      <div
        className="plans d-flex flex-wrap shadow-lg rounded-5"
        style={{ padding: "20px", marginRight: "30px", marginLeft: "30px" }}
      >
        <div className="plan shadow">
          <h3 className="plan-title yearly">Yearly Plan</h3>
          <p className="plan-price">
            <small>
              <small>
                <small>
                  <small>
                    <small>
                      <s className="text-muted">₹ 13800</s>
                    </small>
                  </small>
                </small>
              </small>
            </small>
            {"\n "}₹12420
          </p>

          <ul className="plan-details">
            <li>Validity: 365 days from the date of subscription.</li>
            <li>Access to Udbodh, Bauddha Dakshata and KyeBye.</li>
          </ul>

          {renderPlanButton(
            process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_ANNUALLY,
            8
          )}
        </div>

        <div className="plan shadow">
          <h3 className="plan-title half-yearly">Half Yearly Plan</h3>
          <p className="plan-price">
            <small>
              <small>
                <small>
                  <small>
                    <small>
                      <s className="text-muted">₹ 6900</s>
                    </small>
                  </small>
                </small>
              </small>
            </small>
            {"\n "}₹6555
          </p>

          <ul className="plan-details">
            <li>Validity: 180 days from the date of subscription.</li>
            <li>Access to Udbodh, Bauddha Dakshata and KyeBye.</li>
          </ul>

          {renderPlanButton(
            process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_HALFYEARLY,
            16
          )}
        </div>

        <div className="plan shadow">
          <h3 className="plan-title quarterly">Quarterly Plan</h3>
          <p className="plan-price">
            <small>
              <small>
                <small>
                  <small>
                    <small>
                      <s className="text-muted">₹ 3450</s>
                    </small>
                  </small>
                </small>
              </small>
            </small>
            {"\n "}₹3364
          </p>

          <ul className="plan-details">
            <li>Validity: 90 days from the date of subscription.</li>
            <li>Access to Udbodh, Bauddha Dakshata and KyeBye.</li>
          </ul>

          {renderPlanButton(
            process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_QUARTERLY,
            32
          )}
        </div>

        <div className="plan shadow">
          <h3 className="plan-title monthly">Monthly Plan</h3>
          <p className="plan-price">{"\n "}₹1150</p>

          <ul className="plan-details">
            <li>Validity: 30 days from the date of subscription.</li>
            <li>Access to Udbodh, Bauddha Dakshata and KyeBye.</li>
          </ul>

          {renderPlanButton(
            process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_MONTHLY,
            96
          )}
        </div>
      </div>

      {/*-------------------------------------------------------------------------------------------------------------------*/}

      <h2
        id="combo-8"
        className="mt-5"
        style={{
          fontFamily: '"Libre Baskerville", serif',
          fontWeight: "bold",
        }}
      >
        Combo 8 ( Udbodh + Bauddha Dakshata + KyeBye + Abhyudaya )
      </h2>
      <div
        className="plans d-flex flex-wrap shadow-lg rounded-5"
        style={{ padding: "20px", marginRight: "30px", marginLeft: "30px" }}
      >
        <div className="plan shadow">
          <h3 className="plan-title yearly">Yearly Plan</h3>
          <p className="plan-price">
            <small>
              <small>
                <small>
                  <small>
                    <small>
                      <s className="text-muted">₹ 25200</s>
                    </small>
                  </small>
                </small>
              </small>
            </small>
            {"\n "}₹22680
          </p>

          <ul className="plan-details">
            <li>Validity: 365 days from the date of subscription.</li>
            <li>Access to Udbodh, Bauddha Dakshata, KyeBye and Abhyudaya.</li>
          </ul>

          {renderPlanButton(process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY, 8)}
        </div>

        <div className="plan shadow">
          <h3 className="plan-title half-yearly">Half Yearly Plan</h3>
          <p className="plan-price">
            <small>
              <small>
                <small>
                  <small>
                    <small>
                      <s className="text-muted">₹ 12600</s>
                    </small>
                  </small>
                </small>
              </small>
            </small>
            {"\n "}₹11970
          </p>

          <ul className="plan-details">
            <li>Validity: 180 days from the date of subscription.</li>
            <li>Access to Udbodh, Bauddha Dakshata, KyeBye and Abhyudaya.</li>
          </ul>

          {renderPlanButton(process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY, 16)}
        </div>

        <div className="plan shadow">
          <h3 className="plan-title quarterly">Quarterly Plan</h3>
          <p className="plan-price">
            <small>
              <small>
                <small>
                  <small>
                    <small>
                      <s className="text-muted">₹ 6300</s>
                    </small>
                  </small>
                </small>
              </small>
            </small>
            {"\n "}₹6143
          </p>

          <ul className="plan-details">
            <li>Validity: 90 days from the date of subscription.</li>
            <li>Access to Udbodh, Bauddha Dakshata, KyeBye and Abhyudaya.</li>
          </ul>

          {renderPlanButton(process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY, 32)}
        </div>

        <div className="plan shadow">
          <h3 className="plan-title monthly">Monthly Plan</h3>
          <p className="plan-price">{"\n "}₹2100</p>

          <ul className="plan-details">
            <li>Validity: 30 days from the date of subscription.</li>
            <li>Access to Udbodh, Bauddha Dakshata, KyeBye and Abhyudaya.</li>
          </ul>

          {renderPlanButton(process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY, 96)}
        </div>
      </div>
      <div className="contact-info">
        <p>FOR ANY QUERY PLEASE CONTACT:</p>
        <p>ashutosh@gyankosha.com</p>
      </div>

      <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            Gyankosha's Subscription Active now and your Referral Code :{" "}
            <span style={{ color: "red" }}>{referralCode}</span>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div style={{ fontFamily: "Arial, sans-serif", lineHeight: "1.6" }}>
            <p>
              Dear <strong>{currentUser1 ? currentUser1.name : ""}</strong>,
            </p>
            <p>
              Thank you for choosing <strong>Gyankosha</strong> as your trusted
              education partner. We are thrilled to have you on board! Your
              subscription plan is now active, giving you access to the premium
              content and resources.
            </p>
            <p>
              Attached, you will find your unique Referral Code:{" "}
              <strong style={{ color: "red" }}>{referralCode}</strong>.
            </p>
            <p>
              As a token of appreciation for your support, when a new user
              subscribes and makes their first payment using your referral code,
              you become eligible for a <strong>20% cashback</strong> to your
              account, and the one referred by you becomes eligible for a{" "}
              <strong>10% cashback</strong> to their account. Please note that
              the cashback is applicable only against the first payment made by
              the referred user and is valid for one cycle only.
            </p>
            <p>
              Let’s keep learning, growing, and making education exciting
              together!
            </p>
            <p>Warm regards,</p>
            <p>
              <strong>The Gyankosha Team</strong>
            </p>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showReferralModal}
        onHide={() => {
          setShowClassSelection(true);
          setShowReferralModal(false);
        }}
        centered
      >
        <Modal.Header closeButton>
          {hasReferralCode === null && (
            <Modal.Title
              style={{ fontFamily: "Arial, sans-serif", fontWeight: "bold" }}
            >
              Do you have a referral code?
            </Modal.Title>
          )}
        </Modal.Header>
        <Modal.Body
          style={{ fontFamily: "Arial, sans-serif", lineHeight: "1.6" }}
        >
          
            <div>
              {hasReferralCode === null && (
                <div className="d-flex flex-column align-items-start">
                  <div className="form-check custom-radio mb-3">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="referralCodeOption"
                      id="referralYes"
                      onChange={() => setHasReferralCode(true)}
                    />
                    <label
                      className="form-check-label ms-2"
                      htmlFor="referralYes"
                    >
                      Yes, I have a referral code
                    </label>
                  </div>
                  <div className="form-check custom-radio">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="referralCodeOption"
                      id="referralNo"
                      onChange={() => setHasReferralCode(false)}
                    />
                    <label
                      className="form-check-label ms-2"
                      htmlFor="referralNo"
                    >
                      No, I don't have a referral code
                    </label>
                  </div>
                </div>
              )}
              {hasReferralCode === true && !referralCodeVerified && (
                <div>
                  <label htmlFor="referralInput" style={{ fontWeight: "bold" }}>
                    Enter your referral code:
                  </label>
                  <input
                    type="text"
                    className="form-control mt-2"
                    id="referralInput"
                    placeholder="Enter referral code here"
                    value={referralInput}
                    onChange={(e) => setReferralInput(e.target.value)}
                    style={{ border: "1px solid #ced4da", borderRadius: "5px" }}
                  />
                  <button
                    className="btn btn-primary mt-3 w-100"
                    onClick={verifyReferralCode}
                    disabled={verifyingReferral}
                    style={{ boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)" }}
                  >
                    {verifyingReferral
                      ? "Verifying..."
                      : "Verify Referral Code"}
                  </button>
                </div>
              )}
              {referralCodeVerified && (
                <div className="text-center">
                  <p className="text-success" style={{ fontWeight: "bold" }}>
                    Referral code verified successfully!
                  </p>
                  <button
                    className="btn btn-success mt-3 w-100"
                    onClick={() => {
                      setShowReferralModal(false);
                      handleSubscription(selectedPlanId, selectedTotalCount);
                    }}
                    style={{ boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)" }}
                  >
                    Proceed to Payment
                  </button>
                </div>
              )}
              {hasReferralCode === false && (
                <div className="text-center">
                  <p className="text-muted" style={{ fontWeight: "bold" }}>
                    No referral code? No problem!
                  </p>
                  <button
                    className="btn btn-success mt-3 w-100"
                    onClick={() => {
                      setShowReferralModal(false);
                      handleSubscription(selectedPlanId, selectedTotalCount);
                    }}
                    style={{ boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)" }}
                  >
                    Proceed to Payment
                  </button>
                </div>
              )}
            </div>
          
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Paymentplansscreen;
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
