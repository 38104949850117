import React, { useEffect } from "react";
import "./Firstscreen.css";
import Ring from "./Ring";
import MediaContainer from "./MediaContainer";
import MediaContainer2 from "./MediaContainer2";
import ProductOfferings from "./ProductOfferings";
import LearningOutcomes from "./LearningOutcomes";
import FooterInfo from "./FooterInfo";
import Learning from "./Learning";
import Gallery from "./Gallery";

function Firstscreen() {
  useEffect(() => {
    const handleScroll = () => {
      const sections = document.querySelectorAll(".section");

      sections.forEach((section, index) => {
        const rect = section.getBoundingClientRect();
        const nextSection = sections[index + 1];

        // Exclude the "ring-section" from hiding
        if (section.id === "ring-section") {
          section.classList.add("visible");
          section.classList.remove("hidden");
          return; // Skip further logic for "ring-section"
        }

        // General visibility logic for other sections
        if (rect.top < window.innerHeight && rect.bottom > 0) {
          section.classList.add("visible");
          section.classList.remove("hidden");
        } else {
          section.classList.remove("visible");
          section.classList.add("hidden");
        }

        // Hide current section when the next section reaches the top
        if (
          nextSection &&
          nextSection.getBoundingClientRect().top < window.innerHeight / 2
        ) {
          section.classList.remove("visible");
          section.classList.add("hidden");
        }
      });
    };

    // Attach scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Trigger on mount
    handleScroll();

    // Cleanup on unmount
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div className="firstscreen">
      {/* Sections */}
      <div id="ring-section" className="section" style={{ top: "0px" }}>
        <Ring />
      </div>
      <div id="mediacontainer2" className="section">
        <MediaContainer2 />
      </div>
      <div id="productofferings" className="section">
        <ProductOfferings />
      </div>
      <div id="MediaContainer" className="section">
        <MediaContainer />
      </div>
      <div id="LearningAsStudent" className="section">
        <Learning />
      </div>
      <div id="Gallery" className="section">
        <Gallery />
      </div>
      <div id="LearningOutcomes" className="section">
        <LearningOutcomes />
      </div>
      <div id="FooterInfo" className="section footer-section">
        <FooterInfo />
      </div>
    </div>
  );
}

export default Firstscreen;
