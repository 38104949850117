import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

const MediaContainer = () => {
  const navigate = useNavigate();
  const sections = [
    {
      title: "STUDENT-CENTRIC CLASSROOM PROGRAMS",
      description:
        "Gyankosha’s Student Learning Vertical offers programs in diverse subjects focussed towards School Curriculum, General Awareness, Mental Abilities, and Competitive Exam Preparation, designed to enhance  academic excellence, rigour and  critical thinking.",
      image: "/sTUDENTlEARNING.jpg",
      buttonAction: () => navigate("/StudentLearning"),
    },
    {
      title: "21ST CENTURY EDUCATOR PROGRAM",
      description:
        "Gyankosha’s 21st Century Educator Vertical (Teacher Training Programs) equip educators with innovative teaching methodologies, classroom management techniques, and technology integration skills to drive impactful learning experiences and inspire student success.",
      image: "/wa4.jpeg",
      buttonAction: () => navigate("/TeacherTraining"),
    },
    {
      title: "PARENTING EXCELLENCE PROGRAM",
      description:
        "Gyankosha’s Parenting Vertical empowers parents with practical tools and strategies to foster their child’s academic, emotional, and social growth. Through collaborative sessions, we build stronger school-parent relationships for holistic development.",
      image: "/Parenting.png",
      buttonAction: () => navigate("/Parenting"),
    },
  ];

  const [activeSection, setActiveSection] = useState(""); // Default section
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [navbarVisible, setNavbarVisible] = useState(false);
  const sectionRefs = useRef([]); // To track each section
  const [visibleSections, setVisibleSections] = useState([]);
  const [isHovered, setIsHovered] = useState(
    Array(sections.length).fill(false)
  );

  // Update visibility of the navbar
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setNavbarVisible(true); // Show navbar when section is in view
            setVisibleSections((prev) => [...prev, entry.target]);
            observer.unobserve(entry.target);
          } else {
            setNavbarVisible(false); // Hide navbar when section goes out of view
          }
        });
      },
      { threshold: 0.5 }
    );

    sectionRefs.current.forEach((ref) => {
      if (ref) observer.observe(ref);
    });

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      observer.disconnect();
    };
  }, []);

  const styles = {
    container: {
      fontFamily: '"Libre Baskerville", serif',
      margin: "5vh 0vh",

      padding: 0,
    },
    header: {
      backgroundColor: "#2c1a63",
      fontWeight: "bolder",
      color: "white",
      textAlign: "center",
      padding: isMobile ? "0.5rem" : "1rem",
      transition: "opacity 0.5s ease", // Smooth transition for navbar visibility
      opacity: navbarVisible ? 1 : 0,
      visibility: navbarVisible ? "visible" : "hidden",
    },
    content: {
      display: "grid",
      gridTemplateColumns:
        window.innerWidth <= 600
          ? "1fr"
          : "repeat(auto-fit, minmax(250px, 1fr))",
      gap: "2rem",
      padding: "1rem",
    },
    item: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between", // Distribute content evenly
      alignItems: "center",
      background:
        "linear-gradient(to top, rgb(251, 252, 252), #e0f7fa, rgb(128, 206, 234))",
      padding: "1.5rem",
      textAlign: "center",
      borderRadius: "0.8rem",
      transition: "transform 0.1s, background 0.1s, color 0.1s", // Smooth transition
      opacity: 0, // Hidden by default
      maxheight: "800px",
      transform: "translateY(20px)", // Initial animation state
    },
    itemVisible: {
      opacity: 1,
      transform: "translateY(0)",
      transition: "opacity 0.6s ease, transform 0.6s ease",
    },
    image: {
      width: "100%", // Ensures the image spans the full width of the container
      maxWidth: "500px", // Constrain the image width for consistency
      height: "250px", // Fixed height for all images
      borderRadius: "0.5rem", // Rounded corners
      objectFit: "cover", // Ensures the image covers the defined area proportionally
      margin: "0 auto", // Centers the image horizontally
      display: "block", // Ensures proper centering in case of inline contexts
    },

    heading: {
      color: "#2c1a63",
      fontSize: isMobile ? "1.3rem" : "1.5rem",
      marginTop: "1rem",
    },
    paragraph: {
      fontSize: isMobile ? "0.9rem" : "1rem",
      color: "#333",
      margin: "1rem 0",
    },
    button: {
      marginTop: "auto",
      padding: "1rem 2rem",
      backgroundColor: "#2c1a63",
      color: "white",
      border: "none",
      borderRadius: "0.5rem",
      cursor: "pointer",
      alignSelf: "center",
      transition: "background-color 0.3s ease, transform 0.2s ease",
    },
    buttonHovered: {
      backgroundColor: "#1a0f50", // Darker shade on hover
      transform: "scale(1.05)", // Slight scaling effect
    },

    itemHovered: {
      transform: "scale(1.05)", // Apply scaling on hover
      background:
        "linear-gradient(to top, rgba(0, 8, 244, 0.6),rgb(1, 5, 131), rgb(1, 2, 48))",
      color: "rgb(251, 252, 252)", // Fallback color for text
      transition: "transform 0.3s ease, background 0.3s ease, color 0.3s ease",
    },
    headingHovered: {
      color: "rgb(251, 252, 252)", // Change heading color on hover
      transition: "color 0.3s ease",
    },
    paragraphHovered: {
      color: "rgb(210, 230, 255)", // Change paragraph color on hover
      transition: "color 0.3s ease",
    },
  };

  const handleNavigate = (sectionId) => {
    setActiveSection(sectionId);
  };
  return (
    <div style={styles.container}>
      <div style={{ ...styles.header, fontSize: isMobile ? "1.5rem" : "2rem" }}>
        <p>EXPLORE SOLUTIONS FOR SCHOOLS</p>
      </div>
      <div style={styles.content}>
        {sections.map((section, index) => (
          <div
            key={index}
            ref={(el) => (sectionRefs.current[index] = el)}
            style={{
              ...styles.item,
              ...(visibleSections.includes(sectionRefs.current[index])
                ? styles.itemVisible
                : {}),
              ...(isHovered[index] ? styles.itemHovered : {}),
            }}
            onMouseEnter={() => {
              const newHovered = [...isHovered];
              newHovered[index] = true;
              setIsHovered(newHovered);
            }}
            onMouseLeave={() => {
              const newHovered = [...isHovered];
              newHovered[index] = false;
              setIsHovered(newHovered);
            }}
          >
            <img src={section.image} alt={section.title} style={styles.image} />
            <h2
              style={{
                ...styles.heading,
                ...(isHovered[index] ? styles.headingHovered : {}),
              }}
            >
              {section.title}
            </h2>
            <p
              style={{
                ...styles.paragraph,
                ...(isHovered[index] ? styles.paragraphHovered : {}),
              }}
            >
              {section.description}
            </p>
            <button
              style={styles.button}
              onClick={() => handleNavigate(section.buttonAction)}
            >
              Learn More
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MediaContainer;
