// import React, { useState, useEffect } from "react";
// import { useSelector, useDispatch } from "react-redux";
// import { logoutUser } from "../actions/userActions";
// //import { useLocation } from "react-router-dom";
// import { useLocation, useNavigate } from "react-router-dom"; // Import useNavigate
// import axios from "axios";
// import "./Navbar.css";

// function Navbar() {
//   const userstate = useSelector((state) => state.loginUserReducer);
//   const { currentUser1 } = userstate;
//   const dispatch = useDispatch();
//   const location = useLocation();
//   const navigate = useNavigate(); // Use navigate hook

//   const isOnFirstScreen = location.pathname === "/";

//   const isOnLoginScreen = location.pathname === "/login";

//   const isOnRegisterScreen = location.pathname === "/register";

//   const isOnQuestionsScreen = location.pathname === "/questions";

//   const isOnKyebyeQuestionsScreen = location.pathname === "/kyebyequestions";

//   const isOnReattemptScreen = location.pathname === "/reattempt";

//   const [transparent, setTransparent] = useState(true);
//   const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

//   const buttonShadowStyle = {
//     boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.5)",
//     transition: "background-color 0.3s",
//   };

//   useEffect(() => {
//     const handleScroll = () => {
//       if (isOnQuestionsScreen) {
//         setTransparent(true); // Keep Navbar transparent on /questions
//       } else {
//         const isTransparent = window.scrollY < 50;
//         setTransparent(isTransparent);
//       }
//     };

//     window.addEventListener("scroll", handleScroll);
//     return () => {
//       window.removeEventListener("scroll", handleScroll);
//     };
//   }, [isOnQuestionsScreen]);

//   useEffect(() => {
//     const verifyBrowserId = async () => {
//       const uniqueBrowserId = localStorage.getItem("uniqueBrowserId");

//       if (currentUser1 && uniqueBrowserId) {
//         try {
//           const response = await axios.post(`/api/users/verifyBrowserId`, {
//             userId: currentUser1.userId,
//             uniqueBrowserId,
//           });

//           if (!response.data.match) {
//             // If browser IDs don't match, clear localStorage and redirect
//             localStorage.removeItem("currentUser1");
//             localStorage.removeItem("uniqueBrowserId");
//             navigate("/login");
//           }
//         } catch (error) {
//           console.error("Error verifying browser ID:", error);
//           // Clear localStorage and redirect in case of error
//           localStorage.removeItem("currentUser1");
//           localStorage.removeItem("uniqueBrowserId");
//           navigate("/login");
//         }
//       }
//     };

//     verifyBrowserId();
//   }, [currentUser1, navigate]);

//   const scrollToMediaContainer = () => {
//     if (isOnFirstScreen) {
//       // Scroll to 100px above the media container
//       const mediaContainer = document.getElementById("media-container");
//       if (mediaContainer) {
//         const mediaContainerTop =
//           mediaContainer.getBoundingClientRect().top + window.pageYOffset;
//         window.scrollTo({
//           top: isMobile ? mediaContainerTop + 50 : mediaContainerTop - 200, // Adjusting scroll position to 100px above
//           behavior: "smooth",
//         });
//       }
//     } else {
//       // Navigate to the first screen and scroll after navigation
//       navigate("/", { state: { scrollTo: "media-container" } });
//     }
//   };

//   const scrollToFooterInfo = () => {
//     if (isOnFirstScreen) {
//       const footerInfo = document.getElementById("footer-info");
//       if (footerInfo) {
//         const footerInfoTop =
//           footerInfo.getBoundingClientRect().top + window.pageYOffset;
//         window.scrollTo({
//           top: footerInfoTop - 100, // Adjust to scroll above FooterInfo if needed
//           behavior: "smooth",
//         });
//       }
//     } else {
//       navigate("/", { state: { scrollTo: "footer-info" } });
//     }
//   };

//   useEffect(() => {
//     if (
//       location.pathname === "/" &&
//       location.state?.scrollTo === "media-container"
//     ) {
//       const mediaContainer = document.getElementById("media-container");
//       if (mediaContainer) {
//         const mediaContainerTop =
//           mediaContainer.getBoundingClientRect().top + window.pageYOffset;
//         window.scrollTo({
//           top: mediaContainerTop - 100, // Adjusting scroll position to 100px above
//           behavior: "smooth",
//         });
//       }
//     }

//     if (
//       location.pathname === "/" &&
//       location.state?.scrollTo === "footer-info"
//     ) {
//       const footerInfo = document.getElementById("footer-info");
//       if (footerInfo) {
//         const footerInfoTop =
//           footerInfo.getBoundingClientRect().top + window.pageYOffset;
//         window.scrollTo({
//           top: footerInfoTop - 100,
//           behavior: "smooth",
//         });
//       }
//     }
//   }, [location]);

//   return (
//     <div>
//       {isOnQuestionsScreen ||
//       isOnKyebyeQuestionsScreen ||
//       isOnReattemptScreen ? (
//         <></>
//       ) : (
//         <>
//           <nav
//             className={`navbar navbar-expand-lg p-3 mb-5 ${
//               transparent ? "bg-transparent" : "bg-white rounded"
//             }`}
//             style={{
//               position: "fixed",
//               width: "100%",
//               zIndex: 1000, // Ensure the navbar is always on top
//               transition: "background-color 0.3s",
//               backgroundColor: transparent ? "transparent" : "#fff",
//             }}
//           >
//             <div className="d-flex align-items-center">
//               <a className="navbar-brand" href="/">
//                 <img
//                   src="gyankosha_logo.png"
//                   width="100"
//                   height="100"
//                   alt="Gyankosha Logo"
//                 />
//               </a>
//             </div>

//             <button
//               className="navbar-toggler"
//               type="button"
//               data-bs-toggle="collapse"
//               data-bs-target="#navbarNav"
//               aria-controls="navbarNav"
//               aria-expanded="false"
//               aria-label="Toggle navigation"
//             >
//               <span className="navbar-toggler-icon"></span>
//             </button>
//             <div className="collapse navbar-collapse" id="navbarNav">
//               <ul className="navbar-nav ms-auto">
//                 {currentUser1 && !isOnFirstScreen && !isOnLoginScreen ? (
//                   <div className="dropdown">
//                     <a
//                       className="dropdown-toggle nav-link btn btn-primary"
//                       href="#"
//                       role="button"
//                       data-bs-toggle="dropdown"
//                       aria-expanded="false"
//                       style={{
//                         ...buttonShadowStyle,
//                         fontFamily: "'Georgia Ref', Georgia, serif",
//                         //fontWeight: "bold",
//                         fontSize: "15px",
//                         textAlign: "center",
//                         marginRight: isMobile ? "" : "120px",
//                       }}
//                     >
//                       {currentUser1.name}
//                     </a>

//                     <ul className="dropdown-menu">
//                       <li>
//                         <a
//                           className="dropdown-item"
// style={{
//   fontFamily: '"Libre Baskerville", serif',
//   fontWeight: "bold",
// }}
//                           href="/middlescreen2"
//                           onClick={(e) => {
//                             if (
//                               currentUser1?.classs === "Already passed 12th"
//                             ) {
//                               e.preventDefault();
//                               alert(
//                                 "Udbodh and Bauddha Dakshata are only available for class 1st to 12th."
//                               );
//                             }
//                           }}
//                         >
//                           Udbodh
//                         </a>
//                       </li>
//                       <li>
//                         <a
//                           className="dropdown-item"
//                           style={{
//                             fontFamily: '"Libre Baskerville", serif',
//                             fontWeight: "bold",
//                           }}
//                           href="/middlescreen"
//                           onClick={(e) => {
//                             if (
//                               currentUser1?.classs === "Already passed 12th"
//                             ) {
//                               e.preventDefault();
//                               alert(
//                                 "Udbodh and Bauddha Dakshata are only available for class 1st to 12th."
//                               );
//                             }
//                           }}
//                         >
//                           Bauddha Dakshata
//                         </a>
//                       </li>
//                       <li>
//                         <a
//                           className="dropdown-item"
//                           style={{
//                             fontFamily: '"Libre Baskerville", serif',
//                             fontWeight: "bold",
//                           }}
//                           href="/middlescreen4"
//                         >
//                           Abhyudaya
//                         </a>
//                       </li>
//                       <li>
//                         <a
//                           className="dropdown-item"
//                           style={{
//                             fontFamily: '"Libre Baskerville", serif',
//                             fontWeight: "bold",
//                           }}
//                           href="/middlescreen3"
//                         >
//                           KYE BYE
//                         </a>
//                       </li>
//                       <li>
//                         <a
//                           className="dropdown-item"
//                           href="/middlescreen5"
//                           style={{
//                             fontFamily: '"Libre Baskerville", serif',
//                             fontWeight: "bold",
//                           }}
//                         >
//                           Business Wisdom
//                         </a>
//                       </li>
//                       <li>
//                         <a
//                           className="dropdown-item"
//                           style={{
//                             fontFamily: '"Libre Baskerville", serif',
//                             fontWeight: "bold",
//                           }}
//                           href="/paymentplans"
//                         >
//                           Plans for Premium Quiz
//                         </a>
//                       </li>
//                       {currentUser1.paid ? (
//                         <li>
//                           <a
//                             className="dropdown-item"
//                             style={{
//                               fontFamily: '"Libre Baskerville", serif',
//                               fontWeight: "bold",
//                             }}
//                             href="/dashboard"
//                           >
//                             Dashboard
//                           </a>
//                         </li>
//                       ) : (
//                         <></>
//                       )}
//                       {currentUser1.isAdmin ||
//                       currentUser1.isSubAdmin ||
//                       currentUser1.isSubAdminTwo ? (
//                         <li>
//                           <a
//                             className="dropdown-item"
//                             style={{
//                               fontFamily: '"Libre Baskerville", serif',
//                               fontWeight: "bold",
//                             }}
//                             href="/adminpanel"
//                           >
//                             Admin Panel
//                           </a>
//                         </li>
//                       ) : null}
//                       <li>
//                         <a
//                           className="dropdown-item"
//                           href="#"
//                           style={{
//                             fontFamily: '"Libre Baskerville", serif',
//                             fontWeight: "bold",
//                           }}
//                           onClick={() => {
//                             dispatch(logoutUser(currentUser1._id));
//                           }}
//                         >
//                           Logout
//                         </a>
//                       </li>
//                     </ul>
//                   </div>
//                 ) : isOnFirstScreen ? (
//                   <>
//                     <div className="dropdown">
//                       <a
//                         className="dropdown-toggle nav-link btn btn-primary"
//                         href="#"
//                         role="button"
//                         data-bs-toggle="dropdown"
//                         aria-expanded="false"
//                         style={{
//                           ...buttonShadowStyle,
//                           fontFamily: "'Georgia Ref', Georgia, serif",
//                           //fontWeight: "bold",
//                           fontSize: "15px",
//                           textAlign: "center",
//                           marginRight: isMobile ? "" : "100px",

//                           // paddingLeft: isMobile ? "20px" : "100px",
//                           // color: "red",
//                         }}
//                       >
//                         E x p l o r e
//                       </a>

//                       <ul className="dropdown-menu">
//                         <li>
//                           <a
//                             className="dropdown-item"
//                             href="/middlescreen2"
//                             style={{
//                               fontFamily: '"Libre Baskerville", serif',
//                               fontWeight: "bold",
//                             }}
//                             onClick={(e) => {
//                               if (
//                                 currentUser1?.classs === "Already passed 12th"
//                               ) {
//                                 e.preventDefault();
//                                 alert(
//                                   "Udbodh and Bauddha Dakshata are only available for class 1st to 12th."
//                                 );
//                               }
//                             }}
//                           >
//                             Udbodh
//                           </a>
//                         </li>
//                         <li>
//                           <a
//                             className="dropdown-item"
//                             href="/middlescreen"
//                             style={{
//                               fontFamily: '"Libre Baskerville", serif',
//                               fontWeight: "bold",
//                             }}
//                             onClick={(e) => {
//                               if (
//                                 currentUser1?.classs === "Already passed 12th"
//                               ) {
//                                 e.preventDefault();
//                                 alert(
//                                   "Udbodh and Bauddha Dakshata are only available for class 1st to 12th."
//                                 );
//                               }
//                             }}
//                           >
//                             Bauddha Dakshata
//                           </a>
//                         </li>
//                         <li>
//                           <a
//                             className="dropdown-item"
//                             style={{
//                               fontFamily: '"Libre Baskerville", serif',
//                               fontWeight: "bold",
//                             }}
//                             href="/middlescreen4"
//                           >
//                             Abhyudaya
//                           </a>
//                         </li>
//                         <li>
//                           <a
//                             className="dropdown-item"
//                             href="/middlescreen3"
//                             style={{
//                               fontFamily: '"Libre Baskerville", serif',
//                               fontWeight: "bold",
//                             }}
//                           >
//                             KYE BYE
//                           </a>
//                         </li>
//                         <li>
//                           <a
//                             className="dropdown-item"
//                             href="/middlescreen5"
//                             style={{
//                               fontFamily: '"Libre Baskerville", serif',
//                               fontWeight: "bold",
//                             }}
//                           >
//                             Business Wisdom
//                           </a>
//                         </li>
//                         <li>
//                           <a
//                             className="dropdown-item"
//                             href="/paymentplans"
//                             style={{
//                               fontFamily: '"Libre Baskerville", serif',
//                               fontWeight: "bold",
//                             }}
//                           >
//                             Plans for Premium Quiz
//                           </a>
//                         </li>
//                         <li>
//                           {currentUser1 ? (
//                             <a
//                               className="dropdown-item"
//                               href="#"
//                               style={{
//                                 fontFamily: '"Libre Baskerville", serif',
//                                 fontWeight: "bold",
//                               }}
//                               onClick={() => {
//                                 dispatch(logoutUser(currentUser1._id));
//                                 // Optionally redirect to homepage or login page here after logout
//                               }}
//                             >
//                               Logout
//                             </a>
//                           ) : (
//                             <a
//                               className="dropdown-item"
//                               href="/login"
//                               style={{
//                                 fontFamily: '"Libre Baskerville", serif',
//                                 fontWeight: "bold",
//                               }}
//                             >
//                               Login
//                             </a>
//                           )}
//                         </li>
//                         <li>
//                           <a
//                             className="dropdown-item"
//                             href="/register"
//                             style={{
//                               fontFamily: '"Libre Baskerville", serif',
//                               fontWeight: "bold",
//                             }}
//                           >
//                             Register
//                           </a>
//                         </li>
//                         <li>
//                           <a
//                             className="dropdown-item"
//                             href="/tutorial"
//                             style={{
//                               fontFamily: '"Libre Baskerville", serif',
//                               fontWeight: "bold",
//                             }}
//                           >
//                             Tutorial for Registration
//                           </a>
//                         </li>
//                       </ul>
//                     </div>
//                   </>
//                 ) : (
//                   !isOnLoginScreen &&
//                   !isOnRegisterScreen && (
//                     <li className="nav-item">
//                       <a
//                         className="nav-link"
//                         href="/login"
//                         style={{
//                           fontFamily: '"Libre Baskerville", serif',
//                           fontWeight: "bold",
//                         }}
//                       >
//                         Login
//                       </a>
//                     </li>
//                   )
//                 )}
//               </ul>
//             </div>
//           </nav>
//           {/* Padding to offset the fixed navbar height */}
//           <div
//             style={{ paddingTop: isOnFirstScreen ? "0.001px" : "70px" }}
//           ></div>
//         </>
//       )}
//     </div>
//   );
// }

// export default Navbar;
/////////////////////////////////////////////////////////////////////////////////////////////////////////
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { logoutUser } from "../actions/userActions";
//import { useLocation } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom"; // Import useNavigate
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import "./Navbar.css";

function Navbar() {
  const userstate = useSelector((state) => state.loginUserReducer);
  const { currentUser1 } = userstate;
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate(); // Use navigate hook

  const isOnFirstScreen = location.pathname === "/";

  const isOnLoginScreen = location.pathname === "/login";

  const isOnRegisterScreen = location.pathname === "/register";

  const isOnQuestionsScreen = location.pathname === "/questions";

  const isOnKyebyeQuestionsScreen = location.pathname === "/kyebyequestions";

  const isOnReattemptScreen = location.pathname === "/reattempt";

  const [transparent, setTransparent] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  const [showClassModal, setShowClassModal] = useState(false);
  const [selectedClass, setSelectedClass] = useState("");
  const [classes, setClasses] = useState([]); // Store classes for the dropdown
  const [targetRoute, setTargetRoute] = useState(""); // Route to navigate after selecting class

  const buttonShadowStyle = {
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.5)",
    transition: "background-color 0.3s",
  };

  useEffect(() => {
    const handleScroll = () => {
      if (isOnQuestionsScreen) {
        setTransparent(true); // Keep Navbar transparent on /questions
      } else {
        const isTransparent = window.scrollY < 50;
        setTransparent(isTransparent);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isOnQuestionsScreen]);

  useEffect(() => {
    // Fetch available classes from the backend
    const fetchClasses = async () => {
      try {
        const response = await axios.get("/api/classss/getallclassss");
        setClasses(response.data); // Assuming the response has the array of classes
      } catch (error) {
        console.error("Error fetching classes:", error);
      }
    };

    fetchClasses();
  }, []);

  useEffect(() => {
    const verifyBrowserId = async () => {
      const uniqueBrowserId = localStorage.getItem("uniqueBrowserId");

      if (currentUser1 && uniqueBrowserId) {
        try {
          const response = await axios.post(`/api/users/verifyBrowserId`, {
            userId: currentUser1.userId,
            uniqueBrowserId,
          });

          if (!response.data.match) {
            // If browser IDs don't match, clear localStorage and redirect
            localStorage.removeItem("currentUser1");
            localStorage.removeItem("uniqueBrowserId");
            navigate("/login");
          }
        } catch (error) {
          console.error("Error verifying browser ID:", error);
          // Clear localStorage and redirect in case of error
          localStorage.removeItem("currentUser1");
          localStorage.removeItem("uniqueBrowserId");
          navigate("/login");
        }
      }
    };

    verifyBrowserId();
  }, [currentUser1, navigate]);

  const handleClassChange = (e) => {
    setSelectedClass(e.target.value);
  };

  const handleModalClose = () => {
    setShowClassModal(false);
  };

  const handleModalConfirm = () => {
    if (selectedClass) {
      // navigate(`/middlescreen`, { state: { classs: selectedClass } });
      navigate(`${targetRoute}?classs=${encodeURIComponent(selectedClass)}`);
      setShowClassModal(false);
    } else {
      alert("Please select a class.");
    }
  };

  const handleClassRestrictedNavigation = (e, path, route) => {
    if (currentUser1?.classs === "Already passed 12th") {
      e.preventDefault();
      setTargetRoute(route); // Set the target route
      setShowClassModal(true);
    } else {
      //navigate(path);
      e.preventDefault();
      setTargetRoute(route); // Set the target route
      setShowClassModal(true);
    }
  };

  const scrollToMediaContainer = () => {
    if (isOnFirstScreen) {
      // Scroll to 100px above the media container
      const mediaContainer = document.getElementById("media-container");
      if (mediaContainer) {
        const mediaContainerTop =
          mediaContainer.getBoundingClientRect().top + window.pageYOffset;
        window.scrollTo({
          top: isMobile ? mediaContainerTop + 50 : mediaContainerTop - 200, // Adjusting scroll position to 100px above
          behavior: "smooth",
        });
      }
    } else {
      // Navigate to the first screen and scroll after navigation
      navigate("/", { state: { scrollTo: "media-container" } });
    }
  };

  const scrollToFooterInfo = () => {
    if (isOnFirstScreen) {
      const footerInfo = document.getElementById("footer-info");
      if (footerInfo) {
        const footerInfoTop =
          footerInfo.getBoundingClientRect().top + window.pageYOffset;
        window.scrollTo({
          top: footerInfoTop - 100, // Adjust to scroll above FooterInfo if needed
          behavior: "smooth",
        });
      }
    } else {
      navigate("/", { state: { scrollTo: "footer-info" } });
    }
  };

  useEffect(() => {
    if (
      location.pathname === "/" &&
      location.state?.scrollTo === "media-container"
    ) {
      const mediaContainer = document.getElementById("media-container");
      if (mediaContainer) {
        const mediaContainerTop =
          mediaContainer.getBoundingClientRect().top + window.pageYOffset;
        window.scrollTo({
          top: mediaContainerTop - 100, // Adjusting scroll position to 100px above
          behavior: "smooth",
        });
      }
    }

    if (
      location.pathname === "/" &&
      location.state?.scrollTo === "footer-info"
    ) {
      const footerInfo = document.getElementById("footer-info");
      if (footerInfo) {
        const footerInfoTop =
          footerInfo.getBoundingClientRect().top + window.pageYOffset;
        window.scrollTo({
          top: footerInfoTop - 100,
          behavior: "smooth",
        });
      }
    }
  }, [location]);

  return (
    <div>
      {isOnQuestionsScreen ||
      isOnKyebyeQuestionsScreen ||
      isOnReattemptScreen ? (
        <></>
      ) : (
        <>
          <nav
            className={`navbar navbar-expand-lg p-3 mb-5 ${
              transparent ? "bg-transparent" : "bg-white rounded"
            }`}
            style={{
              position: "fixed",
              width: "100%",
              zIndex: 1000, // Ensure the navbar is always on top
              transition: "background-color 0.3s",
              backgroundColor: transparent ? "transparent" : "#fff",
            }}
          >
            <div className="d-flex align-items-center">
              <a className="navbar-brand" href="/">
                <img
                  src="gyankosha_logo.png"
                  width="100"
                  height="100"
                  alt="Gyankosha Logo"
                />
              </a>
            </div>

            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarNav">
              <ul className="navbar-nav ms-auto">
                {currentUser1 && !isOnFirstScreen && !isOnLoginScreen ? (
                  <div className="dropdown">
                    <a
                      className="dropdown-toggle nav-link btn btn-primary"
                      href="#"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      style={{
                        ...buttonShadowStyle,
                        fontFamily: "'Georgia Ref', Georgia, serif",
                        //fontWeight: "bold",
                        fontSize: "15px",
                        textAlign: "center",
                        marginRight: isMobile ? "" : "120px",
                      }}
                    >
                      {currentUser1.name}
                    </a>

                    <ul className="dropdown-menu">
                      <li>
                        <a
                          className="dropdown-item"
                          style={{
                            fontFamily: '"Libre Baskerville", serif',
                            fontWeight: "bold",
                          }}
                          href="/middlescreen2"
                          onClick={(e) =>
                            handleClassRestrictedNavigation(
                              e,
                              "/middlescreen2",
                              "/udbodh"
                            )
                          }
                        >
                          Udbodh
                        </a>
                      </li>
                      <li>
                        <a
                          className="dropdown-item"
                          style={{
                            fontFamily: '"Libre Baskerville", serif',
                            fontWeight: "bold",
                          }}
                          href="/middlescreen"
                          onClick={(e) =>
                            handleClassRestrictedNavigation(
                              e,
                              "/middlescreen",
                              "/bauddhadakshata"
                            )
                          }
                        >
                          Bauddha Dakshata
                        </a>
                      </li>
                      <li>
                        <a
                          className="dropdown-item"
                          style={{
                            fontFamily: '"Libre Baskerville", serif',
                            fontWeight: "bold",
                          }}
                          href="/middlescreen4"
                        >
                          Abhyudaya
                        </a>
                      </li>
                      <li>
                        <a
                          className="dropdown-item"
                          style={{
                            fontFamily: '"Libre Baskerville", serif',
                            fontWeight: "bold",
                          }}
                          href="/middlescreen3"
                        >
                          KYE BYE
                        </a>
                      </li>
                      <li>
                        <a
                          className="dropdown-item"
                          href="/middlescreen5"
                          style={{
                            fontFamily: '"Libre Baskerville", serif',
                            fontWeight: "bold",
                          }}
                        >
                          Business Wisdom
                        </a>
                      </li>
                      <li>
                        <a
                          className="dropdown-item"
                          style={{
                            fontFamily: '"Libre Baskerville", serif',
                            fontWeight: "bold",
                          }}
                          href="/paymentplans"
                        >
                          Plans for Premium Quiz
                        </a>
                      </li>
                      {currentUser1.paid ? (
                        <li>
                          <a
                            className="dropdown-item"
                            style={{
                              fontFamily: '"Libre Baskerville", serif',
                              fontWeight: "bold",
                            }}
                            href="/dashboard"
                          >
                            Dashboard
                          </a>
                        </li>
                      ) : (
                        <></>
                      )}
                      {currentUser1.isAdmin ||
                      currentUser1.isSubAdmin ||
                      currentUser1.isSubAdminTwo ? (
                        <li>
                          <a
                            className="dropdown-item"
                            style={{
                              fontFamily: '"Libre Baskerville", serif',
                              fontWeight: "bold",
                            }}
                            href="/adminpanel"
                          >
                            Admin Panel
                          </a>
                        </li>
                      ) : null}
                      <li>
                        <a
                          className="dropdown-item"
                          href="#"
                          style={{
                            fontFamily: '"Libre Baskerville", serif',
                            fontWeight: "bold",
                          }}
                          onClick={() => {
                            dispatch(logoutUser(currentUser1._id));
                          }}
                        >
                          Logout
                        </a>
                      </li>
                    </ul>
                  </div>
                ) : isOnFirstScreen ? (
                  <>
                    <div className="dropdown">
                      <a
                        className="dropdown-toggle nav-link btn btn-primary"
                        href="#"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        style={{
                          ...buttonShadowStyle,
                          fontFamily: "'Georgia Ref', Georgia, serif",
                          //fontWeight: "bold",
                          fontSize: "15px",
                          textAlign: "center",
                          marginRight: isMobile ? "" : "100px",

                          // paddingLeft: isMobile ? "20px" : "100px",
                          // color: "red",
                        }}
                      >
                        E x p l o r e
                      </a>

                      <ul className="dropdown-menu">
                        <li>
                          <a
                            className="dropdown-item"
                            href="/middlescreen2"
                            style={{
                              fontFamily: '"Libre Baskerville", serif',
                              fontWeight: "bold",
                            }}
                            onClick={(e) =>
                              handleClassRestrictedNavigation(
                                e,
                                "/middlescreen2",
                                "/udbodh"
                              )
                            }
                          >
                            Udbodh
                          </a>
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/middlescreen"
                            style={{
                              fontFamily: '"Libre Baskerville", serif',
                              fontWeight: "bold",
                            }}
                            onClick={(e) =>
                              handleClassRestrictedNavigation(
                                e,
                                "/middlescreen",
                                "/bauddhadakshata"
                              )
                            }
                          >
                            Bauddha Dakshata
                          </a>
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            style={{
                              fontFamily: '"Libre Baskerville", serif',
                              fontWeight: "bold",
                            }}
                            href="/middlescreen4"
                          >
                            Abhyudaya
                          </a>
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/middlescreen3"
                            style={{
                              fontFamily: '"Libre Baskerville", serif',
                              fontWeight: "bold",
                            }}
                          >
                            KYE BYE
                          </a>
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/middlescreen5"
                            style={{
                              fontFamily: '"Libre Baskerville", serif',
                              fontWeight: "bold",
                            }}
                          >
                            Business Wisdom
                          </a>
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/paymentplans"
                            style={{
                              fontFamily: '"Libre Baskerville", serif',
                              fontWeight: "bold",
                            }}
                          >
                            Plans for Premium Quiz
                          </a>
                        </li>
                        <li>
                          {currentUser1 ? (
                            <a
                              className="dropdown-item"
                              href="#"
                              style={{
                                fontFamily: '"Libre Baskerville", serif',
                                fontWeight: "bold",
                              }}
                              onClick={() => {
                                dispatch(logoutUser(currentUser1._id));
                                // Optionally redirect to homepage or login page here after logout
                              }}
                            >
                              Logout
                            </a>
                          ) : (
                            <a
                              className="dropdown-item"
                              href="/login"
                              style={{
                                fontFamily: '"Libre Baskerville", serif',
                                fontWeight: "bold",
                              }}
                            >
                              Login
                            </a>
                          )}
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/register"
                            style={{
                              fontFamily: '"Libre Baskerville", serif',
                              fontWeight: "bold",
                            }}
                          >
                            Register
                          </a>
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/tutorial"
                            style={{
                              fontFamily: '"Libre Baskerville", serif',
                              fontWeight: "bold",
                            }}
                          >
                            Tutorial for Registration
                          </a>
                        </li>
                      </ul>
                    </div>
                  </>
                ) : (
                  !isOnLoginScreen &&
                  !isOnRegisterScreen && (
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        href="/login"
                        style={{
                          fontFamily: '"Libre Baskerville", serif',
                          fontWeight: "bold",
                        }}
                      >
                        Login
                      </a>
                    </li>
                  )
                )}
              </ul>
            </div>
          </nav>
          {/* Padding to offset the fixed navbar height */}
          <div
            style={{ paddingTop: isOnFirstScreen ? "0.001px" : "70px" }}
          ></div>
        </>
      )}

      {/* Modal for selecting class */}
      <Modal show={showClassModal} onHide={handleModalClose}>
        <Modal.Header
          closeButton
          style={{ backgroundColor: "black", color: "white" }}
        >
          <Modal.Title
            style={{
              fontFamily: '"Libre Baskerville", serif',
              fontWeight: "bold",
            }}
          >
            Select Your Class
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ backgroundColor: "black", color: "white" }}>
          <div
            className="form-group"
            style={{
              fontFamily: '"Libre Baskerville", serif',
              fontWeight: "bold",
            }}
          >
            <label>
              Udbodh and Bauddha Dakshata are only available for class 1 to 12.
              Select one of the class from the drop-down list below :
            </label>
            <br></br>
            <select
              className="form-control mt-2"
              value={selectedClass}
              onChange={handleClassChange}
            >
              <option value="">Select Class</option>
              {classes
                .filter((cls) => cls.cls !== "Already passed 12th") // Exclude "Already passed 12th"
                .sort((a, b) => {
                  const aIsNumeric = /^\d+$/.test(a.cls); // Check if class is numeric
                  const bIsNumeric = /^\d+$/.test(b.cls);

                  if (aIsNumeric && bIsNumeric) {
                    return parseInt(a.cls) - parseInt(b.cls); // Numerical sort
                  } else if (aIsNumeric) {
                    return -1; // Numbers before non-numeric classes
                  } else if (bIsNumeric) {
                    return 1; // Non-numeric classes after numbers
                  } else {
                    return a.cls.localeCompare(b.cls); // Alphabetical sort for non-numeric classes
                  }
                })
                .map((cls) => (
                  <option key={cls._id} value={cls.cls}>
                    {cls.cls}
                  </option>
                ))}
            </select>
          </div>
        </Modal.Body>
        <Modal.Footer style={{ backgroundColor: "black", color: "white" }}>
          <button
            className="btn btn-secondary"
            onClick={handleModalClose}
            style={{
              fontFamily: '"Libre Baskerville", serif',
              fontWeight: "bold",
            }}
          >
            Cancel
          </button>
          <button
            className="btn btn-primary"
            onClick={handleModalConfirm}
            style={{
              fontFamily: '"Libre Baskerville", serif',
              fontWeight: "bold",
            }}
          >
            Confirm
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Navbar;
