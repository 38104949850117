// import React, { useState, useEffect } from "react";
// import toast from "react-hot-toast";
// import Modal from "react-bootstrap/Modal";
// import { Button } from "react-bootstrap";
// import { useSelector } from "react-redux";

// const Purchasescreen = () => {
//   const [selectedDurations, setSelectedDurations] = useState(["", "", "", ""]);
//   const [selectedDivisions, setSelectedDivisions] = useState(["", "", "", ""]);
//   const [totalPrice, setTotalPrice] = useState(0);
//   const [selectedPlanId, setSelectedPlanId] = useState(null);
//   const [selectedTotalCount, setSelectedTotalCount] = useState(null);
//   const [showReferralModal, setShowReferralModal] = useState(false);
//   const [referralCodeVerified, setReferralCodeVerified] = useState(false);
//   const [verifyingReferral, setVerifyingReferral] = useState(false);
//   const [referralInput, setReferralInput] = useState("");
//   const [loadingPlan, setLoadingPlan] = useState(null);

//   const [showModal, setShowModal] = useState(false);
//   const [referralCode, setReferralCode] = useState(null);
//   const [hasReferralCode, setHasReferralCode] = useState(null); // Track Yes/No selection

//   const userstate = useSelector((state) => state.loginUserReducer);
//   const { currentUser1 } = userstate;

//   const durations = ["Monthly", "Quarterly", "Half Yearly", "Yearly"];

//   const plans = {
//     Udbodh: { Monthly: 300 },
//     "Bauddha Dakshata": { Monthly: 500 },
//     "Udbodh + Bauddha Dakshata": { Monthly: 700 },
//     "Bauddha Dakshata + Udbodh": { Monthly: 700 },
//     "Udbodh + Kye Bye": { Monthly: 700 },
//     "Udbodh + Abhyudaya": { Monthly: 1200 },

//     // Add other combinations here...

//     Udbodh: {
//       Monthly: 300,
//       Quarterly: 878,
//       "Half Yearly": 1710,
//       Yearly: 3240,
//     },
//     "Bauddha Dakshata": {
//       Monthly: 500,
//       Quarterly: 1463,
//       "Half Yearly": 2850,
//       Yearly: 5400,
//     },
//     "Kye Bye": {
//       Monthly: 500,
//       Quarterly: 1463,
//       "Half Yearly": 2850,
//       Yearly: 5400,
//     },
//     Abhyudaya: {
//       Monthly: 1000, //process.env.REACT_APP_PLAN_ID_ABHYUDAYA_MONTHLY,
//       Quarterly: 2925, //process.env.REACT_APP_PLAN_ID_ABHYUDAYA_QUARTERLY,
//       "Half Yearly": 5700, //process.env.REACT_APP_PLAN_ID_ABHYUDAYA_HALFYEARLY,
//       Yearly: 10800, //process.env.REACT_APP_PLAN_ID_ABHYUDAYA_ANNUALLY,
//     },
//     "Udbodh + Bauddha Dakshata": {
//       Monthly: 700, //process.env.REACT_APP_PLAN_ID_COMBO_ONE_MONTHLY,
//       Quarterly: 2048, //process.env.REACT_APP_PLAN_ID_COMBO_ONE_QUARTERLY,
//       "Half Yearly": 3990, //process.env.REACT_APP_PLAN_ID_COMBO_ONE_HALFYEARLY,
//       Yearly: 7560, //process.env.REACT_APP_PLAN_ID_COMBO_ONE_ANNUALLY,
//     },
//     "Bauddha Dakshata + Udbodh": {
//       Monthly: 700, //process.env.REACT_APP_PLAN_ID_COMBO_ONE_MONTHLY,
//       Quarterly: 2048, //process.env.REACT_APP_PLAN_ID_COMBO_ONE_QUARTERLY,
//       "Half Yearly": 3990, //process.env.REACT_APP_PLAN_ID_COMBO_ONE_HALFYEARLY,
//       Yearly: 7560, //process.env.REACT_APP_PLAN_ID_COMBO_ONE_ANNUALLY,
//     },
//     "Udbodh + Kye Bye": {
//       Monthly: 700, //process.env.REACT_APP_PLAN_ID_COMBO_TWO_MONTHLY,
//       Quarterly: 2048, //process.env.REACT_APP_PLAN_ID_COMBO_TWO_QUARTERLY,
//       "Half Yearly": 3990, //process.env.REACT_APP_PLAN_ID_COMBO_TWO_HALFYEARLY,
//       Yearly: 7560, //process.env.REACT_APP_PLAN_ID_COMBO_TWO_ANNUALLY,
//     },
//     "Kye Bye + Udbodh": {
//       Monthly: 700, //process.env.REACT_APP_PLAN_ID_COMBO_TWO_MONTHLY,
//       Quarterly: 2048, //process.env.REACT_APP_PLAN_ID_COMBO_TWO_QUARTERLY,
//       "Half Yearly": 3990, //process.env.REACT_APP_PLAN_ID_COMBO_TWO_HALFYEARLY,
//       Yearly: 7560, //process.env.REACT_APP_PLAN_ID_COMBO_TWO_ANNUALLY,
//     },
//     "Udbodh + Abhyudaya": {
//       Monthly: 1200, //process.env.REACT_APP_PLAN_ID_COMBO_THREE_MONTHLY,
//       Quarterly: 3510, //process.env.REACT_APP_PLAN_ID_COMBO_THREE_QUARTERLY,
//       "Half Yearly": 6840, //process.env.REACT_APP_PLAN_ID_COMBO_THREE_HALFYEARLY,
//       Yearly: 12960, //process.env.REACT_APP_PLAN_ID_COMBO_THREE_ANNUALLY,
//     },
//     "Abhyudaya + Udbodh": {
//       Monthly: 1200, //process.env.REACT_APP_PLAN_ID_COMBO_THREE_MONTHLY,
//       Quarterly: 3510, //process.env.REACT_APP_PLAN_ID_COMBO_THREE_QUARTERLY,
//       "Half Yearly": 6840, //process.env.REACT_APP_PLAN_ID_COMBO_THREE_HALFYEARLY,
//       Yearly: 12960, //process.env.REACT_APP_PLAN_ID_COMBO_THREE_ANNUALLY,
//     },
//     "Bauddha Dakshata + Kye Bye": {
//       Monthly: 900, //process.env.REACT_APP_PLAN_ID_COMBO_FOUR_MONTHLY,
//       Quarterly: 2633, //process.env.REACT_APP_PLAN_ID_COMBO_FOUR_QUARTERLY,
//       "Half Yearly": 5130, //process.env.REACT_APP_PLAN_ID_COMBO_FOUR_HALFYEARLY,
//       Yearly: 9720, //process.env.REACT_APP_PLAN_ID_COMBO_FOUR_ANNUALLY,
//     },
//     "Kye Bye + Bauddha Dakshata": {
//       Monthly: 900, //process.env.REACT_APP_PLAN_ID_COMBO_FOUR_MONTHLY,
//       Quarterly: 2633, //process.env.REACT_APP_PLAN_ID_COMBO_FOUR_QUARTERLY,
//       "Half Yearly": 5130, //process.env.REACT_APP_PLAN_ID_COMBO_FOUR_HALFYEARLY,
//       Yearly: 9720, //process.env.REACT_APP_PLAN_ID_COMBO_FOUR_ANNUALLY,
//     },
//     "Bauddha Dakshata + Abhyudaya": {
//       Monthly: 1400, //process.env.REACT_APP_PLAN_ID_COMBO_FIVE_MONTHLY,
//       Quarterly: 4095, //process.env.REACT_APP_PLAN_ID_COMBO_FIVE_QUARTERLY,
//       "Half Yearly": 7980, //process.env.REACT_APP_PLAN_ID_COMBO_FIVE_HALFYEARLY,
//       Yearly: 15120, //process.env.REACT_APP_PLAN_ID_COMBO_FIVE_ANNUALLY,
//     },
//     "Abhyudaya + Bauddha Dakshata": {
//       Monthly: 1400, //process.env.REACT_APP_PLAN_ID_COMBO_FIVE_MONTHLY,
//       Quarterly: 4095, //process.env.REACT_APP_PLAN_ID_COMBO_FIVE_QUARTERLY,
//       "Half Yearly": 7980, //process.env.REACT_APP_PLAN_ID_COMBO_FIVE_HALFYEARLY,
//       Yearly: 15120, //process.env.REACT_APP_PLAN_ID_COMBO_FIVE_ANNUALLY,
//     },
//     "Kye Bye + Abhyudaya": {
//       Monthly: 1400, //process.env.REACT_APP_PLAN_ID_COMBO_SIX_MONTHLY,
//       Quarterly: 4095, //process.env.REACT_APP_PLAN_ID_COMBO_SIX_QUARTERLY,
//       "Half Yearly": 7980, //process.env.REACT_APP_PLAN_ID_COMBO_SIX_HALFYEARLY,
//       Yearly: 15120, //process.env.REACT_APP_PLAN_ID_COMBO_SIX_ANNUALLY,
//     },
//     "Abhyudaya + Kye Bye": {
//       Monthly: 1400, //process.env.REACT_APP_PLAN_ID_COMBO_SIX_MONTHLY,
//       Quarterly: 4095, //process.env.REACT_APP_PLAN_ID_COMBO_SIX_QUARTERLY,
//       "Half Yearly": 7980, //process.env.REACT_APP_PLAN_ID_COMBO_SIX_HALFYEARLY,
//       Yearly: 15120, //process.env.REACT_APP_PLAN_ID_COMBO_SIX_ANNUALLY,
//     },
//     "Udbodh + Bauddha Dakshata + Kye Bye": {
//       Monthly: 1150, //process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_MONTHLY,
//       Quarterly: 3364, //process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_QUARTERLY,
//       "Half Yearly": 6555, //process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_HALFYEARLY,
//       Yearly: 12420, //process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_ANNUALLY,
//     },
//     "Udbodh + Kye Bye + Bauddha Dakshata": {
//       Monthly: 1150, //process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_MONTHLY,
//       Quarterly: 3364, //process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_QUARTERLY,
//       "Half Yearly": 6555, //process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_HALFYEARLY,
//       Yearly: 12420, //process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_ANNUALLY,
//     },
//     "Bauddha Dakshata + Kye Bye + Udbodh": {
//       Monthly: 1150, //process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_MONTHLY,
//       Quarterly: 3364, //process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_QUARTERLY,
//       "Half Yearly": 6555, //process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_HALFYEARLY,
//       Yearly: 12420, //process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_ANNUALLY,
//     },
//     "Bauddha Dakshata + Udbodh + Kye Bye": {
//       Monthly: 1150, //process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_MONTHLY,
//       Quarterly: 3364, //process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_QUARTERLY,
//       "Half Yearly": 6555, //process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_HALFYEARLY,
//       Yearly: 12420, //process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_ANNUALLY,
//     },
//     "Kye Bye + Udbodh + Bauddha Dakshata": {
//       Monthly: 1150, //process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_MONTHLY,
//       Quarterly: 3364, //process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_QUARTERLY,
//       "Half Yearly": 6555, //process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_HALFYEARLY,
//       Yearly: 12420, //process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_ANNUALLY,
//     },
//     "Kye Bye + Bauddha Dakshata + Udbodh": {
//       Monthly: 1150, //process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_MONTHLY,
//       Quarterly: 3364, //process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_QUARTERLY,
//       "Half Yearly": 6555, //process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_HALFYEARLY,
//       Yearly: 12420, //process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_ANNUALLY,
//     },
//     "Udbodh + Bauddha Dakshata + Kye Bye + Abhyudaya": {
//       Monthly: 2100, //process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: 6143, //process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": 11970, //process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: 22680, //process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Udbodh + Bauddha Dakshata + Abhyudaya + Kye Bye": {
//       Monthly: 2100, //process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: 6143, //process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": 11970, //process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: 22680, //process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Udbodh + Kye Bye + Bauddha Dakshata + Abhyudaya": {
//       Monthly: 2100, //process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: 6143, //process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": 11970, //process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: 22680, //process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Udbodh + Kye Bye + Abhyudaya + Bauddha Dakshata": {
//       Monthly: 2100, //process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: 6143, //process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": 11970, //process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: 22680, //process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Udbodh + Abhyudaya + Bauddha Dakshata + Kye Bye": {
//       Monthly: 2100, //process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: 6143, //process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": 11970, //process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: 22680, //process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Udbodh + Abhyudaya + Kye Bye + Bauddha Dakshata": {
//       Monthly: 2100, //process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: 6143, //process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": 11970, //process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: 22680, //process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Bauddha Dakshata + Udbodh + Kye Bye + Abhyudaya": {
//       Monthly: 2100, //process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: 6143, //process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": 11970, //process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: 22680, //process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Bauddha Dakshata + Udbodh + Abhyudaya + Kye Bye": {
//       Monthly: 2100, //process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: 6143, //process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": 11970, //process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: 22680, //process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Bauddha Dakshata + Kye Bye + Udbodh + Abhyudaya": {
//       Monthly: 2100, //process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: 6143, //process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": 11970, //process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: 22680, //process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Bauddha Dakshata + Kye Bye + Abhyudaya + Udbodh": {
//       Monthly: 2100, //process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: 6143, //process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": 11970, //process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: 22680, //process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Bauddha Dakshata + Abhyudaya + Udbodh + Kye Bye": {
//       Monthly: 2100, //process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: 6143, //process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": 11970, //process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: 22680, //process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Bauddha Dakshata + Abhyudaya + Kye Bye + Udbodh": {
//       Monthly: 2100, //process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: 6143, //process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": 11970, //process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: 22680, //process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Kye Bye + Udbodh + Bauddha Dakshata + Abhyudaya": {
//       Monthly: 2100, //process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: 6143, //process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": 11970, //process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: 22680, //process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Kye Bye + Udbodh + Abhyudaya + Bauddha Dakshata": {
//       Monthly: 2100, //process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: 6143, //process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": 11970, //process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: 22680, //process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Kye Bye + Bauddha Dakshata + Udbodh + Abhyudaya": {
//       Monthly: 2100, //process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: 6143, //process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": 11970, //process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: 22680, //process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Kye Bye + Bauddha Dakshata + Abhyudaya + Udbodh": {
//       Monthly: 2100, //process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: 6143, //process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": 11970, //process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: 22680, //process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Kye Bye + Abhyudaya + Udbodh + Bauddha Dakshata": {
//       Monthly: 2100, //process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: 6143, //process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": 11970, //process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: 22680, //process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Kye Bye + Abhyudaya + Bauddha Dakshata + Udbodh": {
//       Monthly: 2100, //process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: 6143, //process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": 11970, //process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: 22680, //process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Abhyudaya + Udbodh + Bauddha Dakshata + Kye Bye": {
//       Monthly: 2100, //process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: 6143, //process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": 11970, //process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: 22680, //process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Abhyudaya + Udbodh + Kye Bye + Bauddha Dakshata": {
//       Monthly: 2100, //process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: 6143, //process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": 11970, //process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: 22680, //process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Abhyudaya + Bauddha Dakshata + Udbodh + Kye Bye": {
//       Monthly: 2100, //process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: 6143, //process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": 11970, //process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: 22680, //process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Abhyudaya + Bauddha Dakshata + Kye Bye + Udbodh": {
//       Monthly: 2100, //process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: 6143, //process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": 11970, //process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: 22680, //process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Abhyudaya + Kye Bye + Udbodh + Bauddha Dakshata": {
//       Monthly: 2100, //process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: 6143, //process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": 11970, //process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: 22680, //process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Abhyudaya + Kye Bye + Bauddha Dakshata + Udbodh": {
//       Monthly: 2100, //process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: 6143, //process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": 11970, //process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: 22680, //process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//   };

//   const planIds = {
//     Udbodh: {
//       Monthly: process.env.REACT_APP_PLAN_ID_UDBODH_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_UDBODH_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_UDBODH_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_UDBODH_ANNUALLY,
//     },
//     "Bauddha Dakshata": {
//       Monthly: process.env.REACT_APP_PLAN_ID_BD_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_BD_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_BD_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_BD_ANNUALLY,
//     },
//     "Kye Bye": {
//       Monthly: process.env.REACT_APP_PLAN_ID_KYEBYE_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_KYEBYE_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_KYEBYE_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_KYEBYE_ANNUALLY,
//     },
//     Abhyudaya: {
//       Monthly: process.env.REACT_APP_PLAN_ID_ABHYUDAYA_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_ABHYUDAYA_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_ABHYUDAYA_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_ABHYUDAYA_ANNUALLY,
//     },
//     "Udbodh + Bauddha Dakshata": {
//       Monthly: process.env.REACT_APP_PLAN_ID_COMBO_ONE_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_COMBO_ONE_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_COMBO_ONE_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_COMBO_ONE_ANNUALLY,
//     },
//     "Bauddha Dakshata + Udbodh": {
//       Monthly: process.env.REACT_APP_PLAN_ID_COMBO_ONE_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_COMBO_ONE_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_COMBO_ONE_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_COMBO_ONE_ANNUALLY,
//     },
//     "Udbodh + Kye Bye": {
//       Monthly: process.env.REACT_APP_PLAN_ID_COMBO_TWO_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_COMBO_TWO_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_COMBO_TWO_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_COMBO_TWO_ANNUALLY,
//     },
//     "Kye Bye + Udbodh": {
//       Monthly: process.env.REACT_APP_PLAN_ID_COMBO_TWO_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_COMBO_TWO_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_COMBO_TWO_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_COMBO_TWO_ANNUALLY,
//     },
//     "Udbodh + Abhyudaya": {
//       Monthly: process.env.REACT_APP_PLAN_ID_COMBO_THREE_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_COMBO_THREE_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_COMBO_THREE_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_COMBO_THREE_ANNUALLY,
//     },
//     "Abhyudaya + Udbodh": {
//       Monthly: process.env.REACT_APP_PLAN_ID_COMBO_THREE_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_COMBO_THREE_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_COMBO_THREE_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_COMBO_THREE_ANNUALLY,
//     },
//     "Bauddha Dakshata + Kye Bye": {
//       Monthly: process.env.REACT_APP_PLAN_ID_COMBO_FOUR_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_COMBO_FOUR_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_COMBO_FOUR_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_COMBO_FOUR_ANNUALLY,
//     },
//     "Kye Bye + Bauddha Dakshata": {
//       Monthly: process.env.REACT_APP_PLAN_ID_COMBO_FOUR_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_COMBO_FOUR_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_COMBO_FOUR_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_COMBO_FOUR_ANNUALLY,
//     },
//     "Bauddha Dakshata + Abhyudaya": {
//       Monthly: process.env.REACT_APP_PLAN_ID_COMBO_FIVE_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_COMBO_FIVE_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_COMBO_FIVE_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_COMBO_FIVE_ANNUALLY,
//     },
//     "Abhyudaya + Bauddha Dakshata": {
//       Monthly: process.env.REACT_APP_PLAN_ID_COMBO_FIVE_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_COMBO_FIVE_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_COMBO_FIVE_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_COMBO_FIVE_ANNUALLY,
//     },
//     "Kye Bye + Abhyudaya": {
//       Monthly: process.env.REACT_APP_PLAN_ID_COMBO_SIX_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_COMBO_SIX_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_COMBO_SIX_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_COMBO_SIX_ANNUALLY,
//     },
//     "Abhyudaya + Kye Bye": {
//       Monthly: process.env.REACT_APP_PLAN_ID_COMBO_SIX_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_COMBO_SIX_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_COMBO_SIX_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_COMBO_SIX_ANNUALLY,
//     },
//     "Udbodh + Bauddha Dakshata + Kye Bye": {
//       Monthly: process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_ANNUALLY,
//     },
//     "Udbodh + Kye Bye + Bauddha Dakshata": {
//       Monthly: process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_ANNUALLY,
//     },
//     "Bauddha Dakshata + Kye Bye + Udbodh": {
//       Monthly: process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_ANNUALLY,
//     },
//     "Bauddha Dakshata + Udbodh + Kye Bye": {
//       Monthly: process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_ANNUALLY,
//     },
//     "Kye Bye + Udbodh + Bauddha Dakshata": {
//       Monthly: process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_ANNUALLY,
//     },
//     "Kye Bye + Bauddha Dakshata + Udbodh": {
//       Monthly: process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_ANNUALLY,
//     },
//     "Udbodh + Bauddha Dakshata + Kye Bye + Abhyudaya": {
//       Monthly: process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Udbodh + Bauddha Dakshata + Abhyudaya + Kye Bye": {
//       Monthly: process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Udbodh + Kye Bye + Bauddha Dakshata + Abhyudaya": {
//       Monthly: process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Udbodh + Kye Bye + Abhyudaya + Bauddha Dakshata": {
//       Monthly: process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Udbodh + Abhyudaya + Bauddha Dakshata + Kye Bye": {
//       Monthly: process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Udbodh + Abhyudaya + Kye Bye + Bauddha Dakshata": {
//       Monthly: process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Bauddha Dakshata + Udbodh + Kye Bye + Abhyudaya": {
//       Monthly: process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Bauddha Dakshata + Udbodh + Abhyudaya + Kye Bye": {
//       Monthly: process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Bauddha Dakshata + Kye Bye + Udbodh + Abhyudaya": {
//       Monthly: process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Bauddha Dakshata + Kye Bye + Abhyudaya + Udbodh": {
//       Monthly: process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Bauddha Dakshata + Abhyudaya + Udbodh + Kye Bye": {
//       Monthly: process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Bauddha Dakshata + Abhyudaya + Kye Bye + Udbodh": {
//       Monthly: process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Kye Bye + Udbodh + Bauddha Dakshata + Abhyudaya": {
//       Monthly: process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Kye Bye + Udbodh + Abhyudaya + Bauddha Dakshata": {
//       Monthly: process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Kye Bye + Bauddha Dakshata + Udbodh + Abhyudaya": {
//       Monthly: process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Kye Bye + Bauddha Dakshata + Abhyudaya + Udbodh": {
//       Monthly: process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Kye Bye + Abhyudaya + Udbodh + Bauddha Dakshata": {
//       Monthly: process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Kye Bye + Abhyudaya + Bauddha Dakshata + Udbodh": {
//       Monthly: process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Abhyudaya + Udbodh + Bauddha Dakshata + Kye Bye": {
//       Monthly: process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Abhyudaya + Udbodh + Kye Bye + Bauddha Dakshata": {
//       Monthly: process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Abhyudaya + Bauddha Dakshata + Udbodh + Kye Bye": {
//       Monthly: process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Abhyudaya + Bauddha Dakshata + Kye Bye + Udbodh": {
//       Monthly: process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Abhyudaya + Kye Bye + Udbodh + Bauddha Dakshata": {
//       Monthly: process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Abhyudaya + Kye Bye + Bauddha Dakshata + Udbodh": {
//       Monthly: process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },

//     // Add other combinations here...
//   };

//   const totalCounts = { Monthly: 96 };

//   // Handle modal close event
//   const handleCloseModal = () => {
//     setShowModal(false);

//     // Trigger page reload only when modal is closed
//     window.location.reload();
//   };

//   const handleDivisionChange = (index, value) => {
//     const divisions = [...selectedDivisions];
//     divisions[index] = value;
//     setSelectedDivisions(divisions);
//     calculateTotalPrice(divisions, selectedDurations);
//   };

//   const handleDurationChange = (index, value) => {
//     const durationsCopy = [...selectedDurations];
//     durationsCopy[index] = value;

//     // Set all following duration selections to match the first row's selection
//     if (index === 0) {
//       for (let i = 1; i < durationsCopy.length; i++) {
//         durationsCopy[i] = value;
//       }
//     }

//     setSelectedDurations(durationsCopy);
//     calculateTotalPrice(selectedDivisions, durationsCopy);
//   };

//   const calculateTotalPrice = (divisions, durations) => {
//     let price = 0;
//     let planKey = divisions.filter((d) => d).join(" + "); // Join only non-empty values

//     if (planKey && plans[planKey] && durations[0]) {
//       price = plans[planKey][durations[0]] || 0;
//     }

//     setTotalPrice(price);
//     setSelectedPlanId(planIds[planKey]?.[durations[0]] || null);
//     setSelectedTotalCount(totalCounts[durations[0]] || null);
//   };

//   const handleProceedToPayment = () => {
//     if (!selectedPlanId || !selectedTotalCount) {
//       toast.error("Please complete your selections.");
//       return;
//     }

//     setShowReferralModal(true);
//     setReferralCodeVerified(false);
//   };

//   const verifyReferralCode = async () => {
//     setVerifyingReferral(true);
//     try {
//       const res = await fetch(`/api/users/verifyReferral`, {
//         method: "POST",
//         headers: { "Content-Type": "application/json" },
//         body: JSON.stringify({ referralCode: referralInput.trim() }),
//       });
//       const data = await res.json();

//       if (data.success) {
//         setReferralCodeVerified(true);
//         toast.success("Referral code verified successfully!");
//       } else {
//         toast.error("Invalid referral code.");
//       }
//     } catch (error) {
//       toast.error("Failed to verify referral code.");
//     } finally {
//       setVerifyingReferral(false);
//     }
//   };

//   // handleSubscription Function
//   const handleSubscription = async (plan_id, total_count) => {
//     setLoadingPlan(plan_id); // Set loading for the clicked plan
//     try {
//       const res = await fetch(`/api/payment/subscription`, {
//         method: "POST",
//         headers: {
//           "content-type": "application/json",
//         },
//         body: JSON.stringify({
//           plan_id,
//           userId: currentUser1._id, // Pass user ID to backend
//           total_count,
//           referralCode: referralInput.trim(), // Include referral code in request
//         }),
//       });

//       const data = await res.json();
//       console.log(data);
//       handlePaymentVerify(data.data);
//       // setReferralCode(data.referralCode); // Set referral code if returned
//       // setShowModal(true);
//       setLoadingPlan(null); // Reset loading state in case of error
//     } catch (error) {
//       console.log(error);
//       setLoadingPlan(null); // Reset loading state in case of error
//     }
//   };

//   // handlePaymentVerify Function
//   const handlePaymentVerify = async (data) => {
//     const options = {
//       key: process.env.REACT_APP_RAZORPAY_KEY_ID,
//       subscription_id: data.id,
//       name: "Gyankosha",
//       description: "Payment for Quiz Subscription",
//       handler: async (response) => {
//         console.log("response", response);
//         try {
//           const res = await fetch(`/api/payment/verify`, {
//             method: "POST",
//             headers: {
//               "content-type": "application/json",
//             },
//             body: JSON.stringify({
//               razorpay_subscription_id: response.razorpay_subscription_id,
//               razorpay_payment_id: response.razorpay_payment_id,
//               razorpay_signature: response.razorpay_signature,
//               userId: currentUser1._id, // Pass user ID for verification
//             }),
//           });

//           const verifyData = await res.json();

//           if (verifyData.message) {
//             toast.success(verifyData.message);
//             console.log("verifyData.referralCode : ", verifyData.referralCode);
//             if (verifyData.referralCode) {
//               setReferralCode(verifyData.referralCode);
//               setShowModal(true);
//             }
//             // Reload the page after 2 seconds
//             // setTimeout(() => {
//             //   window.location.reload();
//             // }, 2000);
//           }
//         } catch (error) {
//           console.log(error);
//         } finally {
//           setLoadingPlan(null); // Reset loading state in case of error
//         }
//       },
//       theme: {
//         color: "#5f63b8",
//       },
//     };
//     const rzp1 = new window.Razorpay(options);
//     rzp1.open();
//   };

//   return (
//     <div
//       className="pricing-table"
//       style={{
//         fontFamily: '"Libre Baskerville", serif',
//         margin: "100px 50px 200px 50px",
//       }}
//     >
//       <h3>Select Divisions and Durations:</h3>
//       <table
//         className="table table-bordered"
//         style={{ borderColor: "#343a40", overflow: "visible" }}
//       >
//         <thead className="table-dark">
//           <tr>
//             <th></th>
//             <th>Select Division</th>
//             <th>Select Duration</th>
//           </tr>
//         </thead>
//         <tbody>
//           {[0, 1, 2, 3].map((rowIndex) => (
//             <tr key={rowIndex}>
//               <td>Choose your vertical {rowIndex + 1} :</td>
//               <td>
//                 <select
//                   className="form-control"
//                   value={selectedDivisions[rowIndex]}
//                   onChange={(e) =>
//                     handleDivisionChange(rowIndex, e.target.value)
//                   }
//                 >
//                   <option value="">-- Select Division --</option>
//                   {["Udbodh", "Bauddha Dakshata", "Kye Bye", "Abhyudaya"].map(
//                     (division) => (
//                       <option key={division} value={division}>
//                         {division}
//                       </option>
//                     )
//                   )}
//                 </select>
//               </td>
//               <td>
//                 <select
//                   className="form-control"
//                   value={selectedDurations[rowIndex]}
//                   onChange={(e) =>
//                     handleDurationChange(rowIndex, e.target.value)
//                   }
//                   disabled={rowIndex !== 0 && !selectedDurations[0]}
//                 >
//                   <option value="">-- Select Duration --</option>
//                   {rowIndex === 0
//                     ? durations.map((duration) => (
//                         <option key={duration} value={duration}>
//                           {duration}
//                         </option>
//                       ))
//                     : selectedDurations[0] && (
//                         <option value={selectedDurations[0]}>
//                           {selectedDurations[0]}
//                         </option>
//                       )}
//                 </select>
//               </td>
//             </tr>
//           ))}
//         </tbody>
//       </table>

//       <div
//         style={{
//           display: "flex",
//           alignItems: "center",
//           gap: "15px",
//           marginTop: "10px",
//         }}
//       >
//         <span style={{ fontWeight: "bold" }}>Total Price: ₹{totalPrice}</span>
//         <button className="btn btn-primary" onClick={handleProceedToPayment}>
//           Proceed
//         </button>
//       </div>

//       <Modal show={showModal} onHide={handleCloseModal} centered>
//         <Modal.Header closeButton>
//           <Modal.Title>
//             Gyankosha's Subscription Active now and your Referral Code :{" "}
//             <span style={{ color: "red" }}>{referralCode}</span>
//           </Modal.Title>
//         </Modal.Header>

//         <Modal.Body>
//           <div style={{ fontFamily: "Arial, sans-serif", lineHeight: "1.6" }}>
//             <p>
//               Dear <strong>{currentUser1 ? currentUser1.name : ""}</strong>,
//             </p>
//             <p>
//               Thank you for choosing <strong>Gyankosha</strong> as your trusted
//               education partner. We are thrilled to have you on board! Your
//               subscription plan is now active, giving you access to the premium
//               content and resources.
//             </p>
//             <p>
//               Attached, you will find your unique Referral Code:{" "}
//               <strong style={{ color: "red" }}>{referralCode}</strong>.
//             </p>
//             <p>
//               As a token of appreciation for your support, when a new user
//               subscribes and makes their first payment using your referral code,
//               you become eligible for a <strong>20% cashback</strong> to your
//               account, and the one referred by you becomes eligible for a{" "}
//               <strong>10% cashback</strong> to their account. Please note that
//               the cashback is applicable only against the first payment made by
//               the referred user and is valid for one cycle only.
//             </p>
//             <p>
//               Let’s keep learning, growing, and making education exciting
//               together!
//             </p>
//             <p>Warm regards,</p>
//             <p>
//               <strong>The Gyankosha Team</strong>
//             </p>
//           </div>
//         </Modal.Body>

//         <Modal.Footer>
//           <Button variant="secondary" onClick={handleCloseModal}>
//             Close
//           </Button>
//         </Modal.Footer>
//       </Modal>

//       <Modal
//         show={showReferralModal}
//         onHide={() => {
//           setShowReferralModal(false);
//           setHasReferralCode(null); // Reset the referral code selection
//           setReferralInput(""); // Clear referral input field
//           setReferralCodeVerified(false); // Reset verification status
//           setVerifyingReferral(false); // Reset verifying state
//         }}
//         onExit={() => {
//           setShowReferralModal(false);
//           setHasReferralCode(null); // Reset the referral code selection
//           setReferralInput(""); // Clear referral input field
//           setReferralCodeVerified(false); // Reset verification status
//           setVerifyingReferral(false); // Reset verifying state
//         }}
//         centered
//       >
//         <Modal.Header closeButton>
//           {hasReferralCode === null && (
//             <Modal.Title
//               style={{ fontFamily: "Arial, sans-serif", fontWeight: "bold" }}
//             >
//               Do you have a referral code?
//             </Modal.Title>
//           )}
//         </Modal.Header>
//         <Modal.Body
//           style={{ fontFamily: "Arial, sans-serif", lineHeight: "1.6" }}
//         >
//           <div>
//             {hasReferralCode === null && (
//               <div className="d-flex flex-column align-items-start">
//                 <div className="form-check custom-radio mb-3">
//                   <input
//                     className="form-check-input"
//                     type="radio"
//                     name="referralCodeOption"
//                     id="referralYes"
//                     onChange={() => setHasReferralCode(true)}
//                   />
//                   <label
//                     className="form-check-label ms-2"
//                     htmlFor="referralYes"
//                   >
//                     Yes, I have a referral code
//                   </label>
//                 </div>
//                 <div className="form-check custom-radio">
//                   <input
//                     className="form-check-input"
//                     type="radio"
//                     name="referralCodeOption"
//                     id="referralNo"
//                     onChange={() => setHasReferralCode(false)}
//                   />
//                   <label className="form-check-label ms-2" htmlFor="referralNo">
//                     No, I don't have a referral code
//                   </label>
//                 </div>
//               </div>
//             )}
//             {hasReferralCode === true && !referralCodeVerified && (
//               <div>
//                 <label htmlFor="referralInput" style={{ fontWeight: "bold" }}>
//                   Enter your referral code:
//                 </label>
//                 <input
//                   type="text"
//                   className="form-control mt-2"
//                   id="referralInput"
//                   placeholder="Enter referral code here"
//                   value={referralInput}
//                   onChange={(e) => setReferralInput(e.target.value)}
//                   style={{ border: "1px solid #ced4da", borderRadius: "5px" }}
//                 />
//                 <button
//                   className="btn btn-primary mt-3 w-100"
//                   onClick={verifyReferralCode}
//                   disabled={verifyingReferral}
//                   style={{ boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)" }}
//                 >
//                   {verifyingReferral ? "Verifying..." : "Verify Referral Code"}
//                 </button>
//               </div>
//             )}
//             {referralCodeVerified && (
//               <div className="text-center">
//                 <p className="text-success" style={{ fontWeight: "bold" }}>
//                   Referral code verified successfully!
//                 </p>
//                 <button
//                   className="btn btn-success mt-3 w-100"
//                   onClick={() => {
//                     setShowReferralModal(false);
//                     handleSubscription(selectedPlanId, selectedTotalCount);
//                   }}
//                   style={{ boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)" }}
//                 >
//                   Proceed to Payment
//                 </button>
//               </div>
//             )}
//             {hasReferralCode === false && (
//               <div className="text-center">
//                 <p className="text-muted" style={{ fontWeight: "bold" }}>
//                   No referral code? No problem!
//                 </p>
//                 <button
//                   className="btn btn-success mt-3 w-100"
//                   onClick={() => {
//                     setShowReferralModal(false);
//                     handleSubscription(selectedPlanId, selectedTotalCount);
//                   }}
//                   style={{ boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)" }}
//                 >
//                   Proceed to Payment
//                 </button>
//               </div>
//             )}
//           </div>
//         </Modal.Body>
//       </Modal>
//     </div>
//   );
// };

// export default Purchasescreen;
////////////////////////////////////////////////////////////////////////////////////////////////////////
// import React, { useState, useEffect } from "react";
// import toast from "react-hot-toast";
// import Modal from "react-bootstrap/Modal";
// import { Button } from "react-bootstrap";
// import { useSelector } from "react-redux";

// const Purchasescreen = () => {
//   const [selectedDurations, setSelectedDurations] = useState(["", "", "", ""]);
//   const [selectedDivisions, setSelectedDivisions] = useState(["", "", "", ""]);
//   const [selectedClasses, setSelectedClasses] = useState(["", "", "", ""]); // New State for Class
//   const [totalPrice, setTotalPrice] = useState(0);
//   const [selectedPlanId, setSelectedPlanId] = useState(null);
//   const [selectedTotalCount, setSelectedTotalCount] = useState(null);
//   const [showReferralModal, setShowReferralModal] = useState(false);
//   const [referralCodeVerified, setReferralCodeVerified] = useState(false);
//   const [verifyingReferral, setVerifyingReferral] = useState(false);
//   const [referralInput, setReferralInput] = useState("");
//   const [loadingPlan, setLoadingPlan] = useState(null);

//   const [showModal, setShowModal] = useState(false);
//   const [referralCode, setReferralCode] = useState(null);
//   const [hasReferralCode, setHasReferralCode] = useState(null); // Track Yes/No selection

//   const userstate = useSelector((state) => state.loginUserReducer);
//   const { currentUser1 } = userstate;

//   const durations = ["Monthly", "Quarterly", "Half Yearly", "Yearly"];
//   const divisions = ["Udbodh", "Bauddha Dakshata", "Kye Bye", "Abhyudaya"];
//   const classes = [...Array(12).keys()]
//     .map((num) => (num + 1).toString())
//     .concat(["Already passed 12th"]);

//   const plans = {
//     Udbodh: {
//       Monthly: 300,
//       Quarterly: 878,
//       "Half Yearly": 1710,
//       Yearly: 3240,
//     },
//     "Bauddha Dakshata": {
//       Monthly: 500,
//       Quarterly: 1463,
//       "Half Yearly": 2850,
//       Yearly: 5400,
//     },
//     "Kye Bye": {
//       Monthly: 500,
//       Quarterly: 1463,
//       "Half Yearly": 2850,
//       Yearly: 5400,
//     },
//     Abhyudaya: {
//       Monthly: 1000, //process.env.REACT_APP_PLAN_ID_ABHYUDAYA_MONTHLY,
//       Quarterly: 2925, //process.env.REACT_APP_PLAN_ID_ABHYUDAYA_QUARTERLY,
//       "Half Yearly": 5700, //process.env.REACT_APP_PLAN_ID_ABHYUDAYA_HALFYEARLY,
//       Yearly: 10800, //process.env.REACT_APP_PLAN_ID_ABHYUDAYA_ANNUALLY,
//     },
//     "Udbodh + Bauddha Dakshata": {
//       Monthly: 700, //process.env.REACT_APP_PLAN_ID_COMBO_ONE_MONTHLY,
//       Quarterly: 2048, //process.env.REACT_APP_PLAN_ID_COMBO_ONE_QUARTERLY,
//       "Half Yearly": 3990, //process.env.REACT_APP_PLAN_ID_COMBO_ONE_HALFYEARLY,
//       Yearly: 7560, //process.env.REACT_APP_PLAN_ID_COMBO_ONE_ANNUALLY,
//     },
//     "Bauddha Dakshata + Udbodh": {
//       Monthly: 700, //process.env.REACT_APP_PLAN_ID_COMBO_ONE_MONTHLY,
//       Quarterly: 2048, //process.env.REACT_APP_PLAN_ID_COMBO_ONE_QUARTERLY,
//       "Half Yearly": 3990, //process.env.REACT_APP_PLAN_ID_COMBO_ONE_HALFYEARLY,
//       Yearly: 7560, //process.env.REACT_APP_PLAN_ID_COMBO_ONE_ANNUALLY,
//     },
//     "Udbodh + Kye Bye": {
//       Monthly: 700, //process.env.REACT_APP_PLAN_ID_COMBO_TWO_MONTHLY,
//       Quarterly: 2048, //process.env.REACT_APP_PLAN_ID_COMBO_TWO_QUARTERLY,
//       "Half Yearly": 3990, //process.env.REACT_APP_PLAN_ID_COMBO_TWO_HALFYEARLY,
//       Yearly: 7560, //process.env.REACT_APP_PLAN_ID_COMBO_TWO_ANNUALLY,
//     },
//     "Kye Bye + Udbodh": {
//       Monthly: 700, //process.env.REACT_APP_PLAN_ID_COMBO_TWO_MONTHLY,
//       Quarterly: 2048, //process.env.REACT_APP_PLAN_ID_COMBO_TWO_QUARTERLY,
//       "Half Yearly": 3990, //process.env.REACT_APP_PLAN_ID_COMBO_TWO_HALFYEARLY,
//       Yearly: 7560, //process.env.REACT_APP_PLAN_ID_COMBO_TWO_ANNUALLY,
//     },
//     "Udbodh + Abhyudaya": {
//       Monthly: 1200, //process.env.REACT_APP_PLAN_ID_COMBO_THREE_MONTHLY,
//       Quarterly: 3510, //process.env.REACT_APP_PLAN_ID_COMBO_THREE_QUARTERLY,
//       "Half Yearly": 6840, //process.env.REACT_APP_PLAN_ID_COMBO_THREE_HALFYEARLY,
//       Yearly: 12960, //process.env.REACT_APP_PLAN_ID_COMBO_THREE_ANNUALLY,
//     },
//     "Abhyudaya + Udbodh": {
//       Monthly: 1200, //process.env.REACT_APP_PLAN_ID_COMBO_THREE_MONTHLY,
//       Quarterly: 3510, //process.env.REACT_APP_PLAN_ID_COMBO_THREE_QUARTERLY,
//       "Half Yearly": 6840, //process.env.REACT_APP_PLAN_ID_COMBO_THREE_HALFYEARLY,
//       Yearly: 12960, //process.env.REACT_APP_PLAN_ID_COMBO_THREE_ANNUALLY,
//     },
//     "Bauddha Dakshata + Kye Bye": {
//       Monthly: 900, //process.env.REACT_APP_PLAN_ID_COMBO_FOUR_MONTHLY,
//       Quarterly: 2633, //process.env.REACT_APP_PLAN_ID_COMBO_FOUR_QUARTERLY,
//       "Half Yearly": 5130, //process.env.REACT_APP_PLAN_ID_COMBO_FOUR_HALFYEARLY,
//       Yearly: 9720, //process.env.REACT_APP_PLAN_ID_COMBO_FOUR_ANNUALLY,
//     },
//     "Kye Bye + Bauddha Dakshata": {
//       Monthly: 900, //process.env.REACT_APP_PLAN_ID_COMBO_FOUR_MONTHLY,
//       Quarterly: 2633, //process.env.REACT_APP_PLAN_ID_COMBO_FOUR_QUARTERLY,
//       "Half Yearly": 5130, //process.env.REACT_APP_PLAN_ID_COMBO_FOUR_HALFYEARLY,
//       Yearly: 9720, //process.env.REACT_APP_PLAN_ID_COMBO_FOUR_ANNUALLY,
//     },
//     "Bauddha Dakshata + Abhyudaya": {
//       Monthly: 1400, //process.env.REACT_APP_PLAN_ID_COMBO_FIVE_MONTHLY,
//       Quarterly: 4095, //process.env.REACT_APP_PLAN_ID_COMBO_FIVE_QUARTERLY,
//       "Half Yearly": 7980, //process.env.REACT_APP_PLAN_ID_COMBO_FIVE_HALFYEARLY,
//       Yearly: 15120, //process.env.REACT_APP_PLAN_ID_COMBO_FIVE_ANNUALLY,
//     },
//     "Abhyudaya + Bauddha Dakshata": {
//       Monthly: 1400, //process.env.REACT_APP_PLAN_ID_COMBO_FIVE_MONTHLY,
//       Quarterly: 4095, //process.env.REACT_APP_PLAN_ID_COMBO_FIVE_QUARTERLY,
//       "Half Yearly": 7980, //process.env.REACT_APP_PLAN_ID_COMBO_FIVE_HALFYEARLY,
//       Yearly: 15120, //process.env.REACT_APP_PLAN_ID_COMBO_FIVE_ANNUALLY,
//     },
//     "Kye Bye + Abhyudaya": {
//       Monthly: 1400, //process.env.REACT_APP_PLAN_ID_COMBO_SIX_MONTHLY,
//       Quarterly: 4095, //process.env.REACT_APP_PLAN_ID_COMBO_SIX_QUARTERLY,
//       "Half Yearly": 7980, //process.env.REACT_APP_PLAN_ID_COMBO_SIX_HALFYEARLY,
//       Yearly: 15120, //process.env.REACT_APP_PLAN_ID_COMBO_SIX_ANNUALLY,
//     },
//     "Abhyudaya + Kye Bye": {
//       Monthly: 1400, //process.env.REACT_APP_PLAN_ID_COMBO_SIX_MONTHLY,
//       Quarterly: 4095, //process.env.REACT_APP_PLAN_ID_COMBO_SIX_QUARTERLY,
//       "Half Yearly": 7980, //process.env.REACT_APP_PLAN_ID_COMBO_SIX_HALFYEARLY,
//       Yearly: 15120, //process.env.REACT_APP_PLAN_ID_COMBO_SIX_ANNUALLY,
//     },
//     "Udbodh + Bauddha Dakshata + Kye Bye": {
//       Monthly: 1150, //process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_MONTHLY,
//       Quarterly: 3364, //process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_QUARTERLY,
//       "Half Yearly": 6555, //process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_HALFYEARLY,
//       Yearly: 12420, //process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_ANNUALLY,
//     },
//     "Udbodh + Kye Bye + Bauddha Dakshata": {
//       Monthly: 1150, //process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_MONTHLY,
//       Quarterly: 3364, //process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_QUARTERLY,
//       "Half Yearly": 6555, //process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_HALFYEARLY,
//       Yearly: 12420, //process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_ANNUALLY,
//     },
//     "Bauddha Dakshata + Kye Bye + Udbodh": {
//       Monthly: 1150, //process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_MONTHLY,
//       Quarterly: 3364, //process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_QUARTERLY,
//       "Half Yearly": 6555, //process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_HALFYEARLY,
//       Yearly: 12420, //process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_ANNUALLY,
//     },
//     "Bauddha Dakshata + Udbodh + Kye Bye": {
//       Monthly: 1150, //process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_MONTHLY,
//       Quarterly: 3364, //process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_QUARTERLY,
//       "Half Yearly": 6555, //process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_HALFYEARLY,
//       Yearly: 12420, //process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_ANNUALLY,
//     },
//     "Kye Bye + Udbodh + Bauddha Dakshata": {
//       Monthly: 1150, //process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_MONTHLY,
//       Quarterly: 3364, //process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_QUARTERLY,
//       "Half Yearly": 6555, //process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_HALFYEARLY,
//       Yearly: 12420, //process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_ANNUALLY,
//     },
//     "Kye Bye + Bauddha Dakshata + Udbodh": {
//       Monthly: 1150, //process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_MONTHLY,
//       Quarterly: 3364, //process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_QUARTERLY,
//       "Half Yearly": 6555, //process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_HALFYEARLY,
//       Yearly: 12420, //process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_ANNUALLY,
//     },
//     "Udbodh + Bauddha Dakshata + Kye Bye + Abhyudaya": {
//       Monthly: 2100, //process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: 6143, //process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": 11970, //process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: 22680, //process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Udbodh + Bauddha Dakshata + Abhyudaya + Kye Bye": {
//       Monthly: 2100, //process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: 6143, //process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": 11970, //process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: 22680, //process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Udbodh + Kye Bye + Bauddha Dakshata + Abhyudaya": {
//       Monthly: 2100, //process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: 6143, //process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": 11970, //process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: 22680, //process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Udbodh + Kye Bye + Abhyudaya + Bauddha Dakshata": {
//       Monthly: 2100, //process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: 6143, //process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": 11970, //process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: 22680, //process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Udbodh + Abhyudaya + Bauddha Dakshata + Kye Bye": {
//       Monthly: 2100, //process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: 6143, //process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": 11970, //process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: 22680, //process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Udbodh + Abhyudaya + Kye Bye + Bauddha Dakshata": {
//       Monthly: 2100, //process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: 6143, //process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": 11970, //process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: 22680, //process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Bauddha Dakshata + Udbodh + Kye Bye + Abhyudaya": {
//       Monthly: 2100, //process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: 6143, //process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": 11970, //process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: 22680, //process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Bauddha Dakshata + Udbodh + Abhyudaya + Kye Bye": {
//       Monthly: 2100, //process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: 6143, //process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": 11970, //process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: 22680, //process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Bauddha Dakshata + Kye Bye + Udbodh + Abhyudaya": {
//       Monthly: 2100, //process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: 6143, //process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": 11970, //process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: 22680, //process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Bauddha Dakshata + Kye Bye + Abhyudaya + Udbodh": {
//       Monthly: 2100, //process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: 6143, //process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": 11970, //process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: 22680, //process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Bauddha Dakshata + Abhyudaya + Udbodh + Kye Bye": {
//       Monthly: 2100, //process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: 6143, //process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": 11970, //process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: 22680, //process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Bauddha Dakshata + Abhyudaya + Kye Bye + Udbodh": {
//       Monthly: 2100, //process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: 6143, //process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": 11970, //process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: 22680, //process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Kye Bye + Udbodh + Bauddha Dakshata + Abhyudaya": {
//       Monthly: 2100, //process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: 6143, //process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": 11970, //process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: 22680, //process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Kye Bye + Udbodh + Abhyudaya + Bauddha Dakshata": {
//       Monthly: 2100, //process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: 6143, //process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": 11970, //process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: 22680, //process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Kye Bye + Bauddha Dakshata + Udbodh + Abhyudaya": {
//       Monthly: 2100, //process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: 6143, //process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": 11970, //process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: 22680, //process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Kye Bye + Bauddha Dakshata + Abhyudaya + Udbodh": {
//       Monthly: 2100, //process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: 6143, //process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": 11970, //process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: 22680, //process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Kye Bye + Abhyudaya + Udbodh + Bauddha Dakshata": {
//       Monthly: 2100, //process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: 6143, //process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": 11970, //process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: 22680, //process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Kye Bye + Abhyudaya + Bauddha Dakshata + Udbodh": {
//       Monthly: 2100, //process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: 6143, //process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": 11970, //process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: 22680, //process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Abhyudaya + Udbodh + Bauddha Dakshata + Kye Bye": {
//       Monthly: 2100, //process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: 6143, //process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": 11970, //process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: 22680, //process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Abhyudaya + Udbodh + Kye Bye + Bauddha Dakshata": {
//       Monthly: 2100, //process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: 6143, //process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": 11970, //process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: 22680, //process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Abhyudaya + Bauddha Dakshata + Udbodh + Kye Bye": {
//       Monthly: 2100, //process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: 6143, //process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": 11970, //process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: 22680, //process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Abhyudaya + Bauddha Dakshata + Kye Bye + Udbodh": {
//       Monthly: 2100, //process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: 6143, //process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": 11970, //process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: 22680, //process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Abhyudaya + Kye Bye + Udbodh + Bauddha Dakshata": {
//       Monthly: 2100, //process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: 6143, //process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": 11970, //process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: 22680, //process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Abhyudaya + Kye Bye + Bauddha Dakshata + Udbodh": {
//       Monthly: 2100, //process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: 6143, //process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": 11970, //process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: 22680, //process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//   };

//   const planIds = {
//     Udbodh: {
//       Monthly: process.env.REACT_APP_PLAN_ID_UDBODH_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_UDBODH_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_UDBODH_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_UDBODH_ANNUALLY,
//     },
//     "Bauddha Dakshata": {
//       Monthly: process.env.REACT_APP_PLAN_ID_BD_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_BD_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_BD_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_BD_ANNUALLY,
//     },
//     "Kye Bye": {
//       Monthly: process.env.REACT_APP_PLAN_ID_KYEBYE_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_KYEBYE_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_KYEBYE_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_KYEBYE_ANNUALLY,
//     },
//     Abhyudaya: {
//       Monthly: process.env.REACT_APP_PLAN_ID_ABHYUDAYA_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_ABHYUDAYA_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_ABHYUDAYA_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_ABHYUDAYA_ANNUALLY,
//     },
//     "Udbodh + Bauddha Dakshata": {
//       Monthly: process.env.REACT_APP_PLAN_ID_COMBO_ONE_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_COMBO_ONE_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_COMBO_ONE_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_COMBO_ONE_ANNUALLY,
//     },
//     "Bauddha Dakshata + Udbodh": {
//       Monthly: process.env.REACT_APP_PLAN_ID_COMBO_ONE_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_COMBO_ONE_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_COMBO_ONE_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_COMBO_ONE_ANNUALLY,
//     },
//     "Udbodh + Kye Bye": {
//       Monthly: process.env.REACT_APP_PLAN_ID_COMBO_TWO_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_COMBO_TWO_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_COMBO_TWO_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_COMBO_TWO_ANNUALLY,
//     },
//     "Kye Bye + Udbodh": {
//       Monthly: process.env.REACT_APP_PLAN_ID_COMBO_TWO_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_COMBO_TWO_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_COMBO_TWO_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_COMBO_TWO_ANNUALLY,
//     },
//     "Udbodh + Abhyudaya": {
//       Monthly: process.env.REACT_APP_PLAN_ID_COMBO_THREE_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_COMBO_THREE_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_COMBO_THREE_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_COMBO_THREE_ANNUALLY,
//     },
//     "Abhyudaya + Udbodh": {
//       Monthly: process.env.REACT_APP_PLAN_ID_COMBO_THREE_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_COMBO_THREE_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_COMBO_THREE_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_COMBO_THREE_ANNUALLY,
//     },
//     "Bauddha Dakshata + Kye Bye": {
//       Monthly: process.env.REACT_APP_PLAN_ID_COMBO_FOUR_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_COMBO_FOUR_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_COMBO_FOUR_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_COMBO_FOUR_ANNUALLY,
//     },
//     "Kye Bye + Bauddha Dakshata": {
//       Monthly: process.env.REACT_APP_PLAN_ID_COMBO_FOUR_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_COMBO_FOUR_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_COMBO_FOUR_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_COMBO_FOUR_ANNUALLY,
//     },
//     "Bauddha Dakshata + Abhyudaya": {
//       Monthly: process.env.REACT_APP_PLAN_ID_COMBO_FIVE_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_COMBO_FIVE_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_COMBO_FIVE_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_COMBO_FIVE_ANNUALLY,
//     },
//     "Abhyudaya + Bauddha Dakshata": {
//       Monthly: process.env.REACT_APP_PLAN_ID_COMBO_FIVE_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_COMBO_FIVE_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_COMBO_FIVE_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_COMBO_FIVE_ANNUALLY,
//     },
//     "Kye Bye + Abhyudaya": {
//       Monthly: process.env.REACT_APP_PLAN_ID_COMBO_SIX_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_COMBO_SIX_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_COMBO_SIX_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_COMBO_SIX_ANNUALLY,
//     },
//     "Abhyudaya + Kye Bye": {
//       Monthly: process.env.REACT_APP_PLAN_ID_COMBO_SIX_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_COMBO_SIX_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_COMBO_SIX_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_COMBO_SIX_ANNUALLY,
//     },
//     "Udbodh + Bauddha Dakshata + Kye Bye": {
//       Monthly: process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_ANNUALLY,
//     },
//     "Udbodh + Kye Bye + Bauddha Dakshata": {
//       Monthly: process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_ANNUALLY,
//     },
//     "Bauddha Dakshata + Kye Bye + Udbodh": {
//       Monthly: process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_ANNUALLY,
//     },
//     "Bauddha Dakshata + Udbodh + Kye Bye": {
//       Monthly: process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_ANNUALLY,
//     },
//     "Kye Bye + Udbodh + Bauddha Dakshata": {
//       Monthly: process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_ANNUALLY,
//     },
//     "Kye Bye + Bauddha Dakshata + Udbodh": {
//       Monthly: process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_ANNUALLY,
//     },
//     "Udbodh + Bauddha Dakshata + Kye Bye + Abhyudaya": {
//       Monthly: process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Udbodh + Bauddha Dakshata + Abhyudaya + Kye Bye": {
//       Monthly: process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Udbodh + Kye Bye + Bauddha Dakshata + Abhyudaya": {
//       Monthly: process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Udbodh + Kye Bye + Abhyudaya + Bauddha Dakshata": {
//       Monthly: process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Udbodh + Abhyudaya + Bauddha Dakshata + Kye Bye": {
//       Monthly: process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Udbodh + Abhyudaya + Kye Bye + Bauddha Dakshata": {
//       Monthly: process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Bauddha Dakshata + Udbodh + Kye Bye + Abhyudaya": {
//       Monthly: process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Bauddha Dakshata + Udbodh + Abhyudaya + Kye Bye": {
//       Monthly: process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Bauddha Dakshata + Kye Bye + Udbodh + Abhyudaya": {
//       Monthly: process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Bauddha Dakshata + Kye Bye + Abhyudaya + Udbodh": {
//       Monthly: process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Bauddha Dakshata + Abhyudaya + Udbodh + Kye Bye": {
//       Monthly: process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Bauddha Dakshata + Abhyudaya + Kye Bye + Udbodh": {
//       Monthly: process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Kye Bye + Udbodh + Bauddha Dakshata + Abhyudaya": {
//       Monthly: process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Kye Bye + Udbodh + Abhyudaya + Bauddha Dakshata": {
//       Monthly: process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Kye Bye + Bauddha Dakshata + Udbodh + Abhyudaya": {
//       Monthly: process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Kye Bye + Bauddha Dakshata + Abhyudaya + Udbodh": {
//       Monthly: process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Kye Bye + Abhyudaya + Udbodh + Bauddha Dakshata": {
//       Monthly: process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Kye Bye + Abhyudaya + Bauddha Dakshata + Udbodh": {
//       Monthly: process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Abhyudaya + Udbodh + Bauddha Dakshata + Kye Bye": {
//       Monthly: process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Abhyudaya + Udbodh + Kye Bye + Bauddha Dakshata": {
//       Monthly: process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Abhyudaya + Bauddha Dakshata + Udbodh + Kye Bye": {
//       Monthly: process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Abhyudaya + Bauddha Dakshata + Kye Bye + Udbodh": {
//       Monthly: process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Abhyudaya + Kye Bye + Udbodh + Bauddha Dakshata": {
//       Monthly: process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Abhyudaya + Kye Bye + Bauddha Dakshata + Udbodh": {
//       Monthly: process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },

//     // Add other combinations here...
//   };

//   const totalCounts = {
//     Yearly: 8,
//     Quarterly: 32,
//     "Half Yearly": 16,
//     Monthly: 96,
//   };

//   // Handle modal close event
//   const handleCloseModal = () => {
//     setShowModal(false);

//     // Trigger page reload only when modal is closed
//     window.location.reload();
//   };

//   const handleDivisionChange = (index, value) => {
//     const updatedDivisions = [...selectedDivisions];
//     updatedDivisions[index] = value;
//     setSelectedDivisions(updatedDivisions);
//     calculateTotalPrice(updatedDivisions, selectedDurations);

//     // Automatically set "Already passed 12th" if division is "Abhyudaya" or "Kye Bye"
//     const updatedClasses = [...selectedClasses];
//     updatedClasses[index] =
//       value === "Abhyudaya" || value === "Kye Bye" ? "Already passed 12th" : "";
//     setSelectedClasses(updatedClasses);
//   };

//   const handleClassChange = (index, value) => {
//     const updatedClasses = [...selectedClasses];
//     updatedClasses[index] = value;
//     setSelectedClasses(updatedClasses);
//   };

//   const handleDurationChange = (index, value) => {
//     const durationsCopy = [...selectedDurations];
//     durationsCopy[index] = value;

//     // Set all following duration selections to match the first row's selection
//     if (index === 0) {
//       for (let i = 1; i < durationsCopy.length; i++) {
//         durationsCopy[i] = value;
//       }
//     }

//     setSelectedDurations(durationsCopy);
//     calculateTotalPrice(selectedDivisions, durationsCopy);
//   };

//   const calculateTotalPrice = (divisions, durations) => {
//     let price = 0;
//     let planKey = divisions.filter((d) => d).join(" + "); // Join only non-empty values

//     if (planKey && plans[planKey] && durations[0]) {
//       price = plans[planKey][durations[0]] || 0;
//     }

//     setTotalPrice(price);
//     setSelectedPlanId(planIds[planKey]?.[durations[0]] || null);
//     setSelectedTotalCount(totalCounts[durations[0]] || null);
//   };

//   const handleProceedToPayment = () => {
//     if (!selectedPlanId || !selectedTotalCount) {
//       toast.error("Please complete your selections.");
//       return;
//     }

//     setShowReferralModal(true);
//     setReferralCodeVerified(false);
//   };

//   const verifyReferralCode = async () => {
//     setVerifyingReferral(true);
//     try {
//       const res = await fetch(`/api/users/verifyReferral`, {
//         method: "POST",
//         headers: { "Content-Type": "application/json" },
//         body: JSON.stringify({ referralCode: referralInput.trim() }),
//       });
//       const data = await res.json();

//       if (data.success) {
//         setReferralCodeVerified(true);
//         toast.success("Referral code verified successfully!");
//       } else {
//         toast.error("Invalid referral code.");
//       }
//     } catch (error) {
//       toast.error("Failed to verify referral code.");
//     } finally {
//       setVerifyingReferral(false);
//     }
//   };

//   // handleSubscription Function
//   const handleSubscription = async (plan_id, total_count) => {
//     setLoadingPlan(plan_id); // Set loading for the clicked plan
//     try {
//       const res = await fetch(`/api/payment/subscription`, {
//         method: "POST",
//         headers: {
//           "content-type": "application/json",
//         },
//         body: JSON.stringify({
//           plan_id,
//           userId: currentUser1._id, // Pass user ID to backend
//           total_count,
//           referralCode: referralInput.trim(), // Include referral code in request
//         }),
//       });

//       const data = await res.json();
//       console.log(data);
//       handlePaymentVerify(data.data);
//       // setReferralCode(data.referralCode); // Set referral code if returned
//       // setShowModal(true);
//       setLoadingPlan(null); // Reset loading state in case of error
//     } catch (error) {
//       console.log(error);
//       setLoadingPlan(null); // Reset loading state in case of error
//     }
//   };

//   // handlePaymentVerify Function
//   const handlePaymentVerify = async (data) => {
//     const options = {
//       key: process.env.REACT_APP_RAZORPAY_KEY_ID,
//       subscription_id: data.id,
//       name: "Gyankosha",
//       description: "Payment for Quiz Subscription",
//       handler: async (response) => {
//         console.log("response", response);
//         try {
//           const res = await fetch(`/api/payment/verify`, {
//             method: "POST",
//             headers: {
//               "content-type": "application/json",
//             },
//             body: JSON.stringify({
//               razorpay_subscription_id: response.razorpay_subscription_id,
//               razorpay_payment_id: response.razorpay_payment_id,
//               razorpay_signature: response.razorpay_signature,
//               userId: currentUser1._id, // Pass user ID for verification
//               paidfor: selectedDivisions.map(
//                 (division, index) => `${division}-${selectedClasses[index]}`
//               ), // Store "Division-Class"
//             }),
//           });

//           const verifyData = await res.json();

//           if (verifyData.message) {
//             toast.success(verifyData.message);
//             console.log("verifyData.referralCode : ", verifyData.referralCode);
//             if (verifyData.referralCode) {
//               setReferralCode(verifyData.referralCode);
//               setShowModal(true);
//             }
//             // Reload the page after 2 seconds
//             // setTimeout(() => {
//             //   window.location.reload();
//             // }, 2000);
//           }
//         } catch (error) {
//           console.log(error);
//         } finally {
//           setLoadingPlan(null); // Reset loading state in case of error
//         }
//       },
//       theme: {
//         color: "#5f63b8",
//       },
//     };
//     const rzp1 = new window.Razorpay(options);
//     rzp1.open();
//   };

//   return (
//     <div
//       className="pricing-table"
//       style={{
//         fontFamily: '"Libre Baskerville", serif',
//         margin: "100px 50px 200px 50px",
//       }}
//     >
//       <h3>Select Divisions and Durations:</h3>
//       <table
//         className="table table-bordered"
//         style={{ borderColor: "#343a40", overflow: "visible" }}
//       >
//         <thead className="table-dark">
//           <tr>
//             <th></th>
//             <th>Select Division</th>
//             <th>Select Quiz Class</th>
//             <th>Select Duration</th>
//           </tr>
//         </thead>
//         <tbody>
//           {[0, 1, 2, 3].map((rowIndex) => (
//             <tr key={rowIndex}>
//               <td>Choose your vertical {rowIndex + 1} :</td>
//               <td>
//                 <select
//                   className="form-control"
//                   value={selectedDivisions[rowIndex]}
//                   onChange={(e) =>
//                     handleDivisionChange(rowIndex, e.target.value)
//                   }
//                 >
//                   <option value="">-- Select Division --</option>
//                   {divisions.map((division) => (
//                     <option key={division} value={division}>
//                       {division}
//                     </option>
//                   ))}
//                 </select>
//               </td>
//               <td>
//                 <select
//                   className="form-control"
//                   value={selectedClasses[rowIndex]}
//                   onChange={(e) => handleClassChange(rowIndex, e.target.value)}
//                   disabled={
//                     selectedDivisions[rowIndex] === "Abhyudaya" ||
//                     selectedDivisions[rowIndex] === "Kye Bye"
//                   }
//                 >
//                   <option value="">-- Select Class --</option>
//                   {classes.map((cls) => (
//                     <option key={cls} value={cls}>
//                       {cls}
//                     </option>
//                   ))}
//                 </select>
//               </td>
//               <td>
//                 <select
//                   className="form-control"
//                   value={selectedDurations[rowIndex]}
//                   onChange={(e) =>
//                     handleDurationChange(rowIndex, e.target.value)
//                   }
//                   //disabled={rowIndex !== 0 && !selectedDurations[0]}
//                 >
//                   <option value="">-- Select Duration --</option>
//                   {durations.map((duration) => (
//                     <option key={duration} value={duration}>
//                       {duration}
//                     </option>
//                   ))}
//                 </select>
//               </td>
//             </tr>
//           ))}
//         </tbody>
//       </table>

//       <div
//         style={{
//           display: "flex",
//           alignItems: "center",
//           gap: "15px",
//           marginTop: "10px",
//         }}
//       >
//         <span style={{ fontWeight: "bold" }}>Total Price: ₹{totalPrice}</span>
//         <button className="btn btn-primary" onClick={handleProceedToPayment}>
//           Proceed
//         </button>
//       </div>

//       <Modal show={showModal} onHide={handleCloseModal} centered>
//         <Modal.Header closeButton>
//           <Modal.Title>
//             Gyankosha's Subscription Active now and your Referral Code :{" "}
//             <span style={{ color: "red" }}>{referralCode}</span>
//           </Modal.Title>
//         </Modal.Header>

//         <Modal.Body>
//           <div style={{ fontFamily: "Arial, sans-serif", lineHeight: "1.6" }}>
//             <p>
//               Dear <strong>{currentUser1 ? currentUser1.name : ""}</strong>,
//             </p>
//             <p>
//               Thank you for choosing <strong>Gyankosha</strong> as your trusted
//               education partner. We are thrilled to have you on board! Your
//               subscription plan is now active, giving you access to the premium
//               content and resources.
//             </p>
//             <p>
//               Attached, you will find your unique Referral Code:{" "}
//               <strong style={{ color: "red" }}>{referralCode}</strong>.
//             </p>
//             <p>
//               As a token of appreciation for your support, when a new user
//               subscribes and makes their first payment using your referral code,
//               you become eligible for a <strong>20% cashback</strong> to your
//               account, and the one referred by you becomes eligible for a{" "}
//               <strong>10% cashback</strong> to their account. Please note that
//               the cashback is applicable only against the first payment made by
//               the referred user and is valid for one cycle only.
//             </p>
//             <p>
//               Let’s keep learning, growing, and making education exciting
//               together!
//             </p>
//             <p>Warm regards,</p>
//             <p>
//               <strong>The Gyankosha Team</strong>
//             </p>
//           </div>
//         </Modal.Body>

//         <Modal.Footer>
//           <Button variant="secondary" onClick={handleCloseModal}>
//             Close
//           </Button>
//         </Modal.Footer>
//       </Modal>

//       <Modal
//         show={showReferralModal}
//         onHide={() => {
//           setShowReferralModal(false);
//           setHasReferralCode(null); // Reset the referral code selection
//           setReferralInput(""); // Clear referral input field
//           setReferralCodeVerified(false); // Reset verification status
//           setVerifyingReferral(false); // Reset verifying state
//         }}
//         onExit={() => {
//           setShowReferralModal(false);
//           setHasReferralCode(null); // Reset the referral code selection
//           setReferralInput(""); // Clear referral input field
//           setReferralCodeVerified(false); // Reset verification status
//           setVerifyingReferral(false); // Reset verifying state
//         }}
//         centered
//       >
//         <Modal.Header closeButton>
//           {hasReferralCode === null && (
//             <Modal.Title
//               style={{ fontFamily: "Arial, sans-serif", fontWeight: "bold" }}
//             >
//               Do you have a referral code?
//             </Modal.Title>
//           )}
//         </Modal.Header>
//         <Modal.Body
//           style={{ fontFamily: "Arial, sans-serif", lineHeight: "1.6" }}
//         >
//           <div>
//             {hasReferralCode === null && (
//               <div className="d-flex flex-column align-items-start">
//                 <div className="form-check custom-radio mb-3">
//                   <input
//                     className="form-check-input"
//                     type="radio"
//                     name="referralCodeOption"
//                     id="referralYes"
//                     onChange={() => setHasReferralCode(true)}
//                   />
//                   <label
//                     className="form-check-label ms-2"
//                     htmlFor="referralYes"
//                   >
//                     Yes, I have a referral code
//                   </label>
//                 </div>
//                 <div className="form-check custom-radio">
//                   <input
//                     className="form-check-input"
//                     type="radio"
//                     name="referralCodeOption"
//                     id="referralNo"
//                     onChange={() => setHasReferralCode(false)}
//                   />
//                   <label className="form-check-label ms-2" htmlFor="referralNo">
//                     No, I don't have a referral code
//                   </label>
//                 </div>
//               </div>
//             )}
//             {hasReferralCode === true && !referralCodeVerified && (
//               <div>
//                 <label htmlFor="referralInput" style={{ fontWeight: "bold" }}>
//                   Enter your referral code:
//                 </label>
//                 <input
//                   type="text"
//                   className="form-control mt-2"
//                   id="referralInput"
//                   placeholder="Enter referral code here"
//                   value={referralInput}
//                   onChange={(e) => setReferralInput(e.target.value)}
//                   style={{ border: "1px solid #ced4da", borderRadius: "5px" }}
//                 />
//                 <button
//                   className="btn btn-primary mt-3 w-100"
//                   onClick={verifyReferralCode}
//                   disabled={verifyingReferral}
//                   style={{ boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)" }}
//                 >
//                   {verifyingReferral ? "Verifying..." : "Verify Referral Code"}
//                 </button>
//               </div>
//             )}
//             {referralCodeVerified && (
//               <div className="text-center">
//                 <p className="text-success" style={{ fontWeight: "bold" }}>
//                   Referral code verified successfully!
//                 </p>
//                 <button
//                   className="btn btn-success mt-3 w-100"
//                   onClick={() => {
//                     setShowReferralModal(false);
//                     handleSubscription(selectedPlanId, selectedTotalCount);
//                   }}
//                   style={{ boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)" }}
//                 >
//                   Proceed to Payment
//                 </button>
//               </div>
//             )}
//             {hasReferralCode === false && (
//               <div className="text-center">
//                 <p className="text-muted" style={{ fontWeight: "bold" }}>
//                   No referral code? No problem!
//                 </p>
//                 <button
//                   className="btn btn-success mt-3 w-100"
//                   onClick={() => {
//                     setShowReferralModal(false);
//                     handleSubscription(selectedPlanId, selectedTotalCount);
//                   }}
//                   style={{ boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)" }}
//                 >
//                   Proceed to Payment
//                 </button>
//               </div>
//             )}
//           </div>
//         </Modal.Body>
//       </Modal>
//     </div>
//   );
// };

// export default Purchasescreen;
/////////////////////////////////////////////////////////////////////////////////

// import React, { useState, useEffect } from "react";
// import toast from "react-hot-toast";
// import Modal from "react-bootstrap/Modal";
// import { Button } from "react-bootstrap";
// import { useSelector } from "react-redux";

// const Purchasescreen = () => {
//   const [selectedDurations, setSelectedDurations] = useState(["", "", "", ""]);
//   const [selectedDivisions, setSelectedDivisions] = useState(["", "", "", ""]);
//   const [selectedClasses, setSelectedClasses] = useState(["", "", "", ""]); // New State for Class
//   const [totalPrice, setTotalPrice] = useState(0);
//   const [selectedPlanId, setSelectedPlanId] = useState(null);
//   const [selectedTotalCount, setSelectedTotalCount] = useState(null);
//   const [showReferralModal, setShowReferralModal] = useState(false);
//   const [referralCodeVerified, setReferralCodeVerified] = useState(false);
//   const [verifyingReferral, setVerifyingReferral] = useState(false);
//   const [referralInput, setReferralInput] = useState("");
//   const [loadingPlan, setLoadingPlan] = useState(null);

//   const [showModal, setShowModal] = useState(false);
//   const [referralCode, setReferralCode] = useState(null);
//   const [hasReferralCode, setHasReferralCode] = useState(null); // Track Yes/No selection

//   const userstate = useSelector((state) => state.loginUserReducer);
//   const { currentUser1 } = userstate;

//   const durations = ["Monthly", "Quarterly", "Half Yearly", "Yearly"];
//   const divisions = ["Udbodh", "Bauddha Dakshata", "Kye Bye", "Abhyudaya"];
//   const classes = [...Array(12).keys()]
//     .map((num) => (num + 1).toString())
//     .concat(["Already passed 12th"]);

//   const plans = {
//     Udbodh: {
//       Monthly: 300,
//       Quarterly: 878,
//       "Half Yearly": 1710,
//       Yearly: 3240,
//     },
//     "Bauddha Dakshata": {
//       Monthly: 500,
//       Quarterly: 1463,
//       "Half Yearly": 2850,
//       Yearly: 5400,
//     },
//     "Kye Bye": {
//       Monthly: 500,
//       Quarterly: 1463,
//       "Half Yearly": 2850,
//       Yearly: 5400,
//     },
//     Abhyudaya: {
//       Monthly: 1000, //process.env.REACT_APP_PLAN_ID_ABHYUDAYA_MONTHLY,
//       Quarterly: 2925, //process.env.REACT_APP_PLAN_ID_ABHYUDAYA_QUARTERLY,
//       "Half Yearly": 5700, //process.env.REACT_APP_PLAN_ID_ABHYUDAYA_HALFYEARLY,
//       Yearly: 10800, //process.env.REACT_APP_PLAN_ID_ABHYUDAYA_ANNUALLY,
//     },
//     "Udbodh + Bauddha Dakshata": {
//       Monthly: 700, //process.env.REACT_APP_PLAN_ID_COMBO_ONE_MONTHLY,
//       Quarterly: 2048, //process.env.REACT_APP_PLAN_ID_COMBO_ONE_QUARTERLY,
//       "Half Yearly": 3990, //process.env.REACT_APP_PLAN_ID_COMBO_ONE_HALFYEARLY,
//       Yearly: 7560, //process.env.REACT_APP_PLAN_ID_COMBO_ONE_ANNUALLY,
//     },
//     "Bauddha Dakshata + Udbodh": {
//       Monthly: 700, //process.env.REACT_APP_PLAN_ID_COMBO_ONE_MONTHLY,
//       Quarterly: 2048, //process.env.REACT_APP_PLAN_ID_COMBO_ONE_QUARTERLY,
//       "Half Yearly": 3990, //process.env.REACT_APP_PLAN_ID_COMBO_ONE_HALFYEARLY,
//       Yearly: 7560, //process.env.REACT_APP_PLAN_ID_COMBO_ONE_ANNUALLY,
//     },
//     "Udbodh + Kye Bye": {
//       Monthly: 700, //process.env.REACT_APP_PLAN_ID_COMBO_TWO_MONTHLY,
//       Quarterly: 2048, //process.env.REACT_APP_PLAN_ID_COMBO_TWO_QUARTERLY,
//       "Half Yearly": 3990, //process.env.REACT_APP_PLAN_ID_COMBO_TWO_HALFYEARLY,
//       Yearly: 7560, //process.env.REACT_APP_PLAN_ID_COMBO_TWO_ANNUALLY,
//     },
//     "Kye Bye + Udbodh": {
//       Monthly: 700, //process.env.REACT_APP_PLAN_ID_COMBO_TWO_MONTHLY,
//       Quarterly: 2048, //process.env.REACT_APP_PLAN_ID_COMBO_TWO_QUARTERLY,
//       "Half Yearly": 3990, //process.env.REACT_APP_PLAN_ID_COMBO_TWO_HALFYEARLY,
//       Yearly: 7560, //process.env.REACT_APP_PLAN_ID_COMBO_TWO_ANNUALLY,
//     },
//     "Udbodh + Abhyudaya": {
//       Monthly: 1200, //process.env.REACT_APP_PLAN_ID_COMBO_THREE_MONTHLY,
//       Quarterly: 3510, //process.env.REACT_APP_PLAN_ID_COMBO_THREE_QUARTERLY,
//       "Half Yearly": 6840, //process.env.REACT_APP_PLAN_ID_COMBO_THREE_HALFYEARLY,
//       Yearly: 12960, //process.env.REACT_APP_PLAN_ID_COMBO_THREE_ANNUALLY,
//     },
//     "Abhyudaya + Udbodh": {
//       Monthly: 1200, //process.env.REACT_APP_PLAN_ID_COMBO_THREE_MONTHLY,
//       Quarterly: 3510, //process.env.REACT_APP_PLAN_ID_COMBO_THREE_QUARTERLY,
//       "Half Yearly": 6840, //process.env.REACT_APP_PLAN_ID_COMBO_THREE_HALFYEARLY,
//       Yearly: 12960, //process.env.REACT_APP_PLAN_ID_COMBO_THREE_ANNUALLY,
//     },
//     "Bauddha Dakshata + Kye Bye": {
//       Monthly: 900, //process.env.REACT_APP_PLAN_ID_COMBO_FOUR_MONTHLY,
//       Quarterly: 2633, //process.env.REACT_APP_PLAN_ID_COMBO_FOUR_QUARTERLY,
//       "Half Yearly": 5130, //process.env.REACT_APP_PLAN_ID_COMBO_FOUR_HALFYEARLY,
//       Yearly: 9720, //process.env.REACT_APP_PLAN_ID_COMBO_FOUR_ANNUALLY,
//     },
//     "Kye Bye + Bauddha Dakshata": {
//       Monthly: 900, //process.env.REACT_APP_PLAN_ID_COMBO_FOUR_MONTHLY,
//       Quarterly: 2633, //process.env.REACT_APP_PLAN_ID_COMBO_FOUR_QUARTERLY,
//       "Half Yearly": 5130, //process.env.REACT_APP_PLAN_ID_COMBO_FOUR_HALFYEARLY,
//       Yearly: 9720, //process.env.REACT_APP_PLAN_ID_COMBO_FOUR_ANNUALLY,
//     },
//     "Bauddha Dakshata + Abhyudaya": {
//       Monthly: 1400, //process.env.REACT_APP_PLAN_ID_COMBO_FIVE_MONTHLY,
//       Quarterly: 4095, //process.env.REACT_APP_PLAN_ID_COMBO_FIVE_QUARTERLY,
//       "Half Yearly": 7980, //process.env.REACT_APP_PLAN_ID_COMBO_FIVE_HALFYEARLY,
//       Yearly: 15120, //process.env.REACT_APP_PLAN_ID_COMBO_FIVE_ANNUALLY,
//     },
//     "Abhyudaya + Bauddha Dakshata": {
//       Monthly: 1400, //process.env.REACT_APP_PLAN_ID_COMBO_FIVE_MONTHLY,
//       Quarterly: 4095, //process.env.REACT_APP_PLAN_ID_COMBO_FIVE_QUARTERLY,
//       "Half Yearly": 7980, //process.env.REACT_APP_PLAN_ID_COMBO_FIVE_HALFYEARLY,
//       Yearly: 15120, //process.env.REACT_APP_PLAN_ID_COMBO_FIVE_ANNUALLY,
//     },
//     "Kye Bye + Abhyudaya": {
//       Monthly: 1400, //process.env.REACT_APP_PLAN_ID_COMBO_SIX_MONTHLY,
//       Quarterly: 4095, //process.env.REACT_APP_PLAN_ID_COMBO_SIX_QUARTERLY,
//       "Half Yearly": 7980, //process.env.REACT_APP_PLAN_ID_COMBO_SIX_HALFYEARLY,
//       Yearly: 15120, //process.env.REACT_APP_PLAN_ID_COMBO_SIX_ANNUALLY,
//     },
//     "Abhyudaya + Kye Bye": {
//       Monthly: 1400, //process.env.REACT_APP_PLAN_ID_COMBO_SIX_MONTHLY,
//       Quarterly: 4095, //process.env.REACT_APP_PLAN_ID_COMBO_SIX_QUARTERLY,
//       "Half Yearly": 7980, //process.env.REACT_APP_PLAN_ID_COMBO_SIX_HALFYEARLY,
//       Yearly: 15120, //process.env.REACT_APP_PLAN_ID_COMBO_SIX_ANNUALLY,
//     },
//     "Udbodh + Bauddha Dakshata + Kye Bye": {
//       Monthly: 1150, //process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_MONTHLY,
//       Quarterly: 3364, //process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_QUARTERLY,
//       "Half Yearly": 6555, //process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_HALFYEARLY,
//       Yearly: 12420, //process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_ANNUALLY,
//     },
//     "Udbodh + Kye Bye + Bauddha Dakshata": {
//       Monthly: 1150, //process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_MONTHLY,
//       Quarterly: 3364, //process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_QUARTERLY,
//       "Half Yearly": 6555, //process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_HALFYEARLY,
//       Yearly: 12420, //process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_ANNUALLY,
//     },
//     "Bauddha Dakshata + Kye Bye + Udbodh": {
//       Monthly: 1150, //process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_MONTHLY,
//       Quarterly: 3364, //process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_QUARTERLY,
//       "Half Yearly": 6555, //process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_HALFYEARLY,
//       Yearly: 12420, //process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_ANNUALLY,
//     },
//     "Bauddha Dakshata + Udbodh + Kye Bye": {
//       Monthly: 1150, //process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_MONTHLY,
//       Quarterly: 3364, //process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_QUARTERLY,
//       "Half Yearly": 6555, //process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_HALFYEARLY,
//       Yearly: 12420, //process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_ANNUALLY,
//     },
//     "Kye Bye + Udbodh + Bauddha Dakshata": {
//       Monthly: 1150, //process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_MONTHLY,
//       Quarterly: 3364, //process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_QUARTERLY,
//       "Half Yearly": 6555, //process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_HALFYEARLY,
//       Yearly: 12420, //process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_ANNUALLY,
//     },
//     "Kye Bye + Bauddha Dakshata + Udbodh": {
//       Monthly: 1150, //process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_MONTHLY,
//       Quarterly: 3364, //process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_QUARTERLY,
//       "Half Yearly": 6555, //process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_HALFYEARLY,
//       Yearly: 12420, //process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_ANNUALLY,
//     },
//     "Udbodh + Bauddha Dakshata + Kye Bye + Abhyudaya": {
//       Monthly: 2100, //process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: 6143, //process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": 11970, //process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: 22680, //process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Udbodh + Bauddha Dakshata + Abhyudaya + Kye Bye": {
//       Monthly: 2100, //process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: 6143, //process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": 11970, //process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: 22680, //process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Udbodh + Kye Bye + Bauddha Dakshata + Abhyudaya": {
//       Monthly: 2100, //process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: 6143, //process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": 11970, //process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: 22680, //process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Udbodh + Kye Bye + Abhyudaya + Bauddha Dakshata": {
//       Monthly: 2100, //process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: 6143, //process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": 11970, //process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: 22680, //process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Udbodh + Abhyudaya + Bauddha Dakshata + Kye Bye": {
//       Monthly: 2100, //process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: 6143, //process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": 11970, //process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: 22680, //process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Udbodh + Abhyudaya + Kye Bye + Bauddha Dakshata": {
//       Monthly: 2100, //process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: 6143, //process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": 11970, //process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: 22680, //process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Bauddha Dakshata + Udbodh + Kye Bye + Abhyudaya": {
//       Monthly: 2100, //process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: 6143, //process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": 11970, //process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: 22680, //process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Bauddha Dakshata + Udbodh + Abhyudaya + Kye Bye": {
//       Monthly: 2100, //process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: 6143, //process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": 11970, //process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: 22680, //process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Bauddha Dakshata + Kye Bye + Udbodh + Abhyudaya": {
//       Monthly: 2100, //process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: 6143, //process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": 11970, //process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: 22680, //process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Bauddha Dakshata + Kye Bye + Abhyudaya + Udbodh": {
//       Monthly: 2100, //process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: 6143, //process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": 11970, //process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: 22680, //process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Bauddha Dakshata + Abhyudaya + Udbodh + Kye Bye": {
//       Monthly: 2100, //process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: 6143, //process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": 11970, //process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: 22680, //process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Bauddha Dakshata + Abhyudaya + Kye Bye + Udbodh": {
//       Monthly: 2100, //process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: 6143, //process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": 11970, //process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: 22680, //process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Kye Bye + Udbodh + Bauddha Dakshata + Abhyudaya": {
//       Monthly: 2100, //process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: 6143, //process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": 11970, //process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: 22680, //process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Kye Bye + Udbodh + Abhyudaya + Bauddha Dakshata": {
//       Monthly: 2100, //process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: 6143, //process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": 11970, //process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: 22680, //process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Kye Bye + Bauddha Dakshata + Udbodh + Abhyudaya": {
//       Monthly: 2100, //process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: 6143, //process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": 11970, //process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: 22680, //process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Kye Bye + Bauddha Dakshata + Abhyudaya + Udbodh": {
//       Monthly: 2100, //process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: 6143, //process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": 11970, //process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: 22680, //process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Kye Bye + Abhyudaya + Udbodh + Bauddha Dakshata": {
//       Monthly: 2100, //process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: 6143, //process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": 11970, //process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: 22680, //process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Kye Bye + Abhyudaya + Bauddha Dakshata + Udbodh": {
//       Monthly: 2100, //process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: 6143, //process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": 11970, //process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: 22680, //process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Abhyudaya + Udbodh + Bauddha Dakshata + Kye Bye": {
//       Monthly: 2100, //process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: 6143, //process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": 11970, //process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: 22680, //process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Abhyudaya + Udbodh + Kye Bye + Bauddha Dakshata": {
//       Monthly: 2100, //process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: 6143, //process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": 11970, //process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: 22680, //process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Abhyudaya + Bauddha Dakshata + Udbodh + Kye Bye": {
//       Monthly: 2100, //process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: 6143, //process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": 11970, //process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: 22680, //process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Abhyudaya + Bauddha Dakshata + Kye Bye + Udbodh": {
//       Monthly: 2100, //process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: 6143, //process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": 11970, //process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: 22680, //process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Abhyudaya + Kye Bye + Udbodh + Bauddha Dakshata": {
//       Monthly: 2100, //process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: 6143, //process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": 11970, //process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: 22680, //process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Abhyudaya + Kye Bye + Bauddha Dakshata + Udbodh": {
//       Monthly: 2100, //process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: 6143, //process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": 11970, //process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: 22680, //process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//   };

//   const planIds = {
//     Udbodh: {
//       Monthly: process.env.REACT_APP_PLAN_ID_UDBODH_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_UDBODH_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_UDBODH_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_UDBODH_ANNUALLY,
//     },
//     "Bauddha Dakshata": {
//       Monthly: process.env.REACT_APP_PLAN_ID_BD_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_BD_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_BD_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_BD_ANNUALLY,
//     },
//     "Kye Bye": {
//       Monthly: process.env.REACT_APP_PLAN_ID_KYEBYE_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_KYEBYE_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_KYEBYE_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_KYEBYE_ANNUALLY,
//     },
//     Abhyudaya: {
//       Monthly: process.env.REACT_APP_PLAN_ID_ABHYUDAYA_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_ABHYUDAYA_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_ABHYUDAYA_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_ABHYUDAYA_ANNUALLY,
//     },
//     "Udbodh + Bauddha Dakshata": {
//       Monthly: process.env.REACT_APP_PLAN_ID_COMBO_ONE_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_COMBO_ONE_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_COMBO_ONE_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_COMBO_ONE_ANNUALLY,
//     },
//     "Bauddha Dakshata + Udbodh": {
//       Monthly: process.env.REACT_APP_PLAN_ID_COMBO_ONE_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_COMBO_ONE_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_COMBO_ONE_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_COMBO_ONE_ANNUALLY,
//     },
//     "Udbodh + Kye Bye": {
//       Monthly: process.env.REACT_APP_PLAN_ID_COMBO_TWO_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_COMBO_TWO_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_COMBO_TWO_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_COMBO_TWO_ANNUALLY,
//     },
//     "Kye Bye + Udbodh": {
//       Monthly: process.env.REACT_APP_PLAN_ID_COMBO_TWO_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_COMBO_TWO_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_COMBO_TWO_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_COMBO_TWO_ANNUALLY,
//     },
//     "Udbodh + Abhyudaya": {
//       Monthly: process.env.REACT_APP_PLAN_ID_COMBO_THREE_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_COMBO_THREE_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_COMBO_THREE_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_COMBO_THREE_ANNUALLY,
//     },
//     "Abhyudaya + Udbodh": {
//       Monthly: process.env.REACT_APP_PLAN_ID_COMBO_THREE_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_COMBO_THREE_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_COMBO_THREE_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_COMBO_THREE_ANNUALLY,
//     },
//     "Bauddha Dakshata + Kye Bye": {
//       Monthly: process.env.REACT_APP_PLAN_ID_COMBO_FOUR_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_COMBO_FOUR_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_COMBO_FOUR_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_COMBO_FOUR_ANNUALLY,
//     },
//     "Kye Bye + Bauddha Dakshata": {
//       Monthly: process.env.REACT_APP_PLAN_ID_COMBO_FOUR_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_COMBO_FOUR_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_COMBO_FOUR_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_COMBO_FOUR_ANNUALLY,
//     },
//     "Bauddha Dakshata + Abhyudaya": {
//       Monthly: process.env.REACT_APP_PLAN_ID_COMBO_FIVE_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_COMBO_FIVE_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_COMBO_FIVE_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_COMBO_FIVE_ANNUALLY,
//     },
//     "Abhyudaya + Bauddha Dakshata": {
//       Monthly: process.env.REACT_APP_PLAN_ID_COMBO_FIVE_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_COMBO_FIVE_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_COMBO_FIVE_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_COMBO_FIVE_ANNUALLY,
//     },
//     "Kye Bye + Abhyudaya": {
//       Monthly: process.env.REACT_APP_PLAN_ID_COMBO_SIX_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_COMBO_SIX_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_COMBO_SIX_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_COMBO_SIX_ANNUALLY,
//     },
//     "Abhyudaya + Kye Bye": {
//       Monthly: process.env.REACT_APP_PLAN_ID_COMBO_SIX_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_COMBO_SIX_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_COMBO_SIX_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_COMBO_SIX_ANNUALLY,
//     },
//     "Udbodh + Bauddha Dakshata + Kye Bye": {
//       Monthly: process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_ANNUALLY,
//     },
//     "Udbodh + Kye Bye + Bauddha Dakshata": {
//       Monthly: process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_ANNUALLY,
//     },
//     "Bauddha Dakshata + Kye Bye + Udbodh": {
//       Monthly: process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_ANNUALLY,
//     },
//     "Bauddha Dakshata + Udbodh + Kye Bye": {
//       Monthly: process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_ANNUALLY,
//     },
//     "Kye Bye + Udbodh + Bauddha Dakshata": {
//       Monthly: process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_ANNUALLY,
//     },
//     "Kye Bye + Bauddha Dakshata + Udbodh": {
//       Monthly: process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_ANNUALLY,
//     },
//     "Udbodh + Bauddha Dakshata + Kye Bye + Abhyudaya": {
//       Monthly: process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Udbodh + Bauddha Dakshata + Abhyudaya + Kye Bye": {
//       Monthly: process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Udbodh + Kye Bye + Bauddha Dakshata + Abhyudaya": {
//       Monthly: process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Udbodh + Kye Bye + Abhyudaya + Bauddha Dakshata": {
//       Monthly: process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Udbodh + Abhyudaya + Bauddha Dakshata + Kye Bye": {
//       Monthly: process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Udbodh + Abhyudaya + Kye Bye + Bauddha Dakshata": {
//       Monthly: process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Bauddha Dakshata + Udbodh + Kye Bye + Abhyudaya": {
//       Monthly: process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Bauddha Dakshata + Udbodh + Abhyudaya + Kye Bye": {
//       Monthly: process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Bauddha Dakshata + Kye Bye + Udbodh + Abhyudaya": {
//       Monthly: process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Bauddha Dakshata + Kye Bye + Abhyudaya + Udbodh": {
//       Monthly: process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Bauddha Dakshata + Abhyudaya + Udbodh + Kye Bye": {
//       Monthly: process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Bauddha Dakshata + Abhyudaya + Kye Bye + Udbodh": {
//       Monthly: process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Kye Bye + Udbodh + Bauddha Dakshata + Abhyudaya": {
//       Monthly: process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Kye Bye + Udbodh + Abhyudaya + Bauddha Dakshata": {
//       Monthly: process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Kye Bye + Bauddha Dakshata + Udbodh + Abhyudaya": {
//       Monthly: process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Kye Bye + Bauddha Dakshata + Abhyudaya + Udbodh": {
//       Monthly: process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Kye Bye + Abhyudaya + Udbodh + Bauddha Dakshata": {
//       Monthly: process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Kye Bye + Abhyudaya + Bauddha Dakshata + Udbodh": {
//       Monthly: process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Abhyudaya + Udbodh + Bauddha Dakshata + Kye Bye": {
//       Monthly: process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Abhyudaya + Udbodh + Kye Bye + Bauddha Dakshata": {
//       Monthly: process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Abhyudaya + Bauddha Dakshata + Udbodh + Kye Bye": {
//       Monthly: process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Abhyudaya + Bauddha Dakshata + Kye Bye + Udbodh": {
//       Monthly: process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Abhyudaya + Kye Bye + Udbodh + Bauddha Dakshata": {
//       Monthly: process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Abhyudaya + Kye Bye + Bauddha Dakshata + Udbodh": {
//       Monthly: process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },

//     // Add other combinations here...
//   };

//   const totalCounts = {
//     Yearly: 8,
//     Quarterly: 32,
//     "Half Yearly": 16,
//     Monthly: 96,
//   };

//   // Handle modal close event
//   const handleCloseModal = () => {
//     setShowModal(false);

//     // Trigger page reload only when modal is closed
//     window.location.reload();
//   };

//   const handleDivisionChange = (index, value) => {
//     const updatedDivisions = [...selectedDivisions];
//     updatedDivisions[index] = value;
//     setSelectedDivisions(updatedDivisions);
//     calculateTotalPrice(updatedDivisions, selectedDurations);

//     const updatedClasses = [...selectedClasses];

//     if (value === "Abhyudaya" || value === "Kye Bye") {
//       updatedClasses[index] = "Already passed 12th";
//     } else if (value === "Udbodh" || value === "Bauddha Dakshata") {
//       // Find if another row already has Udbodh or Bauddha Dakshata with a selected class
//       const matchingIndex = selectedDivisions.findIndex(
//         (div, i) =>
//           i !== index &&
//           (div === "Udbodh" || div === "Bauddha Dakshata") &&
//           selectedClasses[i]
//       );

//       // If found, update this row's class to match
//       if (matchingIndex !== -1) {
//         updatedClasses[index] = selectedClasses[matchingIndex];
//       }
//     }

//     setSelectedClasses(updatedClasses);
//   };

//   const handleClassChange = (index, value) => {
//     const updatedClasses = [...selectedClasses];
//     updatedClasses[index] = value;

//     // If this row belongs to Udbodh or Bauddha Dakshata, update all similar divisions
//     if (
//       selectedDivisions[index] === "Udbodh" ||
//       selectedDivisions[index] === "Bauddha Dakshata"
//     ) {
//       selectedDivisions.forEach((div, i) => {
//         if (i !== index && (div === "Udbodh" || div === "Bauddha Dakshata")) {
//           updatedClasses[i] = value; // Sync all similar divisions
//         }
//       });
//     }

//     setSelectedClasses(updatedClasses);
//   };

//   const handleDurationChange = (index, value) => {
//     const updatedDurations = selectedDurations.map(() => value); // Set all to the same value
//     setSelectedDurations(updatedDurations);
//     calculateTotalPrice(selectedDivisions, updatedDurations);
//   };

//   const calculateTotalPrice = (divisions, durations) => {
//     let price = 0;
//     let planKey = divisions.filter((d) => d).join(" + "); // Join only non-empty values

//     if (planKey && plans[planKey] && durations[0]) {
//       price = plans[planKey][durations[0]] || 0;
//     }

//     setTotalPrice(price);
//     setSelectedPlanId(planIds[planKey]?.[durations[0]] || null);
//     setSelectedTotalCount(totalCounts[durations[0]] || null);
//   };

//   const handleProceedToPayment = () => {
//     if (!selectedPlanId || !selectedTotalCount) {
//       toast.error("Please complete your selections.");
//       return;
//     }

//     setShowReferralModal(true);
//     setReferralCodeVerified(false);
//   };

//   const verifyReferralCode = async () => {
//     setVerifyingReferral(true);
//     try {
//       const res = await fetch(`/api/users/verifyReferral`, {
//         method: "POST",
//         headers: { "Content-Type": "application/json" },
//         body: JSON.stringify({ referralCode: referralInput.trim() }),
//       });
//       const data = await res.json();

//       if (data.success) {
//         setReferralCodeVerified(true);
//         toast.success("Referral code verified successfully!");
//       } else {
//         toast.error("Invalid referral code.");
//       }
//     } catch (error) {
//       toast.error("Failed to verify referral code.");
//     } finally {
//       setVerifyingReferral(false);
//     }
//   };

//   // handleSubscription Function
//   const handleSubscription = async (plan_id, total_count) => {
//     setLoadingPlan(plan_id); // Set loading for the clicked plan
//     try {
//       const res = await fetch(`/api/payment/subscription`, {
//         method: "POST",
//         headers: {
//           "content-type": "application/json",
//         },
//         body: JSON.stringify({
//           plan_id,
//           userId: currentUser1._id, // Pass user ID to backend
//           total_count,
//           referralCode: referralInput.trim(), // Include referral code in request
//         }),
//       });

//       const data = await res.json();
//       console.log(data);
//       handlePaymentVerify(data.data);
//       // setReferralCode(data.referralCode); // Set referral code if returned
//       // setShowModal(true);
//       setLoadingPlan(null); // Reset loading state in case of error
//     } catch (error) {
//       console.log(error);
//       setLoadingPlan(null); // Reset loading state in case of error
//     }
//   };

//   // handlePaymentVerify Function
//   const handlePaymentVerify = async (data) => {
//     const options = {
//       key: process.env.REACT_APP_RAZORPAY_KEY_ID,
//       subscription_id: data.id,
//       name: "Gyankosha",
//       description: "Payment for Quiz Subscription",
//       handler: async (response) => {
//         console.log("response", response);
//         try {
//           const res = await fetch(`/api/payment/verify`, {
//             method: "POST",
//             headers: {
//               "content-type": "application/json",
//             },
//             body: JSON.stringify({
//               razorpay_subscription_id: response.razorpay_subscription_id,
//               razorpay_payment_id: response.razorpay_payment_id,
//               razorpay_signature: response.razorpay_signature,
//               userId: currentUser1._id, // Pass user ID for verification
//               paidfor: selectedDivisions.map(
//                 (division, index) => `${division}-${selectedClasses[index]}`
//               ), // Store "Division-Class"
//             }),
//           });

//           const verifyData = await res.json();

//           if (verifyData.message) {
//             toast.success(verifyData.message);
//             console.log("verifyData.referralCode : ", verifyData.referralCode);
//             if (verifyData.referralCode) {
//               setReferralCode(verifyData.referralCode);
//               setShowModal(true);
//             }
//             // Reload the page after 2 seconds
//             // setTimeout(() => {
//             //   window.location.reload();
//             // }, 2000);
//           }
//         } catch (error) {
//           console.log(error);
//         } finally {
//           setLoadingPlan(null); // Reset loading state in case of error
//         }
//       },
//       theme: {
//         color: "#5f63b8",
//       },
//     };
//     const rzp1 = new window.Razorpay(options);
//     rzp1.open();
//   };

//   return (
//     <div
//       className="pricing-table"
//       style={{
//         fontFamily: '"Libre Baskerville", serif',
//         margin: "100px 50px 200px 50px",
//       }}
//     >
//       <h3>Select Divisions and Durations:</h3>
//       <table
//         className="table table-bordered"
//         style={{ borderColor: "#343a40", overflow: "visible" }}
//       >
//         <thead className="table-dark">
//           <tr>
//             <th></th>
//             <th>Select Division</th>
//             <th>Select Quiz Class</th>
//             <th>Select Duration</th>
//           </tr>
//         </thead>
//         <tbody>
//           {[0, 1, 2, 3].map((rowIndex) => (
//             <tr key={rowIndex}>
//               <td>Choose your vertical {rowIndex + 1} :</td>
//               <td>
//                 <select
//                   className="form-control"
//                   value={selectedDivisions[rowIndex]}
//                   onChange={(e) =>
//                     handleDivisionChange(rowIndex, e.target.value)
//                   }
//                 >
//                   <option value="">-- Select Division --</option>
//                   {divisions.map((division) => (
//                     <option key={division} value={division}>
//                       {division}
//                     </option>
//                   ))}
//                 </select>
//               </td>
//               <td>
//                 <select
//                   className="form-control"
//                   value={selectedClasses[rowIndex]}
//                   onChange={(e) => handleClassChange(rowIndex, e.target.value)}
//                   disabled={
//                     selectedDivisions[rowIndex] === "Abhyudaya" ||
//                     selectedDivisions[rowIndex] === "Kye Bye"
//                   }
//                 >
//                   <option value="">-- Select Class --</option>
//                   {classes.map((cls) => (
//                     <option key={cls} value={cls}>
//                       {cls}
//                     </option>
//                   ))}
//                 </select>
//               </td>
//               <td>
//                 <select
//                   className="form-control"
//                   value={selectedDurations[rowIndex]}
//                   onChange={(e) =>
//                     handleDurationChange(rowIndex, e.target.value)
//                   }
//                   //disabled={rowIndex !== 0 && !selectedDurations[0]}
//                 >
//                   <option value="">-- Select Duration --</option>
//                   {durations.map((duration) => (
//                     <option key={duration} value={duration}>
//                       {duration}
//                     </option>
//                   ))}
//                 </select>
//               </td>
//             </tr>
//           ))}
//         </tbody>
//       </table>

//       <div
//         style={{
//           display: "flex",
//           alignItems: "center",
//           gap: "15px",
//           marginTop: "10px",
//         }}
//       >
//         <span style={{ fontWeight: "bold" }}>Total Price: ₹{totalPrice}</span>
//         <button className="btn btn-primary" onClick={handleProceedToPayment}>
//           Proceed
//         </button>
//       </div>

//       <Modal show={showModal} onHide={handleCloseModal} centered>
//         <Modal.Header closeButton>
//           <Modal.Title>
//             Gyankosha's Subscription Active now and your Referral Code :{" "}
//             <span style={{ color: "red" }}>{referralCode}</span>
//           </Modal.Title>
//         </Modal.Header>

//         <Modal.Body>
//           <div style={{ fontFamily: "Arial, sans-serif", lineHeight: "1.6" }}>
//             <p>
//               Dear <strong>{currentUser1 ? currentUser1.name : ""}</strong>,
//             </p>
//             <p>
//               Thank you for choosing <strong>Gyankosha</strong> as your trusted
//               education partner. We are thrilled to have you on board! Your
//               subscription plan is now active, giving you access to the premium
//               content and resources.
//             </p>
//             <p>
//               Attached, you will find your unique Referral Code:{" "}
//               <strong style={{ color: "red" }}>{referralCode}</strong>.
//             </p>
//             <p>
//               As a token of appreciation for your support, when a new user
//               subscribes and makes their first payment using your referral code,
//               you become eligible for a <strong>20% cashback</strong> to your
//               account, and the one referred by you becomes eligible for a{" "}
//               <strong>10% cashback</strong> to their account. Please note that
//               the cashback is applicable only against the first payment made by
//               the referred user and is valid for one cycle only.
//             </p>
//             <p>
//               Let’s keep learning, growing, and making education exciting
//               together!
//             </p>
//             <p>Warm regards,</p>
//             <p>
//               <strong>The Gyankosha Team</strong>
//             </p>
//           </div>
//         </Modal.Body>

//         <Modal.Footer>
//           <Button variant="secondary" onClick={handleCloseModal}>
//             Close
//           </Button>
//         </Modal.Footer>
//       </Modal>

//       <Modal
//         show={showReferralModal}
//         onHide={() => {
//           setShowReferralModal(false);
//           setHasReferralCode(null); // Reset the referral code selection
//           setReferralInput(""); // Clear referral input field
//           setReferralCodeVerified(false); // Reset verification status
//           setVerifyingReferral(false); // Reset verifying state
//         }}
//         onExit={() => {
//           setShowReferralModal(false);
//           setHasReferralCode(null); // Reset the referral code selection
//           setReferralInput(""); // Clear referral input field
//           setReferralCodeVerified(false); // Reset verification status
//           setVerifyingReferral(false); // Reset verifying state
//         }}
//         centered
//       >
//         <Modal.Header closeButton>
//           {hasReferralCode === null && (
//             <Modal.Title
//               style={{ fontFamily: "Arial, sans-serif", fontWeight: "bold" }}
//             >
//               Do you have a referral code?
//             </Modal.Title>
//           )}
//         </Modal.Header>
//         <Modal.Body
//           style={{ fontFamily: "Arial, sans-serif", lineHeight: "1.6" }}
//         >
//           <div>
//             {hasReferralCode === null && (
//               <div className="d-flex flex-column align-items-start">
//                 <div className="form-check custom-radio mb-3">
//                   <input
//                     className="form-check-input"
//                     type="radio"
//                     name="referralCodeOption"
//                     id="referralYes"
//                     onChange={() => setHasReferralCode(true)}
//                   />
//                   <label
//                     className="form-check-label ms-2"
//                     htmlFor="referralYes"
//                   >
//                     Yes, I have a referral code
//                   </label>
//                 </div>
//                 <div className="form-check custom-radio">
//                   <input
//                     className="form-check-input"
//                     type="radio"
//                     name="referralCodeOption"
//                     id="referralNo"
//                     onChange={() => setHasReferralCode(false)}
//                   />
//                   <label className="form-check-label ms-2" htmlFor="referralNo">
//                     No, I don't have a referral code
//                   </label>
//                 </div>
//               </div>
//             )}
//             {hasReferralCode === true && !referralCodeVerified && (
//               <div>
//                 <label htmlFor="referralInput" style={{ fontWeight: "bold" }}>
//                   Enter your referral code:
//                 </label>
//                 <input
//                   type="text"
//                   className="form-control mt-2"
//                   id="referralInput"
//                   placeholder="Enter referral code here"
//                   value={referralInput}
//                   onChange={(e) => setReferralInput(e.target.value)}
//                   style={{ border: "1px solid #ced4da", borderRadius: "5px" }}
//                 />
//                 <button
//                   className="btn btn-primary mt-3 w-100"
//                   onClick={verifyReferralCode}
//                   disabled={verifyingReferral}
//                   style={{ boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)" }}
//                 >
//                   {verifyingReferral ? "Verifying..." : "Verify Referral Code"}
//                 </button>
//               </div>
//             )}
//             {referralCodeVerified && (
//               <div className="text-center">
//                 <p className="text-success" style={{ fontWeight: "bold" }}>
//                   Referral code verified successfully!
//                 </p>
//                 <button
//                   className="btn btn-success mt-3 w-100"
//                   onClick={() => {
//                     setShowReferralModal(false);
//                     handleSubscription(selectedPlanId, selectedTotalCount);
//                   }}
//                   style={{ boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)" }}
//                 >
//                   Proceed to Payment
//                 </button>
//               </div>
//             )}
//             {hasReferralCode === false && (
//               <div className="text-center">
//                 <p className="text-muted" style={{ fontWeight: "bold" }}>
//                   No referral code? No problem!
//                 </p>
//                 <button
//                   className="btn btn-success mt-3 w-100"
//                   onClick={() => {
//                     setShowReferralModal(false);
//                     handleSubscription(selectedPlanId, selectedTotalCount);
//                   }}
//                   style={{ boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)" }}
//                 >
//                   Proceed to Payment
//                 </button>
//               </div>
//             )}
//           </div>
//         </Modal.Body>
//       </Modal>
//     </div>
//   );
// };

// export default Purchasescreen;
/////////////////////////////////////////////////////////////////////////////////////

// import React, { useState, useEffect } from "react";
// import toast from "react-hot-toast";
// import Modal from "react-bootstrap/Modal";
// import { Button } from "react-bootstrap";
// import { useSelector } from "react-redux";

// const Purchasescreen = () => {
//   const [selectedDurations, setSelectedDurations] = useState(["", "", "", ""]);
//   const [selectedDivisions, setSelectedDivisions] = useState(["", "", "", ""]);
//   const [selectedClasses, setSelectedClasses] = useState(["", "", "", ""]); // New State for Class
//   const [totalPrice, setTotalPrice] = useState(0);
//   const [selectedPlanId, setSelectedPlanId] = useState(null);
//   const [selectedTotalCount, setSelectedTotalCount] = useState(null);
//   const [showReferralModal, setShowReferralModal] = useState(false);
//   const [referralCodeVerified, setReferralCodeVerified] = useState(false);
//   const [verifyingReferral, setVerifyingReferral] = useState(false);
//   const [referralInput, setReferralInput] = useState("");
//   const [loadingPlan, setLoadingPlan] = useState(null);

//   const [showModal, setShowModal] = useState(false);
//   const [referralCode, setReferralCode] = useState(null);
//   const [hasReferralCode, setHasReferralCode] = useState(null); // Track Yes/No selection

//   const userstate = useSelector((state) => state.loginUserReducer);
//   const { currentUser1 } = userstate;

//   const durations = ["Monthly", "Quarterly", "Half Yearly", "Yearly"];
//   const divisions = ["Udbodh", "Bauddha Dakshata", "Kye Bye", "Abhyudaya"];
//   const classes = [...Array(12).keys()]
//     .map((num) => (num + 1).toString())
//     .concat(["Already passed 12th"]);

//   const plans = {
//     Udbodh: {
//       Monthly: 300,
//       Quarterly: 878,
//       "Half Yearly": 1710,
//       Yearly: 3240,
//     },
//     "Bauddha Dakshata": {
//       Monthly: 500,
//       Quarterly: 1463,
//       "Half Yearly": 2850,
//       Yearly: 5400,
//     },
//     "Kye Bye": {
//       Monthly: 500,
//       Quarterly: 1463,
//       "Half Yearly": 2850,
//       Yearly: 5400,
//     },
//     Abhyudaya: {
//       Monthly: 1000, //process.env.REACT_APP_PLAN_ID_ABHYUDAYA_MONTHLY,
//       Quarterly: 2925, //process.env.REACT_APP_PLAN_ID_ABHYUDAYA_QUARTERLY,
//       "Half Yearly": 5700, //process.env.REACT_APP_PLAN_ID_ABHYUDAYA_HALFYEARLY,
//       Yearly: 10800, //process.env.REACT_APP_PLAN_ID_ABHYUDAYA_ANNUALLY,
//     },
//     "Udbodh + Bauddha Dakshata": {
//       Monthly: 700, //process.env.REACT_APP_PLAN_ID_COMBO_ONE_MONTHLY,
//       Quarterly: 2048, //process.env.REACT_APP_PLAN_ID_COMBO_ONE_QUARTERLY,
//       "Half Yearly": 3990, //process.env.REACT_APP_PLAN_ID_COMBO_ONE_HALFYEARLY,
//       Yearly: 7560, //process.env.REACT_APP_PLAN_ID_COMBO_ONE_ANNUALLY,
//     },
//     "Bauddha Dakshata + Udbodh": {
//       Monthly: 700, //process.env.REACT_APP_PLAN_ID_COMBO_ONE_MONTHLY,
//       Quarterly: 2048, //process.env.REACT_APP_PLAN_ID_COMBO_ONE_QUARTERLY,
//       "Half Yearly": 3990, //process.env.REACT_APP_PLAN_ID_COMBO_ONE_HALFYEARLY,
//       Yearly: 7560, //process.env.REACT_APP_PLAN_ID_COMBO_ONE_ANNUALLY,
//     },
//     "Udbodh + Kye Bye": {
//       Monthly: 700, //process.env.REACT_APP_PLAN_ID_COMBO_TWO_MONTHLY,
//       Quarterly: 2048, //process.env.REACT_APP_PLAN_ID_COMBO_TWO_QUARTERLY,
//       "Half Yearly": 3990, //process.env.REACT_APP_PLAN_ID_COMBO_TWO_HALFYEARLY,
//       Yearly: 7560, //process.env.REACT_APP_PLAN_ID_COMBO_TWO_ANNUALLY,
//     },
//     "Kye Bye + Udbodh": {
//       Monthly: 700, //process.env.REACT_APP_PLAN_ID_COMBO_TWO_MONTHLY,
//       Quarterly: 2048, //process.env.REACT_APP_PLAN_ID_COMBO_TWO_QUARTERLY,
//       "Half Yearly": 3990, //process.env.REACT_APP_PLAN_ID_COMBO_TWO_HALFYEARLY,
//       Yearly: 7560, //process.env.REACT_APP_PLAN_ID_COMBO_TWO_ANNUALLY,
//     },
//     "Udbodh + Abhyudaya": {
//       Monthly: 1200, //process.env.REACT_APP_PLAN_ID_COMBO_THREE_MONTHLY,
//       Quarterly: 3510, //process.env.REACT_APP_PLAN_ID_COMBO_THREE_QUARTERLY,
//       "Half Yearly": 6840, //process.env.REACT_APP_PLAN_ID_COMBO_THREE_HALFYEARLY,
//       Yearly: 12960, //process.env.REACT_APP_PLAN_ID_COMBO_THREE_ANNUALLY,
//     },
//     "Abhyudaya + Udbodh": {
//       Monthly: 1200, //process.env.REACT_APP_PLAN_ID_COMBO_THREE_MONTHLY,
//       Quarterly: 3510, //process.env.REACT_APP_PLAN_ID_COMBO_THREE_QUARTERLY,
//       "Half Yearly": 6840, //process.env.REACT_APP_PLAN_ID_COMBO_THREE_HALFYEARLY,
//       Yearly: 12960, //process.env.REACT_APP_PLAN_ID_COMBO_THREE_ANNUALLY,
//     },
//     "Bauddha Dakshata + Kye Bye": {
//       Monthly: 900, //process.env.REACT_APP_PLAN_ID_COMBO_FOUR_MONTHLY,
//       Quarterly: 2633, //process.env.REACT_APP_PLAN_ID_COMBO_FOUR_QUARTERLY,
//       "Half Yearly": 5130, //process.env.REACT_APP_PLAN_ID_COMBO_FOUR_HALFYEARLY,
//       Yearly: 9720, //process.env.REACT_APP_PLAN_ID_COMBO_FOUR_ANNUALLY,
//     },
//     "Kye Bye + Bauddha Dakshata": {
//       Monthly: 900, //process.env.REACT_APP_PLAN_ID_COMBO_FOUR_MONTHLY,
//       Quarterly: 2633, //process.env.REACT_APP_PLAN_ID_COMBO_FOUR_QUARTERLY,
//       "Half Yearly": 5130, //process.env.REACT_APP_PLAN_ID_COMBO_FOUR_HALFYEARLY,
//       Yearly: 9720, //process.env.REACT_APP_PLAN_ID_COMBO_FOUR_ANNUALLY,
//     },
//     "Bauddha Dakshata + Abhyudaya": {
//       Monthly: 1400, //process.env.REACT_APP_PLAN_ID_COMBO_FIVE_MONTHLY,
//       Quarterly: 4095, //process.env.REACT_APP_PLAN_ID_COMBO_FIVE_QUARTERLY,
//       "Half Yearly": 7980, //process.env.REACT_APP_PLAN_ID_COMBO_FIVE_HALFYEARLY,
//       Yearly: 15120, //process.env.REACT_APP_PLAN_ID_COMBO_FIVE_ANNUALLY,
//     },
//     "Abhyudaya + Bauddha Dakshata": {
//       Monthly: 1400, //process.env.REACT_APP_PLAN_ID_COMBO_FIVE_MONTHLY,
//       Quarterly: 4095, //process.env.REACT_APP_PLAN_ID_COMBO_FIVE_QUARTERLY,
//       "Half Yearly": 7980, //process.env.REACT_APP_PLAN_ID_COMBO_FIVE_HALFYEARLY,
//       Yearly: 15120, //process.env.REACT_APP_PLAN_ID_COMBO_FIVE_ANNUALLY,
//     },
//     "Kye Bye + Abhyudaya": {
//       Monthly: 1400, //process.env.REACT_APP_PLAN_ID_COMBO_SIX_MONTHLY,
//       Quarterly: 4095, //process.env.REACT_APP_PLAN_ID_COMBO_SIX_QUARTERLY,
//       "Half Yearly": 7980, //process.env.REACT_APP_PLAN_ID_COMBO_SIX_HALFYEARLY,
//       Yearly: 15120, //process.env.REACT_APP_PLAN_ID_COMBO_SIX_ANNUALLY,
//     },
//     "Abhyudaya + Kye Bye": {
//       Monthly: 1400, //process.env.REACT_APP_PLAN_ID_COMBO_SIX_MONTHLY,
//       Quarterly: 4095, //process.env.REACT_APP_PLAN_ID_COMBO_SIX_QUARTERLY,
//       "Half Yearly": 7980, //process.env.REACT_APP_PLAN_ID_COMBO_SIX_HALFYEARLY,
//       Yearly: 15120, //process.env.REACT_APP_PLAN_ID_COMBO_SIX_ANNUALLY,
//     },
//     "Udbodh + Bauddha Dakshata + Kye Bye": {
//       Monthly: 1150, //process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_MONTHLY,
//       Quarterly: 3364, //process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_QUARTERLY,
//       "Half Yearly": 6555, //process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_HALFYEARLY,
//       Yearly: 12420, //process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_ANNUALLY,
//     },
//     "Udbodh + Kye Bye + Bauddha Dakshata": {
//       Monthly: 1150, //process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_MONTHLY,
//       Quarterly: 3364, //process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_QUARTERLY,
//       "Half Yearly": 6555, //process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_HALFYEARLY,
//       Yearly: 12420, //process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_ANNUALLY,
//     },
//     "Bauddha Dakshata + Kye Bye + Udbodh": {
//       Monthly: 1150, //process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_MONTHLY,
//       Quarterly: 3364, //process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_QUARTERLY,
//       "Half Yearly": 6555, //process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_HALFYEARLY,
//       Yearly: 12420, //process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_ANNUALLY,
//     },
//     "Bauddha Dakshata + Udbodh + Kye Bye": {
//       Monthly: 1150, //process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_MONTHLY,
//       Quarterly: 3364, //process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_QUARTERLY,
//       "Half Yearly": 6555, //process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_HALFYEARLY,
//       Yearly: 12420, //process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_ANNUALLY,
//     },
//     "Kye Bye + Udbodh + Bauddha Dakshata": {
//       Monthly: 1150, //process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_MONTHLY,
//       Quarterly: 3364, //process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_QUARTERLY,
//       "Half Yearly": 6555, //process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_HALFYEARLY,
//       Yearly: 12420, //process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_ANNUALLY,
//     },
//     "Kye Bye + Bauddha Dakshata + Udbodh": {
//       Monthly: 1150, //process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_MONTHLY,
//       Quarterly: 3364, //process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_QUARTERLY,
//       "Half Yearly": 6555, //process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_HALFYEARLY,
//       Yearly: 12420, //process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_ANNUALLY,
//     },
//     "Udbodh + Bauddha Dakshata + Kye Bye + Abhyudaya": {
//       Monthly: 2100, //process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: 6143, //process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": 11970, //process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: 22680, //process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Udbodh + Bauddha Dakshata + Abhyudaya + Kye Bye": {
//       Monthly: 2100, //process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: 6143, //process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": 11970, //process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: 22680, //process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Udbodh + Kye Bye + Bauddha Dakshata + Abhyudaya": {
//       Monthly: 2100, //process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: 6143, //process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": 11970, //process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: 22680, //process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Udbodh + Kye Bye + Abhyudaya + Bauddha Dakshata": {
//       Monthly: 2100, //process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: 6143, //process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": 11970, //process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: 22680, //process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Udbodh + Abhyudaya + Bauddha Dakshata + Kye Bye": {
//       Monthly: 2100, //process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: 6143, //process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": 11970, //process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: 22680, //process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Udbodh + Abhyudaya + Kye Bye + Bauddha Dakshata": {
//       Monthly: 2100, //process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: 6143, //process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": 11970, //process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: 22680, //process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Bauddha Dakshata + Udbodh + Kye Bye + Abhyudaya": {
//       Monthly: 2100, //process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: 6143, //process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": 11970, //process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: 22680, //process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Bauddha Dakshata + Udbodh + Abhyudaya + Kye Bye": {
//       Monthly: 2100, //process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: 6143, //process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": 11970, //process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: 22680, //process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Bauddha Dakshata + Kye Bye + Udbodh + Abhyudaya": {
//       Monthly: 2100, //process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: 6143, //process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": 11970, //process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: 22680, //process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Bauddha Dakshata + Kye Bye + Abhyudaya + Udbodh": {
//       Monthly: 2100, //process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: 6143, //process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": 11970, //process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: 22680, //process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Bauddha Dakshata + Abhyudaya + Udbodh + Kye Bye": {
//       Monthly: 2100, //process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: 6143, //process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": 11970, //process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: 22680, //process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Bauddha Dakshata + Abhyudaya + Kye Bye + Udbodh": {
//       Monthly: 2100, //process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: 6143, //process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": 11970, //process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: 22680, //process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Kye Bye + Udbodh + Bauddha Dakshata + Abhyudaya": {
//       Monthly: 2100, //process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: 6143, //process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": 11970, //process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: 22680, //process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Kye Bye + Udbodh + Abhyudaya + Bauddha Dakshata": {
//       Monthly: 2100, //process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: 6143, //process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": 11970, //process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: 22680, //process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Kye Bye + Bauddha Dakshata + Udbodh + Abhyudaya": {
//       Monthly: 2100, //process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: 6143, //process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": 11970, //process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: 22680, //process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Kye Bye + Bauddha Dakshata + Abhyudaya + Udbodh": {
//       Monthly: 2100, //process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: 6143, //process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": 11970, //process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: 22680, //process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Kye Bye + Abhyudaya + Udbodh + Bauddha Dakshata": {
//       Monthly: 2100, //process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: 6143, //process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": 11970, //process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: 22680, //process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Kye Bye + Abhyudaya + Bauddha Dakshata + Udbodh": {
//       Monthly: 2100, //process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: 6143, //process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": 11970, //process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: 22680, //process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Abhyudaya + Udbodh + Bauddha Dakshata + Kye Bye": {
//       Monthly: 2100, //process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: 6143, //process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": 11970, //process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: 22680, //process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Abhyudaya + Udbodh + Kye Bye + Bauddha Dakshata": {
//       Monthly: 2100, //process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: 6143, //process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": 11970, //process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: 22680, //process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Abhyudaya + Bauddha Dakshata + Udbodh + Kye Bye": {
//       Monthly: 2100, //process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: 6143, //process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": 11970, //process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: 22680, //process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Abhyudaya + Bauddha Dakshata + Kye Bye + Udbodh": {
//       Monthly: 2100, //process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: 6143, //process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": 11970, //process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: 22680, //process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Abhyudaya + Kye Bye + Udbodh + Bauddha Dakshata": {
//       Monthly: 2100, //process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: 6143, //process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": 11970, //process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: 22680, //process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Abhyudaya + Kye Bye + Bauddha Dakshata + Udbodh": {
//       Monthly: 2100, //process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: 6143, //process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": 11970, //process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: 22680, //process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//   };

//   const planIds = {
//     Udbodh: {
//       Monthly: process.env.REACT_APP_PLAN_ID_UDBODH_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_UDBODH_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_UDBODH_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_UDBODH_ANNUALLY,
//     },
//     "Bauddha Dakshata": {
//       Monthly: process.env.REACT_APP_PLAN_ID_BD_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_BD_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_BD_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_BD_ANNUALLY,
//     },
//     "Kye Bye": {
//       Monthly: process.env.REACT_APP_PLAN_ID_KYEBYE_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_KYEBYE_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_KYEBYE_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_KYEBYE_ANNUALLY,
//     },
//     Abhyudaya: {
//       Monthly: process.env.REACT_APP_PLAN_ID_ABHYUDAYA_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_ABHYUDAYA_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_ABHYUDAYA_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_ABHYUDAYA_ANNUALLY,
//     },
//     "Udbodh + Bauddha Dakshata": {
//       Monthly: process.env.REACT_APP_PLAN_ID_COMBO_ONE_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_COMBO_ONE_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_COMBO_ONE_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_COMBO_ONE_ANNUALLY,
//     },
//     "Bauddha Dakshata + Udbodh": {
//       Monthly: process.env.REACT_APP_PLAN_ID_COMBO_ONE_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_COMBO_ONE_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_COMBO_ONE_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_COMBO_ONE_ANNUALLY,
//     },
//     "Udbodh + Kye Bye": {
//       Monthly: process.env.REACT_APP_PLAN_ID_COMBO_TWO_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_COMBO_TWO_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_COMBO_TWO_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_COMBO_TWO_ANNUALLY,
//     },
//     "Kye Bye + Udbodh": {
//       Monthly: process.env.REACT_APP_PLAN_ID_COMBO_TWO_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_COMBO_TWO_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_COMBO_TWO_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_COMBO_TWO_ANNUALLY,
//     },
//     "Udbodh + Abhyudaya": {
//       Monthly: process.env.REACT_APP_PLAN_ID_COMBO_THREE_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_COMBO_THREE_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_COMBO_THREE_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_COMBO_THREE_ANNUALLY,
//     },
//     "Abhyudaya + Udbodh": {
//       Monthly: process.env.REACT_APP_PLAN_ID_COMBO_THREE_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_COMBO_THREE_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_COMBO_THREE_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_COMBO_THREE_ANNUALLY,
//     },
//     "Bauddha Dakshata + Kye Bye": {
//       Monthly: process.env.REACT_APP_PLAN_ID_COMBO_FOUR_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_COMBO_FOUR_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_COMBO_FOUR_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_COMBO_FOUR_ANNUALLY,
//     },
//     "Kye Bye + Bauddha Dakshata": {
//       Monthly: process.env.REACT_APP_PLAN_ID_COMBO_FOUR_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_COMBO_FOUR_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_COMBO_FOUR_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_COMBO_FOUR_ANNUALLY,
//     },
//     "Bauddha Dakshata + Abhyudaya": {
//       Monthly: process.env.REACT_APP_PLAN_ID_COMBO_FIVE_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_COMBO_FIVE_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_COMBO_FIVE_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_COMBO_FIVE_ANNUALLY,
//     },
//     "Abhyudaya + Bauddha Dakshata": {
//       Monthly: process.env.REACT_APP_PLAN_ID_COMBO_FIVE_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_COMBO_FIVE_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_COMBO_FIVE_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_COMBO_FIVE_ANNUALLY,
//     },
//     "Kye Bye + Abhyudaya": {
//       Monthly: process.env.REACT_APP_PLAN_ID_COMBO_SIX_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_COMBO_SIX_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_COMBO_SIX_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_COMBO_SIX_ANNUALLY,
//     },
//     "Abhyudaya + Kye Bye": {
//       Monthly: process.env.REACT_APP_PLAN_ID_COMBO_SIX_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_COMBO_SIX_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_COMBO_SIX_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_COMBO_SIX_ANNUALLY,
//     },
//     "Udbodh + Bauddha Dakshata + Kye Bye": {
//       Monthly: process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_ANNUALLY,
//     },
//     "Udbodh + Kye Bye + Bauddha Dakshata": {
//       Monthly: process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_ANNUALLY,
//     },
//     "Bauddha Dakshata + Kye Bye + Udbodh": {
//       Monthly: process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_ANNUALLY,
//     },
//     "Bauddha Dakshata + Udbodh + Kye Bye": {
//       Monthly: process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_ANNUALLY,
//     },
//     "Kye Bye + Udbodh + Bauddha Dakshata": {
//       Monthly: process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_ANNUALLY,
//     },
//     "Kye Bye + Bauddha Dakshata + Udbodh": {
//       Monthly: process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_ANNUALLY,
//     },
//     "Udbodh + Bauddha Dakshata + Kye Bye + Abhyudaya": {
//       Monthly: process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Udbodh + Bauddha Dakshata + Abhyudaya + Kye Bye": {
//       Monthly: process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Udbodh + Kye Bye + Bauddha Dakshata + Abhyudaya": {
//       Monthly: process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Udbodh + Kye Bye + Abhyudaya + Bauddha Dakshata": {
//       Monthly: process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Udbodh + Abhyudaya + Bauddha Dakshata + Kye Bye": {
//       Monthly: process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Udbodh + Abhyudaya + Kye Bye + Bauddha Dakshata": {
//       Monthly: process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Bauddha Dakshata + Udbodh + Kye Bye + Abhyudaya": {
//       Monthly: process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Bauddha Dakshata + Udbodh + Abhyudaya + Kye Bye": {
//       Monthly: process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Bauddha Dakshata + Kye Bye + Udbodh + Abhyudaya": {
//       Monthly: process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Bauddha Dakshata + Kye Bye + Abhyudaya + Udbodh": {
//       Monthly: process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Bauddha Dakshata + Abhyudaya + Udbodh + Kye Bye": {
//       Monthly: process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Bauddha Dakshata + Abhyudaya + Kye Bye + Udbodh": {
//       Monthly: process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Kye Bye + Udbodh + Bauddha Dakshata + Abhyudaya": {
//       Monthly: process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Kye Bye + Udbodh + Abhyudaya + Bauddha Dakshata": {
//       Monthly: process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Kye Bye + Bauddha Dakshata + Udbodh + Abhyudaya": {
//       Monthly: process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Kye Bye + Bauddha Dakshata + Abhyudaya + Udbodh": {
//       Monthly: process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Kye Bye + Abhyudaya + Udbodh + Bauddha Dakshata": {
//       Monthly: process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Kye Bye + Abhyudaya + Bauddha Dakshata + Udbodh": {
//       Monthly: process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Abhyudaya + Udbodh + Bauddha Dakshata + Kye Bye": {
//       Monthly: process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Abhyudaya + Udbodh + Kye Bye + Bauddha Dakshata": {
//       Monthly: process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Abhyudaya + Bauddha Dakshata + Udbodh + Kye Bye": {
//       Monthly: process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Abhyudaya + Bauddha Dakshata + Kye Bye + Udbodh": {
//       Monthly: process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Abhyudaya + Kye Bye + Udbodh + Bauddha Dakshata": {
//       Monthly: process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Abhyudaya + Kye Bye + Bauddha Dakshata + Udbodh": {
//       Monthly: process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },

//     // Add other combinations here...
//   };

//   const totalCounts = {
//     Yearly: 8,
//     Quarterly: 32,
//     "Half Yearly": 16,
//     Monthly: 96,
//   };

//   // Handle modal close event
//   const handleCloseModal = () => {
//     setShowModal(false);

//     // Trigger page reload only when modal is closed
//     window.location.reload();
//   };

//   const handleDivisionChange = (index, value) => {
//     const updatedDivisions = [...selectedDivisions];
//     updatedDivisions[index] = value;
//     setSelectedDivisions(updatedDivisions);
//     calculateTotalPrice(updatedDivisions, selectedDurations);

//     const updatedClasses = [...selectedClasses];

//     if (value === "Abhyudaya" || value === "Kye Bye") {
//       updatedClasses[index] = "Already passed 12th";
//     } else if (value === "Udbodh" || value === "Bauddha Dakshata") {
//       // Find if another row already has Udbodh or Bauddha Dakshata with a selected class
//       const matchingIndex = selectedDivisions.findIndex(
//         (div, i) =>
//           i !== index &&
//           (div === "Udbodh" || div === "Bauddha Dakshata") &&
//           selectedClasses[i]
//       );

//       // If found, update this row's class to match
//       if (matchingIndex !== -1) {
//         updatedClasses[index] = selectedClasses[matchingIndex];
//       }
//     }

//     setSelectedClasses(updatedClasses);
//   };

//   const handleClassChange = (index, value) => {
//     const updatedClasses = [...selectedClasses];
//     updatedClasses[index] = value;

//     // If this row belongs to Udbodh or Bauddha Dakshata, update all similar divisions
//     if (
//       selectedDivisions[index] === "Udbodh" ||
//       selectedDivisions[index] === "Bauddha Dakshata"
//     ) {
//       selectedDivisions.forEach((div, i) => {
//         if (i !== index && (div === "Udbodh" || div === "Bauddha Dakshata")) {
//           updatedClasses[i] = value; // Sync all similar divisions
//         }
//       });
//     }

//     setSelectedClasses(updatedClasses);
//   };

//   const handleDurationChange = (index, value) => {
//     const updatedDurations = selectedDurations.map(() => value); // Set all to the same value
//     setSelectedDurations(updatedDurations);
//     calculateTotalPrice(selectedDivisions, updatedDurations);
//   };

//   const calculateTotalPrice = (divisions, durations) => {
//     let price = 0;
//     let planKey = divisions.filter((d) => d).join(" + "); // Join only non-empty values

//     if (planKey && plans[planKey] && durations[0]) {
//       price = plans[planKey][durations[0]] || 0;
//     }

//     setTotalPrice(price);
//     setSelectedPlanId(planIds[planKey]?.[durations[0]] || null);
//     setSelectedTotalCount(totalCounts[durations[0]] || null);
//   };

//   const handleProceedToPayment = () => {
//     if (!selectedPlanId || !selectedTotalCount) {
//       toast.error("Please complete your selections.");
//       return;
//     }

//     setShowReferralModal(true);
//     setReferralCodeVerified(false);
//   };

//   const verifyReferralCode = async () => {
//     setVerifyingReferral(true);
//     try {
//       const res = await fetch(`/api/users/verifyReferral`, {
//         method: "POST",
//         headers: { "Content-Type": "application/json" },
//         body: JSON.stringify({ referralCode: referralInput.trim() }),
//       });
//       const data = await res.json();

//       if (data.success) {
//         setReferralCodeVerified(true);
//         toast.success("Referral code verified successfully!");
//       } else {
//         toast.error("Invalid referral code.");
//       }
//     } catch (error) {
//       toast.error("Failed to verify referral code.");
//     } finally {
//       setVerifyingReferral(false);
//     }
//   };

//   // handleSubscription Function
//   const handleSubscription = async (plan_id, total_count) => {
//     setLoadingPlan(plan_id); // Set loading for the clicked plan
//     try {
//       const res = await fetch(`/api/payment/subscription`, {
//         method: "POST",
//         headers: {
//           "content-type": "application/json",
//         },
//         body: JSON.stringify({
//           plan_id,
//           userId: currentUser1._id, // Pass user ID to backend
//           total_count,
//           referralCode: referralInput.trim(), // Include referral code in request
//         }),
//       });

//       const data = await res.json();
//       console.log(data);
//       handlePaymentVerify(data.data);
//       // setReferralCode(data.referralCode); // Set referral code if returned
//       // setShowModal(true);
//       setLoadingPlan(null); // Reset loading state in case of error
//     } catch (error) {
//       console.log(error);
//       setLoadingPlan(null); // Reset loading state in case of error
//     }
//   };

//   // handlePaymentVerify Function
//   const handlePaymentVerify = async (data) => {
//     const options = {
//       key: process.env.REACT_APP_RAZORPAY_KEY_ID,
//       subscription_id: data.id,
//       name: "Gyankosha",
//       description: "Payment for Quiz Subscription",
//       handler: async (response) => {
//         console.log("response", response);
//         try {
//           const res = await fetch(`/api/payment/verify`, {
//             method: "POST",
//             headers: {
//               "content-type": "application/json",
//             },
//             body: JSON.stringify({
//               razorpay_subscription_id: response.razorpay_subscription_id,
//               razorpay_payment_id: response.razorpay_payment_id,
//               razorpay_signature: response.razorpay_signature,
//               userId: currentUser1._id, // Pass user ID for verification
//               paidfor: selectedDivisions.map(
//                 (division, index) => `${division}-${selectedClasses[index]}`
//               ), // Store "Division-Class"
//             }),
//           });

//           const verifyData = await res.json();

//           if (verifyData.message) {
//             toast.success(verifyData.message);
//             console.log("verifyData.referralCode : ", verifyData.referralCode);
//             if (verifyData.referralCode) {
//               setReferralCode(verifyData.referralCode);
//               setShowModal(true);
//             }
//             // Reload the page after 2 seconds
//             // setTimeout(() => {
//             //   window.location.reload();
//             // }, 2000);
//           }
//         } catch (error) {
//           console.log(error);
//         } finally {
//           setLoadingPlan(null); // Reset loading state in case of error
//         }
//       },
//       theme: {
//         color: "#5f63b8",
//       },
//     };
//     const rzp1 = new window.Razorpay(options);
//     rzp1.open();
//   };

//   const getIndividualPrice = (division, duration) => {
//     if (division && duration && plans[division]) {
//       return plans[division][duration] || 0; // Return price or 0 if not found
//     }
//     return 0;
//   };

//   return (
//     <div className="row justify-content-center">
//       <div className="col-md-9">
//         <div
//           className="pricing-table"
//           style={{
//             fontFamily: '"Libre Baskerville", serif',
//             margin: "100px 25px 200px 25px",
//           }}
//         >
//           <h3>Purchase Premium Quizzes:</h3>
//           <div className="table-responsive">
//             <table
//               className="table table-bordered"
//               style={{ borderColor: "#343a40", overflow: "visible" }}
//             >
//               <thead className="table-dark">
//                 <tr>
//                   <th></th>
//                   <th>Select Division</th>
//                   <th>Select Quiz Class</th>
//                   <th>Select Duration</th>
//                   <th>Individual Price</th> {/* New Column */}
//                 </tr>
//               </thead>
//               <tbody>
//                 {[0, 1, 2, 3].map((rowIndex) => (
//                   <tr key={rowIndex}>
//                     <td>Choose your vertical {rowIndex + 1} :</td>
//                     <td>
//                       <select
//                         className="form-control"
//                         value={selectedDivisions[rowIndex]}
//                         onChange={(e) =>
//                           handleDivisionChange(rowIndex, e.target.value)
//                         }
//                       >
//                         <option value="">-- Select Division --</option>
//                         {divisions.map((division) => (
//                           <option key={division} value={division}>
//                             {division}
//                           </option>
//                         ))}
//                       </select>
//                     </td>
//                     <td>
//                       <select
//                         className="form-control"
//                         value={selectedClasses[rowIndex]}
//                         onChange={(e) =>
//                           handleClassChange(rowIndex, e.target.value)
//                         }
//                         disabled={
//                           selectedDivisions[rowIndex] === "Abhyudaya" ||
//                           selectedDivisions[rowIndex] === "Kye Bye"
//                         }
//                       >
//                         <option value="">-- Select Class --</option>
//                         {classes.map((cls) => (
//                           <option key={cls} value={cls}>
//                             {cls}
//                           </option>
//                         ))}
//                       </select>
//                     </td>
//                     <td>
//                       <select
//                         className="form-control"
//                         value={selectedDurations[rowIndex]}
//                         onChange={(e) =>
//                           handleDurationChange(rowIndex, e.target.value)
//                         }
//                         //disabled={rowIndex !== 0 && !selectedDurations[0]}
//                       >
//                         <option value="">-- Select Duration --</option>
//                         {durations.map((duration) => (
//                           <option key={duration} value={duration}>
//                             {duration}
//                           </option>
//                         ))}
//                       </select>
//                     </td>
//                     <td>
//                       ₹
//                       {getIndividualPrice(
//                         selectedDivisions[rowIndex],
//                         selectedDurations[rowIndex]
//                       )}
//                     </td>{" "}
//                     {/* New Column */}
//                   </tr>
//                 ))}
//               </tbody>
//             </table>
//           </div>

//           <div
//             style={{
//               display: "flex",
//               alignItems: "center",
//               gap: "15px",
//               marginTop: "10px",
//             }}
//           >
//             <span style={{ fontWeight: "bold" }}>
//               Total Price: ₹{totalPrice}
//             </span>
//             <button
//               className="btn btn-primary"
//               onClick={handleProceedToPayment}
//             >
//               Proceed
//             </button>
//           </div>

//           <Modal show={showModal} onHide={handleCloseModal} centered>
//             <Modal.Header closeButton>
//               <Modal.Title>
//                 Gyankosha's Subscription Active now and your Referral Code :{" "}
//                 <span style={{ color: "red" }}>{referralCode}</span>
//               </Modal.Title>
//             </Modal.Header>

//             <Modal.Body>
//               <div
//                 style={{ fontFamily: "Arial, sans-serif", lineHeight: "1.6" }}
//               >
//                 <p>
//                   Dear <strong>{currentUser1 ? currentUser1.name : ""}</strong>,
//                 </p>
//                 <p>
//                   Thank you for choosing <strong>Gyankosha</strong> as your
//                   trusted education partner. We are thrilled to have you on
//                   board! Your subscription plan is now active, giving you access
//                   to the premium content and resources.
//                 </p>
//                 <p>
//                   Attached, you will find your unique Referral Code:{" "}
//                   <strong style={{ color: "red" }}>{referralCode}</strong>.
//                 </p>
//                 <p>
//                   As a token of appreciation for your support, when a new user
//                   subscribes and makes their first payment using your referral
//                   code, you become eligible for a <strong>20% cashback</strong>{" "}
//                   to your account, and the one referred by you becomes eligible
//                   for a <strong>10% cashback</strong> to their account. Please
//                   note that the cashback is applicable only against the first
//                   payment made by the referred user and is valid for one cycle
//                   only.
//                 </p>
//                 <p>
//                   Let’s keep learning, growing, and making education exciting
//                   together!
//                 </p>
//                 <p>Warm regards,</p>
//                 <p>
//                   <strong>The Gyankosha Team</strong>
//                 </p>
//               </div>
//             </Modal.Body>

//             <Modal.Footer>
//               <Button variant="secondary" onClick={handleCloseModal}>
//                 Close
//               </Button>
//             </Modal.Footer>
//           </Modal>

//           <Modal
//             show={showReferralModal}
//             onHide={() => {
//               setShowReferralModal(false);
//               setHasReferralCode(null); // Reset the referral code selection
//               setReferralInput(""); // Clear referral input field
//               setReferralCodeVerified(false); // Reset verification status
//               setVerifyingReferral(false); // Reset verifying state
//             }}
//             onExit={() => {
//               setShowReferralModal(false);
//               setHasReferralCode(null); // Reset the referral code selection
//               setReferralInput(""); // Clear referral input field
//               setReferralCodeVerified(false); // Reset verification status
//               setVerifyingReferral(false); // Reset verifying state
//             }}
//             centered
//           >
//             <Modal.Header closeButton>
//               {hasReferralCode === null && (
//                 <Modal.Title
//                   style={{
//                     fontFamily: "Arial, sans-serif",
//                     fontWeight: "bold",
//                   }}
//                 >
//                   Do you have a referral code?
//                 </Modal.Title>
//               )}
//             </Modal.Header>
//             <Modal.Body
//               style={{ fontFamily: "Arial, sans-serif", lineHeight: "1.6" }}
//             >
//               <div>
//                 {hasReferralCode === null && (
//                   <div className="d-flex flex-column align-items-start">
//                     <div className="form-check custom-radio mb-3">
//                       <input
//                         className="form-check-input"
//                         type="radio"
//                         name="referralCodeOption"
//                         id="referralYes"
//                         onChange={() => setHasReferralCode(true)}
//                       />
//                       <label
//                         className="form-check-label ms-2"
//                         htmlFor="referralYes"
//                       >
//                         Yes, I have a referral code
//                       </label>
//                     </div>
//                     <div className="form-check custom-radio">
//                       <input
//                         className="form-check-input"
//                         type="radio"
//                         name="referralCodeOption"
//                         id="referralNo"
//                         onChange={() => setHasReferralCode(false)}
//                       />
//                       <label
//                         className="form-check-label ms-2"
//                         htmlFor="referralNo"
//                       >
//                         No, I don't have a referral code
//                       </label>
//                     </div>
//                   </div>
//                 )}
//                 {hasReferralCode === true && !referralCodeVerified && (
//                   <div>
//                     <label
//                       htmlFor="referralInput"
//                       style={{ fontWeight: "bold" }}
//                     >
//                       Enter your referral code:
//                     </label>
//                     <input
//                       type="text"
//                       className="form-control mt-2"
//                       id="referralInput"
//                       placeholder="Enter referral code here"
//                       value={referralInput}
//                       onChange={(e) => setReferralInput(e.target.value)}
//                       style={{
//                         border: "1px solid #ced4da",
//                         borderRadius: "5px",
//                       }}
//                     />
//                     <button
//                       className="btn btn-primary mt-3 w-100"
//                       onClick={verifyReferralCode}
//                       disabled={verifyingReferral}
//                       style={{ boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)" }}
//                     >
//                       {verifyingReferral
//                         ? "Verifying..."
//                         : "Verify Referral Code"}
//                     </button>
//                   </div>
//                 )}
//                 {referralCodeVerified && (
//                   <div className="text-center">
//                     <p className="text-success" style={{ fontWeight: "bold" }}>
//                       Referral code verified successfully!
//                     </p>
//                     <button
//                       className="btn btn-success mt-3 w-100"
//                       onClick={() => {
//                         setShowReferralModal(false);
//                         handleSubscription(selectedPlanId, selectedTotalCount);
//                       }}
//                       style={{ boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)" }}
//                     >
//                       Proceed to Payment
//                     </button>
//                   </div>
//                 )}
//                 {hasReferralCode === false && (
//                   <div className="text-center">
//                     <p className="text-muted" style={{ fontWeight: "bold" }}>
//                       No referral code? No problem!
//                     </p>
//                     <button
//                       className="btn btn-success mt-3 w-100"
//                       onClick={() => {
//                         setShowReferralModal(false);
//                         handleSubscription(selectedPlanId, selectedTotalCount);
//                       }}
//                       style={{ boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)" }}
//                     >
//                       Proceed to Payment
//                     </button>
//                   </div>
//                 )}
//               </div>
//             </Modal.Body>
//           </Modal>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Purchasescreen;
///////////////////////////////////////////////////////////////////////////////////////////////////
// import React, { useState, useEffect } from "react";
// import toast from "react-hot-toast";
// import Modal from "react-bootstrap/Modal";
// import { Button } from "react-bootstrap";
// import { useSelector } from "react-redux";

// const Purchasescreen = () => {
//   const [selectedDurations, setSelectedDurations] = useState(["", "", "", ""]);
//   const [selectedDivisions, setSelectedDivisions] = useState(["", "", "", ""]);
//   const [selectedClasses, setSelectedClasses] = useState(["", "", "", ""]); // New State for Class
//   const [totalPrice, setTotalPrice] = useState(0);
//   const [selectedPlanId, setSelectedPlanId] = useState(null);
//   const [selectedTotalCount, setSelectedTotalCount] = useState(null);
//   const [showReferralModal, setShowReferralModal] = useState(false);
//   const [referralCodeVerified, setReferralCodeVerified] = useState(false);
//   const [verifyingReferral, setVerifyingReferral] = useState(false);
//   const [referralInput, setReferralInput] = useState("");
//   const [loadingPlan, setLoadingPlan] = useState(null);

//   const [showModal, setShowModal] = useState(false);
//   const [referralCode, setReferralCode] = useState(null);
//   const [hasReferralCode, setHasReferralCode] = useState(null); // Track Yes/No selection

//   const [subscription, setSubscription] = useState(null);
//   const [loadingUnsubscribe, setLoadingUnsubscribe] = useState(false);

//   const userstate = useSelector((state) => state.loginUserReducer);
//   const { currentUser1 } = userstate;

//   const durations = ["Monthly", "Quarterly", "Half Yearly", "Yearly"];
//   const divisions = ["Udbodh", "Bauddha Dakshata", "Kye Bye", "Abhyudaya"];
//   const classes = [...Array(12).keys()]
//     .map((num) => (num + 1).toString())
//     .concat(["Already passed 12th"]);

//   const plans = {
//     Udbodh: {
//       Monthly: 300,
//       Quarterly: 878,
//       "Half Yearly": 1710,
//       Yearly: 3240,
//     },
//     "Bauddha Dakshata": {
//       Monthly: 500,
//       Quarterly: 1463,
//       "Half Yearly": 2850,
//       Yearly: 5400,
//     },
//     "Kye Bye": {
//       Monthly: 500,
//       Quarterly: 1463,
//       "Half Yearly": 2850,
//       Yearly: 5400,
//     },
//     Abhyudaya: {
//       Monthly: 1000, //process.env.REACT_APP_PLAN_ID_ABHYUDAYA_MONTHLY,
//       Quarterly: 2925, //process.env.REACT_APP_PLAN_ID_ABHYUDAYA_QUARTERLY,
//       "Half Yearly": 5700, //process.env.REACT_APP_PLAN_ID_ABHYUDAYA_HALFYEARLY,
//       Yearly: 10800, //process.env.REACT_APP_PLAN_ID_ABHYUDAYA_ANNUALLY,
//     },
//     "Udbodh + Bauddha Dakshata": {
//       Monthly: 700, //process.env.REACT_APP_PLAN_ID_COMBO_ONE_MONTHLY,
//       Quarterly: 2048, //process.env.REACT_APP_PLAN_ID_COMBO_ONE_QUARTERLY,
//       "Half Yearly": 3990, //process.env.REACT_APP_PLAN_ID_COMBO_ONE_HALFYEARLY,
//       Yearly: 7560, //process.env.REACT_APP_PLAN_ID_COMBO_ONE_ANNUALLY,
//     },
//     "Bauddha Dakshata + Udbodh": {
//       Monthly: 700, //process.env.REACT_APP_PLAN_ID_COMBO_ONE_MONTHLY,
//       Quarterly: 2048, //process.env.REACT_APP_PLAN_ID_COMBO_ONE_QUARTERLY,
//       "Half Yearly": 3990, //process.env.REACT_APP_PLAN_ID_COMBO_ONE_HALFYEARLY,
//       Yearly: 7560, //process.env.REACT_APP_PLAN_ID_COMBO_ONE_ANNUALLY,
//     },
//     "Udbodh + Kye Bye": {
//       Monthly: 700, //process.env.REACT_APP_PLAN_ID_COMBO_TWO_MONTHLY,
//       Quarterly: 2048, //process.env.REACT_APP_PLAN_ID_COMBO_TWO_QUARTERLY,
//       "Half Yearly": 3990, //process.env.REACT_APP_PLAN_ID_COMBO_TWO_HALFYEARLY,
//       Yearly: 7560, //process.env.REACT_APP_PLAN_ID_COMBO_TWO_ANNUALLY,
//     },
//     "Kye Bye + Udbodh": {
//       Monthly: 700, //process.env.REACT_APP_PLAN_ID_COMBO_TWO_MONTHLY,
//       Quarterly: 2048, //process.env.REACT_APP_PLAN_ID_COMBO_TWO_QUARTERLY,
//       "Half Yearly": 3990, //process.env.REACT_APP_PLAN_ID_COMBO_TWO_HALFYEARLY,
//       Yearly: 7560, //process.env.REACT_APP_PLAN_ID_COMBO_TWO_ANNUALLY,
//     },
//     "Udbodh + Abhyudaya": {
//       Monthly: 1200, //process.env.REACT_APP_PLAN_ID_COMBO_THREE_MONTHLY,
//       Quarterly: 3510, //process.env.REACT_APP_PLAN_ID_COMBO_THREE_QUARTERLY,
//       "Half Yearly": 6840, //process.env.REACT_APP_PLAN_ID_COMBO_THREE_HALFYEARLY,
//       Yearly: 12960, //process.env.REACT_APP_PLAN_ID_COMBO_THREE_ANNUALLY,
//     },
//     "Abhyudaya + Udbodh": {
//       Monthly: 1200, //process.env.REACT_APP_PLAN_ID_COMBO_THREE_MONTHLY,
//       Quarterly: 3510, //process.env.REACT_APP_PLAN_ID_COMBO_THREE_QUARTERLY,
//       "Half Yearly": 6840, //process.env.REACT_APP_PLAN_ID_COMBO_THREE_HALFYEARLY,
//       Yearly: 12960, //process.env.REACT_APP_PLAN_ID_COMBO_THREE_ANNUALLY,
//     },
//     "Bauddha Dakshata + Kye Bye": {
//       Monthly: 900, //process.env.REACT_APP_PLAN_ID_COMBO_FOUR_MONTHLY,
//       Quarterly: 2633, //process.env.REACT_APP_PLAN_ID_COMBO_FOUR_QUARTERLY,
//       "Half Yearly": 5130, //process.env.REACT_APP_PLAN_ID_COMBO_FOUR_HALFYEARLY,
//       Yearly: 9720, //process.env.REACT_APP_PLAN_ID_COMBO_FOUR_ANNUALLY,
//     },
//     "Kye Bye + Bauddha Dakshata": {
//       Monthly: 900, //process.env.REACT_APP_PLAN_ID_COMBO_FOUR_MONTHLY,
//       Quarterly: 2633, //process.env.REACT_APP_PLAN_ID_COMBO_FOUR_QUARTERLY,
//       "Half Yearly": 5130, //process.env.REACT_APP_PLAN_ID_COMBO_FOUR_HALFYEARLY,
//       Yearly: 9720, //process.env.REACT_APP_PLAN_ID_COMBO_FOUR_ANNUALLY,
//     },
//     "Bauddha Dakshata + Abhyudaya": {
//       Monthly: 1400, //process.env.REACT_APP_PLAN_ID_COMBO_FIVE_MONTHLY,
//       Quarterly: 4095, //process.env.REACT_APP_PLAN_ID_COMBO_FIVE_QUARTERLY,
//       "Half Yearly": 7980, //process.env.REACT_APP_PLAN_ID_COMBO_FIVE_HALFYEARLY,
//       Yearly: 15120, //process.env.REACT_APP_PLAN_ID_COMBO_FIVE_ANNUALLY,
//     },
//     "Abhyudaya + Bauddha Dakshata": {
//       Monthly: 1400, //process.env.REACT_APP_PLAN_ID_COMBO_FIVE_MONTHLY,
//       Quarterly: 4095, //process.env.REACT_APP_PLAN_ID_COMBO_FIVE_QUARTERLY,
//       "Half Yearly": 7980, //process.env.REACT_APP_PLAN_ID_COMBO_FIVE_HALFYEARLY,
//       Yearly: 15120, //process.env.REACT_APP_PLAN_ID_COMBO_FIVE_ANNUALLY,
//     },
//     "Kye Bye + Abhyudaya": {
//       Monthly: 1400, //process.env.REACT_APP_PLAN_ID_COMBO_SIX_MONTHLY,
//       Quarterly: 4095, //process.env.REACT_APP_PLAN_ID_COMBO_SIX_QUARTERLY,
//       "Half Yearly": 7980, //process.env.REACT_APP_PLAN_ID_COMBO_SIX_HALFYEARLY,
//       Yearly: 15120, //process.env.REACT_APP_PLAN_ID_COMBO_SIX_ANNUALLY,
//     },
//     "Abhyudaya + Kye Bye": {
//       Monthly: 1400, //process.env.REACT_APP_PLAN_ID_COMBO_SIX_MONTHLY,
//       Quarterly: 4095, //process.env.REACT_APP_PLAN_ID_COMBO_SIX_QUARTERLY,
//       "Half Yearly": 7980, //process.env.REACT_APP_PLAN_ID_COMBO_SIX_HALFYEARLY,
//       Yearly: 15120, //process.env.REACT_APP_PLAN_ID_COMBO_SIX_ANNUALLY,
//     },
//     "Udbodh + Bauddha Dakshata + Kye Bye": {
//       Monthly: 1150, //process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_MONTHLY,
//       Quarterly: 3364, //process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_QUARTERLY,
//       "Half Yearly": 6555, //process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_HALFYEARLY,
//       Yearly: 12420, //process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_ANNUALLY,
//     },
//     "Udbodh + Kye Bye + Bauddha Dakshata": {
//       Monthly: 1150, //process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_MONTHLY,
//       Quarterly: 3364, //process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_QUARTERLY,
//       "Half Yearly": 6555, //process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_HALFYEARLY,
//       Yearly: 12420, //process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_ANNUALLY,
//     },
//     "Bauddha Dakshata + Kye Bye + Udbodh": {
//       Monthly: 1150, //process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_MONTHLY,
//       Quarterly: 3364, //process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_QUARTERLY,
//       "Half Yearly": 6555, //process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_HALFYEARLY,
//       Yearly: 12420, //process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_ANNUALLY,
//     },
//     "Bauddha Dakshata + Udbodh + Kye Bye": {
//       Monthly: 1150, //process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_MONTHLY,
//       Quarterly: 3364, //process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_QUARTERLY,
//       "Half Yearly": 6555, //process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_HALFYEARLY,
//       Yearly: 12420, //process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_ANNUALLY,
//     },
//     "Kye Bye + Udbodh + Bauddha Dakshata": {
//       Monthly: 1150, //process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_MONTHLY,
//       Quarterly: 3364, //process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_QUARTERLY,
//       "Half Yearly": 6555, //process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_HALFYEARLY,
//       Yearly: 12420, //process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_ANNUALLY,
//     },
//     "Kye Bye + Bauddha Dakshata + Udbodh": {
//       Monthly: 1150, //process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_MONTHLY,
//       Quarterly: 3364, //process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_QUARTERLY,
//       "Half Yearly": 6555, //process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_HALFYEARLY,
//       Yearly: 12420, //process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_ANNUALLY,
//     },
//     "Udbodh + Bauddha Dakshata + Kye Bye + Abhyudaya": {
//       Monthly: 2100, //process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: 6143, //process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": 11970, //process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: 22680, //process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Udbodh + Bauddha Dakshata + Abhyudaya + Kye Bye": {
//       Monthly: 2100, //process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: 6143, //process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": 11970, //process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: 22680, //process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Udbodh + Kye Bye + Bauddha Dakshata + Abhyudaya": {
//       Monthly: 2100, //process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: 6143, //process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": 11970, //process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: 22680, //process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Udbodh + Kye Bye + Abhyudaya + Bauddha Dakshata": {
//       Monthly: 2100, //process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: 6143, //process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": 11970, //process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: 22680, //process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Udbodh + Abhyudaya + Bauddha Dakshata + Kye Bye": {
//       Monthly: 2100, //process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: 6143, //process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": 11970, //process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: 22680, //process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Udbodh + Abhyudaya + Kye Bye + Bauddha Dakshata": {
//       Monthly: 2100, //process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: 6143, //process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": 11970, //process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: 22680, //process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Bauddha Dakshata + Udbodh + Kye Bye + Abhyudaya": {
//       Monthly: 2100, //process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: 6143, //process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": 11970, //process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: 22680, //process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Bauddha Dakshata + Udbodh + Abhyudaya + Kye Bye": {
//       Monthly: 2100, //process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: 6143, //process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": 11970, //process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: 22680, //process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Bauddha Dakshata + Kye Bye + Udbodh + Abhyudaya": {
//       Monthly: 2100, //process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: 6143, //process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": 11970, //process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: 22680, //process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Bauddha Dakshata + Kye Bye + Abhyudaya + Udbodh": {
//       Monthly: 2100, //process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: 6143, //process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": 11970, //process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: 22680, //process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Bauddha Dakshata + Abhyudaya + Udbodh + Kye Bye": {
//       Monthly: 2100, //process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: 6143, //process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": 11970, //process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: 22680, //process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Bauddha Dakshata + Abhyudaya + Kye Bye + Udbodh": {
//       Monthly: 2100, //process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: 6143, //process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": 11970, //process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: 22680, //process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Kye Bye + Udbodh + Bauddha Dakshata + Abhyudaya": {
//       Monthly: 2100, //process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: 6143, //process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": 11970, //process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: 22680, //process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Kye Bye + Udbodh + Abhyudaya + Bauddha Dakshata": {
//       Monthly: 2100, //process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: 6143, //process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": 11970, //process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: 22680, //process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Kye Bye + Bauddha Dakshata + Udbodh + Abhyudaya": {
//       Monthly: 2100, //process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: 6143, //process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": 11970, //process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: 22680, //process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Kye Bye + Bauddha Dakshata + Abhyudaya + Udbodh": {
//       Monthly: 2100, //process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: 6143, //process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": 11970, //process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: 22680, //process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Kye Bye + Abhyudaya + Udbodh + Bauddha Dakshata": {
//       Monthly: 2100, //process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: 6143, //process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": 11970, //process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: 22680, //process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Kye Bye + Abhyudaya + Bauddha Dakshata + Udbodh": {
//       Monthly: 2100, //process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: 6143, //process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": 11970, //process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: 22680, //process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Abhyudaya + Udbodh + Bauddha Dakshata + Kye Bye": {
//       Monthly: 2100, //process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: 6143, //process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": 11970, //process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: 22680, //process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Abhyudaya + Udbodh + Kye Bye + Bauddha Dakshata": {
//       Monthly: 2100, //process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: 6143, //process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": 11970, //process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: 22680, //process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Abhyudaya + Bauddha Dakshata + Udbodh + Kye Bye": {
//       Monthly: 2100, //process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: 6143, //process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": 11970, //process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: 22680, //process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Abhyudaya + Bauddha Dakshata + Kye Bye + Udbodh": {
//       Monthly: 2100, //process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: 6143, //process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": 11970, //process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: 22680, //process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Abhyudaya + Kye Bye + Udbodh + Bauddha Dakshata": {
//       Monthly: 2100, //process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: 6143, //process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": 11970, //process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: 22680, //process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Abhyudaya + Kye Bye + Bauddha Dakshata + Udbodh": {
//       Monthly: 2100, //process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: 6143, //process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": 11970, //process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: 22680, //process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//   };

//   const planIds = {
//     Udbodh: {
//       Monthly: process.env.REACT_APP_PLAN_ID_UDBODH_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_UDBODH_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_UDBODH_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_UDBODH_ANNUALLY,
//     },
//     "Bauddha Dakshata": {
//       Monthly: process.env.REACT_APP_PLAN_ID_BD_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_BD_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_BD_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_BD_ANNUALLY,
//     },
//     "Kye Bye": {
//       Monthly: process.env.REACT_APP_PLAN_ID_KYEBYE_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_KYEBYE_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_KYEBYE_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_KYEBYE_ANNUALLY,
//     },
//     Abhyudaya: {
//       Monthly: process.env.REACT_APP_PLAN_ID_ABHYUDAYA_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_ABHYUDAYA_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_ABHYUDAYA_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_ABHYUDAYA_ANNUALLY,
//     },
//     "Udbodh + Bauddha Dakshata": {
//       Monthly: process.env.REACT_APP_PLAN_ID_COMBO_ONE_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_COMBO_ONE_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_COMBO_ONE_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_COMBO_ONE_ANNUALLY,
//     },
//     "Bauddha Dakshata + Udbodh": {
//       Monthly: process.env.REACT_APP_PLAN_ID_COMBO_ONE_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_COMBO_ONE_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_COMBO_ONE_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_COMBO_ONE_ANNUALLY,
//     },
//     "Udbodh + Kye Bye": {
//       Monthly: process.env.REACT_APP_PLAN_ID_COMBO_TWO_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_COMBO_TWO_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_COMBO_TWO_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_COMBO_TWO_ANNUALLY,
//     },
//     "Kye Bye + Udbodh": {
//       Monthly: process.env.REACT_APP_PLAN_ID_COMBO_TWO_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_COMBO_TWO_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_COMBO_TWO_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_COMBO_TWO_ANNUALLY,
//     },
//     "Udbodh + Abhyudaya": {
//       Monthly: process.env.REACT_APP_PLAN_ID_COMBO_THREE_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_COMBO_THREE_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_COMBO_THREE_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_COMBO_THREE_ANNUALLY,
//     },
//     "Abhyudaya + Udbodh": {
//       Monthly: process.env.REACT_APP_PLAN_ID_COMBO_THREE_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_COMBO_THREE_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_COMBO_THREE_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_COMBO_THREE_ANNUALLY,
//     },
//     "Bauddha Dakshata + Kye Bye": {
//       Monthly: process.env.REACT_APP_PLAN_ID_COMBO_FOUR_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_COMBO_FOUR_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_COMBO_FOUR_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_COMBO_FOUR_ANNUALLY,
//     },
//     "Kye Bye + Bauddha Dakshata": {
//       Monthly: process.env.REACT_APP_PLAN_ID_COMBO_FOUR_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_COMBO_FOUR_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_COMBO_FOUR_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_COMBO_FOUR_ANNUALLY,
//     },
//     "Bauddha Dakshata + Abhyudaya": {
//       Monthly: process.env.REACT_APP_PLAN_ID_COMBO_FIVE_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_COMBO_FIVE_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_COMBO_FIVE_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_COMBO_FIVE_ANNUALLY,
//     },
//     "Abhyudaya + Bauddha Dakshata": {
//       Monthly: process.env.REACT_APP_PLAN_ID_COMBO_FIVE_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_COMBO_FIVE_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_COMBO_FIVE_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_COMBO_FIVE_ANNUALLY,
//     },
//     "Kye Bye + Abhyudaya": {
//       Monthly: process.env.REACT_APP_PLAN_ID_COMBO_SIX_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_COMBO_SIX_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_COMBO_SIX_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_COMBO_SIX_ANNUALLY,
//     },
//     "Abhyudaya + Kye Bye": {
//       Monthly: process.env.REACT_APP_PLAN_ID_COMBO_SIX_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_COMBO_SIX_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_COMBO_SIX_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_COMBO_SIX_ANNUALLY,
//     },
//     "Udbodh + Bauddha Dakshata + Kye Bye": {
//       Monthly: process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_ANNUALLY,
//     },
//     "Udbodh + Kye Bye + Bauddha Dakshata": {
//       Monthly: process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_ANNUALLY,
//     },
//     "Bauddha Dakshata + Kye Bye + Udbodh": {
//       Monthly: process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_ANNUALLY,
//     },
//     "Bauddha Dakshata + Udbodh + Kye Bye": {
//       Monthly: process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_ANNUALLY,
//     },
//     "Kye Bye + Udbodh + Bauddha Dakshata": {
//       Monthly: process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_ANNUALLY,
//     },
//     "Kye Bye + Bauddha Dakshata + Udbodh": {
//       Monthly: process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_ANNUALLY,
//     },
//     "Udbodh + Bauddha Dakshata + Kye Bye + Abhyudaya": {
//       Monthly: process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Udbodh + Bauddha Dakshata + Abhyudaya + Kye Bye": {
//       Monthly: process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Udbodh + Kye Bye + Bauddha Dakshata + Abhyudaya": {
//       Monthly: process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Udbodh + Kye Bye + Abhyudaya + Bauddha Dakshata": {
//       Monthly: process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Udbodh + Abhyudaya + Bauddha Dakshata + Kye Bye": {
//       Monthly: process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Udbodh + Abhyudaya + Kye Bye + Bauddha Dakshata": {
//       Monthly: process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Bauddha Dakshata + Udbodh + Kye Bye + Abhyudaya": {
//       Monthly: process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Bauddha Dakshata + Udbodh + Abhyudaya + Kye Bye": {
//       Monthly: process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Bauddha Dakshata + Kye Bye + Udbodh + Abhyudaya": {
//       Monthly: process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Bauddha Dakshata + Kye Bye + Abhyudaya + Udbodh": {
//       Monthly: process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Bauddha Dakshata + Abhyudaya + Udbodh + Kye Bye": {
//       Monthly: process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Bauddha Dakshata + Abhyudaya + Kye Bye + Udbodh": {
//       Monthly: process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Kye Bye + Udbodh + Bauddha Dakshata + Abhyudaya": {
//       Monthly: process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Kye Bye + Udbodh + Abhyudaya + Bauddha Dakshata": {
//       Monthly: process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Kye Bye + Bauddha Dakshata + Udbodh + Abhyudaya": {
//       Monthly: process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Kye Bye + Bauddha Dakshata + Abhyudaya + Udbodh": {
//       Monthly: process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Kye Bye + Abhyudaya + Udbodh + Bauddha Dakshata": {
//       Monthly: process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Kye Bye + Abhyudaya + Bauddha Dakshata + Udbodh": {
//       Monthly: process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Abhyudaya + Udbodh + Bauddha Dakshata + Kye Bye": {
//       Monthly: process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Abhyudaya + Udbodh + Kye Bye + Bauddha Dakshata": {
//       Monthly: process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Abhyudaya + Bauddha Dakshata + Udbodh + Kye Bye": {
//       Monthly: process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Abhyudaya + Bauddha Dakshata + Kye Bye + Udbodh": {
//       Monthly: process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Abhyudaya + Kye Bye + Udbodh + Bauddha Dakshata": {
//       Monthly: process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },
//     "Abhyudaya + Kye Bye + Bauddha Dakshata + Udbodh": {
//       Monthly: process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
//       Quarterly: process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
//       "Half Yearly": process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
//       Yearly: process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
//     },

//     // Add other combinations here...
//   };

//   const totalCounts = {
//     Yearly: 8,
//     Quarterly: 32,
//     "Half Yearly": 16,
//     Monthly: 96,
//   };

//   useEffect(() => {
//     // Fetch user subscription data
//     const fetchSubscription = async () => {
//       try {
//         const res = await fetch(`/api/users/${currentUser1._id}`);
//         const data = await res.json();
//         setSubscription(data.subscription);
//       } catch (error) {
//         console.error("Error fetching subscription:", error);
//       }
//     };

//     if (currentUser1 && currentUser1._id) {
//       fetchSubscription();
//     }
//   }, [currentUser1]);

//   const handleUnsubscribe = async () => {
//     if (!subscription?.id) {
//       toast.error("No active subscription found.");
//       return;
//     }

//     setLoadingUnsubscribe(true);
//     try {
//       const res = await fetch(`/api/payment/unsubscribe`, {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify({
//           subscriptionId: subscription.id,
//           userId: currentUser1._id,
//         }),
//       });

//       const data = await res.json();

//       if (data.message) {
//         toast.success(data.message);
//         setTimeout(() => {
//           window.location.reload();
//         }, 2000);
//       }
//     } catch (error) {
//       console.log(error);
//       toast.error("Error unsubscribing from the plan.");
//     } finally {
//       setLoadingUnsubscribe(false);
//     }
//   };

//   // Handle modal close event
//   const handleCloseModal = () => {
//     setShowModal(false);

//     // Trigger page reload only when modal is closed
//     window.location.reload();
//   };

//   const handleDivisionChange = (index, value) => {
//     const updatedDivisions = [...selectedDivisions];
//     updatedDivisions[index] = value;
//     setSelectedDivisions(updatedDivisions);
//     calculateTotalPrice(updatedDivisions, selectedDurations);

//     const updatedClasses = [...selectedClasses];

//     if (value === "Abhyudaya" || value === "Kye Bye") {
//       updatedClasses[index] = "Already passed 12th";
//     } else if (value === "Udbodh" || value === "Bauddha Dakshata") {
//       // Find if another row already has Udbodh or Bauddha Dakshata with a selected class
//       const matchingIndex = selectedDivisions.findIndex(
//         (div, i) =>
//           i !== index &&
//           (div === "Udbodh" || div === "Bauddha Dakshata") &&
//           selectedClasses[i]
//       );

//       // If found, update this row's class to match
//       if (matchingIndex !== -1) {
//         updatedClasses[index] = selectedClasses[matchingIndex];
//       }
//     }

//     setSelectedClasses(updatedClasses);
//   };

//   const handleClassChange = (index, value) => {
//     const updatedClasses = [...selectedClasses];
//     updatedClasses[index] = value;

//     // If this row belongs to Udbodh or Bauddha Dakshata, update all similar divisions
//     if (
//       selectedDivisions[index] === "Udbodh" ||
//       selectedDivisions[index] === "Bauddha Dakshata"
//     ) {
//       selectedDivisions.forEach((div, i) => {
//         if (i !== index && (div === "Udbodh" || div === "Bauddha Dakshata")) {
//           updatedClasses[i] = value; // Sync all similar divisions
//         }
//       });
//     }

//     setSelectedClasses(updatedClasses);
//   };

//   const handleDurationChange = (index, value) => {
//     const updatedDurations = selectedDurations.map(() => value); // Set all to the same value
//     setSelectedDurations(updatedDurations);
//     calculateTotalPrice(selectedDivisions, updatedDurations);
//   };

//   const calculateTotalPrice = (divisions, durations) => {
//     let price = 0;
//     let planKey = divisions.filter((d) => d).join(" + "); // Join only non-empty values

//     if (planKey && plans[planKey] && durations[0]) {
//       price = plans[planKey][durations[0]] || 0;
//     }

//     setTotalPrice(price);
//     setSelectedPlanId(planIds[planKey]?.[durations[0]] || null);
//     setSelectedTotalCount(totalCounts[durations[0]] || null);
//   };

//   const handleProceedToPayment = () => {
//     if (!selectedPlanId || !selectedTotalCount) {
//       toast.error("Please complete your selections.");
//       return;
//     }

//     setShowReferralModal(true);
//     setReferralCodeVerified(false);
//   };

//   const verifyReferralCode = async () => {
//     setVerifyingReferral(true);
//     try {
//       const res = await fetch(`/api/users/verifyReferral`, {
//         method: "POST",
//         headers: { "Content-Type": "application/json" },
//         body: JSON.stringify({ referralCode: referralInput.trim() }),
//       });
//       const data = await res.json();

//       if (data.success) {
//         setReferralCodeVerified(true);
//         toast.success("Referral code verified successfully!");
//       } else {
//         toast.error("Invalid referral code.");
//       }
//     } catch (error) {
//       toast.error("Failed to verify referral code.");
//     } finally {
//       setVerifyingReferral(false);
//     }
//   };

//   // handleSubscription Function
//   const handleSubscription = async (plan_id, total_count) => {
//     setLoadingPlan(plan_id); // Set loading for the clicked plan
//     try {
//       const res = await fetch(`/api/payment/subscription`, {
//         method: "POST",
//         headers: {
//           "content-type": "application/json",
//         },
//         body: JSON.stringify({
//           plan_id,
//           userId: currentUser1._id, // Pass user ID to backend
//           total_count,
//           referralCode: referralInput.trim(), // Include referral code in request
//         }),
//       });

//       const data = await res.json();
//       console.log(data);
//       handlePaymentVerify(data.data);
//       // setReferralCode(data.referralCode); // Set referral code if returned
//       // setShowModal(true);
//       setLoadingPlan(null); // Reset loading state in case of error
//     } catch (error) {
//       console.log(error);
//       setLoadingPlan(null); // Reset loading state in case of error
//     }
//   };

//   // handlePaymentVerify Function
//   const handlePaymentVerify = async (data) => {
//     const options = {
//       key: process.env.REACT_APP_RAZORPAY_KEY_ID,
//       subscription_id: data.id,
//       name: "Gyankosha",
//       description: "Payment for Quiz Subscription",
//       handler: async (response) => {
//         console.log("response", response);
//         try {
//           const res = await fetch(`/api/payment/verify`, {
//             method: "POST",
//             headers: {
//               "content-type": "application/json",
//             },
//             body: JSON.stringify({
//               razorpay_subscription_id: response.razorpay_subscription_id,
//               razorpay_payment_id: response.razorpay_payment_id,
//               razorpay_signature: response.razorpay_signature,
//               userId: currentUser1._id, // Pass user ID for verification
//               paidfor: selectedDivisions.map(
//                 (division, index) => `${division}-${selectedClasses[index]}`
//               ), // Store "Division-Class"
//             }),
//           });

//           const verifyData = await res.json();

//           if (verifyData.message) {
//             toast.success(verifyData.message);
//             console.log("verifyData.referralCode : ", verifyData.referralCode);
//             if (verifyData.referralCode) {
//               setReferralCode(verifyData.referralCode);
//               setShowModal(true);
//             }
//             // Reload the page after 2 seconds
//             // setTimeout(() => {
//             //   window.location.reload();
//             // }, 2000);
//           }
//         } catch (error) {
//           console.log(error);
//         } finally {
//           setLoadingPlan(null); // Reset loading state in case of error
//         }
//       },
//       theme: {
//         color: "#5f63b8",
//       },
//     };
//     const rzp1 = new window.Razorpay(options);
//     rzp1.open();
//   };

//   const getIndividualPrice = (division, duration) => {
//     if (division && duration && plans[division]) {
//       return plans[division][duration] || 0; // Return price or 0 if not found
//     }
//     return 0;
//   };

//   return (
//     <div className="row justify-content-center">
//       <div className="col-md-9">
//         <div
//           className="pricing-table"
//           style={{
//             fontFamily: '"Libre Baskerville", serif',
//             margin: "100px 25px 200px 25px",
//           }}
//         >
//           <h3>Purchase Premium Quizzes:</h3>
//           <div className="table-responsive">
//             <table
//               className="table table-bordered"
//               style={{ borderColor: "#343a40", overflow: "visible" }}
//             >
//               <thead className="table-dark">
//                 <tr>
//                   <th></th>
//                   <th>Select Division</th>
//                   <th>Select Quiz Class</th>
//                   <th>Select Duration</th>
//                   <th>Individual Price</th> {/* New Column */}
//                 </tr>
//               </thead>
//               <tbody>
//                 {[0, 1, 2, 3].map((rowIndex) => (
//                   <tr key={rowIndex}>
//                     <td>Choose your vertical {rowIndex + 1} :</td>
//                     <td>
//                       <select
//                         className="form-control"
//                         value={selectedDivisions[rowIndex]}
//                         onChange={(e) =>
//                           handleDivisionChange(rowIndex, e.target.value)
//                         }
//                       >
//                         <option value="">-- Select Division --</option>
//                         {divisions.map((division) => (
//                           <option key={division} value={division}>
//                             {division}
//                           </option>
//                         ))}
//                       </select>
//                     </td>
//                     <td>
//                       <select
//                         className="form-control"
//                         value={selectedClasses[rowIndex]}
//                         onChange={(e) =>
//                           handleClassChange(rowIndex, e.target.value)
//                         }
//                         disabled={
//                           selectedDivisions[rowIndex] === "Abhyudaya" ||
//                           selectedDivisions[rowIndex] === "Kye Bye"
//                         }
//                       >
//                         <option value="">-- Select Class --</option>
//                         {classes.map((cls) => (
//                           <option key={cls} value={cls}>
//                             {cls}
//                           </option>
//                         ))}
//                       </select>
//                     </td>
//                     <td>
//                       <select
//                         className="form-control"
//                         value={selectedDurations[rowIndex]}
//                         onChange={(e) =>
//                           handleDurationChange(rowIndex, e.target.value)
//                         }
//                         //disabled={rowIndex !== 0 && !selectedDurations[0]}
//                       >
//                         <option value="">-- Select Duration --</option>
//                         {durations.map((duration) => (
//                           <option key={duration} value={duration}>
//                             {duration}
//                           </option>
//                         ))}
//                       </select>
//                     </td>
//                     <td>
//                       ₹
//                       {getIndividualPrice(
//                         selectedDivisions[rowIndex],
//                         selectedDurations[rowIndex]
//                       )}
//                     </td>{" "}
//                     {/* New Column */}
//                   </tr>
//                 ))}
//               </tbody>
//             </table>
//           </div>

//           <div
//             style={{
//               display: "flex",
//               alignItems: "center",
//               gap: "15px",
//               marginTop: "10px",
//             }}
//           >
//             <span style={{ fontWeight: "bold" }}>
//               Total Price: ₹{totalPrice}
//             </span>
//             <button
//               className="btn btn-primary"
//               onClick={handleProceedToPayment}
//             >
//               Proceed
//             </button>
//           </div>

//           <h3>Your Subscription:</h3>

//           {subscription?.status === "active" ? (
//             <div>
//               <p>
//                 You are currently subscribed to:{" "}
//                 <strong>{subscription.plan}</strong>
//               </p>
//               <button
//                 className="btn btn-danger"
//                 onClick={handleUnsubscribe}
//                 disabled={loadingUnsubscribe}
//               >
//                 {loadingUnsubscribe ? "Unsubscribing..." : "Unsubscribe"}
//               </button>
//             </div>
//           ) : (
//             <p>You have no active subscriptions.</p>
//           )}

//           <Modal show={showModal} onHide={handleCloseModal} centered>
//             <Modal.Header closeButton>
//               <Modal.Title>
//                 Gyankosha's Subscription Active now and your Referral Code :{" "}
//                 <span style={{ color: "red" }}>{referralCode}</span>
//               </Modal.Title>
//             </Modal.Header>

//             <Modal.Body>
//               <div
//                 style={{ fontFamily: "Arial, sans-serif", lineHeight: "1.6" }}
//               >
//                 <p>
//                   Dear <strong>{currentUser1 ? currentUser1.name : ""}</strong>,
//                 </p>
//                 <p>
//                   Thank you for choosing <strong>Gyankosha</strong> as your
//                   trusted education partner. We are thrilled to have you on
//                   board! Your subscription plan is now active, giving you access
//                   to the premium content and resources.
//                 </p>
//                 <p>
//                   Attached, you will find your unique Referral Code:{" "}
//                   <strong style={{ color: "red" }}>{referralCode}</strong>.
//                 </p>
//                 <p>
//                   As a token of appreciation for your support, when a new user
//                   subscribes and makes their first payment using your referral
//                   code, you become eligible for a <strong>20% cashback</strong>{" "}
//                   to your account, and the one referred by you becomes eligible
//                   for a <strong>10% cashback</strong> to their account. Please
//                   note that the cashback is applicable only against the first
//                   payment made by the referred user and is valid for one cycle
//                   only.
//                 </p>
//                 <p>
//                   Let’s keep learning, growing, and making education exciting
//                   together!
//                 </p>
//                 <p>Warm regards,</p>
//                 <p>
//                   <strong>The Gyankosha Team</strong>
//                 </p>
//               </div>
//             </Modal.Body>

//             <Modal.Footer>
//               <Button variant="secondary" onClick={handleCloseModal}>
//                 Close
//               </Button>
//             </Modal.Footer>
//           </Modal>

//           <Modal
//             show={showReferralModal}
//             onHide={() => {
//               setShowReferralModal(false);
//               setHasReferralCode(null); // Reset the referral code selection
//               setReferralInput(""); // Clear referral input field
//               setReferralCodeVerified(false); // Reset verification status
//               setVerifyingReferral(false); // Reset verifying state
//             }}
//             onExit={() => {
//               setShowReferralModal(false);
//               setHasReferralCode(null); // Reset the referral code selection
//               setReferralInput(""); // Clear referral input field
//               setReferralCodeVerified(false); // Reset verification status
//               setVerifyingReferral(false); // Reset verifying state
//             }}
//             centered
//           >
//             <Modal.Header closeButton>
//               {hasReferralCode === null && (
//                 <Modal.Title
//                   style={{
//                     fontFamily: "Arial, sans-serif",
//                     fontWeight: "bold",
//                   }}
//                 >
//                   Do you have a referral code?
//                 </Modal.Title>
//               )}
//             </Modal.Header>
//             <Modal.Body
//               style={{ fontFamily: "Arial, sans-serif", lineHeight: "1.6" }}
//             >
//               <div>
//                 {hasReferralCode === null && (
//                   <div className="d-flex flex-column align-items-start">
//                     <div className="form-check custom-radio mb-3">
//                       <input
//                         className="form-check-input"
//                         type="radio"
//                         name="referralCodeOption"
//                         id="referralYes"
//                         onChange={() => setHasReferralCode(true)}
//                       />
//                       <label
//                         className="form-check-label ms-2"
//                         htmlFor="referralYes"
//                       >
//                         Yes, I have a referral code
//                       </label>
//                     </div>
//                     <div className="form-check custom-radio">
//                       <input
//                         className="form-check-input"
//                         type="radio"
//                         name="referralCodeOption"
//                         id="referralNo"
//                         onChange={() => setHasReferralCode(false)}
//                       />
//                       <label
//                         className="form-check-label ms-2"
//                         htmlFor="referralNo"
//                       >
//                         No, I don't have a referral code
//                       </label>
//                     </div>
//                   </div>
//                 )}
//                 {hasReferralCode === true && !referralCodeVerified && (
//                   <div>
//                     <label
//                       htmlFor="referralInput"
//                       style={{ fontWeight: "bold" }}
//                     >
//                       Enter your referral code:
//                     </label>
//                     <input
//                       type="text"
//                       className="form-control mt-2"
//                       id="referralInput"
//                       placeholder="Enter referral code here"
//                       value={referralInput}
//                       onChange={(e) => setReferralInput(e.target.value)}
//                       style={{
//                         border: "1px solid #ced4da",
//                         borderRadius: "5px",
//                       }}
//                     />
//                     <button
//                       className="btn btn-primary mt-3 w-100"
//                       onClick={verifyReferralCode}
//                       disabled={verifyingReferral}
//                       style={{ boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)" }}
//                     >
//                       {verifyingReferral
//                         ? "Verifying..."
//                         : "Verify Referral Code"}
//                     </button>
//                   </div>
//                 )}
//                 {referralCodeVerified && (
//                   <div className="text-center">
//                     <p className="text-success" style={{ fontWeight: "bold" }}>
//                       Referral code verified successfully!
//                     </p>
//                     <button
//                       className="btn btn-success mt-3 w-100"
//                       onClick={() => {
//                         setShowReferralModal(false);
//                         handleSubscription(selectedPlanId, selectedTotalCount);
//                       }}
//                       style={{ boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)" }}
//                     >
//                       Proceed to Payment
//                     </button>
//                   </div>
//                 )}
//                 {hasReferralCode === false && (
//                   <div className="text-center">
//                     <p className="text-muted" style={{ fontWeight: "bold" }}>
//                       No referral code? No problem!
//                     </p>
//                     <button
//                       className="btn btn-success mt-3 w-100"
//                       onClick={() => {
//                         setShowReferralModal(false);
//                         handleSubscription(selectedPlanId, selectedTotalCount);
//                       }}
//                       style={{ boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)" }}
//                     >
//                       Proceed to Payment
//                     </button>
//                   </div>
//                 )}
//               </div>
//             </Modal.Body>
//           </Modal>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Purchasescreen;
////////////////////////////////////////////////////////////////////////////////////
import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";

const Purchasescreen = () => {
  const [selectedDurations, setSelectedDurations] = useState(["", "", "", ""]);
  const [selectedDivisions, setSelectedDivisions] = useState(["", "", "", ""]);
  const [selectedClasses, setSelectedClasses] = useState(["", "", "", ""]); // New State for Class
  const [totalPrice, setTotalPrice] = useState(0);
  const [selectedPlanId, setSelectedPlanId] = useState(null);
  const [selectedTotalCount, setSelectedTotalCount] = useState(null);
  const [showReferralModal, setShowReferralModal] = useState(false);
  const [referralCodeVerified, setReferralCodeVerified] = useState(false);
  const [verifyingReferral, setVerifyingReferral] = useState(false);
  const [referralInput, setReferralInput] = useState("");
  const [loadingPlan, setLoadingPlan] = useState(null);

  const [showModal, setShowModal] = useState(false);
  const [referralCode, setReferralCode] = useState(null);
  const [hasReferralCode, setHasReferralCode] = useState(null); // Track Yes/No selection

  const [subscription, setSubscription] = useState(null);
  const [loadingUnsubscribe, setLoadingUnsubscribe] = useState(false);

  const userstate = useSelector((state) => state.loginUserReducer);
  const { currentUser1 } = userstate;

  const durations = ["Monthly", "Quarterly", "Half Yearly", "Yearly"];
  const divisions = ["Udbodh", "Bauddha Dakshata", "Kye Bye", "Abhyudaya"];
  const classes = [...Array(12).keys()]
    .map((num) => (num + 1).toString())
    .concat(["Already passed 12th"]);

  const plans = {
    Udbodh: {
      Monthly: 300,
      Quarterly: 878,
      "Half Yearly": 1710,
      Yearly: 3240,
    },
    "Bauddha Dakshata": {
      Monthly: 500,
      Quarterly: 1463,
      "Half Yearly": 2850,
      Yearly: 5400,
    },
    "Kye Bye": {
      Monthly: 500,
      Quarterly: 1463,
      "Half Yearly": 2850,
      Yearly: 5400,
    },
    Abhyudaya: {
      Monthly: 1000, //process.env.REACT_APP_PLAN_ID_ABHYUDAYA_MONTHLY,
      Quarterly: 2925, //process.env.REACT_APP_PLAN_ID_ABHYUDAYA_QUARTERLY,
      "Half Yearly": 5700, //process.env.REACT_APP_PLAN_ID_ABHYUDAYA_HALFYEARLY,
      Yearly: 10800, //process.env.REACT_APP_PLAN_ID_ABHYUDAYA_ANNUALLY,
    },
    "Udbodh + Bauddha Dakshata": {
      Monthly: 700, //process.env.REACT_APP_PLAN_ID_COMBO_ONE_MONTHLY,
      Quarterly: 2048, //process.env.REACT_APP_PLAN_ID_COMBO_ONE_QUARTERLY,
      "Half Yearly": 3990, //process.env.REACT_APP_PLAN_ID_COMBO_ONE_HALFYEARLY,
      Yearly: 7560, //process.env.REACT_APP_PLAN_ID_COMBO_ONE_ANNUALLY,
    },
    "Bauddha Dakshata + Udbodh": {
      Monthly: 700, //process.env.REACT_APP_PLAN_ID_COMBO_ONE_MONTHLY,
      Quarterly: 2048, //process.env.REACT_APP_PLAN_ID_COMBO_ONE_QUARTERLY,
      "Half Yearly": 3990, //process.env.REACT_APP_PLAN_ID_COMBO_ONE_HALFYEARLY,
      Yearly: 7560, //process.env.REACT_APP_PLAN_ID_COMBO_ONE_ANNUALLY,
    },
    "Udbodh + Kye Bye": {
      Monthly: 700, //process.env.REACT_APP_PLAN_ID_COMBO_TWO_MONTHLY,
      Quarterly: 2048, //process.env.REACT_APP_PLAN_ID_COMBO_TWO_QUARTERLY,
      "Half Yearly": 3990, //process.env.REACT_APP_PLAN_ID_COMBO_TWO_HALFYEARLY,
      Yearly: 7560, //process.env.REACT_APP_PLAN_ID_COMBO_TWO_ANNUALLY,
    },
    "Kye Bye + Udbodh": {
      Monthly: 700, //process.env.REACT_APP_PLAN_ID_COMBO_TWO_MONTHLY,
      Quarterly: 2048, //process.env.REACT_APP_PLAN_ID_COMBO_TWO_QUARTERLY,
      "Half Yearly": 3990, //process.env.REACT_APP_PLAN_ID_COMBO_TWO_HALFYEARLY,
      Yearly: 7560, //process.env.REACT_APP_PLAN_ID_COMBO_TWO_ANNUALLY,
    },
    "Udbodh + Abhyudaya": {
      Monthly: 1200, //process.env.REACT_APP_PLAN_ID_COMBO_THREE_MONTHLY,
      Quarterly: 3510, //process.env.REACT_APP_PLAN_ID_COMBO_THREE_QUARTERLY,
      "Half Yearly": 6840, //process.env.REACT_APP_PLAN_ID_COMBO_THREE_HALFYEARLY,
      Yearly: 12960, //process.env.REACT_APP_PLAN_ID_COMBO_THREE_ANNUALLY,
    },
    "Abhyudaya + Udbodh": {
      Monthly: 1200, //process.env.REACT_APP_PLAN_ID_COMBO_THREE_MONTHLY,
      Quarterly: 3510, //process.env.REACT_APP_PLAN_ID_COMBO_THREE_QUARTERLY,
      "Half Yearly": 6840, //process.env.REACT_APP_PLAN_ID_COMBO_THREE_HALFYEARLY,
      Yearly: 12960, //process.env.REACT_APP_PLAN_ID_COMBO_THREE_ANNUALLY,
    },
    "Bauddha Dakshata + Kye Bye": {
      Monthly: 900, //process.env.REACT_APP_PLAN_ID_COMBO_FOUR_MONTHLY,
      Quarterly: 2633, //process.env.REACT_APP_PLAN_ID_COMBO_FOUR_QUARTERLY,
      "Half Yearly": 5130, //process.env.REACT_APP_PLAN_ID_COMBO_FOUR_HALFYEARLY,
      Yearly: 9720, //process.env.REACT_APP_PLAN_ID_COMBO_FOUR_ANNUALLY,
    },
    "Kye Bye + Bauddha Dakshata": {
      Monthly: 900, //process.env.REACT_APP_PLAN_ID_COMBO_FOUR_MONTHLY,
      Quarterly: 2633, //process.env.REACT_APP_PLAN_ID_COMBO_FOUR_QUARTERLY,
      "Half Yearly": 5130, //process.env.REACT_APP_PLAN_ID_COMBO_FOUR_HALFYEARLY,
      Yearly: 9720, //process.env.REACT_APP_PLAN_ID_COMBO_FOUR_ANNUALLY,
    },
    "Bauddha Dakshata + Abhyudaya": {
      Monthly: 1400, //process.env.REACT_APP_PLAN_ID_COMBO_FIVE_MONTHLY,
      Quarterly: 4095, //process.env.REACT_APP_PLAN_ID_COMBO_FIVE_QUARTERLY,
      "Half Yearly": 7980, //process.env.REACT_APP_PLAN_ID_COMBO_FIVE_HALFYEARLY,
      Yearly: 15120, //process.env.REACT_APP_PLAN_ID_COMBO_FIVE_ANNUALLY,
    },
    "Abhyudaya + Bauddha Dakshata": {
      Monthly: 1400, //process.env.REACT_APP_PLAN_ID_COMBO_FIVE_MONTHLY,
      Quarterly: 4095, //process.env.REACT_APP_PLAN_ID_COMBO_FIVE_QUARTERLY,
      "Half Yearly": 7980, //process.env.REACT_APP_PLAN_ID_COMBO_FIVE_HALFYEARLY,
      Yearly: 15120, //process.env.REACT_APP_PLAN_ID_COMBO_FIVE_ANNUALLY,
    },
    "Kye Bye + Abhyudaya": {
      Monthly: 1400, //process.env.REACT_APP_PLAN_ID_COMBO_SIX_MONTHLY,
      Quarterly: 4095, //process.env.REACT_APP_PLAN_ID_COMBO_SIX_QUARTERLY,
      "Half Yearly": 7980, //process.env.REACT_APP_PLAN_ID_COMBO_SIX_HALFYEARLY,
      Yearly: 15120, //process.env.REACT_APP_PLAN_ID_COMBO_SIX_ANNUALLY,
    },
    "Abhyudaya + Kye Bye": {
      Monthly: 1400, //process.env.REACT_APP_PLAN_ID_COMBO_SIX_MONTHLY,
      Quarterly: 4095, //process.env.REACT_APP_PLAN_ID_COMBO_SIX_QUARTERLY,
      "Half Yearly": 7980, //process.env.REACT_APP_PLAN_ID_COMBO_SIX_HALFYEARLY,
      Yearly: 15120, //process.env.REACT_APP_PLAN_ID_COMBO_SIX_ANNUALLY,
    },
    "Udbodh + Bauddha Dakshata + Kye Bye": {
      Monthly: 1150, //process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_MONTHLY,
      Quarterly: 3364, //process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_QUARTERLY,
      "Half Yearly": 6555, //process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_HALFYEARLY,
      Yearly: 12420, //process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_ANNUALLY,
    },
    "Udbodh + Kye Bye + Bauddha Dakshata": {
      Monthly: 1150, //process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_MONTHLY,
      Quarterly: 3364, //process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_QUARTERLY,
      "Half Yearly": 6555, //process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_HALFYEARLY,
      Yearly: 12420, //process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_ANNUALLY,
    },
    "Bauddha Dakshata + Kye Bye + Udbodh": {
      Monthly: 1150, //process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_MONTHLY,
      Quarterly: 3364, //process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_QUARTERLY,
      "Half Yearly": 6555, //process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_HALFYEARLY,
      Yearly: 12420, //process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_ANNUALLY,
    },
    "Bauddha Dakshata + Udbodh + Kye Bye": {
      Monthly: 1150, //process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_MONTHLY,
      Quarterly: 3364, //process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_QUARTERLY,
      "Half Yearly": 6555, //process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_HALFYEARLY,
      Yearly: 12420, //process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_ANNUALLY,
    },
    "Kye Bye + Udbodh + Bauddha Dakshata": {
      Monthly: 1150, //process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_MONTHLY,
      Quarterly: 3364, //process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_QUARTERLY,
      "Half Yearly": 6555, //process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_HALFYEARLY,
      Yearly: 12420, //process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_ANNUALLY,
    },
    "Kye Bye + Bauddha Dakshata + Udbodh": {
      Monthly: 1150, //process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_MONTHLY,
      Quarterly: 3364, //process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_QUARTERLY,
      "Half Yearly": 6555, //process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_HALFYEARLY,
      Yearly: 12420, //process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_ANNUALLY,
    },
    "Udbodh + Bauddha Dakshata + Kye Bye + Abhyudaya": {
      Monthly: 2100, //process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
      Quarterly: 6143, //process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
      "Half Yearly": 11970, //process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
      Yearly: 22680, //process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
    },
    "Udbodh + Bauddha Dakshata + Abhyudaya + Kye Bye": {
      Monthly: 2100, //process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
      Quarterly: 6143, //process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
      "Half Yearly": 11970, //process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
      Yearly: 22680, //process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
    },
    "Udbodh + Kye Bye + Bauddha Dakshata + Abhyudaya": {
      Monthly: 2100, //process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
      Quarterly: 6143, //process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
      "Half Yearly": 11970, //process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
      Yearly: 22680, //process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
    },
    "Udbodh + Kye Bye + Abhyudaya + Bauddha Dakshata": {
      Monthly: 2100, //process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
      Quarterly: 6143, //process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
      "Half Yearly": 11970, //process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
      Yearly: 22680, //process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
    },
    "Udbodh + Abhyudaya + Bauddha Dakshata + Kye Bye": {
      Monthly: 2100, //process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
      Quarterly: 6143, //process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
      "Half Yearly": 11970, //process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
      Yearly: 22680, //process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
    },
    "Udbodh + Abhyudaya + Kye Bye + Bauddha Dakshata": {
      Monthly: 2100, //process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
      Quarterly: 6143, //process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
      "Half Yearly": 11970, //process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
      Yearly: 22680, //process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
    },
    "Bauddha Dakshata + Udbodh + Kye Bye + Abhyudaya": {
      Monthly: 2100, //process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
      Quarterly: 6143, //process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
      "Half Yearly": 11970, //process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
      Yearly: 22680, //process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
    },
    "Bauddha Dakshata + Udbodh + Abhyudaya + Kye Bye": {
      Monthly: 2100, //process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
      Quarterly: 6143, //process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
      "Half Yearly": 11970, //process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
      Yearly: 22680, //process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
    },
    "Bauddha Dakshata + Kye Bye + Udbodh + Abhyudaya": {
      Monthly: 2100, //process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
      Quarterly: 6143, //process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
      "Half Yearly": 11970, //process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
      Yearly: 22680, //process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
    },
    "Bauddha Dakshata + Kye Bye + Abhyudaya + Udbodh": {
      Monthly: 2100, //process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
      Quarterly: 6143, //process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
      "Half Yearly": 11970, //process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
      Yearly: 22680, //process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
    },
    "Bauddha Dakshata + Abhyudaya + Udbodh + Kye Bye": {
      Monthly: 2100, //process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
      Quarterly: 6143, //process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
      "Half Yearly": 11970, //process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
      Yearly: 22680, //process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
    },
    "Bauddha Dakshata + Abhyudaya + Kye Bye + Udbodh": {
      Monthly: 2100, //process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
      Quarterly: 6143, //process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
      "Half Yearly": 11970, //process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
      Yearly: 22680, //process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
    },
    "Kye Bye + Udbodh + Bauddha Dakshata + Abhyudaya": {
      Monthly: 2100, //process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
      Quarterly: 6143, //process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
      "Half Yearly": 11970, //process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
      Yearly: 22680, //process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
    },
    "Kye Bye + Udbodh + Abhyudaya + Bauddha Dakshata": {
      Monthly: 2100, //process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
      Quarterly: 6143, //process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
      "Half Yearly": 11970, //process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
      Yearly: 22680, //process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
    },
    "Kye Bye + Bauddha Dakshata + Udbodh + Abhyudaya": {
      Monthly: 2100, //process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
      Quarterly: 6143, //process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
      "Half Yearly": 11970, //process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
      Yearly: 22680, //process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
    },
    "Kye Bye + Bauddha Dakshata + Abhyudaya + Udbodh": {
      Monthly: 2100, //process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
      Quarterly: 6143, //process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
      "Half Yearly": 11970, //process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
      Yearly: 22680, //process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
    },
    "Kye Bye + Abhyudaya + Udbodh + Bauddha Dakshata": {
      Monthly: 2100, //process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
      Quarterly: 6143, //process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
      "Half Yearly": 11970, //process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
      Yearly: 22680, //process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
    },
    "Kye Bye + Abhyudaya + Bauddha Dakshata + Udbodh": {
      Monthly: 2100, //process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
      Quarterly: 6143, //process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
      "Half Yearly": 11970, //process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
      Yearly: 22680, //process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
    },
    "Abhyudaya + Udbodh + Bauddha Dakshata + Kye Bye": {
      Monthly: 2100, //process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
      Quarterly: 6143, //process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
      "Half Yearly": 11970, //process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
      Yearly: 22680, //process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
    },
    "Abhyudaya + Udbodh + Kye Bye + Bauddha Dakshata": {
      Monthly: 2100, //process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
      Quarterly: 6143, //process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
      "Half Yearly": 11970, //process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
      Yearly: 22680, //process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
    },
    "Abhyudaya + Bauddha Dakshata + Udbodh + Kye Bye": {
      Monthly: 2100, //process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
      Quarterly: 6143, //process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
      "Half Yearly": 11970, //process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
      Yearly: 22680, //process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
    },
    "Abhyudaya + Bauddha Dakshata + Kye Bye + Udbodh": {
      Monthly: 2100, //process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
      Quarterly: 6143, //process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
      "Half Yearly": 11970, //process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
      Yearly: 22680, //process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
    },
    "Abhyudaya + Kye Bye + Udbodh + Bauddha Dakshata": {
      Monthly: 2100, //process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
      Quarterly: 6143, //process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
      "Half Yearly": 11970, //process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
      Yearly: 22680, //process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
    },
    "Abhyudaya + Kye Bye + Bauddha Dakshata + Udbodh": {
      Monthly: 2100, //process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
      Quarterly: 6143, //process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
      "Half Yearly": 11970, //process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
      Yearly: 22680, //process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
    },
  };

  const planIds = {
    Udbodh: {
      Monthly: process.env.REACT_APP_PLAN_ID_UDBODH_MONTHLY,
      Quarterly: process.env.REACT_APP_PLAN_ID_UDBODH_QUARTERLY,
      "Half Yearly": process.env.REACT_APP_PLAN_ID_UDBODH_HALFYEARLY,
      Yearly: process.env.REACT_APP_PLAN_ID_UDBODH_ANNUALLY,
    },
    "Bauddha Dakshata": {
      Monthly: process.env.REACT_APP_PLAN_ID_BD_MONTHLY,
      Quarterly: process.env.REACT_APP_PLAN_ID_BD_QUARTERLY,
      "Half Yearly": process.env.REACT_APP_PLAN_ID_BD_HALFYEARLY,
      Yearly: process.env.REACT_APP_PLAN_ID_BD_ANNUALLY,
    },
    "Kye Bye": {
      Monthly: process.env.REACT_APP_PLAN_ID_KYEBYE_MONTHLY,
      Quarterly: process.env.REACT_APP_PLAN_ID_KYEBYE_QUARTERLY,
      "Half Yearly": process.env.REACT_APP_PLAN_ID_KYEBYE_HALFYEARLY,
      Yearly: process.env.REACT_APP_PLAN_ID_KYEBYE_ANNUALLY,
    },
    Abhyudaya: {
      Monthly: process.env.REACT_APP_PLAN_ID_ABHYUDAYA_MONTHLY,
      Quarterly: process.env.REACT_APP_PLAN_ID_ABHYUDAYA_QUARTERLY,
      "Half Yearly": process.env.REACT_APP_PLAN_ID_ABHYUDAYA_HALFYEARLY,
      Yearly: process.env.REACT_APP_PLAN_ID_ABHYUDAYA_ANNUALLY,
    },
    "Udbodh + Bauddha Dakshata": {
      Monthly: process.env.REACT_APP_PLAN_ID_COMBO_ONE_MONTHLY,
      Quarterly: process.env.REACT_APP_PLAN_ID_COMBO_ONE_QUARTERLY,
      "Half Yearly": process.env.REACT_APP_PLAN_ID_COMBO_ONE_HALFYEARLY,
      Yearly: process.env.REACT_APP_PLAN_ID_COMBO_ONE_ANNUALLY,
    },
    "Bauddha Dakshata + Udbodh": {
      Monthly: process.env.REACT_APP_PLAN_ID_COMBO_ONE_MONTHLY,
      Quarterly: process.env.REACT_APP_PLAN_ID_COMBO_ONE_QUARTERLY,
      "Half Yearly": process.env.REACT_APP_PLAN_ID_COMBO_ONE_HALFYEARLY,
      Yearly: process.env.REACT_APP_PLAN_ID_COMBO_ONE_ANNUALLY,
    },
    "Udbodh + Kye Bye": {
      Monthly: process.env.REACT_APP_PLAN_ID_COMBO_TWO_MONTHLY,
      Quarterly: process.env.REACT_APP_PLAN_ID_COMBO_TWO_QUARTERLY,
      "Half Yearly": process.env.REACT_APP_PLAN_ID_COMBO_TWO_HALFYEARLY,
      Yearly: process.env.REACT_APP_PLAN_ID_COMBO_TWO_ANNUALLY,
    },
    "Kye Bye + Udbodh": {
      Monthly: process.env.REACT_APP_PLAN_ID_COMBO_TWO_MONTHLY,
      Quarterly: process.env.REACT_APP_PLAN_ID_COMBO_TWO_QUARTERLY,
      "Half Yearly": process.env.REACT_APP_PLAN_ID_COMBO_TWO_HALFYEARLY,
      Yearly: process.env.REACT_APP_PLAN_ID_COMBO_TWO_ANNUALLY,
    },
    "Udbodh + Abhyudaya": {
      Monthly: process.env.REACT_APP_PLAN_ID_COMBO_THREE_MONTHLY,
      Quarterly: process.env.REACT_APP_PLAN_ID_COMBO_THREE_QUARTERLY,
      "Half Yearly": process.env.REACT_APP_PLAN_ID_COMBO_THREE_HALFYEARLY,
      Yearly: process.env.REACT_APP_PLAN_ID_COMBO_THREE_ANNUALLY,
    },
    "Abhyudaya + Udbodh": {
      Monthly: process.env.REACT_APP_PLAN_ID_COMBO_THREE_MONTHLY,
      Quarterly: process.env.REACT_APP_PLAN_ID_COMBO_THREE_QUARTERLY,
      "Half Yearly": process.env.REACT_APP_PLAN_ID_COMBO_THREE_HALFYEARLY,
      Yearly: process.env.REACT_APP_PLAN_ID_COMBO_THREE_ANNUALLY,
    },
    "Bauddha Dakshata + Kye Bye": {
      Monthly: process.env.REACT_APP_PLAN_ID_COMBO_FOUR_MONTHLY,
      Quarterly: process.env.REACT_APP_PLAN_ID_COMBO_FOUR_QUARTERLY,
      "Half Yearly": process.env.REACT_APP_PLAN_ID_COMBO_FOUR_HALFYEARLY,
      Yearly: process.env.REACT_APP_PLAN_ID_COMBO_FOUR_ANNUALLY,
    },
    "Kye Bye + Bauddha Dakshata": {
      Monthly: process.env.REACT_APP_PLAN_ID_COMBO_FOUR_MONTHLY,
      Quarterly: process.env.REACT_APP_PLAN_ID_COMBO_FOUR_QUARTERLY,
      "Half Yearly": process.env.REACT_APP_PLAN_ID_COMBO_FOUR_HALFYEARLY,
      Yearly: process.env.REACT_APP_PLAN_ID_COMBO_FOUR_ANNUALLY,
    },
    "Bauddha Dakshata + Abhyudaya": {
      Monthly: process.env.REACT_APP_PLAN_ID_COMBO_FIVE_MONTHLY,
      Quarterly: process.env.REACT_APP_PLAN_ID_COMBO_FIVE_QUARTERLY,
      "Half Yearly": process.env.REACT_APP_PLAN_ID_COMBO_FIVE_HALFYEARLY,
      Yearly: process.env.REACT_APP_PLAN_ID_COMBO_FIVE_ANNUALLY,
    },
    "Abhyudaya + Bauddha Dakshata": {
      Monthly: process.env.REACT_APP_PLAN_ID_COMBO_FIVE_MONTHLY,
      Quarterly: process.env.REACT_APP_PLAN_ID_COMBO_FIVE_QUARTERLY,
      "Half Yearly": process.env.REACT_APP_PLAN_ID_COMBO_FIVE_HALFYEARLY,
      Yearly: process.env.REACT_APP_PLAN_ID_COMBO_FIVE_ANNUALLY,
    },
    "Kye Bye + Abhyudaya": {
      Monthly: process.env.REACT_APP_PLAN_ID_COMBO_SIX_MONTHLY,
      Quarterly: process.env.REACT_APP_PLAN_ID_COMBO_SIX_QUARTERLY,
      "Half Yearly": process.env.REACT_APP_PLAN_ID_COMBO_SIX_HALFYEARLY,
      Yearly: process.env.REACT_APP_PLAN_ID_COMBO_SIX_ANNUALLY,
    },
    "Abhyudaya + Kye Bye": {
      Monthly: process.env.REACT_APP_PLAN_ID_COMBO_SIX_MONTHLY,
      Quarterly: process.env.REACT_APP_PLAN_ID_COMBO_SIX_QUARTERLY,
      "Half Yearly": process.env.REACT_APP_PLAN_ID_COMBO_SIX_HALFYEARLY,
      Yearly: process.env.REACT_APP_PLAN_ID_COMBO_SIX_ANNUALLY,
    },
    "Udbodh + Bauddha Dakshata + Kye Bye": {
      Monthly: process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_MONTHLY,
      Quarterly: process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_QUARTERLY,
      "Half Yearly": process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_HALFYEARLY,
      Yearly: process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_ANNUALLY,
    },
    "Udbodh + Kye Bye + Bauddha Dakshata": {
      Monthly: process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_MONTHLY,
      Quarterly: process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_QUARTERLY,
      "Half Yearly": process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_HALFYEARLY,
      Yearly: process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_ANNUALLY,
    },
    "Bauddha Dakshata + Kye Bye + Udbodh": {
      Monthly: process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_MONTHLY,
      Quarterly: process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_QUARTERLY,
      "Half Yearly": process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_HALFYEARLY,
      Yearly: process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_ANNUALLY,
    },
    "Bauddha Dakshata + Udbodh + Kye Bye": {
      Monthly: process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_MONTHLY,
      Quarterly: process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_QUARTERLY,
      "Half Yearly": process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_HALFYEARLY,
      Yearly: process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_ANNUALLY,
    },
    "Kye Bye + Udbodh + Bauddha Dakshata": {
      Monthly: process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_MONTHLY,
      Quarterly: process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_QUARTERLY,
      "Half Yearly": process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_HALFYEARLY,
      Yearly: process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_ANNUALLY,
    },
    "Kye Bye + Bauddha Dakshata + Udbodh": {
      Monthly: process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_MONTHLY,
      Quarterly: process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_QUARTERLY,
      "Half Yearly": process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_HALFYEARLY,
      Yearly: process.env.REACT_APP_PLAN_ID_COMBO_SEVEN_ANNUALLY,
    },
    "Udbodh + Bauddha Dakshata + Kye Bye + Abhyudaya": {
      Monthly: process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
      Quarterly: process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
      "Half Yearly": process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
      Yearly: process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
    },
    "Udbodh + Bauddha Dakshata + Abhyudaya + Kye Bye": {
      Monthly: process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
      Quarterly: process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
      "Half Yearly": process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
      Yearly: process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
    },
    "Udbodh + Kye Bye + Bauddha Dakshata + Abhyudaya": {
      Monthly: process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
      Quarterly: process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
      "Half Yearly": process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
      Yearly: process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
    },
    "Udbodh + Kye Bye + Abhyudaya + Bauddha Dakshata": {
      Monthly: process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
      Quarterly: process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
      "Half Yearly": process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
      Yearly: process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
    },
    "Udbodh + Abhyudaya + Bauddha Dakshata + Kye Bye": {
      Monthly: process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
      Quarterly: process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
      "Half Yearly": process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
      Yearly: process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
    },
    "Udbodh + Abhyudaya + Kye Bye + Bauddha Dakshata": {
      Monthly: process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
      Quarterly: process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
      "Half Yearly": process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
      Yearly: process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
    },
    "Bauddha Dakshata + Udbodh + Kye Bye + Abhyudaya": {
      Monthly: process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
      Quarterly: process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
      "Half Yearly": process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
      Yearly: process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
    },
    "Bauddha Dakshata + Udbodh + Abhyudaya + Kye Bye": {
      Monthly: process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
      Quarterly: process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
      "Half Yearly": process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
      Yearly: process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
    },
    "Bauddha Dakshata + Kye Bye + Udbodh + Abhyudaya": {
      Monthly: process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
      Quarterly: process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
      "Half Yearly": process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
      Yearly: process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
    },
    "Bauddha Dakshata + Kye Bye + Abhyudaya + Udbodh": {
      Monthly: process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
      Quarterly: process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
      "Half Yearly": process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
      Yearly: process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
    },
    "Bauddha Dakshata + Abhyudaya + Udbodh + Kye Bye": {
      Monthly: process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
      Quarterly: process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
      "Half Yearly": process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
      Yearly: process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
    },
    "Bauddha Dakshata + Abhyudaya + Kye Bye + Udbodh": {
      Monthly: process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
      Quarterly: process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
      "Half Yearly": process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
      Yearly: process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
    },
    "Kye Bye + Udbodh + Bauddha Dakshata + Abhyudaya": {
      Monthly: process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
      Quarterly: process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
      "Half Yearly": process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
      Yearly: process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
    },
    "Kye Bye + Udbodh + Abhyudaya + Bauddha Dakshata": {
      Monthly: process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
      Quarterly: process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
      "Half Yearly": process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
      Yearly: process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
    },
    "Kye Bye + Bauddha Dakshata + Udbodh + Abhyudaya": {
      Monthly: process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
      Quarterly: process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
      "Half Yearly": process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
      Yearly: process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
    },
    "Kye Bye + Bauddha Dakshata + Abhyudaya + Udbodh": {
      Monthly: process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
      Quarterly: process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
      "Half Yearly": process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
      Yearly: process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
    },
    "Kye Bye + Abhyudaya + Udbodh + Bauddha Dakshata": {
      Monthly: process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
      Quarterly: process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
      "Half Yearly": process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
      Yearly: process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
    },
    "Kye Bye + Abhyudaya + Bauddha Dakshata + Udbodh": {
      Monthly: process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
      Quarterly: process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
      "Half Yearly": process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
      Yearly: process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
    },
    "Abhyudaya + Udbodh + Bauddha Dakshata + Kye Bye": {
      Monthly: process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
      Quarterly: process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
      "Half Yearly": process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
      Yearly: process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
    },
    "Abhyudaya + Udbodh + Kye Bye + Bauddha Dakshata": {
      Monthly: process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
      Quarterly: process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
      "Half Yearly": process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
      Yearly: process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
    },
    "Abhyudaya + Bauddha Dakshata + Udbodh + Kye Bye": {
      Monthly: process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
      Quarterly: process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
      "Half Yearly": process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
      Yearly: process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
    },
    "Abhyudaya + Bauddha Dakshata + Kye Bye + Udbodh": {
      Monthly: process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
      Quarterly: process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
      "Half Yearly": process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
      Yearly: process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
    },
    "Abhyudaya + Kye Bye + Udbodh + Bauddha Dakshata": {
      Monthly: process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
      Quarterly: process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
      "Half Yearly": process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
      Yearly: process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
    },
    "Abhyudaya + Kye Bye + Bauddha Dakshata + Udbodh": {
      Monthly: process.env.REACT_APP_PLAN_ID_EIGHT_MONTHLY,
      Quarterly: process.env.REACT_APP_PLAN_ID_EIGHT_QUARTERLY,
      "Half Yearly": process.env.REACT_APP_PLAN_ID_EIGHT_HALFYEARLY,
      Yearly: process.env.REACT_APP_PLAN_ID_EIGHT_ANNUALLY,
    },

    // Add other combinations here...
  };

  const totalCounts = {
    Yearly: 8,
    Quarterly: 32,
    "Half Yearly": 16,
    Monthly: 96,
  };

  useEffect(() => {
    // Fetch user subscription data
    const fetchSubscription = async () => {
      try {
        const res = await fetch(`/api/users/${currentUser1._id}`);
        const data = await res.json();
        setSubscription(data.subscription);
      } catch (error) {
        console.error("Error fetching subscription:", error);
      }
    };

    if (currentUser1 && currentUser1._id) {
      fetchSubscription();
    }
  }, [currentUser1]);

  const handleUnsubscribe = async () => {
    if (!subscription?.id) {
      toast.error("No active subscription found.");
      return;
    }

    setLoadingUnsubscribe(true);
    try {
      const res = await fetch(`/api/payment/unsubscribe`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          subscriptionId: subscription.id,
          userId: currentUser1._id,
        }),
      });

      const data = await res.json();

      if (data.message) {
        toast.success(data.message);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    } catch (error) {
      console.log(error);
      toast.error("Error unsubscribing from the plan.");
    } finally {
      setLoadingUnsubscribe(false);
    }
  };

  // Handle modal close event
  const handleCloseModal = () => {
    setShowModal(false);

    // Trigger page reload only when modal is closed
    window.location.reload();
  };

  const handleDivisionChange = (index, value) => {
    const updatedDivisions = [...selectedDivisions];
    updatedDivisions[index] = value;
    setSelectedDivisions(updatedDivisions);
    calculateTotalPrice(updatedDivisions, selectedDurations);

    const updatedClasses = [...selectedClasses];

    if (value === "Abhyudaya" || value === "Kye Bye") {
      updatedClasses[index] = "Already passed 12th";
    } else if (value === "Udbodh" || value === "Bauddha Dakshata") {
      // Find if another row already has Udbodh or Bauddha Dakshata with a selected class
      const matchingIndex = selectedDivisions.findIndex(
        (div, i) =>
          i !== index &&
          (div === "Udbodh" || div === "Bauddha Dakshata") &&
          selectedClasses[i]
      );

      // If found, update this row's class to match
      if (matchingIndex !== -1) {
        updatedClasses[index] = selectedClasses[matchingIndex];
      }
    }

    setSelectedClasses(updatedClasses);
  };

  const handleClassChange = (index, value) => {
    const updatedClasses = [...selectedClasses];
    updatedClasses[index] = value;

    // If this row belongs to Udbodh or Bauddha Dakshata, update all similar divisions
    if (
      selectedDivisions[index] === "Udbodh" ||
      selectedDivisions[index] === "Bauddha Dakshata"
    ) {
      selectedDivisions.forEach((div, i) => {
        if (i !== index && (div === "Udbodh" || div === "Bauddha Dakshata")) {
          updatedClasses[i] = value; // Sync all similar divisions
        }
      });
    }

    setSelectedClasses(updatedClasses);
  };

  const handleDurationChange = (index, value) => {
    const updatedDurations = selectedDurations.map(() => value); // Set all to the same value
    setSelectedDurations(updatedDurations);
    calculateTotalPrice(selectedDivisions, updatedDurations);
  };

  const calculateTotalPrice = (divisions, durations) => {
    let price = 0;
    let planKey = divisions.filter((d) => d).join(" + "); // Join only non-empty values

    if (planKey && plans[planKey] && durations[0]) {
      price = plans[planKey][durations[0]] || 0;
    }

    setTotalPrice(price);
    setSelectedPlanId(planIds[planKey]?.[durations[0]] || null);
    setSelectedTotalCount(totalCounts[durations[0]] || null);
  };

  const handleProceedToPayment = () => {
    if (!selectedPlanId || !selectedTotalCount) {
      toast.error("Please complete your selections.");
      return;
    }

    setShowReferralModal(true);
    setReferralCodeVerified(false);
  };

  const verifyReferralCode = async () => {
    setVerifyingReferral(true);
    try {
      const res = await fetch(`/api/users/verifyReferral`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ referralCode: referralInput.trim() }),
      });
      const data = await res.json();

      if (data.success) {
        setReferralCodeVerified(true);
        toast.success("Referral code verified successfully!");
      } else {
        toast.error("Invalid referral code.");
      }
    } catch (error) {
      toast.error("Failed to verify referral code.");
    } finally {
      setVerifyingReferral(false);
    }
  };

  // handleSubscription Function
  const handleSubscription = async (plan_id, total_count) => {
    setLoadingPlan(plan_id); // Set loading for the clicked plan
    try {
      const res = await fetch(`/api/payment/subscription`, {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({
          plan_id,
          userId: currentUser1._id, // Pass user ID to backend
          total_count,
          referralCode: referralInput.trim(), // Include referral code in request
        }),
      });

      const data = await res.json();
      console.log(data);
      handlePaymentVerify(data.data);
      // setReferralCode(data.referralCode); // Set referral code if returned
      // setShowModal(true);
      setLoadingPlan(null); // Reset loading state in case of error
    } catch (error) {
      console.log(error);
      setLoadingPlan(null); // Reset loading state in case of error
    }
  };

  // handlePaymentVerify Function
  const handlePaymentVerify = async (data) => {
    const options = {
      key: process.env.REACT_APP_RAZORPAY_KEY_ID,
      subscription_id: data.id,
      name: "Gyankosha",
      description: "Payment for Quiz Subscription",
      handler: async (response) => {
        console.log("response", response);
        try {
          const res = await fetch(`/api/payment/verify`, {
            method: "POST",
            headers: {
              "content-type": "application/json",
            },
            body: JSON.stringify({
              razorpay_subscription_id: response.razorpay_subscription_id,
              razorpay_payment_id: response.razorpay_payment_id,
              razorpay_signature: response.razorpay_signature,
              userId: currentUser1._id, // Pass user ID for verification
              paidfor: selectedDivisions.map(
                (division, index) => `${division}-${selectedClasses[index]}`
              ), // Store "Division-Class"
            }),
          });

          const verifyData = await res.json();

          if (verifyData.message) {
            toast.success(verifyData.message);
            console.log("verifyData.referralCode : ", verifyData.referralCode);
            if (verifyData.referralCode) {
              setReferralCode(verifyData.referralCode);
              setShowModal(true);
            }
            // Reload the page after 2 seconds
            // setTimeout(() => {
            //   window.location.reload();
            // }, 2000);
          }
        } catch (error) {
          console.log(error);
        } finally {
          setLoadingPlan(null); // Reset loading state in case of error
        }
      },
      theme: {
        color: "#5f63b8",
      },
    };
    const rzp1 = new window.Razorpay(options);
    rzp1.open();
  };

  const getIndividualPrice = (division, duration) => {
    if (division && duration && plans[division]) {
      return plans[division][duration] || 0; // Return price or 0 if not found
    }
    return 0;
  };

  // Reverse mapping of plan IDs to their corresponding division and duration
  const planIdToDetails = {};
  Object.entries(planIds).forEach(([division, durations]) => {
    Object.entries(durations).forEach(([duration, planId]) => {
      planIdToDetails[planId] = { division, duration };
    });
  });

  // Function to get user-friendly subscription details
  const getSubscriptionDetails = (planId) => {
    if (planIdToDetails[planId]) {
      return `Division ${planIdToDetails[planId].division} with ${planIdToDetails[planId].duration} billing cycle.`;
    }
    return "Unknown Subscription Plan";
  };

  return (
    <div className="row justify-content-center">
      <div className="col-md-9">
        <div
          className="pricing-table"
          style={{
            fontFamily: '"Libre Baskerville", serif',
            margin: "100px 25px 200px 25px",
          }}
        >
          <h3>Your Subscription:</h3>

          {subscription?.status === "active" ? (
            <div>
              <p>
                You are currently subscribed to:{" "}
                <strong>{getSubscriptionDetails(subscription.plan)}</strong>
              </p>
              <button
                className="btn btn-danger mb-5"
                onClick={handleUnsubscribe}
                disabled={loadingUnsubscribe}
              >
                {loadingUnsubscribe
                  ? "Unsubscribing..."
                  : "Unsubscribe first to purchase a new plan"}
              </button>
            </div>
          ) : (
            <p className="mb-5">You have no active subscriptions.</p>
          )}

          <h3>Purchase Premium Quizzes:</h3>
          <div className="table-responsive">
            <table
              className="table table-bordered"
              style={{ borderColor: "#343a40", overflow: "visible" }}
            >
              <thead className="table-dark">
                <tr>
                  <th></th>
                  <th>Select Division</th>
                  <th>Select Quiz Class</th>
                  <th>Select Duration</th>
                  <th>Individual Price</th> {/* New Column */}
                </tr>
              </thead>
              <tbody>
                {[0, 1, 2, 3].map((rowIndex) => (
                  <tr key={rowIndex}>
                    <td>Choose your vertical {rowIndex + 1} :</td>
                    <td>
                      <select
                        className="form-control"
                        value={selectedDivisions[rowIndex]}
                        onChange={(e) =>
                          handleDivisionChange(rowIndex, e.target.value)
                        }
                      >
                        <option value="">-- Select Division --</option>
                        {divisions.map((division) => (
                          <option key={division} value={division}>
                            {division}
                          </option>
                        ))}
                      </select>
                    </td>
                    <td>
                      <select
                        className="form-control"
                        value={selectedClasses[rowIndex]}
                        onChange={(e) =>
                          handleClassChange(rowIndex, e.target.value)
                        }
                        disabled={
                          selectedDivisions[rowIndex] === "Abhyudaya" ||
                          selectedDivisions[rowIndex] === "Kye Bye"
                        }
                      >
                        <option value="">-- Select Class --</option>
                        {classes.map((cls) => (
                          <option key={cls} value={cls}>
                            {cls}
                          </option>
                        ))}
                      </select>
                    </td>
                    <td>
                      <select
                        className="form-control"
                        value={selectedDurations[rowIndex]}
                        onChange={(e) =>
                          handleDurationChange(rowIndex, e.target.value)
                        }
                        //disabled={rowIndex !== 0 && !selectedDurations[0]}
                      >
                        <option value="">-- Select Duration --</option>
                        {durations.map((duration) => (
                          <option key={duration} value={duration}>
                            {duration}
                          </option>
                        ))}
                      </select>
                    </td>
                    <td>
                      ₹
                      {getIndividualPrice(
                        selectedDivisions[rowIndex],
                        selectedDurations[rowIndex]
                      )}
                    </td>{" "}
                    {/* New Column */}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "15px",
              marginTop: "10px",
            }}
          >
            <span style={{ fontWeight: "bold" }}>
              Total Price: ₹{totalPrice}
            </span>
            <button
              className="btn btn-primary"
              onClick={handleProceedToPayment}
              disabled={subscription?.status === "active"}
            >
              Proceed
            </button>
          </div>

          <Modal show={showModal} onHide={handleCloseModal} centered>
            <Modal.Header closeButton>
              <Modal.Title>
                Gyankosha's Subscription Active now and your Referral Code :{" "}
                <span style={{ color: "red" }}>{referralCode}</span>
              </Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <div
                style={{ fontFamily: "Arial, sans-serif", lineHeight: "1.6" }}
              >
                <p>
                  Dear <strong>{currentUser1 ? currentUser1.name : ""}</strong>,
                </p>
                <p>
                  Thank you for choosing <strong>Gyankosha</strong> as your
                  trusted education partner. We are thrilled to have you on
                  board! Your subscription plan is now active, giving you access
                  to the premium content and resources.
                </p>
                <p>
                  Attached, you will find your unique Referral Code:{" "}
                  <strong style={{ color: "red" }}>{referralCode}</strong>.
                </p>
                <p>
                  As a token of appreciation for your support, when a new user
                  subscribes and makes their first payment using your referral
                  code, you become eligible for a <strong>20% cashback</strong>{" "}
                  to your account, and the one referred by you becomes eligible
                  for a <strong>10% cashback</strong> to their account. Please
                  note that the cashback is applicable only against the first
                  payment made by the referred user and is valid for one cycle
                  only.
                </p>
                <p>
                  Let’s keep learning, growing, and making education exciting
                  together!
                </p>
                <p>Warm regards,</p>
                <p>
                  <strong>The Gyankosha Team</strong>
                </p>
              </div>
            </Modal.Body>

            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseModal}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal
            show={showReferralModal}
            onHide={() => {
              setShowReferralModal(false);
              setHasReferralCode(null); // Reset the referral code selection
              setReferralInput(""); // Clear referral input field
              setReferralCodeVerified(false); // Reset verification status
              setVerifyingReferral(false); // Reset verifying state
            }}
            onExit={() => {
              setShowReferralModal(false);
              setHasReferralCode(null); // Reset the referral code selection
              setReferralInput(""); // Clear referral input field
              setReferralCodeVerified(false); // Reset verification status
              setVerifyingReferral(false); // Reset verifying state
            }}
            centered
          >
            <Modal.Header closeButton>
              {hasReferralCode === null && (
                <Modal.Title
                  style={{
                    fontFamily: "Arial, sans-serif",
                    fontWeight: "bold",
                  }}
                >
                  Do you have a referral code?
                </Modal.Title>
              )}
            </Modal.Header>
            <Modal.Body
              style={{ fontFamily: "Arial, sans-serif", lineHeight: "1.6" }}
            >
              <div>
                {hasReferralCode === null && (
                  <div className="d-flex flex-column align-items-start">
                    <div className="form-check custom-radio mb-3">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="referralCodeOption"
                        id="referralYes"
                        onChange={() => setHasReferralCode(true)}
                      />
                      <label
                        className="form-check-label ms-2"
                        htmlFor="referralYes"
                      >
                        Yes, I have a referral code
                      </label>
                    </div>
                    <div className="form-check custom-radio">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="referralCodeOption"
                        id="referralNo"
                        onChange={() => setHasReferralCode(false)}
                      />
                      <label
                        className="form-check-label ms-2"
                        htmlFor="referralNo"
                      >
                        No, I don't have a referral code
                      </label>
                    </div>
                  </div>
                )}
                {hasReferralCode === true && !referralCodeVerified && (
                  <div>
                    <label
                      htmlFor="referralInput"
                      style={{ fontWeight: "bold" }}
                    >
                      Enter your referral code:
                    </label>
                    <input
                      type="text"
                      className="form-control mt-2"
                      id="referralInput"
                      placeholder="Enter referral code here"
                      value={referralInput}
                      onChange={(e) => setReferralInput(e.target.value)}
                      style={{
                        border: "1px solid #ced4da",
                        borderRadius: "5px",
                      }}
                    />
                    <button
                      className="btn btn-primary mt-3 w-100"
                      onClick={verifyReferralCode}
                      disabled={verifyingReferral}
                      style={{ boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)" }}
                    >
                      {verifyingReferral
                        ? "Verifying..."
                        : "Verify Referral Code"}
                    </button>
                  </div>
                )}
                {referralCodeVerified && (
                  <div className="text-center">
                    <p className="text-success" style={{ fontWeight: "bold" }}>
                      Referral code verified successfully!
                    </p>
                    <button
                      className="btn btn-success mt-3 w-100"
                      onClick={() => {
                        setShowReferralModal(false);
                        handleSubscription(selectedPlanId, selectedTotalCount);
                      }}
                      style={{ boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)" }}
                    >
                      Proceed to Payment
                    </button>
                  </div>
                )}
                {hasReferralCode === false && (
                  <div className="text-center">
                    <p className="text-muted" style={{ fontWeight: "bold" }}>
                      No referral code? No problem!
                    </p>
                    <button
                      className="btn btn-success mt-3 w-100"
                      onClick={() => {
                        setShowReferralModal(false);
                        handleSubscription(selectedPlanId, selectedTotalCount);
                      }}
                      style={{ boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)" }}
                    >
                      Proceed to Payment
                    </button>
                  </div>
                )}
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default Purchasescreen;
