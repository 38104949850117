// import React, { useState } from "react";
// import { Link, useNavigate } from "react-router-dom";
// import Modal from "react-bootstrap/Modal";
// import axios from "axios";
// import "./Quiz.css";

// function Quiz({
//   quiz,
//   userId,
//   userName,
//   userSchoolcode,
//   userClass,
//   userPaid,
//   userSection,
//   attemptedQuizIds,
//   completedQuizIds,
//   updateDifficulty, // Add this prop to update difficulty in Udbodhscreen
// }) {
//   const navigate = useNavigate();
//   const [showModal, setshowModal] = useState(false);
//   const handleCloseModal = () => setshowModal(false);
//   const handleShowModal = () => setshowModal(true);
//   const isAbhyudayaUser = localStorage.getItem("currentAbhyudayaUser");

//   const modifyImageUrl = (imageUrl) => {
//     const fileId = imageUrl.match(/\/d\/(.+?)\//)[1]; // Extract file ID from URL
//     return `https://lh3.googleusercontent.com/d/${fileId}=s1900?authuser=0`; // Construct thumbnail URL
//   };

//   // Define a common style object for button shadows
//   const buttonShadowStyle = {
//     boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.5)",
//     transition: "background-color 0.3s",
//   };

//   const handleStartOrResumeQuiz = async () => {
//     try {
//       const isAbhyudayaUser =
//         localStorage.getItem("currentAbhyudayaUser") !== null;

//       // Determine the API URL dynamically based on the user type
//       const apiUrl = isAbhyudayaUser
//         ? "/api/abhyudayausers/Abhyudayaattemptquiz"
//         : "/api/users/attemptquiz";

//       if (
//         !attemptedQuizIds.includes(quiz._id) &&
//         !completedQuizIds.includes(quiz._id)
//       ) {
//         await axios.post(apiUrl, {
//           userId,
//           quizId: quiz._id,
//           totalQuestions: quiz.questions.length,
//           correctAnswers: "",
//           wrongAnswers: "",
//           attemptedQuestions: "",
//           division: quiz.division,
//           userName,
//           userSchoolcode,
//           userClass,
//           userSection,
//           quizName: quiz.name,
//           quizCategory: quiz.difficulty,
//           quizSubject: quiz.subject,
//         });
//       }
//       navigate("/questions", {
//         state: {
//           userName: userName,
//           userSchoolcode: userSchoolcode,
//           userClass: userClass,
//           userSection: userSection,
//           name: quiz.name,
//           questions: quiz.questions,
//           division: quiz.division,
//           userId: userId, // Pass userId to QuestionPage
//           quizId: quiz._id, // Pass quizId to QuestionPage
//           quizName: quiz.name,
//           quizCategory: quiz.difficulty,
//           quizSubject: quiz.subject,
//         },
//       });
//     } catch (error) {
//       console.error("Error at Start/Resume Quiz", error);
//     }
//   };

//   const handleFreeExploratoryQuizzes = () => {
//     updateDifficulty("Free Exploratory Quiz"); // Change difficulty to Free Exploratory Quiz
//     setshowModal(false); // Close modal after setting difficulty
//   };

//   const handlePlansforPremiumQuizzes = () => {
//     // Check if the user exists and navigate accordingly
//     navigate(
//       isAbhyudayaUser
//         ? "/abhyudayapaymentplanscreen" // Navigate to Abhyudaya's payment plans screen
//         : "/paymentplans" // Navigate to the general payment plans page
//     );
//   };

//   const getButtonLabel = () => {
//     if (completedQuizIds.includes(quiz._id)) {
//       return "Retake Quiz";
//     }
//     if (attemptedQuizIds.includes(quiz._id)) {
//       return "Resume Quiz";
//     }
//     return "Start Quiz";
//   };
//   // const getButtonLabel = () => {
//   //   console.log("quiz._id : ", quiz._id);
//   //   console.log("completedQuizIds : ", completedQuizIds);
//   //   console.log("attemptedQuizIds : ", attemptedQuizIds);
//   //   // Convert quiz._id to string for comparison
//   //   const quizIdStr = quiz._id.toString();

//   //   // Check if the quiz is in completedQuizIds by comparing strings
//   //   if (completedQuizIds.some((id) => id.toString() === quizIdStr)) {
//   //     console.log("Matched Completed Quiz ID:", quizIdStr);
//   //     return "Retake Quiz";
//   //   }

//   //   // Check if the quiz is in attemptedQuizIds by comparing strings
//   //   if (attemptedQuizIds.some((id) => id.toString() === quizIdStr)) {
//   //     console.log("Matched Attempted Quiz ID:", quizIdStr);
//   //     return "Resume Quiz";
//   //   }

//   //   return "Start Quiz";
//   // };

//   return (
//     <div
//       className="shadow-lg p-3 mb-5 bg-white"
//       id="round"
//       onClick={
//         userPaid || quiz.difficulty === "Free Exploratory Quiz"
//           ? handleStartOrResumeQuiz
//           : handleShowModal
//       }
//       style={{ cursor: "pointer" }} // Adds pointer cursor on hover
//     >
//       {quiz.quiz_image && (
//         <img
//           src={modifyImageUrl(quiz.quiz_image)}
//           alt="Quiz"
//           width="200px"
//           height="200px"
//           className="img-fluid"
//         />
//       )}
//       <br />
//       <h1
//         style={{
//           fontFamily: "'Georgia Ref', Georgia, serif",
//           fontWeight: "bold",
//           fontSize: "20px",
//           textAlign: "center",
//         }}
//       >
//         {quiz.name}
//       </h1>
//       <br />
//       <button
//         className="btn btn-primary"
//         style={{
//           ...buttonShadowStyle,
//           fontFamily: "'Georgia Ref', Georgia, serif",
//           fontSize: "25px",
//           textAlign: "center",
//         }}
//         onClick={(e) => {
//           e.stopPropagation(); // Prevents the card's onClick from firing
//           userPaid || quiz.difficulty === "Free Exploratory Quiz"
//             ? handleStartOrResumeQuiz()
//             : handleShowModal();
//         }}
//       >
//         {getButtonLabel()}
//       </button>
//       <Modal
//         show={showModal}
//         onHide={handleCloseModal}
//         size="lg" // Optional: 'sm', 'lg', 'xl'
//       >
//         <Modal.Header closeButton>
//           <Modal.Title
//             style={{
//               textAlign: "center",
//               fontFamily: "Baskerville, serif",
//               fontWeight: "bold",
//             }}
//           >
//             This is a Premium Quiz.
//           </Modal.Title>
//         </Modal.Header>
//         <Modal.Body
//           style={{
//             textAlign: "center",
//             fontFamily: "Baskerville, serif",
//             fontSize: "20px",
//           }}
//         >
//           Please Click on "Free Exploratory Quizzes" to explore the Free
//           Quizzess.
//           <br></br>
//           <br></br>
//           OR
//           <br></br>
//           <br></br>
//           Explore "Plans for Premium Quizzes"
//         </Modal.Body>
//         <Modal.Footer
//           style={{
//             display: "flex",
//             justifyContent: "center", // Centers the buttons horizontally
//           }}
//         >
//           <button
//             className="btn"
//             onClick={handleFreeExploratoryQuizzes}
//             style={{ fontFamily: "Baskerville, serif", fontSize: "20px" }}
//           >
//             Free Exploratory Quizzes
//           </button>
//           <button
//             className="btn"
//             onClick={handlePlansforPremiumQuizzes}
//             style={{ fontFamily: "Baskerville, serif", fontSize: "20px" }}
//           >
//             Plans for Premium Quizzes
//           </button>
//         </Modal.Footer>
//       </Modal>
//     </div>
//   );
// }

// export default Quiz;
// /////////////////////////////////////////////////////////////////////
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import "./Quiz.css";

function Quiz({
  quiz,
  userId,
  userName,
  userSchoolcode,
  userClass,
  userPaid,
  userPaidfor,
  userSection,
  attemptedQuizIds,
  completedQuizIds,
  attemptedkyebyeQuizIds,
  completedkyebyeQuizIds,
  updateDifficulty, // Add this prop to update difficulty in Udbodhscreen
  selectedClassFromModal,
}) {
  const navigate = useNavigate();
  const [showModal, setshowModal] = useState(false);
  const handleCloseModal = () => setshowModal(false);

  const handleShowModal = () => setshowModal(true);

  // const modifyImageUrl = (imageUrl) => {
  //   const fileId = imageUrl.match(/\/d\/(.+?)\//)[1]; // Extract file ID from URL
  //   return `https://lh3.googleusercontent.com/d/${fileId}=s1900?authuser=0`; // Construct thumbnail URL
  // };

  // const modifyImageUrl = (image) => {
  //   if (!image) return "";

  //   console.log("image in Quiz.js : ", image);

  //   if (image.storageType === "google_drive") {
  //     const fileId = image.url.match(/\/d\/(.+?)\//)[1];
  //     return `https://lh3.googleusercontent.com/d/${fileId}=s1900?authuser=0`;
  //   }
  //   console.log("image.url in Quiz.js : ", image.url);
  //   return image.url; // AWS S3 URLs are already direct links
  // };

  const modifyImageUrl = (image) => {
    if (!image) return ""; // If image is null/undefined, return an empty string

    // If image is a string (direct URL), return it as is
    if (typeof image === "string") {
      // Handle Google Drive URLs
      if (image.includes("drive.google.com")) {
        console.log("inside google drive");
        // const match = image.match(/\/d\/(.+?)\//);
        // if (match) {
        //   console.log(`https://lh3.googleusercontent.com/d/${match[1]}=s1900?authuser=0`);
        //   return `https://lh3.googleusercontent.com/d/${match[1]}=s1900?authuser=0`;
        // }
        const fileId = image.match(/\/d\/(.+?)\//)[1]; // Extract file ID from URL if it exists
        return fileId
          ? `https://lh3.googleusercontent.com/d/${fileId}=s1900?authuser=0`
          : image;
      }
      return image; // Return direct AWS S3 links without modification
    }

    // If image is an object (future-proofing), handle it correctly
    if (typeof image === "object" && image.url) {
      if (image.storageType === "google_drive") {
        const match = image.url.match(/\/d\/(.+?)\//);
        if (match) {
          return `https://lh3.googleusercontent.com/d/${match[1]}=s1900?authuser=0`;
        }
      }
      return image.url;
    }

    return ""; // Fallback for unexpected cases
  };

  // Define a common style object for button shadows
  const buttonShadowStyle = {
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.5)",
    transition: "background-color 0.3s",
  };

  const handleStartOrResumeQuiz = async () => {
    try {
      console.log("quiz : ", quiz);
      console.log("attempted : ", attemptedQuizIds);
      if (undefined) {
        console.log(userPaidfor.find((div) => div === quiz.division));
      }
      if (
        !attemptedQuizIds.includes(quiz._id) &&
        !completedQuizIds.includes(quiz._id) &&
        (quiz.division === "Udbodh" ||
          quiz.division === "Bauddha Dakshata" ||
          quiz.division == "Abhyudaya")
      ) {
        await axios.post("/api/users/attemptquiz", {
          userId,
          quizId: quiz._id,
          totalQuestions: quiz.questionsLength,
          correctAnswers: "",
          wrongAnswers: "",
          attemptedQuestions: "",
          division: quiz.division,
          userName,
          userSchoolcode,
          userClass,
          userSection,
          quizName: quiz.name,
          quizCategory: quiz.difficulty,
          quizSubject: quiz.subject,
        });
      }
      if (
        Array.isArray(attemptedkyebyeQuizIds) &&
        Array.isArray(completedkyebyeQuizIds) &&
        !attemptedkyebyeQuizIds.includes(quiz._id) &&
        !completedkyebyeQuizIds.includes(quiz._id) &&
        (quiz.division === "Kye Bye" || quiz.division === "Business Wisdom")
      ) {
        await axios.post("/api/users/attemptkyebyequiz", {
          userId,
          quizId: quiz._id,
          totalQuestions: quiz.questionsLength,
          correctAnswers: "",
          wrongAnswers: "",
          attemptedQuestions: "",
          division: quiz.division,
          userName,
          userSchoolcode,
          userClass,
          userSection,
          quizName: quiz.name,
          quizCategory: quiz.difficulty,
          quizSubject: quiz.subject,
        });
      }
      navigate("/questions", {
        state: {
          userName: userName,
          userSchoolcode: userSchoolcode,
          userClass: userClass,
          userSection: userSection,
          name: quiz.name,
          //questions: quiz.questions,
          division: quiz.division,
          userId: userId, // Pass userId to QuestionPage
          quizId: quiz._id, // Pass quizId to QuestionPage
          quizName: quiz.name,
          quizCategory: quiz.difficulty,
          quizSubject: quiz.subject,
          selectedClassFromModal: selectedClassFromModal,
        },
      });
    } catch (error) {
      console.error("Error at Start/Resume Quiz", error);
    }
  };

  const handleFreeExploratoryQuizzes = () => {
    updateDifficulty("Free Exploratory Quiz"); // Change difficulty to Free Exploratory Quiz
    setshowModal(false); // Close modal after setting difficulty
  };

  const handlePlansforPremiumQuizzes = () => {
    navigate("/paymentplans"); // Navigate to the premium plans page
  };

  const getButtonLabel = () => {
    if (completedQuizIds.includes(quiz._id)) {
      return "Retake Quiz";
    }
    if (attemptedQuizIds.includes(quiz._id)) {
      return "Resume Quiz";
    }
    return "Start Quiz";
  };

  return (
    <>
      <div style={{ position: "relative" }}>
        <div
          className="quiz-card shadow-lg p-3 mb-5 d-flex flex-column"
          id="round"
          onClick={
            userPaidfor?.some((entry) => {
              const [paidDivision, paidClass] = entry.split("-"); // Split "division-class"
              return (
                paidDivision === quiz.division && paidClass === quiz.classs
              );
            }) || // Check if userPaidfor includes the division
            (userSchoolcode !== "Empty" &&
              quiz.classs !== "Already passed 12th") ||
            (userSchoolcode == "Empty" &&
              userClass == "Already passed 12th" &&
              quiz.difficulty !== "Premium Quiz") ||
            (userClass == "Already passed 12th" &&
              userSchoolcode != "Empty" &&
              quiz.classs == "Already passed 12th") ||
            quiz.difficulty === "Free Exploratory Quiz"
              ? handleStartOrResumeQuiz
              : handleShowModal
          }
          style={{
            cursor: "pointer",
            background:
              quiz.difficulty === "Premium Quiz"
                ? "linear-gradient(to bottom,rgba(96, 165, 230, 0.91),rgba(250, 252, 252, 0.79),rgba(132, 239, 245, 0.91))" // Light gold to dark gold gradient
                : "white",
            overflow: "hidden",
            border:
              quiz.difficulty === "Premium Quiz"
                ? "2px solid rgba(19, 18, 15, 0.71)" //rgba(247, 185, 1, 0.71)
                : "0px",
            height: "400px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          {/* Quiz Image */}
          {quiz.quiz_image && (
            <div
              // style={{
              //   display: "flex",
              //   justifyContent: "center",
              //   alignItems: "center",
              //   marginTop: "40px",
              //   height: "200px",
              //   overflow: "hidden",
              // }}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "20px",
                marginLeft: "10px",
                height: "250px", // Fixed height for the container
                width: "250px", // Fixed width for the container
                overflow: "hidden", // Prevent overflow of the container
              }}
            >
              {quiz.quiz_image && (
                <img
                  src={modifyImageUrl(quiz.quiz_image)}
                  alt="Quiz"
                  style={{
                    width: "100%",
                    //maxWidth: "200px",
                    height: "100%",
                    //objectFit: "cover",
                    objectFit: "contain", // Ensure the entire image is visible
                  }}
                  className="img-fluid"
                />
              )}
            </div>
          )}

          {/* Hidden quiz name, shown on hover */}
          <div className="quiz-name-hover" style={{ zIndex: 1000 }}>
            {quiz.name}
          </div>

          {/* Quiz Title */}
          <div
            style={{
              height: "50px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: quiz.quiz_image ? "10px" : "190px",
              padding: "10px", // Optional for spacing
            }}
          >
            <h1
              // style={{
              //   fontFamily: "'Georgia Ref', Georgia, serif",
              //   fontWeight: "bold",
              //   fontSize: "20px",
              //   textAlign: "center",
              //   overflow: "hidden",
              //   textOverflow: "ellipsis", // Add ellipsis for long text
              //   whiteSpace: "nowrap", // Prevents text wrapping
              // }}
              style={{
                fontFamily: "'Georgia Ref', Georgia, serif",
                fontWeight: "bold",
                fontSize: "20px",
                textAlign: "center",
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 2, // Limit to 2 lines
                whiteSpace: "normal", // Allow wrapping
              }}
            >
              {quiz.name}
            </h1>
          </div>

          {/* Start/Resume Button */}
          <div className="mt-auto mb-5">
            <button
              className="btn btn-primary"
              style={{
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.5)",
                transition: "background-color 0.3s",
                fontFamily: "'Georgia Ref', Georgia, serif",
                fontSize: "25px",
                textAlign: "center",
              }}
              onClick={(e) => {
                e.stopPropagation(); // Prevents the card's onClick from firing
                userPaidfor?.some((entry) => {
                  const [paidDivision, paidClass] = entry.split("-"); // Split "division-class"
                  return (
                    paidDivision === quiz.division && paidClass === quiz.classs
                  );
                }) ||
                (userSchoolcode !== "Empty" &&
                  quiz.classs !== "Already passed 12th") ||
                (userSchoolcode == "Empty" &&
                  userClass == "Already passed 12th" &&
                  quiz.difficulty !== "Premium Quiz") ||
                (userClass == "Already passed 12th" &&
                  userSchoolcode != "Empty" &&
                  quiz.classs == "Already passed 12th") ||
                quiz.difficulty === "Free Exploratory Quiz"
                  ? handleStartOrResumeQuiz()
                  : handleShowModal();
              }}
            >
              {getButtonLabel()}
            </button>
          </div>
        </div>
      </div>

      {/* Modal for Premium Quizzes */}
      <Modal show={showModal} onHide={handleCloseModal} size="lg">
        <Modal.Header closeButton>
          <Modal.Title
            style={{
              textAlign: "center",
              fontFamily: "Baskerville, serif",
              fontWeight: "bold",
            }}
          >
            This is a Premium Quiz.
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            textAlign: "center",
            fontFamily: "Baskerville, serif",
            fontSize: "20px",
          }}
        >
          Please Click on "Free Exploratory Quizzes" to explore the Free
          Quizzes.
          <br />
          OR
          <br />
          Explore "Plans for Premium Quizzes"
        </Modal.Body>
        <Modal.Footer
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <button
            className="btn"
            onClick={handleFreeExploratoryQuizzes}
            style={{
              fontFamily: "Baskerville, serif",
              fontSize: "20px",
            }}
          >
            Free Exploratory Quizzes
          </button>
          <button
            className="btn"
            onClick={handlePlansforPremiumQuizzes}
            style={{
              fontFamily: "Baskerville, serif",
              fontSize: "20px",
            }}
          >
            Plans for Premium Quizzes
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Quiz;
