import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

function QuizResult() {
  const location = useLocation();
  const navigate = useNavigate();

  const totalQuestions = location.state?.totalQuestions || 0;
  const correctAnswers = location.state?.correctAnswers || 0;
  const wrongAnswers = location.state?.wrongAnswers || 0;
  const attemptedQuestions = location.state?.attemptedQuestions || 0;
  const result = location.state?.result || [];
  const quizId = location.state?.quizId || "";
  const division = location.state?.division || "";
  const selectedClassFromModal = location.state?.selectedClassFromModal || null;

  const wrongQuestions = [];
  const unattemptedQuestions = [];

  result.forEach((r, index) => {
    if (r === 0) wrongQuestions.push(index); // Wrong answers
    if (r === -1) unattemptedQuestions.push(index); // Unattempted questions
  });

  const handleReattempt = () => {
    console.log("wrongQuestions.length : ", wrongQuestions.length);
    console.log("unattemptedQuestions.length : ", unattemptedQuestions.length);
    if (wrongQuestions.length == 0 && unattemptedQuestions.length == 0) {
      alert("Your all answers were correct.");
      return;
    } else {
      navigate("/reattempt", {
        state: {
          quizId,
          wrongQuestions,
          unattemptedQuestions,
          division,
          selectedClassFromModal,
        },
      });
    }
  };

  // const {
  //   totalQuestions,
  //   correctAnswers,
  //   wrongAnswers,
  //   attemptedQuestions,
  //   division,
  //   result, // Assuming result array is passed via location.state
  // } = location.state || {};

  // const [wronglyAnsweredIndices, setWronglyAnsweredIndices] = useState([]);
  // const [unattemptedIndices, setUnattemptedIndices] = useState([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // useEffect(() => {
  //   if (result) {
  //     // Find indices of wrongly answered questions
  //     const wrongIndices = result
  //       .map((value, index) => (value === 0 ? index + 1 : null))
  //       .filter((index) => index !== null);

  //     // Find indices of unattempted questions
  //     const unattemptedIndices = result
  //       .map((value, index) => (value === -1 ? index + 1 : null))
  //       .filter((index) => index !== null);

  //     setWronglyAnsweredIndices(wrongIndices);
  //     setUnattemptedIndices(unattemptedIndices);
  //   }
  // }, [result]);

  // Get indices of wrong and unattempted questions
  const wrongQuestionIndices = result
    .map((value, index) => (value === 0 ? index : null))
    .filter((index) => index !== null);

  const unattemptedQuestionIndices = result
    .map((value, index) => (value === -1 ? index : null))
    .filter((index) => index !== null);

  // const handleReattempt = () => {
  //   navigate("/reattempt", {
  //     state: {
  //       wrongQuestionIndices,
  //       unattemptedQuestionIndices,
  //       division,
  //     },
  //   });
  // };

  return (
    <div className="container mt-5">
      <h2>Quiz Results</h2>
      <hr />
      <div className="table-responsive">
        <table className="table table-striped table-bordered">
          <thead className="table-dark">
            <tr>
              <th>Total Questions</th>
              <th>Questions Attempted</th>
              <th>Correctly Answered Questions</th>
              <th>Wrongly Answered Questions</th>
              <th>Total Score</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{totalQuestions}</td>
              <td>{attemptedQuestions}</td>
              <td>{correctAnswers}</td>
              <td>{wrongAnswers}</td>
              <td>{correctAnswers}</td>
            </tr>
          </tbody>
        </table>
      </div>

      {/* <div>
        <h3>Details:</h3>
        <p>
          <strong>Wrongly Answered Questions:</strong>{" "}
          {wronglyAnsweredIndices.length > 0
            ? wronglyAnsweredIndices.join(", ")
            : "None"}
        </p>
        <p>
          <strong>Unattempted Questions:</strong>{" "}
          {unattemptedIndices.length > 0
            ? unattemptedIndices.join(", ")
            : "None"}
        </p>
      </div> */}

      <button
        className="btn btn-primary mt-1"
        onClick={() =>
          navigate(
            division === "Udbodh"
              ? selectedClassFromModal != null
                ? `/udbodh?classs=${selectedClassFromModal}`
                : "/udbodh"
              : division === "Bauddha Dakshata"
              ? selectedClassFromModal != null
                ? `/bauddhadakshata?classs=${selectedClassFromModal}`
                : "/bauddhadakshata"
              : division === "Kye Bye"
              ? "/kyebye"
              : division === "Abhyudaya"
              ? "/abhyudayascreen"
              : "/business"
          )
        }
      >
        Return to Quiz List
      </button>

      <button className="btn btn-warning mt-1 ms-3" onClick={handleReattempt}>
        Reattempt Wrong and Unattempted Questions
      </button>

      <div
        className="text-center mt-1"
        style={{ display: "flex", justifyContent: "space-evenly" }}
      >
        <img
          width="250px"
          height="250px"
          src="student.gif"
          alt="Celebration GIF"
          className="img-fluid"
        />

        {!isMobile && (
          <>
            <img
              width="250px"
              height="250px"
              src="srch.gif"
              alt="Celebration GIF"
              className="img-fluid"
            />
            <img
              width="250px"
              height="250px"
              src="res-img.gif"
              alt="Celebration GIF"
              className="img-fluid"
            />
          </>
        )}
      </div>
    </div>
  );
}

export default QuizResult;
//////////////////////////////////////////////////////////////////////////////////////

// import React from "react";
// import { useNavigate, useLocation } from "react-router-dom";

// function QuizResult() {
//   const location = useLocation();
//   const navigate = useNavigate();

//   const totalQuestions = location.state?.totalQuestions || 0;
//   const correctAnswers = location.state?.correctAnswers || 0;
//   const wrongAnswers = location.state?.wrongAnswers || 0;
//   const attemptedQuestions = location.state?.attemptedQuestions || 0;
//   const result = location.state?.result || [];
//   const quizId = location.state?.quizId || "";

//   const wrongQuestions = [];
//   const unattemptedQuestions = [];

//   result.forEach((r, index) => {
//     if (r === 0) wrongQuestions.push(index); // Wrong answers
//     if (r === -1) unattemptedQuestions.push(index); // Unattempted questions
//   });

//   const handleReattempt = () => {
//     navigate("/reattempt", {
//       state: {
//         quizId,
//         wrongQuestions,
//         unattemptedQuestions,
//       },
//     });
//   };

//   return (
//     <div className="quiz-result-container">
//       <h2>Quiz Results</h2>
//       <p>Total Questions: {totalQuestions}</p>
//       <p>Attempted Questions: {attemptedQuestions}</p>
//       <p>Correct Answers: {correctAnswers}</p>
//       <p>Wrong Answers: {wrongAnswers}</p>
//       <button className="btn btn-primary" onClick={handleReattempt}>
//         Reattempt wrong and unattempted questions
//       </button>
//     </div>
//   );
// }

// export default QuizResult;
