import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { IoVolumeHighOutline } from "react-icons/io5";
import axios from "axios";
import "./QuestionPage.css"; // Import the CSS file

function ReattemptQuestionsMobile() {
  const location = useLocation();
  const navigate = useNavigate();

  const quizId = location.state ? location.state.quizId : null;
  const division = location.state ? location.state.division : "Kye Bye";
  const wrongQuestionIndices = location.state
    ? location.state.wrongQuestions
    : [];
  const unattemptedQuestionIndices = location.state
    ? location.state.unattemptedQuestions
    : [];
  const selectedClassFromModal = location.state
    ? location.state.selectedClassFromModal
    : null;

  const userId = location.state ? location.state.userId : null;
  //const quizId = location.state ? location.state.quizId : null;
  const quizName = location.state ? location.state.quizName : null;
  const quizCategory = location.state ? location.state.quizCategory : null;
  const quizSubject = location.state ? location.state.quizSubject : null;
  const userName = location.state ? location.state.userName : null;
  const userSchoolcode = location.state ? location.state.userSchoolcode : null;
  const userClass = location.state ? location.state.userClass : null;
  const userSection = location.state ? location.state.userSection : null;

  const [questions, setQuestions] = useState([]);
  const [name, setName] = useState("");
  //const [division, setDivision] = useState("");
  const [quizDetails, setQuizDetails] = useState(null); // For other quiz-specific details
  const [language, setLanguage] = useState("English"); // Default to English

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedOption, setSelectedOption] = useState(null);
  const [feedbackMessage, setFeedbackMessage] = useState("");
  const [isCorrect, setIsCorrect] = useState(false);
  const [showQuestionImage, setShowQuestionImage] = useState(true);
  const [showAnswerImage, setShowAnswerImage] = useState(false);
  const [shouldReadExplanation, setShouldReadExplanation] = useState(false);
  // const [result, setResult] = useState(Array(questions.length).fill(-1));
  const [result, setResult] = useState([]);
  const [showAnswer, setShowAnswer] = useState(false);
  const [isReading, setIsReading] = useState(false); // State to track if reading is active
  const [isPaused, setIsPaused] = useState(false); // State to track if reading is paused
  const [currentReadIndex, setCurrentReadIndex] = useState(-1);

  const [showQuestionImageModal, setshowQuestionImageModal] = useState(false);
  const [showAnswerImageModal, setshowAnswerImageModal] = useState(false);

  useEffect(() => {
    const fetchQuizDetails = async () => {
      try {
        let data; // Declare `data` outside of the blocks

        if (division === "Kye Bye" || division === "Business Wisdom") {
          const response = await axios.post(
            `/api/KyebyeQuiz/getKyebyeQuizById`,
            {
              quizid: quizId,
            }
          );
          data = response.data; // Assign `data` from the response
        } else {
          const response = await axios.post(`/api/quizs/getquizbyid`, {
            quizid: quizId,
          });
          data = response.data; // Assign `data` from the response
        }

        const filteredQuestions = data.questions.filter((_, index) =>
          [...wrongQuestionIndices, ...unattemptedQuestionIndices].includes(
            index
          )
        );

        setQuestions(filteredQuestions);
        console.log("questions : ", questions);
        setName(data.name);
      } catch (error) {
        console.error("Error fetching quiz details for reattempt", error);
      }
    };

    console.log("Location State in ReattemptQuestionsMobile:", location.state);
    console.log("quizId : ", quizId);

    if (quizId) {
      fetchQuizDetails();
    }
  }, [quizId, wrongQuestionIndices, unattemptedQuestionIndices]);

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top when the component mounts
  }, []);

  useEffect(() => {
    const handleContextMenu = (event) => {
      event.preventDefault();
    };

    const handleKeyDown = (event) => {
      if (event.ctrlKey) {
        event.preventDefault();
      }
    };

    document.addEventListener("contextmenu", handleContextMenu);
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("contextmenu", handleContextMenu);
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    const currentQuestion = questions[currentQuestionIndex];

    // Determine the correct answer based on the selected language
    const correctAnswer =
      language === "English"
        ? currentQuestion.correct_answer
        : currentQuestion.correct_answer_hindi;

    if (event.target.value === correctAnswer) {
      // Feedback for correct answer
      setFeedbackMessage(
        language === "English" ? "Correct Option Chosen" : "सही विकल्प चुना गया"
      );
      setIsCorrect(true);
      setShowAnswerImage(true);
      setShouldReadExplanation(true);

      if (result[currentQuestionIndex] === -1) {
        updateResult(1);
      }

      // Scroll to the correct feedback section
      setTimeout(function () {
        const element = document.getElementById("scrollcorrect");
        element?.scrollIntoView({
          behavior: "smooth",
        });
      }, 200);
    } else {
      // Feedback for wrong answer
      setFeedbackMessage(
        language === "English" ? "Wrong Answer Chosen" : "गलत उत्तर चुना गया"
      );
      setIsCorrect(false);
      setShowAnswerImage(false);
      setShouldReadExplanation(false);

      if (result[currentQuestionIndex] === -1) {
        updateResult(0);
      }

      // Scroll to the wrong feedback section
      setTimeout(function () {
        const element = document.getElementById("scrollwrong");
        element?.scrollIntoView({
          behavior: "smooth",
        });
      }, 200);
    }
  };
  const updateResult = (value) => {
    setResult((prevResult) => {
      const newResult = [...prevResult];
      newResult[currentQuestionIndex] = value;
      return newResult;
    });
  };

  const clearOptions = () => {
    setSelectedOption(null);
    setFeedbackMessage("");
    setShowAnswerImage(false);
    setShouldReadExplanation(false);
    setShowAnswer(false);

    if (result[currentQuestionIndex] === -1) {
      updateResult(-1);
    }
  };

  const handleNextQuestion = () => {
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
      setSelectedOption(null);
      setFeedbackMessage("");
      setShowAnswerImage(false);
      setShouldReadExplanation(false);
      setShowQuestionImage(true);
      setShowAnswer(false);
      setIsCorrect(false);
      setTimeout(function () {
        const element = document.getElementById("top");
        element?.scrollIntoView({ behavior: "smooth" });
      }, 200);
    }
  };

  const handlePreviousQuestion = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
      setSelectedOption(null);
      setFeedbackMessage("");
      setShowAnswerImage(false);
      setShouldReadExplanation(false);
      setShowQuestionImage(true);
      setShowAnswer(false);
      setIsCorrect(false);
      setTimeout(function () {
        const element = document.getElementById("top");
        element?.scrollIntoView({ behavior: "smooth" });
      }, 200);
    }
  };

  const handleQuestionChange = (index) => {
    setCurrentQuestionIndex(index);
    setSelectedOption(null);
    setFeedbackMessage("");
    setShowAnswerImage(false);
    setShouldReadExplanation(false);
    setShowQuestionImage(true);
    setShowAnswer(false);
    setIsCorrect(false);
    setTimeout(function () {
      const element = document.getElementById("top");
      element?.scrollIntoView({ behavior: "smooth" });
    }, 200);
  };

  const handleVoiceReading = () => {
    if (isPaused) {
      // Resume if currently paused
      window.speechSynthesis.resume();
      setIsPaused(false);
      return;
    }

    if (isReading) {
      // Pause if currently reading
      window.speechSynthesis.pause();
      setIsPaused(true);
      return;
    }

    // Start reading if not already reading
    const speech = new SpeechSynthesisUtterance();
    const currentQuestion = questions[currentQuestionIndex];

    // Stop any ongoing speech
    window.speechSynthesis.cancel();

    // Determine language and content based on the selected language
    const lang = language === "Hindi" ? "hi-IN" : "en-US";
    const chunks = [];

    // Add content based on the selected language
    if (language === "Hindi") {
      chunks.push(`प्रश्न ${currentQuestionIndex + 1}`); // Hindi for 'Question'
      chunks.push(
        currentQuestion.question_in_hindi || "प्रश्न उपलब्ध नहीं है।"
      ); // Hindi question text
      currentQuestion.options_hindi?.forEach(
        (option, index) => chunks.push(`विकल्प ${index + 1}: ${option}`) // Hindi for 'Option'
      );
      if (shouldReadExplanation) {
        chunks.push(
          currentQuestion.answer_explanation_in_hindi ||
            "हिंदी में स्पष्टीकरण उपलब्ध नहीं है।"
        );
      }
    } else if (language === "English") {
      chunks.push(`Question ${currentQuestionIndex + 1}`); // English for 'Question'
      chunks.push(currentQuestion.question || "Question not available."); // English question text
      currentQuestion.options?.forEach(
        (option, index) => chunks.push(`Option ${index + 1}: ${option}`) // English for 'Option'
      );
      if (shouldReadExplanation) {
        chunks.push(
          currentQuestion.answer_explanation_in_english ||
            "No explanation available in English."
        );
      }
    }

    // Read each chunk one by one
    let currentIndex = 0;

    const ensureVoicesLoaded = (callback) => {
      let voices = window.speechSynthesis.getVoices();
      if (voices.length) {
        callback(voices);
      } else {
        window.speechSynthesis.onvoiceschanged = () => {
          voices = window.speechSynthesis.getVoices();
          callback(voices);
        };
      }
    };

    const speakChunk = () => {
      if (currentIndex >= chunks.length) {
        setCurrentReadIndex(-1); // Reset highlighting after reading
        setIsReading(false); // Reading finished
        window.speechSynthesis.cancel(); // Ensure no lingering utterances
        return;
      }

      // Update the highlighted index
      setCurrentReadIndex(currentIndex);

      // Set up the speech for the current chunk
      speech.text = chunks[currentIndex];
      speech.lang = lang;

      // Assign a specific voice for Hindi or English dynamically
      ensureVoicesLoaded((voices) => {
        let selectedVoice;
        if (lang === "hi-IN") {
          selectedVoice = voices.find((voice) => voice.lang === "hi-IN");
          if (!selectedVoice) {
            console.warn("Hindi voice not available! Using default voice.");
            selectedVoice = voices[0]; // Fallback to the first available voice
          }
        } else {
          selectedVoice = voices.find((voice) => voice.lang === "en-US");
          if (!selectedVoice) {
            selectedVoice = voices[0]; // Fallback to the first available voice
          }
        }
        speech.voice = selectedVoice;

        speech.volume = 1; // Set volume (0 to 1)
        speech.rate = 1; // Set rate (0.1 to 10)
        speech.pitch = 1; // Set pitch (0 to 2)

        // Move to the next chunk when the current one ends
        speech.onend = () => {
          currentIndex++;
          speakChunk();
        };

        // Error handling for edge cases
        speech.onerror = () => {
          console.error("Speech synthesis encountered an error.");
          window.speechSynthesis.cancel(); // Stop any ongoing speech
          setIsReading(false);
          setCurrentReadIndex(-1);
        };

        window.speechSynthesis.speak(speech);
      });
    };

    setIsReading(true); // Set the reading state to active
    setIsPaused(false); // Ensure the paused state is reset
    speakChunk();
  };
  useEffect(() => {
    if (isReading) {
      // Stop current speech synthesis when language changes
      window.speechSynthesis.cancel();
      setIsReading(false);
      setIsPaused(false);
    }
  }, [language]);

  // const modifyImageUrl = (imageUrl) => {
  //   if (imageUrl.includes("drive.google.com")) {
  //     const fileId = imageUrl.match(/\/d\/(.+?)\//)[1]; // Extract file ID from URL if it exists
  //     return fileId
  //       ? `https://lh3.googleusercontent.com/d/${fileId}=s1900?authuser=0`
  //       : imageUrl; // Construct direct view URL
  //   }
  //   return imageUrl;
  // };

  const modifyImageUrl = (image) => {
    if (!image) return ""; // If image is null/undefined, return an empty string

    // If image is a string (direct URL), return it as is
    if (typeof image === "string") {
      // Handle Google Drive URLs
      if (image.includes("drive.google.com")) {
        console.log("inside google drive");
        // const match = image.match(/\/d\/(.+?)\//);
        // if (match) {
        //   console.log(`https://lh3.googleusercontent.com/d/${match[1]}=s1900?authuser=0`);
        //   return `https://lh3.googleusercontent.com/d/${match[1]}=s1900?authuser=0`;
        // }
        const fileId = image.match(/\/d\/(.+?)\//)[1]; // Extract file ID from URL if it exists
        return fileId
          ? `https://lh3.googleusercontent.com/d/${fileId}=s1900?authuser=0`
          : image;
      }
      return image; // Return direct AWS S3 links without modification
    }

    // If image is an object (future-proofing), handle it correctly
    if (typeof image === "object" && image.url) {
      if (image.storageType === "google_drive") {
        const match = image.url.match(/\/d\/(.+?)\//);
        if (match) {
          return `https://lh3.googleusercontent.com/d/${match[1]}=s1900?authuser=0`;
        }
      }
      return image.url;
    }

    return ""; // Fallback for unexpected cases
  };

  if (!questions) {
    return <div className="container mt-3">Loading...</div>;
  }

  const handleReturnToQuizList = async () => {
    try {
      //   const totalQuestions = result.length;
      //   const correctAnswers = result.filter((r) => r === 1).length;
      //   const wrongAnswers = result.filter((r) => r === 0).length;
      //   const attemptedQuestions = result.filter((r) => r !== -1).length;

      //   if (
      //     division == "Udbodh" ||
      //     division == "Bauddha Dakshata" ||
      //     division == "Abhyudaya"
      //   ) {
      //     await axios.post("/api/users/attemptquiz", {
      //       userId,
      //       quizId,
      //       totalQuestions,
      //       correctAnswers,
      //       wrongAnswers,
      //       attemptedQuestions,
      //       division,
      //       userName,
      //       userSchoolcode,
      //       userClass,
      //       userSection,
      //       quizName,
      //       quizCategory,
      //       quizSubject,
      //     });
      //   }

      //   if (division == "kyebye") {
      //     await axios.post("/api/users/attemptkyebyequiz", {
      //       userId,
      //       quizId,
      //       totalQuestions,
      //       correctAnswers,
      //       wrongAnswers,
      //       attemptedQuestions,
      //       division,
      //       userName,
      //       userSchoolcode,
      //       userClass,
      //       userSection,
      //       quizName,
      //       quizCategory,
      //       quizSubject,
      //     });
      //   }

      navigate(
        division === "Udbodh"
          ? selectedClassFromModal != null
            ? `/udbodh?classs=${selectedClassFromModal}`
            : "/udbodh"
          : division === "Bauddha Dakshata"
          ? selectedClassFromModal != null
            ? `/bauddhadakshata?classs=${selectedClassFromModal}`
            : "/bauddhadakshata"
          : division === "Kye Bye"
          ? "/kyebye"
          : division === "Abhyudaya"
          ? "/abhyudayascreen"
          : "/business"
      );
    } catch (error) {
      console.error("Error at Exit Quiz", error);
    }
  };

  const handleSubmitQuiz = async () => {
    try {
      const totalQuestions = result.length;
      const correctAnswers = result.filter((r) => r === 1).length;
      const wrongAnswers = result.filter((r) => r === 0).length;
      const attemptedQuestions = result.filter((r) => r !== -1).length;

      if (
        division == "Udbodh" ||
        division == "Bauddha Dakshata" ||
        division == "Abhyudaya"
      ) {
        await axios.post("/api/users/completequiz", {
          userId,
          quizId,
          totalQuestions,
          correctAnswers,
          wrongAnswers,
          attemptedQuestions,
          division,
          userName,
          userSchoolcode,
          userClass,
          userSection,
          quizName,
          quizCategory,
          quizSubject,
        });
      }

      if (division == "Kye Bye" || division == "Business Wisdom") {
        await axios.post("/api/users/completekyebyequiz", {
          userId,
          quizId,
          totalQuestions,
          correctAnswers,
          wrongAnswers,
          attemptedQuestions,
          division,
          userName,
          userSchoolcode,
          userClass,
          userSection,
          quizName,
          quizCategory,
          quizSubject,
        });
      }

      // await axios.post("/api/users/sendresult", {
      //   userId,
      //   totalQuestions,
      //   attemptedQuestions,
      //   correctAnswers,
      //   wrongAnswers,
      // });

      navigate("/quizresult", {
        state: {
          totalQuestions,
          correctAnswers,
          wrongAnswers,
          attemptedQuestions,
          division,
          result, // Pass the result array
        },
      });
    } catch (error) {
      console.error("Error at Submitting Quiz", error);
    }
  };

  const handleShowAnswer = () => {
    setShowAnswer(true);
    setShouldReadExplanation(true);

    setTimeout(function () {
      const element = document.getElementById("scrollcorrect");
      element?.scrollIntoView({
        behavior: "smooth",
      });
    }, 200);
  };
  const getYouTubeEmbedUrl = (url) => {
    let videoId;
    if (url.includes("embed")) {
      videoId = url.split("/embed/")[1];
    } else if (url.includes("watch?v=")) {
      videoId = url.split("v=")[1];
    } else if (url.includes("youtu.be")) {
      videoId = url.split(".be/")[1];
    }
    // Ensure videoId exists and construct the embed URL
    return videoId ? `https://www.youtube.com/embed/${videoId}` : null;
  };

  return (
    <div className="container-fluid question-page-container">
      <div className="question-content mt-5">
        <div>
          {/* <button
                className="btn btn-primary"
                onClick={handleReturnToQuizList}
                style={{ boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.5)" }}
                id="top"
              >
                Exit Quiz
              </button>
              <button
                className="btn btn-primary"
                style={{
                  marginLeft: "20px",
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.5)",
                }}
                onClick={handleSubmitQuiz}
              >
                Submit
              </button> */}
          <button
            className="btn btn-primary"
            onClick={handleReturnToQuizList}
            style={{ boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.5)" }}
            id="top"
          >
            Exit Re-attempt Page
          </button>
          <div
            className="btn btn-primary"
            style={{
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.5)",
              fontFamily: "'Georgia Ref', Georgia, serif",
              fontSize: "20px",
              display: "flex",
              alignItems: "center",
              gap: "10px",
              borderRadius: "10px",
              padding: "10px",
            }}
          >
            <label
              style={{
                fontWeight: "bold",
                fontSize: "16px",
                color: "white",
              }}
            >
              Select Language:
            </label>
            <select
              id="language-selector"
              value={language}
              onChange={(e) => setLanguage(e.target.value)}
              style={{
                fontSize: "16px",
                borderRadius: "5px",
                border: "1px solid #ccc",
                backgroundColor:
                  isReading || division === "Kye Bye" ? "#f9f9f9" : "#fff",
                color: isReading || division === "Kye Bye" ? "#aaa" : "#000",
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                cursor:
                  isReading || division === "Kye Bye"
                    ? "not-allowed"
                    : "pointer",
              }}
              disabled={isReading || division === "Kye Bye"}
            >
              <option value="English">English</option>
              <option value="Hindi">Hindi</option>
            </select>
          </div>
        </div>
        <br></br>
        <div>
          <div className="navigation-bar">
            {questions.map((_, index) => {
              // Determine the background color and text color
              let backgroundColor = "white"; // Default background color
              let textColor = "black"; // Default text color

              if (currentQuestionIndex === index) {
                // If it's the current question
                backgroundColor = "blue";
                textColor = "white";
              } else if (result[index] === 0) {
                // If the result is wrong
                backgroundColor = "red";
                textColor = "white";
              } else if (result[index] === 1) {
                // If the result is correct
                backgroundColor = "green";
                textColor = "white";
              } else if (result[index] === -1) {
                // If the question is unattempted
                backgroundColor = "white";
                textColor = "black";
              }

              return (
                <button
                  key={index}
                  className="navigation-button"
                  style={{
                    backgroundColor: backgroundColor,
                    color: textColor,
                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.5)",
                  }}
                  onClick={() => handleQuestionChange(index)}
                >
                  {index + 1}
                </button>
              );
            })}
            {/* {questions.map((_, index) => (
                  <button
                    key={index}
                    className={`btn btn-link navigation-button ${
                      currentQuestionIndex === index ? "active" : ""
                    }`}
                    onClick={() => handleQuestionChange(index)}
                    style={{
                      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.5)",
                    }}
                  >
                    {index + 1}
                  </button>
                ))} */}
          </div>
        </div>
        <br></br>
        <p style={{ color: "red", fontStyle: "italic" }}>
          Only First Attempted Option will be counted in Final Result
        </p>
        <h1 className="question-header">
          Question {currentQuestionIndex + 1}
          <button
            className="voice-button"
            onClick={handleVoiceReading}
            style={{
              fontSize: "14px",
              marginLeft: "10px",
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.5)",
            }}
          >
            <IoVolumeHighOutline style={{ color: "black" }} />
          </button>
        </h1>
        {/* Render YouTube video */}
        {questions.length > 0 &&
        questions[currentQuestionIndex] &&
        questions[currentQuestionIndex].youtube_video ? (
          <div className="video-container" style={{ margin: "20px 0" }}>
            <iframe
              width="560"
              height="315"
              src={getYouTubeEmbedUrl(
                questions[currentQuestionIndex].youtube_video
              )}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="YouTube Video"
            ></iframe>
          </div>
        ) : (
          <p></p>
        )}

        <div className="question-passage">
          {questions.length > 0 &&
          questions[currentQuestionIndex] &&
          questions[currentQuestionIndex].passage_enable ? (
            <p>
              {language === "English"
                ? questions[currentQuestionIndex].question_passage
                : questions[currentQuestionIndex].question_passage_hindi}
            </p>
          ) : null}
        </div>

        <p
          className="desktop-question-text"
          style={{
            fontFamily: "'Maged Bold', Georgia, serif",
            fontWeight: currentReadIndex === 1 ? "bold" : "normal", // Highlight question in English
            fontSize: "20px",
            color: currentReadIndex === 1 ? "blue" : "black",
          }}
        >
          {questions.length > 0 &&
            (language === "English"
              ? questions[currentQuestionIndex].question
              : questions[currentQuestionIndex].question_in_hindi)}
        </p>
        {questions.length > 0 &&
          questions[currentQuestionIndex].question_image && (
            <div>
              <img
                src={modifyImageUrl(
                  questions[currentQuestionIndex].question_image
                )}
                alt="Question Image"
                className="question-image"
              />
            </div>
          )}
        <form className="desktop-options-form ms-5">
          {questions.length > 0 &&
            (() => {
              const currentOptions =
                language === "English"
                  ? questions[currentQuestionIndex].options
                  : questions[currentQuestionIndex].options_hindi;

              return currentOptions.map((option, index) => (
                <div className="form-check desktop-option-item" key={index}>
                  <input
                    className="form-check-input"
                    type="radio"
                    id={`option${index}`}
                    name="option"
                    value={option}
                    checked={option === selectedOption}
                    onChange={handleOptionChange}
                  />
                  <label
                    className="form-check-label"
                    htmlFor={`option${index}`}
                    style={{
                      fontFamily: "'Maged Bold', Georgia, serif",
                      fontSize: "20px",
                      fontWeight:
                        currentReadIndex === index + 3 ? "bold" : "normal", // Highlight options
                      color: currentReadIndex === index + 3 ? "blue" : "black",
                    }}
                  >
                    {option}
                  </label>
                </div>
              ));
            })()}
        </form>
        <div>
          {currentQuestionIndex > 0 && (
            <button
              className="btn btn-secondary"
              style={{
                marginRight: "10px",
                marginLeft: "10px",
                marginTop: "10px",
                marginBottom: "10px",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.5)",
              }}
              onClick={handlePreviousQuestion}
            >
              Previous
            </button>
          )}
          {currentQuestionIndex < questions.length - 1 && (
            <button
              className="btn btn-primary"
              style={{
                marginRight: "10px",
                marginLeft: "10px",
                marginTop: "10px",
                marginBottom: "10px",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.5)",
              }}
              onClick={handleNextQuestion}
            >
              Next
            </button>
          )}
          <button
            className="btn btn-warning"
            onClick={clearOptions}
            style={{
              marginRight: "10px",
              marginLeft: "10px",
              marginTop: "10px",
              marginBottom: "10px",
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.5)",
            }}
          >
            Clear Options
          </button>
          <button
            className="btn btn-info"
            onClick={handleShowAnswer}
            disabled={isCorrect || !selectedOption || isCorrect}
            style={{
              marginRight: "10px",
              marginLeft: "10px",
              marginTop: "10px",
              marginBottom: "10px",
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.5)",
            }}
          >
            Show Answer
          </button>
          <div
            style={{
              marginRight: "10px",
              marginLeft: "10px",
              marginTop: "10px",
              marginBottom: "10px",
              fontWeight: "bold",
              color: isCorrect ? "green" : "red",
            }}
          >
            {feedbackMessage}
          </div>
          <div id="scrollwrong" style={{ color: "white" }}>
            .
          </div>
          {(shouldReadExplanation || isCorrect || showAnswer) && (
            <div className="desktop-answer-explanation-container">
              {/* Explanation based on selected language */}
              {language === "English" && (
                <div className="desktop-explanation-text-container-left">
                  <p
                    className="desktop-explanation-text"
                    style={{
                      fontFamily: "'Maged Bold', Georgia, serif",
                      fontWeight: "bold",
                      fontSize: "20px",
                    }}
                  >
                    {questions[currentQuestionIndex]
                      ?.answer_explanation_in_english ||
                      "No explanation available in English."}
                  </p>
                </div>
              )}
              {language === "Hindi" && (
                <div className="desktop-explanation-text-container-right">
                  <p
                    className="desktop-explanation-text"
                    style={{
                      fontFamily: "'Maged Bold', Georgia, serif",
                      fontWeight: "bold",
                      fontSize: "20px",
                    }}
                    id="scrollcorrect"
                  >
                    {questions[currentQuestionIndex]
                      ?.answer_explanation_in_hindi ||
                      "No explanation available in Hindi."}
                  </p>
                </div>
              )}

              {/* Answer Image */}
              {questions.length > 0 &&
                questions[currentQuestionIndex].answer_image && (
                  <div className="answer-image-container">
                    <img
                      src={modifyImageUrl(
                        questions[currentQuestionIndex].answer_image
                      )}
                      alt="Answer Image"
                      className="answer-image"
                    />
                  </div>
                )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ReattemptQuestionsMobile;
