// import React, { useState, useEffect } from "react";

// const GalleryPhotos = () => {
//   const [photos, setPhotos] = useState([]); // State to store all photos
//   const [filteredPhotos, setFilteredPhotos] = useState([]); // State for filtered photos
//   const [division, setDivision] = useState(""); // State for division filter
//   const [error, setError] = useState(""); // State for errors
//   const [loading, setLoading] = useState(false); // State for loading spinner
//   const [isEditing, setIsEditing] = useState(false); // State to track if editing mode is active
//   const [editPhotoId, setEditPhotoId] = useState(null); // ID of the photo being edited
//   const [newImage, setNewImage] = useState(null); // New image for upload
//   const [newDivision, setNewDivision] = useState(""); // New division selected

//   // Function to modify the image URL (to view images)
//   const modifyImageUrl = (imageUrl) => {
//     if (imageUrl.includes("drive.google.com")) {
//       const fileId = imageUrl.match(/\/d\/(.+?)\//)[1]; // Extract file ID from URL if it exists
//       return fileId
//         ? `https://lh3.googleusercontent.com/d/${fileId}=s1900?authuser=0` // Construct direct view URL
//         : imageUrl;
//     }
//     return imageUrl; // Return the original URL if not from Google Drive
//   };

//   // Fetch all photos from the backend
//   const fetchPhotos = async () => {
//     try {
//       setLoading(true);
//       setError("");
// const response = await fetch("/api/gallery/fetchimages", {
//   method: "POST",
//   headers: {
//     "Content-Type": "application/json",
//   },
//   body: JSON.stringify({}),
// });

//       if (!response.ok) {
//         throw new Error("Failed to fetch photos");
//       }

//       const data = await response.json();
//       setPhotos(data.images);
//       setFilteredPhotos(data.images); // Initialize filtered photos
//       setLoading(false);
//     } catch (err) {
//       setLoading(false);
//       setError(err.message);
//     }
//   };

//   // Filter photos based on division
//   const filterPhotosByDivision = async () => {
//     try {
//       setLoading(true);
//       setError("");
//       const response = await fetch("/api/gallery/fetchimages", {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify({ division }),
//       });

//       if (!response.ok) {
//         throw new Error("Failed to fetch photos for the selected division");
//       }

//       const data = await response.json();
//       setFilteredPhotos(data.images);
//       setLoading(false);
//     } catch (err) {
//       setLoading(false);
//       setError(err.message);
//     }
//   };

//   // Handle division filter change
//   const handleDivisionChange = (e) => {
//     setDivision(e.target.value);
//   };

//   // Apply division filter when the division changes
//   useEffect(() => {
//     if (division === "") {
//       setFilteredPhotos(photos); // Reset to all photos if no division selected
//     } else {
//       filterPhotosByDivision();
//     }
//   }, [division]);

//   // Fetch photos on component mount
//   useEffect(() => {
//     fetchPhotos();
//   }, []);

//   // Delete photo
//   const deletePhoto = async (photoId) => {
//     try {
//       const response = await fetch(`/api/gallery/deleteimage/${photoId}`, {
//         method: "DELETE",
//       });

//       if (!response.ok) {
//         throw new Error("Failed to delete image");
//       }

//       // Remove the deleted photo from the state
//       setPhotos(photos.filter((photo) => photo._id !== photoId));
//       setFilteredPhotos(
//         filteredPhotos.filter((photo) => photo._id !== photoId)
//       );
//       alert("Image deleted successfully");
//     } catch (err) {
//       setError(err.message);
//     }
//   };

//   // Handle image upload
//   const handleImageUpload = (e) => {
//     const file = e.target.files[0];
//     if (file) {
//       setNewImage(file);
//     }
//   };

//   // Handle division change during editing
//   const handleDivisionSelect = (e) => {
//     setNewDivision(e.target.value);
//   };

//   // Handle image and division updates during editing
//   const updatePhoto = async () => {
//     if (!newImage && !newDivision) {
//       alert("Please select a new image or division.");
//       return;
//     }

//     const formData = new FormData();
//     if (newImage) {
//       formData.append("mediaUrl", newImage);
//     }
//     if (newDivision) {
//       formData.append("division", newDivision);
//     }

//     try {
//       const response = await fetch(`/api/gallery/editimage/${editPhotoId}`, {
//         method: "PUT",
//         body: formData,
//       });

//       if (!response.ok) {
//         throw new Error("Failed to edit image");
//       }

//       // Update the photo in the state with the new details
//       setPhotos(
//         photos.map((photo) =>
//           photo._id === editPhotoId
//             ? {
//                 ...photo,
//                 mediaUrl: newImage ? newImage : photo.mediaUrl,
//                 division: newDivision ? newDivision : photo.division,
//               }
//             : photo
//         )
//       );
//       setFilteredPhotos(
//         filteredPhotos.map((photo) =>
//           photo._id === editPhotoId
//             ? {
//                 ...photo,
//                 mediaUrl: newImage ? newImage : photo.mediaUrl,
//                 division: newDivision ? newDivision : photo.division,
//               }
//             : photo
//         )
//       );
//       alert("Image updated successfully");

//       // Reset edit mode
//       setIsEditing(false);
//       setEditPhotoId(null);
//       setNewImage(null);
//       setNewDivision("");
//     } catch (err) {
//       setError(err.message);
//     }
//   };

//   // Start editing a photo
//   const startEditing = (photoId, currentDivision) => {
//     setIsEditing(true);
//     setEditPhotoId(photoId);
//     setNewDivision(currentDivision);
//   };

//   return (
//     <div className="gallery-container">
//       <h2>Photo Gallery</h2>

//       {/* Division Filter Dropdown */}
//       <div className="filter-container">
//         <label htmlFor="division">Filter by Division:</label>
//         <select id="division" value={division} onChange={handleDivisionChange}>
//           <option value="">All Divisions</option>
//           <option value="Abhyudaya">Abhyudaya</option>
//           <option value="Division 2">Division 2</option>
//           <option value="Division 3">Division 3</option>
//         </select>
//       </div>

//       {/* Loading Spinner */}
//       {loading && <p>Loading photos...</p>}

//       {/* Error Message */}
//       {error && <p className="error">{error}</p>}

//       {/* Photos Grid */}
//       <div className="photos-grid">
//         {filteredPhotos.map((photo) => (
//           <div key={photo._id} className="photo-item">
//             <img src={modifyImageUrl(photo.mediaUrl)} alt={photo.title} />
//             <div className="photo-details">
//               <h4>{photo.title}</h4>
//               <p>
//                 <strong>Division:</strong> {photo.division}
//               </p>
//               <div className="actions">
//                 <button onClick={() => startEditing(photo._id, photo.division)}>
//                   Edit
//                 </button>
//                 <button onClick={() => deletePhoto(photo._id)}>Delete</button>
//               </div>
//             </div>
//           </div>
//         ))}
//       </div>

//       {/* Edit Photo Form (Appears when editing a photo) */}
//       {isEditing && (
//         <div className="edit-form">
//           <h3>Edit Photo</h3>

//           {/* Image Upload */}
//           <input type="file" accept="image/*" onChange={handleImageUpload} />

//           {/* Division Select */}
//           <select value={newDivision} onChange={handleDivisionSelect}>
//             <option value="Abhyudaya">Abhyudaya</option>
//             <option value="Division 2">Division 2</option>
//             <option value="Division 3">Division 3</option>
//           </select>

//           {/* Save Changes Button */}
//           <button onClick={updatePhoto}>Save Changes</button>
//         </div>
//       )}

//       {/* Inline Styles */}
//       <style jsx>{`
//         .gallery-container {
//           padding: 20px;
//         }

//         .filter-container {
//           margin-bottom: 20px;
//         }

//         .photos-grid {
//           display: grid;
//           grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
//           gap: 20px;
//         }

//         .photo-item {
//           border: 1px solid #ddd;
//           border-radius: 8px;
//           overflow: hidden;
//           text-align: center;
//           background: #f9f9f9;
//         }

//         .photo-item img {
//           width: 100%;
//           height: auto;
//         }

//         .photo-details {
//           padding: 10px;
//         }

//         .actions {
//           margin-top: 10px;
//         }

//         .actions button {
//           margin: 5px;
//           padding: 8px 16px;
//           background-color: #4caf50;
//           color: white;
//           border: none;
//           border-radius: 5px;
//           cursor: pointer;
//         }

//         .actions button:hover {
//           background-color: #45a049;
//         }

//         .edit-form {
//           margin-top: 20px;
//         }

//         .edit-form input {
//           margin-bottom: 10px;
//         }

//         .error {
//           color: red;
//           font-weight: bold;
//         }
//       `}</style>
//     </div>
//   );
// };

// export default GalleryPhotos;
import React, { useState, useEffect } from "react";

const GalleryPhotos = () => {
  const [photos, setPhotos] = useState([]); // State to store all photos
  const [filteredPhotos, setFilteredPhotos] = useState([]); // State for filtered photos
  const [division, setDivision] = useState(""); // State for division filter
  const [error, setError] = useState(""); // State for errors
  const [loading, setLoading] = useState(false); // State for loading spinner
  const [isEditing, setIsEditing] = useState(false); // State to track if editing mode is active
  const [editPhotoId, setEditPhotoId] = useState(null); // ID of the photo being edited
  const [newImage, setNewImage] = useState(null); // New image for upload
  const [newDivision, setNewDivision] = useState(""); // New division selected

  // Function to modify the image URL (to view images)
  const modifyImageUrl = (image) => {
    if (!image) return ""; // If image is null/undefined, return an empty string

    // If image is a string (direct URL), return it as is
    if (typeof image === "string") {
      // Handle Google Drive URLs
      if (image.includes("drive.google.com")) {
        console.log("inside google drive");
        // const match = image.match(/\/d\/(.+?)\//);
        // if (match) {
        //   console.log(`https://lh3.googleusercontent.com/d/${match[1]}=s1900?authuser=0`);
        //   return `https://lh3.googleusercontent.com/d/${match[1]}=s1900?authuser=0`;
        // }
        const fileId = image.match(/\/d\/(.+?)\//)[1]; // Extract file ID from URL if it exists
        return fileId
          ? `https://lh3.googleusercontent.com/d/${fileId}=s1900?authuser=0`
          : image;
      }
      return image; // Return direct AWS S3 links without modification
    }

    // If image is an object (future-proofing), handle it correctly
    if (typeof image === "object" && image.url) {
      if (image.storageType === "google_drive") {
        const match = image.url.match(/\/d\/(.+?)\//);
        if (match) {
          return `https://lh3.googleusercontent.com/d/${match[1]}=s1900?authuser=0`;
        }
      }
      return image.url;
    }

    return ""; // Fallback for unexpected cases
  };
  // Fetch all photos from the backend
  const fetchPhotos = async () => {
    try {
      setLoading(true);
      setError("");
      const response = await fetch("/api/gallery/fetchimages", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({}),
      });

      if (!response.ok) {
        throw new Error("Failed to fetch photos");
      }

      const data = await response.json();
      setPhotos(data.images);
      setFilteredPhotos(data.images); // Initialize filtered photos
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setError(err.message);
    }
  };

  // Filter photos based on division
  const filterPhotosByDivision = async () => {
    try {
      setLoading(true);
      setError("");
      const response = await fetch("/api/gallery/fetchimages", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ division }),
      });

      if (!response.ok) {
        throw new Error("Failed to fetch photos for the selected division");
      }

      const data = await response.json();
      setFilteredPhotos(data.images);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setError(err.message);
    }
  };

  // Handle division filter change
  const handleDivisionChange = (e) => {
    setDivision(e.target.value);
  };

  // Apply division filter when the division changes
  useEffect(() => {
    if (division === "") {
      setFilteredPhotos(photos); // Reset to all photos if no division selected
    } else {
      filterPhotosByDivision();
    }
  }, [division]);

  // Fetch photos on component mount
  useEffect(() => {
    fetchPhotos();
  }, []);

  // Delete photo
  const deletePhoto = async (photoId) => {
    try {
      const response = await fetch(`/api/gallery/deleteimage/${photoId}`, {
        method: "DELETE",
      });

      if (!response.ok) {
        throw new Error("Failed to delete image");
      }

      // Remove the deleted photo from the state
      setPhotos(photos.filter((photo) => photo._id !== photoId));
      setFilteredPhotos(
        filteredPhotos.filter((photo) => photo._id !== photoId)
      );
      alert("Image deleted successfully");
    } catch (err) {
      setError(err.message);
    }
  };

  // Handle image upload
  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setNewImage(file);
    }
  };

  // Handle division change during editing
  const handleDivisionSelect = (e) => {
    setNewDivision(e.target.value);
  };

  // Handle image and division updates during editing
  const updatePhoto = async () => {
    if (!newImage && !newDivision) {
      alert("Please select a new image or division.");
      return;
    }

    const formData = new FormData();
    if (newImage) {
      formData.append("mediaUrl", newImage);
    }
    if (newDivision) {
      formData.append("division", newDivision);
    }

    try {
      const response = await fetch(`/api/gallery/editimage/${editPhotoId}`, {
        method: "PUT",
        body: formData,
      });

      if (!response.ok) {
        throw new Error("Failed to edit image");
      }

      // Update the photo in the state with the new details
      setPhotos(
        photos.map((photo) =>
          photo._id === editPhotoId
            ? {
                ...photo,
                mediaUrl: newImage ? newImage : photo.mediaUrl,
                division: newDivision ? newDivision : photo.division,
              }
            : photo
        )
      );
      setFilteredPhotos(
        filteredPhotos.map((photo) =>
          photo._id === editPhotoId
            ? {
                ...photo,
                mediaUrl: newImage ? newImage : photo.mediaUrl,
                division: newDivision ? newDivision : photo.division,
              }
            : photo
        )
      );
      alert("Image updated successfully");

      // Reset edit mode
      setIsEditing(false);
      setEditPhotoId(null);
      setNewImage(null);
      setNewDivision("");
    } catch (err) {
      setError(err.message);
    }
  };

  // Start editing a photo
  const startEditing = (photoId, currentDivision) => {
    setIsEditing(true);
    setEditPhotoId(photoId);
    setNewDivision(currentDivision);
  };

  return (
    <div className="gallery-container">
      <h2>Photo Gallery</h2>

      {/* Division Filter Dropdown */}
      <div className="filter-container">
        <label htmlFor="division">Filter by Division:</label>
        <select id="division" value={division} onChange={handleDivisionChange}>
          <option value="select value">select value</option>
          <option value="Student">Student</option>
          <option value="Teacher">Teacher</option>
          <option value="Parent">Parent</option>
          <option value="Gallery">Gallery</option>
        </select>
      </div>

      {/* Loading Spinner */}
      {loading && <p>Loading photos...</p>}

      {/* Error Message */}
      {error && <p className="error">{error}</p>}

      {/* Photos Grid */}
      <div className="photos-grid">
        {filteredPhotos.map((photo) => (
          <div key={photo._id} className="photo-item">
            <img src={modifyImageUrl(photo.mediaUrl)} alt={photo.title} />
            <div className="photo-details">
              <h4>{photo.title}</h4>
              <p>
                <strong>Division:</strong> {photo.division}
              </p>
              <div className="actions">
                <button onClick={() => startEditing(photo._id, photo.division)}>
                  Edit
                </button>
                <button onClick={() => deletePhoto(photo._id)}>Delete</button>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Edit Photo Form (Appears when editing a photo) */}
      {isEditing && (
        <div className="edit-form">
          <h3>Edit Photo</h3>

          {/* Image Upload */}
          <input type="file" accept="image/*" onChange={handleImageUpload} />

          {/* Division Select */}
          <select value={newDivision} onChange={handleDivisionSelect}>
            <option value="Student">Student</option>
            <option value="Teacher">Teacher</option>
            <option value="Parent">Parent</option>
            <option value="Gallery">Gallery</option>
          </select>

          {/* Save Changes Button */}
          <button onClick={updatePhoto}>Save Changes</button>
        </div>
      )}

      {/* Inline Styles */}
      <style jsx>{`
        .gallery-container {
          padding: 20px;
          font-family: Arial, sans-serif;
        }

        .filter-container {
          margin-bottom: 20px;
        }

        .photos-grid {
          display: grid;
          grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
          gap: 20px;
        }

        .photo-item {
          border: 2px solid #1e2a47;
          border-radius: 8px;
          overflow: hidden;
          text-align: center;
          background: #f1f5f9;
          padding: 10px;
        }

        .photo-item img {
          width: 100%;
          height: auto;
          max-width: 400px;
          max-height: 300px;
          object-fit: cover;
        }

        .photo-details {
          margin-top: 10px;
        }

        .actions button {
          background-color: #f44336;
          color: white;
          border: none;
          padding: 10px;
          gap: 10px;
          border-radius: 5px;
          cursor: pointer;
        }

        .actions button:hover {
          background-color: #d32f2f;
        }

        .edit-form {
          background: #e3f2fd;
          padding: 20px;
          border-radius: 8px;
          max-width: 400px;
          margin-top: 20px;
        }

        .edit-form input,
        .edit-form select {
          width: 100%;
          padding: 10px;
          margin: 10px 0;
          border: 1px solid #1e2a47;
          border-radius: 5px;
        }

        .edit-form button {
          background-color: #1976d2;
          color: white;
          padding: 10px 20px;
          border: none;
          border-radius: 5px;
          cursor: pointer;
        }

        .edit-form button:hover {
          background-color: #1565c0;
        }

        .error {
          color: red;
        }
      `}</style>
    </div>
  );
};

export default GalleryPhotos;
