// import React, { useState, useEffect } from "react";
// import { Link } from "react-router-dom";
// import { useSelector } from "react-redux";
// import "animate.css";

// const MiddleScreen4 = () => {
//   const userState = useSelector((state) => state.loginUserReducer);
//   const { currentAbhyudayaUser } = userState;

//   const buttonShadowStyle = {
//     boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.5)",
//     transition: "background-color 0.3s, box-shadow 0.3s",
//   };

//   const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
//   const [showDisclaimer, setShowDisclaimer] = useState(true);
//   const [showVideoPopup, setShowVideoPopup] = useState(true);
//   const [showWelcome, setShowWelcome] = useState(true); // State to control the welcome message

//   useEffect(() => {
//     const handleResize = () => {
//       setIsMobile(window.innerWidth < 768);
//     };

//     window.addEventListener("resize", handleResize);

//     // Hide the welcome message after 5 seconds
//     const timer = setTimeout(() => {
//       setShowWelcome(false);
//     }, 5000); // 5 seconds

//     return () => {
//       window.removeEventListener("resize", handleResize);
//       clearTimeout(timer); // Clean up the timer on component unmount
//     };
//   }, []);

//   return (
//     <div
//       style={{
//         paddingTop: "50px",
//         minHeight: "100vh",
//         position: "relative",
//         background: "radial-gradient(circle, #cfe0e8, #6a92bf)",
//         overflowX: "hidden",
//         display: "flex",
//         justifyContent: "center",
//         alignItems: "center",
//       }}
//     >
//       {/* Welcome Message */}
//       {showWelcome && (
//         <div
//           style={{
//             position: "absolute",
//             top: "0",
//             left: "0",
//             right: "0",
//             bottom: "0",
//             display: "flex",
//             justifyContent: "center",
//             alignItems: "center",
//             zIndex: "1000",
//             animation: "popIn 1s ease-out", // Custom animation for popping effect
//           }}
//           className="animate__animated animate__zoomIn" // Use a zoom-in animation from animate.css
//         >
//           <h1
//             style={{
//               fontFamily: "'Poppins', sans-serif",
//               color: "#E63946", // Bright color for the text
//               fontSize: "60px", // Increase the font size
//               fontWeight: "bold",
//               textAlign: "center",
//               textTransform: "uppercase",
//               background: "linear-gradient(to right, #ff7e5f, #feb47b)", // Gradient effect for text
//               WebkitBackgroundClip: "text", // Clip the background to the text
//               color: "transparent", // Make the text color transparent so gradient shows
//               padding: "10px",
//               letterSpacing: "5px", // Add some spacing to make it more dramatic
//             }}
//           >
//             WELCOME
//           </h1>
//         </div>
//       )}

//       {/* White Container behind Content */}
//       <div
//         style={{
//           backgroundColor: "white",
//           borderRadius: "10px",
//           padding: "20px",
//           boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)",
//           maxWidth: "1200px",
//           width: "100%",
//         }}
//       >
//         {/* Main Content Area */}
//         <div
//           style={{
//             display: "flex",
//             flexDirection: isMobile ? "column" : "row",
//             alignItems: "center",
//           }}
//         >
//           {/* Left Side with Image */}
//           <div
//             style={{
//               display: "flex",
//               flexDirection: "column",
//               alignItems: "center",
//               justifyContent: "center",
//               flex: "1",
//               animation: "bounceInLeft 1s",
//             }}
//           >
//             <img
//               src={"Achievements.gif"} // Adjust the image as needed
//               alt="Welcome"
//               style={{
//                 width: "100%",
//                 maxWidth: "400px", // Adjust as per your requirement
//                 marginBottom: "20px",
//                 borderRadius: "15px",
//                 boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.15)",
//               }}
//             />
//           </div>

//           {/* Right Side with Text */}
//           <div
//             style={{
//               display: "flex",
//               flexDirection: "column",
//               alignItems: isMobile ? "center" : "flex-start",
//               textAlign: isMobile ? "center" : "left",
//               flex: "1",
//               paddingLeft: isMobile ? "0" : "40px",
//               animation: "fadeIn 1s ease-in-out",
//             }}
//           >
//             <h2
//               style={{
//                 color: "#E63946",
//                 fontWeight: "600",
//                 marginBottom: "15px",
//                 fontSize: "2.5rem",
//                 animation: "slideInFromLeft 1s",
//               }}
//             >
//               ABHYUDAYA HOME PAGE
//             </h2>
//             <h1
//               style={{
//                 fontFamily: "'Poppins', sans-serif",
//                 color: "#1E3A8A",
//                 fontWeight: "bold",
//                 fontSize: "2.5rem",
//                 marginBottom: "10px",
//                 animation: "zoomIn 1s ease",
//               }}
//             >
//               LEARN WITH THE BEST!
//             </h1>
//             <p
//               style={{
//                 color: "#555",
//                 fontSize: "16px",
//                 maxWidth: "500px",
//                 lineHeight: "1.5",
//                 marginBottom: "20px",
//                 animation: "fadeIn 2s ease-out",
//               }}
//             >
//               Welcome to Gyankosha's Abhyudaya Program! 🌍📚 We are dedicated to
//               helping students master General Knowledge (GK) and General
//               Awareness, laying a strong foundation for success in competitive
//               exams like UPSC, State PSCs, SSC, BANK PO, NDA, and more. Here's
//               how we can help you:
//               <br />
//               <br />
//               📖 Comprehensive Subject Mastery : Strengthening core subjects
//               with a clear and engaging approach in form of Question Sets with
//               graded difficulty levels ( Set 1- Average...... Set 5- Very
//               Difficult)
//               <br />
//               🏆 Exam-Focused GK Training : Comprehensive coverage of static and
//               current affairs tailored to exam patterns.
//               <br />
//               📊 General Awareness Boost : Stay updated on national and
//               international events with structured modules.
//               <br />
//               📚 Expert-Led Sessions: Learn from subject matter experts who
//               simplify complex topics for better understanding.
//               <br />
//               🧠 Critical Thinking Development : Enhance analytical and
//               reasoning skills for competitive advantage.
//               <br />
//               🔍 Mock Tests & Practice Papers: Prepare with real-time
//               assessments to improve accuracy and time management.
//               <br />
//               🎯 Tailored for Success : Programs aligned with the latest
//               syllabus and exam trends to ensure high performance.
//               <br />
//               <br />
//               Start your journey to competitive exam success with us and turn
//               your aspirations into achievements! 🚀
//             </p>

// <Link
//   to={currentAbhyudayaUser ? "/abhyudayascreen" : "/abhyudayalogin"}
//   style={{
//     display: "inline-block",
//     backgroundColor: "#E63946",
//     color: "#fff",
//     padding: "12px 30px",
//     borderRadius: "5px",
//     textDecoration: "none",
//     fontWeight: "bold",
//     boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)",
//     transition: "transform 0.3s ease-in-out",
//   }}
//   onMouseEnter={(e) => {
//     e.target.style.transform = "scale(1.1)";
//   }}
//   onMouseLeave={(e) => {
//     e.target.style.transform = "scale(1)";
//   }}
// >
//   {currentAbhyudayaUser ? "Abhyudaya Quiz" : "Login"}
// </Link>
//           </div>
//         </div>
//       </div>

//       {/* Small YouTube Video Popup in Corner */}
//       {showVideoPopup && (
//         <div
//           style={{
//             position: "fixed",
//             bottom: "60px",
//             right: "20px",
//             width: "250px",
//             backgroundColor: "#fff",
//             boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)",
//             borderRadius: "10px",
//             padding: "10px",
//             zIndex: "1000",
//             animation: "fadeIn 2s",
//           }}
//         >
//           <iframe
//             width="230"
//             height="130"
//             src="https://www.youtube.com/embed/oJdGGgxl5XE?si=n1uXx-s0omRQtHv9"
//             title="YouTube video player"
//             frameBorder="0"
//             allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
//             allowFullScreen
//             style={{ borderRadius: "8px" }}
//           ></iframe>
//           <button
//             onClick={() => setShowVideoPopup(false)}
//             style={{
//               position: "absolute",
//               top: "5px",
//               right: "5px",
//               background: "transparent",
//               border: "none",
//               fontSize: "16px",
//               cursor: "pointer",
//               color: "#333",
//             }}
//             aria-label="Close Video Popup"
//           >
//             &times;
//           </button>
//         </div>
//       )}

//       {/* Disclaimer at the Bottom */}
//       {showDisclaimer && (
//         <div
//           style={{
//             position: "fixed",
//             bottom: "0",
//             width: "100%",
//             textAlign: "center",
//             fontSize: "12px",
//             color: "#666",
//             fontFamily: "'Libre Baskerville', serif",
//             padding: "15px 20px",
//             backgroundColor: "#D3D3D3",
//             borderTop: "1px solid black",
//             boxShadow: "0px -2px 10px rgba(0, 0, 0, 0.2)",
//             zIndex: "1000",
//             display: "flex",
//             alignItems: "center",
//             justifyContent: "center",
//             animation: "slideInUp 2s",
//           }}
//         >
//           <span style={{ maxWidth: "90%", textAlign: "left" }}>
//             <strong>Disclaimer :</strong> All the information published here is
//             in good faith and for general information purposes only. Every
//             effort has been made to make sure that the information presented
//             here is accurate and correct, but the website (company) does not
//             make any warranties about the completeness, reliability, and
//             accuracy of this information. Any action you take upon the
//             information on this website is strictly at your own risk. The
//             company will not be liable for any losses and/or damages in
//             connection with the use of the content.
//           </span>
//           <button
//             onClick={() => setShowDisclaimer(false)}
//             style={{
//               marginLeft: "20px",
//               background: "transparent",
//               border: "none",
//               fontSize: "18px",
//               cursor: "pointer",
//               color: "#333",
//             }}
//             aria-label="Close Disclaimer"
//           >
//             &times;
//           </button>
//         </div>
//       )}
//     </div>
//   );
// };

// export default MiddleScreen4;
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import "animate.css";

const MiddleScreen4 = () => {
  const userstate = useSelector((state) => state.loginUserReducer);
  const { currentUser1 } = userstate;

  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [showDisclaimer, setShowDisclaimer] = useState(true);
  const [showVideoPopup, setShowVideoPopup] = useState(true);
  const [showWelcome, setShowWelcome] = useState(true);
  const [blurred, setBlurred] = useState(true); // State to control blur effect

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    window.addEventListener("resize", handleResize);

    document.body.style.margin = "0";
    document.body.style.padding = "0";
    document.body.style.background =
      "radial-gradient(circle at 74.2% 50.9%, rgb(14, 72, 222) 5.2%, rgb(3, 22, 65) 75.3%)";
    document.body.style.backgroundSize = "cover";
    document.body.style.backgroundAttachment = "fixed";

    const timer = setTimeout(() => {
      setShowWelcome(false);
    }, 1000); // 5 seconds

    const blurTimer = setTimeout(() => {
      setBlurred(false); // Remove blur after 5 seconds
    }, 1000); // 5 seconds

    return () => {
      window.removeEventListener("resize", handleResize);
      clearTimeout(timer);
      clearTimeout(blurTimer);
      document.body.style.background = "";
    };
  }, []);

  return (
    <div
      style={{
        paddingTop: "50px",
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        overflowX: "hidden",
        position: "relative",
      }}
    >
      {/* Welcome Message */}
      {showWelcome && (
        <div
          style={{
            position: "absolute",
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: "1000",
            animation: "popIn 1s ease-out",
          }}
          className="animate__animated animate__zoomIn"
        >
          <div
            style={{
              fontFamily: '"Libre Baskerville", serif',
              fontSize: "10vw", // Increased font size for visibility
              fontWeight: "bold",
              textAlign: "center",
              textTransform: "uppercase",
              background: "linear-gradient(to right, #0074D9, #001f3f)", // Gradient from blue to dark blue
              WebkitBackgroundClip: "text", // Clip the background to text for gradient
              color: "transparent", // Text color is transparent to show the background gradient
              padding: "10px",
              letterSpacing: "5px",
              zIndex: "1000", // Ensure it's on top
            }}
          >
            <b>WELCOME</b>
          </div>
        </div>
      )}
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      {/* White Container behind Content with Blur Effect for 5 seconds */}
      <div
        style={{
          backgroundColor: "white", // Slightly transparent
          borderRadius: "10px",
          padding: "20px",
          boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)",
          maxWidth: "1200px",
          width: "100%",
          marginTop: "50px",
          filter: blurred ? "blur(5px)" : "none", // Apply blur effect only for 5 seconds
          transition: "filter 1s ease-out", // Smooth transition for blur effect
          zIndex: "1", // Ensure the blur effect is behind the "Welcome" message
        }}
      >
        {/* <center>
          <h2
            style={{
              color: "#ef0b0b",
              fontFamily: '"Libre Baskerville", serif',
              fontWeight: "600",
              marginBottom: "15px",
              justifyContent: "center",
              lineHeight: "1.4", // Reduced line spacing
            }}
          >
            BAUDDHA DAKSHTA HOME PAGE
          </h2>
        </center> */}
        {/* Main Content Area */}
        <div
          style={{
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
            alignItems: "center",
          }}
        >
          {/* Left Side with Image */}
          <div
            style={{
              position: "relative",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              flex: "1",
            }}
            className="animate__animated animate__fadeInLeft"
          >
            <center>
              <h2
                style={{
                  position: "absolute",
                  color: "#ef0b0b",
                  fontFamily: '"Libre Baskerville", serif',
                  fontWeight: "600",
                  marginBottom: "5px",
                  textAlign: "center",
                  lineHeight: "1.4", // Reduced line spacing
                  zIndex: 1,
                  top: "5%", // Adjust the vertical position
                  left: "50%", // Adjust the horizontal position
                  transform: "translate(-50%, -50%)", // Center the text
                  whiteSpace: "nowrap", // Prevent text from breaking into multiple lines
                }}
              >
                ABHYUDAYA
              </h2>
            </center>
            <img
              src={"Achievements.gif"}
              alt="Mental Abilities"
              style={{
                width: "100%",
                maxWidth: "800px", // Increased image size
                marginBottom: "20px",
                marginTop: "90px",
              }}
            />
          </div>

          {/* Right Side with Text */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: isMobile ? "center" : "flex-start",
              textAlign: isMobile ? "center" : "justify", // Justified text alignment
              flex: "1",
              paddingLeft: isMobile ? "0" : "40px",
              justifyContent: "center", // Vertically center the content
            }}
            className="animate__animated animate__fadeInRight"
          >
            <Link
              to={currentUser1 ? "/abhyudayascreen" : "/login"}
              style={{
                display: "inline-block",
                backgroundColor: "#E63946",
                color: "#fff",
                padding: "12px 30px",
                borderRadius: "5px",
                textDecoration: "none",
                fontWeight: "bold",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)",
                transition: "transform 0.3s ease-in-out",
              }}
              onMouseEnter={(e) => {
                e.target.style.transform = "scale(1.1)";
              }}
              onMouseLeave={(e) => {
                e.target.style.transform = "scale(1)";
              }}
            >
              {currentUser1 ? "Abhyudaya Quiz" : "Login"}
            </Link>
            <br></br>
            <h2
              style={{
                color: "#0e003d",
                fontFamily: '"Libre Baskerville", serif',
                fontWeight: "600",
                marginBottom: "10px",
                textAlign: "center",
                lineHeight: "1.5", // Reduced line spacing
              }}
            >
              Welcome to Gyankosha's Abhyudaya Program!{" "}
            </h2>
            <div
              style={{
                fontFamily: '"Libre Baskerville", serif',
                fontSize: "17px",
                textAlign: "justify",
                lineHeight: "1.2",
                color: "#000000",
              }}
            >
              Gyankosha through its Abhyudaya Program helps competitive exams
              aspirants master each topic and excel in their examinations
              through a uniquely designed blend of chapter sets, interactive
              quizzes and as an integrated learning platform. ( Competitive
              Exams: UPSC, State PSCs, SSC, IBPS, NDA etc.)
              <br></br>
            </div>
            <br></br>
            <strong>
              {" "}
              <div
                style={{
                  fontFamily: '"Libre Baskerville", serif',
                  fontSize: "20px",
                  color: "#000000",
                }}
              >
                Here are our offerings:{" "}
              </div>
            </strong>
            <div>
              <ul
                style={{
                  color: "#000000",
                  fontSize: "16px",
                  lineHeight: "1.4", // Reduced line spacing
                  listStyleType: "disc",
                  paddingLeft: "20px",
                  maxWidth: "500px",
                  fontFamily: "'Libre Baskerville', serif",
                }}
              >
                {/* <strong>
                {" "}
                At Gyankosha's Bauddha Dakshata vertical, we specialize in
                nurturing Mental Abilities and Analytical Prowess to help
                students think critically, solve problems, and achieve academic
                success.
              </strong> */}

                {/* Here's how we make it happen: */}
                <li>
                  Detailed Course Content (History, Geography, Polity,
                  Environment) in Forms of Graded Chapter Sets and Practice Sets
                </li>
                <li>
                  Comprehensive CurrentAffairs Coverage in form of Question Sets
                </li>
                <li>Detailed Daily News Coverage : In form of Question Sets</li>
                <li>NCERT Coverage: Graded Question Sets</li>
                <li>Mock Tests: Weekly, Monthly and Many More...</li>
                {/* <li>
                  <strong>
                    <b> Mock Tests & Practice Papers: </b>
                  </strong>{" "}
                  Prepare with real-time assessments to improve accuracy and
                  time management.{" "}
                </li>
                <li>
                  <strong>
                    <b> Tailored for Success : </b>
                  </strong>{" "}
                  Programs aligned with the latest syllabus and exam trends to
                  ensure high performance.{" "}
                </li>
                <li>
                  Start your journey to competitive exam success with us and
                  turn your aspirations into achievements!
                </li> */}
              </ul>
            </div>
            {/* <Link
              to={currentUser1 ? "/bauddhadakshata" : "/login"}
              style={{
                display: "inline-block",
                backgroundColor: "#ef0b0b",
                color: "#fff",
                padding: "10px 20px",
                borderRadius: "5px",
                textDecoration: "none",
                fontWeight: "bold",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)",
              }}
            >
              {currentUser1 ? "Bauddha Dakshata Quiz" : "Login"}
            </Link> */}
          </div>
        </div>
      </div>

      {/* Small YouTube Video Popup in Corner */}
      {showVideoPopup && (
        <div
          style={{
            position: "fixed",
            bottom: "60px",
            left: "20px",
            width: "200px",
            backgroundColor: "#fff",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            padding: "10px",
            zIndex: "1000",
          }}
          className="animate__animated animate__fadeIn"
        >
          <iframe
            width="180"
            height="100"
            src="https://www.youtube.com/embed/oJdGGgxl5XE?si=n1uXx-s0omRQtHv9"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            style={{
              borderRadius: "5px",
            }}
          ></iframe>
          <button
            onClick={() => setShowVideoPopup(false)}
            className="btn btn-primary"
            style={{
              //backgroundColor: "#ff4d4d",
              border: "none",
              color: "white",
              padding: "5px 10px",
              fontSize: "12px",
              cursor: "pointer",
              borderRadius: "5px",
              marginTop: "5px",
              width: "100%",
            }}
          >
            Close
          </button>
        </div>
      )}

      {/* Disclaimer */}
      {showDisclaimer && (
        <div
          style={{
            position: "fixed",
            bottom: "0",
            width: "100%",
            textAlign: "center",
            fontSize: "12px",
            color: "#666",
            fontFamily: "'Libre Baskerville', serif",
            padding: "15px 20px",
            backgroundColor: "#D3D3D3",
            borderTop: "1px solid black",
            boxShadow: "0px -2px 10px rgba(0, 0, 0, 0.2)",
            zIndex: "1000",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          className="animate__animated animate__fadeInUp"
        >
          <span style={{ maxWidth: "90%", textAlign: "left" }}>
            <strong>Disclaimer :</strong> All the information published here is
            in good faith and for general information purposes only. Every
            effort has been made to make sure that the information presented
            here is accurate and correct, but the website (company) does not
            make any warranties about the completeness, reliability, and
            accuracy of this information. Any action you take upon the
            information on this website is strictly at your own risk. The
            company will not be liable for any losses and/or damages in
            connection with the use of the content.
          </span>
          <button
            onClick={() => setShowDisclaimer(false)}
            style={{
              marginLeft: "20px",
              background: "transparent",
              border: "none",
              fontSize: "18px",
              cursor: "pointer",
              color: "#333",
            }}
            aria-label="Close Disclaimer"
          >
            &times;
          </button>
        </div>
      )}
    </div>
  );
};

export default MiddleScreen4;
