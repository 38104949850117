import axios from "axios";

export const KBgetAllQuizs = () => async (dispatch) => {
  dispatch({ type: "GET_QUIZS_REQUEST" });
  try {
    const response = await axios.get(`/api/KyebyeQuiz/getAllKyebyeQuizs`);
    dispatch({ type: "GET_QUIZS_SUCCESS", payload: response.data });
  } catch (error) {
    dispatch({
      type: "GET_QUIZS_FAILED",
      payload: error.response?.data?.message || error.message,
    });
  }
};

export const KBaddQuiz = (quiz) => async (dispatch) => {
  dispatch({ type: "ADD_QUIZ_REQUEST" });
  try {
    const response = await axios.post(`/api/KyebyeQuiz/addKyebyeQuiz`, {
      quiz,
    });
    console.log(response);
    dispatch({ type: "ADD_QUIZ_SUCCESS", payload: { success: true } });
  } catch (error) {
    dispatch({
      type: "ADD_QUIZ_FAILED",
      payload: error.response?.data?.message || error.message,
    });
  }
};

export const KBeditQuiz = (editedquiz) => async (dispatch) => {
  dispatch({ type: "EDIT_QUIZ_REQUEST" });
  try {
    const response = await axios.post(`/api/KyebyeQuiz/Kyebyeeditquiz`, {
      editedquiz,
    });
    console.log(response);
    dispatch({ type: "EDIT_QUIZ_SUCCESS" });
    window.location.href = "/adminpanel/KyeByeQuizList";
  } catch (error) {
    dispatch({ type: "EDIT_QUIZ_FAILED", payload: error });
  }
};

export const KBdeleteQuiz = (quizid) => async (dispatch) => {
  try {
    const response = await axios.post(`/api/KyebyeQuiz/deleteKyebyeQuiz`, {
      quizid,
    });
    alert("Quiz Deleted Successfully");
    console.log(response);
    //window.location.reload();
    window.location.href = "/adminpanel";
  } catch (error) {
    alert("Something went wrong");
    console.log(error);
  }
};

export const KBgetQuizById = (quizid) => async (dispatch) => {
  dispatch({ type: "GET_QUIZBYID_REQUEST" });
  try {
    const response = await axios.post(`/api/KyebyeQuiz/getKyebyeQuizById`, {
      quizid,
    });
    console.log(response);

    dispatch({ type: "GET_QUIZBYID_SUCCESS", payload: response.data });
  } catch (error) {
    dispatch({
      type: "GET_QUIZBYID_FAILED",
      payload: error.response ? error.response.data : error.message,
    });
  }
};

export const KBupdateQuizEnabledStatus =
  (quizId, schoolcode, enabled) => async (dispatch) => {
    console.log("Data being sent to API:", { quizId, schoolcode, enabled }); // Debugging log
    try {
      const response = await axios.post(`/api/KyebyeQuiz/updatekyebyeEnable`, {
        quizId,
        schoolcode,
        enabled,
      });
      console.log(response);
      dispatch(KBgetAllQuizs()); // Refresh the quiz list
    } catch (error) {
      console.log(
        "Error in KBupdateQuizEnabledStatus:",
        error.response?.data || error.message
      );
    }
  };
