// // // // // import React, { useState } from "react";

// // // // // function Parenting() {
// // // // //   const [isVideoOpen, setIsVideoOpen] = useState(false);

// // // // //   const handleButtonClick = () => {
// // // // //     alert("Button clicked!");
// // // // //   };

// // // // //   return (
// // // // //     <div style={{ position: "relative", height: "100vh", overflow: "hidden" }}>
// // // // //       {/* Navbar */}
// // // // //       <nav
// // // // //         style={{
// // // // //           backgroundColor: "#0e003d", // Blue background
// // // // //           color: "white", // White text
// // // // //           padding: "15px 20px",
// // // // //           position: "sticky",
// // // // //           top: 0,
// // // // //           zIndex: 1, // Reduced z-index
// // // // //           textAlign: "center",
// // // // //           boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)", // Add shadow for depth
// // // // //         }}
// // // // //       >
// // // // //         <h2
// // // // //           style={{
// // // // //             fontSize: "1.8rem",
// // // // //             fontWeight: "bold",
// // // // //             letterSpacing: "1px",
// // // // //             margin: 0,
// // // // //             textShadow: "2px 2px 4px rgba(0, 0, 0, 0.3)", // Add subtle text shadow
// // // // //           }}
// // // // //         >
// // // // //           Parenting Excellence Program: In Partnership with Schools
// // // // //         </h2>
// // // // //       </nav>

// // // // //       {/* Main Content */}
// // // // //       <div
// // // // //         className="parenting-container"
// // // // //         style={{
// // // // //           display: "flex",
// // // // //           flexDirection: "row",
// // // // //           overflowY: "auto",
// // // // //           height: "calc(100vh - 60px)", // Adjust height to fit below the navbar
// // // // //           backgroundColor: "white",
// // // // //           padding: "20px",
// // // // //           gap: "20px", // Space between left and right sections
// // // // //         }}
// // // // //       >
// // // // //         {/* Left Side: Image */}
// // // // //         <div
// // // // //           className="left-side"
// // // // //           style={{
// // // // //             flex: 1, // Equal size for left and right sections
// // // // //             display: "flex",
// // // // //             justifyContent: "center",
// // // // //             alignItems: "center",
// // // // //           }}
// // // // //         >
// // // // //           <img
// // // // //             src="/family.png"
// // // // //             alt="Parenting GIF"
// // // // //             style={{
// // // // //               width: "100%",
// // // // //               height: "auto",
// // // // //               maxHeight: "400px",
// // // // //               borderRadius: "10px", // Add rounded corners
// // // // //             }}
// // // // //           />
// // // // //         </div>

// // // // //         {/* Right Side: Text and Link */}
// // // // //         <div
// // // // //           className="right-side"
// // // // //           style={{
// // // // //             flex: 1, // Equal size for left and right sections
// // // // //             padding: "20px",
// // // // //             textAlign: "left", // Align text to the left
// // // // //             fontFamily: '"Libre Baskerville", serif',
// // // // //           }}
// // // // //         >
// // // // //           <strong
// // // // //             style={{
// // // // //               fontSize: "1.2rem",
// // // // //               marginBottom: "15px",
// // // // //               display: "block",
// // // // //               color: "#0e003d",
// // // // //             }}
// // // // //           >
// // // // //             Gyankosha’s Parenting Excellence Program:
// // // // //           </strong>
// // // // //           <h2
// // // // //             style={{
// // // // //               fontSize: "1.5rem",
// // // // //               fontWeight: "bold",
// // // // //               marginBottom: "20px",
// // // // //               color: "#333",
// // // // //             }}
// // // // //           >
// // // // //             Ensuring schools and parents work as partners in shaping students’
// // // // //             holistic development
// // // // //           </h2>
// // // // //           <p
// // // // //             style={{
// // // // //               fontSize: "1rem",
// // // // //               marginBottom: "20px",
// // // // //               lineHeight: "1.6",
// // // // //             }}
// // // // //           >
// // // // //             <ul style={{ paddingLeft: "20px" }}>
// // // // //               <li>
// // // // //                 Thoughtfully designed to empower parents with practical tools
// // // // //                 and strategies to support their child’s academic, emotional, and
// // // // //                 social development.
// // // // //               </li>
// // // // //               <li>
// // // // //                 Delivered in collaboration with schools, these sessions cover
// // // // //                 critical topics such as managing academic stress, fostering
// // // // //                 emotional intelligence, positive discipline, and nurturing
// // // // //                 independence.
// // // // //               </li>
// // // // //               <li>
// // // // //                 Through engaging, interactive workshops, Gyankosha helps parents
// // // // //                 build stronger connections with their children, navigate modern
// // // // //                 parenting challenges, and create a supportive environment for
// // // // //                 holistic growth.
// // // // //               </li>
// // // // //               <li>
// // // // //                 These sessions not only enhance parenting skills but also
// // // // //                 strengthen the partnership between parents and schools, ensuring
// // // // //                 a unified approach to a child’s success.
// // // // //               </li>
// // // // //             </ul>
// // // // //           </p>
// // // // //           <a
// // // // //             href="#"
// // // // //             className="video-link"
// // // // //             style={{
// // // // //               color: "#0e003d",
// // // // //               textDecoration: "underline",
// // // // //               fontSize: "1rem",
// // // // //               display: "block",
// // // // //               marginBottom: "20px",
// // // // //             }}
// // // // //             onClick={(e) => {
// // // // //               e.preventDefault();
// // // // //               setIsVideoOpen(true);
// // // // //             }}
// // // // //           >
// // // // //             Watch Video
// // // // //           </a>
// // // // //           <button
// // // // //             className="action-button"
// // // // //             onClick={handleButtonClick}
// // // // //             style={{
// // // // //               backgroundColor: "#0e003d",
// // // // //               color: "white",
// // // // //               padding: "10px 20px",
// // // // //               border: "none",
// // // // //               borderRadius: "5px",
// // // // //               cursor: "pointer",
// // // // //               fontSize: "1rem",
// // // // //             }}
// // // // //           >
// // // // //             Question? Contact Us
// // // // //           </button>
// // // // //         </div>
// // // // //       </div>

// // // // //       {/* Video Popup */}
// // // // //       {isVideoOpen && (
// // // // //         <div
// // // // //           className="video-modal"
// // // // //           style={{
// // // // //             position: "fixed",
// // // // //             top: 0,
// // // // //             left: 0,
// // // // //             right: 0,
// // // // //             bottom: 0,
// // // // //             backgroundColor: "rgba(0, 0, 0, 0.7)",
// // // // //             zIndex: 10,
// // // // //             display: "flex",
// // // // //             justifyContent: "center",
// // // // //             alignItems: "center",
// // // // //           }}
// // // // //         >
// // // // //           <div
// // // // //             className="video-content"
// // // // //             style={{
// // // // //               position: "relative",
// // // // //               backgroundColor: "white",
// // // // //               padding: "20px",
// // // // //               borderRadius: "10px",
// // // // //               boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)",
// // // // //             }}
// // // // //           >
// // // // //             <button
// // // // //               className="close-button"
// // // // //               onClick={() => setIsVideoOpen(false)}
// // // // //               style={{
// // // // //                 position: "absolute",
// // // // //                 top: "10px",
// // // // //                 right: "10px",
// // // // //                 fontSize: "30px",
// // // // //                 color: "#333",
// // // // //                 background: "none",
// // // // //                 border: "none",
// // // // //                 cursor: "pointer",
// // // // //               }}
// // // // //             >
// // // // //               &times;
// // // // //             </button>
// // // // //             <iframe
// // // // //               width="560"
// // // // //               height="315"
// // // // //               src="https://www.youtube.com/embed/dQw4w9WgXcQ"
// // // // //               title="YouTube video player"
// // // // //               frameBorder="0"
// // // // //               allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
// // // // //               allowFullScreen
// // // // //               style={{
// // // // //                 maxWidth: "100%",
// // // // //                 maxHeight: "100%",
// // // // //                 borderRadius: "10px",
// // // // //               }}
// // // // //             ></iframe>
// // // // //           </div>
// // // // //         </div>
// // // // //       )}
// // // // //     </div>
// // // // //   );
// // // // // }

// // // // // export default Parenting;
// // // // import React, { useState } from "react";

// // // // function Parenting() {
// // // //   const [isVideoOpen, setIsVideoOpen] = useState(false);

// // // //   const handleButtonClick = () => {
// // // //     alert("Button clicked!");
// // // //   };

// // // //   return (
// // // //     <div
// // // //       style={{
// // // //         position: "relative",
// // // //         height: "100vh",
// // // //         overflow: "hidden",
// // // //         bottom: "0px",
// // // //       }}
// // // //     >
// // // //       {/* Navbar */}
// // // //       <nav
// // // //         style={{
// // // //           backgroundColor: "#0e003d",
// // // //           color: "white",
// // // //           padding: "15px 20px",
// // // //           position: "sticky",
// // // //           top: 0,
// // // //           zIndex: 1,
// // // //           textAlign: "center",
// // // //           boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
// // // //           width: "100vw",
// // // //         }}
// // // //       >
// // // //         <h2
// // // //           style={{
// // // //             fontSize: "1.5rem",
// // // //             fontWeight: "bold",
// // // //             letterSpacing: "1px",
// // // //             margin: 0,
// // // //             textShadow: "2px 2px 4px rgba(0, 0, 0, 0.3)",
// // // //             fontFamily: '"Libre Baskerville", serif',
// // // //           }}
// // // //         >
// // // //           Parenting Excellence Program: In Partnership with Schools
// // // //         </h2>
// // // //       </nav>

// // // //       {/* Main Content */}
// // // //       <div
// // // //         className="parenting-container"
// // // //         style={{
// // // //           display: "flex",
// // // //           flexDirection: "row",
// // // //           overflowY: "auto",
// // // //           height: "calc(100vh - 50px)",
// // // //           backgroundColor: "white",
// // // //           padding: "0px",
// // // //           gap: "20px",
// // // //         }}
// // // //       >
// // // //         {/* Left Side: Image */}
// // // //         <div
// // // //           className="left-side"
// // // //           style={{
// // // //             flex: 1,
// // // //             display: "flex",
// // // //             justifyContent: "center",
// // // //             alignItems: "center",
// // // //             height: "calc(5px-100vh)",
// // // //           }}
// // // //         >
// // // //           <img
// // // //             src="/family.png"
// // // //             alt="Parenting GIF"
// // // //             style={{
// // // //               width: "100%",
// // // //               height: "auto",
// // // //               maxHeight: "500px",
// // // //               borderRadius: "0px",
// // // //               alignContent: "center",
// // // //             }}
// // // //           />
// // // //         </div>

// // // //         {/* Right Side: Text and Content */}
// // // //         <div
// // // //           className="right-side"
// // // //           style={{
// // // //             flex: "1 1 15%",
// // // //             padding: "20px",
// // // //             textAlign: "left",
// // // //             fontFamily: '"Libre Baskerville", serif',
// // // //             fontSize: "0.9rem",
// // // //           }}
// // // //         >
// // // //           <strong
// // // //             style={{
// // // //               fontSize: "1.5rem",
// // // //               marginBottom: "5px",
// // // //               display: "block",
// // // //               color: "#0e003d",
// // // //             }}
// // // //           >
// // // //             Gyankosha’s Parenting Excellence Program:
// // // //           </strong>
// // // //           <h2
// // // //             style={{
// // // //               fontSize: "1.3rem",
// // // //               fontWeight: "bold",
// // // //               marginBottom: "10px",
// // // //               color: "#333",
// // // //             }}
// // // //           >
// // // //             Ensuring schools and parents work as partners in shaping students’
// // // //             holistic development
// // // //           </h2>
// // // //           <ul style={{ paddingLeft: "20px", marginBottom: "10px" }}>
// // // //             <li>
// // // //               Thoughtfully designed to empower parents with practical tools and
// // // //               strategies to support their child’s academic, emotional, and
// // // //               social development.{" "}
// // // //             </li>
// // // //             <li>
// // // //               Delivered in collaboration with schools, these sessions cover
// // // //               critical topics such as managing academic stress, fostering
// // // //               emotional intelligence, positive discipline, and nurturing
// // // //               independence.
// // // //             </li>
// // // //             <li>
// // // //               Through engaging, interactive workshops, Gyankosha helps parents
// // // //               build stronger connections with their children, navigate modern
// // // //               parenting challenges, and create a supportive environment for
// // // //               holistic growth.{" "}
// // // //             </li>
// // // //             <li>
// // // //               These sessions not only enhance parenting skills but also
// // // //               strengthen the partnership between parents and schools, ensuring a
// // // //               unified approach to a child’s success.{" "}
// // // //             </li>
// // // //           </ul>

// // // //           {/* Content Boxes */}
// // // //           <div
// // // //             style={{
// // // //               display: "grid",
// // // //               gridTemplateColumns: "1fr 1fr",
// // // //               gap: "10px",
// // // //               marginTop: "20px",
// // // //             }}
// // // //           >
// // // //             <div
// // // //               style={{
// // // //                 background: "#f0f8ff",
// // // //                 padding: "10px",
// // // //                 //  borderRadius: "8px",
// // // //                 boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
// // // //               }}
// // // //             >
// // // //               <h3 style={{ fontSize: "1rem", margin: 0, color: "#0e003d" }}>
// // // //                 Structured “On The Campus” Sessions
// // // //               </h3>
// // // //             </div>

// // // //             <div
// // // //               style={{
// // // //                 background: "#f0f8ff",
// // // //                 padding: "10px",
// // // //                 // borderRadius: "8px",
// // // //                 boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
// // // //               }}
// // // //             >
// // // //               <h3 style={{ fontSize: "1rem", margin: 0, color: "#0e003d" }}>
// // // //                 Comprehensive Topics: Preprimary to Senior Classes
// // // //               </h3>
// // // //             </div>

// // // //             <div
// // // //               style={{
// // // //                 background: "#f0f8ff",
// // // //                 padding: "10px",
// // // //                 //  borderRadius: "8px",
// // // //                 boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
// // // //               }}
// // // //             >
// // // //               <h3 style={{ fontSize: "1rem", margin: 0, color: "#0e003d" }}>
// // // //                 Enhanced Parent School Collaboration: Regular Sessions{" "}
// // // //               </h3>
// // // //             </div>

// // // //             <div
// // // //               style={{
// // // //                 background: "#f0f8ff",
// // // //                 padding: "10px",
// // // //                 //  borderRadius: "8px",
// // // //                 boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
// // // //               }}
// // // //             >
// // // //               <h3 style={{ fontSize: "1rem", margin: 0, color: "#0e003d" }}>
// // // //                 Engaging and Interactive Approach: Real Life Scenarios and
// // // //                 Actionale take Aways{" "}
// // // //               </h3>
// // // //             </div>
// // // //           </div>

// // // //           {/* Buttons */}
// // // //           <a
// // // //             href="#"
// // // //             style={{
// // // //               color: "#0e003d",
// // // //               textDecoration: "underline",
// // // //               fontSize: "1rem",
// // // //               display: "block",
// // // //               marginBottom: "10px",
// // // //               marginTop: "20px",
// // // //             }}
// // // //             onClick={(e) => {
// // // //               e.preventDefault();
// // // //               setIsVideoOpen(true);
// // // //             }}
// // // //           >
// // // //             Watch Video
// // // //           </a>
// // // //           <button
// // // //             style={{
// // // //               backgroundColor: "#0e003d",
// // // //               color: "white",
// // // //               padding: "10px 20px",
// // // //               border: "none",
// // // //               borderRadius: "5px",
// // // //               cursor: "pointer",
// // // //               fontSize: "1rem",
// // // //             }}
// // // //             onClick={handleButtonClick}
// // // //           >
// // // //             Question? Contact Us
// // // //           </button>
// // // //         </div>
// // // //       </div>

// // // //       {/* Video Popup */}
// // // //       {isVideoOpen && (
// // // //         <div
// // // //           style={{
// // // //             position: "fixed",
// // // //             top: 0,
// // // //             left: 0,
// // // //             right: 0,
// // // //             bottom: 0,
// // // //             backgroundColor: "rgba(0, 0, 0, 0.7)",
// // // //             zIndex: 10,
// // // //             display: "flex",
// // // //             justifyContent: "center",
// // // //             alignItems: "center",
// // // //           }}
// // // //         >
// // // //           <div
// // // //             style={{
// // // //               position: "relative",
// // // //               backgroundColor: "white",
// // // //               padding: "20px",
// // // //               borderRadius: "10px",
// // // //               boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)",
// // // //             }}
// // // //           >
// // // //             <button
// // // //               style={{
// // // //                 position: "absolute",
// // // //                 top: "10px",
// // // //                 right: "10px",
// // // //                 fontSize: "30px",
// // // //                 color: "#333",
// // // //                 background: "none",
// // // //                 border: "none",
// // // //                 cursor: "pointer",
// // // //               }}
// // // //               onClick={() => setIsVideoOpen(false)}
// // // //             >
// // // //               &times;
// // // //             </button>
// // // //             <iframe
// // // //               width="560"
// // // //               height="315"
// // // //               src="https://www.youtube.com/embed/dQw4w9WgXcQ"
// // // //               title="YouTube video player"
// // // //               frameBorder="0"
// // // //               allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
// // // //               allowFullScreen
// // // //               style={{
// // // //                 maxWidth: "100%",
// // // //                 maxHeight: "100%",
// // // //                 borderRadius: "10px",
// // // //               }}
// // // //             ></iframe>
// // // //           </div>
// // // //         </div>
// // // //       )}
// // // //     </div>
// // // //   );
// // // // }

// // // // export default Parenting;
// // // import React, { useState, useEffect } from "react";
// // // import LearningAsParent from "./LearningAsParent";
// // // import ParentGallery from "./Parentgallery";
// // // import FooterInfo from "./FooterInfo";

// // // function Parenting() {
// // //   const [isVideoOpen, setIsVideoOpen] = useState(false);
// // //   const [screenSize, setScreenSize] = useState("desktop"); // Default to desktop

// // //   useEffect(() => {
// // //     const handleResize = () => {
// // //       const width = window.innerWidth;
// // //       if (width <= 600) {
// // //         setScreenSize("mobile");
// // //       } else if (width <= 1000) {
// // //         setScreenSize("tablet");
// // //       } else {
// // //         setScreenSize("desktop");
// // //       }
// // //     };

// // //     window.addEventListener("resize", handleResize);
// // //     handleResize(); // Initialize state on load

// // //     return () => {
// // //       window.removeEventListener("resize", handleResize);
// // //     };
// // //   }, []);

// // //   const handleButtonClick = () => {
// // //     const footer = document.getElementById("FooterInfo"); // Select the footer section
// // //     if (footer) {
// // //       footer.scrollIntoView({ behavior: "smooth" }); // Smoothly scroll to the footer
// // //     }
// // //   };

// // //   return screenSize === "tablet" || screenSize === "mobile" ? (
// // //     <div
// // //       style={{
// // //         background:
// // //           "linear-gradient(to left, rgb(10, 1, 50),rgb(9, 7, 114), rgb(7, 53, 190))",
// // //         padding: "40px",
// // //         paddingBottom: "0px",
// // //       }}
// // //     >
// // //       {/* Navbar */}
// // //       <nav
// // //         style={{
// // //           backgroundColor: "#0e003d",
// // //           color: "white",
// // //           padding: "15px 20px",
// // //           position: "sticky",
// // //           zIndex: 1,
// // //           textAlign: "center",
// // //           boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
// // //           width: "100vw",
// // //         }}
// // //       >
// // //         <h2
// // //           style={{
// // //             fontSize: "2rem",
// // //             fontWeight: "bold",
// // //             letterSpacing: "1px",
// // //             //  margin: 0,
// // //             textShadow: "2px 2px 4px rgba(0, 0, 0, 0.3)",
// // //             fontFamily: '"Libre Baskerville", serif',
// // //           }}
// // //         >
// // //           Parenting Excellence Program: In Partnership with Schools
// // //         </h2>
// // //       </nav>

// // //       {/* Main Content */}
// // //       <div
// // //         className="parenting-container"
// // //         style={{
// // //           display: "block", // Mobile or desktop layout
// // //           flexDirection: "column",
// // //           // overflowY: "auto",
// // //           height: screenSize === "mobile" ? "150vh" : "calc(auto - 50px)",
// // //           backgroundColor: "white",
// // //           padding: "2vh",
// // //           gap: "20px",
// // //           height:
// // //             screenSize === "mobile"
// // //               ? "auto"
// // //               : screenSize === "tablet"
// // //               ? "auto"
// // //               : "1100px",
// // //           maxHeight:
// // //             screenSize === "mobile"
// // //               ? "1700px"
// // //               : screenSize === "tablet"
// // //               ? "1500px"
// // //               : "1100px", // Adjust line height based on screen size"1200px",
// // //           lineHeight:
// // //             screenSize === "mobile"
// // //               ? "1"
// // //               : screenSize === "tablet"
// // //               ? "1.1"
// // //               : "1.5",
// // //           justifyContent: screenSize === "mobile" ? "center" : " ",
// // //           //  alignItems:  screenSize === "mobile" ? "center" : " ",
// // //         }}
// // //       >
// // //         {/* Left Side: Image */}
// // //         {/* Image displayed first */}
// // //         <div style={{ marginTop: "20px", textAlign: "center" }}>
// // //           <img
// // //             src="/Parenting.png" // Replace with the relevant image path
// // //             alt="Student Centric Classroom"
// // //             style={{
// // //               width: "100%",
// // //               height: "auto",
// // //               maxHeight: "500px",
// // //               marginBottom: "20px",
// // //             }}
// // //           />
// // //         </div>
// // //         {/* Right Side: Text and Content */}
// // //         {screenSize === "Tablet" ? (
// // //           <div
// // //             className="right-side"
// // //             style={{
// // //               flex: screenSize === "mobile" ? "1" : "1 1 15%",
// // //               padding: screenSize === "mobile" ? "5vh" : "20px",
// // //               textAlign: "left",
// // //               fontFamily: '"Libre Baskerville", serif',
// // //               lineHeight:
// // //                 screenSize === "mobile"
// // //                   ? "1"
// // //                   : screenSize === "tablet"
// // //                   ? "1.1"
// // //                   : "1.5",
// // //               fontSize: screenSize === "mobile" ? "1.5rem" : "1.2rem",
// // //               height: "auto",
// // //               marginBottom: "2vh",
// // //               // marginInline: "-5px",
// // //               textAlign: "justify",
// // //             }}
// // //           >
// // //             <strong
// // //               style={{
// // //                 fontSize: "1.5rem",
// // //                 marginBottom: "2px",
// // //                 display: "flex",
// // //                 color: "#0e003d",
// // //               }}
// // //             >
// // //               Gyankosha’s Parenting Excellence Program:
// // //             </strong>
// // //             <h2
// // //               style={{
// // //                 fontSize: screenSize === "mobile" ? "1" : "1.3rem",
// // //                 fontWeight: "bold",
// // //                 marginBottom: "10px",
// // //                 color: "#333",
// // //               }}
// // //             >
// // //               Ensuring schools and parents work as partners in shaping students’
// // //               holistic development
// // //             </h2>
// // //             <ul
// // //               style={{
// // //                 fontSize: screenSize === "mobile" ? "0.8" : "1.3rem",
// // //                 paddingLeft: "20px",
// // //                 marginBottom: "10px",
// // //               }}
// // //             >
// // //               <li>
// // //                 Thoughtfully designed to empower parents with practical tools
// // //                 and strategies to support their child’s academic, emotional, and
// // //                 social development.
// // //               </li>
// // //               <li>
// // //                 Delivered in collaboration with schools, these sessions cover
// // //                 critical topics such as managing academic stress, fostering
// // //                 emotional intelligence, positive discipline, and nurturing
// // //                 independence.
// // //               </li>
// // //               <li>
// // //                 Through engaging, interactive workshops, Gyankosha helps parents
// // //                 build stronger connections with their children, navigate modern
// // //                 parenting challenges, and create a supportive environment for
// // //                 holistic growth.
// // //               </li>
// // //               <li>
// // //                 These sessions not only enhance parenting skills but also
// // //                 strengthen the partnership between parents and schools, ensuring
// // //                 a unified approach to a child’s success.
// // //               </li>
// // //             </ul>

// // //             {/* Buttons */}
// // //             <a
// // //               href="#"
// // //               style={{
// // //                 color: "#0e003d",
// // //                 textDecoration: "underline",
// // //                 fontSize: "1rem",
// // //                 display: "block",
// // //                 marginBottom: "10px",
// // //                 marginTop: "5px",
// // //               }}
// // //               onClick={(e) => {
// // //                 e.preventDefault();
// // //                 setIsVideoOpen(true);
// // //               }}
// // //             >
// // //               Watch Video
// // //             </a>
// // //             <button
// // //               style={{
// // //                 backgroundColor: "#0e003d",
// // //                 color: "white",
// // //                 padding: "10px 20px",
// // //                 border: "none",
// // //                 borderRadius: "5px",
// // //                 cursor: "pointer",
// // //                 fontSize: "1rem",
// // //                 marginTop: "10px", // Ensure some spacing between the video link and button
// // //                 display: "inline-block",
// // //               }}
// // //               onClick={handleButtonClick}
// // //             >
// // //               Question? Contact Us
// // //             </button>
// // //           </div>
// // //         ) : (
// // //           <div
// // //             style={{
// // //               background:
// // //                 "linear-gradient(to left, rgb(10, 1, 50),rgb(9, 7, 114), rgb(7, 53, 190))",
// // //               padding: "40px",
// // //               paddingBottom: "0px",
// // //             }}
// // //           >
// // //             <strong
// // //               style={{
// // //                 fontSize: "1.3rem",
// // //                 marginBottom: "5px",
// // //                 display: "flex",
// // //                 color: "#0e003d",
// // //               }}
// // //             >
// // //               Gyankosha’s Parenting Excellence Program:
// // //             </strong>
// // //             <h2
// // //               style={{
// // //                 fontSize: "1.2rem",
// // //                 fontWeight: "bold",
// // //                 marginBottom: "10px",
// // //                 color: "#333",
// // //               }}
// // //             >
// // //               Ensuring schools and parents work as partners in shaping students’
// // //               holistic development
// // //             </h2>
// // //             <ul
// // //               style={{
// // //                 fontSize: "1.1rem",
// // //                 paddingLeft: "20px",
// // //                 marginBottom: "10px",
// // //               }}
// // //             >
// // //               <li>
// // //                 Thoughtfully designed to empower parents with practical tools
// // //                 and strategies to support their child’s academic, emotional, and
// // //                 social development.
// // //               </li>
// // //               <li>
// // //                 Delivered in collaboration with schools, these sessions cover
// // //                 critical topics such as managing academic stress, fostering
// // //                 emotional intelligence, positive discipline, and nurturing
// // //                 independence.
// // //               </li>
// // //               <li>
// // //                 Through engaging, interactive workshops, Gyankosha helps parents
// // //                 build stronger connections with their children, navigate modern
// // //                 parenting challenges, and create a supportive environment for
// // //                 holistic growth.
// // //               </li>
// // //               <li>
// // //                 These sessions not only enhance parenting skills but also
// // //                 strengthen the partnership between parents and schools, ensuring
// // //                 a unified approach to a child’s success.
// // //               </li>
// // //             </ul>
// // //             <a
// // //               href="#"
// // //               style={{
// // //                 color: "#0e003d",
// // //                 textDecoration: "underline",
// // //                 fontSize: "1rem",
// // //                 display: "block",
// // //                 marginBottom: "10px",
// // //                 marginTop: "5px",
// // //               }}
// // //               onClick={(e) => {
// // //                 e.preventDefault();
// // //                 setIsVideoOpen(true);
// // //               }}
// // //             >
// // //               Watch Video
// // //             </a>
// // //             <button
// // //               style={{
// // //                 backgroundColor: "#0e003d",
// // //                 color: "white",
// // //                 padding: "10px 20px",
// // //                 border: "none",
// // //                 borderRadius: "5px",
// // //                 cursor: "pointer",
// // //                 fontSize: "1rem",
// // //                 marginTop: "10px", // Ensure some spacing between the video link and button
// // //                 display: "inline-block",
// // //               }}
// // //               onClick={handleButtonClick}
// // //             >
// // //               Question? Contact Us
// // //             </button>
// // //           </div>
// // //         )}
// // //       </div>

// // //       {/* Video Popup */}
// // //       {isVideoOpen && (
// // //         <div
// // //           style={{
// // //             position: "fixed",
// // //             top: 0,
// // //             left: 0,
// // //             right: 0,
// // //             bottom: 0,
// // //             backgroundColor: "rgba(0, 0, 0, 0.7)",
// // //             zIndex: 10,
// // //             display: "flex",
// // //             justifyContent: "center",
// // //             alignItems: "center",
// // //           }}
// // //         >
// // //           <div
// // //             style={{
// // //               position: "relative",
// // //               backgroundColor: "white",
// // //               padding: "20px",
// // //               borderRadius: "10px",
// // //               boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)",
// // //             }}
// // //           >
// // //             <button
// // //               style={{
// // //                 position: "absolute",
// // //                 top: "10px",
// // //                 right: "10px",
// // //                 fontSize: "30px",
// // //                 color: "#333",
// // //                 background: "transparent",
// // //                 border: "none",
// // //                 cursor: "pointer",
// // //               }}
// // //               onClick={() => setIsVideoOpen(false)}
// // //             >
// // //               &times;
// // //             </button>
// // //             <iframe
// // //               width="560"
// // //               height="315"
// // //               src="https://www.youtube-nocookie.com/embed/5KbUbAPeh1k?si=wCz3rAwFYlDyc9tS?rel=0"
// // //               title="YouTube video player"
// // //               style={{
// // //                 border: "none",
// // //                 borderRadius: "10px",
// // //               }}
// // //               frameborder="0"
// // //               allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
// // //               referrerpolicy="strict-origin-when-cross-origin"
// // //               allowfullscreen
// // //             ></iframe>
// // //           </div>
// // //         </div>
// // //       )}
// // //     </div>
// // //   ) : (
// // //     <div
// // //       style={{
// // //         position: "relative",
// // //         height: "auto",
// // //         maxHeight: "1100px",
// // //         overflow: "hidden",
// // //         margin: "50px 0px",
// // //       }}
// // //     >
// // //       {/* Navbar */}
// // //       <nav
// // //         style={{
// // //           backgroundColor: "#0e003d",
// // //           color: "white",
// // //           padding: "15px 20px",
// // //           position: "sticky",
// // //           top: 0,
// // //           left: 0,
// // //           right: 0,
// // //           zIndex: 1,
// // //           textAlign: "center",
// // //           boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
// // //           width: "100vw",
// // //           // marginInline: "-5px",
// // //           marginBottom: "60px",
// // //         }}
// // //       >
// // //         <h2
// // //           style={{
// // //             fontSize: "2.5rem",
// // //             fontWeight: "bold",
// // //             letterSpacing: "1px",
// // //             margin: 0,
// // //             textShadow: "2px 2px 4px rgba(0, 0, 0, 0.3)",
// // //             fontFamily: '"Libre Baskerville", serif',
// // //           }}
// // //         >
// // //           Parenting Excellence Program: In Partnership with Schools
// // //         </h2>
// // //       </nav>

// // //       {/* Main Content */}
// // //       <div
// // //         className="student-learning-container"
// // //         style={{
// // //           display: "flex",
// // //           flexDirection: "row",
// // //           //  overflowY: "auto",
// // //           // height: "calc(auto - 60px)",
// // //           height: "calc(110vh-5px)",
// // //           backgroundColor: "white",
// // //           padding: "5px",
// // //           gap: "20px",
// // //         }}
// // //       >
// // //         {/* Left Side: Image */}
// // //         <div
// // //           className="left-side"
// // //           style={{
// // //             flex: 1,
// // //             display: "flex",
// // //             justifyContent: "center",
// // //             //alignItems: "center",
// // //             borderRadius: "10px",
// // //             height: "calc(5px-110vh)",
// // //             width: "50vw",
// // //           }}
// // //         >
// // //           <img
// // //             src="/Parenting.png"
// // //             alt="Parenting GIF"
// // //             style={{
// // //               width: "50vw",
// // //               height: "auto",
// // //               maxHeight: "800px",
// // //               alignContent: "center",
// // //               borderRadius: "10px", // Add rounded corners
// // //               boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)", // Add shadow for depth
// // //             }}
// // //           />
// // //         </div>

// // //         {/* Right Side: Text and Content */}
// // //         <div
// // //           className="right-side"
// // //           style={{
// // //             flex: 1,
// // //             padding: "30px",
// // //             textAlign: "left",
// // //             fontFamily: '"Libre Baskerville", serif',
// // //             fontSize: "1.2rem",
// // //           }}
// // //         >
// // //           <strong
// // //             style={{
// // //               fontSize: "2rem", // Reduced font size
// // //               marginBottom: "5px", // Reduced margin
// // //               display: "block",
// // //               color: "#0e003d",
// // //               textAlign: "Left",
// // //               fontFamily: '"Libre Baskerville", serif',
// // //             }}
// // //           >
// // //             Gyankosha’s Parenting Excellence Program:
// // //           </strong>
// // //           <h2
// // //             style={{
// // //               fontSize: "1.5rem", // Reduced font size
// // //               fontWeight: "bold",
// // //               marginBottom: "20px", // Reduced margin
// // //               color: "#333",
// // //             }}
// // //           >
// // //             Ensuring schools and parents work as partners in shaping students’
// // //             holistic development
// // //           </h2>
// // //           <ul
// // //             style={{
// // //               fontSize: "1.2rem",
// // //               paddingLeft: "20px",
// // //               marginBottom: "10px",
// // //             }}
// // //           >
// // //             <li>
// // //               Thoughtfully designed to empower parents with practical tools and
// // //               strategies to support their child’s academic, emotional, and
// // //               social development.{" "}
// // //             </li>
// // //             <li>
// // //               Delivered in collaboration with schools, these sessions cover
// // //               critical topics such as managing academic stress, fostering
// // //               emotional intelligence, positive discipline, and nurturing
// // //               independence.
// // //             </li>
// // //             <li>
// // //               Through engaging, interactive workshops, Gyankosha helps parents
// // //               build stronger connections with their children, navigate modern
// // //               parenting challenges, and create a supportive environment for
// // //               holistic growth.{" "}
// // //             </li>
// // //             <li>
// // //               These sessions not only enhance parenting skills but also
// // //               strengthen the partnership between parents and schools, ensuring a
// // //               unified approach to a child’s success.{" "}
// // //             </li>
// // //           </ul>

// // //           {/* Content Boxes */}
// // //           <div
// // //             style={{
// // //               display: "grid",
// // //               gridTemplateColumns: "1fr 1fr",
// // //               gap: "10px",
// // //               marginTop: "20px",
// // //               textAlign: "center",
// // //             }}
// // //           >
// // //             <div
// // //               style={{
// // //                 background: "#f0f8ff",
// // //                 padding: "10px",
// // //                 //  borderRadius: "8px",
// // //                 boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
// // //               }}
// // //             >
// // //               <h3 style={{ fontSize: "1.2rem", margin: 0, color: "#0e003d" }}>
// // //                 Structured “On The Campus” Sessions
// // //               </h3>
// // //             </div>

// // //             <div
// // //               style={{
// // //                 background: "#f0f8ff",
// // //                 padding: "10px",
// // //                 // borderRadius: "8px",
// // //                 boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
// // //               }}
// // //             >
// // //               <h3 style={{ fontSize: "1.2rem", margin: 0, color: "#0e003d" }}>
// // //                 Comprehensive Topics: Preprimary to Senior Classes
// // //               </h3>
// // //             </div>

// // //             <div
// // //               style={{
// // //                 background: "#f0f8ff",
// // //                 padding: "10px",
// // //                 //  borderRadius: "8px",
// // //                 boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
// // //               }}
// // //             >
// // //               <h3 style={{ fontSize: "1.2rem", margin: 0, color: "#0e003d" }}>
// // //                 Enhanced Parent School Collaboration: Regular Sessions{" "}
// // //               </h3>
// // //             </div>

// // //             <div
// // //               style={{
// // //                 background: "#f0f8ff",
// // //                 padding: "10px",
// // //                 //  borderRadius: "8px",
// // //                 boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
// // //               }}
// // //             >
// // //               <h3 style={{ fontSize: "1.2rem", margin: 0, color: "#0e003d" }}>
// // //                 Engaging and Interactive Approach: Real Life Scenarios and
// // //                 Actionale take Aways{" "}
// // //               </h3>
// // //             </div>
// // //           </div>

// // //           {/* Buttons */}
// // //           <a
// // //             href="#"
// // //             style={{
// // //               color: "#0e003d",
// // //               textDecoration: "underline",
// // //               fontSize: "1.5rem",
// // //               display: "block",
// // //               marginBottom: "10px",
// // //               marginTop: "20px",
// // //             }}
// // //             onClick={(e) => {
// // //               e.preventDefault();
// // //               setIsVideoOpen(true);
// // //             }}
// // //           >
// // //             Watch Video
// // //           </a>
// // //           <button
// // //             style={{
// // //               backgroundColor: "#0e003d",
// // //               color: "white",
// // //               padding: "10px 20px",
// // //               border: "none",
// // //               borderRadius: "5px",
// // //               cursor: "pointer",
// // //               fontSize: "1.5rem",
// // //             }}
// // //             onClick={handleButtonClick}
// // //           >
// // //             Question? Contact Us
// // //           </button>

// // //           <div style={{ width: "100vw" }}>
// // //             <LearningAsParent />
// // //           </div>
// // //           <div>
// // //             <ParentGallery />
// // //           </div>
// // //           <div>
// // //             <FooterInfo />
// // //           </div>
// // //         </div>
// // //       </div>

// // //       {/* Video Popup */}
// // //       {isVideoOpen && (
// // //         <div
// // //           style={{
// // //             position: "fixed",
// // //             top: 0,
// // //             left: 0,
// // //             right: 0,
// // //             bottom: 0,
// // //             backgroundColor: "rgba(0, 0, 0, 0.7)",
// // //             zIndex: 10,
// // //             display: "flex",
// // //             justifyContent: "center",
// // //             alignItems: "center",
// // //           }}
// // //         >
// // //           <div
// // //             style={{
// // //               position: "relative",
// // //               backgroundColor: "white",
// // //               padding: "20px",
// // //               borderRadius: "10px",
// // //               boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)",
// // //             }}
// // //           >
// // //             <button
// // //               style={{
// // //                 position: "absolute",
// // //                 top: "10px",
// // //                 right: "10px",
// // //                 fontSize: "30px",
// // //                 color: "#333",
// // //                 background: "none",
// // //                 border: "none",
// // //                 cursor: "pointer",
// // //               }}
// // //               onClick={() => setIsVideoOpen(false)}
// // //             >
// // //               &times;
// // //             </button>
// // //             <iframe
// // //               width="560"
// // //               height="315"
// // //               src="https://www.youtube-nocookie.com/embed/5KbUbAPeh1k?si=wCz3rAwFYlDyc9tS?rel=0"
// // //               title="Parentingplayer"
// // //               frameborder="0"
// // //               allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
// // //               referrerpolicy="strict-origin-when-cross-origin"
// // //               allowfullscreen
// // //               style={{
// // //                 maxWidth: "100%",
// // //                 maxHeight: "100%",
// // //                 borderRadius: "10px",
// // //               }}
// // //             ></iframe>
// // //           </div>
// // //         </div>
// // //       )}
// // //     </div>
// // //   );
// // // }
// // // export default Parenting;
// // import React, { useEffect, useState, useRef } from "react";
// // import LearningAsParent from "./LearningAsParent";
// // import ParentGallery from "./Parentgallery";
// // import FooterInfo from "./FooterInfo";
// // const styles = {
// //   navbar: {
// //     backgroundColor: "#0e003d",
// //     color: "white",
// //     textAlign: "center",
// //     padding: "30px",
// //     position: "sticky",
// //     top: 0,
// //     zIndex: 1,
// //     boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
// //   },
// //   navbarTitle: {
// //     fontSize: "1.8rem",
// //     fontWeight: "bold",
// //     margin: 0,
// //     textShadow: "2px 2px 4px rgba(0, 0, 0, 0.3)",
// //     fontFamily: '"Libre Baskerville", serif',
// //   },
// //   container: {
// //     display: "flex",
// //     flexDirection: "column", // Default to column for smaller screens
// //     fontFamily: '"Libre Baskerville", serif',
// //     fontWeight: "bolder",
// //     alignItems: "center",
// //     overflowX: "hidden",

// //     // overflowY: "auto",
// //     backgroundColor: "#ffffff",
// //     padding: "20px",
// //     marginBottom: "5vh",
// //   },
// //   mainContent: {
// //     display: "flex",
// //     flexDirection: "column",
// //     gap: "20px",
// //     padding: "20px",
// //   },
// //   imageSection: {
// //     display: "flex",
// //     // width: "auto", // Full width for responsive adjustment
// //     width: "50vw",
// //     // height: "40vh",
// //     flexDirection: "row",
// //     flexWrap: "wrap",
// //     justifyContent: "right",
// //     gap: "20px",
// //     paddingTop: "5vh",
// //   },
// //   imageContainer: {
// //     display: "flex",
// //     flexDirection: "column",
// //     width: "20vw", // Full width for responsive adjustment
// //     height: "auto",
// //     gap: "10px",
// //     alignItems: "center",
// //     width: "15vw",
// //     height: "20vw", // Maintain aspect ratio
// //     boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
// //   },
// //   image: {
// //     width: "100%", // Adjust to 90% for smaller screens
// //     height: "35vw", // Maintain aspect ratio
// //     objectFit: "cover",

// //     boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
// //     //  borderRadius: "8px",
// //   },
// //   textSection: {
// //     flex: 1,
// //     textAlign: "justify",
// //     height: "auto",
// //     padding: "5vw",
// //     width: "100%",
// //     fontSize: "1.5rem",

// //     color: "black",
// //   },
// //   programTitle: {
// //     fontSize: "1.5rem",
// //     color: "#0e003d",
// //     display: "block",
// //     marginBottom: "10px",
// //   },

// //   header: {
// //     paddingLeft: "10px",
// //     textAlign: "left",
// //     marginBottom: "20px",
// //     width: "50vw",
// //   },
// //   title: {
// //     fontSize: "24px",
// //     fontWeight: "bold",
// //     color: "black",
// //   },
// //   subtitle: {
// //     fontSize: "32px",
// //     fontWeight: "bolder",
// //     color: "#0e003d",
// //   },
// //   contentBoxes: {
// //     padding: "5vh",
// //     height: "100vh",
// //     display: "flex",
// //     flexDirection: "column",
// //     alignItems: "center",
// //     gap: "20px",
// //     marginTop: "30px",
// //     overflow: "hidden", // Hides scrollbar
// //     background: "white",
// //     width: "100vw",
// //     // maxwidth: "100vw",
// //   },
// //   contentBox: {
// //     width: "50%",
// //     height: "25%",
// //     background:
// //       "linear-gradient(to left, rgb(251, 252, 252), #e0f7fa, rgb(128, 206, 234))",
// //     color: "#0e003d",
// //     alignItems: "center",
// //     justifyContent: "center",
// //     textAlign: "center",
// //     display: "flex", // Added for centering
// //     flexDirection: "column", // Ensures vertical alignment for multiple lines
// //     padding: "20px",
// //     borderRadius: "10px",
// //     boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
// //     opacity: 0,
// //     transform: "translateY(50px)", // Initial state for scrolling
// //     transition: "all 0.8s ease-in-out",
// //   },

// //   contentBoxVisible: {
// //     opacity: 2,
// //     transform: "translateY(0)", // Final state after animation
// //   },
// //   contentBoxLeft: {
// //     alignSelf: "flex-start",
// //     transform: "translateX(-100px)", // Slide in from the left
// //   },
// //   contentBoxRight: {
// //     alignSelf: "flex-end",
// //     transform: "translateX(100px)", // Slide in from the right
// //   },
// //   contentBoxText: {
// //     fontWeight: "600",
// //     fontSize: "1.8rem",
// //     lineHeight: "1.5",
// //     justifyContent: "center",
// //     textAlign: "center",
// //     zIndex: 2,
// //     position: "relative",
// //   },
// //   overlay: {
// //     position: "absolute",
// //     top: 0,
// //     left: 0,
// //     width: "0%",
// //     height: "100%",
// //     backgroundColor: "rgba(0, 0, 0, 0.4)",
// //     borderRadius: "10px",
// //     zIndex: 1,
// //   },
// //   videoModal: {
// //     position: "fixed",
// //     top: 0,
// //     left: 0,
// //     right: 0,
// //     bottom: 0,
// //     backgroundColor: "rgba(0, 0, 0, 0.7)",
// //     display: "flex",
// //     justifyContent: "center",
// //     alignItems: "center",
// //     zIndex: 10,
// //   },
// //   videoContent: {
// //     backgroundColor: "white",
// //     padding: "20px",
// //     borderRadius: "10px",
// //     boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)",
// //     position: "relative",
// //   },
// //   closeButton: {
// //     position: "absolute",
// //     top: "10px",
// //     right: "10px",
// //     fontSize: "30px",
// //     background: "none",
// //     border: "none",
// //     cursor: "pointer",
// //   },
// //   actionButton: {
// //     backgroundColor: "#0e003d",
// //     color: "white",
// //     padding: "10px 20px",
// //     border: "none",
// //     marginTop: "5vh",
// //     fontSize: "1.5rem",
// //     borderRadius: "5px",
// //     cursor: "pointer",
// //   },
// //   galleryContainer: {
// //     display: "flex",
// //     overflowX: "hidden",
// //     gap: "20px",
// //     padding: "20px",
// //     // border: "1px solid #ddd",
// //     // borderRadius: "8px",
// //     width: "100%",
// //   },
// //   galleryItem: {
// //     minWidth: "300px",
// //     height: "200px",
// //     borderRadius: "8px",
// //     overflow: "hidden",
// //     display: "flex",
// //     alignItems: "center",
// //     justifyContent: "center",
// //   },
// // };

// // // function Navbar() {
// // //   return (
// // //     <nav style={styles.navbar}>
// // //       <h2 style={styles.navbarTitle}>
// // //         Parenting Excellence Program: In Partnership with Schools
// // //       </h2>
// // //     </nav>
// // //   );
// // // }

// // function VideoModal({ isOpen, onClose }) {
// //   if (!isOpen) return null;

// //   return (
// //     <div style={styles.videoModal}>
// //       <div style={styles.videoContent}>
// //         <button style={styles.closeButton} onClick={onClose}>
// //           &times;
// //         </button>
// //         <iframe
// //           width="560"
// //           height="315"
// //           src="https://www.youtube-nocookie.com/embed/5KbUbAPeh1k?rel=0"
// //           title="YouTube video player"
// //           frameBorder="0"
// //           allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
// //           allowFullScreen
// //         ></iframe>
// //       </div>
// //     </div>
// //   );
// // }

// // function ContentBoxes() {
// //   const boxes = [
// //     "Structured “On The Campus” Sessions",
// //     "Comprehensive Topics: Preprimary to Senior Classes",
// //     "Enhanced Parent School Collaboration: Regular Sessions",
// //     "Engaging and Interactive Approach: Real Life Scenarios and Actionable Takeaways",
// //   ];

// //   const [visibleBoxes, setVisibleBoxes] = useState([]);
// //   const boxRefs = useRef([]);

// //   useEffect(() => {
// //     const observer = new IntersectionObserver(
// //       (entries) => {
// //         entries.forEach((entry) => {
// //           if (entry.isIntersecting) {
// //             setVisibleBoxes((prev) => {
// //               const index = Number(entry.target.dataset.index);
// //               return prev.includes(index) ? prev : [...prev, index];
// //             });
// //           }
// //         });
// //       },
// //       { threshold: 0.2 }
// //     );

// //     boxRefs.current.forEach((box) => observer.observe(box));

// //     return () => observer.disconnect();
// //   }, []);

// //   return (
// //     <div style={styles.contentBoxes}>
// //       {boxes.map((text, index) => (
// //         <div
// //           key={index}
// //           data-index={index}
// //           ref={(el) => (boxRefs.current[index] = el)}
// //           style={{
// //             ...styles.contentBox,
// //             ...(visibleBoxes.includes(index) && styles.contentBoxVisible),
// //             ...(index % 2 === 0
// //               ? {
// //                   ...styles.contentBoxLeft,
// //                   ...(visibleBoxes.includes(index) && {
// //                     transform: "translateX(0)",
// //                   }),
// //                 }
// //               : {
// //                   ...styles.contentBoxRight,
// //                   ...(visibleBoxes.includes(index) && {
// //                     transform: "translateX(0)",
// //                   }),
// //                 }),
// //           }}
// //         >
// //           <div style={styles.overlay}></div>
// //           <h3 style={styles.contentBoxText}>{text}</h3>
// //         </div>
// //       ))}
// //     </div>
// //   );
// // }

// // function Parenting() {
// //   const [isVideoOpen, setIsVideoOpen] = useState(false);

// //   const handleButtonClick = () => {
// //     document
// //       .getElementById("FooterInfo")
// //       ?.scrollIntoView({ behavior: "smooth" });
// //   };

// //   return (
// //     <div
// //       style={{
// //         background: `
// // linear-gradient(to left, rgb(10, 1, 50),rgb(9, 7, 114), rgb(7, 53, 190)),
// //     linear-gradient(to top, #ffffff,#ffffff,rgb(251, 252, 252),rgba(251, 252, 252, 0.61), rgba(255, 255, 255, 0.21), rgba(255, 255, 255, 0))
// //   `,
// //         backgroundBlendMode: "overlay",

// //         padding: "40px",
// //         paddingBottom: "0px",
// //         overflowX: "hidden",
// //       }}
// //     >
// //       <div style={styles.container}>
// //         <div style={styles.mainContent}>
// //           <div
// //             style={{
// //               ...styles.container,
// //               flexDirection: "row",
// //               height: "auto",
// //             }}
// //           >
// //             <div style={styles.imageSection}>
// //               <div style={{ ...styles.imageContainer, padding: "0px" }}>
// //                 <img src="./p1.jpg" alt="Classroom 1" style={styles.image} />
// //                 <img src="./p1.jpg" alt="Classroom 2" style={styles.image} />
// //               </div>
// //               <div style={styles.imageContainer}>
// //                 <img
// //                   src="./p1.jpg"
// //                   alt="Student Group Activity"
// //                   style={{ ...styles.image, height: "82vh" }}
// //                 />
// //               </div>
// //             </div>
// //             <div style={styles.header}>
// //               <h1 style={styles.title}>GYANKOSHA presents</h1>
// //               <h2 style={styles.subtitle}>
// //                 PARENTING EXCELLENCE PROGRAM:
// //                 <br /> In Partnership with Schools
// //               </h2>
// //             </div>
// //           </div>
// //         </div>
// //         <div style={styles.textSection}>
// //           <strong style={styles.programTitle}>
// //             Gyankosha’s Parenting Excellence Program:
// //           </strong>
// //           <h3
// //             style={{
// //               fontWeight: "bold",
// //               marginBottom: "0px",
// //               fontSize: "2.0rem",
// //             }}
// //           >
// //             Ensuring schools and parents work as partners in shaping students’
// //             holistic development
// //           </h3>
// //           <ul
// //             style={{ fontSize: "1.5rem", padding: "5vw", textAlign: "justify" }}
// //           >
// //             <li>
// //               Thoughtfully designed to empower parents with practical tools and
// //               strategies to support their child’s academic, emotional, and
// //               social development.
// //             </li>
// //             <li>
// //               Delivered in collaboration with schools, these sessions cover
// //               critical topics such as managing academic stress, fostering
// //               emotional intelligence, positive discipline, and nurturing
// //               independence.
// //             </li>
// //             <li>
// //               Through engaging, interactive workshops, Gyankosha helps parents
// //               build stronger connections with their children, navigate modern
// //               parenting challenges, and create a supportive environment for
// //               holistic growth.
// //             </li>
// //             <li>
// //               These sessions not only enhance parenting skills but also
// //               strengthen the partnership between parents and schools, ensuring a
// //               unified approach to a child’s success.
// //             </li>
// //           </ul>
// //           <a
// //             href="#"
// //             onClick={(e) => {
// //               e.preventDefault();
// //               setIsVideoOpen(true);
// //             }}
// //             style={{
// //               color: "#0e003d",
// //               textDecoration: "underline",
// //               padding: "5vh",
// //               fontSize: "1.8rem",
// //             }}
// //           >
// //             Watch Video
// //           </a>
// //           <br />
// //           <button style={styles.actionButton} onClick={handleButtonClick}>
// //             Question? Contact Us
// //           </button>
// //           <div style={{ ...styles.container }}>
// //             <ContentBoxes />
// //           </div>
// //         </div>
// //         <div style={{ ...styles.galleryContainer }}>
// //           <ParentGallery />
// //         </div>

// //         <div style={{ width: "100vw" }}>
// //           <LearningAsParent />
// //         </div>
// //         <FooterInfo id="FooterInfo" />
// //       </div>

// //       <VideoModal isOpen={isVideoOpen} onClose={() => setIsVideoOpen(false)} />
// //     </div>
// //   );
// // }

// // export default Parenting;
// import React, { useState, useRef, useEffect } from "react";
// import LearningAsParent from "./LearningAsParent";
// import ParentGallery from "./Parentgallery";
// import FooterInfo from "./FooterInfo";

// const styles = {
//   navbar: {
//     backgroundColor: "#0e003d",
//     color: "white",
//     textAlign: "center",
//     padding: "30px",
//     position: "sticky",
//     top: 0,
//     zIndex: 1,
//     boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
//   },
//   navbarTitle: {
//     fontSize: "1.8rem",
//     fontWeight: "bold",
//     margin: 0,
//     textShadow: "2px 2px 4px rgba(0, 0, 0, 0.3)",
//     fontFamily: '"Libre Baskerville", serif',
//   },
//   container: {
//     display: "flex",
//     flexDirection: "column",
//     fontFamily: '"Libre Baskerville", serif',
//     fontWeight: "bolder",
//     alignItems: "center",
//     overflowX: "hidden",
//     backgroundColor: "#ffffff",
//     padding: "20px",
//     marginBottom: "5vh",
//   },
//   mainContent: {
//     display: "flex",
//     flexDirection: "column",
//     gap: "20px",
//     padding: "20px",
//   },
//   imageSection: {
//     display: "flex",
//     width: "50vw",
//     flexDirection: "row",
//     flexWrap: "wrap",
//     justifyContent: "right",
//     gap: "20px",
//     paddingTop: "5vh",
//   },
//   imageContainer: {
//     display: "flex",
//     flexDirection: "column",
//     width: "20vw",
//     height: "auto",
//     gap: "10px",
//     alignItems: "center",
//     width: "15vw",
//     height: "20vw",
//     boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
//   },
//   image: {
//     width: "100%",
//     height: "35vw",
//     objectFit: "cover",
//     boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
//   },
//   textSection: {
//     flex: 1,
//     textAlign: "justify",
//     height: "auto",
//     padding: "5vw",
//     width: "100%",
//     fontSize: "1.5rem",
//     color: "black",
//   },
//   programTitle: {
//     fontSize: "1.5rem",
//     color: "#0e003d",
//     display: "block",
//     marginBottom: "10px",
//   },
//   header: {
//     paddingLeft: "10px",
//     textAlign: "left",
//     marginBottom: "20px",
//     width: "50vw",
//   },
//   title: {
//     fontSize: "24px",
//     fontWeight: "bold",
//     color: "black",
//   },
//   subtitle: {
//     fontSize: "32px",
//     fontWeight: "bolder",
//     color: "#0e003d",
//   },
//   contentBoxes: {
//     padding: "5vh",
//     height: "100vh",
//     display: "flex",
//     flexDirection: "column",
//     alignItems: "center",
//     gap: "20px",
//     marginTop: "30px",
//     overflow: "hidden",
//     background: "white",
//     width: "100vw",
//   },
//   contentBox: {
//     width: "50%",
//     height: "25%",
//     background:
//       "linear-gradient(to left, rgb(251, 252, 252), #e0f7fa, rgb(128, 206, 234))",
//     color: "#0e003d",
//     alignItems: "center",
//     justifyContent: "center",
//     textAlign: "center",
//     display: "flex",
//     flexDirection: "column",
//     padding: "20px",
//     borderRadius: "10px",
//     boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
//     opacity: 0,
//     transform: "translateY(50px)",
//     transition: "all 0.8s ease-in-out",
//   },
//   contentBoxVisible: {
//     opacity: 2,
//     transform: "translateY(0)",
//   },
//   contentBoxLeft: {
//     alignSelf: "flex-start",
//     transform: "translateX(-100px)",
//   },
//   contentBoxRight: {
//     alignSelf: "flex-end",
//     transform: "translateX(100px)",
//   },
//   contentBoxText: {
//     fontWeight: "600",
//     fontSize: "1.8rem",
//     lineHeight: "1.5",
//     justifyContent: "center",
//     textAlign: "center",
//     zIndex: 2,
//     position: "relative",
//   },
//   overlay: {
//     position: "absolute",
//     top: 0,
//     left: 0,
//     width: "0%",
//     height: "100%",
//     backgroundColor: "rgba(0, 0, 0, 0.4)",
//     borderRadius: "10px",
//     zIndex: 1,
//   },
//   videoModal: {
//     position: "fixed",
//     top: 0,
//     left: 0,
//     right: 0,
//     bottom: 0,
//     backgroundColor: "rgba(0, 0, 0, 0.7)",
//     display: "flex",
//     justifyContent: "center",
//     alignItems: "center",
//     zIndex: 10,
//   },
//   videoContent: {
//     backgroundColor: "white",
//     padding: "20px",
//     borderRadius: "10px",
//     boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)",
//     position: "relative",
//   },
//   closeButton: {
//     position: "absolute",
//     top: "10px",
//     right: "10px",
//     fontSize: "30px",
//     background: "none",
//     border: "none",
//     cursor: "pointer",
//   },
//   actionButton: {
//     backgroundColor: "#0e003d",
//     color: "white",
//     padding: "10px 20px",
//     border: "none",
//     marginTop: "5vh",
//     fontSize: "1.5rem",
//     borderRadius: "5px",
//     cursor: "pointer",
//   },
//   galleryContainer: {
//     display: "flex",
//     overflowX: "hidden",
//     gap: "20px",
//     padding: "20px",
//     width: "100%",
//   },
//   galleryItem: {
//     minWidth: "300px",
//     height: "200px",
//     borderRadius: "8px",
//     overflow: "hidden",
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "center",
//   },
//   "@media (max-width: 1200px)": {
//     container: {
//       padding: "20px",
//     },
//     imageSection: {
//       width: "70vw",
//     },
//     imageContainer: {
//       width: "40vw",
//     },
//     textSection: {
//       fontSize: "1.2rem",
//       padding: "4vw",
//     },
//     contentBoxes: {
//       padding: "4vh",
//     },
//     contentBox: {
//       width: "60%",
//     },
//   },
//   "@media (max-width: 900px)": {
//     container: {
//       padding: "10px",
//     },
//     imageSection: {
//       width: "80vw",
//     },
//     imageContainer: {
//       width: "45vw",
//     },
//     textSection: {
//       fontSize: "1rem",
//       padding: "3vw",
//     },
//     contentBoxes: {
//       padding: "3vh",
//     },
//     contentBox: {
//       width: "80%",
//     },
//   },
//   "@media (max-width: 600px)": {
//     container: {
//       padding: "10px",
//     },
//     imageSection: {
//       width: "100vw",
//     },
//     imageContainer: {
//       width: "70vw",
//     },
//     textSection: {
//       fontSize: "1rem",
//       padding: "2vw",
//     },
//     contentBoxes: {
//       padding: "2vh",
//     },
//     contentBox: {
//       width: "100%",
//     },
//   },
// };

// function VideoModal({ isOpen, onClose }) {
//   if (!isOpen) return null;

//   return (
//     <div style={styles.videoModal}>
//       <div style={styles.videoContent}>
//         <button style={styles.closeButton} onClick={onClose}>
//           &times;
//         </button>
//         <iframe
//           width="560"
//           height="315"
//           src="https://www.youtube-nocookie.com/embed/5KbUbAPeh1k?rel=0"
//           title="YouTube video player"
//           frameBorder="0"
//           allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
//           allowFullScreen
//         ></iframe>
//       </div>
//     </div>
//   );
// }

// function ContentBoxes() {
//   const boxes = [
//     "Structured “On The Campus” Sessions",
//     "Comprehensive Topics: Preprimary to Senior Classes",
//     "Enhanced Parent School Collaboration: Regular Sessions",
//     "Engaging and Interactive Approach: Real Life Scenarios and Actionable Takeaways",
//   ];

//   const [visibleBoxes, setVisibleBoxes] = useState([]);
//   const boxRefs = useRef([]);

//   useEffect(() => {
//     const observer = new IntersectionObserver(
//       (entries) => {
//         entries.forEach((entry) => {
//           if (entry.isIntersecting) {
//             setVisibleBoxes((prev) => {
//               const index = Number(entry.target.dataset.index);
//               return prev.includes(index) ? prev : [...prev, index];
//             });
//           }
//         });
//       },
//       { threshold: 0.2 }
//     );

//     boxRefs.current.forEach((box) => observer.observe(box));

//     return () => observer.disconnect();
//   }, []);

//   return (
//     <div style={styles.contentBoxes}>
//       {boxes.map((text, index) => (
//         <div
//           key={index}
//           data-index={index}
//           ref={(el) => (boxRefs.current[index] = el)}
//           style={{
//             ...styles.contentBox,
//             ...(visibleBoxes.includes(index) && styles.contentBoxVisible),
//             ...(index % 2 === 0
//               ? {
//                   ...styles.contentBoxLeft,
//                   ...(visibleBoxes.includes(index) && {
//                     transform: "translateX(0)",
//                   }),
//                 }
//               : {
//                   ...styles.contentBoxRight,
//                   ...(visibleBoxes.includes(index) && {
//                     transform: "translateX(0)",
//                   }),
//                 }),
//           }}
//         >
//           <div style={styles.overlay}></div>
//           <h3 style={styles.contentBoxText}>{text}</h3>
//         </div>
//       ))}
//     </div>
//   );
// }

// function Parenting() {
//   const [isVideoOpen, setIsVideoOpen] = useState(false);
//   const [isMobile, setIsMobile] = useState(false);

//   const updateIsMobile = () => {
//     if (window.innerWidth <= 768) {
//       setIsMobile(true);
//     } else {
//       setIsMobile(false);
//     }
//   };

//   // Set up event listener to handle window resize
//   useEffect(() => {
//     updateIsMobile(); // Check on initial render
//     window.addEventListener("resize", updateIsMobile); // Add event listener for resize
//     return () => {
//       window.removeEventListener("resize", updateIsMobile); // Cleanup listener on unmount
//     };
//   }, []);

//   const handleButtonClick = () => {
//     document
//       .getElementById("FooterInfo")
//       ?.scrollIntoView({ behavior: "smooth" });
//   };

//   return (
//     <div
//       style={{
//         background: `
// linear-gradient(to left, rgb(10, 1, 50),rgb(9, 7, 114), rgb(7, 53, 190)),
//     linear-gradient(to top, #ffffff,#ffffff,rgb(251, 252, 252),rgba(251, 252, 252, 0.61), rgba(255, 255, 255, 0.21), rgba(255, 255, 255, 0))
//   `,
//         backgroundBlendMode: "overlay",
//         padding: "40px",
//         paddingBottom: "0px",
//         overflowX: "hidden",
//       }}
//     >
//       <div style={styles.container}>
//         <div style={styles.mainContent}>
//           <div
//             style={{
//               ...styles.container,
//               flexDirection: "row",
//               height: "auto",
//             }}
//           >
//             <div style={styles.imageSection}>
//               <div style={{ ...styles.imageContainer, padding: "0px" }}>
//                 <img src="./p1.jpg" alt="Classroom 1" style={styles.image} />
//                 <img src="./p1.jpg" alt="Classroom 2" style={styles.image} />
//               </div>
//               <div style={styles.imageContainer}>
//                 <img
//                   src="./p1.jpg"
//                   alt="Student Group Activity"
//                   style={{ ...styles.image, height: "82vh" }}
//                 />
//               </div>
//             </div>
//             <div style={styles.header}>
//               <h1 style={styles.title}>GYANKOSHA presents</h1>
//               <h2 style={styles.subtitle}>
//                 PARENTING EXCELLENCE PROGRAM:
//                 <br /> In Partnership with Schools
//               </h2>
//             </div>
//           </div>
//         </div>
//         <div style={styles.textSection}>
//           <strong style={styles.programTitle}>
//             Gyankosha’s Parenting Excellence Program:
//           </strong>
//           <h3
//             style={{
//               fontWeight: "bold",
//               marginBottom: "0px",
//               fontSize: "2.0rem",
//             }}
//           >
//             Ensuring schools and parents work as partners in shaping students’
//             holistic development
//           </h3>
//           <ul
//             style={{ fontSize: "1.5rem", padding: "5vw", textAlign: "justify" }}
//           >
//             <li>
//               Thoughtfully designed to empower parents with practical tools and
//               strategies to support their child’s academic, emotional, and
//               social development.
//             </li>
//             <li>
//               Delivered in collaboration with schools, these sessions cover
//               critical topics such as managing academic stress, fostering
//               emotional intelligence, positive discipline, and nurturing
//               independence.
//             </li>
//             <li>
//               Through engaging, interactive workshops, Gyankosha helps parents
//               build stronger connections with their children, navigate modern
//               parenting challenges, and create a supportive environment for
//               holistic growth.
//             </li>
//             <li>
//               These sessions not only enhance parenting skills but also
//               strengthen the partnership between parents and schools, ensuring a
//               unified approach to a child’s success.
//             </li>
//           </ul>
//           <a
//             href="#"
//             onClick={(e) => {
//               e.preventDefault();
//               setIsVideoOpen(true);
//             }}
//             style={{
//               color: "#0e003d",
//               textDecoration: "underline",
//               padding: "5vh",
//               fontSize: "1.8rem",
//             }}
//           >
//             Watch Video
//           </a>
//           <br />
//           <button style={styles.actionButton} onClick={handleButtonClick}>
//             Question? Contact Us
//           </button>
//           <div style={{ ...styles.container }}>
//             <ContentBoxes />
//           </div>
//         </div>
//         <div style={{ ...styles.galleryContainer }}>
//           <ParentGallery />
//         </div>

//         <div style={{ width: "100vw" }}>
//           <LearningAsParent />
//         </div>
//         <FooterInfo id="FooterInfo" />
//       </div>

//       <VideoModal isOpen={isVideoOpen} onClose={() => setIsVideoOpen(false)} />
//     </div>
//   );
// }

// export default Parenting;

import React, { useState, useRef, useEffect } from "react";
import LearningAsParent from "./LearningAsParent";
import ParentGallery from "./Parentgallery";
import FooterInfo from "./FooterInfo";
import { Link } from "react-router-dom";

const styles = {
  navbar: {
    backgroundColor: "#0e003d",
    color: "white",
    textAlign: "center",
    padding: "30px",
    position: "sticky",
    top: 0,
    zIndex: 1,
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
  },
  navbarTitle: {
    fontSize: "1.8rem",
    fontWeight: "bold",
    margin: 0,
    textShadow: "2px 2px 4px rgba(0, 0, 0, 0.3)",
    fontFamily: '"Libre Baskerville", serif',
  },
  container: {
    display: "flex",
    flexDirection: "column",
    fontFamily: '"Libre Baskerville", serif',
    fontWeight: "bolder",
    alignItems: "center",
    overflowX: "hidden",
    backgroundColor: "#ffffff",
    padding: "20px",
    marginBottom: "5vh",
  },
  mainContent: {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    padding: "20px",
  },
  imageSection: {
    display: "flex",
    width: "50vw",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "right",
    gap: "20px",
    paddingTop: "5vh",
  },
  imageContainer: {
    display: "flex",
    flexDirection: "column",
    width: "20vw",
    height: "auto",
    gap: "10px",
    alignItems: "center",
    width: "15vw",
    height: "20vw",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
  },
  image: {
    width: "100%",
    height: "35vw",
    objectFit: "cover",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
  },
  textSection: {
    flex: 1,
    textAlign: "justify",
    height: "auto",
    padding: "5vw",
    width: "100%",
    fontSize: "1.5rem",
    color: "black",
  },
  programTitle: {
    fontSize: "1.5rem",
    color: "#0e003d",
    display: "block",
    marginBottom: "10px",
  },
  header: {
    paddingTop: "5vh",
    paddingLeft: "10px",
    textAlign: "left",
    marginBottom: "20px",
    width: "50vw",
  },
  title: {
    fontSize: "24px",
    fontWeight: "bold",
    color: "black",
  },
  subtitle: {
    fontSize: "1.8rem",
    fontWeight: "bolder",
    color: "#0e003d",
  },
  contentBoxes: {
    padding: "5vh",
    height: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "20px",
    marginTop: "30px",
    overflow: "hidden",
    background: "white",
    width: "100vw",
  },
  contentBox: {
    width: "60%",
    height: "50%",
    background:
      "linear-gradient(to left, rgb(251, 252, 252), #e0f7fa, rgb(128, 206, 234))",
    color: "#0e003d",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    padding: "20px",
    borderRadius: "10px",
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
    opacity: 0,
    transform: "translateY(50px)",
    transition: "all 0.8s ease-in-out",
  },
  contentBoxVisible: {
    opacity: 2,
    transform: "translateY(0)",
  },
  contentBoxLeft: {
    alignSelf: "flex-start",
    transform: "translateX(-100px)",
  },
  contentBoxRight: {
    alignSelf: "flex-end",
    transform: "translateX(100px)",
  },
  contentBoxText: {
    fontWeight: "600",
    fontSize: window.innerWidth <= 1000 ? "1.8rem" : "2.3rem",
    lineHeight: "1.5",
    justifyContent: "center",
    textAlign: "center",
    zIndex: 2,
    lineHeight: 1.2,
    padding: "1.5rem",
    position: "fit",
  },
  overlay: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "0%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.4)",
    borderRadius: "10px",
    zIndex: 1,
  },
  videoModal: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.7)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 10,
  },
  videoContent: {
    backgroundColor: "white",
    padding: "20px",
    borderRadius: "10px",
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)",
    position: "relative",
  },
  closeButton: {
    position: "absolute",
    top: "10px",
    right: "10px",
    fontSize: "30px",
    background: "none",
    border: "none",
    cursor: "pointer",
  },
  actionButton: {
    backgroundColor: "#0e003d",
    color: "white",
    padding: "10px 20px",
    border: "none",
    marginTop: "5vh",
    fontSize: "1.5rem",
    borderRadius: "5px",
    cursor: "pointer",
  },
  galleryContainer: {
    display: "flex",
    overflowX: "hidden",
    gap: "20px",
    padding: "20px",
    width: "100%",
  },
  galleryItem: {
    minWidth: "300px",
    height: "200px",
    borderRadius: "8px",
    overflow: "hidden",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  "@media (max-width: 1200px)": {
    container: {
      padding: "20px",
    },
    imageSection: {
      width: "70vw",
    },
    imageContainer: {
      width: "40vw",
    },
    textSection: {
      fontSize: "1.2rem",
      padding: "4vw",
    },
    contentBoxes: {
      padding: "4vh",
    },
    contentBox: {
      width: "60%",
    },
  },
  "@media (max-width: 900px)": {
    container: {
      padding: "10px",
    },
    imageSection: {
      width: "80vw",
    },
    imageContainer: {
      width: "45vw",
    },
    textSection: {
      fontSize: "1rem",
      padding: "3vw",
    },

    contentBoxes: {
      padding: "3vh",
    },
    contentBox: {
      width: "80%",
    },
  },
  "@media (max-width: 600px)": {
    container: {
      padding: "10px",
    },

    imageSection: {
      width: "100vw",
    },
    imageContainer: {
      width: "70vw",
    },
    textSection: {
      fontSize: "1rem",
      padding: "2vw",
    },
    contentBoxes: {
      padding: "2vh",
    },
    contentBox: {
      width: "100%",
    },
  },
};
function VideoModal({ isOpen, onClose }) {
  if (!isOpen) return null;

  const handleBackgroundClick = (e) => {
    // Close only if the click is outside the video content
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <div
      style={styles.videoModal}
      onClick={handleBackgroundClick} // Close on clicking outside the video content
    >
      <div style={styles.videoContent}>
        <button style={styles.closeButton} onClick={onClose}>
          &times;
        </button>
        <iframe
          width="560"
          height="315"
          src="https://www.youtube-nocookie.com/embed/5KbUbAPeh1k?rel=0"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  );
}
// function VideoModal({ isOpen, onClose }) {
//   if (!isOpen) return null;

//   return (
//     <div style={styles.videoModal}>
//       <div style={styles.videoContent}>
//         <button style={styles.closeButton} onClick={onClose}>
//           &times;
//         </button>
//         <iframe
//           width="560"
//           height="315"
//           src="https://www.youtube-nocookie.com/embed/5KbUbAPeh1k?rel=0"
//           title="YouTube video player"
//           frameBorder="0"
//           allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
//           allowFullScreen
//         ></iframe>
//       </div>
//     </div>
//   );
// }

function ContentBoxes() {
  const boxes = [
    "Structured “On The Campus” Sessions",
    "Comprehensive Topics: Preprimary to Senior Classes",
    "Enhanced Parent School Collaboration: Regular Sessions",
    // "Engaging and Interactive Approach: Real Life Scenarios and Actionable Takeaways",
    "Interactive Insights: Real-Life Scenarios & Actionable Takeaways",
  ];

  const [visibleBoxes, setVisibleBoxes] = useState([]);
  const boxRefs = useRef([]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setVisibleBoxes((prev) => {
              const index = Number(entry.target.dataset.index);
              return prev.includes(index) ? prev : [...prev, index];
            });
          }
        });
      },
      { threshold: 0.2 }
    );

    boxRefs.current.forEach((box) => observer.observe(box));

    return () => observer.disconnect();
  }, []);

  return (
    <div style={styles.contentBoxes}>
      {boxes.map((text, index) => (
        <div
          key={index}
          data-index={index}
          ref={(el) => (boxRefs.current[index] = el)}
          style={{
            ...styles.contentBox,
            ...(visibleBoxes.includes(index) && styles.contentBoxVisible),
            ...(index % 2 === 0
              ? {
                  ...styles.contentBoxLeft,
                  ...(visibleBoxes.includes(index) && {
                    transform: "translateX(0)",
                  }),
                }
              : {
                  ...styles.contentBoxRight,
                  ...(visibleBoxes.includes(index) && {
                    transform: "translateX(0)",
                  }),
                }),
          }}
        >
          <div style={styles.overlay}></div>
          <h3 style={styles.contentBoxText}>{text}</h3>
        </div>
      ))}
    </div>
  );
}

function Parenting() {
  const [isVideoOpen, setIsVideoOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const updateIsMobile = () => {
    setIsMobile(window.innerWidth <= 1000);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Set up event listener to handle window resize
  useEffect(() => {
    updateIsMobile(); // Check on initial render
    window.addEventListener("resize", updateIsMobile); // Add event listener for resize
    return () => {
      window.removeEventListener("resize", updateIsMobile); // Cleanup listener on unmount
    };
  }, []);
  const handleButtonClick = () => {
    document
      .getElementById("FooterInfo")
      ?.scrollIntoView({ behavior: "smooth" });
  };
  const subtitleFontSize =
    window.innerWidth < 600
      ? "1.1rem"
      : window.innerWidth < 900
      ? "1.7rem"
      : "1.5rem";

  const responsiveStyles = {
    imageSection: {
      flexDirection: isMobile ? "column" : "row",
      width: isMobile ? "0vw" : "50vw",
    },
    navbarStyle: {
      position: "relative",
      display: "flex", // Use flexbox for layout
      justifyContent: "space-between", // Space out heading and button
      alignItems: "center", // Align items vertically
      backgroundColor: "#0e003d",
      color: "white",
      width: "100%",
      padding: "15px 20px",
      fontFamily: '"Libre Baskerville", serif',
      fontWeight: "bolder",
      boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
      flexDirection: "row", // Default for larger screens
    },
    moreArticlesButtonStyle: {
      fontSize: "16px",
      padding: "10px 20px",
      backgroundColor: "rgb(40, 9, 92)",
      color: "#fff",
      border: "none",
      position: "absolute", // Position relative to the containing div
      top: "50%", // Center vertically within the navbar
      right: "20px", // Adjust for the right corner
      transform: "translateY(-50%)", // Vertical alignment
      borderRadius: "4px",
      cursor: "pointer",
      textDecoration: "none",
      zIndex: 1, // Keeps it above other elements
      transition: "background-color 0.3s ease",
    },

    container: {
      padding: isMobile ? "0px" : "20px",
      marginBottom: isMobile ? "0vh" : "5vh",
    },
    imageContainer: {
      width: isMobile ? "100%" : "15vw",
      justifyContent: "center",
    },
    title: {
      fontSize: "24px",
      fontWeight: "bold",
      color: isMobile ? "grey" : "black",
    },
    subtitle: {
      // fontSize: isMobile ? "1.5rem" : "32px",
      fontWeight: "bolder",
      color: isMobile ? "white" : "#0e003d",
    },
    textSection: {
      fontSize: isMobile ? "1rem" : "1.5rem",
      padding: isMobile ? "2vw" : "5vw",
    },
    header: {
      paddingLeft: isMobile ? "" : "10px",
      width: isMobile ? "auto" : "50vw",
      alignItems: isMobile ? "center" : "",
      textAlign: isMobile ? "" : "left",
    },
    subtitle: {
      fontSize: subtitleFontSize,
    },
    actionButton: {
      fontSize: isMobile ? "1rem" : "1.5rem",
      padding: isMobile ? "8px 16px" : "10px 20px",
    },
  };
  const mobileStyles = {
    navbarStyle: {
      flexDirection: "column", // Stack items vertically
      alignItems: "flex-start", // Align items to the left
      padding: "10px 15px",
    },
    moreArticlesButtonStyle: {
      fontSize: "14px",
      padding: "8px 12px",
      position: "relative", // Move to relative for better stacking
      top: "auto", // Reset top position
      right: "auto", // Reset right position
      transform: "none", // Remove transformation
      marginTop: "10px", // Add margin for spacing
    },
  };

  return (
    <div
      style={{
        background: `
linear-gradient(to left, rgb(75, 47, 197),rgb(106, 164, 251), rgb(7, 129, 190)),
    linear-gradient(to top, #ffffff,#ffffff,rgb(251, 252, 252),rgba(251, 252, 252, 0.61), rgba(255, 255, 255, 0.21), rgba(255, 255, 255, 0))
  `,
        backgroundBlendMode: "overlay",
        padding: "5vw",
        paddingBottom: "0vw",
        overflowX: "hidden",
      }}
    >
      <div style={styles.container}>
        <div style={styles.mainContent}>
          <div
            style={{
              ...styles.container,
              flexDirection: isMobile ? "column" : "row",
              marginBottom: isMobile ? "0vh" : "5vh",
              height: "auto",
            }}
          >
            {window.innerWidth <= 1000 ? (
              <div
                style={{
                  ...responsiveStyles.imageContainer,
                }}
              >
                <img
                  src="./p1.jpg"
                  alt="Student Group Activity"
                  style={{
                    ...responsiveStyles.image,
                    height: "40vh",
                  }}
                />
                <div
                  style={{
                    ...styles.header,
                    ...responsiveStyles.header,
                  }}
                >
                  <h1 style={styles.title}>GYANKOSHA presents</h1>
                  <h2
                    style={{
                      ...styles.subtitle,
                      ...responsiveStyles.subtitle,
                    }}
                  >
                    PARENTING EXCELLENCE PROGRAM:
                    <br /> In Partnership with Schools
                  </h2>
                </div>
              </div>
            ) : (
              <>
                <div
                  style={{
                    ...styles.imageSection,
                    ...responsiveStyles.imageSection,
                  }}
                >
                  <div
                    style={{
                      ...styles.imageContainer,
                      ...responsiveStyles.imageContainer,
                      padding: "0px",
                    }}
                  >
                    <img
                      src="./Parenting.png"
                      alt="Classroom 1"
                      style={styles.image}
                    />
                    <img
                      src="./p4.jpg" //change
                      alt="Classroom 2"
                      style={styles.image}
                    />
                  </div>
                  <div
                    style={{
                      ...styles.imageContainer,
                      ...responsiveStyles.imageContainer,
                    }}
                  >
                    <img
                      src="./parentingg3.jpeg"
                      alt="Student Group Activity"
                      style={{
                        ...styles.image,
                        height: isMobile ? "40vh" : "82vh",
                      }}
                    />
                  </div>
                </div>
                <div style={{ ...styles.header }}>
                  <h1 style={styles.title}>GYANKOSHA presents</h1>

                  <h2 style={styles.subtitle}>
                    PARENTING EXCELLENCE PROGRAM:
                    <br /> In Partnership with Schools
                  </h2>
                </div>
              </>
            )}
          </div>
        </div>
        <div style={{ ...styles.textSection, ...responsiveStyles.textSection }}>
          <strong style={styles.programTitle}>
            Gyankosha’s Parenting Excellence Program:
          </strong>
          <h3
            style={{
              fontWeight: "bold",
              marginBottom: "0px",
              fontSize: isMobile ? "1.5rem" : "2.0rem",
            }}
          >
            Ensuring schools and parents work as partners in shaping students’
            holistic development
          </h3>
          <ul
            style={{
              fontSize: isMobile ? "1rem" : "1.5rem",
              padding: isMobile ? "2vw" : "5vw",
              textAlign: "justify",
            }}
          >
            <li>
              Thoughtfully designed to empower parents with practical tools and
              strategies to support their child’s academic, emotional, and
              social development.
            </li>
            <li>
              Delivered in collaboration with schools, these sessions cover
              critical topics such as managing academic stress, fostering
              emotional intelligence, positive discipline, and nurturing
              independence.
            </li>
            <li>
              Through engaging, interactive workshops, Gyankosha helps parents
              build stronger connections with their children, navigate modern
              parenting challenges, and create a supportive environment for
              holistic growth.
            </li>
            <li>
              These sessions not only enhance parenting skills but also
              strengthen the partnership between parents and schools, ensuring a
              unified approach to a child’s success.
            </li>
          </ul>
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              setIsVideoOpen(true);
            }}
            style={{
              color: "#0e003d",
              textDecoration: "underline",
              padding: "5vh",
              fontSize: isMobile ? "1rem" : "1.8rem",
            }}
          >
            Watch Video
          </a>
          <br />
          <button
            style={{ ...styles.actionButton, ...responsiveStyles.actionButton }}
            onClick={handleButtonClick}
          >
            Question? Contact Us
          </button>
          <div
            style={{
              ...styles.container,
              ...responsiveStyles.textSection,

              fontSizeAdjust: ".250",
            }}
          >
            <ContentBoxes />
          </div>
        </div>{" "}
        <div
          style={{
            ...(window.innerWidth <= 600
              ? { ...responsiveStyles.navbarStyle, ...mobileStyles.navbarStyle }
              : responsiveStyles.navbarStyle),
            background: "rgba(3, 2, 51, 0.95)",
          }}
        >
          <strong style={{ fontSize: "1.8rem", padding: "0px", flex: "1" }}>
            LEARNING AS A PARENT
          </strong>
          <Link
            to="/articlecardpage?division=Parent"
            style={{
              ...responsiveStyles.moreArticlesButtonStyle,
              ...mobileStyles.moreArticlesButtonStyle,
            }}
            onMouseEnter={(e) => {
              e.currentTarget.style.backgroundColor = "rgba(3, 2, 51, 0.95)";
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.backgroundColor = "rgb(2, 205, 251)";
            }}
            onClick={(e) => {
              e.currentTarget.style.backgroundColor = "rgba(5, 4, 70, 1)";
            }}
          >
            →
          </Link>
        </div>
        <div
          style={{
            ...styles.galleryContainer,
            overflowX: "scroll",
            width: "100%",
          }}
        >
          {" "}
          <LearningAsParent />
        </div>
        <div style={{ ...styles.galleryContainer }}>
          <ParentGallery />
        </div>
        <div style={{ width: "100vw" }}>
          {" "}
          <FooterInfo id="FooterInfo" />
        </div>
      </div>

      <VideoModal isOpen={isVideoOpen} onClose={() => setIsVideoOpen(false)} />
    </div>
  );
}

export default Parenting;
