// // // import React, { useEffect, useState } from "react";
// // // import { Link } from "react-router-dom"; // For navigation
// // // import ArticleCard from "../components/ArticleCard"; // Assuming the ArticleCard component is the same

// // // function LearningAsStudent() {
// // //   const [cards, setCards] = useState([]);
// // //   const [isMobile, setIsMobile] = useState(false);

// // //   useEffect(() => {
// // //     // Fetch articles from the backend API
// // //     const fetchCards = async () => {
// // //       try {
// // //         // Pass division="Student" as a query parameter
// // //         const response = await fetch(
// // //           `/api/articles/fetcharticle?division=Student`
// // //         );
// // //         const data = await response.json();

// // //         // Set the fetched articles to state
// // //         setCards(data);
// // //       } catch (error) {
// // //         console.error("Error fetching articles:", error);
// // //       }
// // //     };

// // //     fetchCards();

// // //     // Detect if the device is mobile
// // //     const handleResize = () => {
// // //       setIsMobile(window.innerWidth <= 768); // Adjust the breakpoint as needed
// // //     };

// // //     // Initial check and event listener for resize
// // //     handleResize();
// // //     window.addEventListener("resize", handleResize);

// // //     return () => {
// // //       window.removeEventListener("resize", handleResize);
// // //     };
// // //   }, []);

// // //   // Button styles
// // //   const moreArticlesButtonStyle = {
// // //     position: "absolute",
// // //     right: "40px", // Align it to the right
// // //     top: "150%",
// // //     transform: "translateY(-50%)", // Center vertically
// // //     fontSize: "40px", // Larger for better visibility
// // //     color: "white",
// // //     backgroundColor: "rgb(2, 205, 251)", // Attractive green background
// // //     padding: "20px",
// // //     borderRadius: "60%", // Circular button
// // //     textDecoration: "none",
// // //     fontWeight: "bold",
// // //     boxShadow: "0px 4px 6px rgba(12, 9, 91, 0.1)",
// // //     display: "flex",
// // //     justifyContent: "center",
// // //     alignItems: "center",
// // //     transition: "background-color 0.3s ease, transform 0.3s ease",
// // //     cursor: "pointer",
// // //   };

// // //   const sectionStyle = {
// // //     position: "relative",
// // //     backgroundColor: "#0e003d",
// // //     color: "white",
// // //     // padding: isMobile ? "10px" : "10px",
// // //     fontFamily: '"Libre Baskerville", serif',
// // //     marginBottom: "0px",
// // //     // minHeight: "150px", // To ensure the button centers well
// // //     display: "flex",
// // //     justifyContent: "center",
// // //     alignItems: "center",
// // //   };

// // //   return (
// // //     <div>
// // //       {/* Section */}
// // //       <div style={sectionStyle}>
// // //         <h2
// // //           style={{
// // //             backgroundColor: "#0e003d",
// // //             color: "white",
// // //             padding: "15px 20px",
// // //             position: "sticky",
// // //             top: 0,
// // //             fontFamily: '"Libre Baskerville", serif',
// // //             zIndex: 1,
// // //             textAlign: "center",
// // //             boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
// // //             width: "auto",
// // //             // marginInline: "-5px",
// // //             fontWeight: "bolder",
// // //           }}
// // //         >
// // //           {" "}
// // //           Learn as Student
// // //         </h2>

// // //         <Link
// // //           to="/articlecardpage?division=Student"
// // //           style={moreArticlesButtonStyle}
// // //           onMouseEnter={(e) => {
// // //             e.currentTarget.style.backgroundColor = "rgba(3, 2, 51, 0.95)"; // Darker green on hover
// // //             e.currentTarget.style.transform = "scale(1.1)"; // Slight zoom
// // //           }}
// // //           onMouseLeave={(e) => {
// // //             e.currentTarget.style.backgroundColor = "rgb(2, 205, 251)";
// // //             e.currentTarget.style.transform = "scale(1)";
// // //           }}
// // //         >
// // //           →
// // //         </Link>
// // //       </div>

// // //       {/* Mobile View */}
// // //       {isMobile ? (
// // //         <div style={{ marginBottom: "5vh" }}>
// // //           <div
// // //             style={{
// // //               display: "flex",
// // //               overflowX: "auto",
// // //               padding: "10px 0",
// // //               gap: "10px", // Space between items
// // //             }}
// // //           >
// // //             {cards.map((card, index) => (
// // //               <div
// // //                 key={index}
// // //                 style={{
// // //                   minWidth: "250px", // Set a minimum width for each card
// // //                   flexShrink: 0,
// // //                 }}
// // //               >
// // //                 <ArticleCard cards={[card]} />
// // //               </div>
// // //             ))}
// // //           </div>
// // //         </div>
// // //       ) : (
// // //         // Desktop View
// // //         <div style={{ padding: "20px" }}>
// // //           <ArticleCard cards={cards} />
// // //         </div>
// // //       )}
// // //     </div>
// // //   );
// // // }

// // // export default LearningAsStudent;
// // import React, { useEffect, useState } from "react";
// // import { Link } from "react-router-dom";
// // import ArticleCard from "../components/ArticleCard";

// // function LearningAsStudent() {
// //   const [cards, setCards] = useState([]);
// //   const [isMobile, setIsMobile] = useState(false);

// //   useEffect(() => {
// //     const fetchCards = async () => {
// //       try {
// //         const response = await fetch(
// //           `/api/articles/fetcharticle?division=Student`
// //         );
// //         const data = await response.json();
// //         setCards(data);
// //       } catch (error) {
// //         console.error("Error fetching articles:", error);
// //       }
// //     };

// //     fetchCards();

// //     const handleResize = () => {
// //       setIsMobile(window.innerWidth <= 768);
// //     };

// //     handleResize();
// //     window.addEventListener("resize", handleResize);

// //     return () => {
// //       window.removeEventListener("resize", handleResize);
// //     };
// //   }, []);

// //   const navbarStyle = {
// //     // position: "relative",
// //     textAlign: "left",
// //     color: "#0e003d",
// //     fontWeight: "bolder",
// //     padding: "20px",
// //     display: "flex",
// //     fontFamily: '"Libre Baskerville", serif',
// //     fontWeight: "bolder",
// //     boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
// //     background:
// //       "linear-gradient(to left,rgb(251, 252, 252), #e0f7fa,rgb(82, 172, 205))",
// //   };

// //   const moreArticlesButtonStyle = {
// //     position: "absolute",
// //     // top: "50%",
// //     right: "20px",
// //     transform: "translateY(0%)",
// //     fontSize: "24px",
// //     color: "white",
// //     backgroundColor: "rgb(40, 9, 92)",
// //     padding: isMobile ? "5px 10px" : "10px 20px",
// //     borderRadius: "60px",
// //     textDecoration: "none",
// //     fontWeight: "bold",
// //     boxShadow: "0px 4px 6px rgba(12, 9, 91, 0.1)",
// //     transition: "background-color 0.3s ease",
// //     cursor: "pointer",
// //   };

// //   return (
// //     <div>
// //       {/* Navbar */}
// //       <div style={navbarStyle}>
// //         <p style={{ paddingInlineEnd: "10vw", fontSize: "40px" }}>
// //           ARTICLE SECTION
// //         </p>
// //         <Link
// //           to="/articlecardpage?division=Student"
// //           style={moreArticlesButtonStyle}
// //           onMouseEnter={(e) => {
// //             e.currentTarget.style.backgroundColor = "rgba(3, 2, 51, 0.95)";
// //           }}
// //           onMouseLeave={(e) => {
// //             e.currentTarget.style.backgroundColor = "rgb(2, 205, 251)";
// //           }}
// //           onClick={(e) => {
// //             e.currentTarget.style.backgroundColor = "rgba(5, 4, 70, 1)";
// //           }}
// //         >
// //           →
// //         </Link>
// //       </div>
// //       <div style={{ ...navbarStyle, marginTop: "-1vh" }}>
// //         <h2 style={{ paddingInlineEnd: "10vw" }}>STUDENT</h2>
// //         <br></br>
// //         <h2 style={{ paddingInlineEnd: "10vw" }}>TEACHER</h2>
// //         <br></br>
// //         <h2 style={{ paddingInlineEnd: "10vw" }}>PARENT</h2>
// //       </div>
// //       {/* Content */}
// //       {isMobile ? (
// //         <div style={{ marginBottom: "5vh" }}>
// //           <div
// //             style={{
// //               display: "flex",
// //               overflowX: "auto",
// //               padding: "10px 0",
// //               gap: "10px",
// //             }}
// //           >
// //             {cards.map((card, index) => (
// //               <div
// //                 key={index}
// //                 style={{
// //                   minWidth: "250px",
// //                   flexShrink: 0,
// //                 }}
// //               >
// //                 <ArticleCard cards={[card]} />
// //               </div>
// //             ))}
// //           </div>
// //         </div>
// //       ) : (
// //         <div style={{ padding: "20px" }}>
// //           <ArticleCard cards={cards} />
// //         </div>
// //       )}
// //     </div>
// //   );
// // }

// // export default LearningAsStudent;
// import React, { useEffect, useState } from "react";
// import { Link, useNavigate } from "react-router-dom";
// import ArticleCard from "../components/ArticleCard";

// function LearningAsStudent() {
//   const [cards, setCards] = useState([]);
//   const [isMobile, setIsMobile] = useState(false);
//   const [activeSection, setActiveSection] = useState("All"); // Track active section
//   const navigate = useNavigate();

//   useEffect(() => {
//     const fetchCards = async () => {
//       try {
//         let response;
//         if (activeSection === "All") {
//           response = await fetch("/api/articles/fetchallarticles");
//         } else {
//           const division = `?division=${activeSection}`;
//           response = await fetch(`/api/articles/fetcharticle${division}`);
//         }
//         const data = await response.json();
//         setCards(data);
//       } catch (error) {
//         console.error("Error fetching articles:", error);
//       }
//     };

//     fetchCards();
//   }, [activeSection]); // Dependency array ensures this runs when activeSection changes

//   useEffect(() => {
//     const handleResize = () => {
//       setIsMobile(window.innerWidth <= 768);
//     };

//     handleResize();
//     window.addEventListener("resize", handleResize);

//     return () => {
//       window.removeEventListener("resize", handleResize);
//     };
//   }, []);

//   const navbarStyle = {
//     display: "flex",
//     justifyContent: isMobile ? "space-around" : "flex-start",
//     alignItems: "center",
//     flexWrap: "wrap",
//     padding: "20px",
//     fontFamily: '"Libre Baskerville", serif',
//     background:
//       "linear-gradient(to left, rgb(251, 252, 252), #e0f7fa, rgb(82, 172, 205))",
//     boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
//     gap: "15px",
//   };

//   const linkStyle = (section) => ({
//     fontSize: isMobile ? "16px" : "18px",
//     fontWeight: activeSection === section ? "bold" : "normal",
//     textDecoration: "none",
//     color: activeSection === section ? "rgb(40, 9, 92)" : "#000",
//     padding: isMobile ? "5px" : "10px",
//     cursor: "pointer",
//     borderBottom:
//       activeSection === section ? "2px solid rgb(40, 9, 92)" : "none",
//     transition: "color 0.3s ease, border-bottom 0.3s ease",
//   });
//   const buttonStyle = {
//     fontSize: isMobile ? "14px" : "16px",
//     padding: isMobile ? "8px 12px" : "10px 20px",
//     backgroundColor: "rgb(40, 9, 92)",
//     color: "#fff",
//     border: "none",
//     right: 0,
//     borderRadius: "4px",
//     cursor: "pointer",
//     transition: "background-color 0.3s ease",
//   };

//   const handleSectionClick = (section) => {
//     setActiveSection(section);
//   };
//   const handleNavigate = () => {
//     const targetPath = `/articlecardpage?division=${activeSection}`;
//     navigate(targetPath);
//   };
//   return (
//     <div>
//       {/* Navbar */}
//       <div style={navbarStyle}>
//         <p style={{ paddingInlineEnd: "10vw", fontSize: "40px" }}>
//           ARTICLE SECTION
//         </p>
//         <button style={buttonStyle} onClick={handleNavigate}>
//           View All Articles
//         </button>
//       </div>
//       <div style={navbarStyle}>
//         {["All", "Student", "Teacher", "Parent"].map((section) => (
//           <span
//             key={section}
//             style={linkStyle(section)}
//             onClick={() => handleSectionClick(section)}
//           >
//             {section.toUpperCase()}
//           </span>
//         ))}
//       </div>

//       {/* Content */}
//       {isMobile ? (
//         <div style={{ marginBottom: "5vh" }}>
//           <div
//             style={{
//               display: "flex",
//               overflowX: "auto",
//               padding: "10px 0",
//               gap: "10px",
//             }}
//           >
//             {cards.map((card, index) => (
//               <div
//                 key={index}
//                 style={{
//                   minWidth: "250px",
//                   flexShrink: 0,
//                 }}
//               >
//                 <ArticleCard cards={[card]} />
//               </div>
//             ))}
//           </div>
//         </div>
//       ) : (
//         <div style={{ padding: "20px" }}>
//           <ArticleCard cards={cards} />
//         </div>
//       )}
//     </div>
//   );
// }

// export default LearningAsStudent;
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ArticleCard from "../components/ArticleCard";

function Learning() {
  const [cards, setCards] = useState([]);
  const [isMobile, setIsMobile] = useState(false);
  const [activeSection, setActiveSection] = useState("All"); // Track active section
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCards = async () => {
      try {
        let response;
        if (activeSection === "All") {
          response = await fetch("/api/articles/fetchallarticles");
        } else {
          const division = `?division=${activeSection}`;
          response = await fetch(`/api/articles/fetcharticle${division}`);
        }
        const data = await response.json();
        setCards(data);
      } catch (error) {
        console.error("Error fetching articles:", error);
      }
    };

    fetchCards();
  }, [activeSection]); // Dependency array ensures this runs when activeSection changes

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const navbarStyle = {
    display: "flex",
    justifyContent: isMobile ? "space-around" : "flex-start",
    alignItems: "center",
    flexWrap: "wrap",
    padding: "20px",
    fontFamily: '"Libre Baskerville", serif',
    background:
      "linear-gradient(to left, rgb(251, 252, 252), #e0f7fa, rgb(82, 172, 205))",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
    gap: "15px",
  };

  const linkStyle = (section) => ({
    fontSize: isMobile
      ? activeSection === section
        ? "20px"
        : "16px"
      : activeSection === section
      ? "23px"
      : "18px",
    fontWeight: activeSection === section ? "bold" : "normal",
    textDecoration: "none",
    color: activeSection === section ? "rgb(40, 9, 92)" : "#000",
    padding: isMobile ? "5px" : "10px",
    cursor: "pointer",
    borderBottom:
      activeSection === section ? "2px solid rgb(40, 9, 92)" : "none",
    transition: "color 0.3s ease, border-bottom 0.3s ease",
  });

  const buttonStyle = {
    fontSize: isMobile ? "14px" : "16px",
    padding: isMobile ? "8px 12px" : "10px 20px",
    backgroundColor: "rgb(40, 9, 92)",
    color: "#fff",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    transition: "background-color 0.3s ease",
  };

  const handleSectionClick = (section) => {
    setActiveSection(section);
  };

  const handleNavigate = () => {
    const targetPath = `/articlecardpage?division=${activeSection}`;
    navigate(targetPath);
  };

  return (
    <div>
      {/* Navbar */}
      <div style={navbarStyle}>
        <p style={{ paddingInlineEnd: "10vw", fontSize: "40px" }}>
          LEARNING SECTION
        </p>
        <button
          style={{
            ...buttonStyle,
            marginLeft: "auto", // Ensures the button moves to the right
          }}
          onClick={handleNavigate}
        >
          View Articles
        </button>
      </div>
      <div style={navbarStyle}>
        {["All", "Student", "Teacher", "Parent"].map((section) => (
          <span
            key={section}
            style={linkStyle(section)}
            onClick={() => handleSectionClick(section)}
          >
            {section.toUpperCase()}
          </span>
        ))}
      </div>

      {/* Content */}
      <div
        style={{
          display: "flex",
          overflowX: "auto",
          gap: "20px",
          padding: "20px",
          scrollbarWidth: "thin", // Optional: For styling the scrollbar
        }}
      >
        {cards.map((card, index) => (
          <div
            key={index}
            style={{
              flex: "0 0 300px", // Fixed width for all cards
              height: "400px", // Fixed height for all cards
              backgroundColor: "#fff",
              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
              borderRadius: "8px",
              overflow: "hidden",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <ArticleCard cards={[card]} />
          </div>
        ))}
      </div>
    </div>
  );
}

export default Learning;
