// import React, { useState, useEffect } from "react";
// import { Link } from "react-router-dom";
// import { useSelector } from "react-redux";

// const MiddleScreen = () => {
//   const userstate = useSelector((state) => state.loginUserReducer);
//   const { currentUser1 } = userstate;

//   // Define a common style object for button shadows
//   const buttonShadowStyle = {
//     boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.5)",
//     transition: "background-color 0.3s",
//   };

//   const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

//   useEffect(() => {
//     const handleResize = () => {
//       setIsMobile(window.innerWidth < 768);
//     };

//     window.addEventListener("resize", handleResize);

//     return () => {
//       window.removeEventListener("resize", handleResize);
//     };
//   }, []);

//   return (
//     <div style={{ paddingTop: "70px" }} className="position-relative">
//       <button
//         className="btn btn-primary"
//         style={{
//           ...buttonShadowStyle,
//           backgroundColor: "#007bff",
//           display: currentUser1 ? "none" : "",
//         }}
//       >
//         <Link
//           to="/login"
//           className="text-white text-decoration-none"
//           style={{ color: "#fff" }}
//           state={{
//             page: "/middlescreen",
//           }}
//         >
//           Login
//         </Link>
//       </button>
//       {currentUser1 ? (
//         <button
//           className="btn btn-success mx-2"
//           style={{
//             ...buttonShadowStyle,
//             backgroundColor: "#28a745",
//             pointerEvents: "none",
//             opacity: "0.5",
//             display: currentUser1 ? "none" : "",
//           }}
//           disabled
//         >
//           Registration
//         </button>
//       ) : (
//         <button
//           className="btn btn-success mx-2"
//           style={{
//             ...buttonShadowStyle,
//             backgroundColor: "#28a745",
//             display: currentUser1 ? "none" : "",
//           }}
//         >
//           <Link
//             to="/register"
//             className="text-white text-decoration-none"
//             style={{ color: "#fff" }}
//           >
//             Registration
//           </Link>
//         </button>
//       )}
//       <div
//         className="text-center mt-5"
//         style={{ paddingBottom: "450px", left: isMobile ? "10px" : "200px" }}
//       >
//         {currentUser1 ? (
//           <Link
//             to="/bauddhadakshata"
//             className="btn btn-info btn-lg rounded-circle p-5 position-absolute start-50 translate-middle-x"
//             style={{
//               ...buttonShadowStyle,
//               bottom: "50px",
//               border: "2px black",
//             }}
//           >
//             <span style={{ fontSize: "24px" }}>Quiz</span>
//           </Link>
//         ) : (
//           <button
//             className="btn btn-info btn-lg rounded-circle p-5 position-absolute start-50 translate-middle-x"
//             style={{
//               ...buttonShadowStyle,
//               bottom: "10px",
//               border: "2px black",
//               pointerEvents: "none",
//               opacity: "0.5",
//               backgroundColor: "#17a2b8",
//             }}
//             disabled
//           >
//             <span style={{ fontSize: "24px" }}>Quiz</span>
//           </button>
//         )}
//       </div>
//       {!isMobile && (
//         <>
//           <img
//             src={"mental.gif"}
//             alt=""
//             style={{
//               position: "absolute",
//               bottom: "120px",
//               left: "70px",
//               width: "350px",
//               height: "350px",
//               boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.75)",
//             }}
//           />
//         </>
//       )}
//       <img
//         src={"hello_welcome.png"}
//         alt=""
//         style={{
//           position: "absolute",
//           bottom: "200px",
//           left: isMobile ? "0px" : "500px",
//           right: isMobile ? "50px" : "0px",
//           width: isMobile ? "100%" : "500px",
//           height: isMobile ? "auto" : "250px",
//           maxHeight: "250px",
//           margin: "20px",
//           padding: "10px",
//           boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.75)",
//         }}
//       />
//       <p
//         style={{
//           position: "absolute",
//           top: "600px",
//           width: "100%",
//           textAlign: "center",
//           fontSize: "18px",
//           color: "#666",
//           fontFamily: '"Libre Baskerville", serif',
//           padding: "20px",
//           backgroundColor: "#D3D3D3",
//           borderRadius: "10px",
//           boxShadow: "4px 4px 4px 8px rgba(0, 0, 0, 0.5)",
//           border: "1px solid black",
//           margin: "5px 5px 5px 5px",
//           maxWidth: "100%",
//         }}
//       >
//         <strong>Disclaimer :</strong>
//         <br></br>
//         All the information published here is in good faith and for general
//         information purpose only. Every effort has been made to make sure that
//         the information presented here is accurate and correct but the website
//         (company) does not make any warranties about the completeness,
//         reliability and accuracy of this information. Any action you take upon
//         the information on this website, is strictly at your own risk. The
//         company will not be liable for any losses and/or damages in connection
//         with the use of the content. The information being shared here is in
//         good faith to enhance the knowledge and skills of students and is not
//         intended to hurt anyone’s feelings and emotions.
//       </p>
//     </div>
//   );
// };

// export default MiddleScreen;
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import "animate.css";

const MiddleScreen = () => {
  const userstate = useSelector((state) => state.loginUserReducer);
  const { currentUser1 } = userstate;

  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [showDisclaimer, setShowDisclaimer] = useState(true);
  const [showVideoPopup, setShowVideoPopup] = useState(true);
  const [showWelcome, setShowWelcome] = useState(true);
  const [blurred, setBlurred] = useState(true); // State to control blur effect

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    window.addEventListener("resize", handleResize);

    document.body.style.margin = "0";
    document.body.style.padding = "0";
    document.body.style.background =
      "radial-gradient(circle at 74.2% 50.9%, rgb(14, 72, 222) 5.2%, rgb(3, 22, 65) 75.3%)";
    document.body.style.backgroundSize = "cover";
    document.body.style.backgroundAttachment = "fixed";

    const timer = setTimeout(() => {
      setShowWelcome(false);
    }, 1000); // 5 seconds

    const blurTimer = setTimeout(() => {
      setBlurred(false); // Remove blur after 5 seconds
    }, 1000); // 5 seconds

    return () => {
      window.removeEventListener("resize", handleResize);
      clearTimeout(timer);
      clearTimeout(blurTimer);
      document.body.style.background = "";
    };
  }, []);

  return (
    <div
      style={{
        paddingTop: "50px",
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        overflowX: "hidden",
        position: "relative",
      }}
    >
      {/* Welcome Message */}
      {showWelcome && (
        <div
          style={{
            position: "absolute",
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: "1000",
            animation: "popIn 1s ease-out",
          }}
          className="animate__animated animate__zoomIn"
        >
          <div
            style={{
              fontFamily: '"Libre Baskerville", serif',
              fontSize: "10vw", // Increased font size for visibility
              fontWeight: "bold",
              textAlign: "center",
              textTransform: "uppercase",
              background: "linear-gradient(to right, #0074D9, #001f3f)", // Gradient from blue to dark blue
              WebkitBackgroundClip: "text", // Clip the background to text for gradient
              color: "transparent", // Text color is transparent to show the background gradient
              padding: "10px",
              letterSpacing: "5px",
              zIndex: "1000", // Ensure it's on top
            }}
          >
            <b>WELCOME</b>
          </div>
        </div>
      )}
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      {/* White Container behind Content with Blur Effect for 5 seconds */}
      <div
        style={{
          backgroundColor: "white", // Slightly transparent
          borderRadius: "10px",
          padding: "20px",
          boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)",
          maxWidth: "1200px",
          width: "100%",
          marginTop: "50px",
          filter: blurred ? "blur(5px)" : "none", // Apply blur effect only for 5 seconds
          transition: "filter 1s ease-out", // Smooth transition for blur effect
          zIndex: "1", // Ensure the blur effect is behind the "Welcome" message
        }}
      >
        {/* <center>
          <h2
            style={{
              color: "#ef0b0b",
              fontFamily: '"Libre Baskerville", serif',
              fontWeight: "600",
              marginBottom: "15px",
              justifyContent: "center",
              lineHeight: "1.4", // Reduced line spacing
            }}
          >
            BAUDDHA DAKSHTA HOME PAGE
          </h2>
        </center> */}
        {/* Main Content Area */}
        <div
          style={{
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
            alignItems: "center",
          }}
        >
          {/* Left Side with Image */}
          <div
            style={{
              position: "relative",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              flex: "1",
            }}
            className="animate__animated animate__fadeInLeft"
          >
            <center>
              <h2
                style={{
                  position: "absolute",
                  color: "#ef0b0b",
                  fontFamily: '"Libre Baskerville", serif',
                  fontWeight: "600",
                  marginBottom: "5px",
                  textAlign: "center",
                  lineHeight: "1.4", // Reduced line spacing
                  zIndex: 1,
                  top: "10%", // Adjust the vertical position
                  left: "50%", // Adjust the horizontal position
                  transform: "translate(-50%, -50%)", // Center the text
                  whiteSpace: "nowrap", // Prevent text from breaking into multiple lines
                }}
              >
                BAUDDHA DAKSHATA
              </h2>
            </center>
            <img
              src={"mental.gif"}
              alt="Mental Abilities"
              style={{
                width: "100%",
                maxWidth: "800px", // Increased image size
                marginBottom: "20px",
              }}
            />
          </div>

          {/* Right Side with Text */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: isMobile ? "center" : "flex-start",
              textAlign: isMobile ? "center" : "justify", // Justified text alignment
              flex: "1",
              paddingLeft: isMobile ? "0" : "40px",
              justifyContent: "center", // Vertically center the content
            }}
            className="animate__animated animate__fadeInRight"
          >
            <Link
              to={currentUser1 ? "/bauddhadakshata" : "/login"}
              className="btn btn-primary"
              style={{
                display: "inline-block",
                //backgroundColor: "#ef0b0b",
                color: "#fff",
                padding: "10px 20px",
                borderRadius: "5px",
                textDecoration: "none",
                fontWeight: "bold",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)",
              }}
            >
              {currentUser1 ? "Bauddha Dakshata Quiz" : "Login"}
            </Link>
            <br></br>
            <h2
              style={{
                color: "#0e003d",
                fontFamily: '"Libre Baskerville", serif',
                fontWeight: "600",
                marginBottom: "10px",
                textAlign: "center",
                lineHeight: "1.5", // Reduced line spacing
              }}
            >
              Welcome to Gyankosha!
            </h2>
            <div
              style={{
                fontFamily: '"Libre Baskerville", serif',
                fontSize: "17px",
                textAlign: "justify",
                lineHeight: "1.2",
                color: "#000000",
              }}
            >
              At Gyankosha's Bauddha Dakshata vertical, we specialize in
              nurturing Mental Abilities and Analytical Prowess to help students
              think critically, solve problems, and achieve academic success.
              <br></br>
            </div>
            <br></br>
            <strong>
              {" "}
              <div
                style={{
                  fontFamily: '"Libre Baskerville", serif',
                  fontSize: "20px",
                  color: "#000000",
                }}
              >
                Here's how we make it happen:
              </div>
            </strong>
            <div>
              <ul
                style={{
                  color: "#000000",
                  fontSize: "16px",
                  lineHeight: "1.4", // Reduced line spacing
                  listStyleType: "disc",
                  paddingLeft: "20px",
                  maxWidth: "500px",
                  fontFamily: "'Libre Baskerville', serif",
                }}
              >
                {/* <strong>
                {" "}
                At Gyankosha's Bauddha Dakshata vertical, we specialize in
                nurturing Mental Abilities and Analytical Prowess to help
                students think critically, solve problems, and achieve academic
                success.
              </strong> */}

                {/* Here's how we make it happen: */}
                <li>
                  <strong>
                    <b>Cognitive Skill Building:</b>
                  </strong>{" "}
                  Strengthening logical reasoning, analytical thinking, and
                  problem-solving.
                </li>
                <li>
                  <strong>
                    <b>Customized Programs:</b>
                  </strong>{" "}
                  Tailored modules to meet students' unique learning needs and
                  challenges.
                </li>
                <li>
                  <strong>
                    <b>Competitive Exam Readiness:</b>
                  </strong>{" "}
                  Equipping students with essential skills for success in every
                  exam and beyond.
                </li>
                <li>
                  <strong>
                    <b>Interactive Learning Tools:</b>
                  </strong>{" "}
                  Engaging puzzles and quizzes to make learning exciting and
                  fun.
                </li>
                <li>
                  <strong>
                    <b>Holistic Development:</b>
                  </strong>{" "}
                  Fostering creativity, adaptability, and confidence for
                  real-world challenges.
                </li>
                <li>
                  Unleash your potential with us and sharpen your mind to tackle
                  anything life throws your way!
                </li>
              </ul>
            </div>
            {/* <Link
              to={currentUser1 ? "/bauddhadakshata" : "/login"}
              style={{
                display: "inline-block",
                backgroundColor: "#ef0b0b",
                color: "#fff",
                padding: "10px 20px",
                borderRadius: "5px",
                textDecoration: "none",
                fontWeight: "bold",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)",
              }}
            >
              {currentUser1 ? "Bauddha Dakshata Quiz" : "Login"}
            </Link> */}
          </div>
        </div>
      </div>

      {/* Small YouTube Video Popup in Corner */}
      {showVideoPopup && (
        <div
          style={{
            position: "fixed",
            bottom: "60px",
            left: "20px",
            width: "200px",
            backgroundColor: "#fff",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            padding: "10px",
            zIndex: "1000",
          }}
          className="animate__animated animate__fadeIn"
        >
          <iframe
            width="180"
            height="100"
            src="https://www.youtube.com/embed/oJdGGgxl5XE?si=n1uXx-s0omRQtHv9"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            style={{
              borderRadius: "5px",
            }}
          ></iframe>
          <button
            onClick={() => setShowVideoPopup(false)}
            className="btn btn-primary"
            style={{
              //backgroundColor: "#ff4d4d",
              border: "none",
              color: "white",
              padding: "5px 10px",
              fontSize: "12px",
              cursor: "pointer",
              borderRadius: "5px",
              marginTop: "5px",
              width: "100%",
            }}
          >
            Close
          </button>
        </div>
      )}

      {/* Disclaimer */}
      {showDisclaimer && (
        <div
          style={{
            position: "fixed",
            bottom: "0",
            width: "100%",
            textAlign: "center",
            fontSize: "12px",
            color: "#666",
            fontFamily: "'Libre Baskerville', serif",
            padding: "15px 20px",
            backgroundColor: "#D3D3D3",
            borderTop: "1px solid black",
            boxShadow: "0px -2px 10px rgba(0, 0, 0, 0.2)",
            zIndex: "1000",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          className="animate__animated animate__fadeInUp"
        >
          <span style={{ maxWidth: "90%", textAlign: "left" }}>
            <strong>Disclaimer :</strong> All the information published here is
            in good faith and for general information purposes only. Every
            effort has been made to make sure that the information presented
            here is accurate and correct, but the website (company) does not
            make any warranties about the completeness, reliability, and
            accuracy of this information. Any action you take upon the
            information on this website is strictly at your own risk. The
            company will not be liable for any losses and/or damages in
            connection with the use of the content.
          </span>
          <button
            onClick={() => setShowDisclaimer(false)}
            style={{
              marginLeft: "20px",
              background: "transparent",
              border: "none",
              fontSize: "18px",
              cursor: "pointer",
              color: "#333",
            }}
            aria-label="Close Disclaimer"
          >
            &times;
          </button>
        </div>
      )}
    </div>
  );
};

export default MiddleScreen;
