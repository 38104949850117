import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import Error from "../components/Error";
import Loading from "../components/Loading";
import { getAllSchoolcodes } from "../actions/schoolcodeActions"; // Import the action
import {
  KBdeleteQuiz,
  KBupdateQuizEnabledStatus,
} from "../actions/kyebyequizActions";
import { Link } from "react-router-dom";

export default function KyeByeQuizsList() {
  const dispatch = useDispatch();
  const [quizzes, setQuizzes] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [hasMore, setHasMore] = useState(true);

  const schoolcodesstate = useSelector(
    (state) => state.getAllSchoolcodesReducer
  );
  const { schoolcodes } = schoolcodesstate;

  const userstate = useSelector((state) => state.loginUserReducer);
  const { currentUser1 } = userstate;

  const observer = useRef();
  const triggerRef = useRef();

  useEffect(() => {
    dispatch(getAllSchoolcodes()); // Fetch school codes on load
    fetchQuizzes(); // Fetch initial quizzes
  }, [dispatch]);

  useEffect(() => {
    if (page > 1) {
      fetchQuizzes();
    }
  }, [page]);

  const fetchQuizzes = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`/api/KyebyeQuiz/getKyebyeQuizsByPage`, {
        params: { page, limit: 10 }, // Fetch 10 quizzes at a time
      });

      const newQuizzes = response.data;
      if (newQuizzes.length === 0) {
        setHasMore(false);
      }

      setQuizzes((prevQuizzes) => [...prevQuizzes, ...newQuizzes]);
      setLoading(false);
    } catch (error) {
      setError("Failed to fetch quizzes.");
      setLoading(false);
    }
  };

  useEffect(() => {
    if (loading || !hasMore) return;

    const observerCallback = (entries) => {
      const [entry] = entries;
      if (entry.isIntersecting) {
        setPage((prevPage) => prevPage + 1);
      }
    };

    const options = {
      root: null,
      threshold: 1.0,
    };

    observer.current = new IntersectionObserver(observerCallback, options);

    if (triggerRef.current) {
      observer.current.observe(triggerRef.current);
    }

    return () => {
      if (observer.current && triggerRef.current) {
        observer.current.unobserve(triggerRef.current);
      }
    };
  }, [loading, hasMore]);

  const handleEnabledChange = async (quizId, schoolcode, enabled) => {
    if (!quizId || !schoolcode || typeof enabled !== "boolean") {
      alert(
        "Invalid data: Please check the quiz ID, school code, or enabled status."
      );
      return;
    }

    console.log("Toggling quiz status:", { quizId, schoolcode, enabled });

    try {
      // Send the enable/disable request to the server
      const response = await axios.post(`/api/KyebyeQuiz/updatekyebyeEnable`, {
        quizId,
        schoolcode,
        enabled,
      });

      console.log("Server response:", response.data);

      // Update the quiz list locally based on the toggle action
      setQuizzes((prevQuizzes) =>
        prevQuizzes.map((quiz) =>
          quiz._id === quizId
            ? {
                ...quiz,
                enabled: enabled
                  ? [...quiz.enabled, schoolcode] // Add school code if enabling
                  : quiz.enabled.filter((code) => code !== schoolcode), // Remove school code if disabling
              }
            : quiz
        )
      );

      // Show success alert
      alert(
        `Quiz has been successfully ${
          enabled ? "enabled" : "disabled"
        } for the selected school.`
      );
    } catch (error) {
      console.error(
        "Failed to update quiz enabled status:",
        error.response?.data || error.message
      );
      alert("Error updating quiz status. Please try again.");
    }
  };

  return (
    <div style={{ overflow: "visible", height: "auto" }}>
      <hr />
      <h1>Kyebye Quizzes List</h1>
      <hr />
      <div className="table-responsive" style={{ overflow: "visible" }}>
        {error && <Error error="Something went wrong" />}
        {loading && <Loading />}
        <table
          className="table table-bordered"
          style={{ borderColor: "#343a40", overflow: "visible" }}
        >
          <thead className="table-dark">
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Subject</th>
              <th>Class</th>
              <th>Category</th>
              <th>Division</th>
              {currentUser1.isAdmin ? <th>Enabled</th> : ""}
              {currentUser1.isAdmin || currentUser1.isSubAdminTwo ? (
                <th>Actions</th>
              ) : (
                ""
              )}
            </tr>
          </thead>
          <tbody>
            {quizzes.map((quiz) => (
              <tr key={quiz._id}>
                <td>{quiz._id}</td>
                <td>{quiz.name}</td>
                <td>{quiz.subject}</td>
                <td>{quiz.classs}</td>
                <td>{quiz.difficulty}</td>
                <td>{quiz.division}</td>
                {currentUser1.isAdmin ? (
                  <td>
                    {schoolcodes &&
                      schoolcodes.map((schoolcode) => (
                        <div key={schoolcode._id}>
                          <label>
                            <input
                              type="checkbox"
                              checked={quiz.enabled.includes(schoolcode.code)}
                              onChange={(e) =>
                                handleEnabledChange(
                                  quiz._id,
                                  schoolcode.code,
                                  e.target.checked
                                )
                              }
                            />
                            {schoolcode.school}
                          </label>
                        </div>
                      ))}
                  </td>
                ) : (
                  ""
                )}

                <td>
                  {currentUser1.isAdmin ? (
                    <i
                      className="fa fa-trash m1"
                      style={{ cursor: "pointer", marginRight: "10px" }}
                      onClick={() => dispatch(KBdeleteQuiz(quiz._id))}
                    ></i>
                  ) : null}
                  {currentUser1.isAdmin || currentUser1.isSubAdminTwo ? (
                    <>
                      <Link to={`/adminpanel/Kyebyeeditquiz/${quiz._id}`}>
                        <i
                          className="fa fa-edit m1"
                          style={{ cursor: "pointer" }}
                        ></i>
                      </Link>
                    </>
                  ) : null}
                  {currentUser1.isAdmin || currentUser1.isSubAdminTwo ? (
                    <>
                      <Link
                        to={`/adminpanel/updateAllQuestionPassages/${quiz._id}`}
                      >
                        <i
                          className="fa fa-check m1"
                          style={{ cursor: "pointer" }}
                        ></i>
                      </Link>
                    </>
                  ) : null}
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {/* Trigger div at the bottom of the page */}
        <div
          ref={triggerRef}
          style={{
            height: "20px",
            backgroundColor: "lightgray", // Make it visible
            marginTop: "20px",
            textAlign: "center",
          }}
        >
          {loading && hasMore
            ? "Loading more quizzes..."
            : "No more quizzes to display"}
        </div>
      </div>
    </div>
  );
}
