import React, { useState } from "react";

const AddGallery = () => {
  const [title, setTitle] = useState("");
  const [mediaFile, setMediaFile] = useState(null);
  const [mediaType, setMediaType] = useState("image");
  const [videoUrl, setVideoUrl] = useState(""); // New state for video URL
  const [division, setDivision] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      !title ||
      (!mediaFile && mediaType === "image") ||
      (!videoUrl && mediaType === "video") ||
      !division
    ) {
      setError("Please fill out all required fields.");
      return;
    }

    setLoading(true);

    const formData = new FormData();
    formData.append("title", title);
    formData.append("mediaType", mediaType);
    formData.append("division", division);

    if (mediaType === "image") {
      formData.append("mediaFile", mediaFile);
    } else if (mediaType === "video") {
      formData.append("videoUrl", videoUrl);
    }

    try {
      const response = await fetch("/api/gallery/addgallery", {
        method: "POST",
        body: formData,
      });

      if (!response.ok) {
        throw new Error("Failed to add gallery item.");
      }

      setTitle("");
      setMediaFile(null);
      setVideoUrl(""); // Reset video URL
      setMediaType("image");
      setDivision("");
      setError("");
      alert("Gallery item added successfully!");
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="add-gallery-container">
      <h2>Add Gallery Item</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="title">Title:</label>
          <input
            type="text"
            id="title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />
        </div>

        {mediaType === "image" && (
          <div className="form-group">
            <label htmlFor="mediaFile">Media File:</label>
            <input
              type="file"
              id="mediaFile"
              accept="image/*"
              onChange={(e) => setMediaFile(e.target.files[0])}
              required
            />
          </div>
        )}

        {mediaType === "video" && (
          <div className="form-group">
            <label htmlFor="videoUrl">Video URL:</label>
            <input
              type="text"
              id="videoUrl"
              value={videoUrl}
              onChange={(e) => setVideoUrl(e.target.value)}
              placeholder="Enter video URL (e.g., YouTube link)"
              required
            />
          </div>
        )}

        <div className="form-group">
          <label htmlFor="mediaType">Media Type:</label>
          <select
            id="mediaType"
            value={mediaType}
            onChange={(e) => setMediaType(e.target.value)}
          >
            <option value="image">Image</option>
            <option value="video">Video</option>
          </select>
        </div>

        <div className="form-group">
          <label htmlFor="division">Division:</label>
          <select
            id="division"
            value={division}
            onChange={(e) => setDivision(e.target.value)}
            required
          >
            <option value="select value">select value</option>
            <option value="Student">Student</option>
            <option value="Teacher">Teacher</option>
            <option value="Parent">Parent</option>
            <option value="Gallery">Gallery</option>
          </select>
        </div>

        {error && <div className="error-message">{error}</div>}
        {loading && <div className="loading-message">Loading...</div>}

        <button type="submit" disabled={loading}>
          {loading ? "Submitting..." : "Add Item"}
        </button>
      </form>

      <style jsx>{`
        .add-gallery-container {
          background-color: #f7f9fc;
          padding: 20px;
          border-radius: 8px;
          max-width: 500px;
          margin: auto;
          box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        }

        h2 {
          text-align: center;
          color: #333;
        }

        .form-group {
          margin-bottom: 15px;
        }

        label {
          display: block;
          margin-bottom: 5px;
          font-weight: bold;
          color: #555;
        }

        input,
        select {
          width: 100%;
          padding: 10px;
          border: 1px solid #ccc;
          border-radius: 4px;
        }

        input:focus,
        select:focus {
          border-color: #007bff;
          outline: none;
        }

        .error-message {
          color: #d9534f;
          margin-bottom: 10px;
          font-weight: bold;
        }

        .loading-message {
          color: rgb(85, 1, 5);
          margin-bottom: 10px;
        }

        button {
          width: 100%;
          padding: 10px;
          background-color: rgb(31, 0, 71);
          color: white;
          border: none;
          border-radius: 4px;
          cursor: pointer;
        }

        button:disabled {
          background-color: #999;
        }

        button:hover:not(:disabled) {
          background-color: rgb(3, 12, 82);
        }
      `}</style>
    </div>
  );
};

export default AddGallery;
