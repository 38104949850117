import React, { useState, useRef, useEffect } from "react";
import LearningAsTeacher from "./LearningAsTeacher";
import TeachersGallery from "./Teachersgallery";
import FooterInfo from "./FooterInfo";
import { Link } from "react-router-dom";

const styles = {
  navbar: {
    backgroundColor: "#0e003d",
    color: "white",
    textAlign: "center",
    padding: "30px",
    position: "sticky",
    top: 0,
    zIndex: 1,
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
  },
  navbarTitle: {
    fontSize: "1.8rem",
    fontWeight: "bold",
    margin: 0,
    textShadow: "2px 2px 4px rgba(0, 0, 0, 0.3)",
    fontFamily: '"Libre Baskerville", serif',
  },
  container: {
    display: "flex",
    flexDirection: "column",
    fontFamily: '"Libre Baskerville", serif',
    fontWeight: "bolder",
    alignItems: "center",
    overflowX: "hidden",
    backgroundColor: "#ffffff",
    padding: "20px",
    marginBottom: "5vh",
  },
  mainContent: {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    padding: "20px",
  },
  imageSection: {
    display: "flex",
    width: "50vw",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "right",
    gap: "20px",
    paddingTop: "5vh",
  },
  imageContainer: {
    display: "flex",
    flexDirection: "column",
    width: "20vw",
    height: "auto",
    gap: "10px",
    alignItems: "center",
    width: "15vw",
    height: "20vw",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
  },
  image: {
    width: "100%",
    height: "30vh",
    objectFit: "cover",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
  },
  textSection: {
    flex: 1,
    textAlign: "justify",
    height: "auto",
    padding: "5vw",
    width: "100%",
    fontSize: "1.5rem",
    color: "black",
  },
  programTitle: {
    fontSize: "1.5rem",
    color: "#0e003d",
    display: "block",
    marginBottom: "10px",
  },
  header: {
    paddingTop: "5vh",
    paddingLeft: "10px",
    textAlign: "left",
    marginBottom: "20px",
    width: "50vw",
  },
  title: {
    fontSize: "24px",
    fontWeight: "bold",
    color: "black",
  },
  subtitle: {
    fontSize: "1.8rem",
    fontWeight: "bolder",
    color: "#0e003d",
  },
  contentBoxes: {
    padding: "5vh",
    height: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "20px",
    marginTop: "30px",
    overflow: "hidden",
    background: "white",
    width: "100vw",
  },
  contentBox: {
    width: "60%",
    height: "50%",
    background:
      "linear-gradient(to left, rgb(251, 252, 252), #e0f7fa, rgb(128, 206, 234))",
    color: "#0e003d",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    padding: "20px",
    borderRadius: "10px",
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
    opacity: 0,
    transform: "translateY(50px)",
    transition: "all 0.8s ease-in-out",
  },
  contentBoxVisible: {
    opacity: 2,
    transform: "translateY(0)",
  },
  contentBoxLeft: {
    alignSelf: "flex-start",
    transform: "translateX(-100px)",
  },
  contentBoxRight: {
    alignSelf: "flex-end",
    transform: "translateX(100px)",
  },
  contentBoxText: {
    fontWeight: "600",
    fontSize: window.innerWidth <= 1000 ? "1.8rem" : "2.3rem",
    lineHeight: "1.5",
    justifyContent: "center",
    textAlign: "center",
    zIndex: 2,
    lineHeight: 1.2,
    padding: "1.5rem",
    position: "fit",
  },
  overlay: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "0%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.4)",
    borderRadius: "10px",
    zIndex: 1,
  },
  videoModal: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.7)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 10,
  },
  videoContent: {
    backgroundColor: "white",
    padding: "20px",
    borderRadius: "10px",
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)",
    position: "relative",
  },
  closeButton: {
    position: "absolute",
    top: "10px",
    right: "10px",
    fontSize: "30px",
    background: "none",
    border: "none",
    cursor: "pointer",
  },
  actionButton: {
    backgroundColor: "#0e003d",
    color: "white",
    padding: "10px 20px",
    border: "none",
    marginTop: "5vh",
    fontSize: "1.5rem",
    borderRadius: "5px",
    cursor: "pointer",
  },
  galleryContainer: {
    display: "flex",
    overflowX: "hidden",
    gap: "20px",
    padding: "20px",
    width: "100%",
  },
  galleryItem: {
    minWidth: "300px",
    height: "200px",
    borderRadius: "8px",
    overflow: "hidden",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  "@media (max-width: 1200px)": {
    container: {
      padding: "20px",
    },
    imageSection: {
      width: "70vw",
    },
    imageContainer: {
      width: "40vw",
    },
    textSection: {
      fontSize: "1.2rem",
      padding: "4vw",
    },
    contentBoxes: {
      padding: "4vh",
    },
    contentBox: {
      width: "60%",
    },
  },
  "@media (max-width: 900px)": {
    container: {
      padding: "10px",
    },
    imageSection: {
      width: "80vw",
    },
    imageContainer: {
      width: "45vw",
    },
    textSection: {
      fontSize: "1rem",
      padding: "3vw",
    },

    contentBoxes: {
      padding: "3vh",
    },
    contentBox: {
      width: "80%",
    },
  },
  "@media (max-width: 600px)": {
    container: {
      padding: "10px",
    },

    imageSection: {
      width: "100vw",
    },
    imageContainer: {
      width: "70vw",
    },
    textSection: {
      fontSize: "1rem",
      padding: "2vw",
    },
    contentBoxes: {
      padding: "2vh",
    },
    contentBox: {
      width: "100%",
    },
  },
};

function VideoModal({ isOpen, onClose }) {
  if (!isOpen) return null;

  const handleBackgroundClick = (e) => {
    // Close only if the click is outside the video content
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <div
      style={styles.videoModal}
      onClick={handleBackgroundClick} // Close on clicking outside the video content
    >
      <div style={styles.videoContent}>
        <button style={styles.closeButton} onClick={onClose}>
          &times;
        </button>
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/TkMIcy0oXvo?si=o4ioAz4fqjwBaUai"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  );
}

function ContentBoxes() {
  const boxes = [
    " Innovative Teaching Methods",
    "                Technology Integration",
    "  Classroom Management",
    // "Engaging and Interactive Approach: Real Life Scenarios and Actionable Takeaways",
    " Personalized Learning",
  ];

  const [visibleBoxes, setVisibleBoxes] = useState([]);
  const boxRefs = useRef([]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setVisibleBoxes((prev) => {
              const index = Number(entry.target.dataset.index);
              return prev.includes(index) ? prev : [...prev, index];
            });
          }
        });
      },
      { threshold: 0.2 }
    );

    boxRefs.current.forEach((box) => observer.observe(box));

    return () => observer.disconnect();
  }, []);

  return (
    <div style={styles.contentBoxes}>
      {boxes.map((text, index) => (
        <div
          key={index}
          data-index={index}
          ref={(el) => (boxRefs.current[index] = el)}
          style={{
            ...styles.contentBox,
            ...(visibleBoxes.includes(index) && styles.contentBoxVisible),
            ...(index % 2 === 0
              ? {
                  ...styles.contentBoxLeft,
                  ...(visibleBoxes.includes(index) && {
                    transform: "translateX(0)",
                  }),
                }
              : {
                  ...styles.contentBoxRight,
                  ...(visibleBoxes.includes(index) && {
                    transform: "translateX(0)",
                  }),
                }),
          }}
        >
          <div style={styles.overlay}></div>
          <h3 style={styles.contentBoxText}>{text}</h3>
        </div>
      ))}
    </div>
  );
}

function TeacherTraining() {
  const [isVideoOpen, setIsVideoOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const updateIsMobile = () => {
    setIsMobile(window.innerWidth <= 1000);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Set up event listener to handle window resize
  useEffect(() => {
    updateIsMobile(); // Check on initial render
    window.addEventListener("resize", updateIsMobile); // Add event listener for resize
    return () => {
      window.removeEventListener("resize", updateIsMobile); // Cleanup listener on unmount
    };
  }, []);

  const handleButtonClick = () => {
    document
      .getElementById("FooterInfo")
      ?.scrollIntoView({ behavior: "smooth" });
  };
  const subtitleFontSize =
    window.innerWidth < 600
      ? "1rem"
      : window.innerWidth < 900
      ? "1.7rem"
      : "1.5rem";

  const responsiveStyles = {
    imageSection: {
      flexDirection: isMobile ? "column" : "row",
      width: isMobile ? "" : "50vw",
      overflow: "hidden",
    },
    navbarStyle: {
      position: "relative",
      display: "flex", // Use flexbox for layout
      justifyContent: "space-between", // Space out heading and button
      alignItems: "center", // Align items vertically
      backgroundColor: "#0e003d",
      color: "white",
      width: "100%",
      padding: "15px 20px",
      fontFamily: '"Libre Baskerville", serif',
      fontWeight: "bolder",
      boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
      flexDirection: "row", // Default for larger screens
    },
    moreArticlesButtonStyle: {
      fontSize: "16px",
      padding: "10px 20px",
      backgroundColor: "rgb(40, 9, 92)",
      color: "#fff",
      border: "none",
      position: "absolute", // Position relative to the containing div
      top: "50%", // Center vertically within the navbar
      right: "20px", // Adjust for the right corner
      transform: "translateY(-50%)", // Vertical alignment
      borderRadius: "4px",
      cursor: "pointer",
      textDecoration: "none",
      zIndex: 1, // Keeps it above other elements
      transition: "background-color 0.3s ease",
    },

    container: {
      padding: isMobile ? "0px" : "20px",
      marginBottom: isMobile ? "0vh" : "5vh",
    },
    imageContainer: {
      width: isMobile ? "100%" : "15vw",
      justifyContent: "center",
    },
    title: {
      fontSize: "24px",
      fontWeight: "bold",
      color: isMobile ? "grey" : "black",
    },
    subtitle: {
      // fontSize: isMobile ? "1.5rem" : "32px",
      fontWeight: "bolder",
      color: isMobile ? "white" : "#0e003d",
    },
    textSection: {
      fontSize: isMobile ? "1rem" : "1.5rem",
      padding: isMobile ? "2vw" : "5vw",
    },
    header: {
      paddingLeft: isMobile ? "" : "10px",
      width: isMobile ? "auto" : "50vw",
      alignItems: isMobile ? "center" : "",
      textAlign: isMobile ? "" : "left",
    },
    subtitle: {
      fontSize: subtitleFontSize,
    },
    actionButton: {
      fontSize: isMobile ? "1rem" : "1.5rem",
      padding: isMobile ? "8px 16px" : "10px 20px",
    },
  };
  const mobileStyles = {
    navbarStyle: {
      flexDirection: "column", // Stack items vertically
      alignItems: "flex-start", // Align items to the left
      padding: "10px 15px",
    },
    moreArticlesButtonStyle: {
      fontSize: "14px",
      padding: "8px 12px",
      position: "relative", // Move to relative for better stacking
      top: "auto", // Reset top position
      right: "auto", // Reset right position
      transform: "none", // Remove transformation
      marginTop: "10px", // Add margin for spacing
    },
  };

  return (
    <div
      style={{
        background: `
linear-gradient(to left, rgb(75, 47, 197),rgb(106, 164, 251), rgb(7, 129, 190)),
    linear-gradient(to top, #ffffff,#ffffff,rgb(251, 252, 252),rgba(251, 252, 252, 0.61), rgba(255, 255, 255, 0.21), rgba(255, 255, 255, 0))
  `,
        backgroundBlendMode: "overlay",
        padding: "5vw",
        paddingBottom: "0vw",
        overflowX: "hidden",
      }}
    >
      <div style={styles.container}>
        <div style={styles.mainContent}>
          <div
            style={{
              ...styles.container,
              flexDirection: isMobile ? "column" : "row",
              marginBottom: isMobile ? "0vh" : "5vh",
              height: "auto",
            }}
          >
            {window.innerWidth < 1000 ? (
              <div
                style={{
                  ...responsiveStyles.imageContainer,
                }}
              >
                <img
                  src="./wa4.jpeg"
                  alt="Student Group Activity"
                  style={{
                    ...responsiveStyles.image,
                    height: "40vh",
                  }}
                />
                <div
                  style={{
                    ...styles.header,
                    ...responsiveStyles.header,
                  }}
                >
                  <h1 style={styles.title}>GYANKOSHA presents</h1>
                  <h2
                    style={{
                      ...styles.subtitle,
                      ...responsiveStyles.subtitle,
                    }}
                  >
                    21st CENTURY EDUCATOR PROGRAM:
                    <br /> Teachers Training program
                  </h2>
                </div>
              </div>
            ) : (
              <>
                <div
                  style={{
                    ...styles.imageSection,
                    ...responsiveStyles.imageSection,
                  }}
                >
                  <div
                    style={{
                      ...styles.imageContainer,
                      ...responsiveStyles.imageContainer,
                      padding: "0px",
                    }}
                  >
                    <img
                      src="./teachingg3.png"
                      alt="Classroom 1"
                      style={styles.image}
                    />
                    <img
                      src="./teachingg2.png"
                      alt="Classroom 2"
                      style={styles.image}
                    />
                  </div>
                  <div
                    style={{
                      ...styles.imageContainer,
                      ...responsiveStyles.imageContainer,
                    }}
                  >
                    <img
                      src="/teachingg.jpg"
                      alt="Student Group Activity"
                      style={{
                        ...styles.image,
                        height: isMobile ? "40vh" : "82vh",
                      }}
                    />
                  </div>
                </div>
                <div style={{ ...styles.header }}>
                  <h1 style={styles.title}>GYANKOSHA presents</h1>

                  <h2 style={styles.subtitle}>
                    21st CENTURY EDUCATOR PROGRAM:
                    <br /> Teachers Training program
                  </h2>
                </div>
              </>
            )}
          </div>
        </div>
        <div style={{ ...styles.textSection, ...responsiveStyles.textSection }}>
          <strong style={styles.programTitle}>
            Gyankosha’s 21st Century Educator Program
          </strong>
          <h3
            style={{
              fontWeight: "bold",
              marginBottom: "0px",
              fontSize: isMobile ? "1.5rem" : "2.0rem",
            }}
          >
            Ensuring teachers and schools to create a positive ripple effect,
            leading to improved academic outcomes and a more dynamic learning
            environment.
          </h3>
          <ul
            style={{
              fontSize: isMobile ? "1rem" : "1.5rem",
              padding: isMobile ? "2vw" : "5vw",
              textAlign: "justify",
            }}
          >
            <li>
              Crafted to empower educators with modern teaching methodologies,
              classroom management techniques, and technology integration
              skills.
            </li>
            <li>
              Delivered in partnership with schools, these programs focus on
              enhancing teacher effectiveness and fostering innovation in the
              classroom.
            </li>
            <li>
              Gyankosha provides hands-on workshops, interactive sessions, and
              expert guidance to help teachers build confidence, improve student
              engagement, and stay ahead in a rapidly evolving educational
              landscape.
            </li>
            <li>Equipping teachers with practical tools and strategies</li>
          </ul>
          {/* Buttons */}

          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              setIsVideoOpen(true);
            }}
            style={{
              color: "#0e003d",
              textDecoration: "underline",
              padding: "5vh",
              fontSize: isMobile ? "1rem" : "1.8rem",
            }}
          >
            Watch Video
          </a>
          <br />
          <button
            style={{ ...styles.actionButton, ...responsiveStyles.actionButton }}
            onClick={handleButtonClick}
          >
            Question? Contact Us
          </button>
          <div
            style={{
              ...styles.container,
              ...responsiveStyles.textSection,

              fontSizeAdjust: ".250",
            }}
          >
            <ContentBoxes />
          </div>
        </div>
        <div
          style={{
            ...(window.innerWidth <= 600
              ? { ...responsiveStyles.navbarStyle, ...mobileStyles.navbarStyle }
              : responsiveStyles.navbarStyle),
            background: "rgba(3, 2, 51, 0.95)",
          }}
        >
          <strong style={{ fontSize: "1.8rem", padding: "0px", flex: "1" }}>
            LEARNING AS A TEACHER
          </strong>
          <Link
            to="/articlecardpage?division=Teacher"
            style={{
              ...responsiveStyles.moreArticlesButtonStyle,
              ...mobileStyles.moreArticlesButtonStyle,
            }}
            onMouseEnter={(e) => {
              e.currentTarget.style.backgroundColor = "rgba(3, 2, 51, 0.95)";
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.backgroundColor = "rgb(2, 205, 251)";
            }}
            onClick={(e) => {
              e.currentTarget.style.backgroundColor = "rgba(5, 4, 70, 1)";
            }}
          >
            →
          </Link>
        </div>
        <div
          style={{
            ...styles.galleryContainer,
            overflowX: "scroll",
            width: "100%",
          }}
        >
          <LearningAsTeacher />
        </div>
        <div style={{ ...styles.galleryContainer }}>
          <TeachersGallery />
        </div>
        <div style={{ width: "100vw" }}>
          {" "}
          <FooterInfo id="FooterInfo" />
        </div>{" "}
      </div>

      <VideoModal isOpen={isVideoOpen} onClose={() => setIsVideoOpen(false)} />
    </div>
  );
}

export default TeacherTraining;
