// import React, { useEffect, useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { getAllQuizs } from "../actions/quizActions";
// import { getAllDifficultys } from "../actions/difficultyActions";
// import Quiz from "../components/Quiz";
// import Loading from "../components/Loading";
// import Error from "../components/Error";
// import Pagination from "../components/Pagination";
// import { Link } from "react-router-dom";
// import axios from "axios"; // Import axios
// import Paymentplansscreen from "./Paymentplansscreen";

// function Bdscreen() {
//   const dispatch = useDispatch();
//   const quizsState = useSelector((state) => state.getAllQuizsReducer);
//   const userLogin = useSelector((state) => state.loginUserReducer);
//   const { currentUser1 } = userLogin;
//   const { quizs, error, loading } = quizsState;

//   const difficultysState = useSelector(
//     (state) => state.getAllDifficultysReducer
//   );
//   const { difficultys } = difficultysState;

//   const [selectedDifficulty, setSelectedDifficulty] = useState("Premium Quiz");
//   const [selectedSubject, setSelectedSubject] = useState("All");
//   const [currentPage, setCurrentPage] = useState(1);
//   const [quizzesPerPage] = useState(6);
//   const [userData, setUserData] = useState(null); // State to store fetched user data
//   const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);

//   useEffect(() => {
//     const handleResize = () => {
//       setIsMobile(window.innerWidth <= 767);
//     };

//     window.addEventListener("resize", handleResize);
//     return () => {
//       window.removeEventListener("resize", handleResize);
//     };
//   }, []);

//   useEffect(() => {
//     dispatch(getAllQuizs());
//     dispatch(getAllDifficultys()); // Fetch difficulties when component mounts
//   }, [dispatch]);

//   useEffect(() => {
//     setCurrentPage(1); // Reset currentPage to 1 whenever a filter is applied
//   }, [selectedDifficulty, selectedSubject]);

//   // Fetch user data by ID
//   useEffect(() => {
//     const fetchUserData = async () => {
//       try {
//         const { data } = await axios.get(
//           `/api/users/getuserbyid/${currentUser1._id}`
//         );
//         setUserData(data);
//       } catch (error) {
//         console.error("Error fetching user data", error);
//       }
//     };

//     if (currentUser1) {
//       fetchUserData();
//     }
//   }, []);

//   // New function to update difficulty
//   const updateDifficulty = (difficulty) => {
//     setSelectedDifficulty(difficulty);
//   };

//   const filteredQuizzes = userData
//     ? quizs.filter(
//         (quiz) =>
//           (quiz.enabled.includes(userData.schoolcode) ||
//             quiz.enabled.includes(userData.email)) && // Check if user's schoolcode is in quiz's enabled list
//           quiz.difficulty === selectedDifficulty &&
//           (selectedSubject === "All" || quiz.subject === selectedSubject) &&
//           (quiz.classs === userData.classs ||
//             quiz.enabled.includes(userData.email)) &&
//           quiz.division === "Bauddha Dakshata"
//       )
//     : [];

//   const filteredQuizzesBasedOnPaidStatus = filteredQuizzes.filter((quiz) => {
//     if (userData && !userData.paid) {
//       return (
//         (selectedDifficulty === "All" && quiz.difficulty === "General Quiz") ||
//         quiz.difficulty === "General Quiz"
//       );
//     }
//     return true;
//   });

//   const uniqueSubjects = [
//     ...new Set(filteredQuizzes.map((quiz) => quiz.subject)),
//   ];

//   const paginate = (pageNumber) => setCurrentPage(pageNumber);

//   return (
//     <div className="container mt-4" style={{ paddingTop: "70px" }}>
//       <div className="row justify-content-center">
//         <div
//           className="col-md-12 d-flex justify-content-between mb-3"
//           style={{ backgroundColor: "black", padding: "10px" }}
//         >
//           <div>
//             <label style={{ marginRight: "10px", color: "white" }}>
//               Filter by Category:
//             </label>
//             <select
//               value={selectedDifficulty}
//               onChange={(e) => {
//                 setSelectedDifficulty(e.target.value);
//                 setSelectedSubject("All");
//               }}
//               className="form-control"
//               style={{
//                 width: "auto",
//                 display: "inline-block",
//                 backgroundColor: "red",
//                 color: "white",
//               }}
//             >
//               {difficultys &&
//                 difficultys.map((diff) => (
//                   <option key={diff._id} value={diff.diff}>
//                     {diff.diff}
//                   </option>
//                 ))}
//             </select>
//           </div>

//           {!isMobile ? (
//             <div>
//               <Link to={currentUser1.paid ? "/dashboard" : "/bauddhadakshata"}>
//                 <button
//                   className="btn btn-primary"
//                   style={{ backgroundColor: "red", color: "white" }}
//                   disabled={!currentUser1.paid}
//                 >
//                   Dashboard
//                 </button>
//               </Link>
//             </div>
//           ) : (
//             <></>
//           )}

//           <div>
//             <label style={{ marginRight: "10px", color: "white" }}>
//               Filter by Subject:
//             </label>
//             <select
//               value={selectedSubject}
//               onChange={(e) => setSelectedSubject(e.target.value)}
//               className="form-control"
//               style={{
//                 width: "auto",
//                 display: "inline-block",
//                 backgroundColor: "red",
//                 color: "white",
//               }}
//             >
//               <option value="All">All</option>
//               {uniqueSubjects.map((subject) => (
//                 <option key={subject} value={subject}>
//                   {subject}
//                 </option>
//               ))}
//             </select>
//           </div>
//         </div>
//         {loading ? (
//   <Loading />
// ) : error ? (
//   <Error error="Something went wrong" />
// ) : quizs && quizs.length > 0 ? ( // Ensure the quiz data is fetched
//   filteredQuizzes.length > 0 ? (
//     filteredQuizzes
//       .reverse()
//       .slice(
//         (currentPage - 1) * quizzesPerPage,
//         currentPage * quizzesPerPage
//       )
//       .map((quiz) => (
//         <div className="col-md-3 m-3" key={quiz._id.$oid}>
//           <Quiz
//             quiz={quiz}
//             userId={userData._id}
//             userName={userData.name}
//             userSchoolcode={userData.schoolcode}
//             userClass={userData.classs}
//             userSection={userData.section}
//             userPaid={userData.paid}
//             attemptedQuizIds={userData.attemptedquizs.map((q) => q._id)}
//             completedQuizIds={userData.completedquizs.map((q) => q._id)}
//             updateDifficulty={updateDifficulty} // Pass the function
//           />
//         </div>
//       ))
//   ) : (
//     <div className="col-md-12 text-center">
//       <p className="text-danger">
//         No Quizzes Found for your class and selected filters.
//       </p>
//     </div>
//   )
// ) : (
//   <div className="col-md-12 text-center">
//     <p className="text-danger">No Quizzes Available.</p>
//   </div>
// )}

//         {filteredQuizzes.length > quizzesPerPage && (
//           <div className="col-md-12 mt-4">
//             <Pagination
//               quizzesPerPage={quizzesPerPage}
//               totalQuizzes={filteredQuizzesBasedOnPaidStatus.length}
//               currentPage={currentPage}
//               paginate={paginate}
//             />
//           </div>
//         )}
//       </div>
//     </div>
//   );
// }

// export default Bdscreen;
//////////////////////////////////////////////////////////////////////////////
// import React, { useEffect, useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { getAllDifficultys } from "../actions/difficultyActions";
// import Quiz from "../components/Quiz";
// import Loading from "../components/Loading";
// import Error from "../components/Error";
// import Pagination from "../components/Pagination";
// import { Link } from "react-router-dom";
// import axios from "axios"; // Import axios
// import Paymentplansscreen from "./Paymentplansscreen";

// function Bdscreen() {
//   const dispatch = useDispatch();
//   const userLogin = useSelector((state) => state.loginUserReducer);
//   const { currentUser1 } = userLogin;
//   const difficultysState = useSelector(
//     (state) => state.getAllDifficultysReducer
//   );
//   const { difficultys } = difficultysState;

//   const [selectedDifficulty, setSelectedDifficulty] = useState("All");
//   const [selectedSubject, setSelectedSubject] = useState("All");
//   const [currentPage, setCurrentPage] = useState(1);
//   const [quizzesPerPage] = useState(6);
//   const [userData, setUserData] = useState(null); // State to store fetched user data
//   const [filteredQuizzes, setFilteredQuizzes] = useState([]); // Store filtered quizzes
//   const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState(null);
//   const [showNoQuizzesMessage, setShowNoQuizzesMessage] = useState(false); // For delayed message display

//   useEffect(() => {
//     const handleResize = () => {
//       setIsMobile(window.innerWidth <= 767);
//     };

//     window.addEventListener("resize", handleResize);
//     return () => {
//       window.removeEventListener("resize", handleResize);
//     };
//   }, []);

//   useEffect(() => {
//     dispatch(getAllDifficultys()); // Fetch difficulties when component mounts
//   }, [dispatch]);

//   useEffect(() => {
//     setCurrentPage(1); // Reset currentPage to 1 whenever a filter is applied
//   }, [selectedDifficulty, selectedSubject]);

//   // Fetch user data by ID
//   useEffect(() => {
//     const fetchUserData = async () => {
//       try {
//         const { data } = await axios.get(
//           `/api/users/getuserbyid/${currentUser1._id}`
//         );
//         console.log("data bd users : ", data);
//         setUserData(data);
//       } catch (error) {
//         console.error("Error fetching user data", error);
//       }
//     };

//     if (currentUser1) {
//       fetchUserData();
//     }
//   }, [currentUser1]);

//   // Fetch filtered quizzes from the backend
//   useEffect(() => {
//     const fetchFilteredQuizzes = async () => {
//       if (userData) {
//         setLoading(true);
//         setError(null);
//         setShowNoQuizzesMessage(false); // Reset the no quizzes message
//         try {
//           const { data } = await axios.get("/api/quizs/getfilteredquizs", {
//             params: {
//               difficulty: selectedDifficulty,
//               subject: selectedSubject,
//               classs: userData.classs,
//               schoolcode: userData.schoolcode,
//               email: userData.email,
//               division: "Bauddha Dakshata",
//             },
//           });
//           console.log("data bd quizs : ", data);
//           setFilteredQuizzes(data);
//         } catch (error) {
//           setError("Error fetching filtered quizzes");
//           console.error("Error fetching filtered quizzes", error);
//         } finally {
//           setLoading(false);
//         }

//         // Set a timeout to display the no quizzes message after 5 seconds
//         setTimeout(() => {
//           if (filteredQuizzes.length === 0) {
//             setShowNoQuizzesMessage(true);
//           }
//         }, 5000);
//       }
//     };

//     fetchFilteredQuizzes();
//   }, [selectedDifficulty, selectedSubject, userData]);

//   // New function to update difficulty
//   const updateDifficulty = (difficulty) => {
//     setSelectedDifficulty(difficulty);
//   };

//   const paginate = (pageNumber) => setCurrentPage(pageNumber);

//   const uniqueSubjects = [
//     ...new Set(filteredQuizzes.map((quiz) => quiz.subject)),
//   ];

//   return (
//     <div className="container mt-4" style={{ paddingTop: "70px" }}>
//       <div className="row justify-content-center">
//         <div
//           className="col-md-12 d-flex justify-content-between mb-3"
//           style={{ backgroundColor: "black", padding: "10px" }}
//         >
//           <div>
//             <label style={{ marginRight: "10px", color: "white" }}>
//               Filter by Category:
//             </label>
//             <select
//               value={selectedDifficulty}
//               onChange={(e) => {
//                 setSelectedDifficulty(e.target.value);
//                 setSelectedSubject("All");
//               }}
//               className="form-control"
//               style={{
//                 width: "auto",
//                 display: "inline-block",
//                 backgroundColor: "red",
//                 color: "white",
//               }}
//             >
//               {difficultys &&
//                 difficultys.map((diff) => (
//                   <option key={diff._id} value={diff.diff}>
//                     {diff.diff}
//                   </option>
//                 ))}
//             </select>
//           </div>

//           {!isMobile ? (
//             <div>
//               <Link to={currentUser1.paid ? "/dashboard" : "/bauddhadakshata"}>
//                 <button
//                   className="btn btn-primary"
//                   style={{ backgroundColor: "red", color: "white" }}
//                   disabled={!currentUser1.paid}
//                 >
//                   Dashboard
//                 </button>
//               </Link>
//             </div>
//           ) : (
//             <></>
//           )}

//           <div>
//             <label style={{ marginRight: "10px", color: "white" }}>
//               Filter by Subject:
//             </label>
//             <select
//               value={selectedSubject}
//               onChange={(e) => setSelectedSubject(e.target.value)}
//               className="form-control"
//               style={{
//                 width: "auto",
//                 display: "inline-block",
//                 backgroundColor: "red",
//                 color: "white",
//               }}
//             >
//               <option value="All">All</option>
//               {uniqueSubjects.map((subject) => (
//                 <option key={subject} value={subject}>
//                   {subject}
//                 </option>
//               ))}
//             </select>
//           </div>
//         </div>

//         {loading ? (
//           <Loading />
//         ) : error ? (
//           <Error error="Something went wrong" />
//         ) : filteredQuizzes && filteredQuizzes.length > 0 ? (
//           filteredQuizzes
//             .slice(
//               (currentPage - 1) * quizzesPerPage,
//               currentPage * quizzesPerPage
//             )
//             .map((quiz) => (
//               <div className="col-md-3 m-3" key={quiz._id}>
//                 <Quiz
//                   quiz={quiz}
//                   userId={userData._id}
//                   userName={userData.name}
//                   userSchoolcode={userData.schoolcode}
//                   userClass={userData.classs}
//                   userSection={userData.section}
//                   userPaid={userData.paid}
//                   userPaidfor={userData.paidfor}
//                   // attemptedQuizIds={userData.attemptedquizs.map((q) => q._id)}
//                   // completedQuizIds={userData.completedquizs.map((q) => q._id)}
//                   attemptedQuizIds={userData.attemptedquizs}
//                   completedQuizIds={userData.completedquizs}
//                   updateDifficulty={updateDifficulty} // Pass the function
//                 />
//               </div>
//             ))
//         ) : (
//           showNoQuizzesMessage && (
//             <div className="col-md-12 text-center">
//               <p className="text-danger">
//                 No Quizzes Found for your class and selected filters.
//               </p>
//             </div>
//           )
//         )}

//         {filteredQuizzes.length > quizzesPerPage && (
//           <div className="col-md-12 mt-4">
//             <Pagination
//               quizzesPerPage={quizzesPerPage}
//               totalQuizzes={filteredQuizzes.length}
//               currentPage={currentPage}
//               paginate={paginate}
//             />
//           </div>
//         )}
//       </div>
//     </div>
//   );
// }

// export default Bdscreen;
////////////////////////////////////////////////////////////////
import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllDifficultys } from "../actions/difficultyActions";
import Quiz from "../components/Quiz";
import Loading from "../components/Loading";
import Error from "../components/Error";
import Pagination from "../components/Pagination";
import { Link, useLocation } from "react-router-dom";
import axios from "axios"; // Import axios
//import Paymentplansscreen from "./Paymentplansscreen";

function Bdscreen() {
  const dispatch = useDispatch();
  const location = useLocation();

  const userLogin = useSelector((state) => state.loginUserReducer);
  const { currentUser1 } = userLogin;
  const difficultysState = useSelector(
    (state) => state.getAllDifficultysReducer
  );
  const { difficultys } = difficultysState;
  const [selectedChapter, setSelectedChapter] = useState("All"); // Initialize with "All"
  const [chapters, setChapters] = useState([]); // State for chapters
  const [selectedDifficulty, setSelectedDifficulty] = useState("All");
  const [selectedSubject, setSelectedSubject] = useState("All");
  const [currentPage, setCurrentPage] = useState(1);
  const [quizzesPerPage] = useState(6);
  const [userData, setUserData] = useState(null); // State to store fetched user data
  const [filteredQuizzes, setFilteredQuizzes] = useState([]); // Store filtered quizzes
  const [uniqueSubjects, setUniqueSubjects] = useState(["All"]); // Store unique subjects
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showNoQuizzesMessage, setShowNoQuizzesMessage] = useState(false); // For delayed message display
  // Ref to track whether `filteredQuizzes` was previously empty
  const wasFilteredQuizzesEmpty = useRef(true);

  // Extract the 'classs' query parameter from the URL
  const queryParams = new URLSearchParams(location.search);
  const selectedClassFromModal = queryParams.get("classs");
  useEffect(() => {
    const fetchChapters = async () => {
      if (!userData) return; // Ensure userData is available

      try {
        const { data } = await axios.get("/api/chapters/fetchchaptersfilter", {
          params: {
            division: "Bauddha Dakshata", // Division is always included
            subject: selectedSubject === "All" ? undefined : selectedSubject, // Include subject if it's not "All"
            classs: userData.classs, // Fetch classs from userData
          },
        });

        setChapters(data); // Update the chapters state with fetched data
      } catch (error) {
        console.error("Error fetching chapters:", error);
      }
    };

    fetchChapters();
  }, [selectedSubject, userData]); // Re-fetch when subject or userData changes

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 767);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    dispatch(getAllDifficultys()); // Fetch difficulties when component mounts
  }, [dispatch]);

  useEffect(() => {
    setCurrentPage(1); // Reset currentPage to 1 whenever a filter is applied
  }, [selectedDifficulty, selectedSubject, selectedChapter]);

  // Fetch user data by ID
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const { data } = await axios.get(
          `/api/users/getuserbyid/${currentUser1._id}`
        );
        console.log("data bd users : ", data);
        setUserData(data);
      } catch (error) {
        console.error("Error fetching user data", error);
      }
    };

    if (currentUser1) {
      fetchUserData();
    }
  }, [currentUser1]);

  // Fetch filtered quizzes from the backend
  useEffect(() => {
    const fetchFilteredQuizzes = async () => {
      if (userData) {
        setLoading(true);
        setError(null);
        setShowNoQuizzesMessage(false); // Reset the no quizzes message
        console.log(
          "selectedClassFromModal in Bauddha Dakshata : ",
          selectedClassFromModal
        );
        try {
          const { data } = await axios.get("/api/quizs/getfilteredquizs", {
            params: {
              difficulty: selectedDifficulty,
              subject: selectedSubject,
              classs: selectedClassFromModal,
              // userData.classs == "Already passed 12th"
              //   ? selectedClassFromModal
              //   : userData.classs,
              schoolcode: userData.schoolcode,
              email: userData.email,
              chapter: selectedChapter === "All" ? undefined : selectedChapter, // Handle "All"
              division: "Bauddha Dakshata",
            },
          });
          console.log("data bd quizs : ", data);
          setFilteredQuizzes(data);

          // Update uniqueSubjects only if filteredQuizzes was empty and is now non-empty
          if (wasFilteredQuizzesEmpty.current && data.length > 0) {
            const subjects = [
              "All",
              ...new Set(data.map((quiz) => quiz.subject)),
            ];
            setUniqueSubjects(subjects);
          }

          wasFilteredQuizzesEmpty.current = data.length === 0; // Update ref value
        } catch (error) {
          setError("Error fetching filtered quizzes");
          console.error("Error fetching filtered quizzes", error);
        } finally {
          setLoading(false);
        }

        // Set a timeout to display the no quizzes message after 5 seconds
        setTimeout(() => {
          if (filteredQuizzes.length === 0) {
            setShowNoQuizzesMessage(true);
          }
        }, 5000);
      }
    };

    fetchFilteredQuizzes();
  }, [selectedDifficulty, selectedSubject, selectedChapter, userData]);

  // New function to update difficulty
  const updateDifficulty = (difficulty) => {
    setSelectedDifficulty(difficulty);
  };

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // useEffect(() => {
  //   if (filteredQuizzes.length > 0) {
  //     const subjects = [
  //       "All",
  //       ...new Set(filteredQuizzes.map((quiz) => quiz.subject)),
  //     ];
  //     setUniqueSubjects(subjects);
  //   }
  // }, [filteredQuizzes]);

  return (
    <div className="container mt-4" style={{ paddingTop: "70px" }}>
      <div className="row justify-content-center">
        <div
          className="col-md-12 d-flex justify-content-between mb-3"
          style={{ backgroundColor: "black", padding: "10px" }}
        >
          <div>
            <label
              style={{
                marginRight: "10px",
                color: "white",
                fontFamily: "Baskerville, serif",
                fontWeight: "bold",
              }}
            >
              Filter by Category:
            </label>
            <select
              value={selectedDifficulty}
              onChange={(e) => {
                setSelectedDifficulty(e.target.value);
                setSelectedSubject("All");
              }}
              className="form-control"
              style={{
                width: "auto",
                display: "inline-block",
                backgroundColor: "red",
                color: "white",
                fontFamily: "Baskerville, serif",
                fontWeight: "bold",
              }}
            >
              {difficultys &&
                difficultys.map((diff) => (
                  <option key={diff._id} value={diff.diff}>
                    {diff.diff}
                  </option>
                ))}
            </select>
          </div>

          <div>
            <label
              style={{
                marginRight: "10px",
                color: "white",
                fontFamily: "Baskerville, serif",
                fontWeight: "bold",
              }}
            >
              Filter by Subject:
            </label>
            <select
              value={selectedSubject}
              onChange={(e) => setSelectedSubject(e.target.value)}
              className="form-control"
              style={{
                width: "auto",
                display: "inline-block",
                backgroundColor: "red",
                color: "white",
                fontFamily: "Baskerville, serif",
                fontWeight: "bold",
              }}
            >
              {/* <option value="All">All</option> */}
              {uniqueSubjects.map((subject) => (
                <option key={subject} value={subject}>
                  {subject}
                </option>
              ))}
            </select>
          </div>
          <div>
            <label
              style={{
                marginRight: "10px",
                color: "white",
                fontFamily: "Baskerville, serif",
              }}
            >
              Filter by Chapter:
            </label>
            <select
              value={selectedChapter}
              onChange={(e) => setSelectedChapter(e.target.value)}
              className="form-control"
              style={{
                width: "auto",
                display: "inline-block",
                backgroundColor: "red",
                color: "white",
              }}
            >
              <option value="All">All</option>
              {Array.isArray(chapters) && chapters.length > 0 ? (
                chapters.map((chapter) => (
                  <option key={chapter._id} value={chapter.chapter}>
                    {chapter.chapter}
                  </option>
                ))
              ) : (
                <option value="" disabled>
                  No chapters available
                </option>
              )}
            </select>
          </div>
        </div>
        {loading ? (
          <Loading />
        ) : error ? (
          <Error error="Something went wrong" />
        ) : filteredQuizzes && filteredQuizzes.length > 0 ? (
          filteredQuizzes
            .slice(
              (currentPage - 1) * quizzesPerPage,
              currentPage * quizzesPerPage
            )
            .map((quiz) => (
              <div className="col-md-3 m-3" key={quiz._id}>
                <Quiz
                  quiz={quiz}
                  userId={userData._id}
                  userName={userData.name}
                  userSchoolcode={userData.schoolcode}
                  userClass={userData.classs}
                  userSection={userData.section}
                  userPaid={userData.paid}
                  userPaidfor={userData.paidfor}
                  // attemptedQuizIds={userData.attemptedquizs.map((q) => q._id)}
                  // completedQuizIds={userData.completedquizs.map((q) => q._id)}
                  attemptedQuizIds={userData.attemptedquizs}
                  completedQuizIds={userData.completedquizs}
                  updateDifficulty={updateDifficulty} // Pass the function
                  selectedClassFromModal={selectedClassFromModal}
                />
              </div>
            ))
        ) : (
          showNoQuizzesMessage && (
            <div className="col-md-12 text-center">
              <p className="text-danger">
                No Quizzes Found for your class and selected filters.
              </p>
            </div>
          )
        )}

        {filteredQuizzes.length > quizzesPerPage && (
          <div className="col-md-12 mt-4">
            <Pagination
              quizzesPerPage={quizzesPerPage}
              totalQuizzes={filteredQuizzes.length}
              currentPage={currentPage}
              paginate={paginate}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default Bdscreen;
