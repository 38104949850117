import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllDifficultys } from "../actions/difficultyActions";
import Loading from "../components/Loading";
import Error from "../components/Error";
import Pagination from "../components/Pagination";
import { Link } from "react-router-dom";
import axios from "axios"; // Import axios
import KyeByeQuiz from "../components/KyeByeQuiz";

function Kyebyescreen() {
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.loginUserReducer);
  const { currentUser1 } = userLogin;
  const difficultysState = useSelector(
    (state) => state.getAllDifficultysReducer
  );
  const { difficultys } = difficultysState;

  const [selectedDifficulty, setSelectedDifficulty] = useState("All");
  const [selectedSubject, setSelectedSubject] = useState("All");
  const [currentPage, setCurrentPage] = useState(1);
  const [quizzesPerPage] = useState(6);
  const [userData, setUserData] = useState(null); // State to store fetched user data
  const [filteredQuizzes, setFilteredQuizzes] = useState([]); // Store filtered quizzes
  const [uniqueSubjects, setUniqueSubjects] = useState(["All"]); // Store unique subjects
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [chapters, setChapters] = useState([]); // State for chapters
  const [selectedChapter, setSelectedChapter] = useState("All"); // Initialize with "All"

  const [showNoQuizzesMessage, setShowNoQuizzesMessage] = useState(false); // For delayed message display
  // Ref to track whether `filteredQuizzes` was previously empty
  const wasFilteredQuizzesEmpty = useRef(true);

  useEffect(() => {
    const fetchChapters = async () => {
      if (!userData) return; // Ensure userData is available

      try {
        const { data } = await axios.get("/api/chapters/fetchchaptersfilter", {
          params: {
            division: "Kye Bye", // Division is always included
            subject: selectedSubject === "All" ? undefined : selectedSubject, // Include subject if it's not "All"
            classs: "Already passed 12th",
          },
        });

        setChapters(data); // Update the chapters state with fetched data
      } catch (error) {
        console.error("Error fetching chapters:", error);
      }
    };

    fetchChapters();
  }, [selectedDifficulty, selectedSubject, selectedChapter]);

  // Handle screen resizing
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 767);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Fetch difficulties when the component mounts
  useEffect(() => {
    dispatch(getAllDifficultys());
  }, [dispatch]);

  // Reset current page whenever filters are applied
  useEffect(() => {
    setCurrentPage(1);
  }, [selectedDifficulty, selectedSubject]);

  // Fetch user data by ID
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const { data } = await axios.get(
          `/api/users/getuserbyid/${currentUser1._id}`
        );
        console.log("data kyebye users : ", data);
        setUserData(data);
      } catch (error) {
        console.error("Error fetching user data", error);
      }
    };

    if (currentUser1) {
      fetchUserData();
    }
  }, [currentUser1]);

  // Fetch filtered quizzes from the kyebyequizzes collection
  useEffect(() => {
    const fetchFilteredQuizzes = async () => {
      if (userData) {
        console.log("userData : ", userData);
        setLoading(true);
        setError(null);
        setShowNoQuizzesMessage(false); // Reset the no quizzes message
        try {
          const { data } = await axios.get(
            "/api/KyebyeQuiz/getfilteredkyebyequizs",
            {
              params: {
                difficulty: selectedDifficulty,
                subject: selectedSubject,
                classs: "Already passed 12th",
                schoolcode: userData.schoolcode,
                chapter:
                  selectedChapter === "All" ? undefined : selectedChapter, // Handle "All"
                email: userData.email,
                division: "Kye Bye", // Adjusted division for Kyebye
              },
            }
          );

          // Ensure the response data is an array
          if (Array.isArray(data)) {
            setFilteredQuizzes(data);

            // Update uniqueSubjects only if filteredQuizzes was empty and is now non-empty
            if (wasFilteredQuizzesEmpty.current && data.length > 0) {
              const subjects = [
                "All",
                ...new Set(data.map((quiz) => quiz.subject)),
              ];
              setUniqueSubjects(subjects);
            }

            wasFilteredQuizzesEmpty.current = data.length === 0; // Update ref value
          } else {
            setFilteredQuizzes([]); // Set an empty array if data is not an array
          }
          console.log("data kyebye quizs : ", data);
        } catch (error) {
          setError("Error fetching filtered quizzes");
          console.error("Error fetching filtered quizzes", error);
          setFilteredQuizzes([]); // Handle error by setting an empty array
        } finally {
          setLoading(false);
        }

        // Set a timeout to display the no quizzes message after 5 seconds
        setTimeout(() => {
          if (filteredQuizzes.length === 0) {
            setShowNoQuizzesMessage(true);
          }
        }, 5000);
      }
    };

    fetchFilteredQuizzes();
  }, [selectedDifficulty, selectedSubject, selectedChapter, userData]);

  // New function to update difficulty
  const updateDifficulty = (difficulty) => {
    setSelectedDifficulty(difficulty);
  };

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // const uniqueSubjects = [
  //   ...new Set(filteredQuizzes.map((quiz) => quiz.subject)),
  // ];

  return (
    <div className="container mt-4" style={{ paddingTop: "70px" }}>
      <div className="row justify-content-center">
        <div
          className="col-md-12 d-flex justify-content-between mb-3"
          style={{ backgroundColor: "black", padding: "10px" }}
        >
          <div>
            <label
              style={{
                marginRight: "10px",
                color: "white",
                fontFamily: "Baskerville, serif",
                fontWeight: "bold",
              }}
            >
              Filter by Category:
            </label>
            <select
              value={selectedDifficulty}
              onChange={(e) => {
                setSelectedDifficulty(e.target.value);
                setSelectedSubject("All");
                setSelectedChapter("All");
              }}
              className="form-control"
              style={{
                width: "auto",
                display: "inline-block",
                backgroundColor: "red",
                color: "white",
                fontFamily: "Baskerville, serif",
                fontWeight: "bold",
              }}
            >
              {difficultys &&
                difficultys.map((diff) => (
                  <option key={diff._id} value={diff.diff}>
                    {diff.diff}
                  </option>
                ))}
            </select>
          </div>

          <div>
            <label
              style={{
                marginRight: "10px",
                color: "white",
                fontFamily: "Baskerville, serif",
                fontWeight: "bold",
              }}
            >
              Filter by Subject:
            </label>
            <select
              value={selectedSubject}
              onChange={(e) => setSelectedSubject(e.target.value)}
              className="form-control"
              style={{
                width: "auto",
                display: "inline-block",
                backgroundColor: "red",
                color: "white",
                fontFamily: "Baskerville, serif",
                fontWeight: "bold",
              }}
            >
              {/* <option value="All">All</option> */}
              {uniqueSubjects.map((subject) => (
                <option key={subject} value={subject}>
                  {subject}
                </option>
              ))}
            </select>
          </div>
          <div>
            <label
              style={{
                marginRight: "10px",
                color: "white",
                fontFamily: "Baskerville, serif",
              }}
            >
              Filter by Chapter:
            </label>
            <select
              value={selectedChapter}
              onChange={(e) => {
                setSelectedSubject(e.target.value);
                setSelectedChapter("All"); // Reset chapter when subject changes
              }}
              className="form-control"
              style={{
                width: "auto",
                display: "inline-block",
                backgroundColor: "red",
                color: "white",
              }}
            >
              <option value="All">All</option>
              {Array.isArray(chapters) && chapters.length > 0 ? (
                chapters.map((chapter) => (
                  <option key={chapter._id} value={chapter.chapter}>
                    {chapter.chapter}
                  </option>
                ))
              ) : (
                <option value="" disabled>
                  No chapters available
                </option>
              )}
            </select>
          </div>
        </div>

        {loading ? (
          <Loading />
        ) : error ? (
          <Error error="Something went wrong" />
        ) : filteredQuizzes && filteredQuizzes.length > 0 ? (
          filteredQuizzes
            .slice(
              (currentPage - 1) * quizzesPerPage,
              currentPage * quizzesPerPage
            )
            .map((quiz) => (
              <div className="col-md-3 m-3" key={quiz._id}>
                <KyeByeQuiz
                  quiz={quiz}
                  userId={userData._id}
                  userName={userData.name}
                  userSchoolcode={userData.schoolcode}
                  userClass={userData.classs}
                  userSection={userData.section}
                  userPaid={userData.paid}
                  userPaidfor={userData.paidfor}
                  // attemptedQuizIds={userData.attemptedquizs.map((q) => q._id)}
                  // completedQuizIds={userData.completedquizs.map((q) => q._id)}
                  attemptedQuizIds={userData.attemptedquizs}
                  completedQuizIds={userData.completedquizs}
                  attemptedkyebyeQuizIds={userData.attemptedkyebyequizzes}
                  completedkyebyeQuizIds={userData.completedkyebyequizzes}
                  // attemptedkyebyeQuizIds={userData.attemptedkyebyequizzes.map(
                  //   (q) => q._id
                  // )}
                  // completedkyebyeQuizIds={userData.completedkyebyequizzes.map(
                  //   (q) => q._id
                  // )}
                  updateDifficulty={updateDifficulty} // Pass the function
                />
              </div>
            ))
        ) : (
          showNoQuizzesMessage && (
            <div className="col-md-12 text-center">
              <p className="text-danger">
                No Quizzes Found for your class and selected filters.
              </p>
            </div>
          )
        )}

        {filteredQuizzes.length > quizzesPerPage && (
          <div className="col-md-12 mt-4">
            <Pagination
              quizzesPerPage={quizzesPerPage}
              totalQuizzes={filteredQuizzes.length}
              currentPage={currentPage}
              paginate={paginate}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default Kyebyescreen;
