import React, { useState, useEffect } from "react";
import "./Registerscreen.css";
import { useDispatch, useSelector } from "react-redux";
import { registerUser } from "../actions/userActions";
import { getAllSchoolcodes } from "../actions/schoolcodeActions";
import { getAllClassss } from "../actions/classsActions";
import { getAllStudents } from "../actions/studentActions"; // Import getAllStudents action
import Error from "../components/Error";
import Success from "../components/Success";
import Loading from "../components/Loading";
import axios from "axios"; // Import axios to make API requests
import BackgroundImage from "./BackgroundImage";

export default function Registerscreen() {
  const [name, setname] = useState("");
  const [school, setschool] = useState("");
  const [schoolcode, setschoolcode] = useState("");
  const [classs, setclasss] = useState("");
  const [section, setsection] = useState("");
  const [city, setcity] = useState("");
  const [mobile, setmobile] = useState("");
  const [email, setemail] = useState("");
  const [paid, setpaid] = useState(false);
  const [password, setpassword] = useState("");
  const [cpassword, setcpassword] = useState("");
  const [registrationType, setRegistrationType] = useState("schoolCode");
  const [isAllFieldsFilled, setIsAllFieldsFilled] = useState(false);
  const [isSchoolCodeValid, setIsSchoolCodeValid] = useState(true); // Initialize to true
  const [rollNumber, setRollNumber] = useState("");
  const [isRegistering, setIsRegistering] = useState(false);


  const registerstate = useSelector((state) => state.registerUserReducer);
  const { error, loading, success } = registerstate;
  const schoolcodes = useSelector(
    (state) => state.getAllSchoolcodesReducer.schoolcodes
  );

  const classssState = useSelector((state) => state.getAllClassssReducer);
  const {
    classss: classes,
    loading: classssLoading,
    error: classssError,
  } = classssState;

  const studentsstate = useSelector((state) => state.getAllStudentsReducer);
  const { students } = studentsstate;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllSchoolcodes());
    dispatch(getAllClassss()); // Fetch all classes
    dispatch(getAllStudents()); // Fetch all students
  }, [dispatch]);

  useEffect(() => {
    const allFieldsFilled =
      name !== "" &&
      classs !== "" &&
      city !== "" &&
      mobile !== "" &&
      email !== "" &&
      password !== "" &&
      cpassword !== "" &&
      (registrationType === "schoolCode" ? section !== "" : true) &&
      (registrationType === "schoolCode" ? schoolcode !== "" : true) &&
      (registrationType === "schoolCode" ? rollNumber !== "" : true);
    setIsAllFieldsFilled(allFieldsFilled);
  }, [
    name,
    schoolcode,
    classs,
    section,
    city,
    mobile,
    email,
    password,
    cpassword,
    registrationType,
    rollNumber,
  ]);

  const handleMobileChange = (e) => {
    const value = e.target.value;
    if (value === "" || /^[0-9\b]+$/.test(value)) {
      setmobile(value);
    }
  };

  const handleTextChange = (setter) => (e) => {
    const value = e.target.value;
    if (/^[a-zA-Z\s]*$/.test(value)) {
      setter(value);
    }
  };

  async function register() {
    if (password !== cpassword) {
      alert("Passwords do not match");
    } else {
      setIsRegistering(true); // Disable button and show "Please Wait..."
      try {
        // Check if email already exists
        const response = await axios.post("/api/users/checkemail", { email });
        if (response.data.exists) {
          alert(
            "Email already registered. Register with another email account."
          );
          setIsRegistering(false);
          return;
        }

        if (registrationType === "schoolCode") {
          const isValid = schoolcodes.some(
            (code) => code.code === schoolcode.trim()
          );
          setIsSchoolCodeValid(isValid);
          if (!isValid) {
            alert("School Code is Invalid");
            setIsRegistering(false);
            return;
          }
        }

        // Check if a student with similar classs, rollnumber, and schoolcode already exists
      const matchResponse = await axios.post("/api/users/checkstudent", {
        classs: classs.trim(),
        rollnumber: rollNumber.trim(),
        schoolcode: schoolcode.trim(),
      });

      if (matchResponse.data.exists) {
        alert(
          "Student with similar classs, rollnumber, and schoolcode already registered."
        );
        setIsRegistering(false);
        return;
      }


        const matchedStudent = students.find(
          (student) =>
            student.classs &&
            student.rollnumber &&
            student.classs == classs &&
            student.rollnumber.trim() == rollNumber.trim()
        );

        if (registrationType === "schoolCode" && !matchedStudent) {
          alert(
            "You are not yet enrolled with us. Please contact school admin or register without the school code."
          );
          setIsRegistering(false);
          return;
        }

        if (mobile.length < 10) {
          alert("Entered mobile number is less than 10-digit.");
          setIsRegistering(false);
          return;
        }

        const user = {
          name: name.trim(),
          key: `${name.trim()}${classs.trim()}${rollNumber.trim()}`,
          mobile: mobile.trim(),
          school: school.trim(),
          schoolcode:
            registrationType === "schoolCode" ? schoolcode.trim() : "Empty",
          paid: registrationType === "schoolCode" ? true : false,
          classs: classs.trim(),
          rollNumber:
            registrationType === "schoolCode" ? rollNumber.trim() : "",
          section: registrationType === "schoolCode" ? section.trim() : "",
          city: city.trim(),
          email: email.trim().toLowerCase(),
          password: password.trim(),
        };

        console.log(user);

        // Save email to local storage
        localStorage.setItem("registeredEmail", user.email);

        // Send the new user to the backend to register
        dispatch(registerUser(user));
      } catch (error) {
        console.error("Error during registration", error);
        alert("An error occurred during registration. Please try again.");
        setIsRegistering(false);
      }
    }
  }

  return (
    <div className="d-flex align-items-center justify-content-center vh-100">
      <BackgroundImage />
      <div
        className="justify-content-center mt-5"
        style={{ maxWidth: "500px", width: "100%" }}
      >
        <div
          className="text-start shadow-lg p-3 mb-5 glass-card rounded-5"
          style={{ marginTop: "300px" }}
        >
          {/* <div className="col-md-5 mt-5 text-start shadow-lg p-3 mb-5 bg-white rounded-5"> */}
          {loading && <Loading />}

          <h2
            className="text-center m-2"
            style={{
              fontSize: "35px",
              fontFamily: '"Libre Baskerville", serif',
              fontWeight: "bold",
            }}
          >
            Register
          </h2>
          <div>
            <div className="form-check mb-3">
              <input
                className="form-check-input glass-card"
                type="radio"
                name="registrationType"
                id="schoolCode"
                value="schoolCode"
                checked={registrationType === "schoolCode"}
                onChange={() => setRegistrationType("schoolCode")}
                style={{
                  border: "1px solid #ced4da",
                  boxShadow: "none",
                  marginRight: "5px",
                  fontFamily: '"Libre Baskerville", serif',
                  fontWeight: "bold",
                }}
              />
              <label
                className="form-check-label"
                htmlFor="schoolCode"
                style={{
                  marginLeft: "5px",
                  fontFamily: '"Libre Baskerville", serif',
                  fontWeight: "bold",
                }}
              >
                Register with school code
              </label>
            </div>
            <div className="form-check mb-3">
              <input
                className="form-check-input glass-card"
                type="radio"
                name="registrationType"
                id="individual"
                value="individual"
                checked={registrationType === "individual"}
                onChange={() => setRegistrationType("individual")}
                style={{
                  border: "1px solid #ced4da",
                  boxShadow: "none",
                  marginRight: "5px",
                  fontFamily: '"Libre Baskerville", serif',
                  fontWeight: "bold",
                }}
              />
              <label
                className="form-check-label"
                htmlFor="individual"
                style={{
                  marginLeft: "5px",
                  fontFamily: '"Libre Baskerville", serif',
                  fontWeight: "bold",
                }}
              >
                Register as an individual without school code
              </label>
            </div>
            <div>
              <input
                required
                type="text"
                placeholder="Full Name     [ only alphabets ( A-Z , a-z ) are allowed ]"
                className="form-control glass-card"
                value={name}
                onChange={handleTextChange(setname)}
                style={{
                  border: "1px solid black",
                  marginBottom: "10px",
                  fontFamily: '"Libre Baskerville", serif',
                  fontWeight: "bold",
                }}
              />
            </div>

            {registrationType === "schoolCode" && (
              <div>
                <input
                  required
                  type="text"
                  placeholder="School Code"
                  className="form-control glass-card"
                  value={schoolcode}
                  onChange={(e) => {
                    setschoolcode(e.target.value);
                  }}
                  style={{
                    border: "1px solid black",
                    marginBottom: "0px",
                    fontFamily: '"Libre Baskerville", serif',
                    fontWeight: "bold",
                  }}
                />
              </div>
            )}

            <div className="form-group">
              <label></label>
              <select
                className="form-control glass-card"
                value={classs}
                onChange={(e) => setclasss(e.target.value)}
                style={{
                  marginBottom: "0px",
                  fontFamily: '"Libre Baskerville", serif',
                  fontWeight: "bold",
                }}
              >
                <option value="">Select Class</option>
                {classes &&
                  classes
                    .sort((a, b) => {
                      const isANumeric = /^\d+$/.test(a.cls);
                      const isBNumeric = /^\d+$/.test(b.cls);

                      if (isANumeric && isBNumeric) {
                        return parseInt(a.cls) - parseInt(b.cls);
                      } else if (isANumeric) {
                        return 1; // Numbers come after letters
                      } else if (isBNumeric) {
                        return -1; // Letters come before numbers
                      } else {
                        return a.cls.localeCompare(b.cls); // Sort letters alphabetically
                      }
                    })
                    .map((cls) => (
                      <option key={cls._id} value={cls.cls}>
                        {cls.cls}
                      </option>
                    ))}

                {/* {registrationType == "individual" && (
                  <option key={"Already passed 12th"} value={"Already passed 12th"}>
                    Already passed 12th
                  </option>
                )} */}
              </select>
            </div>

            {registrationType === "schoolCode" && (
              <div className="form-group">
                <label></label>
                <select
                  className="form-control glass-card"
                  value={section}
                  onChange={(e) => setsection(e.target.value)}
                  style={{
                    marginBottom: "0px",
                    fontFamily: '"Libre Baskerville", serif',
                    fontWeight: "bold",
                  }}
                >
                  <option value="">Select Class Section</option>
                  <option key="Section Unavailable" value="Section Unavailable">
                    Section Unavailable
                  </option>
                  <option key="A" value="A">
                    A
                  </option>
                  <option key="B" value="B">
                    B
                  </option>
                  <option key="C" value="C">
                    C
                  </option>
                  <option key="D" value="D">
                    D
                  </option>
                  <option key="E" value="E">
                    E
                  </option>
                  <option key="F" value="F">
                    F
                  </option>
                  <option key="G" value="G">
                    G
                  </option>
                  <option key="H" value="H">
                    H
                  </option>
                </select>
              </div>
            )}

            {registrationType === "schoolCode" && (
              <div className="form-group">
                <input
                  className="form-control glass-card"
                  placeholder="Roll Number"
                  value={rollNumber}
                  onChange={(e) => setRollNumber(e.target.value)}
                  style={{
                    border: "1px solid black",
                    marginBottom: "10px",
                    fontFamily: '"Libre Baskerville", serif',
                    fontWeight: "bold",
                  }}
                />
              </div>
            )}

            {registrationType === "schoolCode" && (
              <div>
                <input
                  required
                  type="text"
                  placeholder="School Name     [ only alphabets ( A-Z , a-z ) are allowed ]"
                  className="form-control glass-card"
                  value={school}
                  onChange={handleTextChange(setschool)}
                  style={{
                    border: "1px solid black",
                    marginBottom: "10px",
                    fontFamily: '"Libre Baskerville", serif',
                    fontWeight: "bold",
                  }}
                />
              </div>
            )}

            <div>
              <input
                required
                type="text"
                placeholder="City     [ only alphabets ( A-Z , a-z ) are allowed ]"
                className="form-control glass-card"
                value={city}
                onChange={handleTextChange(setcity)}
                style={{
                  border: "1px solid black",
                  marginBottom: "10px",
                  fontFamily: '"Libre Baskerville", serif',
                  fontWeight: "bold",
                }}
              />
            </div>
            <div>
              <input
                required
                type="text"
                placeholder="10-digit Mobile Number"
                className="form-control glass-card"
                value={mobile}
                onChange={handleMobileChange}
                maxLength="10"
                pattern="\d{10}"
                title="Please enter a valid 10-digit mobile number"
                style={{
                  border: "1px solid black",
                  marginBottom: "10px",
                  fontFamily: '"Libre Baskerville", serif',
                  fontWeight: "bold",
                }}
              />
            </div>
            <div>
              <input
                required
                type="text"
                placeholder="Email"
                className="form-control glass-card"
                value={email}
                onChange={(e) => {
                  setemail(e.target.value);
                }}
                style={{
                  border: "1px solid black",
                  marginBottom: "10px",
                  fontFamily: '"Libre Baskerville", serif',
                  fontWeight: "bold",
                }}
              />
            </div>
            <input
              required
              type="password"
              placeholder="Password"
              className="form-control glass-card"
              value={password}
              onChange={(e) => {
                setpassword(e.target.value);
              }}
              style={{
                border: "1px solid black",
                marginBottom: "10px",
                fontFamily: '"Libre Baskerville", serif',
                fontWeight: "bold",
              }}
            />
            <input
              required
              type="password"
              placeholder="Confirm Password"
              className="form-control mb-3 glass-card"
              value={cpassword}
              onChange={(e) => {
                setcpassword(e.target.value);
              }}
              style={{
                border: "1px solid black",
                marginBottom: "10px",
                fontFamily: '"Libre Baskerville", serif',
                fontWeight: "bold",
              }}
            />

<button
  onClick={register}
  className="btn mt-3 mb-3"
  disabled={!isAllFieldsFilled || isRegistering}
  style={{
    fontFamily: '"Libre Baskerville", serif',
    fontWeight: "bold",
  }}
>
  {isRegistering ? "Please Wait..." : "REGISTER"}
</button>

            <br></br>
            <a
              style={{
                color: "black",
                fontFamily: '"Libre Baskerville", serif',
                fontWeight: "bold",
              }}
              href="/login"
            >
              Click Here To Login
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
