// // // import React, { useEffect, useRef, useState } from "react";
// // // import { useNavigate } from "react-router-dom";

// // // const offerings = [
// // //   {
// // //     title: "Udbodh",
// // //     description:
// // //       "A broad base of diversified knowledge can help students think critically and creatively leading to personal and professional growth.",
// // //   },
// // //   {
// // //     title: "Bauddha Dakshata",
// // //     description:
// // //       "Education in analytical skills and logical reasoning helps students develop their problem-solving skills and critical thinking abilities.",
// // //   },
// // //   {
// // //     title: "Inspiration Stories",
// // //     description: "Motivational and inspiring stories of our young students.",
// // //   },
// // //   {
// // //     title: "Financial Literacy",
// // //     description: "Facilitating financial literacy amongst students.",
// // //   },
// // //   {
// // //     title: "Teacher Training Programs",
// // //     description: "",
// // //   },
// // //   {
// // //     title: "ABL",
// // //     description: "Incorporating art and activities in learning.",
// // //   },
// // //   {
// // //     title: "Parent Orientation Program",
// // //     description: "",
// // //   },
// // //   {
// // //     title: "Samanvaya",
// // //     description: "",
// // //   },
// // //   {
// // //     title: "Remedial Classes",
// // //     description: "",
// // //   },
// // // ];

// // // function ProductOfferings() {
// // //   const headingRef = useRef(null);
// // //   const cardsRef = useRef([]);
// // //   const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
// // //   const navigate = useNavigate();

// // //   useEffect(() => {
// // //     const handleResize = () => {
// // //       setIsMobile(window.innerWidth <= 767);
// // //     };

// // //     window.addEventListener("resize", handleResize);
// // //     return () => {
// // //       window.removeEventListener("resize", handleResize);
// // //     };
// // //   }, []);

// // //   useEffect(() => {
// // //     const observer = new IntersectionObserver(
// // //       (entries, observer) => {
// // //         entries.forEach((entry) => {
// // //           if (entry.isIntersecting) {
// // //             entry.target.classList.add("animate");
// // //             observer.unobserve(entry.target);
// // //           }
// // //         });
// // //       },
// // //       { threshold: 0.5 }
// // //     );

// // //     if (headingRef.current) {
// // //       observer.observe(headingRef.current);
// // //     }

// // //     cardsRef.current.forEach((card) => {
// // //       if (card) observer.observe(card);
// // //     });

// // //     return () => {
// // //       observer.disconnect();
// // //     };
// // //   }, []);

// // //   const handleClick = (title) => {
// // //     window.location.href =
// // //       title == "Udbodh"
// // //         ? "/middlescreen2"
// // //         : title == "Bauddha Dakshata"
// // //         ? "/middlescreen"
// // //         : title == "Inspiration Stories"
// // //         ? "/getinspired"
// // //         : title == "Samanvaya"
// // //         ? "https://knowandbuildbharat.com"
// // //         : "#";
// // //     //navigate(title === "Udbodh" ? "/middlescreen2" : "#"); // Navigates to the 'About' page
// // //   };

// // //   return (
// // //     <div
// // //       style={{
// // //         padding: "40px 0",
// // //         background: "#f8f9fa",
// // //         marginTop: isMobile ? "60vh" : "",
// // //       }}
// // //     >
// // //       <h2
// // //         ref={headingRef}
// // //         className="heading"
// // //         style={{
// // //           textAlign: "center",
// // //           fontFamily: '"Libre Baskerville", serif',
// // //           fontSize: "2em",
// // //           fontWeight: "bold",
// // //           marginTop: "5vh",
// // //           marginBottom: "2vh",
// // //           width: "100%",
// // //         }}
// // //       >
// // //         Our Product Offerings
// // //       </h2>
// // //       <div
// // //         className="card-container"
// // //         style={{
// // //           display: "flex",
// // //           justifyContent: "space-around",
// // //           alignItems: "flex-start",
// // //           flexWrap: "wrap",
// // //           padding: "20px",
// // //           marginTop: "50px",
// // //           background: "white",
// // //         }}
// // //       >
// // //         {offerings.map((offering, index) => (
// // //           <div
// // //             key={index}
// // //             ref={(el) => (cardsRef.current[index] = el)}
// // //             className="card"
// // //             onClick={() => handleClick(offering.title)}
// // //           >
// // //             <div
// // //               className="card-title"
// // //               style={{
// // //                 textAlign: "center",
// // //                 fontFamily: '"Libre Baskerville", serif',
// // //                 fontSize: "2em",
// // //                 fontWeight: "bold",
// // //                 marginTop:
// // //                   offering.title === "Teacher Training Programs" ||
// // //                   offering.title === "Financial Literacy" ||
// // //                   offering.title === "ABL" ||
// // //                   offering.title === "Parent Orientation Program"
// // //                     ? "10vh"
// // //                     : offering.title === "Samanvaya" ||
// // //                       offering.title === "Remedial Classes"
// // //                     ? "15vh"
// // //                     : "5vh",
// // //                 marginBottom: "2vh",
// // //                 width: "100%",
// // //               }}
// // //             >
// // //               {offering.title}
// // //             </div>
// // //             <div
// // //               className="card-description"
// // //               style={{
// // //                 fontFamily: '"Libre Baskerville", serif',
// // //                 fontWeight: "bold",
// // //               }}
// // //             >
// // //               {offering.description}
// // //             </div>
// // //           </div>
// // //         ))}
// // //       </div>
// // //     </div>
// // //   );
// // // }

// // // export default ProductOfferings;
// // import React from "react";
// // import { useNavigate } from "react-router-dom";

// // function ProductOffering() {
// //   const navigate = useNavigate();
// //   const isMobile = window.innerWidth <= 900; // Check if the screen is mobile-sized

// //   // Handle navigation to different sections
// //   const handleNavigate = (path) => {
// //     const targetSection = document.getElementById(path);
// //     if (targetSection) {
// //       targetSection.scrollIntoView({ behavior: "smooth", block: "center" });
// //     } else {
// //       navigate(path);
// //     }
// //   };

// //   const navbarStyle = {
// //     backgroundColor: "rgb(0, 0, 0)", // Dark blue for navbar
// //     color: "white",
// //     padding: isMobile ? "5px" : "10px",
// //     display: "flex",
// //     textAlign: "center",
// //     fontWeight: "bolder",
// //     justifyContent: "center",
// //     boxSizing: "border-box",
// //     fontSize: isMobile ? "20px" : "30px",
// //     fontFamily: '"Libre Baskerville", serif',
// //     marginLeft: "-4vw",
// //   };

// //   const linkNavbarStyle = {
// //     display: "flex",
// //     flexDirection: isMobile ? "column" : "row", // Stack navbar items vertically on mobile
// //     justifyContent: "center",
// //     alignItems: "center",
// //     textAlign: "center",
// //     backgroundColor: "rgb(0, 0, 0)",
// //     padding: "10px 0",
// //     marginTop: "-10px",
// //     marginLeft: "-4vw",
// //   };

// //   const linkStyle = {
// //     padding: isMobile ? "10px 20px" : "2px 20px",
// //     margin: isMobile ? "5px" : "0 10px",
// //     backgroundColor: "white", // Soft blue for inactive links
// //     color: "#0e003d",
// //     fontWeight: "bold",
// //     textDecoration: "none",
// //     borderRadius: "20px",
// //     fontSize: "24px",
// //     cursor: "pointer",
// //     transition: "all 0.3s ease",
// //     width: isMobile ? "80%" : "auto", // Make the buttons wider on mobile
// //     textAlign: "center",
// //   };

// //   const activeLinkStyle = {
// //     ...linkStyle,
// //     backgroundColor: "#0e003d", // Darker blue for active link
// //     color: "white",
// //   };

// //   const linkHoverStyle = {
// //     ...linkStyle,
// //     backgroundColor: "#0e003d", // Dark blue on hover
// //     color: "white",
// //   };

// //   const mainSectionStyle = {
// //     display: "flex",
// //     flexDirection: "column",
// //     alignItems: "center",
// //     padding: "0px",
// //     margin: "20px auto",
// //     width: "90vw",
// //     maxWidth: "1200px",
// //     fontFamily: '"Libre Baskerville", serif',
// //     //  backgroundColor: "#f0f8ff",
// //   };

// //   const sectionStyle = {
// //     display: "flex",
// //     flexDirection: "column", // Ensure content stacks in a column on mobile
// //     alignItems: "center",
// //     height: isMobile ? "auto" : "auto",
// //     backgroundColor: "#f0f8ff",
// //     padding: isMobile ? "20px" : "30px",
// //     borderRadius: "8px",
// //     margin: "60px 0",
// //     boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
// //     gap: "30px",

// //     // width: "100%", // Make sure it takes full width on mobile
// //   };

// //   const leftContainerStyle = {
// //     istextAlign: "center", // Center the text
// //     fontSize: "22px",
// //     fontFamily: '"Libre Baskerville", serif',
// //     color: "black", // Dark blue text
// //     marginBottom: "20px", // Add space between text and buttons
// //   };

// //   const actionButtonStyle = {
// //     padding: "10px 20px",
// //     backgroundColor: "#0e003d", // Medium blue for buttons
// //     color: "white",
// //     border: "none",
// //     borderRadius: "5px",
// //     cursor: "pointer",
// //     fontSize: "16px",
// //     transition: "background-color 0.3s ease",
// //   };

// //   const rightContainerStyle = {
// //     display: "grid",
// //     gridTemplateColumns: isMobile
// //       ? "1fr"
// //       : "repeat(auto-fit, minmax(150px, 1fr))", // Single column on mobile, two columns on desktop
// //     gap: "10px",
// //     marginTop: "20px",
// //     width: "100%", // Full width for right container
// //   };

// //   const boxStyle = {
// //     display: "flex",
// //     justifyContent: "center",
// //     alignItems: "center",
// //     padding: "10px",
// //     backgroundColor: "#0e003d", // Medium blue for boxes
// //     textAlign: "center",
// //     fontSize: "14px",
// //     fontFamily: '"Libre Baskerville", serif',
// //     color: "#ffffff",
// //     borderRadius: "5px",
// //     minHeight: "100px",
// //     width: "100%", // Full width for box on mobile
// //     wordWrap: "break-word",
// //     boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
// //     cursor: "pointer",
// //     transition: "transform 0.3s ease, box-shadow 0.3s ease",
// //   };

// //   const Box = ({ children, bgColor = "#0e003d" }) => (
// //     <div
// //       style={{
// //         ...boxStyle,
// //         backgroundColor: bgColor,
// //       }}
// //       onMouseEnter={(e) => {
// //         e.currentTarget.style.transform = "scale(1.05)";
// //         e.currentTarget.style.backgroundColor = "#003366"; // Darker blue on hover
// //       }}
// //       onMouseLeave={(e) => {
// //         e.currentTarget.style.transform = "scale(1)";
// //         e.currentTarget.style.backgroundColor = bgColor;
// //       }}
// //     >
// //       {children}
// //     </div>
// //   );

// //   const sections = [
// //     {
// //       id: "divudbodh",
// //       title: "UDBODH",
// //       description:
// //         "At Gyankosha's Udbodh vertical, we are committed to academic excellence and enhancing learning by blending School Subjects with General Knowledge, empowering students for holistic growth. ",
// //       features: [
// //         " Comprehensive Subject Mastery : Strengthening core school subjects with a clear and engaging approach.",
// //         "Expanding Horizons : Interactive sessions to build curiosity and world awareness and making sure the interest in general awareness get developed. ",
// //         "Academic Excellence : Tailored programs to support school curriculum and boost exam performance.       ",
// //         "Skill Development : Boosts analytical thinking, curiosity, and awareness of the world around them.",
// //         "Competitive Edge : Prepares students for academic and competitive exams with confidence.",
// //       ],
// //       buttonLabel: "Explore",
// //       buttonAction: () => handleNavigate("/middlescreen2"),
// //     },
// //     {
// //       id: "divbaudh-dakshata",
// //       title: "BAUDH DAKSHATA",
// //       description:
// //         "Welcome! At Gyankosha's Bauddha Dakshata vertical, we specialize in nurturing Mental Abilities and Analytical Prowess to help students think critically, solve problems, and achieve academic success.",
// //       features: [
// //         " Cognitive Skill Building: Strengthening logical reasoning, analytical thinking, and problem-solving.",
// //         "Customized Programs: Tailored modules to meet students' unique learning needs and challenges.",
// //         "Competitive Exam Readiness : Equipping students with essential skills for success in every exam and beyond.",
// //         "Interactive Learning Tools: Engaging puzzles and quizzes to make learning exciting and fun.",
// //         "Holistic Development: Fostering creativity, adaptability, and confidence for real-world challenges.",
// //       ],
// //       buttonLabel: "Explore",
// //       buttonAction: () => handleNavigate("/middlescreen"),
// //     },
// //     {
// //       id: "divkyebye",
// //       title: "KYEBYE",
// //       description:
// //         "At Gyankosha's KYE BYE vertical (KYE BYE- Know your English Build Your English), we specialize in empowering students with English proficiency tailored for academic and professional success. ",
// //       features: [
// //         " MBA & GMAT English Prep : Master verbal reasoning, critical reading, and advanced grammar for top-tier exams.",
// //         " Spoken English Fluency : Boost confidence with interactive sessions on speaking, pronunciation, and vocabulary.",
// //         "Customized Learning Modules : Tailored programs for every level, from foundational skills to advanced proficiency.",
// //         "Exam Success Strategies : Proven techniques to excel in language-based sections of competitive exams.",
// //         "Global Communication Skills : Equipping students to communicate effectively in academic, professional, and real-world settings.",
// //       ],
// //       buttonLabel: "Explore",
// //       buttonAction: () => handleNavigate("/middlescreen3"),
// //     },
// //     {
// //       id: "divabhyudaya",
// //       title: "ABHYUDAYA",
// //       description:
// //         "We are dedicated to helping students master General Knowledge (GK) and General Awareness, laying a strong foundation for success in competitive exams like UPSC, State PSCs,SSC, BANK PO, NDA, and more.  ",
// //       features: [
// //         "Comprehensive Subject Mastery : Strengthening core  subjects with a clear and engaging approach.",
// //         "Exam-Focused : Comprehensive coverage of static and current affairs tailored to exam patterns.",
// //         " General Awareness Boost : Stay updated on national and international events for better general knowledge.",
// //         "Skill Development : Enhancing critical thinking, problem-solving, and analytical abilities for success in competitive exams.",
// //         "Test Assessments : Take simulated exams to gauge knowledge and identify improvement areas.",
// //       ],
// //       buttonLabel: "Explore",
// //       buttonAction: () => handleNavigate("/middlescreen4"),
// //     },
// //   ];

// //   return isMobile ? (
// //     <div>
// //       <nav style={navbarStyle}>
// //         <p>LEARN AS A STUDENT</p>
// //       </nav>
// //       <div style={linkNavbarStyle}>
// //         {sections.map((section) => (
// //           <span
// //             key={section.id}
// //             style={linkStyle}
// //             onClick={() => handleNavigate(section.id)}
// //             onMouseEnter={(e) => {
// //               Object.assign(e.currentTarget.style, linkHoverStyle);
// //             }}
// //             onMouseLeave={(e) => {
// //               Object.assign(e.currentTarget.style, linkStyle);
// //             }}
// //           >
// //             {section.title}
// //           </span>
// //         ))}
// //       </div>
// //       {sections.map((section, index) => (
// //         <section key={index} id={section.id} style={sectionStyle}>
// //           <div style={leftContainerStyle}>
// //             <h2>{section.title}</h2>
// //             <p>{section.description}</p>
// //             <button style={actionButtonStyle} onClick={section.buttonAction}>
// //               {section.buttonLabel}
// //             </button>
// //           </div>
// //           <div style={rightContainerStyle}>
// //             {section.features.map((feature, index) => (
// //               <Box key={index}>{feature}</Box>
// //             ))}
// //           </div>
// //         </section>
// //       ))}
// //     </div>
// //   ) : (
// //     <div>
// //       <nav style={navbarStyle}>
// //         <p>LEARN AS A STUDENT</p>
// //       </nav>
// //       <div style={linkNavbarStyle}>
// //         {sections.map((section) => (
// //           <span
// //             key={section.id}
// //             style={linkStyle}
// //             onClick={() => handleNavigate(section.id)}
// //             onMouseEnter={(e) => {
// //               Object.assign(e.currentTarget.style, linkHoverStyle);
// //             }}
// //             onMouseLeave={(e) => {
// //               Object.assign(e.currentTarget.style, linkStyle);
// //             }}
// //           >
// //             {section.title}
// //           </span>
// //         ))}
// //       </div>
// //       <div style={mainSectionStyle}>
// //         {sections.map((section, index) => (
// //           <div style={sectionStyle} id={section.id} key={index}>
// //             <div style={leftContainerStyle}>
// //               <h2 style={{ color: "#00000" }}>{section.title}</h2>
// //               <p>{section.description}</p>
// //               <button style={actionButtonStyle} onClick={section.buttonAction}>
// //                 {section.buttonLabel}
// //               </button>
// //             </div>
// //             <div style={rightContainerStyle}>
// //               {section.features.map((feature, i) => (
// //                 <Box key={i}>{feature}</Box>
// //               ))}
// //             </div>
// //           </div>
// //         ))}
// //       </div>
// //     </div>
// //   );
// // }

// // export default ProductOffering;
// import React from "react";
// import { useNavigate } from "react-router-dom";

// function ProductOffering() {
//   const navigate = useNavigate();
//   const isMobile = window.innerWidth <= 900;

//   const handleNavigate = (path) => {
//     const targetSection = document.getElementById(path);
//     if (targetSection) {
//       targetSection.scrollIntoView({ behavior: "smooth", block: "center" });
//     } else {
//       navigate(path);
//     }
//   };

//   const commonStyles = {
//     navbar: {
//       backgroundColor: "rgb(0, 0, 0)",
//       color: "white",
//       padding: isMobile ? "5px" : "15px",
//       display: "flex",
//       justifyContent: "center",
//       fontWeight: "bolder",
//       fontSize: isMobile ? "20px" : "50px",
//       fontFamily: '"Libre Baskerville", serif',
//       marginTop: "10vh",
//       textAlign: "center",
//     },
//     linkNavbar: {
//       display: "flex",
//       flexDirection: isMobile ? "column" : "row",
//       justifyContent: "center",
//       alignItems: "center",
//       backgroundColor: "rgb(0, 0, 0)",
//       padding: "10px 0",
//       marginTop: "-10px",
//     },
//     link: {
//       fontFamily: '"Libre Baskerville", serif',
//       padding: isMobile ? "10px 20px" : "10px 10px",
//       margin: isMobile ? "5px" : "0 10px",
//       color: "white",
//       fontWeight: "bold",
//       textDecoration: "none",
//       borderRadius: "20px",
//       fontSize: "24px",
//       cursor: "pointer",
//       transition: "color 0.3s ease, background-color 0.3s ease",
//       textAlign: "center",
//       flexGrow: 1,
//     },
//     activeLink: {
//       backgroundColor: "#0e003d",
//       color: "white",
//     },
//     section: {
//       display: "flex",
//       flexDirection: isMobile ? "column" : "row",
//       alignItems: "center",
//       justifyContent: "space-between",
//       width: "90%",
//       margin: "60px auto",
//       backgroundColor: "#f0f8ff",
//       borderRadius: "8px",
//       padding: "20px",
//       boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
//     },
//     content: {
//       flex: "1",
//       textAlign: isMobile ? "center" : "left",
//       fontSize: "1.5rem",
//       fontFamily: '"Libre Baskerville", serif',
//       color: "black",
//     },
//     button: {
//       padding: "10px 20px",
//       backgroundColor: "#0e003d",
//       color: "white",
//       border: "none",
//       borderRadius: "5px",
//       cursor: "pointer",
//       fontSize: "20px",
//       transition: "background-color 0.3s ease",
//     },
//     imageContainer: {
//       flex: "1",
//       textAlign: "center",
//     },
//     image: {
//       maxWidth: "100%",
//       height: "auto",
//       borderRadius: "8px",
//     },
//   };

//   const sections = [
//     {
//       id: "divudbodh",
//       title: "UDBODH",
//       description:
//         "At Gyankosha's Udbodh vertical, we are committed to academic excellence and enhancing learning by blending School Subjects with General Knowledge, empowering students for holistic growth.",
//       features: "./wa4.png",
//       buttonLabel: "Explore",
//       buttonAction: () => handleNavigate("/middlescreen2"),
//     },
//     {
//       id: "divbaudh-dakshata",
//       title: "BAUDDHA DAKSHATA",
//       description:
//         "In Gyankosha's Bauddha Dakshata vertical, we specialize in nurturing Mental Abilities and Analytical Prowess to help students think critically, solve problems, and achieve academic success.",
//       features: "./wa4.png",
//       buttonLabel: "Explore",
//       buttonAction: () => handleNavigate("/middlescreen"),
//     },
//     {
//       id: "divkyebye",
//       title: "KYE-BYE",
//       description:
//         "In Gyankosha's KYE BYE vertical (KYE BYE- Know your English Build Your English), we specialize in empowering students with English proficiency tailored for academic and professional success.",
//       features: "./wa4.png",
//       buttonLabel: "Explore",
//       buttonAction: () => handleNavigate("/middlescreen3"),
//     },
//     {
//       id: "divabhyudaya",
//       title: "ABHYUDAYA",
//       description:
//         "We are dedicated to helping students master General Knowledge (GK) and General Awareness, laying a strong foundation for success in competitive exams like UPSC, State PSCs, SSC, BANK PO, NDA, and more.",
//       features: "./wa4.png",
//       buttonLabel: "Explore",
//       buttonAction: () => handleNavigate("/middlescreen4"),
//     },
//   ];

//   return (
//     <div>
//       <nav style={commonStyles.navbar}>
//         <p>LEARN AS A STUDENT</p>
//       </nav>
//       <div style={commonStyles.linkNavbar}>
//         {sections.map((section) => (
//           <span
//             key={section.id}
//             style={commonStyles.link}
//             onClick={() => handleNavigate(section.id)}
//           >
//             {section.title}
//           </span>
//         ))}
//       </div>
//       <div>
//         {sections.map((section) => (
//           <div key={section.id} id={section.id} style={commonStyles.section}>
//             <div style={commonStyles.content}>
//               <h2>{section.title}</h2>
//               <p>{section.description}</p>
//               <button
//                 style={commonStyles.button}
//                 onClick={section.buttonAction}
//               >
//                 {section.buttonLabel}
//               </button>
//             </div>
//             <div style={commonStyles.imageContainer}>
//               <img
//                 src={section.features}
//                 alt={`${section.title} feature`}
//                 style={commonStyles.image}
//               />
//             </div>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// }

// export default ProductOffering;

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

function ProductOffering() {
  const navigate = useNavigate();
  const [activeSection, setActiveSection] = useState("divudbodh"); // Default section
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // Mobile breakpoint

  // Handle screen resizing
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleNavigate = (sectionId) => {
    setActiveSection(sectionId);
  };

  // Reusable styles
  const commonStyles = {
    navbar: {
      backgroundColor: "#08023b",
      color: "white",
      padding: "10px",
      display: "flex",
      justifyContent: "center",
      fontWeight: "bolder",
      fontSize: isMobile ? "16px" : "30px",
      fontFamily: '"Libre Baskerville", serif',
      marginTop: "5vh",
      textAlign: "center",
    },
    linkNavbar: {
      display: "flex",
      flexDirection: isMobile ? "column" : "row",
      justifyContent: "center",
      alignItems: "center",
      gap: isMobile ? "10px" : "20px",
      backgroundColor: "#08023b",
      marginTop: "-10px",
      marginBottom: isMobile ? "2vh" : "5vh",
    },
    link: {
      fontFamily: '"Libre Baskerville", serif',
      padding: isMobile ? "10px 15px" : "15px 20px",
      color: "white",
      fontWeight: "bold",
      textDecoration: "none",
      borderRadius: "20px",
      fontSize: isMobile ? "16px" : "25px",
      cursor: "pointer",
      transition: "color 0.3s ease, background-color 0.3s ease",
      textAlign: "center",
    },
    activeLink: {
      backgroundColor: "rgb(168, 235, 250)",
      color: "rgb(0, 0, 0)",
      fontWeight: "bolder",
      padding: isMobile ? "10px 15px" : "5px 20px",

      fontSize: isMobile ? "18px" : "30px",
    },
    section: {
      display: "flex",
      flexDirection: isMobile ? "column" : "row",
      alignItems: "center",
      maxHeight: isMobile ? null : "600px",
      justifyContent: "space-between",
      padding: isMobile ? "20px" : "50px",
      gap: "30px",
      backgroundColor: "#f0f8ff",
    },
    content: {
      flex: 1,
      maxHeight: "80vh",
      fontSize: isMobile ? "1rem" : "1.2rem",
      padding: "10px",
      fontFamily: '"Libre Baskerville", serif',
      color: "black",
      textAlign: isMobile ? "justify" : "center",
    },
    imageContainer: {
      flex: 1,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    image: {
      width: isMobile ? "100%" : "80%", //abcdef
      // maxHeight: "400px",
      Height: "40%px",
      objectFit: "contain",
    },
    button: {
      marginTop: "20px",
      padding: "10px 20px",
      backgroundColor: "#0e003d",
      color: "white",
      border: "none",
      borderRadius: "5px",
      cursor: "pointer",
      fontSize: isMobile ? "16px" : "20px",
      transition: "background-color 0.3s ease",
    },
  };
  const sections = [
    {
      id: "divudbodh",
      title: "UDBODH",
      description:
        "At Gyankosha's Udbodh vertical, we are committed to academic excellence and enhancing learning by blending School Subjects with General Awareness, empowering students for holistic growth.",
      features: "./image4.png",
      buttonLabel: "Explore",
      buttonAction: () => navigate("/middlescreen2"),
    },
    {
      id: "divbaudh-dakshata",
      title: "BAUDDHA DAKSHATA",
      description:
        "In Gyankosha's Bauddha Dakshata vertical, we specialize in nurturing Mental Abilities and Analytical Prowess to help students think critically, solve problems, and achieve academic success.",
      features: "./bauddha_dakshata.png",
      buttonLabel: "Explore",
      buttonAction: () => navigate("/middlescreen"),
    },
    {
      id: "divkyebye",
      title: "KYE-BYE",
      description:
        "In Gyankosha's KYE BYE vertical (KYE BYE- Know your English Build Your English), we specialize in empowering students with English proficiency tailored for academic and professional success.",
      features: "./kyebye.png",
      buttonLabel: "Explore",
      buttonAction: () => navigate("/middlescreen3"),
    },
    {
      id: "divbusiness",
      title: "BUSINESS WISDOM",
      description:
        "Gyankosha’s Business Quizzes are the ultimate playground for working professionals and corporate honchos, MBA maestros, and trivia thrill-seekers! Let your knowledge bones do a happy dance as you tackle our witty and brain-tickling business challenges!",
      features: "./business.png",
      buttonLabel: "Explore",
      buttonAction: () => navigate("/middlescreen5"),
    },
    {
      id: "divabhyudaya",
      title: "ABHYUDAYA",
      description:
        " Gyankosha through its Abhyudaya Program  helps competitive exams aspirants master each topic and excel in their examinations through a uniquely designed blend of chapter sets, interactive quizzes and as an integrated learning platform. ( Competitive Exams: UPSC, State PSCs, SSC, IBPS, NDA etc.) ",
      features: "./Abhyudaya.png",
      buttonLabel: "Explore",
      buttonAction: () => navigate("/middlescreen4"),
    },
  ];

  const activeSectionData = sections.find(
    (section) => section.id === activeSection
  );

  return (
    <div>
      <nav style={commonStyles.navbar}>
        <p>LEARN AS A STUDENT</p>
      </nav>
      <div style={commonStyles.linkNavbar}>
        {sections.map((section) => (
          <span
            key={section.id}
            style={
              activeSection === section.id
                ? { ...commonStyles.link, ...commonStyles.activeLink }
                : commonStyles.link
            }
            onClick={() => handleNavigate(section.id)}
          >
            {section.title}
          </span>
        ))}
      </div>
      {activeSectionData && (
        <div style={commonStyles.section}>
          <div style={commonStyles.content}>
            <h2
              style={{ color: "#0e003d", fontWeight: "bold", fontSize: "2rem" }}
            >
              {activeSectionData.title}
            </h2>
            <p>{activeSectionData.description}</p>
            <button
              style={commonStyles.button}
              onClick={activeSectionData.buttonAction}
            >
              {activeSectionData.buttonLabel}
            </button>
          </div>
          <div style={commonStyles.imageContainer}>
            <img
              src={activeSectionData.features}
              alt={`${activeSectionData.title} feature`}
              style={commonStyles.image}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default ProductOffering;
