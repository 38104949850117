import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ArticleCard from "../components/ArticleCard";

function LearningAsTeacher() {
  const [cards, setCards] = useState([]);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // Fetch articles from the backend API
    const fetchCards = async () => {
      try {
        const response = await fetch(
          `/api/articles/fetcharticle?division=Teacher`
        );
        const data = await response.json();
        setCards(data);
      } catch (error) {
        console.error("Error fetching articles:", error);
      }
    };

    fetchCards();

    // Detect if the device is mobile
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Adjust breakpoint as needed
    };

    // Initial check and event listener for resize
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const containerStyle = {
    display: "flex",
    flexDirection: "row",
    overflowX: "auto", // Enable horizontal scrolling
    whiteSpace: "nowrap", // Prevent wrapping of cards
    gap: "10px",
    padding: "20px",
    scrollbarWidth: "thin", // Use thin scrollbar for Firefox
  };

  const cardStyle = {
    flexShrink: 0, // Prevent cards from shrinking
    width: "300px",
    backgroundColor: "#fff",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column",
  };

  return (
    <div>
      {/* Navbar */}
      {/* <div style={navbarStyle}> ... </div> */}

      {/* Content */}
      <div
        style={{
          display: "flex",
          // overflowX: "auto",
          gap: "20px",
          // padding: "20px",
          scrollbarWidth: "thin", // Optional: For styling the scrollbar
        }}
      >
        {cards.map((card, index) => (
          <div
            key={index}
            style={{
              flex: "0 0 300px", // Fixed width for all cards
              height: "400px", // Fixed height for all cards
              backgroundColor: "#fff",
              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
              borderRadius: "8px",
              overflow: "hidden",
              display: "flex",
              objectFit: "contain",
              flexDirection: "column",
            }}
          >
            <ArticleCard cards={[card]} />
          </div>
        ))}
      </div>
    </div>
  );
}

export default LearningAsTeacher;
