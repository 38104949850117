import React, { useState, useEffect } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { getAllSubjects } from "../actions/subjectActions";
import { getAllClassss } from "../actions/classsActions";

const AddChapter = () => {
  const dispatch = useDispatch();
  const [chapterName, setChapterName] = useState("");
  const [division, setDivision] = useState("");
  const [subject, setSubject] = useState("");
  const [classs, setClasss] = useState("");
  const [chapters, setChapters] = useState([]);
  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(null); // Track which chapter is being deleted

  // Fetch subjects and classes from Redux
  const subjectsState = useSelector((state) => state.getAllSubjectsReducer);
  const classssState = useSelector((state) => state.getAllClassssReducer);

  // Fetch subjects, classes, and chapters on component mount
  useEffect(() => {
    dispatch(getAllSubjects());
    dispatch(getAllClassss());
    fetchChapters();
  }, [dispatch]);

  // Fetch all chapters from the backend
  const fetchChapters = async () => {
    try {
      const response = await axios.get("/api/chapters/getallchapters");
      if (Array.isArray(response.data)) {
        setChapters(response.data);
      } else {
        console.error("Unexpected response format:", response.data);
        setChapters([]);
      }
    } catch (error) {
      console.error("Error fetching chapters:", error);
    }
  };

  // Submit form to add a chapter
  const submitHandler = async (e) => {
    e.preventDefault();
    if (!chapterName || !division || !subject || !classs) {
      alert("Please fill all the fields!");
      return;
    }

    setLoading(true);
    try {
      await axios.post("/api/chapters/addchapter", {
        chapter: chapterName,
        division,
        subject,
        classs,
      });
      alert("Chapter added successfully!");
      setChapterName("");
      setDivision("");
      setSubject("");
      setClasss("");
      fetchChapters();
    } catch (error) {
      console.error("Error adding chapter:", error.response?.data || error);
      alert(
        error.response?.data?.message ||
          "Failed to add chapter. Please try again."
      );
    }
    setLoading(false);
  };

  const deleteChapter = async (id) => {
    if (window.confirm("Are you sure you want to delete this chapter?")) {
      try {
        await axios.delete(`/api/chapters/deletechapter/${id}`);
        alert("Chapter deleted successfully!");
        fetchChapters();
      } catch (error) {
        console.error("Error deleting chapter:", error.response || error);
        alert(
          error.response?.data?.message ||
            "Failed to delete the chapter. Please try again."
        );
      }
    }
  };

  return (
    <div className="container">
      <h1>Add Chapter</h1>
      <form onSubmit={submitHandler}>
        <div className="form-group">
          <label>Chapter Name</label>
          <input
            type="text"
            className="form-control"
            value={chapterName}
            onChange={(e) => setChapterName(e.target.value)}
            placeholder="Enter Chapter Name"
          />
        </div>

        <div className="form-group">
          <label>Division</label>
          <select
            className="form-control"
            value={division}
            onChange={(e) => setDivision(e.target.value)}
          >
            <option value="">Select Division</option>
            <option value="Udbodh">Udbodh</option>
            <option value="Abhyudaya">Abhyudaya</option>
            <option value="Bauddha Dakshata">Bauddha Dakshata</option>
            <option value="Kye Bye">Kye Bye</option>
            <option value="Business Wisdom">Business Wisdom</option>
          </select>
        </div>

        <div className="form-group">
          <label>Subject</label>
          <select
            className="form-control"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
          >
            <option value="">Select Subject</option>
            {subjectsState.subjects &&
              Array.isArray(subjectsState.subjects) &&
              subjectsState.subjects.map((subj) => (
                <option key={subj._id} value={subj.sub}>
                  {subj.sub}
                </option>
              ))}
          </select>
        </div>

        <div className="form-group">
          <label>Class</label>
          <select
            className="form-control"
            value={classs}
            onChange={(e) => setClasss(e.target.value)}
          >
            <option value="">Select Class</option>
            {classssState.classss &&
              Array.isArray(classssState.classss) &&
              classssState.classss.map((cls) => (
                <option key={cls._id} value={cls.cls}>
                  {cls.cls}
                </option>
              ))}
          </select>
        </div>

        <button type="submit" className="btn btn-primary" disabled={loading}>
          {loading ? "Adding Chapter..." : "Add Chapter"}
        </button>
      </form>

      <h2>Chapters List</h2>
      <table className="table">
        <thead>
          <tr>
            <th>#</th>
            <th>Chapter</th>
            <th>Division</th>
            <th>Subject</th>
            <th>Class</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {Array.isArray(chapters) && chapters.length > 0 ? (
            chapters.map((chapter, index) => (
              <tr key={chapter._id}>
                <td>{index + 1}</td>
                <td>{chapter.chapter}</td>
                <td>{chapter.division}</td>
                <td>{chapter.subject}</td>
                <td>{chapter.classs}</td>
                <td>
                  <button
                    className="btn btn-danger"
                    onClick={() => deleteChapter(chapter._id)}
                    disabled={deleteLoading === chapter._id} // Disable button if loading
                  >
                    {deleteLoading === chapter._id ? "Deleting..." : "Delete"}
                  </button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="6" className="text-center">
                No chapters available.
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default AddChapter;
