// import React from "react";
// import { Link } from "react-router-dom"; // Used for navigation

// function ArticleCard({ cards }) {
//   // Function to modify Google Drive URL to direct image URL
//   const modifyImageUrl = (imageUrl) => {
//     const match = imageUrl.match(/\/d\/(.+?)\//); // Match the file ID from the URL
//     if (match && match[1]) {
//       const fileId = match[1];
//       return `https://lh3.googleusercontent.com/d/${fileId}=s1900?authuser=0`; // Construct thumbnail URL
//     }
//     return imageUrl; // Return original URL if not a Google Drive URL
//   };

//   const cardStyle = {
//     width: "400px", // Fixed width for all cards
//     height: "400px", // Fixed height for all cards
//     display: "flex",
//     flexDirection: "column",
//     borderRadius: "8px",
//     boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
//     overflow: "hidden",
//     backgroundColor: "rgba(224, 238, 237, 0.24)",
//     textDecoration: "none",
//     color: "inherit",
//   };

//   const imageStyle = {
//     width: "100%",
//     height: "250px", // Fixed height for images
//     objectFit: "cover", // Ensure the image covers the area
//   };

//   const textStyle = {
//     padding: "15px",
//     textAlign: "left",
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "center",
//     flexGrow: 1, // Ensures the text section takes up the remaining space
//   };

//   return (
//     <div style={{ padding: "20px", fontFamily: '"Libre Baskerville", serif' }}>
//       {cards.length === 0 ? (
//         <p>No articles available</p> // Handle empty cards
//       ) : (
//         <div
//           style={{
//             display: "flex",
//             overflowX: "scroll",
//             gap: "20px",
//             padding: "10px 0",
//           }}
//         >
//           {cards.map((card, index) => (
//             <Link
//               to={`/articleview/${card._id}`} // Navigate to article view page
//               key={index}
//               style={cardStyle}
//             >
//               {card.titleImage && (
//                 <img
//                   src={modifyImageUrl(card.titleImage)} // Use the modified image URL
//                   alt={card.title || "Untitled Article"}
//                   style={imageStyle}
//                 />
//               )}
//               <div style={textStyle}>
//                 <h3 style={{ margin: "0", fontSize: "18px" }}>
//                   {card.title || "Untitled Article"}
//                 </h3>
//               </div>
//             </Link>
//           ))}
//         </div>
//       )}
//     </div>
//   );
// }

// export default ArticleCard;
import React from "react";
import { Link } from "react-router-dom"; // Used for navigation

function ArticleCard({ cards }) {
  // Function to modify Google Drive URL to direct image URL
  // const modifyImageUrl = (imageUrl) => {
  //   const match = imageUrl.match(/\/d\/(.+?)\//); // Match the file ID from the URL
  //   if (match && match[1]) {
  //     const fileId = match[1];
  //     return `https://lh3.googleusercontent.com/d/${fileId}=s1900?authuser=0`; // Construct thumbnail URL
  //   }
  //   return imageUrl; // Return original URL if not a Google Drive URL
  // };

  const modifyImageUrl = (image) => {
    if (!image) return ""; // If image is null/undefined, return an empty string

    // If image is a string (direct URL), return it as is
    if (typeof image === "string") {
      // Handle Google Drive URLs
      if (image.includes("drive.google.com")) {
        console.log("inside google drive");
        // const match = image.match(/\/d\/(.+?)\//);
        // if (match) {
        //   console.log(`https://lh3.googleusercontent.com/d/${match[1]}=s1900?authuser=0`);
        //   return `https://lh3.googleusercontent.com/d/${match[1]}=s1900?authuser=0`;
        // }
        const fileId = image.match(/\/d\/(.+?)\//)[1]; // Extract file ID from URL if it exists
        return fileId
          ? `https://lh3.googleusercontent.com/d/${fileId}=s1900?authuser=0`
          : image;
      }
      return image; // Return direct AWS S3 links without modification
    }

    // If image is an object (future-proofing), handle it correctly
    if (typeof image === "object" && image.url) {
      if (image.storageType === "google_drive") {
        const match = image.url.match(/\/d\/(.+?)\//);
        if (match) {
          return `https://lh3.googleusercontent.com/d/${match[1]}=s1900?authuser=0`;
        }
      }
      return image.url;
    }

    return ""; // Fallback for unexpected cases
  };

  const cardStyle = {
    display: "flex",
    flexDirection: "column",
    borderRadius: "8px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    overflow: "hidden",
    backgroundColor: "rgba(224, 238, 237, 0.24)",
    textDecoration: "none",
    color: "inherit",
    height: "400px", // Fixed height for the entire card
  };

  const imageStyle = {
    width: "100%",
    height: "250px", // Fixed height for the image
    objectFit: "cover", // Ensure the image covers the area without stretching
  };

  const textStyle = {
    padding: "15px",
    textAlign: "left",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexGrow: 1, // Ensures the text section takes up the remaining space
  };

  return (
    <div style={{ padding: "20px", fontFamily: '"Libre Baskerville", serif' }}>
      {cards.length === 0 ? (
        <p>No articles available</p> // Handle empty cards
      ) : (
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(auto-fill, minmax(250px, 1fr))", // Responsive grid with 4 max cards per row
            gap: "20px",
            padding: "10px 0",
          }}
        >
          {cards.map((card, index) => (
            <Link
              to={`/articleview/${card._id}`} // Navigate to article view page
              key={index}
              style={cardStyle}
            >
              {card.titleImage && (
                <img
                  src={modifyImageUrl(card.titleImage)} // Use the modified image URL
                  alt={card.title || "Untitled Article"}
                  style={imageStyle}
                />
              )}
              <div style={textStyle}>
                <h3 style={{ margin: "0", fontSize: "18px" }}>
                  {card.title || "Untitled Article"}
                </h3>
              </div>
            </Link>
          ))}
        </div>
      )}
    </div>
  );
}

export default ArticleCard;
