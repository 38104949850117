// import React, { useState, useEffect } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { loginUser } from "../actions/userActions";
// import Error from "../components/Error";
// import Loading from "../components/Loading";
// import { useLocation, useNavigate } from "react-router";
// import BackgroundImage from "./BackgroundImage";
// import axios from "axios";
// import "./Loginscreen.css";

// export default function Loginscreen() {
//   const location = useLocation();
//   const navigate = useNavigate();
//   const page = location.state ? location.state.page : null;
//   const [email, setemail] = useState("");
//   const [password, setpassword] = useState("");
//   const loginstate = useSelector((state) => state.loginUserReducer);
//   const { loading, error } = loginstate;
//   const dispatch = useDispatch();

//   // Collect device information
//   const deviceInfo = {
//     userAgent: navigator.userAgent,
//     platform: navigator.platform,
//     screenWidth: window.screen.width,
//     screenHeight: window.screen.height,
//   };

//   const [locationData, setLocationData] = useState(null);

//   useEffect(() => {
//     if (localStorage.getItem("currentUser1")) {
//       window.location.href = "/";
//     } else {
//       // Request geolocation
//       if (navigator.geolocation) {
//         navigator.geolocation.getCurrentPosition(
//           async (position) => {
//             const { latitude, longitude } = position.coords;

//             try {
//               // Reverse geocoding to get city name
//               const response = await axios.get(
//                 `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${latitude}&longitude=${longitude}&localityLanguage=en`
//               );

//               const city =
//                 response.data.city || response.data.locality || "Unknown City";
//               const state =
//                 response.data.principalSubdivision || "Unknown State";

//               setLocationData({
//                 latitude,
//                 longitude,
//                 city,
//                 state,
//               });
//             } catch (error) {
//               console.error("Error getting location name: ", error);
//               setLocationData({
//                 latitude,
//                 longitude,
//                 city: "Unknown City",
//                 state: "Unknown State",
//               });
//             }
//           },
//           (error) => {
//             console.error("Error getting location: ", error);
//           }
//         );
//       }
//     }
//   }, []);

//   function login() {
//     const user = { email, password, deviceInfo, locationData }; // Add location data with city to login payload
//     dispatch(loginUser(user, page));
//   }

//   return (
//     <div>
//       <BackgroundImage />
//       <div className="row justify-content-center mt-5">
//         <div className="col-md-5 mt-5 text-start shadow-lg p-3 mb-5 rounded-5 glass-card">
//           <h2
//             className="text-center m-2"
//             style={{
//               fontSize: "35px",
//               fontFamily: '"Libre Baskerville", serif',
//               fontWeight: "bold",
//             }}
//           >
//             Login
//           </h2>

//           {loading && <Loading />}
//           {error && <Error error="Invalid Credentials" />}

//           <div>
//             <input
//               required
//               type="text"
//               placeholder="email"
//               className="form-control glass-card"
//               value={email}
//               onChange={(e) => setemail(e.target.value)}
//               style={{
//                 fontFamily: '"Libre Baskerville", serif',
//                 fontWeight: "bold",
//               }}
//             />
//             <input
//               type="password"
//               placeholder="password"
//               className="form-control glass-card"
//               value={password}
//               required
//               onChange={(e) => setpassword(e.target.value)}
//               style={{
//                 fontFamily: '"Libre Baskerville", serif',
//                 fontWeight: "bold",
//               }}
//             />
//             <button
//               onClick={login}
//               style={{
//                 fontFamily: '"Libre Baskerville", serif',
//                 fontWeight: "bold",
//               }}
//               className="btn mt-3 mb-3 glass-button"
//             >
//               LOGIN
//             </button>
//             <br />
//             <a
//               style={{
//                 color: "black",
//                 fontFamily: '"Libre Baskerville", serif',
//                 fontWeight: "bold",
//               }}
//               href="/register"
//             >
//               Click Here To Register if You are a New User
//             </a>
//             <br />
//             <a
//               style={{
//                 color: "black",
//                 fontFamily: '"Libre Baskerville", serif',
//                 fontWeight: "bold",
//               }}
//               href="/forgotpassword"
//             >
//               Forgot Password?
//             </a>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "../actions/userActions";
import Error from "../components/Error";
import Loading from "../components/Loading";
import { useLocation, useNavigate } from "react-router";
import BackgroundImage from "./BackgroundImage";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import "./Loginscreen.css";

export default function Loginscreen() {
  const location = useLocation();
  const navigate = useNavigate();
  const page = location.state ? location.state.page : null;
  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");
  const loginstate = useSelector((state) => state.loginUserReducer);
  const { loading, error } = loginstate;
  const dispatch = useDispatch();

  // Generate or retrieve the unique identifier from localStorage
  const uniqueBrowserId = localStorage.getItem("uniqueBrowserId")
    ? localStorage.getItem("uniqueBrowserId")
    : (() => {
        const newUuid = uuidv4();
        localStorage.setItem("uniqueBrowserId", newUuid);
        return newUuid;
      })();

  const [locationData, setLocationData] = useState(null);

  // useEffect(() => {
  //   if (localStorage.getItem("currentUser1")) {
  //     window.location.href = "/";
  //   } else {
  //     // Request geolocation
  //     if (navigator.geolocation) {
  //       navigator.geolocation.getCurrentPosition(
  //         async (position) => {
  //           const { latitude, longitude } = position.coords;

  //           try {
  //             // Reverse geocoding to get city and state name
  //             const response = await axios.get(
  //               `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${latitude}&longitude=${longitude}&localityLanguage=en`
  //             );

  //             const city =
  //               response.data.city || response.data.locality || "Unknown City";
  //             const state =
  //               response.data.principalSubdivision || "Unknown State";

  //             setLocationData({
  //               latitude,
  //               longitude,
  //               city,
  //               state,
  //             });
  //           } catch (error) {
  //             console.error("Error getting location name: ", error);
  //             setLocationData({
  //               latitude,
  //               longitude,
  //               city: "Unknown City",
  //               state: "Unknown State",
  //             });
  //           }
  //         },
  //         (error) => {
  //           console.error("Error getting location: ", error);
  //         }
  //       );
  //     }
  //   }
  // }, []);

  function login() {
    const user = {
      email: email.toLowerCase(),
      password,
      uniqueBrowserId, // Send unique browser identifier
      //locationData,
    };
    dispatch(loginUser(user, page));
  }

  return (
    <div>
      <BackgroundImage />
      <div className="row justify-content-center mt-5">
        <div className="col-md-5 mt-5 text-start shadow-lg p-3 mb-5 rounded-5 glass-card">
          <h2
            className="text-center m-2"
            style={{
              fontSize: "35px",
              fontFamily: '"Libre Baskerville", serif',
              fontWeight: "bold",
            }}
          >
            Login
          </h2>

          {loading && <Loading />}
          {/* {error && <Error error="Invalid Credentials" />} */}
          {error && <Error error={error} />}

          <div>
            <input
              required
              type="text"
              placeholder="email"
              className="form-control glass-card"
              value={email}
              onChange={(e) => setemail(e.target.value)}
              style={{
                fontFamily: '"Libre Baskerville", serif',
                fontWeight: "bold",
              }}
            />
            <input
              type="password"
              placeholder="password"
              className="form-control glass-card"
              value={password}
              required
              onChange={(e) => setpassword(e.target.value)}
              style={{
                fontFamily: '"Libre Baskerville", serif',
                fontWeight: "bold",
              }}
            />
            <button
              onClick={login}
              style={{
                fontFamily: '"Libre Baskerville", serif',
                fontWeight: "bold",
              }}
              className="btn mt-3 mb-3 glass-button"
            >
              LOGIN
            </button>
            <br />
            <a
              style={{
                color: "black",
                fontFamily: '"Libre Baskerville", serif',
                fontWeight: "bold",
              }}
              href="/register"
            >
              Click Here To Register if You are a New User
            </a>
            <br />
            <a
              style={{
                color: "black",
                fontFamily: '"Libre Baskerville", serif',
                fontWeight: "bold",
              }}
              href="/forgotpassword"
            >
              Forgot Password?
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
