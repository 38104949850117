// import React, { useEffect, useState } from "react";
// import { useParams } from "react-router-dom";

// function ArticleView() {
//   const { id } = useParams(); // Get the article ID from the URL
//   const [article, setArticle] = useState(null);
//   const [loading, setLoading] = useState(true);

//   // Function to modify Google Drive image URLs
//   const modifyImageUrl = (imageUrl) => {
//     const match = imageUrl.match(/\/d\/(.+?)\//); // Match the file ID from the URL
//     if (match && match[1]) {
//       const fileId = match[1];
//       console.log(
//         "Modified image URL:",
//         `https://lh3.googleusercontent.com/d/${fileId}=s1900?authuser=0`
//       );
//       return `https://lh3.googleusercontent.com/d/${fileId}=s1900?authuser=0`; // Construct thumbnail URL
//     } else {
//       console.error("Invalid Google Drive URL:", imageUrl);
//     }
//     return imageUrl; // Return the original URL if not a Google Drive URL
//   };

//   // Fetch the article when the component mounts
//   useEffect(() => {
//     const fetchArticle = async () => {
//       if (!id) {
//         console.error("No article ID provided.");
//         return;
//       }
//       try {
//         const response = await fetch(`/api/articles/fetcharticlebyid/${id}`);
//         const data = await response.json();

//         if (response.ok) {
//           console.log("Fetched article:", data); // Debug the article object
//           setArticle(data);
//         } else {
//           console.error("Error fetching article:", data.error);
//         }
//       } catch (error) {
//         console.error("Error fetching article:", error);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchArticle();
//   }, [id]);

//   if (loading) {
//     return <p>Loading article...</p>;
//   }

//   if (!article) {
//     return <p>Article not found.</p>;
//   }

//   return (
//     <div style={{ padding: "20px", maxWidth: "800px", margin: "0 auto" }}>
//       {/* Article Title */}
//       <h1
//         style={{
//           fontSize: "36px",
//           fontWeight: "bold",
//           textAlign: "center",
//           marginBottom: "20px",
//         }}
//       >
//         {article.title}
//       </h1>

//       {/* Title Image */}
//       {article.titleImage && (
//         <img
//           src={modifyImageUrl(article.titleImage)} // Use the modified image URL
//           alt="Title"
//           style={{
//             width: "100%",
//             height: "auto",
//             borderRadius: "8px",
//             marginBottom: "40px",
//           }}
//         />
//       )}

//       {/* Content Rendering */}
//       {article.content && article.content.length > 0 ? (
//         article.content.map((item, index) => (
//           <div key={index} style={{ marginBottom: "20px" }}>
//             {item.type === "text" && (
//               <p style={{ fontSize: "18px", color: "#333" }}>{item.value}</p>
//             )}
//             {item.type === "image" && (
//               <img
//                 src={modifyImageUrl(item.value)} // Modify the image URL
//                 alt={`Article Image ${index + 1}`}
//                 style={{
//                   width: "100%",
//                   height: "auto",
//                   borderRadius: "8px",
//                   marginBottom: "10px",
//                 }}
//               />
//             )}
//           </div>
//         ))
//       ) : (
//         <p>No content available for this article.</p>
//       )}
//     </div>
//   );
// }

// export default ArticleView;
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

function ArticleView() {
  const { id } = useParams(); // Get the article ID from the URL
  const [article, setArticle] = useState(null);
  const [loading, setLoading] = useState(true);

  // Function to modify Google Drive image URLs
  // const modifyImageUrl = (imageUrl) => {
  //   const match = imageUrl.match(/\/d\/(.+?)\//); // Match the file ID from the URL
  //   if (match && match[1]) {
  //     const fileId = match[1];
  //     return `https://lh3.googleusercontent.com/d/${fileId}=s1900?authuser=0`; // Construct thumbnail URL
  //   } else {
  //     console.error("Invalid Google Drive URL:", imageUrl);
  //   }
  //   return imageUrl; // Return the original URL if not a Google Drive URL
  // };

  const modifyImageUrl = (image) => {
    if (!image) return ""; // If image is null/undefined, return an empty string

    // If image is a string (direct URL), return it as is
    if (typeof image === "string") {
      // Handle Google Drive URLs
      if (image.includes("drive.google.com")) {
        console.log("inside google drive");
        // const match = image.match(/\/d\/(.+?)\//);
        // if (match) {
        //   console.log(`https://lh3.googleusercontent.com/d/${match[1]}=s1900?authuser=0`);
        //   return `https://lh3.googleusercontent.com/d/${match[1]}=s1900?authuser=0`;
        // }
        const fileId = image.match(/\/d\/(.+?)\//)[1]; // Extract file ID from URL if it exists
        return fileId
          ? `https://lh3.googleusercontent.com/d/${fileId}=s1900?authuser=0`
          : image;
      }
      return image; // Return direct AWS S3 links without modification
    }

    // If image is an object (future-proofing), handle it correctly
    if (typeof image === "object" && image.url) {
      if (image.storageType === "google_drive") {
        const match = image.url.match(/\/d\/(.+?)\//);
        if (match) {
          return `https://lh3.googleusercontent.com/d/${match[1]}=s1900?authuser=0`;
        }
      }
      return image.url;
    }

    return ""; // Fallback for unexpected cases
  };

  // Function to extract YouTube embed URL from a given video link
  const getYouTubeEmbedUrl = (videoUrl) => {
    const match = videoUrl.match(
      /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:embed\/|watch\?v=)|youtu\.be\/)([\w-]{11})/
    );
    if (match && match[1]) {
      return `https://www.youtube.com/embed/${match[1]}`;
    } else {
      console.error("Invalid YouTube URL:", videoUrl);
    }
    return null; // Return null if the URL is not valid
  };

  // Fetch the article when the component mounts
  useEffect(() => {
    const fetchArticle = async () => {
      if (!id) {
        console.error("No article ID provided.");
        return;
      }
      try {
        const response = await fetch(`/api/articles/fetcharticlebyid/${id}`);
        const data = await response.json();

        if (response.ok) {
          setArticle(data);
        } else {
          console.error("Error fetching article:", data.error);
        }
      } catch (error) {
        console.error("Error fetching article:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchArticle();
  }, [id]);

  if (loading) {
    return <p>Loading article...</p>;
  }

  if (!article) {
    return <p>Article not found.</p>;
  }

  return (
    <div
      style={{
        padding: "20px",
        maxWidth: "800px",
        margin: "0 auto",
        fontFamily: '"Libre Baskerville", serif',
      }}
    >
      {/* Article Title */}
      <p
        style={{
          fontSize: "50px", // Largest font size for the title
          fontWeight: "bold",
          textAlign: "center",
          marginBottom: "20px",
        }}
      >
        {article.title}
      </p>

      {/* Title Image */}
      {article.titleImage && (
        <img
          src={modifyImageUrl(article.titleImage)} // Use the modified image URL
          alt="Title"
          style={{
            width: "100%",
            height: "auto",
            borderRadius: "8px",
            marginBottom: "40px",
          }}
        />
      )}

      {/* Content Rendering */}
      {article.content && article.content.length > 0 ? (
        article.content.map((item, index) => (
          <div key={index} style={{ marginBottom: "20px" }}>
            {item.type === "text" && (
              <p
                style={{
                  fontSize: "20px",
                  fontWeight: "normal",
                  textAlign: "justify",
                  color: "#333",
                }}
              >
                {item.value}
              </p>
            )}
            {item.type === "heading" && (
              <h2
                style={{
                  fontSize: "28px", // 5px larger than the text
                  fontWeight: "bold",
                  textAlign: "left",
                  color: "#222",
                  marginBottom: "10px",
                }}
              >
                {item.value}
              </h2>
            )}
            {item.type === "image" && (
              <img
                src={modifyImageUrl(item.value)} // Modify the image URL
                alt={`Article Image ${index + 1}`}
                style={{
                  width: "100%",
                  height: "auto",
                  borderRadius: "8px",
                  marginBottom: "10px",
                }}
              />
            )}
            {item.type === "youtube" && (
              <div
                style={{
                  position: "relative",
                  paddingBottom: "56.25%", // Aspect ratio 16:9
                  height: 0,
                  overflow: "hidden",
                  marginBottom: "20px",
                }}
              >
                <iframe
                  src={getYouTubeEmbedUrl(item.value)}
                  title={`YouTube Video ${index + 1}`}
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    border: "none",
                  }}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            )}
          </div>
        ))
      ) : (
        <p>No content available for this article.</p>
      )}
    </div>
  );
}

export default ArticleView;
