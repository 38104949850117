import { combineReducers } from "redux";
import { createStore, applyMiddleware } from "redux";
import { thunk } from "redux-thunk";
//import { composeWithDevTools } from "redux-devtools-extension";
import {
  getAllStudentsReducer,
  addStudentReducer,
  getStudentByIdReducer,
  editStudentReducer,
} from "./reducers/studentReducers";
import {
  getAllQuizsReducer,
  addQuizReducer,
  getQuizByIdReducer,
  editQuizReducer,
} from "./reducers/quizReducers";
import {
  getAllSchoolcodesReducer,
  addSchoolcodeReducer,
  getSchoolcodeByIdReducer,
} from "./reducers/schoolcodeReducers";
import {
  getAllSubjectsReducer,
  addSubjectReducer,
  getSubjectByIdReducer,
} from "./reducers/subjectReducers";

import {
  getAllDifficultysReducer,
  addDifficultyReducer,
  getDifficultyByIdReducer,
} from "./reducers/difficultyReducers";

import {
  getAllClassssReducer,
  addClasssReducer,
  getClasssByIdReducer,
} from "./reducers/classsReducers";

import {
  loginUserReducer,
  registerUserReducer,
  getAllUsersReducer,
} from "./reducers/userReducer";
import {
  KBgetAllQuizsReducer,
  KBaddQuizReducer,
  KBeditQuizReducer,
  KBgetQuizByIdReducer,
} from "./reducers/kyebyequizReducers";

const finalReducer = combineReducers({
  registerUserReducer: registerUserReducer,
  loginUserReducer: loginUserReducer,
  getAllUsersReducer: getAllUsersReducer,
  getAllQuizsReducer: getAllQuizsReducer,
  addQuizReducer: addQuizReducer,
  getQuizByIdReducer: getQuizByIdReducer,
  editQuizReducer: editQuizReducer,
  getAllSchoolcodesReducer: getAllSchoolcodesReducer,
  addSchoolcodeReducer: addSchoolcodeReducer,
  getSchoolcodeByIdReducer: getSchoolcodeByIdReducer,
  KBgetAllQuizsReducer: KBgetAllQuizsReducer,
  KBaddQuizReducer: KBaddQuizReducer,
  KBeditQuizReducer: KBeditQuizReducer,
  KBgetQuizByIdReducer: KBgetQuizByIdReducer,
  getAllSubjectsReducer: getAllSubjectsReducer,
  addSubjectReducer: addSubjectReducer,
  getSubjectByIdReducer: getSubjectByIdReducer,

  getAllDifficultysReducer: getAllDifficultysReducer,
  addDifficultyReducer: addDifficultyReducer,
  getDifficultyByIdReducer: getDifficultyByIdReducer,

  getAllClassssReducer: getAllClassssReducer,
  addClasssReducer: addClasssReducer,
  getClasssByIdReducer: getClasssByIdReducer,

  getAllStudentsReducer: getAllStudentsReducer,
  addStudentReducer: addStudentReducer,
  getStudentByIdReducer: getStudentByIdReducer,
  editStudentReducer: editStudentReducer,
});

const currentUser1 = localStorage.getItem("currentUser1")
  ? JSON.parse(localStorage.getItem("currentUser1"))
  : null;

const initialState = {
  loginUserReducer: {
    currentUser1: currentUser1,
  },
};

//const composeEnhancers = composeWithDevTools({});

const store = createStore(finalReducer, initialState, applyMiddleware(thunk));

export default store;
