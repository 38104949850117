import "./App.css";
import { Toaster } from "react-hot-toast";
import bootstrap from "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import Navbar from "./components/Navbar";
import Loginscreen from "./screens/Loginscreen";
import Registerscreen from "./screens/Registerscreen";
import Success from "./components/Success";
import UsersList from "./screens/UsersList";
import Firstscreen from "./screens/Firstscreen";
import Udbodhscreen from "./screens/Udbodhscreen";
import Bdscreen from "./screens/Bdscreen";
import GetinspiredScreen from "./screens/GetinspiredScreen";
import AdminPanel from "./screens/AdminPanel";
import Quiz from "./components/Quiz";
import QuestionPage from "./components/QuestionPage";
import SubscriptionList from "./screens/SubscriptionList";
import QuizsList from "./screens/QuizsList";
import AddQuiz from "./screens/AddQuiz";
import Editquiz from "./screens/Editquiz";
import Schoolcodes from "./screens/Schoolcodes";
import SchoolcodesList from "./screens/SchoolcodesList";
import MiddleScreen from "./screens/MiddleScreen";
import QuestionPageDesktop from "./components/QuestionPageDesktop";
import { useEffect, useState } from "react";
import SubjectsList from "./screens/SubjectsList";
import Subjects from "./screens/Subjects";
import DifficultysList from "./screens/DifficultysList";
import Difficultys from "./screens/Difficultys";
import ClassssList from "./screens/ClassssList";
import Classss from "./screens/Classss";
import QuizResult from "./components/QuizResult";
import MiddleScreen2 from "./screens/MiddleScreen2";
import Paymentplansscreen from "./screens/Paymentplansscreen";
import AddStudent from "./screens/AddStudent";
import StudentsList from "./screens/StudentsList";
import Verification from "./screens/Verification";
import EnterOtp from "./screens/EnterOtp";
import Forgotpassword from "./screens/Forgotpassword";
import Dashboard from "./screens/Dashboard";
import Downloadcsv from "./screens/Downloadcsv";
import UploadCSV from "./screens/UploadCSV";
import EnableUser from "./screens/EnableUser";
import Tutorial from "./screens/Tutorial";
import QuizSearch from "./screens/QuizSearch";
import UserSearch from "./screens/UserSearch";
import RestoreImage from "./screens/RestoreImage";
import Kyebyescreen from "./screens/Kyebyescreen";
import AddKyebyeQuiz from "./screens/AddKyebyeQuiz";
import KyebyeEditQuiz from "./screens/KyebyeEditQuiz";
import MiddleScreen3 from "./screens/middlescreen3";
import KyebyeQuizsList from "./screens/KyebyeQuizList";
import QuestionPassageToggle from "./screens/QuestionPassage";
import MiddleScreen4 from "./screens/middlescreen4";
import Abhyudayascreen from "./screens/AbhyudayaScreen.js";
import ArticleCard from "./components/ArticleCard";
import ArticleCardPage from "./screens/ArticleCardPage";
import ArticleView from "./screens/Articleview.js";
import AddArticle from "./screens/AaddArticle.js";
import KyeByeQuestionPage from "./components/KyeByeQuestionPage";
import KyeByeQuestionPageDesktop from "./components/KyeByeQuestionPageDesktop";
import ReattemptQuestions from "./components/ReattemptQuestions.js";
import QuizUploadcsv from "./screens/QuizUploadcsv.js";
import KyebyeQuizUploadCSV from "./screens/KyebyeQuizUploadcsv.js";
import StudentLearning from "./screens/StudentLearning.js";
import Parenting from "./screens/Parenting.js";
import TeacherTraining from "./screens/TeacherTraining.js";
import ReattemptQuestionsMobile from "./components/ReattemptQuestionsMobile.js";
import MiddleScreen5 from "./screens/middlescreen5.js";
import Businessscreen from "./screens/Businessscreen.js";
import Purchasescreen from "./screens/Purchasescreen.js";
import AddChapter from "./screens/addchapter.js";
import AddGallery from "./screens/AddGallery.js";
import GalleryPhotos from "./screens/GalleryPhotos.js";

function App() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 767);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // // Custom Hook to access the current location
  // const location = useLocation();

  // // Define routes where Navbar should be hidden
  // const hideNavbarRoutes = ["/questions", "/kyebyequestions"];

  // const location = useLocation();

  // const isOnQuestionsScreen = location.pathname === "/questions";

  // const isOnKyebyeQuestionsScreen = location.pathname === "/kyebyequestions";

  return (
    <div className="App">
      <BrowserRouter>
        {/* Conditionally render Navbar */}
        {/* {(!isOnQuestionsScreen || !isOnKyebyeQuestionsScreen) && <Navbar />} */}
        <Navbar />
        <Routes>
          <Route path="/" element={<Firstscreen />} />

          <Route path="/Parenting" element={<Parenting />} />
          <Route path="/TeacherTraining" element={<TeacherTraining />} />
          <Route path="/StudentLearning" element={<StudentLearning />} />

          <Route path="/restoreimage" element={<RestoreImage />} />
          <Route path="/middlescreen" element={<MiddleScreen />} />
          <Route path="/middlescreen2" element={<MiddleScreen2 />} />
          <Route path="/middlescreen3" element={<MiddleScreen3 />} />
          <Route path="/abhyudayascreen" element={<Abhyudayascreen />} />
          <Route path="/middlescreen4" element={<MiddleScreen4 />} />
          <Route path="/middlescreen5" element={<MiddleScreen5 />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/udbodh" element={<Udbodhscreen />} />
          <Route path="/bauddhadakshata" element={<Bdscreen />} />
          <Route path="/kyebye" element={<Kyebyescreen />} />
          <Route path="/business" element={<Businessscreen />} />
          <Route path="/getinspired" element={<GetinspiredScreen />} />
          <Route path="/login" element={<Loginscreen />} />
          <Route path="/register" element={<Registerscreen />} />
          <Route path="/verification" element={<Verification />} />
          <Route path="/forgotpassword" element={<Forgotpassword />} />
          <Route path="/enterotp" element={<EnterOtp />} />
          <Route path="/paymentplans" element={<Paymentplansscreen />} />
          <Route path="/tutorial" element={<Tutorial />} />
          <Route path="/purchase" element={<Purchasescreen />} />
          <Route path="/quiz" element={<Quiz />} />
          <Route
            path="/questions"
            element={isMobile ? <QuestionPage /> : <QuestionPageDesktop />}
          />
          <Route
            path="/reattempt"
            element={
              isMobile ? <ReattemptQuestionsMobile /> : <ReattemptQuestions />
            }
          />

          <Route
            path="/kyebyequestions"
            element={
              isMobile ? <KyeByeQuestionPage /> : <KyeByeQuestionPageDesktop />
            }
          />
          <Route path="/articlecard" element={<ArticleCard />} />
          <Route path="/articlecardpage" element={<ArticleCardPage />} />
          <Route path="/articleview/:id" element={<ArticleView />} />

          <Route path="/quizresult" element={<QuizResult />} />
          <Route
            path="/payment-success"
            element={<Success success="Payment Successful" />}
          />
          <Route path="/adminpanel" element={<AdminPanel />}>
            <Route index element={<QuizsList />} />
            <Route path="/adminpanel/userlist" element={<UsersList />} />
            <Route path="/adminpanel/usersearch" element={<UserSearch />} />
            <Route
              path="/adminpanel/subscriptionlist"
              element={<SubscriptionList />}
            />
            <Route path="/adminpanel/quizslist" element={<QuizsList />} />
            <Route path="/adminpanel/quizsearch" element={<QuizSearch />} />
            <Route path="/adminpanel/addquiz" element={<AddQuiz />} />
            <Route path="/adminpanel/editquiz/:quizid" element={<Editquiz />} />
            <Route path="/adminpanel/schoolcodes" element={<Schoolcodes />} />
            <Route
              path="/adminpanel/KyeByeQuizList"
              element={<KyebyeQuizsList />}
            />
            <Route
              path="/adminpanel/AddKyebyeQuiz"
              element={<AddKyebyeQuiz />}
            />
            <Route path="/adminpanel/addchapter" element={<AddChapter />} />

            <Route path="/adminpanel/galleryimage" element={<AddGallery />} />
            <Route
              path="/adminpanel/galleryphotos"
              element={<GalleryPhotos />}
            />
            <Route
              path="/adminpanel/Kyebyeeditquiz/:quizid"
              element={<KyebyeEditQuiz />}
            />
            <Route
              path="/adminpanel/updateAllQuestionPassages/:quizId"
              element={<QuestionPassageToggle />}
            />
            <Route
              path="/adminpanel/schoolcodeslist"
              element={<SchoolcodesList />}
            />
            <Route path="/adminpanel/subjects" element={<Subjects />} />
            <Route path="/adminpanel/subjectslist" element={<SubjectsList />} />
            <Route path="/adminpanel/AaddArticle" element={<AddArticle />} />

            <Route path="/adminpanel/difficultys" element={<Difficultys />} />
            <Route
              path="/adminpanel/difficultyslist"
              element={<DifficultysList />}
            />
            <Route path="/adminpanel/classss" element={<Classss />} />
            <Route path="/adminpanel/classsslist" element={<ClassssList />} />
            <Route path="/adminpanel/editquiz/:quizid" element={<Editquiz />} />
            <Route
              path="/adminpanel/Kyebyeeditquiz/:quizid"
              element={<KyebyeEditQuiz />}
            />
            <Route
              path="/adminpanel/updateAllQuestionPassages/:quizId"
              element={<QuestionPassageToggle />}
            />
            <Route
              path="/adminpanel/QuizUploadcsv"
              element={<QuizUploadcsv />}
            />
            <Route
              path="/adminpanel/KyebyeQuizUploadCSV"
              element={<KyebyeQuizUploadCSV />}
            />
            <Route path="/adminpanel/addstudent" element={<AddStudent />} />
            <Route path="/adminpanel/studentslist" element={<StudentsList />} />
            <Route path="/adminpanel/downloadcsv" element={<Downloadcsv />} />
            <Route path="/adminpanel/uploadcsv" element={<UploadCSV />} />
            <Route path="/adminpanel/enableuser" element={<EnableUser />} />
          </Route>
        </Routes>
        <Toaster />
      </BrowserRouter>
    </div>
  );
}

export default App;
